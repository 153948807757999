<template>
  <FormEditorView :key="id" :id="id" />
</template>
<script>
import FormEditorView from '../components/form/FormEditorView.vue'
export default {
  components: { FormEditorView },
  name: 'FlowEditor',
  props: { id: String },
}
</script>
