<template>
  <div class="ConversionsEditor">
    <a-space direction="vertical">
      <EditorHeading icon="bullseye-pointer" :title="label || 'Conversions'" />
      <template v-if="outofDateWarnings.length">
        <a-alert v-for="warning in outofDateWarnings" :key="warning" :message="warning" banner />
        <div>Old Data: <a-button icon="close" type="link" @click="dismissWarning" /></div>
        <pre v-text="outofDateData"></pre>
      </template>

      <a-collapse v-if="conversions && conversions.length">
        <a-collapse-panel
          v-for="(conversion, i) in conversions"
          :key="conversion.id"
          :header="`${i}: ${conversion.platform}`"
        >
          <div class="ConversionTrackingEditor">
            <a-space direction="vertical">
              <EditorSelect
                :_key="`${conversionKey}.${i}.platform`"
                tooltip="Select a platform"
                :options="platforms"
              />

              <template v-if="conversion.platform === 'facebook'">
                <EditorSelect
                  :_key="`${conversionKey}.${i}.facebook_pixel_event`"
                  tooltip="Trigger a Facebook Pixel event when the user reaches this page"
                  :options="facebookPixelEvents"
                />
                <EditorCheckbox
                  v-if="
                    getEventParams(conversion.facebook_pixel_event).find(
                      p => p.name === 'content_ids'
                    )
                  "
                  :_key="`${conversionKey}.${i}.send_page_key_as_content_id`"
                  label="Use Page Key for Content IDs"
                />
                <EditorInput
                  v-for="param in getEventParams(conversion.facebook_pixel_event)"
                  :key="param.name"
                  allowComputed
                  :label="
                    param.required
                      ? `REQUIRED - ${param.name} (${param.type})`
                      : `${param.name} (${param.type})`
                  "
                  :_key="`${conversionKey}.${i}.fbq.${param.name}`"
                  :disabled="
                    param.name === 'content_ids' &&
                      parent[conversionKey][i].send_page_key_as_content_id
                  "
                  :component="parent"
                />
              </template>
              <template v-if="conversion.platform === 'google'">
                <EditorInput
                  allowComputed
                  monospaced
                  label="Google Conversion Id"
                  :_key="`${conversionKey}.${i}.google_conversion_id`"
                  :component="parent"
                />
              </template>
              <template v-if="conversion.platform === 'gtag'">
                <EditorInput
                  allowComputed
                  monospaced
                  label="Event Name (Required)"
                  :_key="`${conversionKey}.${i}.event_name`"
                  :component="parent"
                />

                <EditorInput
                  allowComputed
                  monospaced
                  label="Event Category"
                  :_key="`${conversionKey}.${i}.event_category`"
                  :component="parent"
                />

                <EditorInput
                  allowComputed
                  monospaced
                  label="Event label"
                  :_key="`${conversionKey}.${i}.event_label`"
                  :component="parent"
                />

                <EditorInput
                  allowComputed
                  monospaced
                  label="Event Action"
                  :_key="`${conversionKey}.${i}.event_action`"
                  :component="parent"
                />

                <EditorInput
                  allowComputed
                  monospaced
                  label="Value"
                  :_key="`${conversionKey}.${i}.value`"
                  :component="parent"
                />
              </template>
              <template v-if="conversion.platform === 'segment'">
                <EditorInput
                  allowComputed
                  monospaced
                  label="Event Name"
                  :_key="`${conversionKey}.${i}.event_name`"
                  :component="parent"
                />

                <label>
                  Must contain a function called 'customPayload'. Function receives userData as the
                  first argument. Function should return a plain JavaScript Object.
                </label>
                <EditorCode
                  :_key="`${conversionKey}.${i}.parameter_payload_code`"
                  v-bind="{ codeDraft }"
                />
                <a-button size="small" @click="generateSampleCode">Generate sample code</a-button>
              </template>
              <a-button icon="minus" size="small" @click="deleteConversion(i)">
                Delete Conversion
              </a-button>
            </a-space>
          </div>
        </a-collapse-panel>
      </a-collapse>
      <a-button icon="plus" class="w-full" @click="addConversion">Add a Conversion</a-button>
    </a-space>
  </div>
</template>
<script>
import facebookPixelEvents, { getEventData } from './helpers/facebookPixelEvents'
export default {
  inject: ['_getOptions', '_getBasePath', '_updateData'],
  props: { parent: Object, label: String, _key: String, jsxKey: String },
  data() {
    return { codeDraft: '' }
  },
  computed: {
    conversions: {
      get() {
        const key = this.conversionKey
        return (this.parent && this.parent[key]) || []
      },
      set(v) {
        const key = this.conversionKey
        this._updateData(this._getBasePath(), key, v)
      },
    },
    conversionKey() {
      return this.jsxKey || this._key || 'conversions'
    },
    platforms() {
      return [undefined, 'facebook', 'google', 'gtag', 'segment']
    },
    facebookPixelEvents() {
      return [undefined].concat(facebookPixelEvents())
    },
    outofDateData() {
      return JSON.stringify({
        fbq: this.parent.fbq,
        facebook_pixel_event: this.parent.facebook_pixel_event,
      })
    },
    outofDateWarnings() {
      if (!this.parent) return []
      const conversions = this.parent.conversion_data || {}
      const warnings = []

      if (
        (this.parent.fbq && !conversions.fbq) ||
        (this.parent.facebook_pixel_event && !conversions.facebook_pixel_event)
      )
        warnings.push('You have Facebok Pixel events set for this element that need to be updated')

      return warnings
    },
  },
  watch: {},
  methods: {
    getEventParams(event) {
      return Object.values(getEventData(event))
    },
    addConversion() {
      const data = { id: `_${Math.round(Math.random() * 1000)}${Math.round(Math.random() * 1000)}` }
      // this._updateData(`${this._getBasePath()}.conversions`, this.conversions.length, data)
      this.conversions = [...this.conversions, data]
    },
    async dismissWarning() {
      this._updateData(this._getBasePath(), 'fbq', null)
      await this.$nextTick()
      this._updateData(this._getBasePath(), 'facebook_pixel_event', null)
    },
    deleteConversion(index) {
      this.conversions = this.conversions.filter((c, i) => i !== index)
    },
    generateSampleCode() {
      this.codeDraft = `function customPayload(userData) {\n  return {} \n}`
    },
  },
}
</script>
