<template>
  <div
    class="AnnotationIcon"
    ref="icon"
    :style="{
      background: colors[0],
      '--fa-primary-color': 'white',
      '--fa-secondary-color': 'white',
    }"
    @click.stop="changeType"
  >
    <Icon v-if="icon" :name="icon" />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import Firebase from 'firebase/app'
import 'firebase/firestore'

export default {
  name: 'AnnotationIcon',
  props: {
    annotation: Object,
    rootPath: String,
    click: Boolean,
  },
  computed: {
    ...mapState(['editingMode', 'pages']),
    ...mapGetters(['isAdmin']),
    icon() {
      switch (this.annotation && this.annotation.type) {
        case 'hot':
          return 'fad-fire-alt'
        case 'heating':
          return 'fad-temperature-up'
        case 'cold':
          return 'fad-snowflake'
        case 'cooling':
          return 'fad-temperature-down'
        default:
          return null
      }
    },
    colors() {
      switch (this.annotation && this.annotation.type) {
        case 'hot':
        case 'heating':
          return ['#f51d4d', 'grey']
        case 'cold':
        case 'cooling':
          return ['#34b3ff', 'grey']
        default:
          return []
      }
    },
  },
  methods: {
    changeType() {
      if (!this.click) return

      const types = ['hot', 'cold', 'heating', 'cooling']
      const index = types.indexOf(this.annotation.type)
      const type = types[index < types.length - 1 ? index + 1 : 0]

      Firebase.firestore()
        .collection('content')
        .doc(this.rootPath)
        .update({
          type,
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/_variables.scss';

.AnnotationIcon {
  background: #ddd;
  width: 20px;
  height: 20px;
  border-radius: 100px;
  @include flex('row', 'center');
  margin-right: 5px;
}
</style>
