<template>
  <div
    v-if="noHandler"
    class="flex bg-white border-b border-gray-200 px-4 py-2 cursor-pointer hover:bg-gray-50"
    :class="{ hidden }"
  >
    <slot />
  </div>
  <div
    v-else
    class="flex bg-white border-b border-gray-200 px-4 py-2 cursor-pointer hover:bg-gray-50"
    :class="{ hidden }"
    @click="_onSelectComponent({ key: id })"
  >
    <slot />
  </div>
</template>
<script>
export default {
  props: { id: String, hidden: Boolean, noHandler: Boolean },
  inject: { _onSelectComponent: { default: () => () => {} } },
}
</script>
