<template>
  <Pill
    class="cursor-pointer"
    :class="{ 'opacity-70': hidden, 'bg-gray-100': hidden }"
    :icon="componentIcon"
    @click.native="_setSelectedComponentId($event, componentId)"
  >
    {{ label }}
    <!-- <Icon v-if="required" class="text-xs ml-1" name="asterisk" /> -->
    <span v-if="required">*</span>
  </Pill>
</template>
<script>
export default {
  name: 'ComponentPill',
  inject: { _setSelectedComponentId: { default: () => () => {} } },
  props: { type: String, label: String, required: Boolean, hidden: Boolean, componentId: String },
  data() {
    return {}
  },
  computed: {
    componentIcon() {
      switch (this.type) {
        case 'Container':
          return 'cubes'
        case 'PlainText':
        case 'PageTitle':
        case 'PageSubtitle':
        case 'PageHeadingLabel':
          return 'text'
        case 'RichText':
          return 'text-size'
        case 'CustomButton':
          return 'rectangle-wide'
        case 'MediaImage':
          return 'image'
        case 'MediaEmbed':
          return 'file-video'
        case 'InputBox':
          return 'italic'
        case 'OptionSelector':
          return 'boxes'
        case 'ProgressBar':
          return 'signal-3'
        case 'StripeCheckout':
          return 'credit-card'

        default:
          return 'cube'
      }
    },
  },
  watch: {},
  methods: {},
}
</script>
