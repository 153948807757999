<template>
  <div
    class="bg-white shadow-lg"
    :class="[
      padding,
      corners,
      {
        'overflow-hidden': hidden,
      },
    ]"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    flush: Boolean,
    small: Boolean,
    hidden: Boolean,
  },
  computed: {
    padding() {
      return this.flush ? 'p-0' : this.small ? 'p-4' : 'p-8'
    },
    corners() {
      // return this.small ? 'rounded-xl' : 'rounded-2xl'
      return 'rounded-xl'
    },
  },
}
</script>
