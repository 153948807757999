<template>
  <div class="PrevFooterButtonEditor">
    <a-space direction="vertical">
      <template v-if="location === 'options'">
        <EditorCheckbox _key="hide_if_no_nav_target" label="Hide button if no previous page" />
      </template>
    </a-space>
  </div>
</template>
<script>
export default {
  name: 'PrevFooterButtonEditor',
  props: { componentId: String, component: Object, location: String },
  methods: {},
}
</script>
