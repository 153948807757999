var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"BarChart relative",class:_vm.presetField},[_vm._l((_vm.chartData),function(ref){
var key = ref.key;
var icons = ref.icons;
var percent = ref.percent;
var count = ref.count;
return _c('router-link',{key:key,staticClass:"row",attrs:{"to":_vm.$route.path
        .split('/')
        .slice(0, -1)
        .join('/') +
        '/people' +
        (_vm.nameKey ? ("?" + _vm.nameKey + "=" + key) : '')}},[_c('div',{staticClass:"label overflow-hidden"},[_c('div',{staticClass:"bar",style:({ width: (percent + "%") })},[_c('Annotation',{attrs:{"id":(_vm.id + "-" + key)}})],1),_c('div',{staticClass:"label-text inline-block whitespace-nowrap overflow-hidden overflow-ellipsis",staticStyle:{"max-width":"calc(100%)"}},[(icons)?_c('div',{staticClass:"icon-container"},[(_vm.presetField === 'channels')?_c('SafeImage',{staticClass:"profile-picture",attrs:{"srcs":icons,"fallbackIcon":key === 'Unknown' ? 'ban' : 'paper-plane'}}):_vm._e()],1):_vm._e(),_vm._v(" "+_vm._s(key)+" ")])]),_c('div',{staticClass:"value",class:{ base: Array.isArray(_vm.rawData) && _vm.rawData.length > 0 }},[_vm._v(" "+_vm._s(count)+_vm._s(_vm.suffix)+" ")]),_c('div',{staticClass:"value hover",class:{ base: Array.isArray(_vm.rawData) && _vm.rawData.length > 0 }},[_vm._v(" "+_vm._s(_vm.rawDataByKey[key] ? ((_vm.rawDataByKey[key].contacts) + " / " + (_vm.rawDataByKey[key].count)) : count)+" ")])])}),(_vm.data.labels && _vm.chartData && _vm.chartData.length !== _vm.data.labels.length)?_c('div',{staticClass:"ShowMore-footer z-50 flex absolute inset-x-0 bottom-0 pt-4 pb-3 justify-center border-none cursor-pointer",on:{"click":_vm.showMore}},[_c('span',{staticClass:"block opacity-0"},[_vm._v(" Show More ")])]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }