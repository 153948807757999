import {
  getStartOfMonth,
  getStartOfWeek,
  getStartOfDay,
  toDateString,
} from '../../helpers/timeFilters'

export function valuesAreDates(arr) {
  return arr.every(d => isNaN(new Date(d).getTime()))
}

export function chartDate(dateStr, config) {
  const date = new Date(dateStr)
  if (isNaN(date.getTime())) return dateStr
  const format = config && config['date-interval']

  switch (format) {
    case 'year':
      return toDateString(dateStr, 'y', true, true)
    case 'month':
      return toDateString(dateStr, 'ym', true, true)

    default:
      return date.getFullYear() === new Date().getFullYear()
        ? toDateString(dateStr, 'dm', true, true)
        : toDateString(dateStr, 'dmy', true, true)
  }
}

function yearGrouping(data) {
  return Object.entries(data).reduce((acc, e) => {
    const [k, v] = e

    const year = k.split('-')[0]
    acc[year] = acc[year] || []
    acc[year].push(...v)

    return acc
  }, {})
}

function getTimezoneString() {
  return `GMT-${Math.round(new Date().getTimezoneOffset() / 60)}`
}

function weekGrouping(data) {
  const offset = getTimezoneString()
  return Object.entries(data).reduce((acc, e) => {
    const [k, v] = e
    let passedFirstData = false

    v.forEach((d, i) => {
      if (d > 0) passedFirstData = true
      else if (!passedFirstData) return
      const day = `${k}-${i + 1} ${offset}`
      const weekStart = getStartOfWeek(day)
      if (weekStart.getTime() > new Date().getTime()) return

      const weekEnd = new Date(weekStart.getTime() + 1000 * 60 * 60 * 24 * 6)

      const isThisYear =
        weekStart.getFullYear() === weekEnd.getFullYear() &&
        weekStart.getFullYear() === new Date().getFullYear()
      const dateFormat = isThisYear ? 'dm' : 'dmy'

      const replaceRegex = /[-/]/gi
      const weekStartString = toDateString(weekStart, dateFormat, true, true).replace(
        replaceRegex,
        ' '
      )
      const weekEndString = toDateString(weekEnd, dateFormat, true, true).replace(replaceRegex, ' ')
      const weekIdx = new Date(day).getDay()
      const time = `${weekStartString} - ${weekEndString}`
      acc[time] = acc[time] || Array(7).fill(0)
      acc[time][weekIdx] += d
      // acc[time].push(d)
    })

    /* For each day, get start of week then map to string */

    return acc
  }, {})
}

function addYearForSorting(start) {
  let str = start.split(' - ')[0]
  const seperator = ' '
  if (str.split(seperator).length !== 3) {
    str = `${str} ${new Date().getFullYear()}`
  }
  return str
}

export function sortEntriesByDate(entries) {
  // if (entries.every(e => e[0].includes(' - '))) {
  //   return entries.sort(
  //     (a, b) => new Date(addYearForSorting(a[0])) - new Date(addYearForSorting(b[0]))
  //   )
  // }

  return entries.sort(
    (a, b) => new Date(addYearForSorting(a[0])) - new Date(addYearForSorting(b[0]))
  )
}

function dayGrouping(data) {
  return sortEntriesByDate(Object.entries(data)).reduce((acc, e) => {
    const [k, v] = e
    let passedFirstData = false
    v.forEach((d, i) => {
      const day = `${k}-${i + 1}`
      const date = new Date(day).getTime()
      const hideYear = new Date(day).getFullYear() === new Date().getFullYear()
      if (date > new Date().getTime()) return
      if (d > 0) passedFirstData = true
      else if (!passedFirstData) return
      const dayString = toDateString(day, hideYear ? 'dm' : 'dmy', true, true)
      acc[dayString] = acc[dayString] || []
      acc[dayString].push(d)
    })
    return acc
  }, {})
}

export function combineDataToNumber(data) {
  return Object.values(data).reduce((acc, v) => {
    const sum = Array.isArray(v) ? v.reduce((acc2, va) => acc2 + va, 0) : v
    return acc + sum
  }, 0)
}

export function groupDataByTime(data, grouping) {
  switch (grouping) {
    case 'year':
      return yearGrouping(data)
    case 'day':
      return dayGrouping(data)
    case 'week':
      return weekGrouping(data)

    case 'month':
    default:
      return data
  }
}

export function limitToDateRange(data, range) {
  const [start, end] = range || []
  if (!start && !end) return data
  const startDate = new Date(start)
  const endDate = new Date(end || Date.now() + 24 * 3600 * 1000)

  return Object.entries(data).reduce((acc, e) => {
    const [yearMonth, dates] = e

    if (!Array.isArray(dates)) {
      if (dates && typeof dates === 'object') {
        const sub = limitToDateRange(dates, range)
        if (Object.keys(sub).length > 0) acc[yearMonth] = sub
        return acc
      }

      const timestamp = new Date(
        new Date(yearMonth).getTime() + new Date().getTimezoneOffset() * 60000
      )
      if (isNaN(timestamp.getTime())) acc[yearMonth] = dates
      else {
        const isValid =
          startDate.getTime() <= timestamp.getTime() + 60000 &&
          timestamp.getTime() < endDate.getTime()
        if (isValid) acc[yearMonth] = dates
      }
      return acc
    }

    const [year, month] = yearMonth.split('-')
    const newDates = dates.map((v, i) => {
      const timestamp = new Date(start)
      timestamp.setFullYear(year)
      timestamp.setMonth(month - 1)
      timestamp.setDate(i + 1)
      // const timestamp = new Date(`${yearMonth}-${i + 1}`)
      const timestampTime = timestamp.getTime() + 60000
      return startDate.getTime() <= timestampTime && timestampTime < endDate.getTime() ? v : 0
    })

    if (newDates.some(v => v !== 0)) acc[yearMonth] = newDates
    return acc
  }, {})
}

export function createDateRangeArray(config) {
  const range = config && config['date-range']
  if (!range) return []

  const day = 1000 * 24 * 3600
  const today = getStartOfDay(Date.now())
  const toStr = date => new Date(date).toISOString()

  switch (range) {
    case 'today':
      return [toStr(today)]
    case 'yesterday':
      return [toStr(today.getTime() - day), toStr(today)]
    case 'this-week':
      return [toStr(getStartOfWeek(today))]
    case 'this-month':
      return [toStr(getStartOfMonth(today))]
    case 'last-week': {
      const start = getStartOfWeek(today)
      return [toStr(getStartOfWeek(start.getTime() - day)), toStr(start)]
    }
    case 'last-month': {
      const start = getStartOfMonth(today)
      return [toStr(getStartOfMonth(start.getTime() - day)), toStr(start)]
    }
    case 'custom': {
      if (!config['custom-date']) return []
      const [start, end] = config['custom-date'].split('_TO_')
      if (!start && !end) return []
      else if (!end) return [toStr(start)]
      return [toStr(start), toStr(end)]
    }
    default: {
      return []
    }
  }
}
