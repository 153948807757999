<template>
  <div
    class="ColorSwitcher"
    :class="{ noColor }"
    :style="{ background: noColor ? 'none' : color }"
    @click="cycleColor"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    noColor: Boolean,
    color: String,
  },
  computed: {
    colors() {
      return ['#1eb7ff', '#33ae9a', '#f27212', '#ed1c24', '#ca8eff', '#ccc']
    },
  },
  methods: {
    cycleColor() {
      const index = this.colors.indexOf(this.color)
      const nexIndex = index < this.colors.length - 1 ? index + 1 : 0

      this.$emit('update', this.colors[nexIndex])
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/styles/_variables.scss';

.ColorSwitcher {
  flex: 0 0 auto;
  width: 12px;
  height: 12px;
  border-radius: 100px;
  background: #ccc;
  margin-right: 5px;
  color: white;
  font-size: 12px;

  &.noColor {
    color: var(--text);
    font-size: 13px;
  }
}
</style>
