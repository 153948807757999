<template>
  <div @mouseenter="onMouseEnter" @mouseleave="onMouseLeave">
    <a-checkbox
      :defaultChecked="val === undefined ? defaultTrue : val"
      :checked="val === undefined ? defaultTrue : val"
      :disabled="disabled"
      @change="val = $event.target.checked"
    >
      {{ title }}
    </a-checkbox>
  </div>
</template>

<script>
import EditorMixin from './EditorMixin'

export default {
  name: 'EditorCheckbox',
  mixins: [EditorMixin],
  props: {
    defaultTrue: Boolean,
    disabled: Boolean,
  },
}
</script>
