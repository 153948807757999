<template>
  <div class="CheckpointEditor">
    <!-- Checkpoints -->
    <EditorSelect isKey multiple :_key="key" :options="checkpointOptions" v-bind="{ label }" />
    <div class="flex flex-row">
      <!-- Do an isKey copy from EditorInput -->
      <a-input
        class="monospaced"
        v-model="customCheckpoint"
        placeholder="Add Custom Checkpoint"
        style="width:100%;"
        @keydown.enter="saveCheckpoint"
      />
      <a-button icon="save" @click="saveCheckpoint" />
    </div>
  </div>
</template>
<script>
import getCheckpointOptions from '@/components/form/editor/helpers/getCheckpointOptions'
export default {
  inject: ['_getOptions', '_getBasePath', '_updateData'],
  props: {
    parent: Object,
    form: Object,
    userData: Object,
    _key: String,
    jsxKey: String,
    label: String,
  },
  data() {
    return {
      customCheckpointValue: null,
    }
  },
  computed: {
    key() {
      return this.jsxKey || this._key || 'checkpoints'
    },
    customCheckpoint: {
      get() {
        return this.customCheckpointValue
      },
      set(v) {
        this.customCheckpointValue =
          v &&
          v
            .split(' ')
            .join('_')
            .toLowerCase()
      },
    },
    checkpoints: {
      get() {
        return this.parent[this.key] || []
      },
      set(v) {
        this._updateData(this._getBasePath(), this.key, v)
      },
    },
    checkpointOptions() {
      return getCheckpointOptions(this.form, true)
    },
  },
  methods: {
    saveCheckpoint() {
      if (this.customCheckpoint) {
        this.checkpoints = [...this.checkpoints, this.customCheckpoint]
        this.customCheckpoint = null
      }
    },
  },
}
</script>
