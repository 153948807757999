export default function pageLayouts() {
  return [
    undefined,
    [
      [1, 1, 2, 2],
      [1, 2, 2, 3],
    ],
    [
      [1, 1, 2, 2],
      [2, 1, 3, 2],
    ],
    [
      [1, 1, 2, 2],
      [2, 1, 3, 2],
      [3, 1, 4, 2],
    ],
    [
      [1, 1, 2, 2],
      [1, 2, 2, 3],
      [2, 1, 3, 3],
    ],
    [
      [1, 1, 2, 2],
      [2, 1, 3, 2],
      [1, 2, 3, 3],
    ],
    [
      [1, 1, 3, 2],
      [1, 2, 2, 3],
      [2, 2, 3, 3],
    ],
    [
      [1, 1, 2, 2],
      [2, 1, 3, 2],
      [3, 1, 4, 2],
      [4, 1, 5, 2],
    ],
    [
      [1, 1, 2, 2],
      [1, 2, 2, 3],
      [2, 1, 3, 2],
      [2, 2, 3, 3],
    ],
    [
      [1, 1, 2, 3],
      [2, 1, 3, 2],
      [2, 2, 3, 3],
    ],
    [
      [1, 1, 2, 2],
      [1, 2, 2, 3],
      [1, 3, 2, 4],
    ],
    [
      [1, 1, 2, 5],
      [2, 1, 3, 2],
      [2, 2, 3, 3],
      [2, 3, 3, 4],
      [2, 4, 3, 5],
    ],
    [
      [1, 1, 2, 3],
      [2, 1, 3, 3],
      [3, 1, 4, 3],
      [1, 3, 4, 4],
    ],
    [
      [1, 1, 2, 3],
      [2, 1, 3, 2],
      [2, 2, 3, 3],
      [3, 1, 4, 3],
    ],
    [
      [1, 1, 2, 3],
      [2, 1, 3, 2],
      [2, 2, 3, 3],
      [3, 1, 4, 3],
      [4, 1, 5, 3],
    ],
    [
      [1, 1, 2, 2],
      [1, 2, 2, 3],
      [2, 1, 3, 2],
      [2, 2, 3, 3],
      [3, 1, 4, 2],
      [3, 2, 4, 3],
    ],
    [
      [1, 1, 2, 2],
      [1, 2, 2, 3],
      [2, 1, 3, 2],
      [2, 2, 3, 3],
      [3, 1, 4, 2],
      [3, 2, 4, 3],
      [4, 1, 5, 2],
      [4, 2, 5, 3],
    ],
    [
      [1, 1, 2, 3],
      [1, 3, 2, 4],
      [2, 1, 3, 3],
      [2, 3, 3, 4],
      [3, 1, 4, 2],
      [3, 2, 4, 3],
      [4, 1, 5, 2],
      [4, 2, 5, 3],
      [3, 3, 5, 4],
    ],
    [
      [1, 1, 2, 5],
      [2, 1, 8, 2],
      [2, 2, 3, 3],
      [3, 2, 4, 3],
      [4, 2, 5, 3],
      [5, 2, 6, 3],
      [6, 2, 7, 3],
      [7, 2, 8, 3],
      [2, 3, 8, 4],
      [2, 4, 8, 5],
    ],
  ].map(l => (!l ? undefined : l.map(j => j.join('/')).join(',')))
}
