<template>
  <div
    class="ClickToScroll relative overflow-y-hidden"
    :class="{ 'overflow-y-scroll': scrollable }"
  >
    <slot></slot>
    <div
      v-if="!off && !scrollable"
      class="ClickToScroll-footer z-10 flex absolute inset-x-0 bottom-0 pt-4 pb-3 justify-center border-none cursor-pointer"
      @click="scrollable = true"
    >
      <span class="block opacity-0">
        Click to scroll
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    off: Boolean,
  },
  data() {
    return {
      scrollable: false,
    }
  },
}
</script>

<style scoped lang="scss">
.ClickToScroll {
  &:hover > .ClickToScroll-footer {
    // background-image: linear-gradient(to bottom, #ffffff00, #ffffffff, #ffffffff);
    &::before {
      opacity: 1;
    }
    > span {
      opacity: 1;
    }
  }
  .ClickToScroll-footer {
    background-image: linear-gradient(to bottom, #ffffff00, #ffffff00, #ffffffff);

    &:hover {
      color: var(--color-savvy);
    }

    &::before {
      position: absolute;
      content: '';
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-image: linear-gradient(to bottom, #ffffff00, #ffffffff, #ffffffff);
      z-index: -1;
      transition: opacity 0.3s linear;
      opacity: 0;

      > span {
        transition: opacity 0.3s;
      }
    }
  }
}
</style>
