var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"SavvyFunnelCard",class:_vm.presetField},[_c('table',{staticStyle:{"height":"100%"}},[_c('tr',_vm._l((_vm.chartData),function(ref){
var key = ref.key;
var percent = ref.percent;
var percentFirst = ref.percentFirst;
var count = ref.count;
var stageSpend = ref.stageSpend;
return _c('td',{key:key,style:({ width: ((100 / _vm.chartData.length) + "%") })},[_c('div',{staticClass:"label"},[_c('div',{staticClass:"bar",style:({ flexBasis: ("calc(" + percent + "% - 14px)") })},[_c('Annotation',{attrs:{"id":(_vm.id + "-" + key)}}),_c('div',{staticClass:"value",class:{ canHide: percentFirst }},[_vm._v(_vm._s(count))]),(percentFirst)?_c('div',{staticClass:"value value-hover"},[_vm._v(" "+_vm._s(percentFirst.toFixed(2))+" % ")]):_vm._e(),(stageSpend)?_c('div',{staticClass:"value value-hover spend"},[_vm._v("Cost: $"+_vm._s(stageSpend))]):_vm._e()],1)])])}),0),_c('tr',{staticStyle:{"height":"20px"}},_vm._l((_vm.chartData),function(ref){
var key = ref.key;
var keyText = ref.keyText;
return _c('td',{key:key,staticClass:"label-text"},[(keyText !== key)?[_c('a-tooltip',[_c('template',{slot:"title"},[_vm._v(_vm._s(key))]),_vm._v(" "+_vm._s(keyText)+" ")],2)]:[_vm._v(_vm._s(key)+" ")]],2)}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }