<template>
  <div class="FlowResults">
    <div
      ref="container"
      :key="divKey"
      class="widget-box"
      style="height: calc(100vh - 100px);"
    ></div>
    <a-button v-if="!isAllEntries" style="margin: 10px;" @click="loadMore"
      >Load {{ numPerPage }} more results</a-button
    >
  </div>
</template>

<script>
/* global dhx */

import Firebase from '@firebase/app'
import '@firebase/firestore'
import getButtons from './helpers/getButtons'
import { mapGetters, mapState } from 'vuex'
import getCheckpointOptions from '@/components/form/editor/helpers/getCheckpointOptions'
import { startCase } from 'lodash'
import axios from 'axios'

export default {
  name: 'FlowResults',
  inject: ['_register'],
  props: {
    id: String,
    form: Object,
    updateNum: Number,
    isActive: Boolean,
  },
  data() {
    return {
      // filters: {},
      entries: [],
      numPagesShowing: 0,
      numPerPage: 100,
      isAllEntries: false,
      downloadFile: null,
      divKey: '',
    }
  },
  computed: {
    ...mapState(['showTestData']),
    ...mapGetters(['users', 'user']),
    formTitle() {
      return this.form && (this.form.name || this.form.title)
    },
    filters: {
      get() {
        return this.$store.state.submissionFilters
      },
      set(v) {
        this.$store.commit('setSubmissionFilters', v)
      },
    },
    filterOptions() {
      return [
        {
          key: 'checkpoints',
          label: 'Checkpoints',
          options: [
            // { label: 'None', key: '__none' },
            ...getCheckpointOptions(this.form, false, true).map(c => ({
              label: startCase(c),
              key: c,
            })),
          ],
        },
        {
          key: 'pages_viewed',
          label: 'Pages Viewed',
          options: this.form.pages.map(p => ({ key: p.key, label: startCase(p.key) })),
        },
      ]
    },
    options() {
      return ((this.form && this.form.pages) || [])
        .reduce(
          (options, page) =>
            options.concat(
              page.components.map(c => {
                const { id, key, type, multiple } = c
                return {
                  key: key || id,
                  type,
                  options: getButtons(c).map(b => b.text) || [],
                  multiple,
                }
              })
            ),
          []
        )
        .concat(
          ((this.form && this.form.computedFields) || []).map(({ id, key }) => ({
            key: key || id,
            type: 'computed',
          }))
        )
        .concat(makeArray(this.form.url_keys).map(key => ({ key })))
        .concat(makeArray(this.form.registered_keys).map(key => ({ key })))
        .concat(makeArray(this.form.embed_code_keys).map(key => ({ key })))
    },
    data() {
      return this.entries
        .map(e => ({
          key: e.id,
          ...e,
          updatedAt: e.updatedAt && e.updatedAt.setToServerValue ? e.createdAt : e.updatedAt,
        }))
        .filter(e => this.keys.some(key => e[key] !== undefined && e[key] !== null))
        .filter(e => this.showTestData || ![true, 'true'].includes(e.is_test))
    },
    keys() {
      const seen = new Set(this.options.map(o => o.key))
      seen.add('_prefilled')
      const keys = Array.from(seen)
        .filter(k => this.entries.some(e => e[k] !== undefined && e[k] !== null))
        .concat(['has_submitted', 'is_test', 'id'])
      return keys
    },
    sheetData() {
      if (!this.data || !this.data.length || !this.keys || !this.keys.length) return null

      return [null]
        .concat(
          this.data.slice().sort((a, b) => {
            return (
              ((b.updatedAt && b.updatedAt.toDate && b.updatedAt.toDate()) || 0) -
              ((a.updatedAt && a.updatedAt.toDate && a.updatedAt.toDate()) || 0)
            )
          })
        )
        .map((row, index) =>
          [
            'createdAt',
            'updatedAt',
            '_checkpoints',
            'current_page_key',
            'current_page_id',
            'current_page_index',
            'first_version',
            'last_version',
          ]
            .concat(this.keys)
            .map(key => (index === 0 ? key : getValText(row[key])))
        )
    },
    sheetDataSliced() {
      if (!this.sheetData) return null

      return this.sheetData.slice(0, this.numPagesShowing * this.numPerPage)
    },
    spreadsheetData() {
      if (!this.sheetDataSliced) return null

      return this.sheetDataSliced.reduce((acc, row, index) => {
        const rowCells = row.map((value, i) => ({
          cell: toCell(i, index),
          value,
        }))

        return acc.concat(rowCells)
      }, [])
    },
  },
  watch: {
    filters: {
      async handler(f) {
        this.resetEntry()
        this.divKey = JSON.stringify(f)
        await this.$nextTick()
        this.spreadsheet = null
        this.getEntries()
      },
    },
    spreadsheetData: {
      handler() {
        this.initSpreadsheet()
        this.downloadFile = {
          file: this.getDownloadData(),
          filename: `${JSON.parse((this.form && this.form.form) || '{}').title ||
            'Flow'} Submissions.csv`,
        }

        // this.deregisterDownload = this._register('download', this.downloadFile)
      },
      deep: true,
      immediate: true,
    },
    updateNum() {
      this.getEntries()
    },
  },
  created() {
    this.getEntries()

    this.deregisterReload = this._register('reload', this.getEntries)
  },
  mounted() {
    this.initSpreadsheet()
    if (window.CommandBar) {
      window.CommandBar.addContext({ flowResultFilters: this.filterOptions })
      window.CommandBar.addCallback('addResultFilter', args => {
        const { name, value } = args
        const isEmpty = value.key === '__none' //|| value.includes('__none')
        this.filters = isEmpty
          ? {}
          : { [name.key]: Array.isArray(value) ? value.map(v => v.key) : value.key }
      })
    }
    this.deregisterDownload = this._register('download', this.downloadCurrentCsv)
  },
  beforeDestroy() {
    delete this.spreadsheet
    this.deregisterReload()
    this.deregisterDownload()
    if (window.CommandBar) window.CommandBar.removeCallback('addResultFilter')
  },
  methods: {
    resetEntry() {
      this.entries = []
      this.numPagesShowing = 0
      this.isAllEntries = false
      this.lastDocumentSnapshot = false
    },
    async getEntries() {
      if (this.isAllEntries) return
      let query = Firebase.firestore()
        .collection(`forms/${this.id}/entries`)
        .orderBy('createdAt', 'desc')
        .limit(this.numPerPage)

      if (this.filters) query = this.applyFilters(query)

      if (this.lastDocumentSnapshot) query = query.startAfter(this.lastDocumentSnapshot)

      const snap = await query.get()
      const entries = [...(this.entries || [])]
      const seenEntries = new Set(entries.map(e => e.id))
      snap.forEach(doc => {
        const seen = seenEntries.has(doc.id)
        if (seen) {
          const index = entries.findIndex(e => e.id === doc.id)
          if (index === -1) return
          entries[index] = {
            id: doc.id,
            ...(doc.data()._prefilled ? doc.data()._prefilled : {}),
            ...doc.data(),
            ...getBlockedFieldKeys(this.id),
          }
        } else {
          seenEntries.add(doc.id)
          entries.push({
            id: doc.id,
            ...(doc.data()._prefilled ? doc.data()._prefilled : {}),
            ...doc.data(),
            ...getBlockedFieldKeys(this.id),
          })
        }
      })
      this.lastDocumentSnapshot = snap.empty ? false : snap.docs[snap.size - 1]
      if (snap.empty) this.isAllEntries = true
      else this.numPagesShowing += 1

      this.entries = entries
      this.initSpreadsheet()
    },
    initSpreadsheet() {
      if (!this.spreadsheetData) return

      if (!this.spreadsheet)
        this.spreadsheet = new dhx.Spreadsheet(this.$refs.container, {
          rowsCount: this.sheetDataSliced.length,
          colsCount: this.sheetDataSliced[0].length,
          toolbar: [],
          editLine: false,
          menu: false,
          // readonly: true,
        })

      this.spreadsheet.events.on('beforeEditStart', function() {
        return false
      })

      this.spreadsheet.events.on('beforeValueChange', function() {
        return false
      })

      this.spreadsheet.events.on('beforeStyleChange', function() {
        return false
      })

      this.spreadsheet.toolbar.data.remove('undo')
      this.spreadsheet.toolbar.data.remove('redo')
      this.spreadsheet.toolbar.data.remove('color')
      this.spreadsheet.toolbar.data.remove('background')
      this.spreadsheet.toolbar.data.remove('font-weight-bold')
      this.spreadsheet.toolbar.data.remove('font-style-italic')
      this.spreadsheet.toolbar.data.remove('text-decoration-underline')
      this.spreadsheet.toolbar.data.remove('align-left')
      this.spreadsheet.toolbar.data.remove('align-center')
      this.spreadsheet.toolbar.data.remove('align-right')
      this.spreadsheet.toolbar.data.remove('format')
      this.spreadsheet.toolbar.data.remove('help')
      this.spreadsheet.toolbar.data.remove('lock')
      this.spreadsheet.toolbar.data.remove('clear-group')
      this.spreadsheet.toolbar.data.remove('clear-value')
      this.spreadsheet.toolbar.data.remove('clear-styles')
      this.spreadsheet.toolbar.data.remove('clear-all')
      this.spreadsheet.toolbar.data.remove('add-row')
      this.spreadsheet.toolbar.data.remove('remove-row')
      this.spreadsheet.toolbar.data.remove('add-col')
      this.spreadsheet.toolbar.data.remove('remove-col')
      this.spreadsheet.toolbar.data.remove('export')
      this.spreadsheet.toolbar.data.remove('export-xlsx')
      this.spreadsheet.toolbar.data.remove('import')

      this.spreadsheet.parse(this.spreadsheetData)
    },
    getDownloadData() {
      if (!this.isActive || !this.sheetData) return

      const csvContent =
        'data:text/csv;charset=utf-8,' +
        this.sheetData
          .map(e =>
            e
              .map(cell =>
                isValidDate(cell)
                  ? new Date(cell).toISOString()
                  : cell
                  ? `"${typeof cell === 'string' ? cell.replace(/"/g, '""') : cell}"`
                  : cell
              )
              .join(',')
          )
          .join('\n')

      var encodedUri = encodeURI(csvContent)
      // window.open(encodedUri, '_blank')

      return encodedUri
    },
    loadMore() {
      this.getEntries()
      // this.numPagesShowing++
    },
    applyFilters(query) {
      const filters = Object.entries(this.filters)
      if (filters.length === 0) return query
      let newQuery = query
      filters.forEach(([k, v]) => {
        const key = filterKey(k)
        const op = Array.isArray(v) ? 'array-contains-any' : 'array-contains'
        newQuery = newQuery.where(key, op, v)
      })
      return newQuery
    },
    async requestEmail(useFilter) {
      const email = this.user && this.user.auth && this.user.auth.email
      const data = {
        limit: this.limit || 100,
        showTestData: this.showTestData,
        formTitle: this.formTitle,
        formId: this.form.id,
        filters: useFilter ? this.filters : {},
        options: this.options,
        to: email,
      }
      axios.post(`https://us-central1-savvy-app-live.cloudfunctions.net/requestEntryData`, data)
    },
    async downloadCurrentCsv(type, useFilter) {
      const file =
        type === 'large'
          ? await this.downloadLargeCsv(useFilter)
          : type === 'request'
          ? this.requestEmail(useFilter)
          : this.downloadFile
      if (type === 'request') return
      const a = document.createElement('a')
      a.href = file.file
      a.download = file.filename
      a.click()
    },
    async downloadLargeCsv(useFilter, limit = 10000) {
      let query = Firebase.firestore()
        .collection(`forms/${this.id}/entries`)
        .orderBy('createdAt', 'desc')
        .limit(limit)

      if (useFilter && this.filters) query = this.applyFilters(query)
      const snap = await query.get()
      const data = snap.docs.map(doc => ({
        id: doc.id,
        ...(doc.data()._prefilled ? doc.data()._prefilled : {}),
        ...doc.data(),
        ...getBlockedFieldKeys(this.id),
      }))
      const file = prepareData(data, this.options, {
        showTestData: this.showTestData,
        flowTitle: this.formTitle,
      })
      // window.open(file.file, '_self')
      return file
    },
  },
}

function filterKey(key) {
  switch (key) {
    case 'checkpoints':
      return '_checkpoints'
    case 'pages_viewed':
      return '_pages_viewed'

    default:
      return key
  }
}

function getValText(value) {
  if (Array.isArray(value)) return value.join(', ')

  if (value && value.toDate) return value.toDate()

  return value
}

function toCell(colIndex, rowIndex) {
  const colChar = columnToLetter(colIndex + 1)
  const rowNum = rowIndex + 1

  return `${colChar}${rowNum}`
}

function columnToLetter(column) {
  var temp,
    letter = ''
  while (column > 0) {
    temp = (column - 1) % 26
    letter = String.fromCharCode(temp + 65) + letter
    column = (column - temp - 1) / 26
  }
  return letter
}

function isValidDate(d) {
  return d instanceof Date && !isNaN(d)
}

function prepareData(entries, options, context = {}) {
  const seen = new Set(options.map(o => o.key))
  seen.add('_prefilled')
  const keys = Array.from(seen)
    .filter(k => entries.some(e => e[k] !== undefined && e[k] !== null))
    .concat(['has_submitted', 'is_test', 'id'])
  const data = entries
    .map(e => ({
      key: e.id,
      ...e,
      updatedAt: e.updatedAt && e.updatedAt.setToServerValue ? e.createdAt : e.updatedAt,
    }))
    .filter(e => keys.some(key => e[key] !== undefined && e[key] !== null))
    .filter(e => context.showTestData || ![true, 'true'].includes(e.is_test))
  const sheetData = [null]
    .concat(
      data.slice().sort((a, b) => {
        return (
          ((b.updatedAt && b.updatedAt.toDate && b.updatedAt.toDate()) || 0) -
          ((a.updatedAt && a.updatedAt.toDate && a.updatedAt.toDate()) || 0)
        )
      })
    )
    .map((row, index) =>
      [
        'createdAt',
        'updatedAt',
        '_checkpoints',
        'current_page_key',
        'current_page_id',
        'current_page_index',
        'first_version',
        'last_version',
      ]
        .concat(keys)
        .map(key => (index === 0 ? key : getValText(row[key])))
    )

  const csvLines = sheetData.map(e =>
    e
      .map(cell =>
        isValidDate(cell)
          ? new Date(cell).toISOString()
          : cell
          ? `"${typeof cell === 'string' ? cell.replace(/"/g, '""').replace(/#/g, '') : cell}"`
          : cell
      )
      .join(',')
  )

  const csvContent = 'data:text/csv;charset=utf-8,' + csvLines.join('\n')

  const file = encodeURI(csvContent)
  // window.open(encodedUri, '_blank')
  return { file, filename: `${context.flowTitle.split(' ').join('_')}_Submissions.csv` }
}

function getBlockedFieldKeys(flowId) {
  return flowId === 'AkRNfkElpmFg4a9ISqZf'
    ? {
        universities_list: null,
        undergrad_major_list: null,
        grad_major_list: null,
        data_test: null,
      }
    : {}
}

function makeArray(x) {
  if (typeof x === 'string') return x.split(/,|\s/)
  if (Array.isArray(x)) return x
  return []
}
</script>

<style lang="scss">
@import '@/styles/_variables.scss';

.FlowResults {
  .ant-pagination.ant-table-pagination {
    margin: 16px;
  }
  .ant-tag {
    margin-top: 4px;
    margin-bottom: 4px;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 8px 16px;
  }

  .toolbar_wrapper {
    display: none;
  }
}
</style>
