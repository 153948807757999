import getButtons from '../../helpers/getButtons'
import { repeatPages } from '../../helpers/repeatPages'

export default function getOptions(form, userData) {
  const tabGroups = new Set()
  const mapper = c => {
    const { id, key, type, multiple, tags, tab_group } = c
    if (tab_group) tabGroups.add(tab_group)
    const options = getButtons(c).map(b => b.key || b.text) || []
    const obj = { key: key || id, type, options, multiple, tags }
    switch (type) {
      case 'CustomAuth': {
        obj.childOptions = [{ key: 'email', type, tags, parentKey: key || id }]
        break
      }
      case 'Container': {
        obj.containerType = c.container_type
        break
      }
      case 'OptionButtons':
      case 'OptionSelector': {
        if (c.store_full_option_data)
          obj.childOptions = [{ key: c.store_full_option_data, type, tags, parentKey: key || id }]
        break
      }
      default:
        break
    }
    return obj
  }
  const options = repeatPages(form.pages || [], userData)
    .reduce((options, page) => {
      if (page.layoutBlocks)
        page.layoutBlocks.forEach(b => b && b.tab_group && tabGroups.add(b.tab_group))

      return options.concat(page.components.map(mapper))
    }, [])
    .concat(
      (form.computedFields || []).map(({ id, key, tags }) => ({
        key: key || id,
        type: 'computed',
        tags,
      }))
    )
    .concat(
      (form.experiments || []).map(({ id, key, variants }) => ({
        key: key || id,
        type: 'computed',
        options: variants.map(v => v.key),
      }))
    )
    .concat(Array.from(tabGroups).map(t => ({ key: t, type: 'tab-group', tags: [] })))
    .filterExists()

  options.push(
    ...[
      '_source_data',
      'current_page_index',
      'current_page_key',
      'highest_page_reached_index',
      'current_total_pages',
      'is_test',
      'location_data',
      '_pages_viewed',
    ].map(o => ({ key: o, type: 'computed', tags: [] }))
  )

  if (form.components) options.push(...form.components.map(mapper))
  if (form.registered_keys)
    options.push(
      ...form.registered_keys
        .replace(/,/gi, '\n')
        .split('\n')
        .map(t => ({ key: t.trim(), type: 'computed', tags: [] }))
    )
  const seenKeys = new Set(options.map(o => o.key))
  return options.reduce((acc, o) => {
    acc.push(o)
    if (Array.isArray(o.childOptions)) {
      o.childOptions.forEach(op => {
        if (!seenKeys.has(op.key)) {
          acc.push(op)
          seenKeys.add(op.key)
        }
      })
    }
    return acc
  }, [])
}
