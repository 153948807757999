<template>
  <div class="BarChart relative" :class="presetField">
    <!-- <div class="header">
      <Icon :name="icon" />
      {{ name }}
    </div> -->
    <!-- <div class="row row-header">
      <div class="label">{{ name }}</div>
      <div class="value">Visitors</div>
    </div> -->
    <router-link
      class="row"
      v-for="{ key, icons, percent, count } in chartData"
      :key="key"
      :to="
        $route.path
          .split('/')
          .slice(0, -1)
          .join('/') +
          '/people' +
          (nameKey ? `?${nameKey}=${key}` : '')
      "
    >
      <div class="label overflow-hidden">
        <div class="bar" :style="{ width: `${percent}%` }">
          <Annotation :id="`${id}-${key}`" />
        </div>
        <div
          class="label-text inline-block whitespace-nowrap overflow-hidden overflow-ellipsis"
          style="max-width: calc(100%)"
        >
          <div class="icon-container" v-if="icons">
            <SafeImage
              v-if="presetField === 'channels'"
              class="profile-picture"
              :srcs="icons"
              :fallbackIcon="key === 'Unknown' ? 'ban' : 'paper-plane'"
            />
          </div>
          {{ key }}
        </div>
      </div>
      <div class="value" :class="{ base: Array.isArray(rawData) && rawData.length > 0 }">
        {{ count }}{{ suffix }}
      </div>
      <div class="value hover" :class="{ base: Array.isArray(rawData) && rawData.length > 0 }">
        {{
          rawDataByKey[key] ? `${rawDataByKey[key].contacts} / ${rawDataByKey[key].count}` : count
        }}
      </div>
    </router-link>
    <div
      v-if="data.labels && chartData && chartData.length !== data.labels.length"
      class="ShowMore-footer z-50 flex absolute inset-x-0 bottom-0 pt-4 pb-3 justify-center border-none cursor-pointer"
      @click="showMore"
    >
      <span class="block opacity-0">
        Show More
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: String,
    preset: String,
    name: String,
    data: Object,
    rawData: [Array, Object],
    colors: [Array, null],
    icon: String,
    config: Object,
    suffix: String,
  },
  data() {
    return { w: 0, h: 0, hovering: false, hoverPosition: null, limitPerPage: 20, pages: 1 }
  },
  computed: {
    presetField() {
      return (this.config && this.config[`${this.config.service}-breakdown`]) || this.preset
    },
    rawDataByKey() {
      return Array.isArray(this.rawData)
        ? this.rawData.reduce((acc, d) => {
            acc[d.key] = d
            return acc
          }, {})
        : {}
    },
    chartData() {
      if (!this.data || !this.data.labels || !this.data.datasets || this.data.datasets.length === 0)
        return

      const data = this.data.labels
        .map((label, i) => ({
          key: label,
          count: this.data.datasets[0].data[i],
        }))
        .sort((a, b) => b.count - a.count)
      const max = Math.max(...data.map(row => row.count))
      const maxArrayLength = this.limitPerPage * this.pages
      const dataArray = data.slice(0, maxArrayLength)

      return dataArray.map(row => {
        const values = (row.values && row.values[0]) || {}
        return {
          ...row,
          key:
            row.key === '__null' || row.key === 'null-null'
              ? 'Unknown'
              : this.presetField === 'channels'
              ? `${values.channel}${values.medium !== 'null' ? ` (${values.medium})` : ''}`
              : row.key,
          percent: (100 * row.count) / max,
          icons: this.presetField === 'channels' ? this.getIcons(row.domains) : null,
        }
      })
    },
    nameKey() {
      switch (this.presetField) {
        case 'pages':
          return 'page'
        case 'channels':
          return 'channel'
        case 'continents':
          return 'continent'
        case 'countries':
          return 'country'
        case 'regions':
          return 'region'
        case 'cities':
          return 'city'
        default:
          return null
      }
    },
  },
  watch: {
    colors() {
      this.setColors()
    },
  },
  mounted() {
    this.setColors()
  },
  methods: {
    showMore() {
      this.pages += 1
    },
    setColors() {
      if (!this.$el || !this.$el.style) return

      if (this.colors) this.$el.style.setProperty('--bar-color', this.colors[0])
      if (this.colors) this.$el.style.setProperty('--bar-background', this.colors[0] + '44')
      if (this.colors) this.$el.style.setProperty('--bar-background-faded', this.colors[0] + '11')
      if (this.colors) this.$el.style.setProperty('--bar-background-strong', this.colors[0] + '66')
    },
    getIcons(domains) {
      if (!domains || !domains.length) return []

      return domains
        .map(
          d =>
            d &&
            d
              .split('.')
              .slice(-2)
              .join('.')
              .split('.android')
              .join('')
        )
        .map(d => `https://logo.clearbit.com/${d}`)
        .reduce((acc, url) => acc.concat([url, `${url}.com`]), [])
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/_variables.scss';
.BarChart {
  --bar-color: #555;
  --bar-background: #55555544;
  --bar-background-faded: #55555511;
  --bar-background-strong: #55555566;

  .header {
    @include flex('a-center');
    font-size: 1.2em;
    text-transform: capitalize;
    margin: 0.25em 0 0.75em;

    .Icon {
      font-size: 0.9em;
      margin-right: 0.5em;
      margin-bottom: 0;
    }
  }
  &:hover > .ShowMore-footer {
    // background-image: linear-gradient(to bottom, #ffffff00, #ffffffff, #ffffffff);
    &::before {
      opacity: 1;
    }
    > span {
      opacity: 1;
    }
  }
  .ShowMore-footer {
    background-image: linear-gradient(to bottom, #ffffff00, #ffffff00, #ffffffff);

    &:hover {
      color: var(--color-savvy);
    }

    &::before {
      position: absolute;
      content: '';
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-image: linear-gradient(to bottom, #ffffff00, #ffffffff, #ffffffff);
      z-index: -1;
      transition: opacity 0.3s linear;
      opacity: 0;

      > span {
        transition: opacity 0.3s;
      }
    }
  }

  &.channels {
    .label {
      text-transform: capitalize;
    }
  }

  .row {
    display: flex;
    border-radius: 2px;
    margin: 0.375em 0;
    margin-right: -0.5em;
    padding-right: 0.5em;
    text-decoration: none;
    font-weight: normal;
    color: inherit;

    .value.hover {
      display: none;
    }

    &:hover {
      .value.base {
        display: none;
        &.hover {
          display: initial;
        }
      }
    }

    &.row-header {
      font-weight: bold;
      text-transform: capitalize;

      > div {
        padding: 0;
      }
    }
    &:not(.row-header) {
      cursor: pointer;

      &:hover {
        background: rgba(0, 0, 0, 0.025);

        > .label > .bar {
          background: var(--bar-background-strong);
        }
        .value {
          color: var(--bar-color);
        }
      }
    }
    &.faded {
      .bar {
        background: var(--bar-background-faded);
      }
      .label {
        opacity: 0.5;
      }
    }

    // @for $i from 1 through 20 {
    //   &:nth-child(#{$i + 1}) .bar {
    //     opacity: 1 - 0.08 * $i;
    //   }
    // }

    > div {
      padding: 0;
    }

    .label {
      flex: 1;
      position: relative;
      padding-left: 0.75em;
      padding-right: 0.75em;

      &:hover .Annotation {
        display: flex;
      }

      .bar {
        z-index: 1;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        border-radius: 2px;
        background: var(--bar-background);
      }
      .label-text {
        // @include flex('a-center');
        position: relative;
        z-index: 2;
        margin-top: 3px;

        .icon-container {
          width: 25px;
          height: 20px;

          img {
            height: 20px;
            border-radius: 3px;
          }
        }
      }
    }

    .value {
      box-sizing: border-box;
      flex: 0 0 auto;
      width: 15%;
      min-width: 4em;
      text-align: right;
    }
  }
}
</style>
