<template>
  <div
    class="EditableText is-picker-on cursor-text"
    :class="{ isEditable, editing, isSingleLine, isPlaceholder }"
    @click="setEditing"
    @mousedown="onMouseDown"
  >
    <WindowEvent
      v-if="isEditable && mousedown"
      event-name="mousemove"
      @on-trigger="onMouseMove($event)"
    />
    <WindowEvent v-if="isEditable" event-name="mouseup" @on-trigger="onMouseUp($event)" />
    <slot name="input" v-if="customInput && editing" v-bind:value="text" />
    <slot v-else-if="customComponent && !editing && text" v-bind:value="text" />
    <div
      v-else
      class="input is-picker-on cursor-text"
      ref="input"
      :contenteditable="editing"
      @keyup.stop.exact
      @keyup.prevent.exact
      @keydown.enter="enterDown"
      @keyup.exact.enter="enterUp"
      @keyup.enter.shift="shiftEnterDown"
    >
      <!-- <Icon v-if="icon" :name="icon" class="icon-left" /> -->
      <p v-for="(textLine, i) in splitText" :key="`${textLine}${i}`">
        {{ textLine }}
      </p>
    </div>
  </div>
</template>

<script>
import WindowEvent from '../logic/WindowEvent.vue'

export default {
  components: { WindowEvent },
  inject: { _isEditing: { default: () => () => false } },
  props: {
    initialText: { type: String, default: '' },
    textArea: Boolean,
    isEditable: { type: Boolean, default: true },
    placeholder: { type: String, default: 'Text goes here' },
    // icon: String,
    multiple: { type: Boolean, default: false },
    deleteOnEmpty: Boolean,
    customComponent: Boolean,
    customInput: Boolean,
  },
  data() {
    return {
      editing: false,
      mousedown: false,
      dragging: false,
      text: null,
      firebaseUnsubscribe: null,
    }
  },
  computed: {
    splitText() {
      const text =
        this.text !== undefined ? this.text : this.initialText !== undefined && this.initialText

      return text && text.trim().length ? text.split('\n') : [this.placeholder]
    },
    isSingleLine() {
      return this.splitText.length === 1
    },
    isPlaceholder() {
      return !this.text || !this.text.trim().length
    },
  },
  watch: {
    initialText: {
      handler(v) {
        this.text = v
      },
      immediate: true,
    },
    async editing(editing) {
      if (editing === true) {
        // Focus contenteditable text
        this.$nextTick(() => {
          this.$refs.input.focus()

          if (this.isPlaceholder) {
            document.execCommand('selectAll', false, null)
          }
        })
      } else {
        // Update parent with text
        // const newText = this.isPlaceholder ? null : this.$refs.input.innerText
        let newText =
          this.isPlaceholder && this.$refs.input.innerText === this.placeholder
            ? null
            : this.$refs.input.innerText

        newText = newText ? newText.replace(/\n+$/, '') : newText

        if (this.deleteOnEmpty && !newText) newText = null

        if (newText !== this.initialText) {
          this.$emit('update-text', newText || undefined)
        }
      }
    },
  },
  methods: {
    changeMode() {
      this.editing = !this.editing
    },
    setEditing(event) {
      if (!this.isEditable) return

      if (event) preventAndStop(event)
      this.editing = true
    },
    enterDown(event) {
      preventAndStop(event)
    },
    enterUp(event) {
      preventAndStop(event)

      this.editing = false
    },
    shiftEnterDown(event) {
      if (this.multiple) {
        preventAndStop(event)
        this.text = this.text + '\n' + 'newline'
      } else this.enterUp(event)
    },
    onMouseDown() {
      this.mousedown = true
    },
    onMouseMove() {
      if (this.mousedown) {
        this.dragging = true
      }
    },
    onMouseUp() {
      if (this.dragging && this.isEditable) {
        this.editing = true
      } else {
        this.editing = false
      }

      this.mousedown = false
      this.dragging = false
    },
  },
}

function preventAndStop(event) {
  event.preventDefault()
  event.stopPropagation()
}
</script>

<style lang="scss" scoped>
@import './styles/EditableText.scss';
</style>
