<template>
  <a-tooltip>
    <template slot="title">
      <template v-if="loading">{{ percentUploaded }}% Uploaded </template>
      <template v-else>{{ tooltip ? tooltip : 'Click or drag into this box to upload' }}</template>
    </template>
    <UploadDragger name="file" :showUploadList="false" v-bind="{ customRequest }">
      <span
        v-if="!hideIcon"
        class="icon fas"
        :class="loading ? 'fa-spinner fa-spin' : 'fa-upload'"
      />
      <slot />
    </UploadDragger>
  </a-tooltip>
</template>

<script>
import Reference from 'firebase-storage-lite'
import { Upload } from 'ant-design-vue'

export default {
  name: 'MediaImageEditor',
  components: {
    UploadDragger: Upload.Dragger,
  },
  props: {
    hideIcon: Boolean,
    tooltip: String,
  },
  data() {
    return {
      loading: false,
      percentUploaded: null,
    }
  },
  methods: {
    async customRequest({ file, onSuccess }) {
      this.loading = true
      this.percentUploaded = 0

      const ref = new Reference(
        `gs://savvy-flow-assets/${file.name.split('.')[0]}_${('_' + Math.random()).slice(
          3
        )}.${file.name
          .split('.')
          .slice(1)
          .join('.')}`
      )

      const upload = ref.put(file) // returns an instance of UploadTask.

      // Will log the metadata when the upload is finished.

      // Handle errors.
      upload.catch(e => {
        console.error('error!', e)
      })

      // Will be run when the promise resolves.
      upload.finally(() => {
        onSuccess()
        ref.getDownloadURL().then(url => {
          this.loading = false
          this.percentUploaded = null
          this.$emit('update', url)
        })
      })

      for await (const { offset, total } of upload) {
        this.percentUploaded = parseInt((offset / total) * 100)
      }
    },
  },
}
</script>

<style lang="scss">
.ant-upload.ant-upload-drag .ant-upload {
  padding: 6px 8px 5px;
}
</style>
