var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{on:{"mouseenter":_vm.onMouseEnter,"mouseleave":_vm.onMouseLeave}},[(!_vm.noLabel)?_c('EditorLabel',{attrs:{"label":_vm.title}}):_vm._e(),_c('div',{staticClass:"flex gap-1"},[_c('a-select',{class:{ monospaced: _vm.isKey },style:(_vm.allowComputed ? "width: 90%;" : "width: 100%;"),attrs:{"mode":_vm.mode,"placeholder":_vm.placeholder || 'None',"disabled":_vm.disabled,"value":_vm.val === undefined ? _vm.defaultVal : _vm.val},on:{"change":function($event){_vm.val = $event}}},[(_vm.hasGroups)?_vm._l((_vm.groups),function(group){return _c('a-select-opt-group',{key:group},_vm._l((_vm.fullOptions.filter(
              function (o) { return o.group === group || (!o.group && group === 'other'); }
            )),function(ref){
            var key = ref.key;
            var label = ref.label;
return _c('a-select-option',{key:key,attrs:{"value":key}},[_vm._v(" "+_vm._s(_vm.isKey ? label || key : _vm.universalIdToProper(label || key))+" ")])}),1)}):_vm._l((_vm.fullOptions),function(ref){
            var key = ref.key;
            var label = ref.label;
return _c('a-select-option',{key:key,attrs:{"value":key}},[_vm._v(" "+_vm._s(_vm.isKey ? label || key : _vm.universalIdToProper(label || key))+" ")])})],2),(_vm.allowComputed)?_c('a-tooltip',{attrs:{"slot":"suffix","placement":"right","title":"Insert template values"},slot:"suffix"},[_c('a-dropdown',{attrs:{"trigger":['click']}},[_c('a-menu',{attrs:{"slot":"overlay"},on:{"click":_vm.onComputedSelect},slot:"overlay"},_vm._l((_vm.keys.filter(function (key) { return _vm.component ? key !== (_vm.component.key || _vm.component.id) : true; }
            )),function(key){return _c('a-menu-item',{key:key},[_c('a-icon',{attrs:{"type":"link"}}),_vm._v(" "+_vm._s(key)+" ")],1)}),1),_c('a-button',{staticStyle:{"font-family":"monospace","font-size":"11px","font-weight":"bold","color":"#777","margin-right":"-5px"},attrs:{"type":"dashed","shape":"circle","size":"small"}},[_vm._v("{}")])],1)],1):_vm._e(),_vm._t("default")],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }