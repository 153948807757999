<template>
  <div
    class="CommentThread my-3 transform filter transition"
    :class="{
      'scale-105': replyingToThisThread,
      'scale-95': replyingToOtherThread,
      'blur-xs': replyingToOtherThread,
      'brightness-80': replyingToOtherThread,
      'opacity-50': replyingToOtherThread,
      'cursor-pointer': !replyingToOtherThread && commentsFilter !== 'this_page',
      'hover:scale-102': !inReplyMode && commentsFilter !== 'this_page',
    }"
    @click="
      replyingToOtherThread
        ? $emit('menu-click', ['reply', null])
        : $emit('open-page', thread.location.page)
    "
  >
    <template v-for="(comment, index) in commentsDisplay">
      <SingleComment
        v-if="!comment.isGap"
        :class="{
          'rounded-t-none': index !== 0,
          'rounded-b-none': index !== commentsDisplay.length - 1,
          'border-t-0': index !== 0,
          'pointer-events-none': replyingToOtherThread,
        }"
        :key="comment.id"
        :commentPage="pages[comment.pageIndex]"
        :editing="comment.id === editingComment"
        :isLastInThread="index === commentsDisplay.length - 1"
        :settingType="settingTypeCommentId === comment.id"
        v-bind="{
          comment,
          commentsFilter,
          noAuthComments,
          commenterName,
          commenterEmail,
          threadId,
          users,
          threadHasCommentWithType,
          thread,
          replyingToThisThread,
        }"
        @menu-click="onMenuClick"
        @assign="$emit('assign', $event)"
        @archive="$emit('archive', thread.id)"
        @resolve="$emit('resolve', $event)"
        @toggle-edit="$emit('toggle-edit', comment)"
        @set-type="setType"
      />
      <div
        v-else
        :key="comment.id"
        class="cursor-pointer p-1 border border-t-0 border-gray-300 bg-gray-50 text-center text-gray-400 hover:bg-gray-100 text-xs"
        :class="{
          'pointer-events-none': replyingToOtherThread,
        }"
        @click.stop="expanded = true"
      >
        Show {{ comment.count }} more {{ comment.count === 1 ? 'reply' : 'replies' }}...
      </div>
    </template>
    <div
      v-if="expanded"
      class="cursor-pointer text-center text-gray-400 p-1 text-xs"
      @click.stop="expanded = false"
    >
      ^ Collapse
    </div>
  </div>
</template>

<script>
import SingleComment from './SingleComment'

export default {
  name: 'CommentThread',
  components: {
    SingleComment,
  },
  props: {
    thread: Object,
    editingComment: [String, Boolean],
    pages: Array,
    commentsFilter: String,
    noAuthComments: Boolean,
    commenterName: String,
    commenterEmail: String,
    commentPage: Object,
    threadId: String,
    users: Array,
    editing: Boolean,
    replyingToThisThread: Boolean,
    inReplyMode: Boolean,
  },
  data() {
    return {
      expanded: false,
      settingTypeCommentId: false,
    }
  },
  computed: {
    replyingToOtherThread() {
      return this.inReplyMode && !this.replyingToThisThread
    },
    commentsDisplay() {
      return this.expanded
        ? this.thread.comments.map((comment, threadIndex) => ({ ...comment, threadIndex }))
        : this.thread.comments.reduce((acc, comment, threadIndex, comments) => {
            if (this.isSignificantComment(comment, threadIndex, comments)) {
              acc.push({ ...comment, threadIndex })
            } else {
              const last = acc.slice(-1)[0]
              if (last.isGap) {
                last.count++
              } else {
                acc.push({ id: `gap_${comment.id}`, isGap: true, count: 1 })
              }
            }

            return acc
          }, [])
    },
    threadHasCommentWithType() {
      return Boolean(this.thread.comments.find(c => c.type))
    },
  },
  methods: {
    onMenuClick([key, comment]) {
      console.log('qwer key, comment', key, comment)
      switch (key) {
        case 'change-type': {
          if (comment.type) {
            this.$emit('set-type', [comment, null])
          }
          return (this.settingTypeCommentId = comment.id)
        }
        case 'archive': {
          return this.$emit('archive', this.thread.id)
        }

        default:
          return this.$emit('menu-click', [key, comment])
      }
    },
    setType([comment, typeKey]) {
      console.log('qwer comment, typeKey', comment, typeKey)
      this.$emit('set-type', [comment, typeKey])
      this.settingTypeCommentId = typeKey ? null : comment.id
    },
    isSignificantComment(comment, index, comments) {
      return (
        (comment.type && comment.type !== 'message') ||
        index === 0 ||
        index === comments.length - 1 ||
        comment.id === this.settingTypeCommentId
      )
    },
  },
}
</script>
