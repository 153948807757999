var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"CommentThread my-3 transform filter transition",class:{
    'scale-105': _vm.replyingToThisThread,
    'scale-95': _vm.replyingToOtherThread,
    'blur-xs': _vm.replyingToOtherThread,
    'brightness-80': _vm.replyingToOtherThread,
    'opacity-50': _vm.replyingToOtherThread,
    'cursor-pointer': !_vm.replyingToOtherThread && _vm.commentsFilter !== 'this_page',
    'hover:scale-102': !_vm.inReplyMode && _vm.commentsFilter !== 'this_page',
  },on:{"click":function($event){_vm.replyingToOtherThread
      ? _vm.$emit('menu-click', ['reply', null])
      : _vm.$emit('open-page', _vm.thread.location.page)}}},[_vm._l((_vm.commentsDisplay),function(comment,index){return [(!comment.isGap)?_c('SingleComment',_vm._b({key:comment.id,class:{
        'rounded-t-none': index !== 0,
        'rounded-b-none': index !== _vm.commentsDisplay.length - 1,
        'border-t-0': index !== 0,
        'pointer-events-none': _vm.replyingToOtherThread,
      },attrs:{"commentPage":_vm.pages[comment.pageIndex],"editing":comment.id === _vm.editingComment,"isLastInThread":index === _vm.commentsDisplay.length - 1,"settingType":_vm.settingTypeCommentId === comment.id},on:{"menu-click":_vm.onMenuClick,"assign":function($event){return _vm.$emit('assign', $event)},"archive":function($event){return _vm.$emit('archive', _vm.thread.id)},"resolve":function($event){return _vm.$emit('resolve', $event)},"toggle-edit":function($event){return _vm.$emit('toggle-edit', comment)},"set-type":_vm.setType}},'SingleComment',{
        comment: comment,
        commentsFilter: _vm.commentsFilter,
        noAuthComments: _vm.noAuthComments,
        commenterName: _vm.commenterName,
        commenterEmail: _vm.commenterEmail,
        threadId: _vm.threadId,
        users: _vm.users,
        threadHasCommentWithType: _vm.threadHasCommentWithType,
        thread: _vm.thread,
        replyingToThisThread: _vm.replyingToThisThread,
      },false)):_c('div',{key:comment.id,staticClass:"cursor-pointer p-1 border border-t-0 border-gray-300 bg-gray-50 text-center text-gray-400 hover:bg-gray-100 text-xs",class:{
        'pointer-events-none': _vm.replyingToOtherThread,
      },on:{"click":function($event){$event.stopPropagation();_vm.expanded = true}}},[_vm._v(" Show "+_vm._s(comment.count)+" more "+_vm._s(comment.count === 1 ? 'reply' : 'replies')+"... ")])]}),(_vm.expanded)?_c('div',{staticClass:"cursor-pointer text-center text-gray-400 p-1 text-xs",on:{"click":function($event){$event.stopPropagation();_vm.expanded = false}}},[_vm._v(" ^ Collapse ")]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }