<template>
  <div class="ContactWhenWhere text-right">
    <div class="when text-xs">
      <a-tooltip :title="`Viewed on a ${deviceType}`">
        <Icon v-if="deviceIcon" type="far" :name="deviceIcon" class="mr-2" />
      </a-tooltip>
      {{ contact.updatedAt | timeSimpleDate }}
    </div>
    <div v-if="country_name" class="where flex items-center justify-end mt-1 whitespace-nowrap">
      <!-- <Icon type="fas" name="map-marker-alt" class="mr-2" /> -->
      <span>
        {{ (city && `${city},`) || (!isUS ? region && `${region},` : '') }}
        {{ (isUS && region) || country_name }}
        {{ emoji_flag }}
      </span>
    </div>
  </div>
</template>

<script>
import { notNull } from './helpers/algolia'

export default {
  name: 'ContactWhenWhere',
  props: {
    contact: Object,
  },
  computed: {
    city() {
      return notNull(this.contact.city)
    },
    region() {
      return notNull(this.contact.region)
    },
    country_name() {
      return notNull(this.contact.country_name)
    },
    emoji_flag() {
      return notNull(this.contact.emoji_flag)
    },
    isUS() {
      return this.contact.country_code === 'US' || this.contact.country_name === 'United States'
    },
    deviceType() {
      return (
        this.contact.browser && this.contact.browser.platform && this.contact.browser.platform.type
      )
    },
    deviceIcon() {
      switch (this.deviceType) {
        case 'desktop':
          return 'desktop'
        case 'tablet':
          return 'tablet'
        case 'mobile':
          return 'mobile'
        default:
          return null
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/_variables.scss';

.ContactWhenWhere {
}
</style>
