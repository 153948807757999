<template>
  <div class="ComputedFieldEditor">
    <a-space direction="vertical">
      <a-space>
        <a-tag closable @close="$emit('remove')">
          Computed Field {{ computedFieldIndex + 1 }}
        </a-tag>
      </a-space>
      <a-radio-group size="small" v-model="saveInUserData">
        <a-radio-button :value="false">
          <a-tooltip title="Save to LocalStorage + Firebase">
            <a-icon type="check" />
          </a-tooltip>
        </a-radio-button>
        <a-radio-button value="cloud">
          <a-tooltip title="Only save to LocalStorage">
            <a-icon type="desktop" />
          </a-tooltip>
        </a-radio-button>
        <a-radio-button :value="true">
          <a-tooltip title="Do not save anywhere">
            <a-icon type="stop" />
          </a-tooltip>
        </a-radio-button>
      </a-radio-group>
      <EditorInput _key="key" isKey placeholder="Key - Required!" :component="computedField" />
      <a-tooltip v-if="numWithSameKey > 1">
        <div
          class="w-8 h-8 ml-1 rounded-md bg-gray-200 purple flex items-center justify-center font-extrabold"
        >
          {{ numWithSameKey }}
        </div>

        <template slot="title">
          This is one of {{ numWithSameKey }} components with the same key
        </template>
      </a-tooltip>
      <template v-if="key">
        <EditorSelect label="Select a Formula" _key="formula" :options="formulas" />
        <template v-if="currentFormula">
          <EditorInput
            v-for="{ key } in currentFormula.fields"
            :key="key"
            allowComputed
            :path="`fields.${key}`"
            :placeholder="key"
            :tooltip="
              `Current value: ${`${computedValues(userData, fields && fields[key], form)}`.slice(
                0,
                200
              )}`
            "
            :component="computedField"
          />
        </template>
        <template v-if="formula === 'custom'">
          <EditorSelect
            multiple
            isKey
            :disabled="computedField.use_full_user_data"
            label="User data input fields"
            _key="inputs"
            :options="keys.filter(key => key !== (computedField.key || computedField.id))"
          />
          <EditorSelect
            multiple
            isKey
            :disabled="computedField.use_full_user_data"
            label="[Optional] Fields to trigger updates (Defaults to all input fields)"
            _key="input_triggers"
            :options="keys.filter(key => key !== (computedField.key || computedField.id))"
          />
          <EditorCheckbox _key="use_full_user_data" label="Watch + Include Full User Data" />
          <a-alert v-for="warning in warnings" :key="warning" :message="warning" banner />
          <EditorCheckbox _key="async" />
          <EditorInput
            v-if="computedField.async"
            _key="async_debounce_time"
            placeholder="Wait before updating (250ms)"
            :component="computedField"
          />
          <EditorCheckbox _key="include_loading_state" />
          <EditorCheckbox _key="cache_data" />
          <label
            >Note: Must contain a function called 'result'. Fields specified above will be passed
            into an object as the first argument of this function.</label
          >
          <a-button
            type="primary"
            icon="edit"
            class="w-full"
            @click="_openDataPath({ path: `${basePath}.code`, code: true, title: key })"
          >
            Edit Computed Field JS Code
          </a-button>
          <EditorLabel label="Edit Inline" />
          <EditorCode _key="code" hideDataPath v-bind="{ codeDraft }" />
          <div class="flex flex-row">
            <a-tooltip>
              <template slot="title">Edit</template>
              <a-button
                size="small"
                icon="edit"
                @click="_openDataPath({ path: `${basePath}.code`, code: true, title: key })"
              />
            </a-tooltip>
            <a-button size="small" @click="generateSampleCode">Generate sample code</a-button>
          </div>
        </template>
        <EditorCheckbox
          _key="set_value_class"
          label="Set Value as Class on Flow"
          :component="computedField"
        />
      </template>
      <div v-if="userData">
        Current Value: {{ labelUserDataValue(userData[computedField.key]) }}
      </div>
    </a-space>
  </div>
</template>

<script>
import { unpack } from '@/helpers/computed'
import formulas from './helpers/computedFieldFormulas'
import { snakeToProper } from '@/helpers/textStringConversions'
import computedValues from './helpers/computedValues'
import componentTypes from '@/components/form/editor/helpers/componentTypes'

export default {
  name: 'ComputedFieldEditor',
  inject: ['_getOptions', '_updateData', '_openDataPath'],
  provide() {
    return {
      _getBasePath: () => this.basePath,
    }
  },
  props: {
    form: Object,
    userData: Object,
    computedField: Object,
    computedFieldIndex: Number,
  },
  data() {
    return {
      formulas,
      codeDraft: null,
    }
  },
  computed: {
    ...unpack('computedField', ['key', 'formula', 'code', 'fields', 'inputs']),
    basePath() {
      return `computedFields.${this.computedFieldIndex}`
    },
    currentFormula() {
      return this.formulas.find(f => f.key === this.formula)
    },
    keys() {
      const allowedComponentTypes = new Set([
        ...componentTypes()
          .filter(c => !['Layout', 'Static Display', 'Savvy Presets'].includes(c.group))
          .map(c => c.key),
        'computed',
        'AddressBox',
        'component',
      ])
      const options = this._getOptions()
      const keys = options.filter(o => allowedComponentTypes.has(o.type)).map(o => o.key)
      return Array.from(new Set(keys))
    },
    numWithSameKey() {
      return this._getOptions().filter(o => o.key === this.computedField.key).length
    },
    saveInUserData: {
      get() {
        return this.computedField.doNotSave || false
      },
      set(v) {
        this.update(['doNotSave', v])
      },
    },
    warnings() {
      const seenKeys = new Set(this.keys)
      const keyWarnings = (this.inputs || []).map(input => {
        return Array.isArray(this.keys) && !seenKeys.has(input)
          ? `"${input}" is not a key in the current form - is this Key out of date?`
          : ''
      })
      return keyWarnings.filterExists()
    },
  },
  methods: {
    update(update) {
      this._updateData(this.basePath, ...update)
    },
    computedValues,
    snakeToProper,
    generateSampleCode() {
      const args =
        this.inputs && this.inputs.length ? ` {\n  ${this.inputs.join(',\n  ')},\n} ` : ''
      this.codeDraft = `function result(${args}) {\n  return \n}`
    },
    labelUserDataValue(v) {
      return typeof v === 'string' ? `"${v}"` : v
    },
  },
}
</script>

<style lang="scss">
/* required class */
.my-editor,
.prism-editor__line-numbers {
  font-size: 11px !important;
  line-height: 1.6em !important;
}

.my-editor {
  border-radius: 4px;
  /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
  background: #2d2d2d;
  color: #ccc;

  /* you must provide font-family font-size line-height. Example: */
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 12px;
  line-height: 1.5;
  padding: 5px;
}

/* optional class for removing the outline */
.prism-editor__textarea:focus {
  outline: none;
}
</style>
