<template>
  <div
    class="EditorTab flex-1 flex flex-col pb-4"
    :class="{
      'overflow-hidden': location !== 'design',
    }"
    style=""
    :style="location === 'comments' ? 'flex: 1 1 auto;' : 'flex: 1 0 auto;'"
  >
    <FormPageEditor
      v-if="type === 'pages'"
      :key="parent && parent.id"
      :form="form"
      :page="parent"
      :userData="userData"
      :pageId="parent && parent.id"
      :pageIndex="parentIndex"
      :location="location"
      @update="_onPageUpdate(parentIndex, $event)"
      @jump="_setUserData($event)"
      @duplicate="_openModal('dupe-page')"
      @remove="_doAction('delete-page')"
    />
    <FlowEditor
      v-else-if="type === 'flow'"
      :key="form.id"
      :location="location"
      :userData="userData"
      :currentPageId="(page && page.id) || (parent && parent.id)"
      :form="form"
      @page="_setUserData($event)"
      @new-component="_openModal('new-global-component')"
    />
    <FormComponentEditor
      v-else-if="['components', 'global_components'].includes(type)"
      :key="parent && parent.id"
      :component="parent"
      :componentIndex="parentIndex"
      :isGlobalComponent="type === 'global_components'"
      v-bind="{ form, page, userData }"
      :grid="currentGrid[parentIndex]"
      :location="location"
      @new="_openModal($event ? 'new-global-component' : 'new-component')"
      @update="onComponentUpdate(parentIndex, $event)"
      @duplicate="_openModal('dupe-component')"
      @remove="_doAction('delete-component', $event)"
    />
    <DataLogicPane
      v-else-if="['data_outputs', 'computed_fields'].includes(type)"
      :currentId="selectedId"
      v-bind="{ type, form, userData, location }"
    />

    <!-- <Feedback v-else-if="type === 'comments'" v-bind="_getCommentEditorProps()" /> -->
    <CommentEditor v-else-if="type === 'comments'" v-bind="_getCommentEditorProps()" />
    <FlowAnalytics v-else-if="type === 'analytics'" v-bind="_getCommentEditorProps()" />
    <FlowExperiments v-else-if="type === 'experiments'" v-bind="_getCommentEditorProps()" />

    <component v-else :is="component" :location="location" />
  </div>
</template>

<script>
import FormPageEditor from '../FormPageEditor'
import FlowEditor from '../FlowEditor'
import FormComponentEditor from '../FormComponentEditor'
import DataLogicPane from './DataLogicPane.vue'
import CommentEditor from './CommentEditor.vue'
// import Feedback from './Feedback.vue'
import FlowAnalytics from './FlowAnalytics.vue'
import FlowExperiments from './FlowExperiments.vue'
export default {
  name: 'EditorTab',
  inject: [
    '_getCurrentGrid',
    '_updateData',
    '_setUserData',
    '_onPageUpdate',
    '_setSelectedComponentId',
    '_doAction',
    '_openModal',
    '_getCommentEditorProps',
  ],
  components: {
    FormPageEditor,
    FormComponentEditor,
    FlowEditor,
    DataLogicPane,
    CommentEditor,
    // Feedback,
    FlowAnalytics,
    FlowExperiments,
  },
  props: {
    parent: Object,
    parentIndex: Number,
    page: Object,
    form: Object,
    userData: Object,
    type: String,
    basePath: String,
    location: String,
    grid: Object,
    selectedId: String,
  },
  data() {
    return {}
  },
  computed: {
    currentGrid() {
      return this._getCurrentGrid() || {}
    },
    component() {
      switch (this.type) {
        case 'page':
          return 'FormPageEditor'
        case 'flow':
          return 'FlowEditor'

        case 'component':
        default:
          return 'FormComponentEditor'
      }
    },
  },
  watch: {},
  methods: {
    onComponentUpdate(index, [key, value]) {
      if (this.type === 'global_components') {
        this._updateData(`components.${index}`, key, value)
      } else {
        this._updateData(`${this.basePath}.components.${index}`, key, value)
      }
    },
  },
}
</script>
