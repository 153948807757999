import { cloneDeep } from 'lodash'
import id from '../helpers/id'
import { copyPageTemplate } from '../helpers/flowTemplates'

export default {
  name: 'EditorPaneMixin',
  inject: [
    // '_registerButton',
    '_setSelectedComponentId',
    '_setShowHiddenPages',
    '_setShowHiddenComponents',
    '_setUserData',
    '_updateReactive',
  ],
  props: { userData: Object, form: Object, showHidden: Boolean },
  data() {
    return {}
  },
  computed: {},
  mounted() {
    // this.deregister = this._registerButton(
    // {
    // id: 'page-actions',
    // tooltip: 'Page actions',
    // icon: 'sticky-note',
    // toggle: true,
    // buttons: [
    // {
    //   id: 'show-hidden-page',
    //   tooltip: 'Show Hidden Pages',
    //   icon: 'eye',
    //   toggle: true,
    //   toggledOnClasses: 'bg-indigo-500 text-white',
    //   toggledOn: () => this._setShowHiddenPages(true),
    //   toggledOff: () => {
    //     this._setShowHiddenComponents(false)
    //     this._setShowHiddenPages(false)
    //   },
    //   buttons: [
    //     {
    //       id: 'show-hidden-components',
    //       tooltip: 'Show Hidden Components',
    //       icon: 'eye',
    //       toggle: true,
    //       toggledOnClasses: 'bg-indigo-500 text-white',
    //       toggledOn: () => this._setShowHiddenComponents(true),
    //       toggledOff: () => this._setShowHiddenComponents(false),
    //     },
    //   ],
    // }
    //   {
    //     id: 'duplicate-page',
    //     tooltip: 'Duplicate current page',
    //     icon: 'clone',
    //     click: () => this.openModal('dupe-page'),
    //   },
    //   {
    //     id: 'delete-page',
    //     tooltip: 'Delete current page',
    //     icon: 'trash-alt',
    //     click: () => this.deletePage(),
    //   },
    // ],
    // }
    // )
  },
  beforeDestroy() {
    if (this.deregister) this.deregister()
    // if (this.deregisterComponentActionsButton) this.deregisterComponentActionsButton()
  },
  watch: {
    // currentComponentId: {
    //   handler(id) {
    // if (this.deregisterComponentActionsButton) this.deregisterComponentActionsButton()
    // if (id) {
    // this.deregisterComponentActionsButton = this._registerButton({
    //   id: 'component-actions',
    //   tooltip: 'Component actions',
    //   icon: 'cube',
    //   toggle: true,
    //   buttons: [
    //     {
    //       id: 'duplicate-component',
    //       tooltip: 'Duplicate selected component',
    //       icon: 'clone',
    //       click: () => this.openModal('dupe-component'),
    //     },
    //     {
    //       id: 'delete-component',
    //       tooltip: 'Delete selected component',
    //       icon: 'trash-alt',
    //       click: () => this.deleteComponent(),
    //     },
    //   ],
    // })
    // }
    // },
    // immediate: true,
    // },
  },
  methods: {
    getPageComponents() {
      const page = this.form.pages.find(p => p.id === this.currentPage.id)
      const pageComponents = page.components || []
      return pageComponents
    },
    findCurrentPosition(components, id) {
      const currentPosition = components.findIndex(c => c.id === id)
      return Math.max(Math.min(components.length + 1, currentPosition), 0)
    },
    loadComponentType(id) {
      const global = ((this.form && this.form.components) || []).find(c => c.id === id)
      const data = { key: this.newKey }
      if (!data.id) data.id = id()
      data.type =
        this.newTypeData && (this.newTypeData.componentKey || this.newTypeData.key.split('-')[0])
      if (this.newTypeData && this.newTypeData.componentProperties) {
        Object.entries(this.newTypeData.componentProperties).forEach(([k, v]) => {
          data[k] = v
        })
      }
      if (this.newContainerKey) data.parent_key = this.newContainerKey
      if (global) {
        const path = ''
        const components = (this.form && this.form.components) || []
        const currentPosition = this.findCurrentPosition(components, id)
        const newComponents = components.map((c, i) =>
          i === currentPosition ? { ...c, ...data } : c
        )
        this._updateReactive(path, 'components', newComponents)
      } else {
        const pageIndex = this.form.pages.findIndex(p => p.id === this.currentPage.id)
        const pageComponents = this.getPageComponents()
        const path = `pages.${pageIndex}`
        const currentPosition = this.findCurrentPosition(pageComponents, id)

        const newComponents = pageComponents.map((c, i) =>
          i === currentPosition ? { ...c, ...data } : c
        )
        this._updateReactive(path, 'components', newComponents)
      }
    },
    async newComponent(global, indexChange) {
      const newId = id()
      const newComponent = { id: newId, key: this.newKey }
      newComponent.type =
        this.newTypeData && (this.newTypeData.componentKey || this.newTypeData.key.split('-')[0])
      if (this.newTypeData && this.newTypeData.componentProperties) {
        Object.entries(this.newTypeData.componentProperties).forEach(([k, v]) => {
          newComponent[k] = v
        })
      }

      const findCurrentPosition = components => {
        if (typeof indexChange !== 'number') return null
        const currentPosition =
          components.findIndex(c => c.id === this.currentComponentId) + indexChange
        return Math.max(Math.min(components.length + 1, currentPosition), 0)
      }
      if (this.newContainerKey) newComponent.parent_key = this.newContainerKey
      if (global) {
        const path = ''
        const components = (this.form && this.form.components) || []
        const currentPosition = findCurrentPosition(components)

        const newComponents =
          currentPosition !== null ? [...components] : [...components, newComponent]
        if (currentPosition !== null) newComponents.splice(currentPosition, 0, newComponent)
        this._updateReactive(path, 'components', newComponents)
      } else {
        const pageIndex = this.form.pages.findIndex(p => p.id === this.currentPage.id)
        const pageComponents = this.getPageComponents()
        const path = `pages.${pageIndex}`
        const currentPosition = findCurrentPosition(pageComponents)

        const newComponents =
          currentPosition !== null ? [...pageComponents] : [...pageComponents, newComponent]
        if (currentPosition !== null) newComponents.splice(currentPosition, 0, newComponent)
        this._updateReactive(path, 'components', newComponents)
      }
      await this.$nextTick()
      this._setSelectedComponentId({}, newId)
    },
    async duplicateComponent() {
      const newComponent = cloneDeep(this.currentComponent)
      newComponent.id = id()
      newComponent.key = this.newKey
      const findCurrentPosition = components => {
        if (typeof indexChange !== 'number') return null
        const currentPosition = components.findIndex(c => c.id === this.currentComponentId) + 1
        return Math.max(Math.min(components.length + 1, currentPosition), 0)
      }
      if (this.currentComponentIsGlobal) {
        const components = (this.form && this.form.components) || []
        const currentPosition = findCurrentPosition(components)
        const newComponents =
          currentPosition !== null ? [...components] : [...components, newComponent]
        if (currentPosition !== null) newComponents.splice(currentPosition, 0, newComponent)

        this._updateReactive(``, `components`, newComponents)
      } else {
        const pageIndex = this.form.pages.findIndex(p => p.id === this.currentPage.id)
        const components = this.getPageComponents()
        const currentPosition = findCurrentPosition(components)
        const newComponents =
          currentPosition !== null ? [...components] : [...components, newComponent]
        if (currentPosition !== null) newComponents.splice(currentPosition, 0, newComponent)

        this._updateReactive(`pages.${pageIndex}`, `components`, newComponents)
      }
      await this.$nextTick()
      this._setSelectedComponentId({}, newComponent.id)
    },
    deleteComponent(deleteChildren) {
      const removedKeys = new Set()
      const findDescendents = (key, array) => {
        removedKeys.add(key)
        array
          .filter(item => item.parent_key === key)
          .forEach(item => {
            if (!removedKeys.has(item.key)) findDescendents(item.key, array)
          })
      }
      const filterFn = (p, ancestorKeys) => {
        const isNotSelf = p.id !== this.currentComponentId
        const isNotChild = deleteChildren && p.parent_key ? !ancestorKeys.has(p.parent_key) : true
        const shouldKeep = isNotSelf && isNotChild
        return shouldKeep
      }
      if (this.currentComponentIsGlobal) {
        const globals = (this.form && this.form.components) || []
        const selfComponent = globals.find(p => p.id === this.currentComponentId)
        findDescendents(selfComponent.key, globals)
        const components = globals.filter(p => filterFn(p, removedKeys))
        this._updateReactive(null, 'components', components)
      } else {
        const page = this.form.pages.find(p => p.id === this.currentPage.id)
        const pageIndex = this.form.pages.findIndex(p => p.id === this.currentPage.id)
        const pageComponents = page.components || []
        const selfComponent = pageComponents.find(p => p.id === this.currentComponentId)
        findDescendents(selfComponent.key, pageComponents)
        const components = pageComponents.filter(p => filterFn(p, removedKeys))
        this._updateReactive(`pages.${pageIndex}`, 'components', components)
      }
      this._setSelectedComponentId({}, null)
    },
    moveComponentInDirection(dir = 'down') {
      const moveDown = dir === 'down'
      const components = this.currentComponentIsGlobal
        ? (this.form && this.form.components) || []
        : this.getPageComponents()
      const componentIndex = components.findIndex(c => c.id === this.currentComponentId)
      /* Find the first index that is on the same container level */
      const findNextIndex = components => {
        const arr = moveDown ? components : [...components].reverse()
        const start = arr.findIndex(c => c.id === this.currentComponentId)
        const arr2 = arr.slice(start)
        const foundIndex = arr2.findIndex(
          c => c.parent_key === this.currentComponent.parent_key && c.id !== this.currentComponentId
        )
        return moveDown ? foundIndex + start : components.length - 1 - (foundIndex + start)
      }
      const newIndex = findNextIndex(components) // moveDown ? componentIndex + 1 : componentIndex - 1
      /* needs to find the first component that has the same parent key rather than the next component indexwise */
      const inBounds = index => 0 <= index && index < components.length
      if (inBounds(componentIndex) && inBounds(newIndex)) {
        const newComponents = [...components].filter(c => c.id !== this.currentComponentId)
        const baseComponent = components.find(c => c.id === this.currentComponentId)
        newComponents.splice(newIndex, 0, baseComponent)
        // newComponents[newIndex] = components[componentIndex]
        // newComponents[componentIndex] = components[newIndex]
        const path = this.currentComponentIsGlobal
          ? null
          : `pages.${this.form.pages.findIndex(p => p.id === this.currentPage.id)}`
        this._updateReactive(path, 'components', newComponents)
      }
    },
    moveComponentToPage(component, newPageId) {
      const newPages = this.form.pages.map(p => {
        const components = (p.components || []).filter(c => c.id !== component.id)
        if (newPageId === p.id) components.push(component)
        return { ...p, components }
      })

      this._updateReactive(null, 'pages', newPages)
    },
    async newPage() {
      const newId = id()
      const basePageData = { id: newId, components: [], showNav: true }
      let newPage = basePageData
      if (this.newKey) newPage.key = this.newKey
      if (this.newTypeData && this.newTypeData.template) {
        const { page } = copyPageTemplate(this.newTypeData.template, this.form, basePageData)
        newPage = page
      }
      const indexShift = this.insertAfterPage ? 1 : 0

      const pages = this.form.pages || []
      const newPages = [...pages]
      if (indexShift) {
        const currentPageIndex = pages.findIndex(p => p.id === this.currentPage.id) + indexShift
        newPages.splice(currentPageIndex, 0, newPage)
      } else newPages.push(newPage)

      this._updateReactive(null, 'pages', newPages)
      await this.$nextTick()
      this.onSelectPage({ key: newId })
    },
    async duplicatePage(pageId) {
      const targetId = pageId || this.currentPage.id
      const newPageId = id()
      const newPages = this.pages.reduce((acc, p) => {
        acc.push(p)
        if (p.id === targetId) {
          const key = this.newKey === p.key ? `${this.newKey}_copy` : this.newKey || `${p.key}_copy`
          const title = p.title ? `${p.title} (Copy)` : ''
          const newPage = cloneDeep({ ...p, id: newPageId, key, title })
          if (Array.isArray(newPage.components))
            newPage.components = newPage.components.map(c => {
              const newC = { ...c, id: id() }
              if (Array.isArray(newC.buttons))
                newC.buttons = newC.buttons.map(b => ({ ...b, id: id() }))
              return newC
            })
          acc.push(newPage)
        }
        return acc
      }, [])
      this._updateReactive(null, `pages`, newPages)
      await this.$nextTick()
      this.onSelectPage({ key: newPageId })
    },
    async deletePage(pageId) {
      const targetPageId = pageId || this.currentPage.id
      const prevPage = this.pages[this.currentPageIndex - 1]
      const isCurrentPage = targetPageId === this.currentPage.id
      const newPages = this.pages.filter(c => c.id !== targetPageId)
      this._updateReactive(null, `pages`, newPages)
      if (prevPage && isCurrentPage) {
        await this.$nextTick()
        this.onSelectPage({ key: prevPage.id })
        this._setUserData({ ...this.userData, currentPageId: prevPage.id })
      }
    },
    newComputedField() {
      const newField = { id: id(), key: this.newKey, formula: 'custom' }
      if (!this.form.computedFields) this._updateReactive(null, 'computedFields', [newField])
      else {
        const computedFields = [...this.form.computedFields, newField]
        this._updateReactive(null, 'computedFields', computedFields)
      }
      this.onSelectComputedField({ key: newField.id })
    },
    duplicateComputedField() {
      const oldComputedField =
        this.form.computedFields.find(c => c.id === this.selectedComputedFieldId) || {}
      const newField = { ...oldComputedField, id: id(), key: this.newKey }
      if (!this.form.computedFields) this._updateReactive(null, 'computedFields', [newField])
      else {
        const computedFields = [...this.form.computedFields, newField]
        this._updateReactive(null, 'computedFields', computedFields)
      }
      this.onSelectComputedField({ key: newField.id })
    },
    async newOutput() {
      const newOutput = { id: id(), conditions: [] }
      if (!this.form.dataOutputs) this._updateReactive(null, 'dataOutputs', [newOutput])
      else {
        const dataOutputs = [...this.form.dataOutputs, newOutput]
        this._updateReactive(null, 'dataOutputs', dataOutputs)
      }
      await this.$nextTick()
      this.onSelectDataOutput({ key: newOutput.id })
    },
  },
}
