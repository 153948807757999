import {
  generatePageSelectors,
  generateComponentSelectors,
} from '@/components/form/helpers/labelGenerator'

export default function getSelectors(el, type, parent, page, viewport) {
  const selectors = generateSelectors(type, parent, page)
  const applyWidth = (sel, width) => {
    if (sel.includes('FlowMain'))
      return sel.replace(/FlowMain/gi, `FlowMain[max-width~='${width}px']`)
    const base = `.Flow-EntireFlow[max-width~='${width}px']`
    return sel.includes('.Flow-EntireFlow')
      ? sel.replace(/Flow-EntireFlow/gi, base.slice(1))
      : `${base} ${sel}`
  }
  switch (viewport) {
    case 'tablet': {
      selectors.push(...selectors.map(s => applyWidth(s, 720)))
      break
    }
    case 'mobile': {
      const tabletSelectors = selectors.map(s => applyWidth(s, 720))
      const mobileSelectors = selectors.map(s => applyWidth(s, 520))
      selectors.push(...tabletSelectors, ...mobileSelectors)
      break
    }

    default:
      break
  }
  return selectors.map(s => applyModifiers(s, el, type))
}

function generateSelectors(type, parent, page) {
  switch (type) {
    case 'pages':
      return generatePageSelectors(parent).map(t =>
        t === 'Flow-Page' ? `.${t}` : `.Flow-Page.${t}`
      )
    case 'components':
    case 'global_components':
      return [
        ...generateComponentSelectors(parent, page),
        ...generateAdditionalSelectors(type, parent, page),
      ].map(t => {
        const txt = `.${t}`
        return t === 'Flow-Component' ? txt : `.Flow-Component${txt}`
      })

    case 'flow':
      return ['.Flow-EntireFlow']
    default:
      return []
  }
}

function generateAdditionalSelectors(type, parent, page) {
  switch (type) {
    case 'page':
      return []
    case 'components':
    case 'global_components': {
      const base = []
      const prefixes = ['']
      if (parent.parent_key) prefixes.push(`InContainer-${parent.parent_key}`)

      const addPrefixes = selectors =>
        prefixes.reduce((acc, p) => {
          selectors.forEach(t => acc.push(p ? `${p}.${t}` : t))
          return acc
        }, [])
      if (page.key) {
        if (parent.type)
          base.push(...addPrefixes([`ComponentType-${parent.type}.PageKey-${page.key}`]))
        if (parent.key)
          base.push(...addPrefixes([`ComponentKey-${parent.key}.PageKey-${page.key}`]))
        if (parent.tags) {
          const tags = parent.tags.map(t => `ComponentTag-${t}.PageKey-${page.key}`)
          base.push(...addPrefixes(tags))
        }
        if (parent.buttons) {
          const buttons = parent.buttons.map(b => `ElementKey-${b.key}`)
          base.push(...addPrefixes(buttons))
        }
      }
      if (Array.isArray(page.tags)) {
        page.tags.forEach(tag => {
          if (parent.key) base.push(...addPrefixes([`ComponentKey-${parent.key}.PageTag-${tag}`]))
          if (parent.type)
            base.push(...addPrefixes([`ComponentType-${parent.type}.PageTag-${tag}`]))
          if (parent.tags) {
            const tags = parent.tags.map(t => `ComponentTag-${t}.PageTag-${tag}`)
            base.push(...addPrefixes(tags))
          }
        })
      }
      return base
    }

    default:
      return []
  }
}
/* 

['.Flow-EntireFlow', ".Flow-EntireFlow[max-width~='720px']"]
becomes
[
  ".Flow-Element.ElementType-FlowPopupOuter",
  ".Flow-EntireFlow[max-width~='720px'] .Flow-Element.ElementType-FlowPopupOuter.Flow-EntireFlow[max-width~='720px']"
]
*/
function applyModifiers(s, el, type) {
  if (el && el.startsWith('.GlobalLocation-') && s && s.endsWith('.Flow-EntireFlow'))
    s = s.slice(0, '.Flow-EntireFlow'.length * -1).trim()

  let selector = s.split('.')
  const [selectedElementType, selectedElementState, selectedChildElement] = getElementParts(el)
  const exceptionTags = ['ul', 'ol', 'li', 'a', 'i', 'strong']

  function isException(t) {
    return t && (exceptionTags.includes(t) || t.startsWith('.GlobalLocation-'))
  }

  if (selectedElementType && !isException(selectedElementType)) {
    /* 
    Skip the base portion for type === flow as it already contains Flow-EntireFlow
    */
    const base =
      s.startsWith(`.Flow-EntireFlow[max-width~='`) && type !== 'flow'
        ? [s.slice(1, `.Flow-EntireFlow[max-width~='720px']`.length) + ' ']
        : []
    selector = [...base, 'Flow-Element', `ElementType-${selectedElementType}`]
      .concat(
        selector.filter(
          c => ![base[0], 'Flow-EntireFlow', 'Flow-Page', 'Flow-Component'].includes(c)
        )
      )
      .filter(c => c)
  }

  if (selectedElementState) {
    const modifiers = [selectedElementState]
    selector.push(...modifiers)
    if (selectedChildElement) {
      selector.push('', `ElementType-${selectedChildElement}`)
    }
  }
  if (isException(selectedElementType)) {
    selector.push('', selectedElementType)
  }
  const finalSelector = selector
    .map(t => {
      if (isException(t)) return t
      return t === '' ? ' ' : t.startsWith(':') || t.startsWith('[') ? t : `.${t}`
    })
    .join('')
    .trim()
  return finalSelector
}

function getElementParts(selectedElement) {
  const [type, state, element] = (selectedElement && selectedElement.split('__')) || []
  switch (type) {
    case 'undefined': {
      return [undefined, state, element]
    }

    default: {
      return [type, state, element]
    }
  }
}
