<template>
  <div class="EditorPane flex-1 flex flex-col scroller">
    <div class="px-4" v-if="blameVersion && tab === 'options'">
      Last Edited: v{{ blameVersion.version }} by
      {{ blameVersion.author && blameVersion.author.userName }}
    </div>
    <EditorTab v-if="tab !== 'design'" v-bind="editorTabProps" :location="tab" />
    <template v-else>
      <DesignTab v-bind="editorTabProps" location="design" />
      <EditorTab v-bind="editorTabProps" location="design-footer" />
    </template>
    <a-modal
      v-model="modalVisible"
      :title="title"
      @ok="handleOk"
      :okButtonProps="{ props: { disabled: !newKey }, on: {} }"
      @cancel="clearModalSettings"
    >
      <div class="overflow-hidden flex flex-col">
        <template v-if="['new-page'].includes(actionButtonType)">
          <ElementCardSelector
            dataType="pageLayouts"
            v-model="newTypeData"
            :typeKey="newTypeData && newTypeData.key"
            @selected="onSelected"
          />
          <div class="flex flex-row items-center">
            <EditorLabel class="mr-2" label="Insert Page after current page" />
            <a-checkbox v-model="insertAfterPage"></a-checkbox>
          </div>
        </template>
        <template
          v-if="
            ['new-global-component', 'new-component', 'load-component-type'].includes(
              actionButtonType
            )
          "
        >
          <ElementCardSelector
            v-model="newTypeData"
            :typeKey="newTypeData && newTypeData.key"
            @selected="onSelected"
          />
        </template>
      </div>
      <EditorLabel label="Enter a key" />
      <a-input ref="keyInput" :value="newKey" @change="setNewKey" @pressEnter.prevent="handleOk" />
      <a-alert v-if="newKeyIsDupe" type="warning" message="Key is already in use" showIcon />
      <template
        v-if="
          ['new-global-component', 'new-component', 'load-component-type'].includes(
            actionButtonType
          )
        "
      >
        <EditorLabel label="Container Key" />
        <a-select v-model="newContainerKey" style="width: 100%">
          <a-select-option v-for="{ key, label } in containerOptions" :key="key" :value="key">
            {{ label }}
          </a-select-option>
        </a-select>
      </template>
    </a-modal>
  </div>
</template>
<script>
import { snakeCase, startCase } from 'lodash'
import { passesConditions } from '../../helpers/conditions'
import componentTypes from '@/components/form/editor/helpers/componentTypes'
import {
  addDefaultComponents,
  defaultHeadingComponents,
} from '@/components/form/helpers/defaultComponents'

import DesignTab from './DesignTab.vue'
import EditorTab from './EditorTab.vue'
import EditorPaneMixin from './EditorPaneMixin'
import { repeatPages } from '../../helpers/repeatPages'
import ElementCardSelector from './ElementCardSelector.vue'
import { createSearchableFlow } from '@/components/form/editor/helpers/searchForm.js'
import { findLastChangedVersion } from '@/components/form/editor/helpers/formHelpers.js'
import { mapState } from 'vuex'

export default {
  name: 'EditorPane',
  components: {
    DesignTab,
    EditorTab,
    ElementCardSelector,
  },
  mixins: [EditorPaneMixin],
  props: {
    userData: Object,
    form: Object,
    noAuthComments: Boolean,
    insideEditor: Boolean,
  },
  provide() {
    return {
      _getBasePath: () => this.basePath,
      _getSiblingOptions: () => this.siblingOptions,
      _onSelectPage: this.onSelectPage,
      _onSelectComponent: this.onSelectComponent,
      _onSelectDataOutput: this.onSelectDataOutput,
      _onSelectComputedField: this.onSelectComputedField,
      _openModal: this.openModal,
      _doAction: this.handleAction,
    }
  },
  inject: [
    '_getSelectedComponentId',
    '_setSelectedComponentId',
    '_setUserData',
    // '_setButtonState',
    '_setShowHiddenPages',
    '_getPreviewMode',
    '_getFlowPages',
    '_register',
  ],
  data() {
    return {
      // tab: 'content',
      selectedComponentId: null,
      selectedPageId: null,
      selectedComputedFieldId: null,
      selectedDataOutputId: null,
      follow: true,
      viewType: 'flow',
      modalVisible: false,
      newKey: '',
      newType: '',
      newTypeData: '',
      actionButtonType: '',
      insertAfterPage: true,
      newParentKey: null,
      showButtonDescriptions: null,
    }
  },
  computed: {
    ...mapState(['flowVersions']),
    title() {
      switch (this.actionButtonType) {
        case 'new-page':
          return 'Choose a Page Layout'
        case 'new-global-component':
        case 'new-component':
          return 'Choose a Component Type'

        default:
          return 'Before you continue...'
      }
    },
    newKeyIsDupe() {
      switch (this.actionButtonType) {
        case 'new-page':
        case 'dupe-page': {
          const pages = (this.form && this.form.pages) || []
          const keys = new Set(pages.map(p => p.key))
          return keys.has(this.newKey)
        }

        default:
          return false
      }
    },
    newContainerKey: {
      get() {
        return this.newParentKey === null
          ? this.currentPage && this.currentPage.default_container
          : this.newParentKey || undefined
      },
      set(v) {
        this.newParentKey = v
      },
    },
    componentTypesByGroup() {
      const types = componentTypes()
      const defaultGroup = 'Other'
      const groupOrder = Array.from(new Set(types.map(t => t.group)))
      const groups = types.reduce((acc, t) => {
        const group = t.group || defaultGroup
        acc[group] = acc[group] ? [...acc[group], t] : [t]
        return acc
      }, {})
      return groupOrder.map(g => ({ group: g, types: groups[g] || [] }))
    },
    types() {
      return [
        { key: 'flow', label: 'Entire Flow' },
        { key: 'pages', label: 'Pages' },
        { key: 'global_components', label: 'Global Components' },
        { key: 'computed_fields', label: 'Computed Fields' },
        { key: 'data_outputs', label: 'Data Outputs' },
        { key: 'components', label: 'Components', hidden: true },
      ]
    },
    currentType() {
      const type = this.types.find(t => t.key === this.viewType)
      return (type && type.label) || 'No type'
    },
    basePath() {
      switch (this.viewType) {
        case 'components':
          return `pages.${this.currentPageIndex}`
        case 'flow':
        case 'pages':
        case 'global_components':
        case 'computed_fields':
        case 'data_outputs':
        default:
          return ``
      }
    },
    selectedId() {
      switch (this.viewType) {
        case 'global_components':
        case 'components':
          return this.selectedComponentId
        case 'pages':
          return this.selectedPageId
        case 'computed_fields':
          return this.selectedComputedFieldId
        case 'data_outputs':
          return this.selectedDataOutputId
        default:
        case 'flow':
          return ``
      }
    },
    editorTabProps() {
      let type = this.noAuthComments || this.tab === 'comments' ? 'comments' : this.viewType
      if (this.tab === 'analytics') type = 'analytics'
      if (this.tab === 'experiments') type = 'experiments'

      return {
        basePath: this.basePath,
        parent: this.parent,
        parentIndex: this.parentIndex,
        form: this.form,
        page: this.currentPage,
        component: this.currentComponent,
        type,
        selectedId: this.selectedId,
        userData: this.userData,
      }
    },
    pages() {
      const pages = this.form.pages || []
      return this._getPreviewMode() ? repeatPages(pages, this.userData) : pages
    },
    hiddenComponents() {
      return new Set(this.siblingOptions.filter(c => c.hidden).map(c => c.id))
    },
    hiddenPages() {
      return new Set(
        this.pages
          .filter(item => {
            if (item.hide) return true
            return !passesConditions(
              item.conditions || [],
              this.userData,
              this.globalComponents,
              true
            )
          })
          .map(c => c.id)
      )
    },
    siblingOptions() {
      switch (this.viewType) {
        case 'components': {
          const components = (this.currentPage && this.currentPage.components) || []
          return addDefaultComponents(components).map(c => {
            const label = `${c.key}${this.getComponentSuffix(c.id)}`
            const hidden = c.hide || !passesConditions(c.conditions || [], this.userData, {}, true)
            const inContainer = Boolean(
              c.parent_key && components.find(co => co.key === c.parent_key)
            )
            const prettyKey = Object.values(defaultHeadingComponents()).find(co => co.id === c.id)
              ? startCase(c.key)
              : c.key
            return { key: c.key, id: c.id, label, hidden, type: c.type, inContainer, prettyKey }
          })
        }
        case 'pages': {
          return this.pages.map(p => {
            return { key: p.key, id: p.id, label: `${p.key}`, hidden: this.hiddenPages.has(p.id) }
          })
        }
        case 'global_components': {
          const components = (this.form && this.form.components) || []
          return components.map(c => {
            const label = `${c.key}${this.getComponentSuffix(c.id)}`
            const hidden = c.hide || !passesConditions(c.conditions || [], this.userData, {}, true)
            const inContainer = Boolean(
              c.parent_key && components.find(co => co.key === c.parent_key)
            )
            return { key: c.key, id: c.id, label, hidden, type: c.type, inContainer }
          })
        }
        case 'computed_fields': {
          const computedFields = (this.form && this.form.computedFields) || []
          return computedFields.map(c => ({ key: c.key, id: c.id, label: c.key }))
        }
        case 'data_outputs': {
          const outputs = (this.form && this.form.dataOutputs) || []
          return outputs.map((c, i) => {
            const label = `${i + 1}: ${startCase(c.output)}`
            return { key: c.id, id: c.id, label: c.name || label }
          })
        }
        case 'flow':
        default:
          return []
      }
    },
    siblingData() {
      const emptySet = new Set()
      switch (this.viewType) {
        case 'computed_fields':
          return {
            hidden: emptySet,
            onClick: e => this.onSelectComputedField(e),
            currentSibling: this.siblingOptions.find(f => f.id === this.selectedComputedFieldId),
          }
        case 'data_outputs':
          return {
            hidden: emptySet,
            onClick: e => this.onSelectDataOutput(e),
            currentSibling: this.siblingOptions.find(f => f.id === this.selectedDataOutputId),
          }

        case 'components':
        case 'global_components': {
          return {
            hidden: this.hiddenComponents,
            onClick: e => this.onSelectComponent(e),
            currentSibling: this.siblingOptions.find(f => f.id === this.currentComponentId),
          }
        }
        case 'pages': {
          return {
            hidden: this.hiddenPages,
            onClick: e => this.onSelectPage(e),
            currentSibling: this.siblingOptions.find(f => f.id === this.currentPageId),
          }
        }

        case 'flow':
        default:
          return {
            hidden: emptySet,
            onClick: e => {
              e.preventDefault()
              e.stopPropagation()
            },
            currentSibling: {},
          }
      }
    },
    tabsToShow() {
      if (this.siblingOptions.length === 0) {
        return this.viewType === 'flow' ? ['content', 'design', 'logic'] : []
      }
      switch (this.viewType) {
        case 'computed_fields':
        case 'data_outputs':
          return ['logic']
        case 'components': {
          const componentIsDefault = Object.values(defaultHeadingComponents()).find(
            c => c.id === this.currentComponentId
          )
          return componentIsDefault ? ['content', 'design'] : ['content', 'design', 'logic']
        }
        case 'global_components':
        case 'flow':
        case 'pages':
        default:
          return ['content', 'design', 'logic']
      }
    },
    flowCurrentPageId() {
      const currentPageId = this.userData && this.userData.currentPageId
      return currentPageId !== undefined
        ? currentPageId
        : this._getFlowPages(true) && this._getFlowPages(true)[0] && this._getFlowPages(true)[0].id
    },
    currentPageId() {
      return this.follow ? this.flowCurrentPageId : this.selectedPageId
    },
    currentComponentId() {
      return this.follow ? this._getSelectedComponentId() : this.selectedComponentId
    },
    currentComponentIsGlobal() {
      const globals = this.form.components || []
      return Boolean(globals.find(g => g.id === this.currentComponentId))
    },
    currentPage() {
      return (this.pages || []).find(p => p.id === this.currentPageId)
    },
    visibleComponents() {
      const components = (this.currentPage && this.currentPage.components) || []
      const globalComponents = (this.form && this.form.components) || []
      return addDefaultComponents(components, globalComponents).filter(item => {
        if (item.hide) return false
        return passesConditions(item.conditions || [], this.userData, {}, true)
      })
    },
    currentPageIndex() {
      return (this.pages || []).findIndex(p => p.id === this.currentPageId)
    },
    currentComponent() {
      if (this.currentComponentIsGlobal)
        return (this.form.components || []).find(g => g.id === this.currentComponentId)
      const components = (this.currentPage && this.currentPage.components) || []
      return addDefaultComponents(components, []).find(c => c.id === this.currentComponentId)
    },
    editorComponent() {
      switch (this.tab) {
        case 'design':
          return 'DesignTab'
        case 'logic':
        case 'content':
        default:
          return 'EditorTab'
      }
    },
    parent() {
      /* Todo - Global Components, and other top level form things (maybe stays inside Form?) */
      switch (this.viewType) {
        case 'components':
        case 'global_components':
          return this.currentComponent
        case 'flow':
          return this.form
        case 'pages':
          return this.currentPage
        case 'computed_fields':
        case 'data_outputs':
        default:
          return null
      }
      // if (this.viewType === 'flow') return this.form
      // return this.currentComponent || this.currentPage || this.form
    },
    parentIndex() {
      if (this.currentComponent) {
        if (this.currentComponentIsGlobal) {
          return (this.form.components || []).findIndex(g => g.id === this.currentComponentId)
        }
        return this.currentPage.components.findIndex(c => c.id === this.currentComponentId)
      }
      if (this.currentPage) return this.currentPageIndex
      return -1
    },
    containerOptions() {
      const components = this.currentComponentIsGlobal
        ? this.form.components || []
        : (this.currentPage && this.currentPage.components) || []
      return [
        { key: undefined, label: 'No Container' },
        ...components.filter(c => c.type === 'Container').map(c => ({ key: c.key, label: c.key })),
      ]
    },
    tab: {
      get() {
        return this.$store.state.showRightBar === false ? 'options' : this.$store.state.showRightBar
      },
      set(v) {
        let val
        switch (v) {
          case 'content': {
            val = 'options'
            break
          }
          case 'logic': {
            val = 'triggers'
            break
          }

          case 'design':
          default: {
            val = v
            break
          }
        }
        this.$store.commit('setRightBar', val)
      },
    },
    paneViewButtonsEnabled() {
      let triggers = ['pages', 'flow'].includes(this.viewType)
      if (['components', 'global_components'].includes(this.viewType)) {
        const type = this.currentComponent && this.currentComponent.type
        const componentDefinition = componentTypes().find(c => c.key === type)
        triggers = (componentDefinition && componentDefinition.hasTriggers) || false
      }
      let design = !['computed_fields', 'data_outputs'].includes(this.viewType)
      return {
        options: true,
        design,
        triggers,
        comments: true,
        analytics: true,
        experiments: true,
      }
    },
    blameVersion() {
      let item
      switch (this.viewType) {
        case 'components':
        case 'global_components': {
          item = this.currentComponent
          break
        }
        case 'pages': {
          item = this.currentPage
          break
        }
        // case 'data_outputs':
        case 'computed_fields': {
          item = (this.form.computedFields || []).find(c => c.id === this.selectedComputedFieldId)
          break
        }
        // case 'flow':
        //   item = this.form
        default: {
          item = null
          break
        }
      }
      if (item) {
        return findLastChangedVersion(this.viewType, item, this.flowVersions, {
          originalForm: this.form,
        })
      }
      return null
    },
  },
  mounted() {
    this.deregisterAction = this._register('editorAction', {
      action: this.handleAction,
      modal: this.openModal,
      onViewType: this.onViewType,
      getSelectedObject: () => ({ viewType: this.viewType, selectedId: this.selectedId }),
      getShowButtonDescriptions: () => this.showButtonDescriptions,
      setShowButtonDescriptions: v => (this.showButtonDescriptions = v),
    })
    if (window.CommandBar) {
      window.CommandBar.addCallback('selectFlowItem', async args => {
        const flowItem = args.flowItem
        if (!flowItem) return
        switch (flowItem.type) {
          case 'global_component': {
            this.onSelectComponent({ key: flowItem.id })
            break
          }
          case 'computed_field': {
            this.onSelectComputedField({ key: flowItem.id })
            break
          }
          case 'page': {
            this.onSelectPage({ key: flowItem.id })
            break
          }
          case 'component': {
            if (flowItem.parentPageId && flowItem.parentPageId !== this.currentPageId) {
              this.onSelectPage({ key: flowItem.parentPageId })
              await this.$nextTick()
            }
            this.onSelectComponent({ key: flowItem.id })
            break
          }

          default:
            break
        }
      })
    }
  },
  beforeDestroy() {
    if (this.deregisterAction) this.deregisterAction()
  },
  watch: {
    form: {
      handler(v) {
        const searchableFlow = createSearchableFlow(v)
        if (window.CommandBar) {
          window.CommandBar.addContext({ searchableFlow })
        }
      },
      immediate: true,
      deep: true,
    },
    viewType: {
      handler(v) {
        switch (v) {
          case 'global_components': {
            const globals = (this.form && this.form.components) || []
            const componentIsGlobal = Boolean(globals.find(g => g.id === this.currentComponentId))
            if (!componentIsGlobal) {
              const firstGlobal = globals[0]
              const key = firstGlobal ? firstGlobal.id : null
              this.setSelectedComponent(key)
            }
            break
          }
          case 'computed_fields': {
            this.tab = 'content'
            const fields = (this.form && this.form.computedFields) || []
            this.selectedComputedFieldId =
              this.selectedComputedFieldId || fields[0] ? fields[0].id : null
            this.setSelectedComponent()
            break
          }
          case 'data_outputs': {
            this.tab = 'content'
            const outputs = (this.form && this.form.dataOutputs) || []
            this.selectedDataOutputId =
              this.selectedDataOutputId || outputs[0] ? outputs[0].id : null
            this.setSelectedComponent()
            break
          }
          case 'pages':
          case 'flow': {
            this.setSelectedComponent()
            break
          }
          case 'components':
          default: {
            break
          }
        }
      },
    },
    currentPageId: {
      handler(v) {
        if (v) this.viewType = 'pages'
      },
      immediate: true,
    },
    currentComponentId: {
      handler(id, old) {
        this.showButtonDescriptions = false
        const globals = this.form.components || []
        if (old && !id) {
          switch (this.viewType) {
            case 'components': {
              this.viewType = 'pages'
              break
            }
            case 'global_components': {
              this.viewType = 'flow'
              break
            }
            default:
              break
          }
        }

        if (id) {
          const componentIsGlobal = Boolean(globals.find(g => g.id === id))
          this.viewType = componentIsGlobal ? 'global_components' : 'components'
        }
      },
      immediate: true,
    },
    paneViewButtonsEnabled: {
      handler(v) {
        this.$store.commit('setPaneViewButtons', v)
      },
      immediate: true,
    },
  },
  methods: {
    setNewKey(e) {
      const val = typeof e.target.value === 'string' ? e.target.value.trim() : e.target.value
      this.newKey = snakeCase(val)
    },
    onSelectType(e) {
      this.viewType = e.key
    },
    onViewType(type, key) {
      switch (type) {
        case 'components':
        case 'global_components':
          return this.onSelectComponent({ key })
        case 'pages':
          return this.onSelectPage({ key })
        case 'computed_fields':
          return this.onSelectComputedField({ key })
        case 'data_outputs':
          return this.onSelectDataOutput({ key })
        case 'flow':
        default:
          return this.onSelectType({ key: 'flow' })
      }
    },
    setSelectedComponent(k) {
      this.follow
        ? this._setSelectedComponentId({}, k || null)
        : (this.selectedComponentId = k || null)
    },
    async onSelectPage(e) {
      const page = this.pages.find(p => p.id === e.key)
      if (page) {
        const pageIsHidden = this.hiddenPages.has(e.key)
        this._setShowHiddenPages(pageIsHidden)
        // this._setButtonState('show-hidden-page', pageIsHidden)
        await this.$nextTick()
      }
      this.follow
        ? this._setUserData({ ...this.userData, currentPageId: e.key })
        : (this.selectedPageId = e.key)

      this.onSelectComponent({})
      this.onSelectType({ key: 'pages' })
    },
    onSelectComponent(e) {
      const globals = this.form.components || []
      const componentIsGlobal = Boolean(globals.find(g => g.id === e.key))
      const key = componentIsGlobal ? 'global_components' : 'components'
      this.onSelectType({ key })
      this.setSelectedComponent(e.key)
      // this.follow ? this._setSelectedComponentId({}, e.key) : (this.selectedComponentId = e.key)
    },
    async onSelectComputedField(e) {
      this.onSelectType({ key: 'computed_fields' })
      await this.$nextTick()
      this.selectedComputedFieldId = e.key
    },
    async onSelectDataOutput(e) {
      this.onSelectType({ key: 'data_outputs' })
      await this.$nextTick()
      this.selectedDataOutputId = e.key
    },
    backNavigate() {
      switch (this.viewType) {
        case 'components':
        case 'global_components': {
          this.setSelectedComponent(null)
          break
        }
        case 'pages':
        case 'computed_fields':
        case 'data_outputs': {
          this.viewType = 'flow'
          break
        }

        case 'flow':
        default:
          break
      }
    },
    getComponentSuffix(componentId) {
      let suffix = ''
      if ((this.form.components || []).find(({ id }) => id === componentId)) suffix += ' (Global)'
      // suffix += this.visibleComponents.find(({ id }) => id === componentId) ? '' : ' (Hidden)'
      return suffix
    },
    openModal(action, ...args) {
      this.actionButtonType = action
      this.modalVisible = true
      if (['new-component', 'new-global-component', 'load-component-type'].includes(action)) {
        if (action === 'load-component-type') {
          this.newKey = this.currentComponent.key
        }
        if (this.currentComponent) {
          this.newContainerKey =
            this.currentComponent.type === 'Container'
              ? this.currentComponent.key || undefined
              : this.currentComponent.parent_key || undefined
        } else this.newContainerKey = undefined
      }
      this.modalArgs = args && args.length > 0 ? args : []
    },
    handleAction(action, ...args) {
      switch (action) {
        case 'move-component-to-page': {
          this.moveComponentToPage(...args)
          break
        }
        case 'load-component-type': {
          this.loadComponentType(...args)
          break
        }
        case 'new-page': {
          this.newPage()
          break
        }
        case 'dupe-page': {
          this.duplicatePage(...args)
          break
        }
        case 'delete-page': {
          this.deletePage(...args)
          break
        }
        case 'new-computed-field': {
          this.newComputedField()
          break
        }
        case 'duplicate-computed-field': {
          this.duplicateComputedField()
          break
        }
        case 'new-component': {
          this.newComponent(false, ...(this.modalArgs || args))
          break
        }
        case 'new-global-component': {
          this.newComponent(true, ...(this.modalArgs || args))
          break
        }
        case 'move-component-up': {
          this.moveComponentInDirection('up')
          break
        }
        case 'move-component-down': {
          this.moveComponentInDirection('down')
          break
        }
        case 'dupe-component': {
          const componentType = this.currentComponent && this.currentComponent.type
          const forbiddenComponents = componentTypes()
            .filter(c => c.group === 'Savvy Presets')
            .map(c => c.key)

          if (new Set(forbiddenComponents).has(componentType)) {
            this.$message.warning('Cannot duplicate preset components.')
            break
          }
          this.duplicateComponent()
          break
        }
        case 'delete-component': {
          this.deleteComponent(...args)
          break
        }
        case 'new-output': {
          this.newOutput()
          break
        }

        default:
          break
      }
      this.modalArgs = []
    },
    handleOk() {
      this.modalVisible = false
      this.handleAction(this.actionButtonType, ...this.modalArgs)
      this.clearModalSettings()
    },
    clearModalSettings() {
      this.newKey = ''
      this.newType = ''
      this.newTypeData = null
      this.newContainerKey = null
      this.modalArgs = []
    },
    onSelected() {
      this.$nextTick(() => {
        this.$refs.keyInput.$el.focus()
        this.$refs.keyInput.$el.scrollIntoView({
          behavior: 'smooth',
        })
      })
    },
  },
}
</script>
