<template>
  <div class="UserDataEditor">
    <div class="cursor-pointer border rounded p-1 m-2 text-center" @click="_resetUserData()">
      <Icon name="redo" class="mr-1" />
      Reset User Data
    </div>
    <div class="cursor-pointer border rounded p-1 m-2 text-center" @click="showSchema = true">
      <Icon name="database" class="mr-1" />
      Show Schema
    </div>
    <a-divider></a-divider>
    <div
      v-for="key in userDataKeys"
      :key="key"
      class="flex"
      :class="
        typeof userData[key] === 'boolean' ? 'flex-row items-center' : 'flex-col justify-center'
      "
    >
      <div class="py-1 px-2 flex flex-row items-center">
        <EditorLabel :label="key" />
        <div
          v-if="!editableUserData[key]"
          class="ml-2 cursor-pointer opacity-80"
          @click="onOpenUserDataKey(key)"
        >
          <a-tooltip title="Go to this computed field">
            <Icon type="far" name="external-link" />
          </a-tooltip>
        </div>
      </div>
      <div class="px-2">
        <a-checkbox
          v-if="typeof userData[key] === 'boolean'"
          :checked="userData[key]"
          @change="setUserData(key, $event.target.checked, 'boolean')"
          style="padding-left: 5px;"
        />
        <a-input-number
          v-else-if="typeof userData[key] === 'number'"
          allowClear
          :disabled="!editableUserData[key]"
          :value="stringifyValue(userData[key])"
          @change="setUserData(key, $event, 'number')"
          style="width: 100%;"
        />
        <template
          v-else-if="
            fieldTypes[key] && ['OptionSelector', 'experiment'].includes(fieldTypes[key].type)
          "
        >
          <a-select
            v-if="fieldTypes[key].multiple"
            mode="tags"
            :value="userData[key]"
            @change="setUserData(key, $event, typeof userData[key])"
          >
            <a-select-option
              v-for="{ key, label } in fieldTypes[key].options || []"
              :key="key"
              :value="key"
            >
              {{ label }}
            </a-select-option>
          </a-select>
          <a-auto-complete
            v-else
            :value="userData[key]"
            :defaultValue="userData[key]"
            :dataSource="fieldTypes[key].options || []"
            @change="setUserData(key, $event, typeof userData[key])"
          />
        </template>
        <a-input
          v-else
          allowClear
          :disabled="!editableUserData[key] || key === 'entryId'"
          :value="stringifyValue(userData[key])"
          @change="setUserData(key, $event.target.value, typeof userData[key])"
        />
      </div>
    </div>
    <a-modal v-model="showSchema" :footer="null" title="User Data Schema">
      <pre class="flex flex-row" @click="copyToClipboard()">{{ userDataSchema }}</pre>
    </a-modal>
  </div>
</template>
<script>
import getButtons from '../helpers/getButtons'
import { generateUserDataSchema } from '@/components/form/editor/helpers/userData'
import { copyToClipboard } from '@/helpers/clipboard'
export default {
  name: 'UserDataEditor',
  inject: {
    _setUserData: { default: () => () => {} },
    _resetUserData: { default: () => () => {} },
  },
  props: {
    userData: Object,
    form: Object,
  },
  data() {
    return { showSchema: false }
  },
  computed: {
    schema() {
      const schema = generateUserDataSchema(this.form, this.userData)
      return schema
    },
    userDataSchema() {
      return JSON.stringify(
        this.schema,
        (key, value) => {
          return Array.isArray(value) ? value.join(' | ') : value
        },
        2
      )
    },
    userDataKeys() {
      return Object.keys(this.schema)
    },
    editableUserData() {
      const computedFields = this.form.computedFields || []
      const computedFieldKeys = new Set(computedFields.map(f => f.key))
      return Object.keys(this.userData).reduce((acc, k) => {
        acc[k] = !computedFieldKeys.has(k)
        return acc
      }, {})
    },
    fieldTypes() {
      const types = {}
      const cb = c => {
        types[c.key] = {
          type: c.type,
          multiple: c.multiple,
          options: c.buttons
            ? getButtons(c, this.userData).map(b =>
                typeof b === 'string'
                  ? { value: b, text: b, key: b, label: b }
                  : { value: b.key, text: b.text, key: b.key, label: b.text }
              )
            : [],
        }
      }
      const computedFields = this.form.computedFields || []
      computedFields.forEach(c => (types[c.key] = { type: 'computed_field' }))
      const experiments = this.form.experiments || []
      experiments.forEach(
        e =>
          (types[e.key] = {
            type: 'experiment',
            options: (e.variants || []).map(({ key }) => ({
              value: key,
              text: key,
              key: key,
              label: key,
            })),
          })
      )
      const globalComponents = this.form.components || []
      globalComponents.forEach(cb)
      const pages = this.form.pages || []
      pages.forEach(p => {
        const components = p.components || []
        components.forEach(cb)
      })

      this.userDataKeys.forEach(k => {
        if (!types[k]) types[k] = { type: 'default' }
      })

      return types
    },
  },
  watch: {},
  methods: {
    stringifyValue(val) {
      switch (typeof val) {
        case 'number':
        case 'boolean':
        case 'string': {
          return `${val}`
        }
        case 'object': {
          return JSON.stringify(val, null, 2)
        }

        default: {
          return val
        }
      }
    },
    setUserData(key, value, type) {
      const newUserData = { ...this.userData }
      switch (type) {
        case 'string': {
          if (value === 'null') newUserData[key] = null
          else if (value === 'undefined') newUserData[key] = undefined
          newUserData[key] = `${value}`
          break
        }
        case 'number': {
          newUserData[key] = +value
          break
        }
        case 'boolean': {
          newUserData[key] = typeof value === 'boolean' ? value : value === 'true' ? true : false
          break
        }
        case 'object': {
          if (Array.isArray(value)) {
            newUserData[key] = value
          } else if (!value) newUserData[key] = value
          else {
            try {
              newUserData[key] = JSON.parse(value)
            } catch (error) {
              console.error(error)
            }
          }
          break
        }

        default: {
          newUserData[key] = value
          break
        }
      }
      this._setUserData(newUserData)
    },
    onOpenUserDataKey(key) {
      const target = (this.form.computedFields || []).find(c => c.key === key)
      if (!target) return
      this.$emit('nav', `cf|${target.id}`)
    },
    async copyToClipboard() {
      await copyToClipboard(this.userDataSchema)
      await this.$nextTick()
      this.$message.info(`Schema copied to clipboard`)
    },
  },
}
</script>
