<template>
  <div class="AdminPanel">
    <a-space direction="vertical">
      <h2>Add Customer</h2>
      <label for="group">Group Name:</label>
      <input
        class="border border-gray-200 p-1 rounded"
        name="group"
        type="text"
        v-model="groupName"
      />
      <label for="email-1">Email 1:</label>
      <input
        class="border border-gray-200 p-1 rounded"
        name="email-1"
        type="text"
        v-model="emails[0]"
      />
      <label for="email-2">Email 2:</label>
      <input
        class="border border-gray-200 p-1 rounded"
        name="email-2"
        type="text"
        v-model="emails[1]"
      />
      <label for="email-3">Email 3:</label>
      <input
        class="border border-gray-200 p-1 rounded"
        name="email-3"
        type="text"
        v-model="emails[2]"
      />
      <Button text="Submit" @click.native="submitForm" />
    </a-space>
  </div>
</template>
<script>
import createGroup from '@/data/createGroup'
import { mapGetters } from 'vuex'
export default {
  props: {},
  data() {
    return { emails: [], groupName: '' }
  },
  computed: {
    ...mapGetters(['user']),
  },
  watch: {},
  methods: {
    async submitForm() {
      const { emails, groupName } = this
      const data = await createGroup(emails, groupName, this.user)
      if (data.success) {
        this.resetAllFields()
        this.$message.success(`New Group ${groupName} successfully created!`)
      } else this.$message.error('Something went wrong creating new customer')
    },
    resetAllFields() {
      this.emails.forEach((e, i) => {
        this.emails[i] = ''
      })
      this.groupName = ''
    },
  },
}
</script>
