<template>
  <div class="Analytics">
    <h3>Your snippet:</h3>
    <AnalyticsSnippet />
  </div>
</template>

<script>
import AnalyticsSnippet from '@/components/AnalyticsSnippet'

export default {
  name: 'Analytics',
  components: {
    AnalyticsSnippet,
  },
}
</script>

<style lang="scss">
@import '@/styles/_variables.scss';

.Analytics {
  h3 {
    margin: 20px 0 0;
  }
  .AnalyticsSnippet {
    margin: 0 -20px;
  }
  .snippet {
    padding: 20px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-top: 8px solid var(--color-savvy);
  }
}
</style>
