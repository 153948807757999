import Axios from 'axios'

import Firebase from 'firebase/app'
import 'firebase/auth'

export default async function api(path, data, config) {
  let { method, noAuth, rootUrl } = config || {}

  if (localStorage.SavvyDebugUseFlowsEndpoint) rootUrl = 'https://staging.trysavvy.com'

  const token = noAuth
    ? null
    : process.env.VUE_APP_FIREBASE_EMULATE
    ? 'test_token'
    : await Firebase.auth().currentUser.getIdToken()

  if (rootUrl && rootUrl.endsWith('/')) rootUrl = rootUrl.slice(0, -1)
  const fullPath = `${rootUrl || ''}/api${path}` //getPath(path)

  return Axios[method || 'post'](fullPath, {
    token,
    data,
    authKey: process.env.VUE_APP_API_ACCESS_KEY,
  })
}

// function getPath(path) {
//   const relativePath = `/api/${path.startsWith('/') ? path.slice(1) : path}`
//   const isLocal =
//     window.location.host.startsWith('localhost:') || window.location.host.startsWith('192.168.')
//   const root = isLocal
//     ? window.location.origin
//         .split(':')
//         .slice(0, -1)
//         .join(':') + ':5000'
//     : ''
//   const fullPath = `${root}${relativePath}`

//   return fullPath
// }
