<template>
  <div
    class="Contacts mb-4"
    :class="{ scroller: !preview }"
    v-infinite-scroll="loadMore"
    :infinite-scroll-distance="5"
  >
    <div v-if="!preview && numValidContacts && !usingRockset" class="text-right px-5 mt-3">
      Total Contacts Found:
      <span class="font-bold">
        {{ numValidContacts }}
      </span>
    </div>
    <!-- <Filters syncRoute class="filters" mode="Insights" @readableFilters="filters = $event" /> -->
    <Card flush class="overflow-hidden" :class="{ 'm-5': !preview, 'mt-3': !preview }">
      <Spinner v-if="loading && !loadingMore" />
      <table v-else class="w-full border-collapse shadow-lg rounded-xl">
        <!-- border border border-gray-50 -->
        <SingleContact
          v-for="contact in validContacts"
          :key="contact.id"
          :crossContaminate="contact.crossDomain"
          :extras="(extras[contact.id] || {}).computed"
          v-bind="{ contact, specifiedFlow }"
        />
        <Spinner v-if="loading && loadingMore" class="flex flex-row justify-center w-full" />
      </table>
    </Card>
    <button
      v-if="!preview && numContacts > contacts.length"
      class="button mx-auto my-8 block"
      @click="loadMore"
    >
      Load more...
    </button>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
import debounce from 'lodash/debounce'
import infiniteScroll from 'vue-infinite-scroll'
import { isEqual, get, set } from 'lodash'

import api from '@/helpers/api'
import computeFilters from '@/helpers/filters'
import getFilterString from '@/components/explorer/Filters/filtersToAlgoliaString'

import SingleContact from './SingleContact'

Vue.use(infiniteScroll)
export default {
  name: 'Contacts',
  components: {
    SingleContact,
  },
  inject: ['_register'],
  props: {
    limit: Number,
    preview: Boolean,
    presetFilters: Object,
  },
  data() {
    return {
      contacts: [],
      numContacts: null,
      tempFilters: {},
      extras: {},
      loading: true,
      loaded: false,
      loadingMore: false,
      page: 0,
      usingRockset: false,
    }
  },
  computed: {
    ...mapState(['algoliaIndices', 'contactSearch', 'showTestData']),
    ...mapGetters(['activeGroupId', 'isAdmin', 'rocksetFilterValues', 'flows']),
    contactIndex() {
      return (this.algoliaIndices && this.algoliaIndices.contacts) || null
    },
    debouncedSearch() {
      return debounce(this.search, 250, { trailing: true, leading: false })
    },
    filters: {
      get() {
        return { ...(this.presetFilters || {}), ...(this.tempFilters || {}) }
      },
      set(v) {
        this.tempFilters = v
      },
    },
    specifiedFlow() {
      return (
        (this.presetFilters &&
          this.presetFilters.flow &&
          typeof this.presetFilters.flow === 'string' &&
          this.presetFilters.flow.split('-')[0]) ||
        null
      )
    },
    validContacts() {
      const seenFlowIds = new Set(this.flows.map(f => f.id))
      const showCrossContaminates = this.isAdmin && this.showTestData
      return this.contacts.reduce((acc, c) => {
        if (c && c.flow_answers) {
          const flowIds = c.flow_answers.map(a => a.split('.')[0])
          const isCrossContaminated = !flowIds.every(id => seenFlowIds.has(id))
          const contact = isCrossContaminated ? { ...c, crossDomain: true } : c
          const shouldShowContact = isCrossContaminated ? showCrossContaminates : true
          if (shouldShowContact) acc.push(contact)

          return acc
        }
        acc.push(c)

        return acc
      }, [])
    },
    numValidContacts() {
      return this.validContacts.length
    },
  },
  watch: {
    contactSearch: {
      handler() {
        this.debouncedSearch(true)
      },
    },
    showTestData: {
      handler() {
        this.debouncedSearch()
      },
    },
    filters: {
      handler(f, o) {
        if (isEqual(f, o)) return
        if (this.loading) return
        this.debouncedSearch(true)
      },
    },
    validContacts(contacts) {
      if (!contacts.length) this.extras = {}
      if (!contacts || contacts.length === 0) return
      this.getNewContactExtras(contacts.map(c => c.id)).then(extras => {
        this.extras = extras
      })
    },
    contactIndex: {
      handler(v) {
        if (v) this.search(true)
      },
      immediate: true,
    },
  },
  async mounted() {
    if (!this.preview) this.deregisterReload = this._register('reload', () => this.search(true))
    if (!this.preview) this.deregisterFilters = this._register('setFilters', this.setFilters)

    await this.debouncedSearch(true)

    if (this.$route.path.split('/').slice(-1)[0] === 'people')
      this.$store.commit('setPageHeader', {
        title: 'People',
        icon: 'users',
      })
    await this.$nextTick()
    this.loaded = true
  },
  beforeDestroy() {
    if (this.deregisterReload) this.deregisterReload()
    if (this.deregisterFilters) this.deregisterFilters()
    this.$store.commit('setContactSearch', '')
  },
  methods: {
    setFilters(f) {
      this.filters = f
    },
    loadMore() {
      if (!this.loaded || this.preview) return
      this.page += 1
      if (!this.loading) this.debouncedSearch(undefined, true)
    },
    async search(resetCache, preserve) {
      this.loading = true
      if (!preserve) {
        this.contacts = []
        this.page = 0
      } else this.loadingMore = true

      const baseFilters = [['groupId', this.activeGroupId]]
      if (!this.showTestData) baseFilters.push(['is_test', false])

      const filters =
        Object.keys(this.filters).length > 0
          ? [...Object.entries(this.filters).map(fi => [fi[0], fi[1]]), ...baseFilters]
          : baseFilters

      const filtersString = filters
        .map(getFilterString)
        .filter(t => t && t.trim())
        .join(' AND ')

      if (filters.find(f => ['channel', 'medium', 'campaign'].includes(f[0]))) {
        this.usingRockset = true
        return await this.searchRockset(preserve)
      }
      this.usingRockset = false
      const algoliaExists = this.contactIndex && typeof this.contactIndex.clear == 'function'
      if (resetCache && algoliaExists) {
        try {
          await this.contactIndex.clear()
        } catch (error) {
          console.error(error)
        }
      }
      await this.waitForAlgolia()
      const index = this.contactIndex && this.contactIndex.index
      const results = await index.search(this.contactSearch, {
        filters: filtersString,
        hitsPerPage: this.limit || 20,
        page: this.page,
        distinct: 1,
      })
      return Promise.all(
        results.hits.map(async hit => {
          const res = await index.search('', { filters: `baseId:"${hit.baseId}"`, distinct: 0 })
          return res.hits.length
            ? res.hits.reduce((acc, hh) => ({ ...hit, ...acc, ...hh }), {})
            : hit
        })
      )
        .then(res => [
          res.map(hit => ({
            ...hit,
            id: hit.baseId || hit.objectID,
            _highlightResult: undefined,
            flows: flowAnswersToFlowObject(hit.flow_answers || []),
          })),
          results.nbHits,
        ])
        .then(([contacts, numContacts]) => {
          return new Promise(resolve => {
            setTimeout(() => {
              this.numContacts = numContacts
              this.setContacts(contacts, preserve)
              // if (preserve) this.contacts = [...this.contacts, ...contacts]
              // else this.contacts = contacts
              return resolve()
            }, 50)
          })
        })
        .catch(err => {
          console.error('Error fetching contacts:', err)
          this.$message.error('Error fetching contacts')
        })
        .finally(() => {
          this.loading = false
          this.loadingMore = false
        })
    },
    setContacts(contacts, preserve) {
      const ids = new Set()
      if (preserve)
        this.contacts = [...this.contacts, ...contacts].filter(c => {
          if (ids.has(c.id)) return false
          ids.add(c.id)
          return true
        })
      else this.contacts = contacts
    },
    async waitForAlgolia() {
      const interval = 250
      const timeout = 10 * 1000
      for (let i = 0; i < timeout; i = i + interval) {
        if (this.contactIndex) {
          console.log(`Waited ${i}ms for Algolia index to be ready.`)
          return
        }
      }
      console.log(`Waited ${timeout}ms but Algolia still wasn't ready`)
      this.$message.error(`Contacts could not be loaded`)
    },
    async getNewContactExtras(contactIds) {
      return (await api('/contacts/values', { contactIds })).data.contacts
    },
    async searchRockset(merge) {
      const simpleFilters = Object.entries(this.filters)
        .map(fi => [fi[0], fi[1]])
        .filter(f => !['journey', 'stage'].includes(f[0]))
      const filters = computeFilters(simpleFilters, {
        rocksetValues: this.rocksetFilterValues,
        aliases: {},
      })
      const date =
        merge && this.contacts.length > 0
          ? this.contacts[this.contacts.length - 1].updatedAt.seconds
            ? this.contacts[this.contacts.length - 1].updatedAt.seconds * 1000
            : this.contacts[this.contacts.length - 1].updatedAt
          : null
      const after = merge && this.contacts.length > 0 ? new Date(date).toISOString() : null
      const data = {
        filters,
        funnelId: 'default_funnel',
        groupId: this.activeGroupId,
        searchText: this.contactSearch ? this.contactSearch.trim() : undefined,
        limit: this.limit || 20,
        after,
        includeTest: this.showTestData,
      }
      try {
        const result = await api('/contacts', data)
        const contacts = get(result, 'data.funnel.data.contacts', [])
        const contactIds = contacts.map(c => c.id)
        contactIds.push(...contacts.map(c => `${c.id}|1`))
        const results =
          (await (this.contactIndex && this.contactIndex.index.getObjects(contactIds))) || []
        const preformatContacts = results.results.reduce((acc, hit) => {
          if (hit) {
            const id = hit.baseId || hit.objectID
            acc[id] = { ...(acc[id] || {}), ...hit }
          }

          return acc
        }, {})
        const finalContacts = Object.values(preformatContacts).map(hit => ({
          ...(hit || {}),
          id: hit && (hit.baseId || hit.objectID),
          _highlightResult: undefined,
          flows: flowAnswersToFlowObject(hit.flow_answers || []),
        }))
        this.setContacts(finalContacts, merge)
        // this.contacts = merge ? [...this.contacts, ...finalContacts] : finalContacts
      } catch (error) {
        console.error('Error fetching contacts:', error)
        this.$message.error('Error fetching contacts')
      }
      this.loading = false
      this.loadingMore = false
    },
  },
}

function flowAnswersToFlowObject(answers) {
  return (answers || []).reduce((acc, item) => {
    const [flowId, ...path] = item.split(/\.|:/)
    const key = path.slice(0, -1)
    const val = path[path.length - 1]
    if (!acc[flowId]) acc[flowId] = {}
    set(acc[flowId], key, val)
    // acc[flowId][key] = val
    return acc
  }, {})
}
</script>

<style lang="scss" scoped>
@import '@/styles/_variables.scss';

.Contacts {
  > table {
    overflow: hidden;
  }
}
</style>
