<template>
  <div class="PageRequirementsViewer">
    <slot />
    <EditorLabel label="Invalid Components" />
    <div class="flex flex-wrap" style="max-width: 450px">
      <ComponentPill
        v-for="component in componentsValidState.invalid"
        :key="component.id"
        :componentId="component.id"
        :label="component.key"
        :type="component.type"
        :required="component.required"
        :hidden="component.hidden"
      />
    </div>
    <EditorLabel label="Valid Components" />
    <div class="flex flex-wrap" style="max-width: 450px">
      <ComponentPill
        v-for="component in componentsValidState.requiredValid"
        :key="component.id"
        :componentId="component.id"
        :label="component.key"
        :type="component.type"
        :required="component.required"
        :hidden="component.hidden"
      />
      <template v-if="showOptionalComponents">
        <ComponentPill
          v-for="component in componentsValidState.optionalValid"
          :key="component.id"
          :componentId="component.id"
          :label="component.key"
          :type="component.type"
          :required="component.required"
          :hidden="component.hidden"
        />
      </template>
      <Pill
        v-if="componentsValidState.optionalValid.length > 0"
        class="cursor-pointer"
        @click="showOptionalComponents = !showOptionalComponents"
      >
        {{ showOptionalComponents ? 'Hide' : 'Show More...' }}
      </Pill>
    </div>
  </div>
</template>
<script>
import { componentPassesValidation } from '@/components/form/helpers/validation.js'
import { passesConditions } from '@/components/form/helpers/conditions'
import ComponentPill from '@/components/form/editor/ComponentPill.vue'

export default {
  name: 'PageRequirementsViewer',
  components: { ComponentPill },
  props: { page: Object, form: Object, userData: Object },
  data() {
    return { showOptionalComponents: false }
  },
  computed: {
    componentsValidState() {
      if (!this.page || !this.userData) return []
      const components = this.page.components || []
      const simpleArray = components.map(c => {
        const required = Boolean(c.isRequired)
        const isValid = componentPassesValidation(c, this.userData, this.form)
        const hidden =
          c.hide || !passesConditions(c.conditions || [], this.userData, undefined, true)
        return { key: c.key, id: c.id, isValid, hidden, required, type: c.type }
      })

      const valid = simpleArray.filter(c => c.isValid)
      return {
        requiredValid: valid.filter(c => c.required),
        optionalValid: valid.filter(c => !c.required),
        invalid: simpleArray.filter(c => !c.isValid),
      }
    },
  },
}
</script>
