import Vue from 'vue'
import LogRocket from 'logrocket'
import Firebase from 'firebase/app'
// import * as Sentry from '@sentry/vue'
// import { Integrations } from '@sentry/tracing'

import FlowSwitch from './FlowSwitch.vue'
import router from './router'
import store from './store'
import Filters from '@/setup/filters'
import '@/setup/fontAwesome'
import { getEnv } from '@/helpers/env'

import Alert from '@/components/utilities/Alert'
import Button from '@/components/utilities/Button'
import Card from '@/components/utilities/Card'
import Pill from '@/components/utilities/Pill'
import DotsMenu from '@/components/utilities/DotsMenu'
import EditorLabel from '@/components/utilities/EditorLabel'
import EditorHeading from '@/components/utilities/EditorHeading'
import ClickToScroll from '@/components/utilities/ClickToScroll'
import ColorSwitcher from '@/components/utilities/ColorSwitcher'
import Dropdown from '@/components/utilities/Dropdown'
import EditableText from '@/components/utilities/EditableText'
import ComponentLink from '@/components/utilities/ComponentLink'
import SmartContent from '@/components/utilities/SmartContent'
import ContentLoop from '@/components/utilities/ContentLoop'
import Icon from '@/components/utilities/Icon'
import LinkBlock from '@/components/utilities/LinkBlock'
import Popup from '@/components/utilities/Popup'
import PopupNew from '@/components/utilities/PopupNew'
import SafeImage from '@/components/utilities/SafeImage'
import Spinner from '@/components/utilities/Spinner'
import ToggleSwitch from '@/components/utilities/ToggleSwitch'
import Annotation from '@/components/utilities/Annotation'
import Entity from '@/components/logic/Entity'
import Entities from '@/components/logic/Entities'

// const HOSS_TOKEN = getEnv('HOSS_TOKEN')
// if (window.hoss && HOSS_TOKEN) {
//   window.hoss(HOSS_TOKEN)
// }

var firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSENGER_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
}

// Initialize Firebase
Firebase.initializeApp(firebaseConfig)

const isReflectTest = window.isReflectTest || window.location.hostname === 'localhost'
//|| (window.location.search && window.location.search.includes('savvy_test=true'))
const isFlows =
  window.location.host === 'flows.trysavvy.com' && !window.location.pathname.endsWith('/feedback')
const LOGROCKET_PROJECT = getEnv('LOGROCKET_PROJECT')

if (!isReflectTest && !isFlows)
  LogRocket.init(isFlows ? `savvy/savvy-flows` : `savvy/${LOGROCKET_PROJECT}`, {
    network: {
      requestSanitizer: request => {
        // if the request is to Highlight.run
        if (request.url.startsWith('https://pub.highlight.run')) {
          // ignore the request response pair
          return null
        }

        // otherwise log the request normally
        return request
      },
    },
  })

// const environmentPrefix = window.location.host.includes('localhost') ? 'local' : 'deployed'

// if (process.env.VUE_APP_SENTRY_DSN)
//   Sentry.init({
//     Vue,
//     dsn: process.env.VUE_APP_SENTRY_DSN,
//     environment: `${process.env.NODE_ENV}-${environmentPrefix}`,
//     logErrors: true,
//     integrations: [new Integrations.BrowserTracing()],
//     tracesSampleRate: 1.0,
//   })

Vue.component('Alert', Alert)
Vue.component('Button', Button)
Vue.component('Card', Card)
Vue.component('Pill', Pill)
Vue.component('DotsMenu', DotsMenu)
Vue.component('ComponentLink', ComponentLink)
Vue.component('EditorLabel', EditorLabel)
Vue.component('EditorHeading', EditorHeading)
Vue.component('ClickToScroll', ClickToScroll)
Vue.component('ColorSwitcher', ColorSwitcher)
Vue.component('Dropdown', Dropdown)
Vue.component('EditableText', EditableText)
Vue.component('SmartContent', SmartContent)
Vue.component('ContentLoop', ContentLoop)
Vue.component('Icon', Icon)
Vue.component('LinkBlock', LinkBlock)
Vue.component('Popup', Popup)
Vue.component('PopupNew', PopupNew)
Vue.component('SafeImage', SafeImage)
Vue.component('Spinner', Spinner)
Vue.component('ToggleSwitch', ToggleSwitch)
Vue.component('Annotation', Annotation)
Vue.component('Entity', Entity)
Vue.component('Entities', Entities)

Vue.config.productionTip = false
Vue.config.devtools = true

Filters.forEach(filter => Vue.filter(filter.name, filter.function))

new Vue({
  router,
  store,
  render: h => h(FlowSwitch),
}).$mount('#app')

/* 

"rrweb": "git+https://github.com/rrweb-io/rrweb.git",
"rrweb-player": "^0.4.6",
"rrweb-snapshot": "git+https://github.com/rrweb-io/rrweb-snapshot.git",

*/
