var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Pill py-0.5 text-xs rounded-full bg-white border-gray-200 items-center group",class:[
    _vm.clickableness,
    {
      selected: _vm.selected,
      border: !_vm.borderless,
      'px-2': !_vm.borderless,
      block: _vm.block,
      flex: !_vm.block,
      'm-1': !_vm.flush,
      'm-0.5': _vm.tight,
      'bg-purple': _vm.selected,
      'text-white': _vm.selected,
      'border-purple': _vm.selected,
      'whitespace-nowrap': !_vm.allowMultipleLines,
      filled: _vm.color && _vm.filled,
      hoverFilled: _vm.color && _vm.hoverFilled,
    } ],style:({
    '--color': _vm.color,
    '--border-color': _vm.color,
    '--filled-color': _vm.filledColor,
    'border-style': _vm.dashed && 'dashed',
    'border-width': _vm.dashed && '1.5px',
  }),on:{"click":function($event){$event.stopPropagation();return _vm.$emit('click', $event)}}},[(_vm.icon)?_c('Icon',{staticClass:"mr-1 mb-px",attrs:{"name":_vm.icon}}):_vm._e(),_vm._t("default"),(_vm.closeable || _vm.closeableHover)?_c('Icon',{staticClass:"ml-1 cursor-pointer group-hover:block",class:{
      hidden: _vm.closeableHover,
      'hover:text-purple': !_vm.clickable,
      'hover:opacity-70': _vm.clickable,
    },attrs:{"name":"times"},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.$emit('close')}}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }