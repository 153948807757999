<template>
  <div
    class="FlowThumbnail relative bg-cover rounded-xl"
    :class="{ quickPlay }"
    :style="{ backgroundImage: `url(${thumIoImageURL})` }"
  >
    <Pill class="absolute -top-5 left-2 z-10 text-base shadow">{{ flow && flow.title }}</Pill>
    <div class="buttons-container rounded-xl">
      <div class="buttons">
        <!-- <a-button icon="select" type="primary" @click="quickPlay = true">Quick Play</a-button> -->
        <a-button icon="home" @click="overview">Overview</a-button>
        <a-button icon="edit" @click="edit">View + Edit</a-button>
        <!-- <a-button icon="fullscreen" @click="preview">Preview</a-button> -->
        <a-button icon="team" @click="viewEntries">Submissions</a-button>
      </div>
    </div>
    <div
      v-if="unresolvedCommentCount > 0"
      class="absolute bottom-2 right-2 bg-yellow-500 px-2 py-0.5 rounded-full text-xs font-bold text-white z-50"
    >
      {{ unresolvedCommentCount }}
      <Icon name="comment" class="ml-0.5" style="font-size: 0.65rem" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { mapParams } from '@/helpers/router'
import { getThreadsFromComments, getThreadsFilteredFn } from './editor/helpers/comments.js'
import getThumbnailUrl from '@/components/form/editor/helpers/thumbnailUrl'
// import SignpostForm from './SignpostForm'
import Firebase from '@firebase/app'
import '@firebase/firestore'

export default {
  name: 'FlowThumbnail',
  components: {
    // SignpostForm,
  },
  props: {
    config: Object,
    flowId: String,
    flow: Object,
  },
  data() {
    return {
      quickPlay: false,
      comments: [],
      highestVersion: [],
    }
  },
  computed: {
    ...mapGetters(['isAdmin']),
    ...mapParams(['id']),
    highestVersionNumber() {
      return (
        (this.highestVersion[0] && this.highestVersion[0].version) ||
        (this.flow && this.flow._meta && this.flow._meta.version)
      )
    },
    thumIoImageURL() {
      return getThumbnailUrl(this.flowIdCalc, this.highestVersionNumber || 0)
    },
    flowIdCalc() {
      return (this.config && this.config.flowId) || this.flowId || this.id
    },
    flowPath() {
      return `/project/flows/${this.flowIdCalc}`
    },
    flowTitle() {
      return 'Title'
    },
    commentWatcher() {
      if (!this.highestVersion[0]) return null
      return `${this.flowId}-${this.highestVersionNumber}`
    },
    unresolvedCommentCount() {
      return getThreadsFilteredFn(
        getThreadsFromComments(this.comments),
        this.version
      )({ resolved: false }).length
    },
  },
  watch: {
    flowId: {
      handler() {
        this.bindHighestVersion()
      },
      immediate: true,
    },
    commentWatcher: {
      handler(id) {
        if (id) this.bindVersionComments()
      },
      immediate: true,
    },
  },
  methods: {
    bindHighestVersion() {
      let ref = Firebase.firestore()
        .collection('forms')
        .doc(this.flowId)
        .collection('history')
        .orderBy('version', 'desc')
        .limit(1)

      this.$bind('highestVersion', ref)
    },
    bindVersionComments() {
      let ref = Firebase.firestore()
        .collection('forms')
        .doc(this.flowId)
        .collection('comments')

      if (this.flow && this.highestVersionNumber)
        ref = ref.where('version', '<=', this.highestVersionNumber)
      this.$bind('comments', ref)
    },
    overview() {
      this.$router.push(`${this.flowPath}`)
    },
    edit() {
      this.$router.push(`${this.flowPath}/edit`)
    },
    preview() {
      this.$router.push(`${this.flowPath}/preview`)
    },
    viewEntries() {
      this.$router.push(`${this.flowPath}/entries`)
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/_variables.scss';

.FlowThumbnail {
  position: relative;
  height: 100%;

  .buttons-container,
  .flow-embed-container {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
  .buttons-container {
    @include flex('center');
    z-index: 2;
    transition: opacity 0.3s;
    background: rgba(0, 0, 0, 0.1);
    opacity: 0;

    &:hover {
      opacity: 1;
    }

    .buttons {
      flex: 1;
      @include flex('center', 'wrap');

      > button {
        margin: 4px;
      }
    }
  }
  .flow-embed-container {
    z-index: 1;
    border-radius: 12px;

    .SignpostForm {
      margin: 0;
      padding: 0;
      width: 100%;
      border: none;
      // transform: scale(0.75);
      // transform-origin: top center;
      // width: 150%;
      // margin: 0 -25% -50%;
    }
  }
}
</style>
