<template>
  <div class="DesignInput flex flex-row w-full justify-stretch">
    <a-auto-complete
      class="p-1 w-1/2 flex-1"
      size="small"
      v-bind="{
        defaultValue: showColor ? cssDefaultValue : defaultValue,
        value: showColor ? cssValue : value,
        dataSource,
        placeholder,
      }"
      :filterOption="filterOption"
      @blur="$emit('blur')"
      @change="onChange"
    />
    <a-tooltip
      v-if="suffixes && suffixes.length"
      slot="suffix"
      placement="right"
      title="Insert template values"
    >
      <a-dropdown :trigger="['click']" class="ml-1">
        <a-menu slot="overlay" @click="onSelect">
          <a-menu-item :key="'__null'"> <a-icon type="link" /> N/A </a-menu-item>
          <a-menu-item v-for="key in suffixes" :key="key">
            <a-icon type="link" /> {{ key }}
          </a-menu-item>
        </a-menu>
        <a-button
          type="dashed"
          shape="circle"
          size="small"
          style="font-family: monospace; font-size: 11px; font-weight: bold; color: #777; margin-right: -5px;"
        >
          {{ currentSuffix === '__null' ? 'N/A' : currentSuffix }}
        </a-button>
      </a-dropdown>
    </a-tooltip>
    <div class="ml-1">
      <a-input
        v-if="showColor"
        type="color"
        size="small"
        v-bind="{ defaultValue, value, placeholder }"
        @change="onColorChange"
        style="width: 5ch;"
      />
    </div>
  </div>
</template>
<script>
import { getCSSColors } from '../helpers/constants'
export default {
  name: 'DesignInput',
  props: {
    styleObj: Object,
    defaultValue: String,
    value: String,
    dataSource: Array,
    placeholder: [String, Number],
    suffixes: Array,
    currentSuffix: String,
    showColor: Boolean,
  },
  data() {
    return {}
  },
  computed: {
    cssColors() {
      return getCSSColors()
    },
    cssValue() {
      return this.getCSSValue(this.value)
    },
    cssDefaultValue() {
      return this.getCSSValue(this.defaultValue)
    },
  },
  mounted() {
    if (this.currentSuffix && this.value) this.$emit('default-suffix', this.currentSuffix)
  },
  watch: {},
  methods: {
    onChange(e) {
      const value = this.showColor ? this.cssColors[e] || e : e
      const val = typeof value === 'string' ? value.replace(/;|!important/g, '') : value // NEW
      this.$emit('change', val)
    },
    getCSSValue(value) {
      const cssColor = Object.entries(this.cssColors).find(([, v]) => v === value)
      return cssColor ? cssColor[0] : value
    },
    onColorChange(e) {
      const value = e.target.value
      const colorHex = this.cssColors[value] || value
      this.$emit('change', colorHex)
    },
    onSelect(event) {
      this.$emit('suffix', event.key)
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toUpperCase().indexOf(input.toUpperCase()) >= 0
      )
    },
  },
}
</script>
