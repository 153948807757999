<template>
  <div class="MediaImageEditor">
    <a-space direction="vertical">
      <template v-if="location === 'content'">
        <EditorHeading icon="paragraph" title="Content" />
        <EditorLabel class="mb-0" label="Image / GIF URL" />
        <div class="flex">
          <Uploader @update="onUpdate('src', $event)" />
          <EditorInput
            noLabel
            class="flex-grow ml-2"
            allowComputed
            _key="src"
            placeholder="Image / GIF URL"
            v-bind="{ component }"
          />
        </div>
        <EditorInput _key="caption" placeholder="Caption" v-bind="{ component }" />
        <EditorInput _key="alt_text" placeholder="Alt text" v-bind="{ component }" />
      </template>
      <!-- <template v-else-if="location === 'design-footer'">
        <EditorHeading icon="caret-down" title="Other Design Options" />
        <EditorCheckbox _key="roundedCorners" label="Rounded Corners" class="mb-8" />
      </template> -->
    </a-space>
  </div>
</template>

<script>
import Uploader from './helpers/Uploader'

export default {
  name: 'MediaImageEditor',
  components: {
    Uploader,
  },
  inject: ['_updateData', '_getBasePath'],
  props: {
    componentId: String,
    component: Object,
    location: String,
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    onUpdate(key, val) {
      this._updateData(this._getBasePath(), key, val)
    },
  },
}
</script>

<style lang="scss">
.ant-upload.ant-upload-drag .ant-upload.ant-upload-btn {
  padding: 6px 8px 5px;
}
</style>
