<template>
  <div class="LottiePlayerEditor">
    <template v-if="location === 'options' || !location">
      <EditorInput _key="src" placeholder="Source JSON Url" label="Source" :component="component" />
      <EditorSelect _key="mode" defaultVal="normal" :options="['normal', 'bounce']" />
      <EditorCheckbox _key="autoplay" defaultTrue />
      <EditorCheckbox _key="loop" defaultTrue />
      <EditorCheckbox _key="controls" defaultTrue label="Show Player Controls" />
      <EditorCheckbox _key="hover" label="Play on Hover" />
    </template>
  </div>
</template>
<script>
export default {
  name: 'LottiePlayerEditor',
  props: {
    component: Object,
    componentIndex: Object,
    page: Object,
    location: String,
  },
  data() {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
}
</script>
