var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"EditorPane flex-1 flex flex-col scroller"},[(_vm.blameVersion && _vm.tab === 'options')?_c('div',{staticClass:"px-4"},[_vm._v(" Last Edited: v"+_vm._s(_vm.blameVersion.version)+" by "+_vm._s(_vm.blameVersion.author && _vm.blameVersion.author.userName)+" ")]):_vm._e(),(_vm.tab !== 'design')?_c('EditorTab',_vm._b({attrs:{"location":_vm.tab}},'EditorTab',_vm.editorTabProps,false)):[_c('DesignTab',_vm._b({attrs:{"location":"design"}},'DesignTab',_vm.editorTabProps,false)),_c('EditorTab',_vm._b({attrs:{"location":"design-footer"}},'EditorTab',_vm.editorTabProps,false))],_c('a-modal',{attrs:{"title":_vm.title,"okButtonProps":{ props: { disabled: !_vm.newKey }, on: {} }},on:{"ok":_vm.handleOk,"cancel":_vm.clearModalSettings},model:{value:(_vm.modalVisible),callback:function ($$v) {_vm.modalVisible=$$v},expression:"modalVisible"}},[_c('div',{staticClass:"overflow-hidden flex flex-col"},[(['new-page'].includes(_vm.actionButtonType))?[_c('ElementCardSelector',{attrs:{"dataType":"pageLayouts","typeKey":_vm.newTypeData && _vm.newTypeData.key},on:{"selected":_vm.onSelected},model:{value:(_vm.newTypeData),callback:function ($$v) {_vm.newTypeData=$$v},expression:"newTypeData"}}),_c('div',{staticClass:"flex flex-row items-center"},[_c('EditorLabel',{staticClass:"mr-2",attrs:{"label":"Insert Page after current page"}}),_c('a-checkbox',{model:{value:(_vm.insertAfterPage),callback:function ($$v) {_vm.insertAfterPage=$$v},expression:"insertAfterPage"}})],1)]:_vm._e(),(
          ['new-global-component', 'new-component', 'load-component-type'].includes(
            _vm.actionButtonType
          )
        )?[_c('ElementCardSelector',{attrs:{"typeKey":_vm.newTypeData && _vm.newTypeData.key},on:{"selected":_vm.onSelected},model:{value:(_vm.newTypeData),callback:function ($$v) {_vm.newTypeData=$$v},expression:"newTypeData"}})]:_vm._e()],2),_c('EditorLabel',{attrs:{"label":"Enter a key"}}),_c('a-input',{ref:"keyInput",attrs:{"value":_vm.newKey},on:{"change":_vm.setNewKey,"pressEnter":function($event){$event.preventDefault();return _vm.handleOk($event)}}}),(_vm.newKeyIsDupe)?_c('a-alert',{attrs:{"type":"warning","message":"Key is already in use","showIcon":""}}):_vm._e(),(
        ['new-global-component', 'new-component', 'load-component-type'].includes(
          _vm.actionButtonType
        )
      )?[_c('EditorLabel',{attrs:{"label":"Container Key"}}),_c('a-select',{staticStyle:{"width":"100%"},model:{value:(_vm.newContainerKey),callback:function ($$v) {_vm.newContainerKey=$$v},expression:"newContainerKey"}},_vm._l((_vm.containerOptions),function(ref){
      var key = ref.key;
      var label = ref.label;
return _c('a-select-option',{key:key,attrs:{"value":key}},[_vm._v(" "+_vm._s(label)+" ")])}),1)]:_vm._e()],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }