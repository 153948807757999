import Firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/auth'
import 'firebase/firestore'

export default function initializeAuth(onLogin, onLogout) {
  Firebase.analytics()
  Firebase.auth().useDeviceLanguage()

  if (process.env.VUE_APP_FIREBASE_EMULATE) {
    Firebase.auth().useEmulator('http://localhost:9099/')
    Firebase.firestore().useEmulator('localhost', 8888)
    Firebase.functions().useEmulator('localhost', 5001)
  }

  const unsubscribe = Firebase.auth().onAuthStateChanged(user =>
    user ? onLogin(user) : onLogout()
  )

  Firebase.auth().getRedirectResult()
  return unsubscribe
}

export async function loginViaService(provider) {
  const signIn = getSignInMethod(provider)
  if (!signIn) return
  try {
    return await signIn()
  } catch (error) {
    console.error('Firebase Auth Error:', error)
    throw error
  }
}

export function logout() {
  return Firebase.auth().signOut()
}

function getSignInMethod(provider) {
  switch (provider) {
    case 'facebook':
    case 'google': {
      const firebaseProvider = getFirebaseProvider(provider)

      return function() {
        // const functionKey = window.interop ? 'signInWithRedirect' : 'signInWithPopup'
        const functionKey = 'signInWithRedirect'

        return Firebase.auth()[functionKey](firebaseProvider)
      }
    }
    default:
      return null
  }
}

function getFirebaseProvider(provider) {
  switch (provider) {
    case 'facebook':
      return new Firebase.auth.FacebookAuthProvider()
    case 'google':
      return new Firebase.auth.GoogleAuthProvider()
  }
}
