<template>
  <div
    class="NavPagePane m-2 mx-4 relative rounded border-gray-200 overflow-hidden cursor-pointer transform hover:border-gray-400 hover:scale-105 transition-all group"
    style="padding-bottom: 50%"
    ref="root"
    :id="value"
    :class="{
      'border-purple': userData.currentPageId === value.slice(2),
      'hover:border-purple': userData.currentPageId === value.slice(2),
      'border-2': selectedKeys.includes(value),
      border: !selectedKeys.includes(value),
    }"
    @click="onClick"
  >
    <template v-if="url">
      <img
        v-if="backupUrl && backupUrl !== url && showImage && showBackupUrl"
        class="absolute inset w-full hover:opacity-100"
        :class="{ 'opacity-50': pageIsHidden }"
        :src="backupUrl"
        :alt="`Page ${index + 1}`"
      />
      <img
        v-if="showImage"
        class="absolute inset w-full hover:opacity-100"
        :class="{ 'opacity-50': pageIsHidden }"
        :src="url"
        :alt="`Page ${index + 1}`"
        @load="showBackupUrl = false"
      />
    </template>
    <Icon
      v-else
      class="absolute inset w-full hover:opacity-100 text-xl opacity-50"
      name="edit"
      style="left: 45%; top: 40%;"
    />
    <div
      class="handle absolute top-0 left-0 bottom-0 bg-white border-r border-gray-300 flex flex-col justify-center p-1 opacity-0 cursor-move group-hover:opacity-100 hover:bg-gray-50 transition-all"
    >
      <Icon name="grip-horizontal" class="text-sm" type="fas" />
    </div>
    <Icon
      v-if="pageIsHidden"
      class="absolute top-2 right-2 text-gray-600 transform group-hover:scale-110"
      :class="{
        'text-purple': userData.currentPageId === value.slice(2),
      }"
      name="eye-slash"
    />
    <div
      v-if="numComments"
      class="absolute bottom-2 right-2 bg-yellow-500 px-2 py-0.5 rounded-full text-xs font-bold text-white"
    >
      {{ numComments }}
      <Icon name="comment" class="ml-0.5" style="font-size: 0.65rem" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavPagePane',
  props: {
    currentPageId: String,
    index: Number,
    url: String,
    value: String,
    hiddenPages: Set,
    userData: Object,
    selectedKeys: Array,
    isInView: Boolean,
    numComments: Number,
  },
  data() {
    return { backupUrl: null, showBackupUrl: true, showImage: false }
  },
  computed: {
    pageId() {
      return this.value.split('|')[1]
    },
    pageIsHidden() {
      return this.hiddenPages.has(this.value.slice(2))
    },
    isCurrentPage() {
      return this.currentPageId === this.pageId
    },
    mode() {
      return this.noAuthComments ? 'use' : this.$store.state.editorMode
    },
  },
  watch: {
    isCurrentPage: {
      handler(v) {
        if (v) {
          this.scrollPage()
        }
      },
    },
    url: {
      handler(v, o) {
        this.showBackupUrl = true
        this.backupUrl = o || v
      },
      immediate: true,
    },
    isInView: {
      handler(v) {
        if (v) this.showImage = true
      },
      immediate: true,
    },
  },
  mounted() {
    if (this.isCurrentPage) this.scrollPage()
  },
  methods: {
    onClick() {
      if (this.pageIsHidden && this.mode === 'preview') {
        this.$message.warning('Unable to view hidden pages in Preview Mode')
      } else this.$emit('select')
    },
    async scrollPage() {
      if (this.isInView) return
      const el = this.$refs.root // document.getElementById(this.value)
      await this.$nextTick()
      el.scrollIntoView({ behavior: 'smooth', block: 'center' })
    },
  },
}
</script>
