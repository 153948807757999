<template>
  <div class="FlowAnalyticsCardSeries"></div>
</template>

<script>
import { mapState } from 'vuex'
import debounce from 'lodash/debounce'

export default {
  name: 'FlowAnalyticsCardSeries',
  props: {
    form: Object,
    chart: Object,
    algoliaFilterString: String,
    tab: String,
    currentPageId: String,
  },
  data() {
    return {
      searchResults: [],
      showNum: false,
      maxWidth: 0,
      searchToken: 0,
      loadingData: true,
      showConditionalPages: false,
      algoliaResults: null,
    }
  },
  computed: {
    ...mapState(['algoliaIndices']),
    isFunnel() {
      return this.chart.key === '_funnel'
    },
    debounceSearch() {
      return debounce(this.search, 100, { trailing: true, leading: false })
    },
    updateSearchTrigger() {
      return [this.algoliaIndices, this.algoliaFilterString, this.form]
    },
    updateWidthTrigger() {
      return [this.data, this.showNum]
    },
    currentPageKey() {
      return (this.form.pages.find(p => p.id === this.currentPageId) || {}).key
    },
    metricKey() {
      return getMetricKey(this.chart.key, this.currentPageKey, this.isFunnel)
    },
    allPages() {
      return this.form.pages.filter(p => !p.hide)
    },
    allUnconditionalPages() {
      return this.allPages.filter(p => noSeriousConditions(p))
    },
    allShowingPages() {
      return this.showConditionalPages ? this.allPages : this.allUnconditionalPages
    },
    data() {
      let data1 = this.isFunnel
        ? this.searchResults
        : this.searchResults.filter(r => r.key === this.metricKey)

      let data = data1.reduce((acc, item) => {
        const newItems = item.value.split(',').map(value => ({ ...item, value }))

        newItems.forEach(item => {
          const index = acc.findIndex(i => i.value === item.value)

          if (index === -1) acc.push(item)
          else acc[index].count += item.count
        })

        return acc
      }, [])

      const total = this.isFunnel
        ? Math.max(...data.map(d => d.count))
        : data.reduce((acc, d) => acc + d.count, 0)

      const currentShowingPageIndex = this.allShowingPages.findIndex(
        p => p.id === this.currentPageId
      )

      if (this.isFunnel) {
        data = this.allShowingPages
          .filter(
            (p, index) =>
              this.tab === 'all_pages' ||
              (index > currentShowingPageIndex - 3 && index < currentShowingPageIndex + 3)
          )
          .map(p => data.find(r => r && r.value === p.key) || { value: p.key, count: 0 })
      } else {
        data.sort((a, b) => b.count - a.count)
      }

      const max = Math.max(...data.map(d => d.count))

      return data
        .filter(row => row)
        .map(row => ({
          ...row,
          valueText: this.isFunnel
            ? `${this.allPages.findIndex(p => p.key === row.value) + 1}. ${(row && row.value) ||
                ''}`
            : row.value,
          countPercent: (row.count || 0) / total,
          isCurrentPage: row.value === this.currentPageKey,
          isLargest: row.count === max,
        }))
    },
    algoliaParams() {
      const key = this.isFunnel ? 'flow_pages_viewed' : `flow_answers`
      const searchTerm = this.isFunnel ? `${this.form.id}.` : `${this.form.id}.${this.metricKey}`
      const filters = this.algoliaFilterString

      return { key, searchTerm, filters }
    },
    updates() {
      return [this.data, this.loadingData]
    },
  },
  watch: {
    updateSearchTrigger: {
      handler() {
        this.debounceSearch()
      },
      immediate: true,
      deep: true,
    },
    updates: {
      handler(updates) {
        this.$emit('update', updates)
      },
      immediate: true,
    },
  },
  methods: {
    search() {
      this.searchToken++
      const searchToken = this.searchToken
      this.loadingData = true

      const algoliaContactsIndex =
        (this.algoliaIndices &&
          this.algoliaIndices.contacts &&
          this.algoliaIndices.contacts.index) ||
        null

      if (!algoliaContactsIndex) return

      const { key, searchTerm, filters } = this.algoliaParams

      algoliaContactsIndex
        .searchForFacetValues(key, searchTerm, {
          filters,
          maxFacetHits: 100,
        })
        .then(res => {
          if (searchToken !== this.searchToken) return

          this.algoliaResults = res

          this.searchResults = this.algoliaResults.facetHits
            .map(h => {
              if (this.isFunnel) {
                const [flowId, value] = h.value.split('.')
                return {
                  count: h.count,
                  flowId,
                  value,
                }
              } else {
                const [flowId, key, value] = h.value.split(/\.|:/)
                return {
                  count: h.count,
                  flowId,
                  key,
                  value,
                }
              }
            })
            .filter(h => h.flowId === this.form.id)
        })
        .catch(err => {
          console.log('qwer err', err)
        })
        .finally(() => {
          if (searchToken === this.searchToken) this.loadingData = false
        })
    },
  },
}

function getMetricKey(key, currentPageKey, isFunnel) {
  return isFunnel ? currentPageKey : key
}

function noSeriousConditions(p) {
  return !p.conditions || !p.conditions.length // || !p.conditions.filter(allowsDefaults).length
}

// function allowsDefaults(c) {
//   return (
//     (c.operator === '==' && c.values.includes('_no_value')) ||
//     (c.operator === '!=' && !c.values.includes('_no_value'))
//   )
// }
</script>
