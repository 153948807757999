<template>
  <div class="EditUserDetails">
    <Entity collection="users" :id="userId" v-model="userData" />
    <h3>Edit Details</h3>
    <div class="field">
      <div class="label">Full Name</div>
      <!-- <div class="edit-field"> -->
      <FieldInput
        v-if="userData"
        :value="userData.displayName"
        @input="updateDoc('users', userId, 'displayName', $event)"
      />
      <!-- </div> -->
    </div>

    <div class="field">
      <div class="label">Company Name</div>
      <FieldInput
        :value="activeGroup.name"
        @input="updateDoc('groups', activeGroupId, 'name', $event)"
      />
    </div>
  </div>
</template>
<script>
import Firebase from 'firebase/app'
import 'firebase/firestore'
import FieldInput from '../utilities/FieldInput.vue'

export default {
  components: { FieldInput },
  props: {},
  data() {
    return {
      userData: null,
    }
  },
  computed: {
    user() {
      return this.$store.getters.user
    },
    userId() {
      return this.user.uid
    },
    activeGroupId() {
      return this.$store.getters.activeGroupId
    },
    activeGroup() {
      return this.$store.getters.activeGroup
    },
  },
  watch: {},
  methods: {
    async updateDoc(collection, id, field, value = null) {
      try {
        await Firebase.firestore()
          .collection(collection)
          .doc(id)
          .update({ [field]: value })
      } catch (error) {
        console.error('error', error)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.EditUserDetails {
  margin: 40px 0;

  h3 {
    font-size: 18px;
    margin-bottom: 0;
  }

  > .field {
    width: 45%;
    margin: 25px 0;

    > .label {
      text-align: left;
      margin: 5px 0;
    }
    > .FieldInput {
      margin: 5px 0;
      background: white;
    }
  }
}
</style>
