import Vue from 'vue'
import Vuex from 'vuex'
import { vuexfireMutations, firestoreAction } from 'vuexfire'
import get from 'lodash/get'
import Firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import LogRocket from 'logrocket'
import createPlugin from 'logrocket-vuex'

import '@/firebase.js'

Vue.use(Vuex)

const allowedFilterValues = [
  'continent',
  'country',
  'region',
  'city',
  'ad',
  'adGroup',
  'campaign',
  'audience',
  'channel',
  'medium',
  'page',
  // 'eventId',
  'daterange',
  'flowValues',
]

const allowPushLiveUserIds = [
  'EQmo3MLSqafF04etOl1DcS9RM4H2', // Luke, Fella
  'HM7F648xFYOCIwx6XipWEv332Wj2', // Andres, Emi
  'vTPQrrgxaxb6ghwNbQA3RgL1wv43', // Gabriel, Emi
  'sBwVc7ASKtWcUdOx64OG4mzMFDZ2', // Minh, ArchForm
  'nZAE2DqTFxMPEPzxcxyBVzSfBpw2', // Tom, Rassa
  'X1aPMWMjEPVV7Kl2cJ3zf5pZjBc2', // Harit, Stimuli
  '6BTBoimTkCTrHy2uLRCYzuyWAju2', // Ratul, Frontier
  'O1hsFGx50AXQB57LdcvWavZcSR72', // Clau, Frontier
  'iXooHeh17aWsuv1JwAIBtG6EBRG2', // Tomer, DASH Healthy
  'OQXkkUbPO4MawVTZ40yu2FWjjmC2', // Peter, Karat
  'stit2FxoO3Sw9bbGfmQyGvJXCCt2', // Seth, Inside Out Goodness
  'ZbTkIdhqgZUJDrvn4h6olIHMjeh2', // Sahil, Closer
  '7axz6E2AdYax0evFyW26UUOy0kl1', // Noah, Closer
  'y4AjlS2MnkejUcZJgLLPLY16W792', // Alexander, Alfie
]

const logrocketPlugin = createPlugin(LogRocket)

export default new Vuex.Store({
  plugins: [logrocketPlugin],
  state: {
    userId: null,
    userClaims: {},
    userDoc: null,
    groups: [],
    users: [],
    products: [],
    allFlows: [],
    projects: [],
    flowVersions: [],
    editingMode: false,
    emailLoginSent: false,
    collectingTextCode: false,
    currentProjectId: null,
    algoliaIndices: {},
    online: true,
    rockset: allowedFilterValues.reduce((acc, l) => ({ ...acc, [l]: [] }), {}),
    rocksetLoading: {},
    showTestData: false,
    pageHeader: {},
    filters: {},
    contactSearch: '',
    versionData: {},
    highestVersion: 0,
    hasUnsavedFlowChanges: false,
    isSavingFlowChanges: false,
    advancedMode: true,
    editorMode: 'editing',
    viewport: 'full',
    compareVersionId: null,
    comparisonData: [],
    submissionFilters: {},
    showLeftBar: true,
    showRightBar: 'options',
    uploadingFile: false,
    paneViewButtons: {},
    fonts: [],
    deletedContacts: [],
    lastAssignedTo: null,
    disableDelay: true,
    showHiddenPages: true,
    hasWarrantSessionToken: null,
    features: null,
    featureValues: null,
  },
  getters: {
    user: state => ({ ...(state.userDoc || {}), claims: state.userClaims }),
    userId: state =>
      (Firebase.auth().currentUser && Firebase.auth().currentUser.uid) || state.userId,
    isAdmin: (state, getters) =>
      getters.user && getters.user.claims
        ? (getters.user.claims.admin && getters.user.adminModeActive) || false
        : undefined,
    flag: (state, getters) => flag =>
      getters.user ? get(getters.user, `flags.${flag}`) || false : undefined,
    hasFeature: state => feature_id =>
      state.features ? Boolean(state.features[feature_id]) : null,
    featureValue: state => feature_id =>
      state.featureValues ? state.featureValues[feature_id] : null,
    groupIds: state => state.groups.map(g => g.id),
    group: state => groupId => state.groups.find(g => g.id === groupId),
    groupsById: state => state.groups.reduce((acc, g) => ({ ...acc, [g.id]: g }), {}),
    groupTimezone: (state, getters) => getters.activeGroup && getters.activeGroup.timezone,
    activeGroupId: (state, getters) => getters.user && getters.user.activeGroupId,
    activeGroup: (state, getters) => getters.group(getters.activeGroupId),
    algoliaTokens: (state, getters) =>
      get(getters.user, 'tokens.algolia', get(getters.user, 'data.tokens.algolia')),
    flows: (state, getters) =>
      state.allFlows.filter(
        f => !f._archived && getters.activeGroupId && f._meta.groupId === getters.activeGroupId
      ),
    currentProject: state => state.projects.find(p => p.id === state.currentProjectId),
    product: state => id => state.products.find(p => p.id === id),
    products: state => state.products,
    onlineStatus: state => state.online,
    rocksetFilterValues: state =>
      allowedFilterValues.reduce((acc, l) => ({ ...acc, [l]: state.rockset[l] || [] }), {}),
    rocksetLoading: state => key => state.rocksetLoading[key],
    allowPushLive: (state, getters) => allowPushLiveUserIds.includes(getters.userId),
  },
  mutations: {
    setFeature(state, { featureId, value }) {
      if (!state.features) state.features = {}
      state.features[featureId] = value
    },
    setFeatureValue(state, { featureId, value }) {
      if (!state.featureValues) state.featureValues = {}
      state.featureValues[featureId] = value
    },
    setEmailLoginSent(state, val) {
      state.emailLoginSent = val
    },
    setLastAssignedTo(state, val) {
      state.lastAssignedTo = val
    },
    setCollectingTextCode(state, val) {
      state.collectingTextCode = val
    },
    setProject(state, projectId) {
      state.currentProjectId = projectId
    },
    setFlowVersions(state, versions) {
      state.flowVersions = versions
    },
    setHasWarrantSessionToken(state, hasToken) {
      state.hasWarrantSessionToken = hasToken
    },
    setAlgoliaIndices(state, indices) {
      state.algoliaIndices = indices || {}
    },
    toggleEditingMode(state) {
      state.editingMode = !state.editingMode
    },
    setSubmissionFilters(state, val) {
      state.submissionFilters = val
    },
    setVersionData(state, val) {
      state.versionData = val
    },
    setHighestVersion(state, val) {
      state.highestVersion = val
    },
    setAdvancedMode(state, val) {
      state.advancedMode = val
    },
    setHasUnsavedFlowChanges(state, val) {
      state.hasUnsavedFlowChanges = val
    },
    setIsSavingFlowChanges(state, val) {
      state.isSavingFlowChanges = val
    },
    setEditorViewport(state, val) {
      state.viewport = val
    },
    setRocksetValues(state, { key, values }) {
      state.rockset = { ...state.rockset, [key]: values }
    },
    setRocksetLoading(state, { key, value }) {
      state.rocksetLoading = { ...state.rocksetLoading, [key]: value }
      // state.rocksetLoading[key] = value
    },
    setRocksetGroup(state, value) {
      state.rocksetGroup = value
    },
    setOnlineStatus(state, val) {
      state.online = val
    },
    setEditorMode(state, val) {
      state.editorMode = val
    },
    clearRocksetValues(state) {
      state.rockset = allowedFilterValues.reduce((acc, l) => ({ ...acc, [l]: [] }), {})
      state.rocksetLoading = {}
    },
    setTestData(state, val) {
      state.showTestData = val
    },
    setPageHeader(state, val) {
      state.pageHeader = val
    },
    setFilters(state, val) {
      state.filters = val
    },
    setContactSearch(state, val) {
      state.contactSearch = val
    },
    setPaneViewButtons(state, val) {
      state.paneViewButtons = val
    },
    setComparisonData(state, val) {
      state.comparisonData = val
    },
    setCompareVersionId(state, val) {
      state.compareVersionId = val
    },
    setLeftBar(state, val) {
      state.showLeftBar = val
    },
    setRightBar(state, val) {
      state.showRightBar = val
    },
    setUploading(state, val) {
      state.uploadingFile = val
    },
    setDisableDelay(state, val) {
      state.disableDelay = val
    },
    setShowHiddenPages(state, val) {
      state.showHiddenPages = val
    },
    stageContactForDeletion(state, val) {
      state.deletedContacts = Array.from(new Set([...state.deletedContacts, val]))
    },
    setNewFont(state, vals, removeVals) {
      const toRemove = new Set(removeVals || [])
      state.fonts = Array.from(new Set([...state.fonts.filter(f => !toRemove.has(f)), ...vals]))
    },
    ...vuexfireMutations,
  },
  actions: {
    initializeUser: firestoreAction(async ({ bindFirestoreRef, state }) => {
      const userId = Firebase.auth().currentUser.uid
      state.userId = userId
      const firestore = Firebase.firestore()
      const userRef = firestore.collection('users').doc(userId)
      const [tokenResult, userData] = await Promise.all([
        Firebase.auth().currentUser.getIdTokenResult(),
        bindFirestoreRef('userDoc', userRef),
      ])
      if (tokenResult) state.userClaims = tokenResult.claims
      return userData
    }),
    bindToAdminStatus: firestoreAction(
      async ({ bindFirestoreRef, unbindFirestoreRef, getters }) => {
        const admin = getters.isAdmin
        const userId = Firebase.auth().currentUser.uid
        if (!userId) {
          console.warn('No User Id found to bind in the store')
          return
        }
        const firestore = Firebase.firestore()
        const bindGroups = bindFirestoreRef(
          'groups',
          admin
            ? firestore.collection('groups')
            : firestore.collection('groups').where('users', 'array-contains', userId)
        )
        const promises = [bindGroups]
        if (admin) {
          const bindFlows = bindFirestoreRef('allFlows', firestore.collection('forms'), {
            serialize: flowsSerialize,
          })
          promises.push(bindFlows)
          const bindUsers = bindFirestoreRef('users', firestore.collection('users'))
          promises.push(bindUsers)
        } else {
          unbindFirestoreRef('users')
        }

        return await Promise.all(promises)
      }
    ),
    bindToCurrentGroup: firestoreAction(async ({ bindFirestoreRef, state, getters }) => {
      const admin = getters.isAdmin
      // const userId = Firebase.auth().currentUser.uid
      const groupId = getters.activeGroupId
      if (!groupId) {
        console.warn('No Active Group Id found to bind in the store')
        return
      }
      const firestore = Firebase.firestore()
      const bindProducts = bindFirestoreRef(
        'products',
        firestore.collection('products').where('ownerGroupId', '==', groupId)
      )
      const projects = bindFirestoreRef(
        'projects',
        firestore.collection(`content/private/groups/${groupId}/projects`)
      )
      state.currentProjectId = null
      const promises = [bindProducts, projects]
      if (!admin) {
        const bindFlows = bindFirestoreRef(
          'allFlows',
          firestore.collection('forms').where('groupId', '==', groupId),
          { serialize: flowsSerialize }
        )
        promises.push(bindFlows)
      }

      return await Promise.all([promises])
    }),
    clearAllBinds: firestoreAction(({ unbindFirestoreRef, state }) => {
      state.userId = null
      state.currentProjectId = null
      return Promise.all([
        unbindFirestoreRef('userDoc'),
        unbindFirestoreRef('groups'),
        unbindFirestoreRef('forms'),
        unbindFirestoreRef('products'),
        unbindFirestoreRef('users'),
      ])
    }),
  },
})

function flowsSerialize(snapshot) {
  const flow = JSON.parse(snapshot.data().form || '{}')
  flow._meta = { ...snapshot.data() }
  flow._archived = snapshot.data()._archived
  if (flow._meta.title) flow.title = flow._meta.title
  delete flow._meta.form

  return Object.defineProperty(flow, 'id', { value: snapshot.id })
}
