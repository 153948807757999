<template>
  <a-popconfirm
    v-if="confirm"
    :title="confirmTitle || `Are you sure?`"
    ok-text="Yes"
    cancel-text="No"
    @confirm="click"
  >
    <!-- @cancel="$emit('remove')" -->
    <button class="block hover:text-purple">
      <Icon :name="icon" />
    </button>
  </a-popconfirm>
  <button v-else class="block hover:text-purple" @click="click">
    <Icon :name="icon" />
  </button>
</template>

<script>
export default {
  components: {},
  props: {
    component: Object,
    click: Function,
    icon: String,
    active: Boolean,
    confirm: Boolean,
    confirmTitle: String,
  },
}
</script>
