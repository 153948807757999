<template>
  <Card
    class="SingleContact cursor-pointer border-gray-100 border-solid shadow-none"
    :class="{
      'text-gray-400': contact.is_test,
      'text-red-600': crossContaminate,
      'opacity-30': contactStagedDelete,
    }"
    @click.native="_openContact(contact.id)"
  >
    <td name="profile_source" :class="{ 'text-gray-400---temp': isAnon }">
      <ContactProfileSource class="vertical" v-bind="{ contact, extras }" />
    </td>
    <td name="answers">
      <ContactAnswers v-bind="{ contact, mainFlow }" />
    </td>
    <td name="progress">
      <ContactProgress v-bind="{ contact, mainFlow }" />
    </td>
    <td name="activity">
      <ContactActivity class="vertical" v-bind="{ contact, extras }" />
    </td>
    <td name="when_where">
      <ContactWhenWhere class="vertical" v-bind="{ contact }" />
    </td>
  </Card>
</template>

<script>
import ContactProfileSource from './ContactProfileSource'
import ContactAnswers from './ContactAnswers'
import ContactProgress from './ContactProgress'
import ContactActivity from './ContactActivity'
import ContactWhenWhere from './ContactWhenWhere'
import { mapState } from 'vuex'

export default {
  name: 'SingleContact',
  components: {
    ContactProfileSource,
    ContactAnswers,
    ContactProgress,
    ContactActivity,
    ContactWhenWhere,
  },
  inject: ['_openContact'],
  props: {
    contact: Object,
    extras: Object,
    specifiedFlow: String,
    crossContaminate: Boolean,
  },
  computed: {
    ...mapState(['deletedContacts']),
    contactStagedDelete() {
      return this.deletedContacts.includes(this.contact && this.contact.id)
    },
    isAnon() {
      const keys = ['name', 'first_name', 'last_name', 'email', 'company', 'website', 'domain']

      return !keys.some(key => this.contact[key])
    },
    mainFlow() {
      return this.specifiedFlow
        ? [this.specifiedFlow, (this.contact.flows || {})[this.specifiedFlow]]
        : Object.entries(this.contact.flows || {})
            .map(([id, flow]) => {
              return [
                id,
                {
                  ...flow,
                  _progress:
                    (100 *
                      ((flow.highest_page_reached_index || flow.current_page_index || 0) + 1)) /
                    (flow.current_total_pages || 5),
                },
              ]
            })
            .sort((a, b) => b[1]._progress - a[1]._progress)[0] || null
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/_variables.scss';

.SingleContact {
  display: table-row;
  transform: scale(1);
  transition: all 0.3s;

  &:hover {
    transform: scale(1.015);
    border-radius: 1rem;
    // background-color: rgba(249, 250, 251);

    > td:first-child {
      border-top-left-radius: 1rem;
      border-bottom-left-radius: 1rem;
    }

    > td:last-child {
      border-top-right-radius: 1rem;
      border-bottom-right-radius: 1rem;
    }

    > td {
    }

    .answer {
      margin: 0.125rem;
    }
  }

  &:first-child {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;

    > td:first-child {
      border-top-left-radius: 1rem;
    }

    > td:last-child {
      border-top-right-radius: 1rem;
    }
  }
  &:last-child {
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;

    > td:first-child {
      border-bottom-left-radius: 1rem;
    }

    > td:last-child {
      border-bottom-right-radius: 1rem;
    }
  }
  // .ContactAnswers .answer {
  //   background-color: rgba(249, 250, 251);
  // }
  &:nth-child(2n) {
    background-color: rgba(249, 250, 251);
  }
  .ContactAnswers .answer {
    // border-color: rgba(249, 250, 251);
    background-color: white;
    margin-top: 2.5px;
    margin-bottom: 2.5px;
  }

  > td {
    padding: 0.25em 1em;
    // background: white;
    vertical-align: middle;
    transition: all 0.3s;
    max-width: 400px;

    &:first-child {
      padding-left: 1.5em;
    }

    &:last-child {
      padding-right: 1.5em;
    }

    > * {
      min-height: 60px;
      display: flex;
      justify-content: center;

      &.vertical {
        flex-direction: column;
      }
    }
  }
}
</style>
