<template>
  <div class="SummaryPage">
    <div>
      <div class="main-content">
        <!-- :useSimpleUpdate="!isHome" -->
        <div
          style="flex: 1; display: flex; align-items: stretch; overflow: hidden; margin: 0 -20px -20px;"
        >
          <div class="scroller" style="flex: 1;  padding: 0 20px 20px;">
            <div class="section" v-if="contentPrefix !== 'People'">
              <Insights
                v-if="insightsLoaded"
                v-bind="{
                  insights,
                  externalFilters: filters,
                  currentPath: pagePath,
                  tags,
                  includeTest: showTestData,
                }"
                @remove-card="removeInsightCard"
              />
            </div>
          </div>
        </div>
        <!-- Content Component Here -->
        <div v-if="contentComponents" class="card-sections">
          <component
            v-for="component in contentComponents"
            :key="component"
            :is="component"
            :limit="isHome ? 3 : null"
          />
        </div>

        <!-- :mode="headerText !== 'People' ? 'preview' : undefined" -->
        <template v-if="$store.getters.userId !== 'SIYGcjRJtNgLq9yDvVItRhF45vV2'">
          <div class="cards-heading">
            Recent People
            <span style="padding-left: 15px; font-size: 0.8em;">
              <router-link :to="viewAllLink">View All ></router-link>
            </span>
          </div>
          <Contacts
            preview
            :limit="5"
            :mode="isHome ? 'preview' : undefined"
            :mini="!isHome"
            :key="currentProjectId"
            v-bind="{ presetFilters: contactFilters, includeTest: showTestData }"
          />
        </template>
      </div>
      <!-- <div v-if="summary.resourceType === 'document'" class="right">
        <FlowEventsTimeline :tags="tags" />
      </div> -->
    </div>
  </div>
</template>
<script>
import Firebase from '@firebase/app'
import '@firebase/firestore'
import { mapState, mapGetters } from 'vuex'
import { cloneDeep } from 'lodash'

import Contacts from '@/components/contacts/Contacts.vue'
import Insights from '@/views/InsightsCanvas.vue'
// import FlowEventsTimeline from '../components/explorer/FlowEventsTimeline.vue'
import FlowCards from '../components/explorer/FlowCards.vue'
// import SiteCards from '../components/explorer/SiteCards.vue'

export default {
  name: 'SummaryPage',
  components: {
    Contacts,
    Insights,
    // FlowEventsTimeline,
    FlowCards,
    // SiteCards,
  },
  inject: { _register: { default: () => () => {} } },
  props: {},
  data() {
    return {
      insightsFull: [],
      project: null,
      insightsLoaded: false,
    }
  },
  computed: {
    ...mapState(['sites', 'showTestData', 'currentProjectId']),
    ...mapGetters(['activeGroupId', 'isAdmin', 'flows']),
    path() {
      return `private/groups/${this.activeGroupId}/projects`
    },
    routePath() {
      return this.$route.path.slice(1).split('/')
    },
    isHome() {
      return this.routePath[this.routePath.length - 1] === 'home'
    },
    isEditing() {
      return this.routePath[this.routePath.length - 1] === 'edit'
    },
    toEntriesPath() {
      const finalPath = this.isEditing ? this.routePath.slice(-1) : [...this.routePath, 'entries']
      return `/${finalPath.join('/')}`
    },
    toPreviewPath() {
      const finalPath = this.isEditing ? this.routePath.slice(-1) : [...this.routePath, 'preview']
      return `/${finalPath.join('/')}`
    },
    toEditPath() {
      const finalPath = this.isEditing ? this.routePath.slice(-1) : [...this.routePath, 'edit']
      return `/${finalPath.join('/')}`
    },
    currentRoute() {
      return this.isEditing ? this.routePath.slice(0, -1) : this.routePath
    },
    summary() {
      const last = this.currentRoute[this.currentRoute.length - 1]
      const secondLast = this.currentRoute[this.currentRoute.length - 2]
      const plurals = ['sites', 'flows']
      const exceptions = ['people', 'home']
      let type = 'unknown'
      if (last === '') type = 'home'
      let resourceType

      if (exceptions.includes(last)) type = last
      else if (plurals.includes(secondLast)) {
        /* Route is single, type = single form */
        resourceType = 'document'
        type = secondLast.slice(0, -1)
      } else if (plurals.includes(last)) {
        /* Route is plural, type = plurals value */
        resourceType = 'collection'
        type = last
      }

      return { type, id: last, resourceType }
    },
    flowId() {
      return this.summary.type === 'flow' && this.summary.id
    },
    icon() {
      switch (this.summary.type) {
        case 'home':
          return 'home'
        case 'people':
          return 'users'
        case 'flows':
        case 'flow':
          return 'map-signs'
        case 'sites':
        case 'site':
          return 'globe'
        default:
          return null
      }
    },
    headerText() {
      if (this.summary.resourceType === 'collection') {
        switch (this.summary.type) {
          case 'home':
          case 'people':
          case 'flows':
          case 'sites':
            return this.summary.id[0].toUpperCase() + this.summary.id.slice(1)
        }
      }
      switch (this.summary.type) {
        case 'site': {
          const site = this.sites.find(s => s.id === this.summary.id)
          return site && site.domain
        }
        case 'flow': {
          const flow = this.flows.find(f => f.id === this.summary.id)
          return (flow && flow.title) || 'Untitled Flow'
        }
        default: {
          const text = this.summary.type
          return text[0].toUpperCase() + text.slice(1).toLowerCase()
        }
      }
    },
    topBarPageObj() {
      return {
        title: this.headerText,
        icon: this.icon,
      }
    },
    contentPrefix() {
      switch (this.summary.type) {
        case 'sites':
          return 'Sites'
        case 'flows':
          return 'Flows'
        case 'people':
          return 'People'
        case 'home':
          return 'Home'
        case 'site':
          return 'Site'
        case 'flow':
          return 'Flow'
        default:
          return ''
      }
    },
    contentComponents() {
      switch (this.summary.type) {
        // case 'sites':
        // return ['SiteCards']
        case 'flows':
          return ['FlowCards']
        case 'home':
          return ['FlowCards' /*, 'SiteCards'*/]
        default:
          return null
      }
    },
    filters() {
      switch (this.summary.type) {
        case 'flows':
          return [['flow', '==', true]]
        case 'flow':
          return [['flow', '==', this.summary.id]]
        case 'site': {
          const site = this.sites.find(s => s.id === this.summary.id)
          return site ? [['domain', '==', site.domain]] : []
        }

        case 'sites':
        case 'home':
        default:
          return []
      }
    },
    contactFilters() {
      switch (this.summary.type) {
        case 'flows':
          return { flow: this.flows.map(f => `${f.id}-viewed_flow`) }
        case 'flow':
          return { flow: `${this.flowId}-viewed_flow` }
        case 'site': {
          const site = this.sites.find(s => s.id === this.summary.id)
          return site ? { domain: site.domain } : {}
        }

        case 'sites':
        case 'home':
        default:
          return {}
      }
    },
    insights() {
      if (this.project && this.project.insights && Object.values(this.project.insights).length) {
        return this.project.insights
      }
      /* Keys must start with "preset" */
      const keys = [
        'preset_popular_pages',
        'preset_visitors_by_source',
        'preset_visitors_over_time_day',
      ]
      const w = 16
      const h = 8
      const cols = 48
      const maxCols = cols / w
      return keys.reduce((acc, k, i) => {
        const x = (i % maxCols) * w
        const y = Math.floor(i / maxCols) * h
        acc[k] = { grid: { w, h, x, y } }
        return acc
      }, {})
    },
    tags() {
      return this.filters.map(f => `${f[0]}:${f[2]}`)
      // ['flow:TEST_FLOW_A']
    },
    pagePath() {
      if (['flow', 'site'].includes(this.summary.type)) {
        return `${this.path}/${this.currentProjectId}/pages/${this.summary.type}-${this.summary.id}`
      }
      return `${this.path}/${this.currentProjectId}/pages/${this.summary.type}`
    },
    viewAllLink() {
      const filters = cloneDeep(this.filters)
      const qs = filters
        .reduce((acc, f) => {
          acc.push(`${f[0]}=${f[2]}`)
          return acc
        }, [])
        .join('&')
      const queryString = filters.length > 0 ? `?${qs}` : ''
      return `/people${queryString}`
    },
  },
  mounted() {
    this.deregister = this._register('addInsightCard', e => this.addInsightCard(e))
  },
  beforeDestroy() {
    this.deregister()
  },
  watch: {
    flowId: {
      async handler(id) {
        if (!id) return
        const recentFlows = this.$store.state.userDoc.recentFlows || []
        const userRef = Firebase.firestore()
          .collection('users')
          .doc(this.$store.getters.userId)
        userRef.update({ recentFlows: [id, ...recentFlows.filter(f => f !== id)] })
      },
      immediate: true,
    },
    pagePath: {
      handler(pid) {
        this.insightsLoaded = false

        this.$bind('project', Firebase.firestore().doc(`content/${pid}`)).then(
          () => (this.insightsLoaded = true)
        )
      },
      immediate: true,
    },
    topBarPageObj: {
      handler(p) {
        this.$store.commit('setPageHeader', p)
      },
      immediate: true,
    },
  },
  methods: {
    async addInsightCard(id) {
      let docRef = Firebase.firestore().doc(`content/${this.pagePath}`)

      const { exists } = await docRef.get()

      if (!exists) docRef.set({})

      const newGridItem = { grid: { h: 8, w: 16 } }

      if (Array.isArray(id)) {
        docRef.update(
          id.reduce((acc, d) => {
            acc[`insights.${d}`] = newGridItem
            return acc
          }, {})
        )
      } else
        docRef.update({
          [`insights.${id}`]: newGridItem,
        })
    },
    removeInsightCard(id) {
      let docRef = Firebase.firestore().doc(`content/${this.pagePath}`)
      docRef.update({ [`insights.${id}`]: Firebase.firestore.FieldValue.delete() })
    },
  },
}
</script>
<style lang="scss" scoped>
.SummaryPage {
  > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 12px 20px;

    .no-border {
      border: none;
    }

    .right {
      display: flex;
      flex-direction: column;
      width: 25%;
    }
    .main-content {
      display: flex;
      flex-direction: column;
      width: 100%;
      /* width: 80%; */

      .card-sections {
        margin-top: 30px;
        display: flex;
        flex-wrap: wrap;
      }
      .cards-heading {
        margin: 12px 0;
        font-size: 16px;
      }
    }
  }
}
</style>
