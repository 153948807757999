<template>
  <div class="Settings">
    <div class="header">
      <h1>
        <Icon name="cog" />
        Account and Settings
      </h1>
    </div>
    <div class="settings-main">
      <div class="tabs">
        <template v-for="tab in tabs">
          <a v-if="tab.type === 'url'" target="_blank" class="tab" :href="tab.url" :key="tab.id">
            <Icon :name="tab.icon" />
            <div class="SmartContent">{{ tab.text }}</div>
          </a>
          <router-link
            v-else
            class="tab"
            v-on:mousedown.native="checkReNavigation(`/settings/${tab.id}`)"
            :key="tab.id"
            :to="`/settings/${tab.id}`"
          >
            <Icon :name="tab.icon" />
            <!-- <SmartContent type="icon" :path="`settings/pages/${tab.id}/icon`" /> -->
            <SmartContent :path="`settings/pages/${tab.id}/name`" />
            <!-- {{ tab.name }} -->
          </router-link>
        </template>
      </div>
      <div class="main-pane">
        <h2>
          <Icon :name="currentTab.icon" />
          <SmartContent :path="`settings/pages/${currentTab.id}/name`" />
        </h2>
        <router-view :re-navigating="reNavigating" />
      </div>
    </div>
  </div>
</template>
<script>
import { logout } from '@/boot-up/authentication'
import api from '@/helpers/api'

export default {
  name: 'Settings',
  data() {
    return {
      currentTabId: 'profile',
      reNavigating: new Date(),
      diahookLoginUrl: '',
    }
  },
  computed: {
    user() {
      return this.$store.getters.user
    },
    userId() {
      return this.user.uid
    },
    activeGroupId() {
      return this.$store.getters.activeGroupId
    },
    isAdmin() {
      return this.$store.getters.isAdmin
    },
    currentTab() {
      return this.tabs.find(t => t.id === this.$route.matched[1].path.split('/').slice(-1)[0])
    },
    tabs() {
      return [
        {
          id: 'profile',
          icon: 'user',
        },
        {
          id: 'analytics',
          icon: 'chart-line',
        },
        {
          id: 'integrations',
          icon: 'plug',
        },
        this.diahookLoginUrl
          ? {
              id: 'webhooks',
              icon: 'plug',
              type: 'url',
              url: this.diahookLoginUrl,
              text: 'Webhooks',
            }
          : undefined,
        {
          id: 'integrations-dashboard',
          name: 'Integration Status',
          icon: 'plug',
          adminOnly: true,
        },
        {
          id: 'feature-flags',
          name: 'Feature Flags',
          icon: 'flask',
          adminOnly: true,
        },
        {
          id: 'billing',
          icon: 'credit-card',
        },
        {
          id: 'admin',
          icon: 'cog',
          adminOnly: true,
        },
      ]
        .filter(t => t && (!t.adminOnly || this.isAdmin))
        .map(t => ({ name: t.id, icon: t.id, ...t }))
    },
  },
  async mounted() {
    /* Get login code here using group.diahookAppId */
    const { data } = await api('/groups/diahook', { groupId: this.activeGroupId })
    this.diahookLoginUrl = data.url
  },
  methods: {
    checkReNavigation(path) {
      if (this.$route.path === path) {
        // Vue Router does not trigger a state update
        // for navigating to the same route (see: https://github.com/vuejs/rfcs/pull/150)
        this.reNavigating = new Date()
      }
    },
    async signOut() {
      await logout()
      this.$router.push('/login')
    },
    goToMeeting() {
      this.$router.push('/meeting')
    },
    goToNewHomepage() {
      this.$router.push('/home-new')
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/_variables.scss';

.Settings.main-view {
  @include flex('column');
  position: relative;
  padding-left: 5%;
  padding-right: 5%;

  .header {
    margin: 10px;

    h1 {
      .Icon {
        margin-right: 0.5em;
        margin-bottom: 0.1em;
      }
    }
  }

  .settings-main {
    flex: 1;
    @include flex('row', 'stretch');

    > div {
      margin: 10px;
      padding: 20px;
      border: 1px solid #eee;
      border-radius: 6px;
      background: white;
    }

    .tabs {
      flex: 1;

      .tab {
        @include flex('row', 'a-center');
        padding: 10px 15px;
        font-size: 18px;
        text-decoration: none;
        text-transform: capitalize;

        &.router-link-active,
        &:hover {
          color: var(--color-savvy);
          // border-left: 3px solid var(--color-savvy);
        }

        .Icon {
          width: 2em;
          margin-right: 0.25em;
        }
      }
    }
    .main-pane {
      flex: 3;
      padding: 30px;

      h2 {
        @include flex('row', 'a-center');
        font-size: 26px;
        text-transform: capitalize;
        margin-bottom: 1em;

        .Icon {
          margin-right: 0.5em;
          margin-bottom: -0.1em;
        }
      }
    }
  }
}
</style>
