<template>
  <a
    v-if="href"
    :href="href"
    class="Button"
    :class="(data && data.class) || ''"
    :target="target"
    @click="clickMethod"
  >
    <Icon :useFeather="useFeather" v-if="buttonIcon" class="margin-right" :name="buttonIcon" />
    {{ buttonText }}
    <slot />
  </a>
  <button v-else class="Button" :class="(data && data.class) || ''" @click="clickMethod">
    <Icon :useFeather="useFeather" v-if="buttonIcon" class="margin-right" :name="buttonIcon" />
    {{ buttonText }}
    <slot />
  </button>
</template>

<script>
import Icon from './Icon'

export default {
  components: { Icon },
  props: {
    data: Object,
    useFeather: Boolean,
    isDropdownButton: Boolean,
    click: Function,
    text: String,
    icon: String,
    href: String,
    target: {
      type: String,
      default: '_blank',
    },
  },
  computed: {
    buttonIcon() {
      return this.icon || (this.data && this.data.icon)
    },
    buttonText() {
      return this.text || (this.data && this.data.text)
    },
    clickMethod() {
      const self = this
      const placeholder = () => {}

      return function(event) {
        if (self.data && self.data.stopPropagation) event.stopPropagation()

        const clickMethod = self.click || (self.data && self.data.click) || placeholder

        if (typeof clickMethod !== 'function') {
          return
        }
        clickMethod(event)
        if (self.isDropdownButton) self.$emit('close-dropdown')
      }
    },
  },
}
</script>

<style lang="scss">
@import './styles/Button.scss';
</style>
