<template>
  <div
    class="FormComponentEditor p-4"
    :class="{ 'opacity-50': component && component.protected && !isAdmin }"
  >
    <a-space direction="vertical">
      <template v-if="location === 'options' || !location">
        <template v-if="siblingOptions.length">
          <div class="flex gap-1 items-center">
            <div class="flex-auto overflow-hidden">
              <div class="text-xs uppercase font-bold text-gray-400 mt-1 ml-1">
                Component
              </div>

              <div class="overflow-hidden flex gap-1 -mt-0.5 p-1">
                <KeyEditor
                  class="text-sm"
                  :component="component"
                  :disabled="Boolean(componentIsDefault)"
                  @update="onKeyUpdate"
                />
              </div>
            </div>
            <a-tooltip v-if="numWithSameKey > 1">
              <div
                class="w-8 h-8 ml-1 rounded-md bg-gray-200 purple flex items-center justify-center font-extrabold"
              >
                {{ numWithSameKey }}
              </div>

              <template slot="title">
                This is one of {{ numWithSameKey }} components with the same key
              </template>
            </a-tooltip>
            <!-- <a-tooltip title="Lock component">
            <a-button class="flex-shrink-0"
              :icon="component && component.protected ? 'lock' : 'unlock'"
              @click="protectComponent"
            />
          </a-tooltip> -->
            <!-- <a-tooltip title="add">
              <a-button
                class="flex-shrink-0"
                icon="plus"
                @click="$emit('new', isGlobalComponent)"
              />
            </a-tooltip> -->
            <a-tooltip v-if="!componentTypeIsPreset || componentIsDefault" title="Duplicate">
              <a-button class="flex-shrink-0" icon="copy" @click="$emit('duplicate')" />
            </a-tooltip>

            <a-tooltip v-if="!componentIsDefault" title="Delete">
              <a-popconfirm
                v-if="component.type === 'Container'"
                title="Do you want to delete child components?"
                ok-text="Yes"
                cancel-text="No"
                @confirm="$emit('remove', true)"
                @cancel="$emit('remove')"
              >
                <!-- @click="$emit('remove')" -->
                <a-button class="flex-shrink-0" icon="delete" />
              </a-popconfirm>
              <a-popconfirm
                v-else
                title="Are you sure you want to delete this component?"
                ok-text="Yes"
                cancel-text="No"
                @confirm="$emit('remove')"
              >
                <!-- @click="$emit('remove')" -->
                <a-button class="flex-shrink-0" icon="delete" />
              </a-popconfirm>
              <!-- <a-button v-else class="flex-shrink-0" icon="delete" @click="$emit('remove')" /> -->
            </a-tooltip>
          </div>
        </template>
        <div
          v-else
          class="flex flex-row items-center border rounded bg-white cursor-pointer py-1 px-3"
          @click="$emit('new', isGlobalComponent)"
        >
          <span class="ml-2"
            >+ Add your first{{ isGlobalComponent ? ' global' : '' }} component</span
          >
        </div>
        <a-tag v-if="page && page.useGrid">
          {{
            Object.entries(component.grid || {})
              .map(e => `${e[0]}: ${e[1]}`)
              .join(', ')
          }}</a-tag
        >

        <template v-if="key">
          <a-popover
            v-if="!componentIsDefault"
            :visible="showingTypeSelector"
            title="Select a Component Type"
            placement="leftTop"
          >
            <template slot="content">
              <ElementCardSelector
                class="overflow-y-auto max-h-96"
                :typeKey="specialComponentType"
                @input="setComponentType"
              />
            </template>
            <div class="cursor-pointer" @click="onClickTypeSelect">
              <ElementCard
                landscape
                :label="specialComponentTypeObject && specialComponentTypeObject.label"
                :icon="specialComponentTypeObject && specialComponentTypeObject.buttonIcon"
                endIcon="fas:sort"
              />
            </div>
          </a-popover>
          <EditorSelect
            v-else
            disabled
            label="Component Type"
            _key="type"
            :defaultVal="componentIsDefault.type"
            :options="componentTypes"
          />

          <EditorHeading icon="cog" title="General" />
          <EditorSelect
            v-if="isGlobalComponent"
            class="flex-grow"
            _key="_location"
            placeholder="Global Component Location"
            :options="locationOptions"
          />
          <EditorSelect label="Parent Container" _key="parent_key" :options="containerOptions" />

          <component
            v-if="typeObjectHasEditor"
            :is="`${type}Editor`"
            :componentId="id"
            v-bind="{ component, page, form, userData }"
            location="top"
            @update="$emit('update', $event)"
          />
        </template>
        <!-- <template v-if="parentComponent">
          <EditorLabel label="Parent Container" />
          <ComponentLink
            :id="parentComponent.id"
            :hidden="hiddenComponents.has(parentComponent.id)"
          >
            <div class="flex-grow">
              <span class="font-mono text-xs">
                {{ parentComponent.key || `Untitled Component ${index + 1}` }}
              </span>
              {{ hiddenComponents.has(parentComponent.id) ? ' (Hidden)' : '' }}
            </div>
          </ComponentLink>
        </template> -->

        <!-- <EditorInput
          v-if="!componentIsDefault"
          _key="key"
          isKey
          placeholder="Component Key"
          :component="component"
        />
        <EditorInput
          v-else
          disabled
          placeholder="Component Key (Required)"
          _key="key"
          emit
          :value="componentIsDefault.key"
        /> -->
        <TagsEditor type="component" />
        <EditorHeading icon="code-branch" title="Conditions" />
        <ConditionsEditor location="component" v-bind="{ form, conditions }" />
        <EditorHeading icon="sliders-h" title="Configuration" />
        <EditorCheckbox _key="hide" label="Hide Component" />
        <EditorCheckbox _key="page_reactive_classes" label="Make classes reactive in Stimulus" />
        <EditorCheckbox
          v-if="supportsRequired"
          _key="isRequired"
          label="Required"
          v-bind="{ component }"
        />

        <component
          v-if="typeObjectHasEditor"
          :is="`${type}Editor`"
          :componentId="id"
          v-bind="{ component, page, form, userData }"
          location="basic_options"
          @update="$emit('update', $event)"
        />

        <component
          v-if="typeObjectHasEditor"
          :is="`${type}Editor`"
          :componentId="id"
          v-bind="{ component, page, form, userData }"
          location="content"
          @update="$emit('update', $event)"
        />

        <EditorHeading
          v-if="['CustomButton', 'InputBox', 'OptionSelector'].includes(type)"
          icon="clipboard-list"
          title="Validation"
        />
        <EditorCheckbox
          v-if="['CustomButton'].includes(type)"
          _key="allow_skip_validation"
          label="Skip validation on next page"
          v-bind="{ component }"
        />
        <template v-if="type === 'InputBox'">
          <EditorCheckbox _key="validate_value" label="Validate Value" v-bind="{ component }" />
        </template>
        <template
          v-if="component.validate_value || component.validate_on_blur || component.isRequired"
        >
          <EditorInput
            v-if="type !== 'OptionSelector'"
            _key="invalid_message"
            placeholder="Custom Invalid Message"
          />
          <EditorInput _key="empty_invalid_message" placeholder="Custom Field Empty Message" />
          <EditorSelect
            _key="validation_formula"
            title="Select validation formula"
            :options="validationTypes"
          />
          <a-alert
            v-if="!isAllowedValidation"
            message="Current Validation Formula is not allowed for this component type"
            banner
          />
        </template>
      </template>
      <template v-if="key">
        <component
          v-if="typeObjectHasEditor"
          :is="`${type}Editor`"
          :componentId="id"
          v-bind="{ component, page, form, userData, location }"
          @update="$emit('update', $event)"
        />
      </template>
      <template v-if="location === 'options' || !location">
        <EditorHeading icon="caret-down" title="More Options" />
        <EditorInput
          v-if="supportsDefault"
          allowComputed
          _key="defaultVal"
          placeholder="Default Value"
          v-bind="{ component }"
          @update="debounceUpdate($event)"
        />
        <a-radio-group
          :disabled="component.type === 'InputBox' && component.input_type === 'password'"
          size="small"
          v-model="saveInUserData"
        >
          <a-radio-button :value="false">
            <a-tooltip title="Save to LocalStorage + Firebase">
              <a-icon type="check" />
            </a-tooltip>
          </a-radio-button>
          <a-radio-button value="cloud">
            <a-tooltip title="Only save to LocalStorage">
              <a-icon type="desktop" />
            </a-tooltip>
          </a-radio-button>
          <a-radio-button :value="true">
            <a-tooltip title="Do not save anywhere">
              <a-icon type="stop" />
            </a-tooltip>
          </a-radio-button>
        </a-radio-group>
        <template v-if="key">
          <a-collapse>
            <a-collapse-panel header="Other Options">
              <div class="flex flex-col gap-2">
                <EditorInput isKey style="width: 120px;" size="small" :path="`tab_group`" />

                <EditorCheckbox _key="mask_recording" label="Mask Component in Recordings" />
                <EditorCheckbox _key="ignore_recording" label="Ignore Component in Recordings" />
                <EditorCheckbox
                  v-if="componentTypeObject && componentTypeObject.group === 'User Input'"
                  _key="set_value_class"
                  label="Set Value as Class on Flow"
                  :component="component"
                />
                <EditorCheckbox
                  _key="fullPageBackground"
                  label="Full-Page Background"
                  v-bind="{ component }"
                />
                <EditorInput
                  v-if="supportsLink && !downloadData"
                  _key="linkUrl"
                  placeholder="Hyperlink URL"
                  v-bind="{ component }"
                  @update="debounceUpdate($event)"
                />
                <EditorCheckbox
                  v-if="supportsLink && !downloadData && linkUrl"
                  defaultTrue
                  _key="open_link_in_new_tab"
                  v-bind="{ component }"
                />
                <EditorInput
                  v-if="supportsLink && !downloadData"
                  _key="element_id"
                  label="DOM Element ID"
                  v-bind="{ component }"
                  @update="debounceUpdate($event)"
                />
                <EditorInput
                  v-if="supportsLink && !linkUrl"
                  allowComputed
                  _key="downloadData"
                  placeholder="Download Data Key"
                  v-bind="{ component }"
                  @update="debounceUpdate($event)"
                />
                <component
                  v-if="typeObjectHasEditor"
                  :is="`${type}Editor`"
                  :componentId="id"
                  v-bind="{ component, page, form, userData, location: `${location}-other` }"
                  @update="$emit('update', $event)"
                />
              </div>
            </a-collapse-panel>
            <a-collapse-panel header="Advanced">
              <div class="flex flex-col gap-2">
                <EditorSelect label="Optional - Repeater Key" _key="repeater_key" :options="keys" />
                <!-- <EditorInput
                  class="flex-grow"
                  _key="parent_key"
                  isKey
                  placeholder="Parent Container Key"
                  v-bind="{ component }"
                /> -->
                <component
                  v-if="typeObjectHasEditor"
                  :is="`${type}Editor`"
                  :componentId="id"
                  v-bind="{ component, page, form, userData, location: `${location}-advanced` }"
                  @update="$emit('update', $event)"
                />
                <a-button v-if="!componentIsDefault" class="w-full" @click="openDataPath">
                  Edit as raw JSON
                  <Icon class="ml-1" name="external-link-alt" />
                </a-button>
              </div>
            </a-collapse-panel>
          </a-collapse>
        </template>
      </template>
    </a-space>
  </div>
</template>

<script>
// import isEqual from 'lodash/isEqual'

import { unpack } from '@/helpers/computed'
import { defaultHeadingComponents } from '@/components/form/helpers/defaultComponents'
import componentTypes, {
  extraComponentTypes,
} from '@/components/form/editor/helpers/componentTypes'

import TagsEditor from './TagsEditor'
import ConditionsEditor from './ConditionsEditor'

import PlainTextEditor from './PlainTextEditor'
import MediaImageEditor from './MediaImageEditor'
import MediaEmbedEditor from './MediaEmbedEditor'
import CalculatedBlockEditor from './CalculatedBlockEditor'
import InputBoxEditor from './InputBoxEditor'
// import ActionButtonEditor from './ActionButtonEditor'
import StripeCheckoutEditor from './StripeCheckoutEditor'
import PaypalCheckoutEditor from './PaypalCheckoutEditor'
import AddressBoxEditor from './AddressBoxEditor'
import OptionSelectorEditor from './OptionSelectorEditor'
import FileUploadEditor from './FileUploadEditor'
import CustomButtonEditor from './CustomButtonEditor'
import RichTextEditor from './RichTextEditor.vue'
import BookMeetingEditor from './BookMeetingEditor'
// import VideoCallEditor from './VideoCallEditor'
import CustomAuthEditor from './CustomAuthEditor'
import RegisterForMeetingEditor from './RegisterForMeetingEditor'
import ContainerEditor from './ContainerEditor'
import LottieEditor from './LottieEditor'
import PageTitleEditor from './PageTitleEditor'
import PageHeadingLabelEditor from './PageHeadingLabelEditor'
import PageSubtitleEditor from './PageSubtitleEditor'
import NextFooterButtonEditor from './NextFooterButtonEditor'
import PrevFooterButtonEditor from './PrevFooterButtonEditor'
import ProgressBarEditor from './ProgressBarEditor'
import ElementCard from './mainEditor/ElementCard.vue'
import KeyEditor from './mainEditor/toolbar/KeyEditor'

import { debounce, snakeCase, startCase } from 'lodash'
import { mapGetters } from 'vuex'
import ElementCardSelector from './mainEditor/ElementCardSelector.vue'

export default {
  name: 'FormComponentEditor',
  components: {
    TagsEditor,
    ConditionsEditor,
    StripeCheckoutEditor,
    PaypalCheckoutEditor,
    PlainTextEditor,
    MediaImageEditor,
    // ActionButtonEditor,
    MediaEmbedEditor,
    InputBoxEditor,
    AddressBoxEditor,
    RichTextEditor,
    CalculatedBlockEditor,
    OptionSelectorEditor,
    FileUploadEditor,
    CustomButtonEditor,
    BookMeetingEditor,
    CalendlyEditor: BookMeetingEditor,
    // VideoCallEditor,
    CustomAuthEditor,
    RegisterForMeetingEditor,
    ContainerEditor,
    LottieEditor,
    PageTitleEditor,
    PageHeadingLabelEditor,
    PageSubtitleEditor,
    ProgressBarEditor,
    ElementCardSelector,
    ElementCard,
    KeyEditor,
    NextFooterButtonEditor,
    PrevFooterButtonEditor,
  },
  inject: [
    '_getOptions',
    '_getBasePath',
    '_updateData',
    '_openDataPath',
    '_getSiblingOptions',
    '_onSelectComponent',
    '_updateReactive',
  ],
  provide() {
    return {
      _getBasePath: () => this.basePath,
    }
  },
  props: {
    component: Object,
    componentIndex: Number,
    isGlobalComponent: Boolean,
    form: Object,
    page: Object,
    grid: Object,
    userData: Object,
    location: String,
  },
  data() {
    return { showingTypeSelector: false }
  },
  computed: {
    ...mapGetters(['isAdmin']),
    basePath() {
      return this.isGlobalComponent
        ? `components.${this.componentIndex}`
        : `${this._getBasePath()}.components.${this.componentIndex}`
    },
    ...unpack('component', [
      'id',
      'conditions',
      'column',
      'block',
      'linkUrl',
      'downloadData',
      'isRequired',
      'fullPageBackground',
    ]),
    componentTypes() {
      return componentTypes()
    },
    extraComponentTypes() {
      return extraComponentTypes()
    },
    allComponentTypes() {
      return [...this.componentTypes, ...this.extraComponentTypes]
    },
    key() {
      return this.componentIsDefault ? startCase(this.component.key) : this.component.key
    },
    type() {
      const type = this.component && this.component.type
      return type === 'OptionButtons' ? 'OptionSelector' : type
    },
    specialComponentType() {
      const specialTypes = this.extraComponentTypes
      const specialType = specialTypes.find(t => {
        const typeMatches = t.componentKey === this.type
        const matchesProperties = Object.entries(t.componentProperties || {}).every(
          ([k, v]) => this.component[k] === v
        )
        return typeMatches && matchesProperties
      })
      return specialType ? specialType.key : this.type
    },
    componentTypeIsPreset() {
      const forbiddenComponents = componentTypes()
        .filter(c => c.group === 'Savvy Presets')
        .map(c => c.key)

      return new Set(forbiddenComponents).has(this.type)
    },
    componentIsDefault() {
      if (!this.component) return {}
      return Object.values(defaultHeadingComponents()).find(c => c.id === this.component.id)
    },
    siblingOptions() {
      return this._getSiblingOptions()
    },
    containerOptions() {
      return [
        undefined,
        ...this.siblingOptions
          .filter(c => c.type === 'Container' && c.key !== this.component.key)
          .map(c => c.key),
      ]
    },
    debounceUpdate() {
      return debounce(
        update =>
          this._updateData(`${this._getBasePath()}.components.${this.componentIndex}`, ...update),
        200,
        {
          trailing: true,
          leading: false,
        }
      )
    },
    supportsRequired() {
      return this.isInput
    },
    supportsDefault() {
      return ['InputBox', 'OptionButtons', 'OptionSelector'].includes(this.type)
    },
    supportsLink() {
      return this.type && !this.isInput && !this.isIntegration
    },
    locationOptions() {
      return [
        'before_page',
        'before_components',
        'after_components',
        'after_page',
        'flow_top',
        'progress_bar',
        'page_top',
        'page_bottom',
        'footer',
        'flow_bottom',
      ]
    },
    validationTypes() {
      if (!this.component) return []
      switch (this.type) {
        case 'InputBox':
          return [
            { key: 'none', label: 'None' },
            { key: 'email_address', label: 'Email Address' },
            { key: 'full_name', label: 'Full Name' },
            { key: 'business_email', label: 'Business Email' },
            { key: 'phone_number_us', label: 'Phone Number (US)' },
            { key: 'custom', label: 'Custom' },
          ]

        default:
          return []
      }
    },
    isAllowedValidation() {
      return this.validationTypes.length === 0 || !this.component.validation_formula
        ? true
        : this.validationTypes.find(v => v.key === this.component.validation_formula)
    },
    isInput() {
      // if (this.type === 'Container' && this.component.container_type === 'Carousel') return true
      return new Set(
        componentTypes()
          .filter(t => !t.hasNoValue)
          .map(t => t.key)
      ).has(this.type)
      // return [
      //   'InputBox',
      //   'OptionButtons',
      //   'OptionSelector',
      //   'FileUpload',
      //   'BookMeeting',
      //   'StripeCheckout',
      //   'CustomButton',
      // ].includes(this.type)
    },
    isIntegration() {
      return [
        'BookMeeting',
        //  'VideoCall'
      ].includes(this.type)
    },
    keys() {
      const keys = Array.from(new Set(this._getOptions().map(o => o.key)))
      return [undefined, ...keys]
    },
    numWithSameKey() {
      if (!this.component) return 0
      return this._getOptions().filter(o => o.key === this.component.key).length
    },
    saveInUserData: {
      get() {
        if (this.component.type === 'InputBox' && this.component.input_type === 'password') {
          return true
        }
        return (this.component && this.component.doNotSave) || false
      },
      set(v) {
        this._updateData(`${this._getBasePath()}.components.${this.componentIndex}`, 'doNotSave', v)
      },
    },
    componentTypeObject() {
      return this.componentTypes.find(c => c.key === this.type)
    },
    specialComponentTypeObject() {
      return this.allComponentTypes.find(c => c.key === this.specialComponentType)
    },
    typeObjectHasEditor() {
      return this.type && (this.componentTypeObject ? !this.componentTypeObject.noEditor : true)
    },
    hiddenComponents() {
      return new Set(
        this._getSiblingOptions()
          .filter(c => c.hidden)
          .map(c => c.id)
      )
    },
    parentComponent() {
      return this._getSiblingOptions().find(
        c => this.component && c.key === this.component.parent_key
      )
    },
  },
  beforeDestroy() {
    if (this.removePopoverListener) this.removePopoverListener()
  },
  watch: {
    // grid: {
    //   handler(grid) {
    //     const { x, y, w, h } = grid || {}
    //     const g = { x, y, w, h }

    //     if (
    //       this.component.grid &&
    //       x === this.component.grid.x &&
    //       y === this.component.grid.y &&
    //       w === this.component.grid.w &&
    //       h === this.component.grid.h
    //     )
    //       return

    //     this.$emit('update', ['grid', g])
    //   },
    //   deep: true,
    // },
    showingTypeSelector: {
      handler(v) {
        if (!v) {
          if (this.removePopoverListener) this.removePopoverListener()
        }
      },
    },
  },
  methods: {
    onKeyUpdate([key, val]) {
      const newVal = typeof val === 'string' ? snakeCase(val.trim()) : val
      this._updateReactive(this.basePath, key, newVal)
    },
    setPopoverListener() {
      if (this.removePopoverListener) this.removePopoverListener()
      const el = document.querySelector('.ant-popover-content')
      const eventListener = e => {
        if (el.contains(e.target)) return
        this.showingTypeSelector = false
      }
      window.addEventListener('click', eventListener)
      this.removePopoverListener = () => {
        window.removeEventListener('click', eventListener)
        this.removePopoverListener = null
      }
    },
    async onClickTypeSelect(e) {
      e.stopPropagation()
      e.preventDefault()
      this.showingTypeSelector = true
      await this.$nextTick()
      this.setPopoverListener()
    },
    setComponentType(typeData) {
      if (!typeData) return
      const newComponent = { ...this.component }
      newComponent.type = typeData.componentKey || typeData.key.split('-')[0]
      if (typeData && typeData.componentProperties) {
        Object.entries(typeData.componentProperties).forEach(([k, v]) => {
          newComponent[k] = v
        })
      }
      this.showingTypeSelector = false
      this._updateData(`${this._getBasePath()}`, `components.${this.componentIndex}`, newComponent)
    },
    openDataPath() {
      this._openDataPath({ path: this.basePath, title: this.component.key })
    },
    protectComponent() {
      this._updateData(
        `${this._getBasePath()}.components.${this.componentIndex}`,
        'protected',
        !this.component.protected
      )
    },
  },
}
</script>
