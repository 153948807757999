export function setRouteQuery(self, key, value) {
  self.$router.push({
    ...self.$route,
    query: {
      ...self.$route.query,
      [key]: value,
    },
  })
}

export function mapParams(arr) {
  return arr.reduce((acc, key) => {
    acc[key] = function() {
      return this.$route.params[key]
    }

    return acc
  }, {})
}

export function mapQuery(arr) {
  return arr.reduce((acc, key) => {
    acc[key] = function() {
      return this.$route.query[key]
    }

    return acc
  }, {})
}
