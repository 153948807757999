<template>
  <div class="ElementCardSelector overflow-y-scroll" style="max-width: 632px">
    <div v-for="g in typesByGroup" :key="g.group" class="flex flex-col mb-4 overflow-hidden px-2">
      <EditorLabel :label="g.group" />
      <div class="grid grid-cols-5 gap-2 auto-cols-auto auto-rows-auto">
        <ElementCard
          v-for="{ key, label, buttonIcon, buttonImage } in g.types"
          :key="key"
          class="min-h-24"
          :label="label"
          :icon="buttonIcon"
          :image="buttonImage"
          :isSelected="newType === key"
          @click.native="select(key)"
        />
      </div>
    </div>
  </div>
</template>
<script>
import componentTypes, {
  extraComponentTypes,
  pageLayouts,
} from '@/components/form/editor/helpers/componentTypes'
import ElementCard from './ElementCard.vue'

export default {
  components: { ElementCard },
  name: 'ElementCardSelector',
  props: { typeKey: String, dataType: String, dataArray: Array },
  data() {
    return { newType: null }
  },
  computed: {
    typesByGroup() {
      const defaultGroup = 'Other'
      const groupOrder = Array.from(new Set(this.allTypeData.map(t => t.group)))
      const groups = this.allTypeData.reduce((acc, t) => {
        const group = t.group || defaultGroup
        if (t.componentKey) {
          acc[group] = acc[group] || []
          const parentKeyIndex = acc[group].findIndex(gt => gt.key === t.componentKey)
          if (parentKeyIndex !== -1) acc[group].splice(parentKeyIndex + 1, 0, t)
          else acc[group] = acc[group] ? [...acc[group], t] : [t]
        } else acc[group] = acc[group] ? [...acc[group], t] : [t]
        return acc
      }, {})
      return groupOrder.map(g => ({ group: g, types: groups[g] || [] }))
    },
    allTypeData() {
      switch (this.dataType) {
        case 'pageLayouts': {
          return pageLayouts()
        }
        case 'flow-templates': {
          return this.dataArray
        }

        default: {
          const types = componentTypes().filter(t => !t.hideFromCreator)
          const extraTypes = extraComponentTypes()
          return [...types, ...extraTypes]
        }
      }
    },
    componentTypeData() {
      return this.allTypeData.find(c => c.key === this.newType) || null
    },
  },
  watch: {
    typeKey: {
      handler(v) {
        this.newType = v
      },
      immediate: true,
    },
    componentTypeData: {
      handler(v) {
        this.$emit('input', v)
      },
    },
  },
  methods: {
    select(key) {
      this.newType = key
      this.$emit('selected', key)
    },
  },
}
</script>
