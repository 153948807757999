<template>
  <div class="SavvyComparisonChart">
    <Entity v-if="configOne" :collection="collection" :id="configOne" v-model="configDataOne" />
    <Entity v-if="configTwo" :collection="collection" :id="configTwo" v-model="configDataTwo" />
    <Entity
      v-if="cacheOneId"
      :collection="`/groups/${activeGroupId}/cached-data/`"
      :id="cacheOneId"
      v-model="cacheOne"
    />
    <Entity
      v-if="cacheTwoId"
      :collection="`/groups/${activeGroupId}/cached-data/`"
      :id="cacheTwoId"
      v-model="cacheTwo"
    />
    <SavvyLineChart v-if="comparedData" :data="comparedData" noTotal v-bind="{ prefix }" />
  </div>
</template>
<script>
import SavvyLineChart from './SavvyLineChart'
import cachedDataToCardData, { generateCacheSlug, getBreakdownArray } from './cacheToChartData'
export default {
  components: { SavvyLineChart },
  props: { config: Object, collection: String },
  data() {
    return {
      configDataOne: null,
      configDataTwo: null,
      configLoadingOne: false,
      configLoadingTwo: false,
      cacheOne: null,
      cacheTwo: null,
      cacheLoadingOne: false,
      cacheLoadingTwo: false,
    }
  },
  computed: {
    activeGroupId() {
      return this.$store.getters.activeGroupId
    },
    // projectId() {
    //   return this.$route.params.projectId
    // },
    // collection() {
    //   return this.projectId
    //     ? `projects/${this.projectId}/stats`
    //     : `groups/${this.activeGroupId}/insights`
    // },
    configOne() {
      return this.config.cardOne
    },
    configTwo() {
      return this.config.cardTwo
    },
    prefix() {
      if (this.configDataOne && this.configDataTwo) {
        if (this.configDataOne[`${this.configDataOne.service}-preset`] === 'revenue') return '$'
      }
      return ''
    },
    cacheOneId() {
      if (!this.configDataOne) return null
      const breakdownArray = getBreakdownArray(this.configDataOne)
      return generateCacheSlug(this.configDataOne, undefined, undefined, breakdownArray)
    },
    cacheTwoId() {
      if (!this.configDataTwo) return null
      const breakdownArray = getBreakdownArray(this.configDataTwo)
      return generateCacheSlug(this.configDataTwo, undefined, undefined, breakdownArray)
    },
    cardDataOne() {
      if (!this.configDataOne) return null
      const service = this.configDataOne.service
      const preset = this.configDataOne[`${service}-preset`]
      return cachedDataToCardData(preset, this.cacheOne, this.configDataOne)
    },
    cardDataTwo() {
      if (!this.configDataTwo) return null
      const service = this.configDataTwo.service
      const preset = this.configDataTwo[`${service}-preset`]
      return cachedDataToCardData(preset, this.cacheTwo, this.configDataTwo)
    },
    comparedData() {
      const top = this.cardDataOne
      const bottom = this.cardDataTwo
      if (!top || !bottom) return null
      if (typeof top === 'number' && typeof bottom === 'number') return top / bottom
      const topData = top.labels.reduce((acc, l, i) => {
        acc[l] = (top.datasets[0] && top.datasets[0].data[i]) || 0
        return acc
      }, {})
      const bottomData = bottom.labels.reduce((acc, l, i) => {
        acc[l] = (bottom.datasets[0] && bottom.datasets[0].data[i]) || 0
        return acc
      }, {})

      const labels = Array.from(new Set([...top.labels, ...bottom.labels])).sort(
        (a, b) => new Date(a).getTime() - new Date(b).getTime()
      )

      const dataInfo = labels.map(l => {
        const t = topData[l] || 0
        const b = bottomData[l] || 0
        const rate = b ? t / b : 0
        return { rate, t, b }
      })

      const dataset = {
        data: dataInfo.map(l => l.rate),
        hoverLabels: dataInfo.map(({ t, rate, b }) => `${t} / ${b} (${rate.toFixed(2)})`),
        backgroundColor: '#6454f355',
        label: 'combined',
      }
      const average = +(
        labels
          .map(l => {
            const t = topData[l] || 0
            const b = bottomData[l] || 0
            return b ? t / b : 0
          })
          .reduce((acc, d) => acc + d, 0) / labels.length
      ).toFixed(2)

      return {
        labels,
        datasets: [dataset],
        average,
      }
    },
  },
  watch: {},
  methods: {},
}
</script>
<style lang="scss" scoped>
.SavvyComparisonChart {
  height: 100%;
}
</style>
