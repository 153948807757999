<template>
  <div class="InputBoxEditor">
    <a-space direction="vertical">
      <template v-if="location === 'top' || !location">
        <EditorSelect
          v-if="!component || !component.multiline"
          _key="input_type"
          :options="inputTypeOptions"
        />
      </template>
      <template v-if="location === 'basic_options' || !location">
        <EditorCheckbox _key="multiline" v-bind="{ component }" />
        <EditorCheckbox _key="autofocus" v-bind="{ component }" />
        <EditorCheckbox _key="inline_button" label="Inline Custom Button" v-bind="{ component }" />
      </template>
      <template v-if="location === 'content'">
        <EditorHeading icon="paragraph" title="Content" />
        <EditorInput allowComputed _key="label" v-bind="{ component }" />
        <EditorCheckbox _key="label_rich" label="Label is RichText" v-bind="{ component }" />
        <EditorInput allowComputed _key="placeholder" v-bind="{ component }" />
        <EditorLabel label="Icon" />
        <EditorIcon _key="icon" v-bind="{ component }" />
        <template v-if="component.input_type === 'switch'">
          <EditorCheckbox _key="two_label" label="Use different labels" v-bind="{ component }" />
          <EditorInput
            v-if="component.two_label"
            allowComputed
            _key="off_label"
            v-bind="{ component }"
          />
          <EditorCheckbox
            v-if="component.two_label"
            _key="label_on_both_side"
            label="Show Labels on both sides"
            v-bind="{ component }"
          />
        </template>
        <!-- <EditorInput _key="char_width" v-bind="{ component }" /> -->
        <template v-if="['range', 'number'].includes(component.input_type)">
          <EditorInput type="number" _key="range_min" v-bind="{ component }" />
          <EditorInput type="number" _key="range_max" v-bind="{ component }" />
          <EditorInput type="number" _key="range_step" v-bind="{ component }" />
        </template>
        <template v-if="component.input_type === 'number'">
          <EditorCheckbox _key="integer_only" label="Allow Integers only" />
        </template>
        <EditorInput
          v-if="allowMaxLength"
          type="number"
          _key="max_length"
          placeholder="Max Length of input"
          v-bind="{ component }"
        />
        <template v-if="component.input_type === 'confirm'">
          <EditorSelect
            _key="confirm_component_key"
            label="Confirm for the following component"
            :options="confirmComponentOptions"
          />
          <a-alert
            v-if="
              component.confirm_component_key &&
                !confirmComponentOptions.includes(component.confirm_component_key)
            "
            message="Component Key to confirm was not found in Options"
          />
          <EditorInput
            _key="mismatch_values_message"
            placeholder="Fields must match"
            :label="`Mismatch Error Message`"
            v-bind="{ component }"
          />
        </template>
        <template v-else-if="['date', 'month'].includes(component.input_type)">
          <EditorInput
            allowComputed
            _key="range_min"
            :placeholder="rangePlaceholders.placeholder"
            :label="`Min Date (${rangePlaceholders.label})`"
            v-bind="{ component }"
          />
          <EditorInput
            allowComputed
            _key="range_max"
            :placeholder="rangePlaceholders.placeholder"
            :label="`Max Date (${rangePlaceholders.label})`"
            v-bind="{ component }"
          />
          <EditorInput
            v-if="component.input_type === 'month'"
            type="number"
            _key="range_step"
            v-bind="{ component }"
          />
        </template>
      </template>
      <template v-if="location === 'options'">
        <EditorCheckbox _key="trim_whitespace_on_blur" v-bind="{ component }" />
        <EditorCheckbox _key="format_email_on_blur" v-bind="{ component }" />
        <EditorCheckbox _key="validate_on_blur" v-bind="{ component }" />
        <EditorCheckbox
          v-if="['text', 'number'].includes(component.input_type)"
          _key="use_tel_input"
          label="Use telephone keypad input"
        />
        <template v-if="component.validation_formula === 'custom'">
          <EditorCode _key="custom_validation_function" :codeDraft="codeDraft" />
          <a-button size="small" @click="generateCodeDraft">Generate sample code</a-button>
        </template>

        <a-collapse v-if="component.inline_button && component.inline_component">
          <a-collapse-panel header="Inline Component">
            <CustomButtonEditor
              :component="component.inline_component"
              :componentId="`${component.id}_inline_button`"
              :form="form"
              path="inline_component"
            />
          </a-collapse-panel>
        </a-collapse>
      </template>
      <template v-if="location === 'options-advanced'">
        <EditorCheckbox
          v-if="component.input_type === 'email'"
          _key="hsq_track"
          label="Track Email in Hubspot"
        />
      </template>
    </a-space>
  </div>
</template>

<script>
import { unpackToCamel } from '@/helpers/computed'
import CustomButtonEditor from './CustomButtonEditor.vue'
import { inputTypes } from '@/components/form/editor/helpers/componentTypes'
export default {
  components: { CustomButtonEditor },
  name: 'InputBoxEditor',
  inject: ['_getBasePath', '_updateData', '_getOptions'],
  props: {
    componentId: String,
    component: Object,
    form: Object,
    location: String,
  },
  data() {
    return { codeDraft: null }
  },
  computed: {
    inputTypeOptions() {
      return inputTypes()
    },
    ...unpackToCamel('component', ['inline_button']),
    inputTypeIsMonth() {
      return this.component.input_type === 'month'
    },
    confirmComponentOptions() {
      const options = this._getOptions()
      const inputBoxes = options.filter(o => o.type === 'InputBox')
      return [undefined, ...inputBoxes.map(o => o.key)]
    },
    rangePlaceholders() {
      return {
        placeholder: this.inputTypeIsMonth ? `YYYY-MM` : `YYYY-MM-DD`,
        label: this.inputTypeIsMonth ? `YYYY-MM` : `YYYY-MM-DD`,
      }
    },
    allowMaxLength() {
      const allowed = new Set(['password', 'search', 'tel', 'text', 'url'])
      const type =
        this.component.input_type === 'number' && this.component.use_tel_input
          ? 'tel'
          : this.component.input_type
      return allowed.has(type)
    },
  },
  watch: {
    inlineButton: {
      handler(v) {
        if (v && !this.component.inline_component) {
          this._updateData(this._getBasePath(), 'inline_component', {
            key: `${this.component.key}_inline`,
            id: `${this.component.id}_inline`,
          })
        }
      },
      immediate: true,
    },
  },
  methods: {
    generateCodeDraft() {
      this.codeDraft = `function validate(value) {
  return value ? true : false
}`
    },
  },
}
</script>
