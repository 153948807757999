<template>
  <div class="Billing">
    <BillingWidget />
  </div>
</template>

<script>
import BillingWidget from '@/components/Billing.vue'

export default {
  name: 'Billing',
  components: {
    BillingWidget,
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/_variables.scss';

.Billing {
}
</style>
