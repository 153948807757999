<template>
  <div class="DesignTab px-4">
    <template v-if="location === 'design' || !location">
      <div v-if="blameVersion" class="mt-2">
        Last Edited: v{{ blameVersion.version }} by
        {{ blameVersion.author && blameVersion.author.userName }}
      </div>
      <!-- <template v-if="type === 'flow'">
        <EditorSelect label="Select Theme" _key="theme" :options="themeOptions" />
      </template> -->
      <div class="available-selectors flex flex-col gap-2">
        <!-- <EditorLabel label="Which sub element" /> -->
        <EditorHeading icon="bullseye-pointer" title="Choose an Element..." />
        <div class="grid grid-cols-3 gap-2 auto-cols-auto auto-rows-auto">
          <ElementCard
            v-for="{ key, label, icon } in showMoreElements
              ? elementTypeOptions.base
              : elementTypeOptions.base.slice(0, 6)"
            :key="key"
            :label="label"
            :icon="icon"
            :bubble="hasStyles(getSelectors(key, type, parent, page, viewport))"
            :grayBubble="hasActiveDefaultStyles(getSelectors(key, type, parent, page, viewport))"
            :bubbleCount="hasStyles(getSelectors(key, type, parent, page, viewport), true).length"
            :isSelected="selectedElement === key"
            @click.native="selectedElement = key"
          />
        </div>
        <div
          v-if="elementTypeOptions.base.length > 6"
          class="w-full cursor-pointer text-center flex flex-row justify-center items-center"
          @click="showMoreElements = !showMoreElements"
        >
          <Icon :name="showMoreElements ? 'caret-up' : 'caret-down'" type="fas" />
          <div class="relative ml-1">
            {{ showMoreElements ? 'Hide' : 'Show More' }}
            <div class="flex flex-row element-bubble-position">
              <div
                v-if="
                  !showMoreElements &&
                    hasStyles(getSelectorsForSlice(elementTypeOptions.base.slice(6)))
                "
                class="element-bubble text-white"
              ></div>
              <div
                v-if="
                  !showMoreElements &&
                    hasActiveDefaultStyles(getSelectorsForSlice(elementTypeOptions.base.slice(6)))
                "
                class="element-bubble text-white"
              ></div>
            </div>
          </div>
        </div>
        <div class="flex gap-2 items-center" v-if="elementTypeOptions.states.length">
          <a-switch size="small" v-model="useStates" /> Use states
        </div>
        <template v-if="useStates">
          <template v-if="elementTypeOptions.states.length">
            <EditorLabel label="Elements with State" />
            <div class="grid grid-cols-3 gap-2 auto-cols-auto auto-rows-auto">
              <ElementCard
                v-for="{ key, label, icon } in showMoreStates
                  ? elementTypeOptions.states
                  : elementTypeOptions.states.slice(0, 6)"
                :key="key"
                :label="label"
                :icon="icon"
                :bubble="hasStyles(getSelectors(key, type, parent, page, viewport))"
                :grayBubble="
                  hasActiveDefaultStyles(getSelectors(key, type, parent, page, viewport))
                "
                :bubbleCount="
                  hasStyles(getSelectors(key, type, parent, page, viewport), true).length
                "
                :isSelected="selectedElement === key"
                @click.native="selectedElement = key"
              />
            </div>
          </template>
          <div
            v-if="elementTypeOptions.states.length > 6"
            class="w-full cursor-pointer text-center flex flex-row justify-center items-center"
            @click="showMoreStates = !showMoreStates"
          >
            <Icon :name="showMoreStates ? 'caret-up' : 'caret-down'" type="fas" />
            <div class="relative ml-1">
              {{ showMoreStates ? 'Hide' : 'Show More' }}
              <div class="flex flex-row element-bubble-position">
                <div
                  v-if="
                    !showMoreStates &&
                      hasStyles(getSelectorsForSlice(elementTypeOptions.states.slice(6)))
                  "
                  class="element-bubble text-white"
                ></div>
                <div
                  v-if="
                    !showMoreStates &&
                      hasActiveDefaultStyles(
                        getSelectorsForSlice(elementTypeOptions.states.slice(6))
                      )
                  "
                  class="element-bubble text-white"
                ></div>
              </div>
            </div>
          </div>
          <template v-if="elementTypeOptions.children.length">
            <EditorLabel label="Elements with Children" />
            <div class="grid grid-cols-3 gap-2 auto-cols-auto auto-rows-auto">
              <ElementCard
                v-for="{ key, label, icon } in showMoreChildren
                  ? elementTypeOptions.children
                  : elementTypeOptions.children.slice(0, 6)"
                :key="key"
                :label="label"
                :icon="icon"
                :grayBubble="
                  hasActiveDefaultStyles(getSelectors(key, type, parent, page, viewport))
                "
                :bubble="hasStyles(getSelectors(key, type, parent, page, viewport))"
                :bubbleCount="
                  hasStyles(getSelectors(key, type, parent, page, viewport), true).length
                "
                :isSelected="selectedElement === key"
                @click.native="selectedElement = key"
              />
            </div>
          </template>
          <div
            v-if="elementTypeOptions.children.length > 6"
            class="w-full cursor-pointer text-center flex flex-row justify-center items-center"
            @click="showMoreChildren = !showMoreChildren"
          >
            <Icon :name="showMoreChildren ? 'caret-up' : 'caret-down'" type="fas" />
            <div class="relative ml-1">
              {{ showMoreChildren ? 'Hide' : 'Show More' }}
              <div class="flex flex-row element-bubble-position">
                <div
                  v-if="
                    !showMoreChildren &&
                      hasStyles(getSelectorsForSlice(elementTypeOptions.children.slice(6)))
                  "
                  class="element-bubble text-white"
                ></div>
                <div
                  v-if="
                    !showMoreChildren &&
                      hasActiveDefaultStyles(
                        getSelectorsForSlice(elementTypeOptions.children.slice(6))
                      )
                  "
                  class="element-bubble text-white"
                ></div>
              </div>
            </div>
          </div>
        </template>
        <!-- <a-select placeholder="Choose an Element" style="width: 100%;" v-model="selectedElement">
          <a-select-opt-group key="base" label="Elements">
            <a-select-option
              v-for="{ key, label } in elementTypeOptions.base"
              :key="key"
              :value="key"
            >
              {{ label || 'Root' }}
            </a-select-option>
          </a-select-opt-group>
          <a-select-opt-group key="states" label="Elements with State">
            <a-select-option
              v-for="{ key, label } in elementTypeOptions.states"
              :key="key"
              :value="key"
            >
              {{ label || 'None' }}
            </a-select-option>
          </a-select-opt-group>
          <a-select-opt-group key="selectors" label="Elements with Children">
            <a-select-option
              v-for="{ key, label } in elementTypeOptions.children"
              :key="key"
              :value="key"
            >
              {{ label || 'None' }}
            </a-select-option>
          </a-select-opt-group>
        </a-select> -->
        <EditorHeading icon="tags" title="Apply these styles to..." />
        <div class="grid grid-cols-2 gap-2 auto-cols-auto auto-rows-auto">
          <ElementCard
            v-for="sel in showMoreSelectors
              ? selectors
              : selectors.filter(sel => !['.Flow-Component'].includes(sel)).slice(0, 4)"
            :key="sel"
            :label="classToLabel(sel) && classToLabel(sel).split('(')[0]"
            :tooltip="classToLabel(sel)"
            :grayBubble="hasActiveDefaultStyles([sel])"
            :bubble="hasStyles([sel])"
            :isSelected="currentSelector === sel"
            @click.native="currentSelector = sel"
          />
        </div>
        <div
          v-if="selectors.filter(sel => !['.Flow-Component'].includes(sel)).slice(4).length > 0"
          class="w-full text-center cursor-pointer"
          @click="showMoreSelectors = !showMoreSelectors"
        >
          <Icon :name="showMoreSelectors ? 'caret-up' : 'caret-down'" type="fas" />
          <span class="relative w-full">
            {{ showMoreSelectors ? 'Hide' : 'Show More' }}
            <div
              v-if="
                !showMoreSelectors &&
                  hasStyles(selectors.filter(sel => !['.Flow-Component'].includes(sel)).slice(4))
              "
              class="element-bubble element-bubble-position"
              style="right: -8px;"
            ></div>
            <div
              v-if="
                !showMoreSelectors &&
                  hasActiveDefaultStyles(
                    selectors.filter(sel => !['.Flow-Component'].includes(sel)).slice(4)
                  )
              "
              class="element-bubble element-bubble-position"
              style="right: 0px"
            ></div>
          </span>
        </div>
        <div v-if="showCustomSelectorsToggle">
          <a-switch size="small" v-model="showLegacySelectors" /> Show Custom Selectors
        </div>
        <template v-if="showLegacySelectors">
          <EditorLabel label="Custom Selectors" />
          <div class="grid grid-cols-2 gap-2 auto-cols-auto auto-rows-auto">
            <ElementCard
              v-for="sel in legacySelectorsSorted"
              :key="sel"
              :label="sel"
              :tooltip="sel"
              :isSelected="currentSelector === sel"
              @click.native="currentSelector = sel"
            />
          </div>
          <div class="editing-selector flex flex-row w-full">
            <a-input
              placeholder="Add Custom selector"
              @keyup.enter="addSelector(customSelectorVal)"
              v-model="customSelectorVal"
            />
            <a-button icon="save" @click="addSelector(customSelectorVal)" />
            <!-- <a-button icon="copy" @click="addSelector([customSelectorVal], { clone: true })" /> -->
          </div>
        </template>
      </div>

      <template v-if="currentSelector">
        <EditorHeading class="mb-2" icon="paint-brush" title="Now set your styles" />
        <a-switch size="small" v-model="showAllStyles" /> Show All Common Styles
        <div :key="currentSelector" class="quick-editor gap-2">
          <div
            v-for="{ category, styles } in stylesToShow"
            :key="category"
            class="flex flex-col gap-1 mt-4"
          >
            <div class="flex flex-row items-center justify-between gap-2 my-1">
              <div class="text-xs uppercase font-bold text-gray-400">
                {{ category.split('_').join(' ') }}:
              </div>
              <div
                class="text-xs text-right uppercase font-bold text-gray-400 cursor-pointer"
                @click="$set(showMoreCategories, category, !showMoreCategories[category])"
              >
                {{ showMoreCategories[category] ? '- Hide' : '+ Show More' }}
              </div>
            </div>
            <div
              v-for="style in styles"
              :key="style.key"
              class="flex flex-row items-center justify-between gap-2"
            >
              <div class="flex-1">{{ style.label }}:</div>
              <!-- Select with search + type your own stuff -->
              <a-popconfirm
                v-if="style.importFont"
                :visible="
                  popConfirmLoadFont &&
                    popConfirmLoadFont === (currentStyles && currentStyles[style.key]) &&
                    !currentStyleFontsInFonts(currentStyles)
                "
                title="Do you want to load this font?"
                ok-text="Yes"
                cancel-text="No"
                @confirm="onPopconfirmYes"
                @cancel="onPopconfirmNo"
              >
                <DesignInput
                  class="flex-1"
                  :defaultValue="getSuffixedValue(style)"
                  :value="getSuffixedValue(style, '')"
                  :placeholder="getDefaultStyle(style.key)"
                  :dataSource="style.inUse || []"
                  :suffixes="style.suffixes"
                  :showColor="style.showColor"
                  :currentSuffix="propertySuffix[style.key] || style.defaultSuffix"
                  @blur="onBlurAutoComplete(style, currentStyles && currentStyles[style.key])"
                  @change="debounceSetStyle(style.key, $event, style)"
                  @suffix="setSuffix(style.key, $event, style)"
                  @default-suffix="$set(this.propertySuffix, style.key, $event)"
                />
              </a-popconfirm>
              <DesignInput
                v-else
                class="flex-1"
                :defaultValue="getSuffixedValue(style)"
                :value="getSuffixedValue(style, '')"
                :placeholder="getDefaultStyle(style.key)"
                :dataSource="style.inUse || []"
                :suffixes="style.suffixes"
                :showColor="style.showColor"
                :currentSuffix="propertySuffix[style.key] || style.defaultSuffix"
                @blur="onBlurAutoComplete(style, currentStyles && currentStyles[style.key])"
                @change="debounceSetStyle(style.key, $event, style)"
                @suffix="setSuffix(style.key, $event, style)"
              />
            </div>
          </div>
          <div class="flex flex-row items-center justify-between gap-2 h-10">
            <a-auto-complete
              ref="new_style_key"
              class="p-1 flex"
              placeholder="+ Add a new style"
              :dataSource="styleKeysArray"
              :filterOption="filterOption"
              v-model="newStyleKey"
              @select="focusVal"
            />
            <a-auto-complete
              ref="new_style_val"
              class="p-1 w-1/2 flex-shrink-0"
              :disabled="!newStyleKey"
              :dataSource="findInUseStyles(newStyleKey, styles, newStyleVal)"
              :filterOption="filterOption"
              v-model="newStyleVal"
              @select="setCustomStyle"
            />
          </div>
        </div>
      </template>

      <a-collapse>
        <a-collapse-panel header="Advanced">
          <a-tooltip title="Add CSS from Clipboard">
            <a-button icon="snippets" @click="addCssFromClipboard"></a-button>
          </a-tooltip>
          <a-button v-if="currentSelector" class="w-full" @click="openDataPath(currentSelector)">
            Edit this selector as raw JSON
            <Icon class="ml-1" name="external-link-alt" />
          </a-button>
          <a-button class="w-full" @click="openDataPath()">
            Edit all styles as raw JSON
            <Icon class="ml-1" name="external-link-alt" />
          </a-button>
          <template v-if="type === 'flow'">
            <EditorHeading title="Raw CSS" />
            <EditorInput _key="styles_raw" type="textarea" />
          </template>
        </a-collapse-panel>
      </a-collapse>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { debounce, isEqual, startCase } from 'lodash'

import getWidths from '@/components/form/editor/helpers/widths'
import {
  flowClassToLabel,
  pageClassToLabel,
  componentClassToLabel,
} from '@/components/form/helpers/labelGenerator'
import getSelectors from '@/components/form/editor/helpers/selectorGenerators'
import { defaultStyles } from '@/components/form/editor/helpers/componentTypes'
// import Draggable from 'vuedraggable'
import { elementToIcon, getFriendlyType } from '../../helpers/labelGenerator'
import ElementCard from './ElementCard.vue'
import googleFontsUrls from '../../../../helpers/googleFontsUrls'
import { findLastChangedVersion } from '@/components/form/editor/helpers/formHelpers.js'

import DesignInput from './DesignInput.vue'

export default {
  name: 'DesignTab',
  components: { ElementCard, DesignInput },
  inject: ['_updateData', '_getSelectedComponentId', '_openDataPath'],
  props: {
    parent: Object,
    form: Object,
    page: Object,
    component: Object,
    type: String,
    location: String,
  },
  data() {
    return {
      currentSelector: undefined,
      showAllSelectors: true,
      showLegacySelectors: false,
      selectedElement: undefined,
      // selectedElementType: undefined,
      // selectedElementState: undefined,
      // selectedChildElement: undefined,
      showAllStyles: false,
      customSelectorVal: '',
      showMoreCategories: {},
      newStyleKey: null,
      newStyleVal: null,
      showMoreElements: false,
      showMoreStates: false,
      showMoreChildren: false,
      useStates: false,
      showMoreSelectors: false,
      useCustomSelectors: false,
      popConfirmLoadFont: null,
      propertySuffix: { padding: 'px' },
    }
  },
  computed: {
    ...mapState(['viewport', 'fonts', 'flowVersions']),
    showCustomSelectorsToggle() {
      return this.legacySelectors.length > 0 || this.form.show_custom_selectors
    },
    selectedComponentId() {
      return this._getSelectedComponentId()
    },
    styles() {
      return (this.form && this.form.styles) || {}
    },
    currentStyles() {
      return (this.styles && this.styles[this.currentSelector]) || null
    },
    styleKeysArray() {
      return Object.values(this.quickEditStyles)
        .reduce((acc, cat) => acc.concat((cat || []).map(style => style.key)), [])
        .filter(k => !k.startsWith('--'))
    },
    quickEditStyles() {
      /* { [style]: string[] } */
      const seenStyles = new Set()
      const groups = {}
      const generateSetting = (keys, common, extraKeys) =>
        keys.map(key => {
          if (key && typeof key === 'object') {
            seenStyles.add(key.key)
            if (key.group) {
              groups[key.group] = groups[key.group] ? groups[key.group] : []
              groups[key.group].push(key.key)
            }
            const base = {
              ...(extraKeys || {}),
              group: key.group || null,
              suffixes: key.suffixes || [],
              key: key.key,
              common: key.common || common || false,
            }
            if (key.showColor) base.showColor = key.showColor
            if (key.suffixes) {
              base.suffixes = key.suffixes
              if (key.defaultSuffix) base.defaultSuffix = key.defaultSuffix
            }
            return base
          }
          seenStyles.add(key)
          return { ...(extraKeys || {}), key, common: common || false }
        })
      const categories = {
        variables:
          this.currentSelector === '.Flow-EntireFlow'
            ? [
                ...generateSetting(['--primary-color', '--border-radius'], true),
                ...generateSetting(
                  [
                    '--fail-color',
                    '--spacing-xs',
                    '--spacing-sm',
                    '--spacing-md',
                    '--spacing-lg',
                    '--border-radius-lg',
                    '--border-radius-xl',
                    '--input-width',
                  ],
                  false
                ),
              ]
            : this.currentSelector && this.currentSelector.includes('.ComponentTag-range')
            ? [
                ...generateSetting(
                  [
                    '--track-color',
                    '--thumb-color',
                    '--thumb-radius',
                    '--thumb-height',
                    '--thumb-width',
                    '--thumb-shadow-size',
                    '--thumb-shadow-blur',
                    '--thumb-shadow-color',
                    '--thumb-border-width',
                    '--thumb-border-color',
                    '--track-width',
                    '--track-height',
                    '--track-shadow-size',
                    '--track-shadow-blur',
                    '--track-shadow-color',
                    '--track-border-width',
                    '--track-border-color',
                    '--track-radius',
                  ],
                  true
                ),
              ]
            : [],
        size: [
          ...generateSetting(['width', 'height'], true),
          ...generateSetting(
            ['max-height', 'max-width', 'min-height', 'min-width', 'box-sizing'],
            false
          ),
        ],
        space: [
          ...generateSetting(
            [
              { key: 'margin', defaultSuffix: 'px', suffixes: ['px', 'rem', 'em'] },
              { key: 'padding', defaultSuffix: 'px', suffixes: ['px', 'rem', 'em'] },
            ],
            true
          ),
          ...generateSetting(
            [
              {
                key: 'padding-bottom',
                group: 'lengths',
                defaultSuffix: 'px',
                suffixes: ['px', 'rem', 'em'],
              },
              {
                key: 'padding-left',
                group: 'lengths',
                defaultSuffix: 'px',
                suffixes: ['px', 'rem', 'em'],
              },
              {
                key: 'padding-right',
                group: 'lengths',
                defaultSuffix: 'px',
                suffixes: ['px', 'rem', 'em'],
              },
              {
                key: 'padding-top',
                group: 'lengths',
                defaultSuffix: 'px',
                suffixes: ['px', 'rem', 'em'],
              },
              {
                key: 'margin-bottom',
                group: 'lengths',
                defaultSuffix: 'px',
                suffixes: ['px', 'rem', 'em'],
              },
              {
                key: 'margin-left',
                group: 'lengths',
                defaultSuffix: 'px',
                suffixes: ['px', 'rem', 'em'],
              },
              {
                key: 'margin-right',
                group: 'lengths',
                defaultSuffix: 'px',
                suffixes: ['px', 'rem', 'em'],
              },
              {
                key: 'margin-top',
                group: 'lengths',
                defaultSuffix: 'px',
                suffixes: ['px', 'rem', 'em'],
              },
            ],
            false
          ),
        ],
        flex_and_grid: [
          ...generateSetting(
            [
              'flex',
              'flex-direction',
              'flex-wrap',
              'gap',
              'align-items',
              'justify-content',
              'place-content',
              'grid-area',
              'grid-template-columns',
              'grid-template-rows',
            ],
            true
          ),
          ...generateSetting(
            [
              'flex-grow',
              'flex-shrink',
              'grid-auto-flow',
              'grid-auto-columns',
              'grid-auto-rows',
              'grid-column',
              'grid-row',
              'place-items',
              'place-self',
              'justify-self',
              'justify-items',
              'align-self',
              'align-content',
              'order',
            ],
            false
          ),
        ],
        position: [
          ...generateSetting(
            ['display', 'float', 'position', 'top', 'bottom', 'left', 'right'],
            true
          ),
          ...generateSetting([], false),
        ],
        text: [
          ...generateSetting(['font-family'], true, { importFont: true }),
          ...generateSetting(['font-size', 'font-weight', 'line-height', 'text-align'], true),
          ...generateSetting(
            [
              'text-decoration',
              'text-indent',
              'text-transform',
              'font',
              'letter-spacing',
              'list-style',
              'list-style-image',
              'list-style-position',
              'list-style-type',
              'font-variant',
            ],
            false
          ),
        ],
        layering: [
          ...generateSetting(['visibility', 'z-index', 'overflow'], true),
          ...generateSetting([], false),
        ],
        // temp: [...generateSetting([], true), ...generateSetting([], false)],
        border: [
          ...generateSetting(['border', 'box-shadow'], true),
          ...generateSetting(
            [
              'border-bottom',
              'border-bottom-color',
              'border-bottom-style',
              { key: 'border-bottom-width', defaultSuffix: 'px', suffixes: ['px', 'rem', 'em'] },
              'border-color',
              'border-left',
              'border-left-color',
              'border-left-style',
              'border-left-width',
              'border-radius',
              'border-right',
              'border-right-color',
              'border-right-style',
              'border-right-width',
              'border-style',
              'border-top',
              'border-top-color',
              'border-top-style',
              'border-top-width',
              'border-width',
              'stroke-dasharray',
              'stroke-dashoffset',
            ],
            false
          ),
        ],
        color_and_background: [
          ...generateSetting(
            [{ key: 'color', showColor: true }, 'opacity', 'background-color', 'background-image'],
            true
          ),
          ...generateSetting(
            [
              { key: 'background', showColor: true },
              'background-attachment',
              'background-position',
              'background-repeat',
            ],
            false
          ),
        ],
        other: [
          ...generateSetting(['cursor', 'filter', 'transform', 'transition'], false),
          ...generateSetting(
            [
              'clear',
              'clip',
              'page-break-after',
              'page-break-before',
              'transition-duration',
              'vertical-align',
            ],
            false
          ),
        ],
      }

      if (categories.variables && categories.variables.length === 0) delete categories.variables

      categories.custom = [
        ...Object.keys(this.currentStyles || {})
          .filter(s => !seenStyles.has(s))
          .map(s => ({ key: s, common: false })),
      ]

      return Object.entries(categories).reduce((acc, [category, styles]) => {
        acc[category] = styles.map(s => ({
          ...s,
          key: s.key,
          common: s.common,
          inUse: this.findInUseStyles(s, this.styles, undefined, groups),
          label: startCase(s.key.replace('--', '[Var] ')),
        }))
        return acc
      }, {})
    },
    styleSuffixes() {
      return Object.values(this.quickEditStyles).reduce((acc, styles) => {
        styles.forEach(s => {
          if (s.suffixes) s.suffixes.forEach(suffix => acc.add(suffix))
        })
        return acc
      }, new Set())
    },
    stylesToShow() {
      const filterFn = this.showAllStyles
        ? s =>
            (this.currentStyles && this.currentStyles[s.key]) ||
            s.common ||
            this.getDefaultStyle(s.key)
        : s => (this.currentStyles && this.currentStyles[s.key]) || this.getDefaultStyle(s.key)
      return Object.entries(this.quickEditStyles).reduce((acc, [category, styles]) => {
        acc.push({
          category,
          styles: this.showMoreCategories[category] ? styles : styles.filter(filterFn),
        })
        return acc
      }, [])
    },
    selectorOrder: {
      get() {
        const baseSelectors = [...((this.form && this.form.selectorOrder) || [])]
        const seen = new Set(baseSelectors)

        Object.keys(this.styles).forEach(k => {
          if (seen.has(k)) return
          baseSelectors.push(k)
          seen.add(k)
        })
        return baseSelectors
      },
      set(v) {
        this._updateData(null, 'selectorOrder', Array.from(new Set(v)))
      },
    },
    selectors() {
      return getSelectors(this.selectedElement, this.type, this.parent, this.page, this.viewport)
    },
    selectorsToShow() {
      const base = this.selectors
      return this.showAllSelectors ? base : base.slice(0, 5)
    },
    currentViewportType() {
      const width = getWidths().find(w => w.key === this.viewport)
      return width && width.type
    },
    elementTypes() {
      switch (this.type) {
        case 'flow':
          return [
            'FlowMain',
            'FlowPopupOuter',
            'FlowPopupOverlay',
            'FlowPopupContainer',
            'FlowPopupCloseButton',
            'FlowPopupCloseButtonIcon',
            'FlowPopupContainerBox',
            'OfflineIndicator',
            '.GlobalLocation-BeforePage',
            '.GlobalLocation-PageTop',
            '.GlobalLocation-BeforeComponents',
            '.GlobalLocation-AfterComponents',
            '.GlobalLocation-Footer',
            '.GlobalLocation-FlowBottom',
            '.GlobalLocation-AfterPage',
          ]

        case 'pages':
          return [
            'PageHeader',
            'PageLogo',
            'PageLogoImage',
            'PageProgressBar',
            'PageContents',
            'PageComponents',
            'PageComponentsList',
            'PageHeadingLabel',
            'PageTitle',
            'PageSubtitle',
            'PageFooter',
            'PageFooterButtonContainer',
            // 'PageFooterButton',
            'PageFooterButtonNext',
            'PageFooterButtonPrev',
          ]

        case 'components':
        case 'global_components': {
          switch (this.component && this.component.type) {
            case 'FileUpload':
              return ['Uploader']
            case 'InputBox':
              return [
                'Label',
                this.component.multiline ? 'TextareaElement' : 'InputElement',
                // 'InputElement',
              ].concat(this.component.inline_button ? ['SubmitButton'] : [])
            case 'OptionSelector':
              return [
                'Label',
                'OptionButtonList',
                'OptionButtonCard',
                'OptionButtonCardText',
                'OptionButtonCardDescription',
                'OptionButtonCardIcon',
                'OptionButtonCardIconImage',
                'OptionButtonCardIconImageElement',
                'OptionButtonCardCheckbox',
                'OptionDropdown',
                /* Stimulus only */
                'Dropdown',
                'DropdownMain',
                'DropdownMainContent',
                'DropdownMainContentInput',
                'DropdownList',
                'DropdownListOption',
                'DropdownListOptionText',
                'DropdownMainContentItem',
                'DropdownMainContentItemText',
                'DropdownMainContentItemClose',
                'DropdownMainContentItemCloseIcon',
              ]
            case 'CustomButton':
              return ['ButtonText', 'ButtonDescription', 'ButtonIcon', 'ButtonIconImage']
            case 'FlowFooter':
              return ['PageFooterButtonPrev', 'PageFooterButtonNext']
            case 'MediaImage':
              return ['Image', 'Caption']
            case 'MediaEmbed':
              return [
                'IFrameContainer',
                'IFrame',
                'Embed',
                'Video',
                'PlayButton',
                'PlaceholderImage',
              ]
            case 'RichText':
              return ['ul', 'ol', 'li', 'a', 'i', 'strong']
            case 'PlainText':
              return ['InnerText']
            case 'StripeCheckout':
              return ['StripeCheckoutButton', 'StripeCheckoutButtonAlternate']
            case 'ProgressBar':
              return [
                'ProgressBarBackground',
                'ProgressBarFilled',
                'BarContainer',
                'BarNode',
                'BarNodeIcon',
                'BarNodeText',
                'BarNodeConnector',
                'BarNodeConnectorInner',
              ]

            default:
              return []
          }
        }

        default:
          return []
      }
    },
    rootElements() {
      switch (this.type) {
        case 'components':
        case 'global_components': {
          switch (this.component && this.component.type) {
            case 'ProgressBar':
              return ['BarNode']
            case 'OptionSelector':
              return [undefined, 'OptionButtonCard']

            default:
              return [undefined]
          }
        }

        default:
          return [undefined]
      }
    },
    elementTypeOptions() {
      const baseStates = this.getElementStates(undefined).map(s => `undefined__${s}`)
      const allOptions = this.elementTypes.reduce(
        (acc, t) => {
          acc.base.push(t)
          const availableStates = this.getElementStates(t)
          availableStates.forEach(state => {
            acc.states.push(`${t}__${state}`)
            const childTypes = this.getChildElementTypes(t, state)
            childTypes.forEach(child =>
              this.rootElements.forEach(r => acc.children.push(`${r}__${state}__${child}`))
            )
          })

          return acc
        },
        { base: [undefined], states: [...baseStates], children: [] }
      )
      allOptions.children = Array.from(new Set(allOptions.children))
      const labeler = t => (t === 'undefined' ? 'Whole Thing' : getFriendlyType(t))
      const mapper = key => {
        const split = (key && key.split('__')) || []
        const label =
          split.length === 3
            ? `${labeler(split[0])} (${split[1].replace(/:/gi, '')}) > ${labeler(split[2])}`
            : split.length === 2
            ? `${labeler(split[0])} (${split[1].replace(/:/gi, '')})`
            : labeler(split[0]) || (this.type === 'pages' ? 'Whole Page' : 'Whole Thing')
        const icon =
          !key && this.type === 'pages' ? 'fluent:page-fit-16-regular@90' : elementToIcon(key)
        return { key, label, icon }
      }

      return {
        base: allOptions.base.map(mapper),
        states: allOptions.states.map(mapper),
        children: allOptions.children.map(mapper),
      }
    },
    selectedElementType() {
      const type = this.selectedElement && this.selectedElement.split('__')[0]
      switch (type) {
        case 'undefined':
          return undefined

        default:
          return type
      }
    },
    selectedElementState() {
      return this.selectedElement && this.selectedElement.split('__')[1]
    },
    selectedChildElement() {
      return this.selectedElement && this.selectedElement.split('__')[2]
    },
    childElementTypes() {
      return this.getChildElementTypes(this.selectedElementType, this.selectedElementState)
    },
    elementStates() {
      return this.getElementStates(this.selectedElementType)
    },
    legacySelectors() {
      const seen = new Set(this.selectors)
      return Object.keys(this.styles || {})
        .filter(s => !seen.has(s))
        .filter(s => typeof this.styles[s] === 'object') // && Object.keys(this.styles[s]).length > 0)
        .filter(s => !s.startsWith('.Flow-') && !s.startsWith('.GlobalLocation-'))
        .filter(key =>
          this.viewport === 'full'
            ? !key.includes(`[max-width~='`)
            : key.includes(
                `[max-width~='${this.currentViewportType === 'tablet' ? 720 : 520}px']`
              ) || !key.includes(`[max-width~='`)
        )
    },
    // suggestedLegacySelectors() {
    //   return this.legacySelectors.filter(key => {
    //     const lastPart = key.split(' ').slice(-1)[0]
    //     switch (this.type) {
    //       case 'pages':
    //         return ['.FormPage', '.form-page-main'].some(str => lastPart.search(str) > -1)
    //       case 'components':
    //         return [
    //           '.component',
    //           `.key-${this.component.key}`,
    //           `.component-key-${this.component.key}`,
    //           ...(this.component.tags || []).map(tag => `.tag-${tag}`),
    //         ].some(str => key.search(str) > -1)

    //       default:
    //         return false
    //     }
    //   })
    // },
    legacySelectorsSorted() {
      const sortedList = []

      switch (this.type) {
        case 'pages': {
          this.legacySelectors.filter(lastElIncludes([`.page-key-${this.page.key}`])).map(pluck)
          this.legacySelectors
            .filter(lastElIncludes((this.page.tags || []).map(tag => `.page-tag-${tag}`)))
            .map(pluck)
          this.legacySelectors.filter(lastElIncludes(['.FormPage', '.form-page-main'])).map(pluck)
          this.legacySelectors.filter(includes(['.FormPage', '.form-page-main'])).map(pluck)
          break
        }
        case 'components':
        case 'global_components': {
          break
        }
        // return [
        //   '.component',
        //   `.key-${this.component.key}`,
        //   `.component-key-${this.component.key}`,
        //   ...(this.component.tags || []).map(tag => `.tag-${tag}`),
        // ].some(str => key.search(str) > -1)

        default:
          break
      }

      this.legacySelectors.map(pluck)

      return sortedList

      function lastElIncludes(arr) {
        return function(sel) {
          return arr.some(
            str =>
              sel
                .split(' ')
                .slice(-1)[0]
                .search(str) > -1
          )
        }
      }
      function includes(arr) {
        return function(sel) {
          return arr.some(str => sel.search(str) > -1)
        }
      }

      function pluck(sel) {
        if (!sortedList.includes(sel)) sortedList.push(sel)
      }
    },
    themeOptions() {
      return [
        { key: 'Default' },
        { key: 'Savvy' },
        { key: 'CommissaryClub', label: 'Commissary Club' },
        { key: 'CustomMySneaker', label: 'Custom My Sneakers' },
        { key: 'Moma' },
        { key: 'ClozeLoop' },
        { key: 'Lawyaw' },
      ]
    },
    selectStyleWatcher() {
      return {
        selectedComponentId: this.selectedComponentId,
        selectedElement: this.selectedElement,
      }
    },
    resetPillWatchers() {
      const {
        type,
        // selectedComponentId,
        viewport,
        // selectedElementType,
        // selectedElementState,
        // selectedChildElement,
      } = this

      return {
        type,
        // selectedComponentId,
        viewport,
        // selectedElementType,
        // selectedElementState,
        // selectedChildElement,
      }
    },
    debounceSetStyle() {
      return debounce(this.setStyle, 250)
    },
    blameVersion() {
      if (Array.isArray(this.flowVersions)) {
        return findLastChangedVersion('styles', null, this.flowVersions, {
          originalForm: this.form,
          property: this.currentSelector,
        })
      }
      return null
    },
  },
  watch: {
    currentSelector: {
      handler(sel) {
        this.showAllStyles = this.getNumStyles(sel) === 0
      },
      immediate: true,
    },
    selectStyleWatcher: {
      async handler(v, o) {
        if (v === o) return
        await this.$nextTick()
        const selectorWithStyle =
          this.selectorsToShow.length === 1
            ? this.selectorsToShow[0]
            : this.selectors.find(s => this.getNumStyles(s) > 0)
        if (!this.selectorsToShow.includes(selectorWithStyle)) this.currentSelector = undefined
        else this.currentSelector = selectorWithStyle
      },
      immediate: true,
    },
    resetPillWatchers: {
      handler(v, o) {
        if (isEqual(v, o)) return
        this.setCurrentSelector(undefined)
        this.showMoreCategories = {}

        if (v.type !== o.type) {
          this.selectedElement = undefined
          // this.selectedElementType = undefined
          // this.selectedElementState = undefined
          // this.selectedChildElement = undefined
        }
        if (v.selectedElementType !== o.selectedElementType) {
          this.selectedElement = undefined
          // this.selectedElementState = undefined
          // this.selectedChildElement = undefined
        }
        if (v.selectedElementState !== o.selectedElementState) {
          this.selectedElement = undefined
          // this.selectedChildElement = undefined
        }
      },
      deep: true,
    },
  },
  methods: {
    getSelectors,
    openDataPath(selector) {
      const path = selector ? `styles.${this.escapeSelector(selector)}` : `styles`
      const title = selector ? selector : `Styles`
      this._openDataPath({ path, title })
    },
    setCurrentSelector(e) {
      this.currentSelector = e
    },
    classToLabel(c) {
      switch (this.type) {
        case 'flow':
          return flowClassToLabel(c)
        case 'pages':
          return pageClassToLabel(c)
        case 'components':
        case 'global_components':
          return componentClassToLabel(c)

        default:
          return c
      }
    },
    async setStyle(k, v, style) {
      if (this.currentSelector) {
        const value = v === '' ? null : this.applyStyleSuffix(v, style)
        this._updateData(`styles.${this.escapeSelector(this.currentSelector)}`, k, value)
        await this.$nextTick()
        const keysInCurrentStyle = Object.keys(this.currentStyles || {})
        const currentKeyIsOnlyKey =
          (keysInCurrentStyle.length === 1 && this.currentStyles.hasOwnProperty(k)) ||
          keysInCurrentStyle.length === 0
        if (v === '' && currentKeyIsOnlyKey) {
          const newStyles = { ...this.styles }
          delete newStyles[this.currentSelector]
          this._updateData(`styles`, null, newStyles)
          // this._updateData(`styles`, `${this.escapeSelector(this.currentSelector)}`, null)
        }
      } else this.$message.warning('No CSS class selected')
    },
    setCustomStyle(val) {
      this.debounceSetStyle(this.newStyleKey, val)

      this.newStyleKey = null
      this.newStyleVal = null

      this.$refs.new_style_key.focus()
    },
    onBlurAutoComplete(styleOption, value) {
      if (!styleOption || !styleOption.importFont) return
      if (value) this.popConfirmLoadFont = value
    },
    currentStyleFontsInFonts(styleObj) {
      /*
      Note - does not compare accurately to the full style object, since this just uses the localized one
      Won't matter probably since on save / load the entire style object fonts will be loaded but some edge cases may exist
      */
      const fontStyleObj = { field: {} }
      if (styleObj['font-family']) fontStyleObj.field['font-family'] = styleObj['font-family']
      if (styleObj['font-weight']) fontStyleObj.field['font-weight'] = styleObj['font-weight']
      if (styleObj['font-style']) fontStyleObj.field['font-style'] = styleObj['font-style']
      const fonts = this.$store.state.fonts || []
      const newFonts = googleFontsUrls(fontStyleObj)
      return fonts.some(f => newFonts.includes(f))
    },
    onPopconfirmNo() {
      this.popConfirmLoadFont = null
    },
    onPopconfirmYes() {
      const fontStyleObj = { field: { 'font-family': this.popConfirmLoadFont } }
      if (this.currentStyles['font-weight'])
        fontStyleObj.field['font-weight'] = this.currentStyles['font-weight']
      if (this.currentStyles['font-style'])
        fontStyleObj.field['font-style'] = this.currentStyles['font-style']
      const fonts = googleFontsUrls(fontStyleObj)
      this.$store.commit('setNewFont', fonts)
      this.popConfirmLoadFont = null
    },
    findInUseStyles(target, stylesObj, firstKey, groups) {
      const forEachCallback = ([k, v], acc) => {
        if (k === target && v) acc.add(v)
        if (target && target.group) {
          const groupVals = new Set(groups[target.group] || [])
          if (groupVals.has(k) && v) acc.add(v)
        }
      }
      const seen = Object.values(stylesObj || {}).reduce((acc, styles) => {
        Object.entries(styles || {}).forEach(e => forEachCallback(e, acc))
        return acc
      }, new Set(firstKey ? [firstKey] : []))

      const defaultStylesObj = defaultStyles(this.form.builder_version) || {}
      Object.values(defaultStylesObj).forEach(styles => {
        Object.entries(styles).forEach(e => forEachCallback(e, seen))
      })

      return Array.from(seen).sort((a, b) => (typeof a === 'string' ? a.localeCompare(b) : a - b))
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toUpperCase().indexOf(input.toUpperCase()) >= 0
      )
    },
    escapeSelector(selector) {
      return selector.replace(/\./g, '\\.')
    },
    convert(fromSel, toSel) {
      const newStyles = { ...this.styles }
      newStyles[toSel] = { ...(newStyles[toSel] || {}), ...newStyles[fromSel] }
      delete newStyles[fromSel]
      this._updateData(null, 'styles', newStyles)
      // alert(`From: ${fromSel} \nTo: ${toSel}`)
    },
    getNumStyles(sel) {
      return this.styles && Object.values(this.styles[sel] || {}).filter(s => s).length
    },
    async addSelector(s) {
      if (!s) return
      this.customSelectorVal = ''
      const sel = this.applyMaxWidthToSelector(s)
      this._updateData('styles', this.escapeSelector(sel), {})
      await this.$nextTick()
      this.setCurrentSelector(sel)
    },
    applyMaxWidthToSelector(selector) {
      let maxWidth
      switch (this.currentViewportType) {
        case 'tablet': {
          maxWidth = 720
          break
        }
        case 'mobile': {
          maxWidth = 520
          break
        }
        case 'full':
        default: {
          maxWidth = null
          break
        }
      }

      if (maxWidth) {
        const split = selector.split(',').map(s => {
          const t = s.trim()
          const maxWidthRegex = /\[max-width~='\d\d\dpx'\]/gi
          const newMaxWidth = `[max-width~='${maxWidth}px']`
          return maxWidthRegex.test(t)
            ? t.replace(maxWidthRegex, newMaxWidth)
            : t.startsWith('.SignpostForm')
            ? t.replace('.SignpostForm', `.SignpostForm${newMaxWidth}`)
            : `.SignpostForm${newMaxWidth} ${t}`
        })
        return split.join(', ')
      }
      return selector
    },
    focusVal() {
      this.$nextTick(() => {
        this.$refs.new_style_val.focus()
      })
    },
    async onContextClick(sel, event) {
      switch (event.key) {
        case 'edit': {
          const newSelector = window.prompt('Enter the new selector', sel)
          const newStyles = { ...(this.styles || {}) }
          newStyles[newSelector] = newStyles[sel]
          delete newStyles[sel]
          this._updateData(null, 'styles', newStyles)
          await this.$nextTick()
          if (this.currentSelector === sel) this.currentSelector = newSelector
          break
        }
        case 'delete': {
          const confirmation = window.prompt(
            'Are you sure you want to delete this selector?\nType "Confirm" to confirm'
          )
          if (confirmation.trim().toLowerCase() !== 'confirm') return
          const newStyles = { ...(this.styles || {}) }
          delete newStyles[sel]
          this._updateData(null, 'styles', newStyles)
          if (this.currentSelector === sel) {
            await this.$nextTick()
            this.currentSelector = undefined
          }
          break
        }

        default: {
          this.convert(sel, event.key)
          break
        }
      }
    },
    getElementStates(type) {
      switch (this.type) {
        case 'pages':
          return []

        case 'flow': {
          switch (type) {
            case 'FlowPopupCloseButton':
              return [':hover', ':enabled', ':disabled', 'selected', ':focus', ':active']
            default:
              return []
          }
        }

        case 'components':
        case 'global_components': {
          switch (this.component && this.component.type) {
            case 'MediaEmbed': {
              switch (type) {
                case 'PlayButton':
                  return [':hover', ':enabled', ':disabled', 'selected', ':focus', ':active']

                default:
                  return []
              }
            }
            case 'StripeCheckout': {
              switch (type) {
                case 'StripeCheckoutButton':
                case 'StripeCheckoutButtonAlternate':
                  return [':hover', ':enabled', ':disabled', 'selected', ':focus', ':active']

                default:
                  return []
              }
            }
            case 'OptionSelector': {
              switch (type) {
                case 'OptionButtonCard':
                  return [':hover', ':enabled', ':disabled', 'selected', ':focus', ':active']
                /* Stimulus only */
                case 'DropdownListOption':
                  return ['selected']
                default:
                  return []
              }
            }
            case 'ProgressBar':
              switch (type) {
                case 'BarNode':
                  return [':hover', 'current', 'completed']
                case 'BarNodeConnector':
                case 'BarNodeConnectorInner':
                  return [':hover', 'completed']

                default:
                  return []
              }
            case 'InputBox':
              switch (type) {
                case 'TextareaElement':
                case 'InputElement':
                  return [
                    // ':hover',
                    ':enabled',
                    ':disabled',
                    ':blank',
                    ':valid',
                    ':required',
                    ':optional',
                    ':user-invalid',
                    ':read-only',
                    ':active',
                    ':focus',
                    '::placeholder',
                    ':placeholder-shown',
                    ':checked',
                  ]
                case 'SubmitButton':
                  return [':hover', ':enabled', ':disabled', 'selected', ':focus', ':active']

                default:
                  return []
              }
            case 'FlowFooter': {
              switch (type) {
                case 'PageFooterButtonPrev':
                case 'PageFooterButtonNext':
                  return [':hover', 'selected', '[disabled=disabled]']

                default:
                  return []
              }
            }
            case 'CustomButton':
            case 'NextFooterButton':
            case 'PrevFooterButton':
              return [
                ':hover',
                ':selected',
                '[disabled=disabled]',
                ':enabled',
                ':disabled',
                ':focus',
                ':active',
              ]
            default:
              return []
          }
        }

        default:
          return []
      }
    },
    getChildElementTypes(type, stateModifier) {
      if (!stateModifier) return []
      switch (type) {
        case 'OptionButtonCard':
          return [
            'OptionButtonCardText',
            'OptionButtonCardDescription',
            'OptionButtonCardIcon',
            'OptionButtonCardIconImage',
            'OptionButtonCardCheckbox',
          ]
        default:
          switch (this.type) {
            case 'components':
            case 'global_components':
              switch (this.component && this.component.type) {
                case 'CustomButton':
                  return ['ButtonText', 'ButtonDescription', 'ButtonIcon', 'ButtonIconImage']
                case 'ProgressBar':
                  return ['BarNodeIcon', 'BarNodeText', 'BarNode']
                case 'StripeCheckout':
                  return ['StripeCheckoutButton', 'StripeCheckoutButton']
                case 'MediaEmbed':
                  return ['PlayButton', 'PlaceholderImage']
                case 'RichText':
                  return ['ul', 'ol', 'li', 'a', 'i', 'strong']
                default:
                  return []
              }

            default:
              return []
          }
      }
    },
    applyStyleSuffix(str, style) {
      if (!style || !Array.isArray(style.suffixes)) return str
      const suffix = this.propertySuffix[style.key] || style.defaultSuffix || null
      if (!suffix || !style.suffixes.includes(suffix)) return str
      if (suffix === '__null') return str
      return this.stripStyleSuffix(str, style)
        .split(' ')
        .map(t => `${t}${suffix}`)
        .join(' ')
    },
    stripStyleSuffix(str, style) {
      if (!str || !Array.isArray(style.suffixes)) return str
      const newRegex = new RegExp([...(style.suffixes || []), '__null'].join('|'), 'gi')
      str.match(newRegex)
      return str.replace(newRegex, '')
    },
    async setSuffix(key, suffix, style) {
      this.$set(this.propertySuffix, key, suffix)
      await this.$nextTick()
      const value = this.currentStyles && this.currentStyles[key]
      const newVal = value //suffix === '__null' ? this.stripStyleSuffix(value, style) : value
      this.setStyle(key, newVal, style)
    },
    getSuffixedValue(style, fallback) {
      return this.propertySuffix[style.key] === '__null'
        ? (this.currentStyles && this.currentStyles[style.key]) || fallback
        : this.stripStyleSuffix(
            (this.currentStyles && this.currentStyles[style.key]) || fallback,
            style
          )
    },
    hasStyles(selectors, count) {
      const cb = s => typeof this.styles[s] === 'object' && Object.keys(this.styles[s]).length > 0
      return count ? selectors.filter(cb) : selectors.some(cb)
    },
    getDefaultStyle(key) {
      const baseStyles = defaultStyles(this.form.builder_version)
      const selector = baseStyles[this.currentSelector] || {}
      return selector[key]
    },
    hasActiveDefaultStyles(selectors) {
      if (this.form.builder_version >= 3) {
        const activeDefaultStyles = []
        const baseStyles = defaultStyles(this.form.builder_version)
        Object.entries(baseStyles).forEach(([selector, styles]) => {
          const selectedSelector = this.styles[selector] || {}
          Object.keys(styles || {}).forEach(property => {
            if (!selectedSelector.hasOwnProperty([property]))
              activeDefaultStyles.push(`${selector}-=-${property}`)
          })
        })
        const allowedSelectors = new Set(selectors)
        return activeDefaultStyles.filter(s => allowedSelectors.has(s.split('-=-')[0])).length > 0
      }
      return false
    },
    getSelectorsForSlice(els) {
      const keys = els.map(el => el.key)
      const slicedSelectors = keys.reduce((acc, key) => {
        const selectors = this.getSelectors(key, this.type, this.parent, this.page, this.viewport)
        acc.push(...selectors)
        return acc
      }, [])
      return Array.from(new Set(slicedSelectors))
    },
    async addCssFromClipboard() {
      const text = await navigator.clipboard.readText()
      const basePath = this.currentSelector
        ? `styles.${this.escapeSelector(this.currentSelector)}`
        : `styles`
      text
        .trim()
        .split(';')
        .filterExists()
        .map(r => r.trim().split(':'))
        .filter(([key, val]) => key && val)
        .forEach(([key, val]) =>
          this._updateData(basePath, key.trim(), val.replace('!important', '').trim())
        )
    },
  },
}
</script>
