<template>
  <div class="Profile">
    <Entity collection="users" :id="userId" v-model="userData" />
    <Entity collection="groups" :id="activeGroupId" v-model="groupData" />
    <div class="profile section">
      <SafeImage
        class="profile-pic"
        :src="user ? user.picture : ''"
        fallback="/images/profile.png"
      />
      <div>
        <div class="name">{{ userData && userData.displayName }}</div>
        <div class="group">{{ groupName }}</div>
      </div>
    </div>

    <EditUserDetails />

    <Button class="Button primary sign-out" icon="sign-out-alt" text="Sign Out" :click="signOut" />
  </div>
</template>

<script>
import { logout } from '@/boot-up/authentication'

import EditUserDetails from '@/components/integrations/EditUserDetails'

export default {
  name: 'Profile',
  components: {
    EditUserDetails,
  },
  data() {
    return {
      userData: null,
      groupData: null,
    }
  },
  computed: {
    user() {
      return this.$store.getters.user
    },
    userId() {
      return this.user.uid
    },
    activeGroupId() {
      return this.$store.getters.activeGroupId
    },
    groupName() {
      return this.groupData && (this.groupData.name || this.groupData.title)
    },
  },
  methods: {
    async signOut() {
      await logout()
      this.$router.push('/login')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/_variables.scss';

.Profile {
  > .profile {
    display: flex;
    justify-content: flex-start;
    padding: 10px 0;

    img.profile-pic {
      border-radius: 50%;
      width: 75px;
      height: 75px;
      margin-right: 3%;
    }
    > div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      > .name {
        font-size: 1.75em;
      }
      > .group {
        font-size: 1.25em;
      }
    }
  }
  .Button.sign-out {
    margin: 40px 0 0;
  }
}
</style>
