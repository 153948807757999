<template>
  <!-- <a-tooltip placement="right" :title="tooltip || title"> -->
  <div @mouseenter="onMouseEnter" @mouseleave="onMouseLeave">
    <EditorLabel v-if="!noLabel" :label="title" />
    <div class="flex gap-1">
      <a-select
        :class="{ monospaced: isKey }"
        :mode="mode"
        :placeholder="placeholder || 'None'"
        :disabled="disabled"
        :value="val === undefined ? defaultVal : val"
        :style="allowComputed ? `width: 90%;` : `width: 100%;`"
        @change="val = $event"
      >
        <template v-if="hasGroups">
          <a-select-opt-group v-for="group in groups" :key="group">
            <a-select-option
              v-for="{ key, label } in fullOptions.filter(
                o => o.group === group || (!o.group && group === 'other')
              )"
              :key="key"
              :value="key"
            >
              {{ isKey ? label || key : universalIdToProper(label || key) }}
            </a-select-option>
          </a-select-opt-group>
        </template>
        <template v-else>
          <a-select-option v-for="{ key, label } in fullOptions" :key="key" :value="key">
            {{ isKey ? label || key : universalIdToProper(label || key) }}
          </a-select-option>
        </template>
      </a-select>
      <a-tooltip
        v-if="allowComputed"
        slot="suffix"
        placement="right"
        title="Insert template values"
      >
        <a-dropdown :trigger="['click']">
          <a-menu slot="overlay" @click="onComputedSelect">
            <a-menu-item
              v-for="key in keys.filter(key =>
                component ? key !== (component.key || component.id) : true
              )"
              :key="key"
            >
              <a-icon type="link" /> {{ key }}
            </a-menu-item>
          </a-menu>
          <a-button
            type="dashed"
            shape="circle"
            size="small"
            style="font-family: monospace; font-size: 11px; font-weight: bold; color: #777; margin-right: -5px;"
            >{}</a-button
          >
        </a-dropdown>
      </a-tooltip>
      <slot />
    </div>
  </div>
  <!-- </a-tooltip> -->
</template>

<script>
import { universalIdToProper } from '@/helpers/textStringConversions'

import EditorMixin from './EditorMixin'

export default {
  name: 'EditorSelect',
  mixins: [EditorMixin],
  inject: ['_getOptions', '_getData', '_getBasePath', '_updateData'],
  props: {
    options: Array,
    multiple: Boolean,
    tags: Boolean,
    disabled: Boolean,
    allowComputed: Boolean,
    isKey: Boolean,
    defaultVal: {},
    component: {},
  },
  computed: {
    keys() {
      return Array.from(new Set(this._getOptions().map(o => o.key)))
    },
    mode() {
      return this.tags ? 'tags' : this.multiple ? 'multiple' : undefined
    },
    fullOptions() {
      return (this.options || []).map(o =>
        o === undefined ? { key: o, label: 'None' } : typeof o === 'string' ? { key: o } : o
      )
    },
    hasGroups() {
      return Boolean(this.groups.find(g => g !== 'other'))
    },
    groups() {
      return Array.from(new Set(this.fullOptions.map(o => o.group || 'other')))
    },
  },
  methods: {
    universalIdToProper,
    onComputedSelect({ key }) {
      const vals = Array.isArray(this.val)
        ? [...this.val]
        : typeof this.val === 'string'
        ? [this.val]
        : []
      const allowsMultiple = this.multiple || this.tags
      const val = `{{${key}}}`
      const alreadyExists = vals.includes(val)
      this.val = alreadyExists
        ? vals.filter(v => v !== val)
        : allowsMultiple
        ? Array.from(new Set([...vals, val]))
        : [val]
    },
  },
}
</script>
