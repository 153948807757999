import VueRouter from 'vue-router'
import Vue from 'vue'
// import Login from '@/views/Login.vue'
import SetGroupName from '@/views/SetGroupName.vue'
import SummaryPage from '@/views/SummaryPage.vue'
import Contacts from '@/components/contacts/Contacts.vue'
import ConnectionsDashboard from '@/views/ConnectionsDashboard'
import Settings from '@/views/SettingsNew.vue'
import SettingsProfile from '@/views/settings/Profile.vue'
import SettingsAnalytics from '@/views/settings/Analytics.vue'
import SettingsIntegrations from '@/views/settings/Integrations.vue'
import SettingsFeatureFlags from '@/views/settings/FeatureFlags.vue'
import SettingsBilling from '@/views/settings/Billing.vue'
import SettingsAdmin from '@/views/settings/Admin.vue'
// import FormEditorView from '@/components/form/FormEditorView.vue'
import FlowResultsPage from '@/views/FlowResultsPage.vue'
import FlowEditor from '@/views/FlowEditor.vue'
import AllFlows from '@/views/AllFlows.vue'

import api from '@/helpers/api'

Vue.use(VueRouter)

const routes = [
  {
    path: '/callback/:type/:serviceId',
    name: 'callback',
  },
  {
    path: '/set-group-name',
    name: 'set-group-name',
    component: SetGroupName,
  },
  {
    path: '/billing',
    beforeEnter: () => {
      manageBilling()
    },
  },
  /* Standalone Flows, not actually used as FlowSwitch handles it */
  {
    path: '/flow/:id/feedback',
    name: 'flow-feedback',
    props: true,
  },
  {
    path: '/flow/:id',
    name: 'flow',
    props: true,
  },
  {
    path: '/all-flows',
    name: 'all-flows',
    component: AllFlows,
  },
  // {
  //   path: '/projects',
  //   name: 'projects',
  //   component: Projects,
  // },
  {
    path: '/',
    name: 'home',
    component: SummaryPage,
  },
  {
    path: '/project',
    redirect: '/',
  },
  {
    path: '/project/flows',
    name: 'flows',
    component: SummaryPage,
    props: true,
  },
  {
    path: '/project/flows/:id',
    name: 'flow-summary',
    component: SummaryPage,
    props: true,
  },
  {
    path: '/project/flows/:id/edit',
    name: 'flow-editor',
    component: FlowEditor,
    props: true,
  },
  {
    path: '/project/flows/:id/preview',
    name: 'flow-preview',
    component: FlowEditor,
    props: true,
  },
  {
    path: '/project/flows/:id/entries',
    name: 'flow-entries',
    component: FlowResultsPage,
    props: true,
  },
  // {
  //   path: '/project/sites',
  //   name: 'sites',
  //   component: SummaryPage,
  //   props: true,
  // },
  {
    path: '/people',
    name: 'people',
    component: Contacts,
    props: true,
  },
  {
    path: '/project/home',
    redirect: '/',
  },
  /* Redirects */
  {
    path: '/insights',
    name: 'insights',
    redirect: '/',
  },
  {
    path: '/live',
    name: 'live',
    redirect: '/',
  },
  {
    path: '/funnel',
    name: 'funnel',
    redirect: '/',
  },
  {
    path: '/flows/:id',
    redirect: '/',
    props: true,
  },
  {
    path: '/flows',
    redirect: '/',
  },
  {
    path: '/blocks/forms',
    redirect: '/',
  },
  {
    path: '/sites',
    redirect: '/',
  },
  {
    path: '/integrations*',
    name: 'integrations',
    component: SettingsIntegrations,
  },
  {
    path: '/settings',
    name: 'settings',
    component: Settings,
    redirect: '/settings/profile',
    children: [
      { path: 'profile', component: SettingsProfile },
      { path: 'analytics', component: SettingsAnalytics },
      { path: 'integrations-dashboard', component: ConnectionsDashboard },
      {
        path: 'integrations',
        component: SettingsIntegrations,
        children: [{ path: '*', component: SettingsIntegrations }],
      },
      { path: 'feature-flags', component: SettingsFeatureFlags },
      { path: 'billing', component: SettingsBilling },
      { path: 'admin', component: SettingsAdmin },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router

async function manageBilling() {
  const {
    data: {
      success,
      data: { portalSessionUrl },
    },
  } = await api('/billing/create-customer-portal-session')

  if (success) {
    window.open(portalSessionUrl)
  }
}
