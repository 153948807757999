<template>
  <div
    class="EditorToolbar flex justify-center"
    :class="[name, anchored ? 'w-full' : 'fixed']"
    ref="root"
    :style="styleText"
  >
    <div class="h-8 flex items-stretch shadow-lg border border-gray-100 bg-white rounded-full">
      <a-tooltip v-for="(option, index) in toolbarOptions" :key="option.key" :title="option.label">
        <component
          class="px-3 hover:text-purple hover:bg-gray-100"
          :class="[
            option.class,
            {
              'pl-4': index === 0,
              'pr-4': index === toolbarOptions.length - 1,
              'rounded-l-full': index === 0,
              'rounded-r-full': index === toolbarOptions.length - 1,
              'bg-gray-200': option.active,
              'text-purple': option.active,
            },
          ]"
          :is="option.componentType"
          :component="currentComponent"
          :style="option.type === 'editor_select' ? 'padding: 0;' : ''"
          v-bind="option"
          @update="([key, val]) => onUpdate(key, val)"
        />
      </a-tooltip>
    </div>
  </div>
</template>

<script>
// import FileToolbarMenu from 'vue-file-toolbar-menu'

import componentTypes, { inputTypes } from '@/components/form/editor/helpers/componentTypes'
import { snakeToPascal, snakeToProper } from '@/helpers/textStringConversions'
// import EditorInput from '../helpers/EditorInput.vue'
import EditorSelect from '../helpers/EditorSelect.vue'
import EditorPaneMixin from './EditorPaneMixin'
import getOptions from '../helpers/options'
import { isEqual, throttle } from 'lodash'
import isElementInViewport from '@/components/form/editor/helpers/isElementInViewport.js'

import KeyEditor from './toolbar/KeyEditor'
import OptionButton from './toolbar/OptionButton'

export default {
  name: 'EditorToolbar',
  // components: { FileToolbarMenu },
  mixins: [EditorPaneMixin],
  components: {
    KeyEditor,
    OptionButton,
  },
  provide() {
    return {
      _getBasePath: () => this.basePath,
      _getOptions: () => this.options,
      _getData: () => this.form,
      _updateData: this._updateReactive,
    }
  },
  inject: [
    '_getRegistered',
    '_getSelectedComponentId',
    '_setSelectedComponentId',
    '_updateReactive',
    '_register',
  ],
  props: {
    form: Object,
    userData: Object,
    name: String,
    anchored: Boolean,
    location: { type: String, default: 'top' },
  },
  data() {
    return {
      position: null,
      dimensions: null,
      isInView: false,
    }
  },
  computed: {
    basePath() {
      return this.currentComponentIsGlobal
        ? `components.${this.currentComponentIndex}`
        : `pages.${this.currentPageIndex}.components.${this.currentComponentIndex}`
    },
    options() {
      return getOptions(this.form, this.userData)
    },
    pages() {
      return (this.form && this.form.pages) || []
    },
    currentPageId() {
      const currentPageId = this.userData && this.userData.currentPageId
      return currentPageId !== undefined
        ? currentPageId
        : this.pages && this.pages[0] && this.pages[0].id
    },
    currentComponentId() {
      return this._getSelectedComponentId()
    },
    currentPage() {
      return this.pages.find(p => p.id === this.currentPageId)
    },
    currentPageIndex() {
      return this.pages.findIndex(p => p.id === this.currentPageId)
    },
    currentComponentIsGlobal() {
      const globalComponents = this.form.components || []
      return Boolean(globalComponents.find(c => c.id === this.currentComponentId))
    },
    components() {
      return this.currentComponentIsGlobal
        ? this.form.components || []
        : (this.currentPage && this.currentPage.components) || []
    },
    currentComponent() {
      const finder = c => c.id === this.currentComponentId
      return this.components.find(finder)
    },
    currentComponentIndex() {
      const finder = c => c.id === this.currentComponentId
      return this.components.findIndex(finder)
    },
    containerOptions() {
      return [
        { key: undefined, label: 'No Container' },
        ...this.components.filter(c => c.type === 'Container').map(c => c.key),
      ]
    },
    componentTypeOptions() {
      return componentTypes()
    },
    inputTypeOptions() {
      return inputTypes()
    },
    styleText() {
      if (this.position && this.dimensions && this.isInView) {
        const { y, x, width, height } = this.position
        const toolbarHeight = this.dimensions.height + 10
        switch (this.location) {
          case 'bottom':
            return `left:${x + (width - this.dimensions.width) / 2}px;top:${y + height + 10}px;`
          case 'top':
          default:
            return `left:${x}px;top:${y - toolbarHeight}px;`
        }
      }
      switch (this.location) {
        case 'bottom':
          return 'visibility: hidden;'

        default:
          return ''
      }
    },
    toolbarOptions() {
      const self = this
      const currentComponent = this.currentComponent || {}
      const formatArray = arr =>
        arr
          .filter(o => (o.hasOwnProperty('condition') ? Boolean(o.condition) : true))
          .map(o => ({
            ...o,
            _key: o.key,
            componentType: (o.type && snakeToPascal(o.type)) || 'OptionButton',
            label: o.label || snakeToProper(o.key),
            style: `${o.style}${o.type === ' editor_select' ? 'padding:0;' : ''}`,
            noLabel: true,
          }))
      switch (this.location) {
        case 'bottom': {
          const buttons = [
            {
              key: 'move-down',
              // condition: this.currentComponentIndex < this.components.length - 1,
              condition: (() => {
                const sameLevelComponents = this.components.filter(
                  c => this.currentComponent && c.parent_key === this.currentComponent.parent_key
                )
                return (
                  sameLevelComponents.findIndex(c => c.id === this.currentComponentId) <
                  sameLevelComponents.length - 1
                )
              })(),
              label: 'Move Component Down',
              icon: 'line-md:arrow-open-down',
              click: () => self.action('move-component-down'),
            },
            {
              key: 'add_below',
              icon: 'plus',
              click: () =>
                self.openModal(
                  this.currentComponentIsGlobal ? 'new-global-component' : 'new-component',
                  1
                ),
            },
          ]
          return formatArray(buttons)
        }

        default: {
          /* https://github.com/motla/vue-file-toolbar-menu/blob/master/API.md */
          const buttons = [
            {
              key: 'component_key',
              type: 'key_editor',
            },

            // {
            //   key: 'type',
            //   type: 'editor_select',
            //   options: self.componentTypeOptions,
            // },
            {
              key: 'duplicate',
              icon: 'copy',
              click: () => self.openModal('dupe-component'),
            },
            {
              key: 'delete',
              icon: 'trash',
              click: () => self.action('delete-component'),
              confirm: true,
              confirmTitle: `Are you sure you want to delete this component?`,
            },
            {
              key: 'move_up',
              condition:
                this.components
                  .filter(
                    c => this.currentComponent && c.parent_key === this.currentComponent.parent_key
                  )
                  .findIndex(c => c.id === this.currentComponentId) > 0, //this.currentComponentIndex > 0,
              label: 'Move Component Up',
              icon: 'line-md:arrow-open-up',
              // icon: 'sort-size-up',
              click: () => self.action('move-component-up'),
            },
            {
              key: 'move-down',
              condition:
                (() => {
                  const sameLevelComponents = this.components.filter(
                    c => this.currentComponent && c.parent_key === this.currentComponent.parent_key
                  )
                  return (
                    sameLevelComponents.findIndex(c => c.id === this.currentComponentId) <
                    sameLevelComponents.length - 1
                  )
                })() && !this.isInView,
              // condition: this.currentComponentIndex < this.components.length - 1 && !this.isInView,
              label: 'Move Component Down',
              icon: 'line-md:arrow-open-down',
              click: () => self.action('move-component-down'),
            },
            // {
            //   key: 'add_above',
            //   icon: 'plus',
            //   click: () =>
            //     self.openModal(
            //       this.currentComponentIsGlobal ? 'new-global-component' : 'new-component',
            //       -1
            //     ),
            // },
            // {
            //   key: 'add_below',
            //   icon: 'plus',
            //   condition: !this.isInView,
            //   click: () =>
            //     self.openModal(
            //       this.currentComponentIsGlobal ? 'new-global-component' : 'new-component',
            //       1
            //     ),
            // },
            {
              key: 'input_type',
              type: 'editor_select',
              condition: currentComponent.type === 'InputBox',
              options: self.inputTypeOptions,
            },
            // {
            //   class: 'cursor-pointer',
            //   title: 'Upload a file',
            //   icon: 'file_upload',
            //   condition: ['MediaImage', 'MediaEmbed'].includes(currentComponent.type),
            //   click: () => self.$store.commit('setUploading', true),
            // },
            {
              key: 'make_required',
              active: currentComponent.isRequired,
              icon: 'ic:outline-notification-important',
              condition: ['InputBox', 'OptionSelector', 'FileUpload'].includes(
                currentComponent.type
              ),
              click: () => self.onUpdate('isRequired', !currentComponent.isRequired),
            },
            {
              key: 'show_button_description',
              active: Boolean(
                currentComponent.description || self.editorActions.getShowButtonDescriptions()
              ),
              icon: 'ic:outline-notification-important',
              condition:
                ['CustomButton'].includes(currentComponent.type) ||
                (['OptionSelector'].includes(currentComponent.type) && !currentComponent.dropdown),
              click: () =>
                self.editorActions.setShowButtonDescriptions(
                  !self.editorActions.getShowButtonDescriptions()
                ),
            },
            // {
            //   key:'allow_multiple_answers',
            //   active: currentComponent.multiple,
            //   icon: '',
            //   condition: ['FileUpload', 'OptionSelector'].includes(currentComponent.type),
            //   click: () => self.onUpdate('multiple', !currentComponent.multiple),
            // },
            // {
            //   key: 'display_as_dropdown',
            //   active: currentComponent.dropdown,
            //   icon: 'mdi:form-dropdown',
            //   condition: ['OptionSelector'].includes(currentComponent.type),
            //   click: () => self.onUpdate('dropdown', !currentComponent.dropdown),
            // },
            // // { is: self.generateMemoryComponent('ContainerEditor') },
            // // {
            // //   class: 'cursor-pointer',
            // //   title: 'Anchor',
            // //   active: self.anchored,
            // //   icon: 'weekend',
            // //   click: () => self.$emit('anchor', !self.anchored),
            // // },
            // // {
            // //   class: 'cursor-pointer',
            // //   title: 'Close',
            // //   icon: 'close',
            // //   click: self.onXClick,
            // // },
            {
              key: 'more_options',
              label:
                this.$store.state.showRightBar === 'options'
                  ? 'Hide More Options'
                  : 'Show More Options',
              icon: 'ellipsis-h',
              click: self.showRightBar,
              class: this.$store.state.showRightBar === 'options' ? 'opacity-70' : '',
            },
          ]
          return formatArray(buttons)
        }
      }
    },
    editorActions() {
      return this._getRegistered('editorAction') || {}
    },
    action() {
      return this.editorActions && this.editorActions.action
    },
    openModal() {
      return this.editorActions && this.editorActions.modal
    },
  },
  mounted() {
    this.deregisterFileCallback = this._register('fileUploadCallback', url =>
      this.onUpdate('src', url)
    )
    if (!this.anchored) this.setup()
  },
  beforeDestroy() {
    if (this.deregisterFileCallback) this.deregisterFileCallback()
    this.cleanup()
  },
  watch: {
    anchored: {
      handler(v) {
        if (v) this.cleanup()
        else this.setup()
      },
    },
    toolbarOptions: {
      handler(n, o) {
        if (isEqual(n, o)) return
        const { height, width } = this.$refs.root.getBoundingClientRect()
        this.dimensions = { height, width }
      },
    },
    currentComponentId: {
      handler(id, old) {
        if (!id || id === old) return
        this.findSelectedComponentPosition(id)
      },
      immediate: true,
    },
    '$store.state.showRightBar'() {
      this.throttledAdjustPosition()
    },
  },
  methods: {
    setup() {
      const { height, width } = this.$refs.root.getBoundingClientRect()
      this.dimensions = { height, width }
      const self = this
      this.throttledAdjustPosition = throttle(
        () => self.findSelectedComponentPosition(self.currentComponentId),
        50,
        { trailing: true, leading: false }
      )
      const flow = document.querySelector('.viewport.scroller.max-h-full')
      if (flow) {
        this.cleanup()
        this.flowEl = flow
        this.flowEl.addEventListener('scroll', self.throttledAdjustPosition, { passive: true })
        window.addEventListener('resize', self.throttledAdjustPosition, { passive: true })
        this.throttledAdjustPosition()
      }
    },
    cleanup() {
      if (this.flowEl) this.flowEl.removeEventListener('scroll', this.throttledAdjustPosition)
    },
    showRightBar() {
      const value = this.$store.state.showRightBar === 'options' ? null : 'options'
      this.$store.commit('setRightBar', value)
    },
    findSelectedComponentPosition(id) {
      this.setPosition(id)

      const self = this
      setTimeout(() => {
        self.setPosition(self.currentComponentId)
      }, 250)
      setTimeout(() => {
        self.setPosition(self.currentComponentId)
      }, 500)
      setTimeout(() => {
        self.setPosition(self.currentComponentId)
      }, 750)
      setTimeout(() => {
        self.setPosition(self.currentComponentId)
      }, 1000)
    },
    setPosition(id) {
      const el = document.querySelector(`.cid-${id}`)
      if (!el) return
      const rect = el.getBoundingClientRect()
      this.position = rect
      const isInView = isElementInViewport(el)
      this.isInView = isInView
    },
    onXClick(e) {
      this._setSelectedComponentId(e, null)
    },
    onUpdate(key, value) {
      this._updateReactive(this.basePath, key, value)
    },
    generateMemoryComponent(componentType) {
      const self = this
      switch (componentType) {
        case 'KeyEditor': {
          const key = this.currentComponent && this.currentComponent.key
          return {
            render() {
              return (
                <PlainEditableText
                  class="flex items-center monospaced font-bold text-sm"
                  initialText={key}
                  placeholder="Component Key"
                  on-update-text={e => self.onUpdate('key', e)}
                />
              )
              // return <EditorInput noLabel jsxKey="key" />
            },
          }
        }
        case 'TypesEditor':
          return {
            render() {
              return (
                <EditorSelect
                  label="Component Type"
                  noLabel
                  placeholder="No Type"
                  jsxKey="type"
                  options={self.componentTypeOptions}
                  style="width: 150px;padding-left: 10px;padding-top: 2px;"
                />
              )
            },
          }
        case 'InputType':
          return {
            render() {
              return (
                <EditorSelect
                  label="Input Type"
                  noLabel
                  placeholder="Text"
                  jsxKey="input_type"
                  options={self.inputTypeOptions}
                  style="width: 150px;padding-left: 10px;padding-top: 2px;"
                />
              )
            },
          }
        case 'ContainerEditor':
          return {
            render() {
              return (
                <EditorSelect
                  label="Parent Container Key"
                  noLabel
                  placeholder="No Container"
                  jsxKey="parent_key"
                  options={self.containerOptions}
                  style="width: 200px;padding-top: 2px;"
                />
              )
            },
          }

        default:
          break
      }
    },
  },
}
</script>
<style lang="scss">
.EditorToolbar {
  z-index: 225;
  transition: left 0.15s linear, top 0.15s linear, bottom 0.15s linear;

  .ant-select-selection.ant-select-selection--single {
    border: none;
    height: 30px;
    border-radius: 0;
    margin: 0;
    transition: none;
    min-width: 100px;

    &:hover {
      background-color: inherit;
      color: var(--color-savvy);
    }
  }
  .ant-select-selection--single .ant-select-selection__rendered {
    margin-right: 32px;
  }
  .ant-select-focused .ant-select-selection,
  .ant-select-selection:focus,
  .ant-select-selection:active {
    box-shadow: none;
  }
}
</style>
