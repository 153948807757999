<template>
  <div class="RichTextEditor" @click.stop.prevent>
    <template v-if="location === 'options' || !location">
      <editor-floating-menu :editor="editor" v-slot="{ commands, isActive, menu }">
        <div
          class="floating-menu"
          :class="{ 'is-active': menu.isActive }"
          :style="`top: ${menu.top}px`"
        >
          <button
            class="menububble__button"
            :class="{ 'is-active': isActive.heading({ level: 1 }) }"
            @click="commands.heading({ level: 1 })"
          >
            H1
          </button>

          <button
            class="menububble__button"
            :class="{ 'is-active': isActive.heading({ level: 2 }) }"
            @click="commands.heading({ level: 2 })"
          >
            H2
          </button>

          <button
            class="menububble__button"
            :class="{ 'is-active': isActive.heading({ level: 3 }) }"
            @click="commands.heading({ level: 3 })"
          >
            H3
          </button>
        </div>
      </editor-floating-menu>
      <editor-menu-bubble
        :editor="editor"
        :keep-in-bounds="keepInBounds"
        v-slot="{ commands, isActive, menu, getMarkAttrs }"
      >
        <div
          class="menububble"
          :class="{ 'is-active': menu.isActive }"
          :style="`left: ${menu.left}px; top: ${menu.top}px;`"
          style="pointer-events: auto;"
        >
          <button
            class="menububble__button"
            :class="{ 'is-active': isActive.bold() }"
            style="pointer-events: auto;"
            @click.stop="commands.bold"
          >
            <a-icon type="bold" />
          </button>

          <button
            class="menububble__button"
            :class="{ 'is-active': isActive.italic() }"
            style="pointer-events: auto;"
            @click.stop="commands.italic"
          >
            <a-icon type="italic" />
          </button>

          <button
            class="menububble__button"
            :class="{ 'is-active': isActive.strike() }"
            style="pointer-events: auto;"
            @click.stop="commands.strike"
          >
            <a-icon type="strikethrough" />
          </button>

          <button
            class="menububble__button"
            :class="{ 'is-active': isActive.ordered_list() }"
            @click.stop="commands.ordered_list"
          >
            <a-icon type="ordered-list" />
          </button>

          <button
            class="menububble__button"
            :class="{ 'is-active': isActive.bullet_list() }"
            style="pointer-events: auto;"
            @click.stop="commands.bullet_list"
          >
            <a-icon type="unordered-list" />
          </button>

          <form
            class="menububble__form"
            v-if="linkMenuIsActive"
            @submit.prevent.stop="setLinkUrl(commands.link, linkUrl)"
          >
            <input
              class="menububble__input"
              type="text"
              v-model="linkUrl"
              placeholder="https://"
              ref="linkInput"
              style="pointer-events: auto;"
              @keydown.esc="hideLinkMenu"
            />
            <button
              class="menububble__button"
              @click.stop="setLinkUrl(commands.link, null)"
              type="button"
              style="pointer-events: auto;"
            >
              <a-icon name="delete" />
            </button>
          </form>

          <template v-else>
            <button
              class="menububble__button"
              @click.stop="showLinkMenu(getMarkAttrs('link'))"
              :class="{ 'is-active': isActive.link() }"
              style="pointer-events: auto;"
            >
              <a-icon type="link" />
            </button>
          </template>
        </div>
      </editor-menu-bubble>
    </template>
    <template v-if="location === 'content' || !location">
      <EditorHeading v-if="location" icon="paragraph" title="Content" />

      <editor-content
        class="editor-content cursor-text is-picker-on"
        :editor="editor"
        @click.native.stop
      />
      <a-button
        v-if="location"
        class="w-full"
        @click="
          _openDataPath({
            path: `${_getBasePath()}.text`,
            title: component.key,
          })
        "
      >
        Edit as raw HTML
        <Icon class="ml-1" name="external-link-alt" />
      </a-button>
    </template>
  </div>
</template>

<script>
// ['paragraph','blockquote','bullet_list','code_block','hard_break','heading','ordered_list','todo_list','link','bold','code','italic','strike','underline','undo','redo','undoDepth','redoDepth',]
import { Editor, EditorContent, EditorMenuBubble, EditorFloatingMenu } from 'tiptap'
import {
  Blockquote,
  BulletList,
  CodeBlock,
  HardBreak,
  Heading,
  ListItem,
  OrderedList,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Strike,
  Underline,
  History,
} from 'tiptap-extensions'
import debounce from 'lodash/debounce'
import CustomLink from './helpers/CustomLink'

export default {
  inject: {
    _isEditing: { default: () => () => {} },
    _getBasePath: { default: () => () => {} },
    _openDataPath: { default: () => () => {} },
  },
  components: {
    EditorContent,
    EditorMenuBubble,
    EditorFloatingMenu,
  },
  props: {
    component: Object,
    placeholder: String,
    editorText: String,
    textUpdate: String,
    location: String,
  },
  data() {
    return {
      keepInBounds: true,
      html: null,
      editor: null,
      linkUrl: null,
      linkMenuIsActive: false,
      focused: false,
    }
  },
  computed: {
    /* Remains emit method because it is rendered in both the Editor and the actual flow (webapp only) */
    debounceUpdate() {
      return debounce(update => this.$emit('update', update), 200, {
        trailing: true,
        leading: false,
      })
    },
    isEditing() {
      return this._isEditing()
    },
    text() {
      return this.editorText || (this.component && this.component.text)
    },
    debounceSetContent() {
      return debounce(t => this.editor.setContent(t), 250, { trailing: true, leading: false })
    },
  },
  async mounted() {
    const text = this.text || this.placeholder || 'Enter Text Here\n\n\n'
    this.editor = new Editor({
      extensions: [
        new Blockquote(),
        new BulletList(),
        new CodeBlock(),
        new HardBreak(),
        new Heading({ levels: [1, 2, 3] }),
        new ListItem(),
        new OrderedList(),
        new TodoItem(),
        new TodoList(),
        new CustomLink({ target: '_blank' }),
        new Bold(),
        new Code(),
        new Italic(),
        new Strike(),
        new Underline(),
        new History(),
      ],
      disableInputRules: true,
      disablePasteRules: true,
      onUpdate: ({ getHTML }) => {
        this.html = getHTML()
      },
      content: text,
      onfocus: this.onFocus,
      onblur: this.onBlur,
    })
  },
  beforeDestroy() {
    this.editor.destroy()
  },
  watch: {
    textUpdate: {
      handler(t) {
        if (t) {
          const transaction = this.editor.state.tr.insertText(t)
          this.editor.view.dispatch(transaction)
          this.$emit('clear')
        }
      },
    },
    isEditing: {
      handler(v) {
        if (!v) this.debounceSetContent(this.text)
      },
    },
    html: {
      handler(v) {
        this.debounceUpdate(['text', v])
      },
    },
  },
  methods: {
    onFocus() {
      this.focused = true
    },
    onBlur() {
      this.focused = false
    },
    showLinkMenu(attrs) {
      this.linkUrl = attrs.href
      this.linkMenuIsActive = true
      this.$nextTick(() => {
        this.$refs.linkInput.focus()
      })
    },
    hideLinkMenu() {
      this.linkUrl = null
      this.linkMenuIsActive = false
    },
    setLinkUrl(command, url) {
      command({ href: url })
      this.hideLinkMenu()
    },
    test(e) {
      console.log(`thef `, e)
    },
  },
}
</script>
<style lang="scss" scoped>
@import './RichTextEditor.scss';
</style>
