export function loadGooglePlaces(cb) {
  const src =
    'https://maps.googleapis.com/maps/api/js?key=AIzaSyDalHb-c9FFBVgDuOegNyZpWd-8bwvQikk&libraries=places'
  const scriptExists = document.querySelector(`script[src='${src}']`)
  if (!scriptExists) {
    const script = document.createElement('script')
    script.src = src
    script.async = true
    document.head.appendChild(script)
    if (typeof cb === 'function') script.addEventListener('load', cb)
  }
}
export function loadXkit(cb) {
  const src = `https://${process.env.VUE_APP_XKIT_DOMAIN}/xkit-catalog.js`
  const scriptExists = document.querySelector(`script[src='${src}']`)
  if (!scriptExists) {
    const xkitScript = document.createElement('script')
    xkitScript.src = src
    document.body.appendChild(xkitScript)
    if (typeof cb === 'function') xkitScript.addEventListener('load', cb)
  } else if (typeof cb === 'function') scriptExists.addEventListener('load', cb)
}
export function loadPapercups(user, cb) {
  const src = `https://app.papercups.io/widget.js`
  const scriptExists = document.querySelector(`script[src='${src}']`)
  if (!scriptExists) {
    const papercupsScript = document.createElement('script')
    papercupsScript.src = src
    papercupsScript.async = true
    papercupsScript.defer = true
    papercupsScript.type = 'text/javascript'
    document.body.appendChild(papercupsScript)
    if (typeof cb === 'function') papercupsScript.addEventListener('load', cb)
  }

  const storylineSrc = `https://app.papercups.io/storytime.js`
  const storylineScriptExists = document.querySelector(`script[src='${storylineSrc}']`)
  if (!storylineScriptExists) {
    const papercupsStorylineScript = document.createElement('script')
    papercupsStorylineScript.src = storylineSrc
    papercupsStorylineScript.async = true
    papercupsStorylineScript.defer = true
    papercupsStorylineScript.type = 'text/javascript'
    document.body.appendChild(papercupsStorylineScript)
  }

  window.Papercups = {
    config: {
      // Pass in your Papercups account token here after signing up
      accountId: '5935e64c-2877-43e2-a0ac-74aa1d6dfec6',
      title: 'Welcome to Savvy!',
      subtitle: 'Ask us anything in the chat window below 😊',
      newMessagePlaceholder: 'Start typing...',
      primaryColor: '#6454f3',
      // Optionally pass in a default greeting
      greeting: 'Hi there! How can I help you?',
      hideToggleButton: true,
      // Add this if you want to indicate when you/your agents
      // are online or offline to your customers
      showAgentAvailability: true,
      // Optionally pass in metadata to identify the customer
      customer: {
        name: user.auth.displayName,
        email: user.auth.email,
        external_id: user.uid,
        metadata: { groupId: user.activeGroupId, picture: user.picture }, // Custom fields go here
      },
      // Optionally specify the base URL
      // baseUrl: 'https://app.papercups.io',
      // Add this if you want to require the customer to enter
      // their email before being able to send you a message
      // requireEmailUpfront: true,
    },
  }
}
export function loadZest(cb) {
  ;(function() {
    window.addEventListener('load', function() {
      var t = document.createElement('script')
      t.type = 'text/javascript'
      t.async = !0
      t.src = 'https://hellozest.io/widget/' + window.zestSettings.app_id
      document.body.appendChild(t)
      if (typeof cb === 'function') t.addEventListener('load', cb)
    })
  })()
}

export function loadCommandBar() {
  return new Promise(resolve => {
    var w = window
    var d = document
    var cb = (window.CommandBar = window.CommandBar || [])
    cb.methods = [
      'config',
      'boot',
      'shutdown',
      'setContext',
      'addContext',
      'addCallback',
      'report',
      'log',
      'error',
      'setTestMode',
      'loadEditor',
    ]
    cb.q = []
    cb.f = function(t) {
      return function() {
        var a = Array.prototype.slice.call(arguments)
        a.unshift(t)
        cb.q.push(a)
      }
    }
    for (var t = 0; t < cb.methods.length; t++) {
      var e = cb.methods[t]
      cb[e] = cb.f(e)
    }
    var load = function() {
      var a = 'h'
      var t = 's'
      var r = localStorage.getItem('commandbar.lc')
      var e = 'https://api.commandbar.com'
      var o = 'o'
      var c = 'l'
      var n = 't'
      var l = 'c'
      if (r && r.includes('local')) {
        var v = 'a'
        var s = ':8'
        var i = 'p:/'
        e = 'htt' + i + '/' + c + o + l + v + c + a + o + t + n + s + '000'
      }
      var h = e + '/latest/87984c40'
      const scriptExists = document.querySelector(`script[src='${h}']`)
      if (!scriptExists) {
        var m = d.createElement('script')
        h = r ? h + '?lc=' + r : h
        m.onload = () => resolve()
        m.type = 'text/javascript'
        m.async = true
        m.src = h
        d.head.appendChild(m)
      }
    }
    if (w.attachEvent) {
      w.attachEvent('onload', load)
    } else {
      w.addEventListener('load', load, false)
    }
  })
}
