<template>
  <div class="h-full flex flex-col">
    <div v-if="!noAuthComments" class="flex flex-row justify-between text-xl py-2 px-4">
      <a-tooltip placement="top" title="Page Previews">
        <div
          class="p-1 cursor-pointer border-gray-200 hover:border-b-2"
          :class="{
            'border-b-2': treeView === 'previews',
            'border-purple': treeView === 'previews',
          }"
          :style="{
            color: treeView === 'previews' ? 'var(--color-savvy)' : 'inherit',
          }"
          @click="setTreeNav('previews')"
        >
          <Icon name="rectangle-landscape" />
        </div>
      </a-tooltip>
      <a-tooltip placement="top" title="Components">
        <div
          class="p-1 cursor-pointer border-gray-200 hover:border-b-2"
          :class="{
            'border-b-2': treeView === 'components',
            'border-purple': treeView === 'components',
          }"
          :style="{
            color: treeView === 'components' ? 'var(--color-savvy)' : 'inherit',
          }"
          @click="setTreeNav('components')"
        >
          <Icon name="cube" />
        </div>
      </a-tooltip>
      <a-tooltip placement="top" title="All">
        <div
          class="p-1 cursor-pointer border-gray-200 hover:border-b-2"
          :class="{
            'border-b-2': treeView === 'all',
            'border-purple': treeView === 'all',
          }"
          :style="{
            color: treeView === 'all' ? 'var(--color-savvy)' : 'inherit',
          }"
          @click="setTreeNav('all')"
        >
          <Icon name="folder-tree" />
        </div>
      </a-tooltip>
      <a-tooltip placement="top" title="User Data">
        <div
          class="p-1 cursor-pointer border-gray-200 hover:border-b-2"
          :class="{
            'border-b-2': treeView === 'userData',
            'border-purple': treeView === 'userData',
          }"
          :style="{
            color: treeView === 'userData' ? 'var(--color-savvy)' : 'inherit',
          }"
          @click="setTreeNav('userData')"
        >
          <Icon name="database" />
        </div>
      </a-tooltip>
    </div>
    <div ref="scroller" class="NavSidebar relative scroller scroller-x pb-12 max-h-full">
      <template v-if="treeView === 'previews'">
        <Draggable class="scrollable" v-model="pagePreviews" handle=".handle">
          <a-tooltip
            v-for="({ url, key, title }, index) in pagePreviews"
            :key="key"
            placement="right"
            :title="title"
          >
            <a-dropdown :trigger="['contextmenu']">
              <a-menu slot="overlay" @click="onDropdownClick($event, key)">
                <!-- @click="onDropdownClick({ key: 'dupe-page' }, key)" -->
                <a-menu-item key="dupe-page">Duplicate</a-menu-item>
                <a-menu-item key="delete-page-popover">Delete</a-menu-item>
              </a-menu>

              <NavPagePane
                v-bind="{
                  currentPageId,
                  index,
                  url,
                  value: key,
                  hiddenPages,
                  userData,
                  selectedKeys,
                  isInView: pagesInView[key],
                }"
                :numComments="
                  unresolvedThreads.filter(t => t.location && t.location.page === key.split('|')[1])
                    .length
                "
                @select="onSelect([key])"
              />
            </a-dropdown>
          </a-tooltip>
        </Draggable>
      </template>
      <div v-else-if="treeView === 'components'" class="mx-2">
        <EditorLabel noColon noWrap class="p-2 -mb-1" label="Page Components" icon="box" />
        <a-tree
          showIcon
          draggable
          :expanded-keys="expandedKeys"
          v-bind="{
            treeData: treeDataByKey.pages.children.find(p => p.key === `p|${currentPageId}`)
              .children,
            selectedKeys,
          }"
          @select="onSelect"
          @expand="onExpand"
          @drop="onDrop"
        >
          <a-icon slot="switcherIcon" type="down" />
          <Icon slot="component" class="-ml-2 mb-0.5" name="cube" type="fad" />
          <Icon slot="container" class="-ml-2 mb-0.5" name="cubes" type="fad" />
          <Icon slot="plain_text" class="-ml-2 mb-0.5" name="text" />
          <Icon slot="page_title" class="-ml-2 mb-0.5" name="text" />
          <Icon slot="page_subtitle" class="-ml-2 mb-0.5" name="text" />
          <Icon slot="page_heading_label" class="-ml-2 mb-0.5" name="text" />
          <Icon slot="rich_text" class="-ml-2 mb-0.5" name="text-size" type="fad" />
          <Icon slot="custom_button" class="-ml-2 mb-0.5" name="rectangle-wide" type="fad" />
          <Icon slot="media_image" class="-ml-2 mb-0.5" name="image" type="fad" />
          <Icon slot="media_embed" class="-ml-2 mb-0.5" name="file-video" type="fad" />
          <Icon slot="input_box" class="-ml-2 mb-0.5" name="italic" />
          <Icon slot="option_selector" class="-ml-2 mb-0.5" name="boxes" type="fad" />
          <Icon slot="progress_bar" class="-ml-2 mb-0.5" name="signal-3" type="fad" />
          <Icon slot="stripe_checkout" class="-ml-2 mb-0.5" name="credit-card" type="fad" />
          <Icon slot="new" class="-ml-2 mb-0.5 text-gray-400" name="plus" />
          <div slot="new-component" class="inline-block text-gray-400">New Component</div>
        </a-tree>
      </div>
      <div v-else-if="treeView === 'userData'">
        <UserDataEditor :userData="userData" :form="form" @nav="onSelect([$event])" />
      </div>
      <div v-else class="mx-2">
        <EditorLabel
          noColon
          noWrap
          class="p-2 cursor-pointer hover:opacity-70"
          label="Entire Flow"
          icon="cog"
          @click.native="onSelect(['f|entire_flow'])"
        />

        <div v-for="{ key, icon } in navSections" :key="key">
          <EditorLabel
            noColon
            noWrap
            class="mt-4 mx-2"
            :label="treeDataByKey[key].title"
            :icon="icon"
          />

          <a-tree
            showIcon
            draggable
            :expanded-keys="expandedKeys"
            v-bind="{ treeData: treeDataByKey[key].children, selectedKeys }"
            @select="onSelect"
            @expand="onExpand"
            @drop="onDrop"
          >
            <a-icon slot="switcherIcon" type="down" />
            <Icon slot="page" class="-ml-2 mb-0.5" name="file" />
            <Icon slot="component" class="-ml-2 mb-0.5" name="cube" type="fad" />
            <Icon slot="container" class="-ml-2 mb-0.5" name="cubes" type="fad" />
            <Icon slot="plain_text" class="-ml-2 mb-0.5" name="text" />
            <Icon slot="page_title" class="-ml-2 mb-0.5" name="text" />
            <Icon slot="page_subtitle" class="-ml-2 mb-0.5" name="text" />
            <Icon slot="page_heading_label" class="-ml-2 mb-0.5" name="text" />
            <Icon slot="rich_text" class="-ml-2 mb-0.5" name="text-size" type="fad" />
            <Icon slot="custom_button" class="-ml-2 mb-0.5" name="rectangle-wide" type="fad" />
            <Icon slot="media_image" class="-ml-2 mb-0.5" name="image" type="fad" />
            <Icon slot="media_embed" class="-ml-2 mb-0.5" name="file-video" type="fad" />
            <Icon slot="input_box" class="-ml-2 mb-0.5" name="italic" />
            <Icon slot="option_selector" class="-ml-2 mb-0.5" name="boxes" type="fad" />
            <Icon slot="progress_bar" class="-ml-2 mb-0.5" name="signal-3" type="fad" />
            <Icon slot="stripe_checkout" class="-ml-2 mb-0.5" name="credit-card" type="fad" />
            <Icon slot="cf" class="-ml-2 mb-0.5" name="hexagon" />
            <Icon slot="do" class="-ml-2 mb-0.5" name="database" />
            <Icon slot="new" class="-ml-2 mb-0.5 text-gray-400" name="plus" />
            <div slot="create-new" class="inline-block text-gray-400">Create New</div>
            <div slot="new-page" class="inline-block text-gray-400">New Page</div>
            <div slot="new-component" class="inline-block text-gray-400">New Component</div>
          </a-tree>
        </div>
      </div>
    </div>
    <a-popconfirm
      v-if="!noAuthComments"
      title="Are you sure you want to delete this page"
      okType="danger"
      ok-text="Yes"
      cancel-text="No"
      key="delete-page"
      :visible="showPopConfirm"
      style="z-index:250"
      @confirm="onDropdownClick({ key: 'delete-page' }, targetPageId)"
      @cancel="targetPageId = null"
    >
      <a-dropdown :trigger="['click']">
        <a-button
          class="block bottom-2 left-2 right-2 transition-all transform hover:scale-105 z-50"
          type="primary"
          style="position: absolute; width: calc(100% - 1rem);"
          icon="plus"
        >
          New
        </a-button>
        <a-menu slot="overlay" @click="onDropdownClick">
          <a-menu-item
            v-for="{ key, label, icon, disabled } in actions"
            :key="key"
            v-bind="{ disabled }"
          >
            <div class="flex">
              <div class="w-6 flex items-center display-inline-block">
                <Icon :name="icon" />
              </div>
              {{ label }}
            </div>
          </a-menu-item>
        </a-menu>
      </a-dropdown>
    </a-popconfirm>
    <!-- <a-button
      v-else
      class="block bottom-2 left-2 right-2 transition-all transform hover:scale-105"
      type="primary"
      style="position: absolute; width: calc(100% - 1rem);"
      icon="plus"
      @click="editorActions.modal(treeView === 'previews' ? 'new-page' : 'new-component')"
    >
      New {{ treeView === 'previews' ? 'Page' : 'Component' }}
    </a-button> -->
  </div>
</template>

<script>
import { cloneDeep, startCase, throttle } from 'lodash'
import Draggable from 'vuedraggable'
// import Firebase from 'firebase/app'
// import 'firebase/firestore'

import { getThreadsFromComments, getThreadsFilteredFn } from './helpers/comments.js'
import { camelToSnake } from '@/helpers/textStringConversions'
import { passesConditions } from '../helpers/conditions'
import { getPathFromView, treeToFlowData } from '@/components/form/editor/helpers/navSidebarHelpers'
import getThumbnailUrl from '@/components/form/editor/helpers/thumbnailUrl'
import NavPagePane from './mainEditor/NavPagePane.vue'
import isElementInViewport from '@/components/form/editor/helpers/isElementInViewport.js'
import UserDataEditor from './UserDataEditor.vue'

export default {
  name: 'NavSidebar',
  components: {
    Draggable,
    NavPagePane,
    UserDataEditor,
  },
  inject: [
    '_setSelectedComponentId',
    '_getSelectedComponentId',
    '_setUserData',
    '_getRegistered',
    '_updateReactive',
  ],
  props: {
    form: Object,
    currentPageId: String,
    userData: Object,
    version: Number,
    noAuthComments: Boolean,
    comments: Array,
    savedForm: Object,
  },
  data() {
    return {
      expandedKeys: [],
      treeView: 'previews',
      selectedKeys: [],
      pagesInView: {},
      targetPageId: null,
      showPopConfirm: false,
    }
  },
  computed: {
    treeViewIsPreview() {
      return this.treeView === 'previews'
    },
    selectedComponentId: {
      get() {
        return this._getSelectedComponentId()
      },
      set(v) {
        this._setSelectedComponentId(v)
      },
    },
    pageTreeData() {
      const generateComponentChildren = (comps, fullComponents, prefix, seen) => {
        const containers = comps.filter(c => c.type === 'Container')
        const seenContainers = new Set(containers.map(c => c.key))
        const componentsInContainers = new Set(
          comps.filter(c => seenContainers.has(c.parent_key)).map(c => c.id)
        )
        return comps
          .filter(c => !componentsInContainers.has(c.id))
          .map(c => {
            const alreadySeen = c.type === 'Container' ? seen.has(c.key) : false
            if (c.type === 'Container') seen.add(c.key)
            const hidden = this.itemIsHidden(c)
            const title = `${alreadySeen ? '[RECURSIVE] ' : ''}${c.key}${hidden ? ' (Hidden)' : ''}`
            const children =
              c.type === 'Container' && !alreadySeen
                ? generateComponentChildren(
                    fullComponents.filter(ch => ch.parent_key === c.key),
                    fullComponents,
                    prefix,
                    seen
                  )
                : undefined
            if (
              this.selectedKeys.includes(c.key) ||
              (children && children.map(ch => ch.key).some(k => this.selectedKeys.includes(k))) ||
              (c.type === 'Container' &&
                Array.isArray(children) &&
                children.length === 0 &&
                this.selectedKeys.includes(`${prefix || 'c'}|${c.id}`))
            ) {
              if (!this.noAuthComments) {
                children.push({
                  key: `cr|${prefix || 'c'}`,
                  title: 'New Component',
                  slots: { icon: 'new', title: 'new-component' },
                })
              }
            }
            return {
              key: `${prefix || 'c'}|${c.id}`,
              title,
              children,
              slots: { icon: camelToSnake(c.type || '').slice(1) },
            }
          })
      }
      const formPages = (this.form && this.form.pages) || []
      const globalComponents = this.form.components || []
      return {
        pages: formPages.map(page => {
          const isHidden = this.hiddenPages.has(page.id)
          const isCurrent = this.currentPageId === page.id
          const title = `${page.key}${isHidden ? ' (Hidden)' : ''}${isCurrent ? ' (Current)' : ''}`
          const key = `p|${page.id}`
          const components = page.components || []
          const children = generateComponentChildren(components, components, 'c', new Set())
          if (
            this.selectedKeys.includes(key) ||
            children.map(c => c.key).some(k => this.selectedKeys.includes(k))
          ) {
            if (!this.noAuthComments) {
              children.push({
                key: 'cr|c',
                title: 'New Component',
                slots: { icon: 'new', title: 'new-component' },
              })
            }
          }
          return { key, title, children, slots: { icon: 'page' } }
        }),
        globalComponents: generateComponentChildren(
          globalComponents,
          globalComponents,
          'gc',
          new Set()
        ),
      }
    },
    allTreeData() {
      const generateChildren = (key, prefix) => {
        const children = (this.form[key] || []).map((c, i) => {
          let name = ''
          switch (prefix) {
            case 'cf':
            case 'gc': {
              name = c.key
              break
            }
            case 'do': {
              name = c.name || `${i + 1}: ${startCase(c.output)}`
              break
            }
          }
          const isHidden = this.itemIsHidden(c)
          const title = `${name}${isHidden ? ' (Hidden)' : ''}`
          return {
            key: `${prefix}|${c.id}`,
            title,
            slots: { icon: prefix },
          }
        })
        return !this.noAuthComments
          ? children.concat({
              key: `cr|${prefix}`,
              title: 'Create New',
              slots: {
                icon: 'new',
                title: 'create-new',
                slots: { icon: 'new', title: 'create-new' },
              },
            })
          : children
      }
      return [
        { key: 'f|entire_flow', title: 'Entire Flow' },
        {
          key: 'n|pages',
          title: 'Pages',
          children: [
            ...this.pageTreeData.pages,
            !this.noAuthComments
              ? { key: `cr|p`, title: 'New Page', slots: { icon: 'new', title: 'new-page' } }
              : null,
          ].filter(e => e),
        },
        {
          key: 'n|global_components',
          title: 'Global Components',
          children: [
            ...this.pageTreeData.globalComponents,
            !this.noAuthComments
              ? { key: `cr|gc`, title: 'Create New', slots: { icon: 'new', title: 'create-new' } }
              : null,
          ].filter(e => e),
        },
        {
          key: 'n|computed_fields',
          title: 'Computed Fields',
          children: generateChildren('computedFields', 'cf'),
        },
        {
          key: 'n|data_outputs',
          title: 'Data Outputs',
          children: generateChildren('dataOutputs', 'do'),
        },
      ]
    },
    treeDataByKey() {
      return this.allTreeData.reduce((acc, d) => {
        acc[d.key.split('|')[1]] = d
        return acc
      }, {})
    },
    treeData() {
      return this.treeDataByKey[this.treeView]
    },
    hiddenPages() {
      return new Set(
        ((this.form && this.form.pages) || []).filter(this.itemIsHidden).map(c => c.id)
      )
    },
    editorActions() {
      return this._getRegistered('editorAction') || {}
    },
    currentPaneSelected() {
      if (!this.editorActions.getSelectedObject) return null
      const { viewType, selectedId } = this.editorActions.getSelectedObject()

      switch (viewType) {
        case 'global_components':
        case 'components':
          return null
        case 'pages':
          return null
        case 'computed_fields':
          return `cf|${selectedId}`
        case 'data_outputs':
          return `do|${selectedId}`
        default:
        case 'flow':
          return null
      }
    },
    actions() {
      return [
        { key: 'new-page', label: 'Page', icon: 'file' },
        {
          key: 'new-component',
          label: 'Component',
          icon: 'cube',
          disabled: !this.form.pages || !this.form.pages.length,
        },
        { key: 'new-global-component', label: 'Global Component', icon: 'globe' },
        { key: 'new-computed-field', label: 'Computed Field', icon: 'hexagon' },
        { key: 'new-data-output', label: 'Data Output', icon: 'database' },
      ]
    },
    setViewType() {
      const action = this.editorActions
      const empty = () => {}
      return action.onViewType || empty
    },
    pagePreviews: {
      get() {
        const savedFormPages = (this.savedForm && this.savedForm.pages) || []
        const savedPages = new Set(savedFormPages.map(p => p.id))
        return this.pageTreeData.pages.map((page, index) => {
          const pageId = page.key.slice('p|'.length)
          const url = savedPages.has(pageId)
            ? getThumbnailUrl(this.form.id, this.version, {
                pageId: page.key.slice(2),
                width: 500,
                viewportWidth: 800,
                maxAge: 24 * 30,
              })
            : null

          return {
            key: page.key,
            title: `Page ${index + 1}: ${page.title}`,
            url,
          }
        })
      },
      set(v) {
        if (this.noAuthComments) return
        const newV = v.map(
          pg => this.form.pages && this.form.pages.find(p => p.id === pg.key.split('|')[1])
        )
        this._updateReactive(null, 'pages', newV)
      },
    },
    navSections() {
      return [
        { key: 'pages', icon: 'copy' },
        { key: 'global_components', icon: 'cube' },
        { key: 'computed_fields', icon: 'function' },
        { key: 'data_outputs', icon: 'project-diagram' },
      ]
    },
    unresolvedThreads() {
      return getThreadsFilteredFn(
        getThreadsFromComments(this.comments, this.form.pages),
        this.version
      )({ resolved: false })
    },
  },
  async mounted() {
    this.throttledOnScrollHandler = throttle(this.onScrollHandler, 100)
    await this.$nextTick()
    if (this.treeViewIsPreview && this.$refs.scroller) {
      this.$refs.scroller.removeEventListener('scroll', this.throttledOnScrollHandler)
      this.$refs.scroller.addEventListener('scroll', this.throttledOnScrollHandler)
      this.onScrollHandler()
    }
  },
  beforeDestroy() {
    if (this.throttledOnScrollHandler) {
      this.$refs.scroller.removeEventListener('scroll', this.throttledOnScrollHandler)
    }
  },
  watch: {
    pagePreviews: {
      async handler() {
        if (this.treeView !== 'previews') return
        await this.$nextTick()
        if (this.throttledOnScrollHandler) this.onScrollHandler()
      },
    },
    treeView: {
      async handler(v) {
        if (v === 'previews') {
          await this.$nextTick()
          if (this.throttledOnScrollHandler) this.onScrollHandler()
        }
      },
    },
    treeViewIsPreview: {
      handler(v) {
        if (!this.$refs.scroller) return
        if (v) {
          this.$refs.scroller.removeEventListener('scroll', this.throttledOnScrollHandler)
          this.$refs.scroller.addEventListener('scroll', this.throttledOnScrollHandler)
        } else {
          this.$refs.scroller.removeEventListener('scroll', this.throttledOnScrollHandler)
        }
      },
      immediate: true,
    },
    selectedComponentId: {
      handler(id) {
        if (!id) {
          this.onPageChange(this.currentPageId)
          return
        }
        const globals = (this.form && this.form.components) || []
        const componentIsGlobal = Boolean(globals.find(c => c.id === id))
        if (componentIsGlobal) {
          this.onSelect([`gc|${id}`])
          return
        } else {
          this.onSelect([`c|${id}`])
        }
      },
    },
    currentPageId: {
      handler(id) {
        this.onPageChange(id)
      },
      immediate: true,
    },
    currentPaneSelected: {
      handler(id) {
        if (id) this.selectedKeys = [id]
      },
      immediate: true,
    },
    // 'form.id': {
    //   handler(id) {
    //     if (id && typeof this.version === 'number') this.bindComments()
    //   },
    //   immediate: true,
    // },
  },
  methods: {
    // bindComments() {
    //   const ref = Firebase.firestore()
    //     .collection('forms')
    //     .doc(this.form.id)
    //     .collection('comments')
    //   this.$bind('comments', ref)
    // },
    itemIsHidden(item) {
      if (item.hide) return true
      return !passesConditions(item.conditions || [], this.userData, {}, true)
    },
    setExpandedKey(val, forceSet, reset) {
      const values = Array.isArray(val) ? val : [val]
      const seen = new Set(reset ? [] : this.expandedKeys)
      if (forceSet === true) values.forEach(v => seen.add(v))
      else if (forceSet === false) values.forEach(v => seen.delete(v))
      else values.forEach(v => (seen.has(v) ? seen.delete(v) : seen.add(v)))
      this.expandedKeys = Array.from(seen)
    },
    setParentNavPage(val) {
      // this.expandedKeys.filter(c => !c.startsWith('n|'))
      this.setExpandedKey(val, true)
    },
    onExpand(keys) {
      this.expandedKeys = keys
    },
    async setTreeNav(key) {
      if (key === this.treeView) return
      this.treeView = key
      switch (key) {
        case 'components': {
          // this.onSelect([`p|${this.currentPageId}`])
          // await this.$nextTick()
          // this.onPageChange(this.currentPageId)
          // this.setViewType('components', { key: this.currentPageId })
          break
        }

        case 'global_components':
        case 'data_outputs':
        case 'computed_fields':
        case 'previews':
        case 'all':
          break

        case 'entire_flow':
        default: {
          this.onSelect(['f|entire_flow'])
          break
        }
      }
    },
    async onDrop(context) {
      /*

      type DragEvent = {
        dragNode: VueNode
        dragNodeKeys: string[]
        dropPosition: number
        dropToGap: boolean
        event: Event
        node: VueComponent - The tree node that was dropped onto (or near I guess)
      }

      Cases:

      * Drag to reorder - drop to gap
      * Drag inside of a container - drop to gap
      * Drag outside of a container - drop to gap
      * Drag onto a container - add to container
      * Drag onto component to create container and set both as children - drop to gap
      */

      if (this.noAuthComments) return

      const targetNode = context.node
      const draggedNode = context.dragNode
      const targetNodeEventKey = targetNode.eventKey
      const targetNodePrefix = targetNodeEventKey.split('|')[0]
      if (targetNodePrefix !== draggedNode.eventKey.split('|')[0]) return
      if (!['p', 'gc', 'c'].includes(targetNodePrefix)) return

      const dropKey = context.node.eventKey
      const dragKey = context.dragNode.eventKey
      const dropPos = context.node.pos.split('-')
      const dropPosition = context.dropPosition - Number(dropPos[dropPos.length - 1])
      const loop = (data, key, callback) => {
        data.forEach((item, index, arr) => {
          if (item.key === key) {
            return callback(item, index, arr)
          }
          if (item.children) {
            return loop(item.children, key, callback)
          }
        })
      }
      const treeData =
        targetNodePrefix === 'gc' ? this.treeDataByKey.global_components : this.treeDataByKey.pages
      const data = cloneDeep(treeData.children)

      // Find dragObject
      let dragObj
      loop(data, dragKey, (item, index, arr) => {
        arr.splice(index, 1)
        dragObj = item
      })
      if (!context.dropToGap) {
        // Drop on target
        loop(data, dropKey, item => {
          item.children = item.children || []
          item.children.push(dragObj)
        })
      } else if (
        (context.node.children || []).length > 0 && // Has children
        context.node.expanded && // Is expanded
        dropPosition === 1 // On the bottom gap
      ) {
        loop(data, dropKey, item => {
          item.children = item.children || []
          item.children.unshift(dragObj)
        })
      } else {
        let ar
        let i
        loop(data, dropKey, (item, index, arr) => {
          ar = arr
          i = index
        })
        if (dropPosition === -1) {
          ar.splice(i, 0, dragObj)
        } else {
          ar.splice(i + 1, 0, dragObj)
        }
      }

      const path = getPathFromView(targetNodePrefix === 'gc' ? 'global_components' : 'pages')
      const finalData = treeToFlowData(data, this.form)
      this._updateReactive(null, path, finalData)
    },
    async onSelect(selectedKeys) {
      const modifiedSelectedKeys = selectedKeys.length === 0 ? this.selectedKeys : selectedKeys
      if (!selectedKeys.includes('cr|c') && !selectedKeys.includes('cr|gc'))
        this.selectedKeys = modifiedSelectedKeys
      const [type, id] = modifiedSelectedKeys[0].split('|')
      // if (!this.expandedKeys.includes(modifiedSelectedKeys[0]))
      const keys = []
      const forceSetKeys = [modifiedSelectedKeys[0]]
      const addKeys = (comp, components, prefix) => {
        if (!comp) return
        const pre = prefix || 'c'
        if (comp.parent_key) {
          const parentComp = components.find(c => c.key === comp.parent_key)
          if (parentComp) {
            const newKey = `${pre}|${parentComp.id}`
            if (new Set(keys).has(newKey)) return
            if (!this.expandedKeys.includes(newKey)) {
              keys.push(newKey)
            }
            addKeys(parentComp, components, prefix)
          }
        }
      }
      switch (type) {
        case 'n':
          break
        case 'cr': {
          switch (id) {
            case 'c': {
              this.editorActions.modal('new-component')
              break
            }
            case 'cf': {
              this.editorActions.modal('new-computed-field')
              break
            }
            case 'gc': {
              this.editorActions.modal('new-global-component')
              break
            }
            case 'p': {
              this.editorActions.modal('new-page')
              break
            }
            case 'do': {
              this.editorActions.action('new-output')
              break
            }
          }
          break
        }
        case 'f': {
          forceSetKeys.push('f|entire_flow')
          // this.setExpandedKey([`f|entire_flow`], true, true)
          await this.$nextTick()
          this.setViewType('flow')
          break
        }
        case 'cf': {
          // forceSetKeys.push(`n|computed_fields`)
          this.setExpandedKey([`n|computed_fields`], true)
          await this.$nextTick()
          this.setViewType('computed_fields', id)
          return
        }
        case 'do': {
          // forceSetKeys.push(`n|data_outputs`)
          this.setExpandedKey([`n|data_outputs`], true)
          await this.$nextTick()
          this.setViewType('data_outputs', id)
          return
        }
        case 'p': {
          forceSetKeys.push('n|pages')
          this._setUserData({ ...this.userData, currentPageId: id })
          if (this.currentPageId === id) this.setViewType('pages', id)

          this._setSelectedComponentId({})
          break
        }
        case 'gc': {
          this._setSelectedComponentId({}, id)
          await this.$nextTick()
          forceSetKeys.push('n|global_components')
          const components = this.form.components || []
          const component = components.find(c => c.id === id)
          addKeys(component, components, 'gc')
          break
        }
        case 'c': {
          const newPage = this.form.pages.find(p => Boolean(p.components.find(c => c.id === id)))
          if (!newPage) return
          if (newPage.id !== this.currentPageId) {
            this._setUserData({ ...this.userData, currentPageId: newPage.id })
            await this.$nextTick()
          }
          this._setSelectedComponentId({}, id)
          await this.$nextTick()
          if (!this.expandedKeys.includes('n|pages')) forceSetKeys.push('n|pages')
          if (!this.expandedKeys.includes(`p|${newPage.id}`)) forceSetKeys.push(`p|${newPage.id}`)
          const components = newPage.components
          const component = components.find(c => c.id === id)
          addKeys(component, components)
          break
        }
      }
      this.setExpandedKey(Array.from(new Set(keys)))
      this.setExpandedKey(Array.from(new Set(forceSetKeys)), true)
    },
    onPageChange(id) {
      if (!id) return
      // this.treeView = 'components'
      const page = this.form.pages.find(p => p.id === id)
      const components = (page && page.components) || []
      this.setExpandedKey(
        [
          'n|pages',
          `p|${id}`,
          ...components.filter(c => c.type === 'Container').map(c => `c|${c.id}`),
        ],
        true,
        true
      )
      this.selectedKeys = [`p|${id}`]
    },
    createPage() {
      this.editorActions.modal('new-page')
    },
    createComponent() {
      this.editorActions.modal('new-component')
    },
    onScrollHandler() {
      const panes = document.querySelectorAll('.NavPagePane')
      const pagesInView = {}
      panes.forEach(p => (pagesInView[p.id] = isElementInViewport(p)))
      this.pagesInView = pagesInView
    },
    onDropdownClick(event, id) {
      switch (event.key) {
        case 'new-data-output': {
          this.editorActions.action('new-output')
          break
        }
        case 'dupe-page': {
          const pageId = id.slice('p|'.length)
          this.editorActions.modal(event.key, pageId)
          break
        }
        case 'delete-page-popover': {
          this.targetPageId = id
          this.showPopConfirm = true
          break
        }
        case 'delete-page': {
          const pageId = id.slice('p|'.length)
          this.editorActions.action(event.key, pageId)
          this.targetPageId = null
          this.showPopConfirm = false
          break
        }

        default: {
          this.editorActions.modal(event.key)
          break
        }
      }
    },
  },
}
</script>
