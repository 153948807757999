import { getStartOfDay, getStartOfWeek, getStartOfMonth } from '@/helpers/timeFilters'

export default function getFilterString([key, val]) {
  switch (key) {
    case 'flow':
      return getCheckpointString(val)
    case 'flow-pages': {
      const [flowId, page] = val.split('-')
      return `flow_pages_viewed:${flowId}.${page}`
    }
    case 'flow-values': {
      const [flowId, k, v] = val.split(' - ')
      return `flow_answers:"${flowId}.${k}:${v}"`
    }
    case 'contact_details':
      return val === 'true'
        ? `NOT email:"__null" AND NOT name:"__null"` // AND NOT customName:"__null"
        : `email:"__null" AND (name:"__null" OR customName:"__null")`

    case 'updatedAt':
    case 'createdAt': {
      const timeValues = presetTimeValues()
      return val === 'yesterday'
        ? `${key}:${timeValues[val].getTime()} TO ${timeValues.today.getTime()}`
        : `${key} > ${timeValues[val].getTime()}`
    }
    case 'page_domain':
    case 'page':
      return `pages:"${val}"`
    case 'domain':
      return `${key}s:"${val}"`
    case 'continent':
    case 'country':
      return `${key}_name:"${val}"`

    default:
      return `${key}:"${val}"`
  }
}

function getCheckpointString(val) {
  return Array.isArray(val)
    ? val
        .filter(v => v)
        .map(v => checkpointValToString(v))
        .join(' OR ')
    : checkpointValToString(val)
}

function checkpointValToString(val) {
  const [flowId, checkpoint] = val.split('-')

  switch (checkpoint) {
    case '_complete':
      return `flow_checkpoints:${flowId}.completed_flow`
    case '_begin':
      return `flow_checkpoints:${flowId}.began_flow OR flow_checkpoints:${flowId}.loaded_flow`
    case '_view':
      return `flow_checkpoints:${flowId}.viewed_flow`
    // case 'daterange':
    //   return `updatedAt:`
    default:
      return `flow_checkpoints:${flowId}.${checkpoint}`
  }
}

function presetTimeValues() {
  const now = Date.now()
  return {
    today: getStartOfDay(now),
    yesterday: getStartOfDay(now - 24 * 60 * 60 * 1000),
    week: getStartOfWeek(now),
    month: getStartOfMonth(now),
  }
}
