<template>
  <div class="Dropdown">
    <Toggle v-slot:default="{ isOn, toggle, turnOff }">
      <div class="toggle-button" v-if="$slots.default" @click="toggle(true)">
        <slot />
      </div>
      <Button
        v-else
        :useFeather="useFeather"
        class="dropdown-button"
        :class="{ isOpen: isOn }"
        :text="textCalc"
        :icon="iconCalc"
        :click="() => toggle(true)"
      >
        <Icon
          :useFeather="useFeather"
          class="up-down"
          :class="{ 'up-down-icon': dropdownIcon === 'code' }"
          :name="dropdownIcon || `chevron-${isOn ? 'up' : 'down'}`"
        />
      </Button>
      <div class="options" :class="{ scrollable }" v-if="isOn">
        <WindowEvent event-name="click" @on-trigger="turnOff" />
        <Button
          v-for="button in buttonsCalc"
          isDropdownButton
          :disabled="button.disabled"
          :class="{ disabled: button.disabled, [button.itemId]: button.itemId }"
          :key="button.id || button.key || button.text"
          :data="button"
          @close-dropdown="turnOff"
        />
      </div>
    </Toggle>
  </div>
</template>

<script>
import isEqual from 'lodash/isEqual'

// import Icon from './Icon.vue'
import Button from './Button.vue'
import Toggle from '../logic/Toggle.vue'
import WindowEvent from '../logic/WindowEvent.vue'

export default {
  components: {
    // Icon,
    Button,
    Toggle,
    WindowEvent,
  },
  props: {
    useFeather: Boolean,
    isRadio: Boolean,
    value: {},
    text: String,
    icon: String,
    dropdownIcon: String,
    buttons: Array,
    scrollable: Boolean,
  },
  computed: {
    textCalc() {
      return this.isRadio
        ? (this.selectedButton && this.selectedButton.text) || 'Select'
        : this.text
    },
    iconCalc() {
      return (this.isRadio && this.selectedButton && this.selectedButton.icon) || this.icon
    },
    buttonsCalc() {
      if (this.isRadio) {
        const self = this
        return this.buttons.map(button => ({
          ...button,
          isSelected: isEqual(button.value, this.value),
          click: () => self.$emit('input', button.value),
        }))
      } else {
        return this.buttons
      }
    },
    selectedButton() {
      return this.buttonsCalc.find(button => button.isSelected)
    },
  },
}
</script>

<style lang="scss">
@import './styles/Dropdown.scss';
</style>
