export async function getWordPressSnippet(productId, cdnUrl) {
  const url = cdnUrl || 'https://cdn.heysavvy.com/wc/savvy.min.js'

  return `<?php
  /**
    * Plugin Name: Savvy Code Snippet
  */

  function inject_savvy_snippet() {
    echo '<script src="${url}"></script>';
    echo '<savvy-smart-form id="${productId}"></savvy-smart-form>';
  }

  add_action('wp_footer', 'inject_savvy_snippet');

?>`
}
