import { cloneDeep, get } from 'lodash'
import idGen from '../../../../helpers/idGen'

export function getPathFromView(key) {
  switch (key) {
    case 'pages': {
      return 'pages'
    }
    case 'global_components': {
      return 'components'
    }
    case 'computed_fields': {
      return 'computedFields'
    }
    case 'data_outputs': {
      return 'dataOutputs'
    }
    case 'entire_flow':
    default:
      return null
  }
}

export function treeToFlowData(data, form) {
  // const baseData = get(form, getPathFromView(treeView), [])
  const addObjectToAcc = (node, acc, parentKey) => {
    const [type, id] = node.key.split('|')
    const isComponent = ['c', 'gc'].includes(type)
    const page =
      type === 'c' ? form.pages.find(p => Boolean(p.components.find(c => c.id === id))) : null
    const dataSource = getDataArray(page || form, type)
    const flowObject = dataSource.find(o => o.id === id)
    if (flowObject) {
      const childKey = getChildKey(type)
      // const newParentKey = parentKey && flowObject.parent_key ? parentKey : undefined
      // if (newParentKey) {
      //   const newContainer = { type: 'Container', key: 'container_0', id: idGen() }
      //   acc.push(newContainer)
      // }
      const newFlowObject = cloneDeep(flowObject)
      if (parentKey) {
        if (isComponent) {
          const parentObject = acc.find(o => o.key === parentKey)
          newFlowObject.parent_key =
            parentObject.type === 'Container' ? parentKey : parentObject.parent_key
        } else newFlowObject.parent_key = parentKey
      } else if (isComponent) delete newFlowObject.parent_key
      if (childKey) newFlowObject[childKey] = []
      acc.push(newFlowObject)

      if (node.children) {
        let inheritedParentKey = isComponent
          ? newFlowObject.type === 'Container'
            ? newFlowObject.key
            : newFlowObject.parent_key
          : null

        if (isComponent && newFlowObject.type !== 'Container') {
          const newContainerKey = generateContainerKey(form)
          const newContainer = { key: newContainerKey, id: idGen(), type: 'Container' }
          if (parentKey) newContainer.parent_key = parentKey
          newFlowObject.parent_key = newContainerKey
          acc.push(newContainer)
          inheritedParentKey = newContainerKey
        }
        const parentObj = acc.find(o => o.id === newFlowObject.id)
        node.children.forEach(c =>
          addObjectToAcc(c, childKey ? parentObj[childKey] : acc, inheritedParentKey)
        )
      }
    }
  }
  const orderedData = data.reduce((acc, node) => {
    addObjectToAcc(node, acc)

    return acc
  }, [])
  return orderedData
}

function getFieldKey(path) {
  switch (path) {
    case 'gc':
    case 'c': {
      return 'components'
    }

    case 'p': {
      return 'pages'
    }
    case 'cf': {
      return 'computedFields'
    }
    case 'do': {
      return 'dataOutputs'
    }

    case 'f':
    default: {
      return ''
    }
  }
}

function getChildKey(path) {
  switch (path) {
    case 'p': {
      return 'components'
    }
    case 'c':
    case 'cf':
    case 'do':
    case 'f':
    default: {
      return null
    }
  }
}

function getDataArray(form, path) {
  const fieldKey = getFieldKey(path)
  return get(form, fieldKey, [])
}

function generateContainerKey(form) {
  const getContainerKeys = comps => comps.filter(c => c.type === 'Container').map(c => c.key)
  const globals = getContainerKeys(form.components || [])
  const seenContainerKeys = new Set(globals)
  const pages = form.pages || []
  pages.forEach(p => {
    getContainerKeys(p.components || []).forEach(k => seenContainerKeys.add(k))
  })
  let keyNumber = -1
  let validKey = false
  let key = ''
  while (!validKey) {
    keyNumber += 1
    key = `container_${keyNumber}`
    if (!seenContainerKeys.has(key)) validKey = true
  }
  return key
}
