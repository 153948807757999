<template>
  <div
    class="SingleComment comment relative p-3 border border-gray-300 rounded-md flex flex-col transform group transition"
    :id="`comment-${commentId}`"
  >
    <a-dropdown v-if="showEnhancedUI" :trigger="['click']">
      <DotsMenu />
      <a-menu slot="overlay" @click="$emit('menu-click', [$event.key, comment])">
        <a-menu-item key="reply">
          <Icon class="mr-1" name="reply" />
          Reply to this Thread
        </a-menu-item>
        <a-menu-item v-if="isAuthor" key="edit">
          <Icon class="mr-1" name="edit" />
          {{ editing ? 'Stop Editing' : 'Edit' }} Comment
        </a-menu-item>
        <a-menu-item v-if="canChooseType" key="change-type">
          <Icon class="mr-1" name="edit" />
          {{ comment.type ? 'Change' : 'Set' }} Type
        </a-menu-item>
        <a-menu-item key="post_to_channel">
          <Icon class="mr-1" name="paper-plane" />
          Post Comment to Channel
        </a-menu-item>
        <a-menu-item v-if="comment.resolved" key="unresolve">
          <Icon class="mr-1" name="history" />
          Un-Resolve Comment
        </a-menu-item>
        <a-menu-item v-else-if="comment.assignee || comment.threadIndex === 0" key="resolve">
          <Icon class="mr-1" name="check" />
          Resolve Comment
        </a-menu-item>
        <a-menu-item v-if="!comment.archived" key="archive">
          <Icon class="mr-1" name="archive" />
          Archive This Thread
        </a-menu-item>
      </a-menu>
    </a-dropdown>
    <!-- { id, pageIndex, text, resolved, author, timestamp, assignee } -->
    <Pill
      borderless
      closeable
      v-if="isLastInThread && replyingToThisThread"
      class="text-xs font-bold mx-1 mt-1 mb-2"
      @close="$emit('menu-click', ['reply', null])"
    >
      <Icon class="mr-0.5" name="reply" />
      Replying to this thread...
    </Pill>
    <div class="flex">
      <Pill
        borderless
        closeableHover
        v-if="type && type.key !== 'message'"
        class="capitalize font-bold mx-1 my-0"
        :style="type.color ? `border-color: ${type.color}; color: ${type.color};` : ''"
        :icon="type.icon"
        @close="$emit('set-type', [comment, null])"
      >
        {{ type.key }}
      </Pill>
    </div>
    <div>
      <div v-if="commentsFilter !== 'this_page'" class="mx-1 mt-1 mb-2 text-xs inline-block">
        <a-tooltip
          :title="
            commentPage
              ? `Click to go to Page ${comment.pageIndex + 1} (${commentPage.key})`
              : `This page no longer exists`
          "
        >
          <div class="inline-block">
            <Icon name="file" class="mr-1" />
            Page {{ comment.pageIndex + 1 }}
          </div>
        </a-tooltip>
      </div>
      <div class="m-1 pr-1">{{ comment.text }}</div>
    </div>
    <div class="mx-1 -mt-1 mb-2">
      <div class="inline-block mr-2 text-xs text-gray-400">
        {{ displayCommentAuthor(comment.author) }}
      </div>
      <div class="inline-block text-xs text-gray-300 italic">
        {{ comment.timestamp | fromNow }}
      </div>
    </div>
    <div
      v-if="canChooseType && !comment.type && (comment.threadIndex === 0 || settingType)"
      class="flex flex-wrap items-center my-2"
    >
      <div class="mx-1 text-xs font-bold">Type:</div>
      <Pill
        clickable
        hover-filled
        v-for="type in types"
        :key="type.key"
        class="mx-0.5 capitalize"
        :color="type.color"
        :icon="type.icon"
        @click.stop="$emit('set-type', [comment, type.key])"
      >
        {{ type.key }}
      </Pill>
    </div>
    <div v-if="comment.assignee && showEnhancedUI" class="flex items-center">
      <div class="mx-1 text-xs mr-0.5">Assigned to</div>
      <Pill
        closeableHover
        :class="{
          'text-purple': comment.assignee.uid === $store.getters.userId,
          'border-purple': comment.assignee.uid === $store.getters.userId,
        }"
        @close="$emit('assign', [comment, null])"
      >
        <Icon name="user" class="mr-1" />
        {{ comment.assignee.name }}</Pill
      >
    </div>
    <div v-if="showEnhancedUI" class="flex">
      <!-- <a-tooltip v-if="comment.resolved" title="Archive this comment">
        <button
          class="m-1 mt-3 px-3 py-1 border border-gray-200 rounded-md my-1 text-xs whitespace-nowrap hover:border-red-500 hover:text-red-500 cursor-pointer"
          @click.stop="$emit('archive', commentId)"
        >
          <Icon name="archive" class="mr-1" />
          Archive
        </button>
      </a-tooltip> -->
      <a-tooltip
        v-if="
          !comment.resolved && comment.assignee && comment.assignee.uid === $store.getters.userId
        "
        title="Mark this comment as resolved"
      >
        <div
          class="m-1 mt-3 px-3 py-1 border rounded-md my-1 text-xs whitespace-nowrap border-green-600 text-green-600 hover:bg-green-600 hover:text-white cursor-pointer"
          @click.stop="$emit('resolve', [$event, comment])"
        >
          <Icon class="mr-1" name="check" /> Resolve
        </div>
      </a-tooltip>
      <div
        v-if="editing"
        class="m-1 mt-3 px-3 py-1 border rounded-md my-1 text-xs whitespace-nowrap border-red-600 text-red-600 hover:bg-red-600 hover:text-white cursor-pointer"
        @click.stop="$emit('toggle-edit', comment)"
      >
        <Icon class="mr-1" name="times" /> Cancel Editing
      </div>
    </div>
    <div
      v-if="
        comment.type &&
          typeAllowsAssignee(comment.type) &&
          !comment.assignee &&
          !editing &&
          ((!noAuthComments && $store.getters.isAdmin) || commenterIsAdminEmail)
      "
      class="flex flex-wrap items-center"
    >
      <template>
        <div class="mx-1 text-xs font-bold">Assign to:</div>
        <Pill
          clickable
          class="mx-0.5"
          v-for="user in users"
          :key="user.uid"
          @click.stop="$emit('assign', [comment, user])"
        >
          {{ user.name }}
        </Pill>
      </template>
    </div>
    <div
      v-if="isLastInThread && !replyingToThisThread"
      class="self-start m-1 mt-3 px-2 py-0.5 border rounded-md my-1 text-xs whitespace-nowrap hover:border-purple hover:text-purple cursor-pointer"
      @click.stop="$emit('menu-click', ['reply', comment])"
    >
      <Icon class="mr-1" name="reply" /> Reply
    </div>
  </div>
</template>

<script>
import getSlackUsers from './helpers/slackUsers'
export default {
  name: 'SingleComment',
  props: {
    comment: Object,
    commentsFilter: String,
    noAuthComments: Boolean,
    commenterName: String,
    commenterEmail: String,
    commentPage: Object,
    threadId: String,
    users: Array,
    editing: Boolean,
    threadHasCommentWithType: Boolean,
    thread: Object,
    isLastInThread: Boolean,
    replyingToThisThread: Boolean,
    settingType: Boolean,
  },
  data() {
    return {}
  },
  computed: {
    commentId() {
      return this.comment && this.comment.id
    },
    commenterIsAdminEmail() {
      const user = getSlackUsers().find(u => u.email === this.commenterEmail)
      return Boolean(user)
    },
    showEnhancedUI() {
      return this.commenterIsAdminEmail || !this.noAuthComments
    },
    isAuthor() {
      return this.comment.author && this.comment.author.email === this.commenterEmail
    },
    type() {
      return this.types.find(t => t.key === this.comment.type)
    },
    types() {
      return [
        { key: 'task', color: '#1b82e4', icon: 'tasks' },
        { key: 'question', color: '#bc11b2', icon: 'question-circle' },
        { key: 'suggestion', color: '#029696', icon: 'lightbulb' },
        { key: 'warning', color: '#d87a00', icon: 'exclamation-circle' },
        { key: 'issue', color: '#e41b72', icon: 'exclamation-triangle' },
        { key: 'note', color: '#9400ff', icon: 'sticky-note' },
        { key: 'message', icon: 'comment' },
      ]
    },
    canChooseType() {
      return !this.noAuthComments || this.isAuthor || this.commenterIsAdminEmail
    },
  },
  methods: {
    displayCommentAuthor(author) {
      if (!author) return 'Anonymous'
      if (this.users.map(a => a.email).includes(author.email)) return author.name.split(' ')[0]
      let name = author.name || 'Anonymous'
      if (author.email) name += ` (${author.email})`
      return name
    },
    typeAllowsAssignee(type) {
      return !['message', 'note'].includes(type)
    },
  },
}
</script>
