<template>
  <input class="FieldInput" type="text" v-model="field" @keyup.enter="setOrgName" />
</template>
<script>
export default {
  props: {
    placeholder: String,
    value: {},
  },
  data() {
    return { field: null }
  },
  computed: {},
  mounted() {
    this.field = this.value
  },
  watch: {
    field: {
      handler(v) {
        this.$emit('input', v)
      },
    },
  },
  methods: {},
}
</script>
<style lang="scss" scoped>
input {
  margin: 10px;
  font-size: 16px;
  padding-left: 15px;
  padding-right: 15px;
  width: 75%;
}
</style>
