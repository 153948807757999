<template>
  <div class="CommentsDisplay flex flex-col items-stretch flex-1 pt-2 overflow-hidden">
    <div class="flex flex-row justify-between px-4">
      <div
        class="cursor-pointer mr-1 px-1 pt-1 border-purple"
        :class="{
          'border-b-2': commentsFilter === 'this_page',
        }"
        @click="commentsFilter = 'this_page'"
      >
        This Page ({{ threadsFiltered({ page: currentPageId, resolved: false }).length }})
      </div>
      <div
        class="cursor-pointer mx-1 px-1 py-1 border-purple"
        :class="{
          'border-b-2': commentsFilter === 'all_pages',
        }"
        @click="commentsFilter = 'all_pages'"
      >
        All Pages ({{ threadsFiltered({ resolved: false }).length }})
      </div>
      <div
        class="cursor-pointer ml-1 px-1 py-1 border-purple"
        :class="{
          'border-b-2': commentsFilter === 'resolved',
        }"
        @click="commentsFilter = 'resolved'"
      >
        Resolved
      </div>
    </div>
    <div
      v-if="currentTabThreads.length === 0"
      class="flex-1 flex flex-col px-4 justify-center text-center text-gray-400"
      style="flex-basis: 20rem;"
    >
      <a-empty v-if="noAuthComments" description="No comments here yet" />
      <a-empty v-else description="No unresolved comments - great job! 🚀" />
    </div>
    <div v-else class="scroller flex-1 px-4 pt-2">
      <CommentThread
        v-for="thread in currentTabThreads"
        :key="thread.id"
        :replyingToThisThread="replyingToThreadId === thread.id"
        :inReplyMode="Boolean(replyingToThreadId)"
        v-bind="{
          thread,
          commentsFilter,
          noAuthComments,
          commenterName,
          commenterEmail,
          threadId,
          users,
          editingComment,
          pages,
        }"
        @open-page="$emit('open-page', $event)"
        @menu-click="$emit('menu-click', $event)"
        @assign="$emit('assign', $event)"
        @archive="$emit('archive', $event)"
        @resolve="$emit('resolve', $event)"
        @toggle-edit="$emit('toggle-edit', comment)"
        @set-type="$emit('set-type', $event)"
      />
    </div>

    <div class="flex flex-col mt-4 px-4 items-stretch">
      <template v-if="commenterName && commenterEmail">
        <textarea
          v-if="useLightweightUIElements"
          ref="addCommentInput"
          class="my-2 add-comment-text"
          v-model="text"
          :placeholder="`Type your comment here...`"
          :disabled="addingComment"
          rows="6"
          @pressEnter.prevent="addComment"
        />
        <a-textarea
          v-else
          ref="addCommentInput"
          class="my-2"
          v-model="text"
          :placeholder="
            replyingToThread
              ? `Reply to '${replyingToThread.comments.slice(-1)[0].text.slice(0, 40)}'...`
              : `Type your comment here...`
          "
          :disabled="addingComment"
          :auto-size="{ minRows: 2, maxRows: 6 }"
          @pressEnter.prevent="addComment"
        />
        <component
          :is="useLightweightUIElements ? 'button' : 'a-button'"
          class="my-2 add-comment-button"
          :type="commentText && commentText.trim() ? 'primary' : null"
          :disabled="!commentText"
          @click="addComment"
        >
          {{ editingComment ? 'Update Comment' : `Add Comment as ${commenterName}` }}
        </component>
        <!-- <template v-if="!noAuthComments && allowedToAssign">
          <div class="text-center">
            Assign to:
            <a-dropdown :trigger="['hover']">
              <strong class="cursor-pointer">{{ defaultAssigneeName || 'Select User' }}</strong>
              <a-menu slot="overlay" @click="changeDefaultAssignee">
                <a-menu-item :key="'__none'">No one</a-menu-item>
                <a-menu-item v-for="{ uid, name } in users" :key="uid">
                  {{ name }}
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </div>
        </template> -->
      </template>
      <template v-else>
        <div class="my-2">Add a name/email to leave feedback on this Flow:</div>
        <component
          :is="useLightweightUIElements ? 'input' : 'a-input'"
          v-model="newCommenterName"
          class="enter-name"
          style="margin: 1em 0 0.5em;"
          placeholder="Your Name"
        />
        <component
          :is="useLightweightUIElements ? 'input' : 'a-input'"
          class="enter-email"
          v-model="newCommenterEmail"
          placeholder="Your Email"
          @keyup.enter.exact="startCommenting"
        />
        <component
          :is="useLightweightUIElements ? 'button' : 'a-button'"
          class="my-2 start-commenting-button"
          type="primary"
          @click="startCommenting"
        >
          Start Commenting
        </component>
      </template>
    </div>
  </div>
</template>

<script>
import getSlackUsers from './helpers/slackUsers'
import { getThreadsFromComments, getThreadsFilteredFn } from '../helpers/comments.js'
import CommentThread from './CommentThread'

export default {
  name: 'CommentsDisplay',
  components: {
    CommentThread,
  },
  props: {
    currentPageId: String,
    pages: Array,
    noAuthComments: Boolean,
    insideEditor: Boolean,
    comments: Array,
    addingComment: Boolean,
    editingComment: [String, Boolean],
    version: [Number],
    commentText: String,
    commenterName: String,
    commenterEmail: String,
    defaultAssignee: String,
    replyingToThreadId: String,
  },
  data() {
    return {
      commentsFilter: 'this_page',
      newCommenterName: '',
      newCommenterEmail: '',
      threadId: null,
    }
  },
  computed: {
    useLightweightUIElements() {
      return this.noAuthComments && !this.insideEditor
    },
    text: {
      get() {
        return this.commentText
      },
      set(v) {
        this.$emit('text', v)
      },
    },
    users() {
      /* Slack Channel: C02GS2AQM09 */
      return getSlackUsers()
    },
    allowedToAssign() {
      const userId = this.$store && this.$store.getters && this.$store.getters.userId
      return userId ? Boolean(this.users.find(a => a.uid === userId)) : false
    },
    defaultAssigneeName() {
      const user = this.users.find(u => u.uid === this.defaultAssignee)
      return user ? user.name : 'Select User'
    },
    threads() {
      return getThreadsFromComments(this.comments, this.pages)
    },
    threadsFiltered() {
      return getThreadsFilteredFn(this.threads, this.version)
    },
    currentTabThreads() {
      return this.threadsFiltered({
        resolved: this.commentsFilter === 'resolved',
        page: this.commentsFilter === 'this_page' ? this.currentPageId : null,
        byPage: this.commentsFilter === 'all_pages',
      })
    },
    replyingToThread() {
      return this.threads.find(t => t.id === this.replyingToThreadId)
    },
  },
  watch: {
    addingComment: {
      handler(v, o) {
        if (!v && o && !this.editingComment) {
          this.$nextTick(() => {
            this.$refs.addCommentInput.focus()
          })
        }
      },
    },
    commentsFilter() {
      this.$emit('menu-click', ['reply', null])
    },
    currentPageId() {
      this.$emit('menu-click', ['reply', null])
    },
    replyingToThread(replyingToThread) {
      if (!replyingToThread) this.replyingToThreadId = null
    },
  },
  methods: {
    startCommenting() {
      this.$emit('set-commenter-details', {
        name: this.newCommenterName,
        email: this.newCommenterEmail,
      })
    },
    addComment() {
      this.$emit('add')
    },
    changeDefaultAssignee(event) {
      // if (event.key === '__none') {
      //   this.$emit('default-assignee', '')
      // } else
      this.$emit('default-assignee', event.key)
    },
  },
}
</script>

<style lang="scss">
// Needs to be moved into scss sheet somewhere, largely just copied ant-input styles
.CommentsDisplay {
  .enter-name,
  .enter-email,
  .add-comment-text {
    box-sizing: border-box;
    margin: 0;
    list-style: none;
    position: relative;
    display: inline-block;
    width: 100%;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    background-color: #fff;
    background-image: none;
    border-radius: 4 px;
    border: 1 px solid #ddd;
    padding: 4 px 11 px;
    max-width: 100%;
    height: auto;
    min-height: 32 px;
    line-height: 1.5;
    vertical-align: bottom;
    transition: all 0.3s, height 0s;
  }
  .add-comment-button,
  .start-commenting-button {
    line-height: 1.499;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    background-image: none;
    border: 1px solid transparent;
    /* -webkit-box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
    box-shadow: 0 2px 0 rgb(0 0 0 / 2%); */
    cursor: pointer;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    height: 32px;
    padding: 0 15px;
    font-size: 14px;
    border-radius: 4px;
    /* color: rgba(0, 0, 0, 0.65);
    background-color: #fff;
    border-color: #d9d9d9; */

    color: white;
    border-color: #6454f3;
    background-color: #6454f3;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
    &:disabled {
      color: rgba(0, 0, 0, 0.25);
      background-color: #f5f5f5;
      border-color: #d9d9d9;
      text-shadow: none;
      box-shadow: none;
      cursor: not-allowed;
    }
  }
}
</style>
