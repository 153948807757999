export default function flowsInOrder(availFlows, user) {
  const flows = availFlows || []
  const recentFlows = (user && user.recentFlows) || []
  const seenFlows = flows.reduce((acc, f) => ({ ...acc, [f.id]: f }), {})
  const seenRecentFlows = new Set(recentFlows)

  const recents = recentFlows.reduce((acc, f) => {
    if (seenFlows[f]) acc.push(seenFlows[f])
    return acc
  }, [])
  const nonRecentFlows = flows.filter(f => !seenRecentFlows.has(f.id))

  return [...recents, ...nonRecentFlows].filter(f => !f._archived)
}
