<template lang="html">
  <div class="ToggleSwitch" :class="{ isOn }" @click="toggle">
    <div class="label" v-if="dualSide">{{ offLabel }}</div>
    <div class="switch">
      <div class="knob" />
    </div>
    <div class="label" v-if="dualSide && label">{{ onLabel }}</div>
    <div class="label" v-else-if="label">{{ isOn ? onLabel : offLabel }}</div>
  </div>
</template>

<script>
export default {
  props: {
    label: {},
    initialOn: Boolean,
    static: Boolean,
    dualSide: Boolean,
  },
  data() {
    return { on: false }
  },
  computed: {
    isOn: {
      get() {
        return this.on // this.initalOn
      },
      set(v) {
        this.on = v
        this.$emit('input', v)
      },
    },
    onLabel() {
      if (typeof this.label === 'string') {
        return this.label
      } else {
        return this.label[1]
      }
    },
    offLabel() {
      if (typeof this.label === 'string') {
        return this.label
      } else {
        return this.label[0]
      }
    },
  },
  watch: {
    initialOn: {
      handler(v) {
        this.isOn = v
      },
      immediate: true,
    },
  },
  methods: {
    toggle() {
      if (this.static) return
      this.isOn = !this.isOn
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/components/utilities/styles/ToggleSwitch.scss';
</style>
