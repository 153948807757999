export const SERVICES = {
  text: { name: 'Text', alwaysConnected: true },
  markdown: { name: 'Markdown', alwaysConnected: true },
  timeline: { name: 'Timeline', alwaysConnected: true, noFilters: true },
  flow: { name: 'Flow', alwaysConnected: true, noFilters: true },
  comparison: { name: 'Comparison', alwaysConnected: true },
  savvy: { name: 'Savvy', alwaysConnected: true, useInternalAPI: true },
  combo: { name: 'Combination', alwaysConnected: true, useInternalAPI: true },
  stripe: { name: 'Stripe' },
  hubspot: { name: 'Hubspot' },
  salesforce: { name: 'Salesforce', useInternalAPI: true, accountRequired: true },
  webflow: { name: 'Webflow', accountRequired: true },
  'facebook-ads': { name: 'Facebook Ads', accountRequired: true, useInternalAPI: true },
  // 'google-ads': { name: 'Google Ads', accountRequired: true },
  'google-analytics': { name: 'Google Analytics', accountRequired: true, useInternalAPI: true },
  'google-search-console': {
    name: 'Google Search Console',
    accountRequired: true,
    useInternalAPI: true,
  },
}

export const UNIQUE_PRESET_COUNT = new Set(['visitors', 'users', 'funnel'])
