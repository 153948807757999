<template>
  <div class="flex flex-col" @mouseenter="onMouseEnter" @mouseleave="onMouseLeave">
    <EditorLabel v-if="!noLabel" :label="title" />
    <div class="flex gap-2">
      <slot name="prefix" />
      <!-- <a-tooltip placement="right" :title="tooltip || title"> -->
      <a-input-number
        v-if="type === 'number'"
        style="width: 100%;"
        :placeholder="placeholder || `My ${title}`"
        :formatter="value => (suffix ? `${value}${suffix}` : value)"
        :parser="value => (suffix ? value.replace(suffix, '') : value)"
        v-bind="{ size }"
        v-model="val"
      />

      <!-- <a-input-number
    v-else-if="type == 'dimension'"
    :placeholder="title"
    :formatter="value => (dimensionSuffix ? value.replace(dimensionSuffix, '') : value)"
    :parser="value => (dimensionSuffix ? `${value}${dimensionSuffix}` : value)"
    v-model="val"
  >
    <a-select slot="addonAfter" default-value="px" style="width: 65px" v-model="dimensionSuffix">
      <a-select-option value="px">
        px
      </a-select-option>
      <a-select-option value="%">
        %
      </a-select-option>
      <a-select-option value="em">
        em
      </a-select-option>
      <a-select-option value="rem">
        rem
      </a-select-option>
    </a-select>
  </a-input-number> -->
      <div
        v-else-if="type === 'color'"
        style="display: flex; justify-content: space-between; align-items: center;"
      >
        <span style="white-space: nowrap">{{ title }}:</span>
        <!-- <input type="color" v-model="val" /> -->
        <a-input style="margin: 0 10px;" type="color" v-bind="{ size }" v-model="val" />
        <a-button style="flex: 0 0 30px;" icon="stop" @click="val = undefined" />
      </div>
      <RichTextEditor
        v-else-if="type === 'rich'"
        :placeholder="title"
        :editorText="val"
        :textUpdate="textUpdate"
        @update="val = $event[1]"
        @clear="textUpdate = null"
      />
      <a-textarea
        v-else-if="type === 'textarea'"
        autoSize
        :class="{ monospaced: isKey || monospaced }"
        :placeholder="title"
        v-model="val"
      />
      <a-input
        v-else
        :class="{ monospaced: isKey || monospaced }"
        :placeholder="title"
        v-bind="{ size, disabled }"
        v-model="val"
      >
        <a-tooltip
          v-if="allowComputed"
          slot="suffix"
          placement="right"
          title="Insert template values"
        >
          <a-dropdown :trigger="['click']">
            <a-menu slot="overlay" @click="onComputedSelect">
              <a-menu-item
                v-for="key in keys.filter(key => key !== (component.key || component.id))"
                :key="key"
              >
                <a-icon type="link" /> {{ key }}
              </a-menu-item>
            </a-menu>
            <a-button
              type="dashed"
              shape="circle"
              size="small"
              style="font-family: monospace; font-size: 11px; font-weight: bold; color: #777; margin-right: -5px;"
              >{}</a-button
            >
          </a-dropdown>
        </a-tooltip>
      </a-input>
      <!-- </a-tooltip> -->
      <slot />
    </div>
  </div>
</template>

<script>
import EditorMixin from './EditorMixin'
import RichTextEditor from '@/components/form/editor/RichTextEditor'

export default {
  name: 'EditorInput',
  components: { RichTextEditor },
  mixins: [EditorMixin],
  inject: ['_getOptions', '_getData', '_getBasePath', '_updateData'],
  props: {
    type: String,
    component: Object,
    allowComputed: Boolean,
    isKey: Boolean,
    disabled: Boolean,
    monospaced: Boolean,
    size: String,
  },
  data() {
    return {
      dimensionSuffix: null,
      debounce: true,
      textUpdate: null,
    }
  },
  computed: {
    keys() {
      return Array.from(new Set(this._getOptions().map(o => o.key)))
    },
  },
  methods: {
    onComputedSelect({ key }) {
      this.val = this.val ? `${this.val.trimEnd()} {{${key}}}` : `{{${key}}}`
      this.textUpdate = `{{${key}}}`
    },
  },
}
</script>
