<template>
  <div class="StripeCheckoutEditor">
    <a-space direction="vertical">
      <template v-if="location === 'options' || !location">
        <EditorInput _key="pay_button_text" allowComputed v-bind="{ component }" />
        <!-- <EditorCheckbox _key="use_paddle" /> -->
        <EditorSelect _key="action" :options="actionOptions" v-bind="{ component }" />
        <EditorCheckbox _key="collect_details" label="Legacy - Only collect details" />
        <EditorInput
          v-if="component.use_paddle"
          monospaced
          _key="paddle_vendor_id"
          v-bind="{ component }"
        />
        <EditorInput
          v-if="component.use_paddle"
          monospaced
          _key="paddle_product_id"
          v-bind="{ component }"
        />
        <EditorInput monospaced _key="publishable_key" v-bind="{ component }" />
        <EditorInput _key="color" type="color" v-bind="{ component }" />
        <EditorCheckbox _key="collect_details" label="Only collect details" />
        <EditorCheckbox _key="use_vanilla_stripe" label="Use Popup Stripe" />
        <EditorCheckbox _key="use_direct_bridge" label="Use Direct Bridge" />
        <EditorCheckbox _key="next_on_complete" label="Automatically go to next page on success" />
        <EditorCheckbox
          _key="enforce_validation"
          label="Enforce Validation before submitting Stripe"
        />
        <template v-if="component.use_direct_bridge">
          <EditorCheckbox _key="individual_fields" label="Individual Fields for Card Details" />
          <EditorInput
            v-if="component.individual_fields"
            allowComputed
            _key="cc_field_logos_image"
            placeholder="Credit Card Logos Image URL"
            v-bind="{ component }"
          />
        </template>
        <template v-if="component.action === 'subscription'">
          <EditorInput
            allowComputed
            _key="promo_code"
            placeholder="Promo Code"
            v-bind="{ component }"
          />
          <EditorInput
            allowComputed
            _key="trial_period_days"
            placeholder="Trial Period (Days)"
            v-bind="{ component }"
          />
          <EditorSelect
            _key="price_id"
            allowComputed
            tags
            tooltip="The Stripe Id (starts with 'price_') of the price you are using"
            v-bind="{ component }"
          />
          <EditorSelect
            _key="one_time_price_id"
            allowComputed
            tags
            tooltip="The Stripe Id (starts with 'price_') of the price you are using as a first time charge / discount"
            v-bind="{ component }"
          />
        </template>
        <template v-if="!collectDetailsOnly">
          <EditorSelect _key="currency" defaultVal="usd" :options="currencyOptions" />
          <EditorInput
            allowComputed
            _key="amount"
            :placeholder="`Amount (${component.currency || 'USD'})`"
            type="number"
            v-bind="{ component }"
          />
          <template v-if="isSinglePaymentOnly">
            <div>
              <a-tooltip>
                <template slot="title">
                  We match the amount your Flow is charging against these values - if it isn't
                  listed here, we reject it so your customer doesn't pay an amount you didn't want
                  them to pay.
                </template>
                <EditorLabel label="Allowed Amounts (raw number, no currency symbol)" />
              </a-tooltip>
            </div>
            <a-select
              showSearch
              placeholder="Enter amount"
              :default-value="allowedAmounts"
              :value="allowedAmounts"
              mode="tags"
              allowClear
              style="width: 100%;"
              @change="onChange"
            >
              <a-select-option v-for="value in allowedAmounts" :key="value" :value="value">
                {{ value }}
              </a-select-option>
            </a-select>
          </template>
          <EditorCheckbox
            _key="allow_alternate_payments"
            label="Enable Alternate Payment Methods (Apple Pay, Google Pay, etc)"
          />
          <EditorCheckbox
            v-if="component.allow_alternate_payments"
            _key="hide_cc_fields"
            label="Hide CC Fields"
          />
          <!-- <EditorCheckbox
            v-if="component.allow_alternate_payments"
            _key="hide_safeguard_button"
            label="Load Payment Intent on Component Load (may result in many unused PaymentIntents)"
          /> -->
          <EditorSelect
            v-if="component.allow_alternate_payments"
            _key="country"
            :options="countries"
          />
        </template>
        <template v-else>
          <EditorCheckbox _key="output_payment_method" label="Output Payment Method to User Data" />
          <EditorInput
            v-if="component.output_payment_method"
            _key="payment_method_key"
            placeholder="Key to output payment method to"
          />
        </template>
        <template v-if="isSinglePaymentOnly">
          <EditorCheckbox
            _key="place_hold_only"
            label="Only place a hold on the card, don't take payment immediately"
          />
          <EditorCheckbox _key="create_customer" label="Add this payment to Stripe as a Customer" />
        </template>
        <template v-if="!isSinglePaymentOnly || (isSinglePaymentOnly && component.create_customer)">
          <!-- Disabled for single payment only - see api function for details -->
          <EditorCheckbox _key="output_customer_id" label="Output Customer Id to User Data" />
          <EditorInput
            v-if="component.output_customer_id"
            _key="customer_id_key"
            placeholder="Key to output customer id to"
          />
        </template>
        <EditorInput _key="billing_info_name" allowComputed v-bind="{ component }" />
        <EditorInput _key="billing_info_email" allowComputed v-bind="{ component }" />
        <!-- <EditorInput
      _key="billing_info_address_key"
      allowComputed
      v-bind="{ component }"

    /> -->
        <EditorInput _key="billing_info_phone" allowComputed v-bind="{ component }" />
      </template>
      <template v-if="location === 'triggers' || !location">
        <EditorLabel label="Checkpoints to trigger on success" />
        <CheckpointEditor _key="on_payment_complete_checkpoints" :parent="component" :form="form" />
        <ConversionTrackingEditor
          _key="on_payment_complete_conversions"
          label="Conversions on payment complete"
          :parent="component"
        />
        <DataOutputFireEditor
          _key="on_payment_complete_outputs"
          :form="form"
          label="Outputs to trigger on payment complete"
        />
        <DataOutputFireEditor
          _key="on_payment_failed_outputs"
          :form="form"
          label="Outputs to trigger on payment failed"
        />
      </template>
    </a-space>
  </div>
</template>
<script>
import CheckpointEditor from './CheckpointEditor.vue'
import ConversionTrackingEditor from './ConversionTrackingEditor.vue'
import DataOutputFireEditor from './DataOutputFireEditor.vue'
export default {
  components: { CheckpointEditor, ConversionTrackingEditor, DataOutputFireEditor },
  inject: {
    _updateReactive: { default: () => () => {} },
    _getBasePath: { default: () => () => {} },
  },
  props: {
    componentId: String,
    component: Object,
    componentData: {},
    userData: {},
    location: String,
    form: Object,
  },
  computed: {
    countries() {
      return ['US']
    },
    collectDetailsOnly() {
      return (
        this.component.action === 'collect_details' ||
        (this.component && this.component.collect_details)
      )
    },
    isSinglePaymentOnly() {
      const isOneTime = this.component.action === 'one-time-payment'
      const isNotCollect = !this.collectDetailsOnly
      const isNotSubscription = this.component.action !== 'subscription'
      return isOneTime || (isNotCollect && isNotSubscription)
    },
    allowedAmounts() {
      const amounts = (this.component && this.component.allowed_amounts) || []
      return amounts
    },
    actionOptions() {
      return ['collect_details', 'one-time-payment', 'subscription']
    },
    currencyOptions() {
      return ['usd', 'gbp', 'cad', 'eur'].map(c => ({ key: c, label: c.toUpperCase() }))
    },
  },
  methods: {
    onChange(event) {
      const values = event.filter(e => !isNaN(+e))
      this._updateReactive(this._getBasePath(), 'allowed_amounts', values)
    },
  },
}
</script>
