<template>
  <div class="BookMeetingEditor">
    <a-space direction="vertical">
      <template v-if="location === 'basic_options'">
        <EditorSelect _key="service" :options="options" defaultValue="calendly" />
        <EditorCheckbox _key="next_on_complete" label="Automatically go to next page on success" />
        <EditorInput allowComputed _key="embedUrl" :component="component" />
        <template v-if="service === 'calendly'">
          <div>
            <EditorLabel
              label="Prefill Keys"
              tooltip="'email', 'name', 'first_name' and 'last_name' will be prefilled automatically (if those fields exist). List all other field to prefill here, in the order in which they appear in the Calendly embed."
            />
            <EditorSelect class="mt-0" noLabel multiple _key="prefill_keys" :options="keys" />
          </div>
        </template>
        <template v-if="service === 'hubspot'">
          Prefill Values
          <EditorInput
            allowComputed
            label="First Name"
            _key="prefill.firstname"
            :component="component"
          />
          <EditorInput
            allowComputed
            label="Last Name"
            _key="prefill.lastname"
            :component="component"
          />
          <EditorInput allowComputed label="Email" _key="prefill.email" :component="component" />
          <div class="flex flex-row items-end" v-for="{ key, label } in prefillKeys" :key="key">
            <EditorInput
              allowComputed
              :label="label"
              :_key="`prefill.${key}`"
              :component="component"
              style="width: 100%"
            />
            <a-button icon="delete" @click="removePrefill(key)" />
          </div>
          <div class="flex flex-row">
            <a-input
              class="monospaced"
              v-model="prefillKey"
              placeholder="Add new prefill"
              style="width:100%;"
              @keydown.enter="addNewPrefill"
            />
            <a-button icon="save" @click="addNewPrefill" />
          </div>
        </template>
      </template>
      <template v-if="location === 'triggers'">
        <ConversionTrackingEditor
          _key="conversions_tier_1"
          label="Conversions on widget load"
          :parent="component"
        />
        <ConversionTrackingEditor
          _key="conversions_tier_2"
          label="Conversions on timeslot chosen"
          :parent="component"
        />
        <ConversionTrackingEditor
          _key="conversions_tier_3"
          label="Conversions on meeting booked"
          :parent="component"
        />
        Book Meeting Checkpoints
        <EditorLabel label="Checkpoints to trigger on widget load" />
        <CheckpointEditor _key="checkpoints_tier_1" :parent="component" :form="form" />
        <EditorLabel label="Checkpoints to trigger on timeslot chosen" />
        <CheckpointEditor _key="checkpoints_tier_2" :parent="component" :form="form" />
        <EditorLabel label="Checkpoints to trigger on meeting booked" />
        <CheckpointEditor :parent="component" :form="form" />

        <DataOutputFireEditor
          _key="outputs_tier_1"
          :form="form"
          label="Outputs to trigger on widget load"
        />
        <DataOutputFireEditor
          v-if="service === 'calendly'"
          _key="outputs_tier_2"
          :form="form"
          label="Outputs to trigger on timeslot chosen"
        />
        <DataOutputFireEditor
          _key="outputs_tier_3"
          :form="form"
          label="Outputs to trigger on meeting booked"
        />
      </template>
    </a-space>
  </div>
</template>

<script>
import { startCase } from 'lodash'
import CheckpointEditor from './CheckpointEditor.vue'
import DataOutputFireEditor from './DataOutputFireEditor.vue'
import ConversionTrackingEditor from './ConversionTrackingEditor.vue'
export default {
  name: 'BookMeetingEditor',
  components: { CheckpointEditor, DataOutputFireEditor, ConversionTrackingEditor },
  inject: {
    _getOptions: { default: () => () => {} },
    _updateData: { default: () => () => {} },
    _getBasePath: { default: () => () => {} },
  },
  props: {
    componentId: String,
    component: Object,
    form: Object,
    location: String,
  },
  data() {
    return { prefillKey: '' }
  },
  computed: {
    options() {
      return ['calendly', 'hubspot']
    },
    service() {
      return this.component.service || 'calendly'
    },
    keys() {
      return ['__empty', ...Array.from(new Set(this._getOptions().map(o => o.key)))]
    },
    prefillKeys() {
      const keys = this.component.prefill ? Object.keys(this.component.prefill || {}) : []
      return keys
        .filter(key => !['lastname', 'firstname', 'email'].includes(key))
        .map(key => ({ key, label: startCase(key) }))
    },
    embedUrl: {
      get() {
        return this.component && this.component.embedUrl
      },
      set(val) {
        this.$emit('update', ['embedUrl', val])
      },
    },
  },
  methods: {
    removePrefill(key) {
      const prefill = { ...(this.component.prefill || {}) }
      delete prefill[key]
      this._updateData(this._getBasePath(), `prefill`, prefill)
    },
    addNewPrefill() {
      if (this.prefillKey) {
        this._updateData(this._getBasePath(), `prefill.${this.prefillKey}`, '')
        this.prefillKey = ''
      }
    },
  },
}
</script>
