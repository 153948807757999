<template>
  <div class="PaypalCheckoutEditor">
    <a-space direction="vertical">
      <template v-if="location === 'options' || !location">
        <EditorInput _key="pay_button_text" allowComputed v-bind="{ component }" />
        <!-- <EditorCheckbox _key="use_paddle" /> -->
        <EditorSelect _key="action" :options="actionOptions" v-bind="{ component }" />
        <EditorCheckbox _key="next_on_complete" label="Automatically go to next page on success" />

        <EditorInput _key="billing_info_name" allowComputed v-bind="{ component }" />
        <EditorInput _key="billing_info_email" allowComputed v-bind="{ component }" />
        <!-- <EditorInput _key="billing_info_phone" allowComputed v-bind="{ component }" /> -->

        <template v-if="component.action === 'one-time-payment'">
          <EditorInput
            allowComputed
            _key="amount"
            :placeholder="`Amount (${component.currency || 'USD'})`"
            type="number"
            v-bind="{ component }"
          />
        </template>
        <template v-if="component.action === 'subscription'">
          <EditorInput
            _key="plan_id"
            allowComputed
            tooltip="The Plan Id of the plan for this subscription"
            v-bind="{ component }"
          />
          <EditorCheckbox
            _key="output_subscription_id"
            label="Output Subscription Id to User Data"
          />
          <EditorInput
            v-if="component.output_subscription_id"
            _key="output_subscription_id_key"
            placeholder="Key to output customer id to"
          />
        </template>

        <EditorHeading title="Paypal Styling Options" />
        <EditorSelect
          v-for="key in Object.keys(buttonsConfigOptions)"
          :key="key"
          :_key="`buttons_config.${key}`"
          :options="buttonsConfigOptions[key]"
          :defaultVal="buttonsConfigOptions[key][0].key || buttonsConfigOptions[key][0]"
        />
      </template>
      <template v-if="location === 'triggers' || !location">
        <EditorLabel label="Checkpoints to trigger on success" />
        <CheckpointEditor _key="on_payment_complete_checkpoints" :parent="component" :form="form" />
        <ConversionTrackingEditor
          _key="on_payment_complete_conversions"
          label="Conversions on payment complete"
          :parent="component"
        />
        <DataOutputFireEditor
          _key="on_payment_complete_outputs"
          :form="form"
          label="Outputs to trigger on payment complete"
        />
      </template>
    </a-space>
  </div>
</template>
<script>
import CheckpointEditor from './CheckpointEditor.vue'
import ConversionTrackingEditor from './ConversionTrackingEditor.vue'
import DataOutputFireEditor from './DataOutputFireEditor.vue'
export default {
  name: 'PaypalCheckoutEditor',
  components: { CheckpointEditor, ConversionTrackingEditor, DataOutputFireEditor },
  inject: {
    _updateReactive: { default: () => () => {} },
    _getBasePath: { default: () => () => {} },
  },
  props: {
    componentId: String,
    component: Object,
    componentData: {},
    userData: {},
    location: String,
    form: Object,
  },
  computed: {
    countries() {
      return ['US']
    },
    actionOptions() {
      return [
        // 'collect_details',
        'one-time-payment',
        'subscription',
      ]
    },
    buttonsConfigOptions() {
      const config = (this.component && this.component.buttons_config) || {}
      const sizes = Array(31)
        .fill(0)
        .map((val, i) => ({ key: i + 25, label: i + 25 }))

      const options = {
        layout: [
          { key: 'vertical', label: '[Default] Vertical' },
          { key: 'horizontal', label: 'Horizontal' },
        ],
        color: [
          { key: 'gold', label: '[Default] Gold (Paypal Recommended)' },
          { key: 'blue', label: 'Blue' },
          { key: 'silver', label: 'Silver' },
          { key: 'black', label: 'Black' },
          { key: 'white', label: 'White' },
        ],
        shape: [
          { key: 'rect', label: '[Default] Rectangle' },
          { key: 'pill', label: 'Pill' },
        ],
        size: [{ key: 'auto', label: '[Default] Automatic' }, ...sizes],
        label: [
          { key: 'paypal', label: '[Default] Paypal' },
          { key: 'checkout', label: 'Checkout' },
          { key: 'buynow', label: 'Buy Now' },
          { key: 'pay', label: 'Pay with Paypal' },
        ],
      }
      if (config.layout === 'horizontal') {
        options.tagline = [
          { key: 'true', label: 'Enabled' },
          { key: 'false', label: 'Disabled' },
        ]
      }
      return options
    },
    currencyOptions() {
      return ['usd', 'gbp', 'cad', 'eur'].map(c => ({ key: c, label: c.toUpperCase() }))
    },
  },
  methods: {},
}
</script>
