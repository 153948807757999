<template lang="html">
  <a class="LinkBlock" v-if="type === 'url'" :href="url" target="_blank">
    <Icon :name="iconCalc" />
    {{ title }}
  </a>
  <router-link class="LinkBlock" v-else-if="type === 'entity' && data" :to="`/${collection}/${id}`">
    <Icon :name="iconCalc" />
    {{ data.title }}
  </router-link>
</template>

<script>
import Firebase from 'firebase/app'
import 'firebase/firestore'

import Icon from './Icon'

export default {
  components: { Icon },
  props: {
    type: String, // 'entity', 'url'
    collection: String,
    id: String,
    title: String,
    url: String,
    icon: String,
  },
  data() {
    return {
      data: null,
    }
  },
  computed: {
    iconCalc() {
      return this.icon || 'bookmark'
    },
  },
  watch: {
    collection: {
      handler() {
        this.bind()
      },
      immediate: true,
    },
    id: {
      handler() {
        this.bind()
      },
      immediate: true,
    },
  },
  methods: {
    bind() {
      if (this.collection && this.id) {
        this.$bind(
          'data',
          Firebase.firestore()
            .collection(this.collection)
            .doc(this.id)
        )
      }
    },
  },
}
</script>

<style lang="scss">
.LinkBlock {
  display: block;
  margin: 2px -5px -2px;
  text-decoration: none;
  padding: 3px 5px;
  border-radius: var(--radius-card);
  font-weight: 500;

  &:hover {
    background: var(--background);
  }

  > svg.Icon {
    stroke-width: 2px;
  }
}
</style>
