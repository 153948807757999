export default function getSlackUsers() {
  return [
    {
      uid: 'x83B5s5IZihOLHa9pWYrtGv2vdC3',
      key: 'jeremy',
      slackId: 'UEVJQ3H25',
      email: 'jeremy@heysavvy.com',
      name: 'Jeremy',
    },
    {
      uid: 'Im1kzGkuACbJYAmRWf4dUNrOKge2',
      key: 'julieta',
      slackId: 'U01A147PFHU',
      email: 'julieta@heysavvy.com',
      name: 'Julieta',
    },
    {
      uid: '7aVZyWmrPWfSDHoi2ALVcy9rmzX2',
      key: 'matt',
      slackId: 'UEX8PALPR',
      email: 'matt@heysavvy.com',
      name: 'Matt',
    },
    {
      uid: 'xtwTMFLqSaa4oITQzg1PEORCyNw1',
      key: 'mauro',
      slackId: 'U02ENGLUVM4',
      email: 'mauro@heysavvy.com',
      name: 'Mauro',
    },
    {
      uid: 'uZZ6q6X9omUxiLz7sedDZ9dU1oI2',
      key: 'estefania',
      slackId: 'U01PHBETUJJ',
      email: 'estefania@heysavvy.com',
      name: 'Estefania',
    },
    {
      uid: 'Ir9fBEzWFuXsb3pK3pppOCrFybJ3',
      key: 'franco',
      slackId: 'U03HHHRTM25',
      email: 'franco@heysavvy.com',
      name: 'Franco',
    },
    {
      uid: 'uXioyVy8mAaS3mDJTrcPi87ObqX2',
      key: 'juan',
      slackId: 'U03H7A4NESY',
      email: 'juan@heysavvy.com',
      name: 'Juan',
    },
    {
      uid: '8w1pQu2IBIUZ0yTX61smwsjnqmT2',
      key: 'lucian',
      slackId: 'U03GQBH7Y79',
      email: 'lucian@heysavvy.com',
      name: 'Lucian',
    },
    {
      uid: 'rNrmLewSXyRxKnv5yVCgEbHxjgH2',
      key: 'paz',
      slackId: 'U03GQBHAN4X',
      email: 'paz@heysavvy.com',
      name: 'Paz',
    },
    {
      uid: 'JNXJ8IGJUicLKtVghqTmGsdfcJz1',
      key: 'tomas',
      slackId: 'U03HHHRREQZ',
      email: 'tomas@heysavvy.com',
      name: 'Tomas',
    },
    {
      uid: 'JNXJ8IGJUicLKtVghqTmGsdfcJz1',
      key: 'mike',
      slackId: 'U03JCFC1M2R',
      email: 'mike@heysavvy.com',
      name: 'Mike',
    },
  ]
}

export function getSlackUser(key) {
  return getSlackUsers().find(u => u.key === key)
}
