<template>
  <div class="PlainTextEditor">
    <a-space direction="vertical">
      <template v-if="location === 'content' || !location">
        <EditorHeading icon="paragraph" title="Content" />
        <EditorInput
          allowComputed
          _key="text"
          placeholder="Text"
          v-bind="{ component }"
          @update="debounceUpdate($event)"
        />
      </template>
    </a-space>
  </div>
</template>

<script>
import { debounce } from 'lodash'

export default {
  name: 'PlainTextEditor',
  props: {
    componentId: String,
    component: Object,
    location: String,
  },
  computed: {
    debounceUpdate() {
      return debounce(update => this.$emit('update', update), 200, {
        trailing: true,
        leading: false,
      })
    },
  },
}
</script>
