<template>
  <div class="FormProgressEditor">
    <a-space direction="vertical">
      <EditorSelect
        v-if="!component"
        label="Progress Bar"
        _key="progressBarType"
        :options="progressBarOptions"
      />
      <EditorCheckbox
        :defaultTrue="Boolean(showProgressPercentage)"
        label="Show Progress Percentage"
        _key="showProgressPercentage"
      />
      <EditorSelect
        v-if="showProgressPercentage && progressBarType === 'circle'"
        label="Label Location"
        :defaultVal="labelPosition"
        _key="progressBarLabelPosition"
        :options="labelLocationOptions"
      />
      <EditorSelect
        v-if="showProgressPercentage"
        label="Percentage Display"
        _key="percentageType"
        :defaultVal="percentageType"
        :options="percentageOptions"
      />
      <EditorCheckbox label="Start Page at 1" _key="progressBarStartAtOne" />
      <EditorInput
        v-if="startAtOne && percentageType === 'fractional'"
        _key="progressBarCompleteText"
        label="Progress Bar Completion Text"
        tooltip="Text to display on the last page"
      />
    </a-space>
  </div>
</template>
<script>
export default {
  props: { form: Object, component: Object },
  computed: {
    progressBarType() {
      return this.component ? 'circle' : this.form && this.form.progressBarType
    },
    showProgressPercentage() {
      const componentVal = this.component && this.component.showProgressPercentage
      if (componentVal === undefined) {
        const formVal = this.form && this.form.showProgressPercentage
        return formVal // === undefined ? true : formVal
      }
      return componentVal
    },
    percentageType() {
      const componentVal = this.component && this.component.percentageType
      if (componentVal === undefined) {
        const formVal = this.form && this.form.percentageType
        return formVal === undefined ? 'percent-complete' : formVal
      }
      return componentVal
    },
    labelPosition() {
      const componentVal = this.component && this.component.progressBarLabelPosition
      if (componentVal === undefined) {
        const formVal = this.form && this.form.progressBarLabelPosition
        return formVal === undefined ? 'left' : formVal
      }
      return componentVal
    },
    startAtOne() {
      const componentVal = this.component && this.component.progressBarStartAtOne
      if (componentVal === undefined) {
        const formVal = this.form && this.form.progressBarStartAtOne
        return formVal
      }
      return componentVal
    },
    progressBarOptions() {
      return ['bar', 'circle']
    },
    labelLocationOptions() {
      return ['bottom', 'left', 'right']
    },
    percentageOptions() {
      return [
        { key: 'percent-complete', label: '% complete' },
        { key: 'fractional', label: 'Fractional (1/6)' },
      ]
    },
  },
}
</script>
