<script>
import { mapState } from 'vuex'
import { copyFlowTemplate } from '../helpers/flowTemplates'
// import ElementCardSelector from './ElementCardSelector.vue'
export default {
  // components: { ElementCardSelector },
  name: 'TemplateEditor',
  props: { form: Object },
  data() {
    return {
      pageRoles: {},
    }
  },
  computed: {
    ...mapState(['allFlows']),
    templateFlows() {
      return this.allFlows.filter(f => f._meta && f._meta.template && !f._archived)
    },
    templateFlowsData() {
      return this.templateFlows.map(f => {
        const { _meta: meta, _archived: isArchived, ...form } = f
        const template = {
          key: f.id,
          label: meta.title || form.title,
          meta,
          isArchived,
          template: form,
        }
        delete form.id
        delete form.title
        return template
      })
    },
    templateFlowsContext() {
      return this.templateFlows.map(c => {
        return {
          id: c.id,
          key: c.id,
          label: (c._meta && c._meta.title) || c.title,
        }
      })
    },
  },
  mounted() {
    window.CommandBar.addCallback('loadFlowTemplate', args => {
      this.loadTemplate(args.template.id)
    })
  },
  beforeDestroy() {
    if (window.CommandBar) {
      window.CommandBar.removeContext('templateFlows')
      window.CommandBar.removeCallback('loadFlowTemplate')
    }
  },
  watch: {
    templateFlowsContext: {
      handler(v) {
        if (window.CommandBar) {
          window.CommandBar.addContext('templateFlows', v)
        }
      },
      immediate: true,
    },
  },
  methods: {
    loadTemplate(key) {
      const templateFlow = this.templateFlowsData.find(f => f.key === key)
      if (templateFlow) {
        const newFlow = copyFlowTemplate(templateFlow.template, this.form, this.pageRoles)
        this.$emit('flow', newFlow)
      }
    },
  },
  render() {
    /* 
    <template>
  <div class="TemplateEditor">
    <ElementCardSelector
      dataType="flow-templates"
      :dataArray="flows"
      @selected="loadTemplate($event)"
    />
  </div>
</template>
    */
    return null
  },
}
</script>
