var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"FlowAnalyticsCard"},[_c('FlowAnalyticsCardSeries',_vm._b({attrs:{"algoliaFilterString":_vm.algoliaFilterString},on:{"update":_vm.onUpdate}},'FlowAnalyticsCardSeries',{
      form: _vm.form,
      chart: _vm.chart,
      tab: _vm.tab,
      currentPageId: _vm.currentPageId,
    },false)),(_vm.algoliaCompareFilterString)?_c('FlowAnalyticsCardSeries',_vm._b({attrs:{"algoliaFilterString":_vm.algoliaCompareFilterString},on:{"update":function($event){return _vm.onUpdate($event, true)}}},'FlowAnalyticsCardSeries',{
      form: _vm.form,
      chart: _vm.chart,
      tab: _vm.tab,
      currentPageId: _vm.currentPageId,
    },false)):_vm._e(),_c('div',{staticClass:"flex justify-between items-center"},[_c('Pill',{staticClass:"-mt-7 overflow-hidden"},[_c('Icon',{staticClass:"mr-1",attrs:{"name":_vm.isFunnel ? 'filter' : 'signal-alt'}}),_c('div',{staticClass:"text-xs uppercase font-bold text-gray-400"},[_vm._v(" "+_vm._s(_vm.isFunnel ? 'Your Funnel' : 'Breakdown:')+" ")]),(!_vm.isFunnel)?_c('div',{staticClass:"monospaced ml-2 overflow-hidden overflow-ellipsis"},[_vm._v(" "+_vm._s(_vm.chart.key === 'first_version' ? 'version' : _vm.chart.key)+" ")]):_vm._e()],1)],1),(_vm.loading)?_c('div',{staticClass:"flex-1 flex flex-col justify-center items-center text-gray-400 min-h-20"},[_c('Icon',{attrs:{"loading":""}})],1):(_vm.data.length === 0)?_c('div',{staticClass:"flex-1 flex flex-col justify-center text-center text-gray-400"},[_vm._v(" No data for this property yet ")]):_c('div',{staticClass:"grid grid-cols-2 gap-2 overflow-hidden",staticStyle:{"grid-template-columns":"10fr 1fr"}},[_c('div'),_c('div',{staticClass:"cursor-pointer hover:opacity-70 font-bold text-gray-400 text-right -mt-1 -mb-2 whitespace-nowrap",on:{"click":function($event){_vm.showNum = !_vm.showNum}}},[_vm._v(" "+_vm._s(_vm.showNum ? '#' : '%')+" ")]),_vm._l((_vm.combinedData),function(row){return [_c('div',{key:row[0].value,staticClass:"flex items-center overflow-hidden"},[_c('div',{ref:("bar" + (row[0].isLargest ? ':largest' : '')),refInFor:true,staticClass:"bg-purple h-1 rounded-full",style:({
            flex:
              row[0].isLargest && row[0].count === _vm.largestCount
                ? '1 1 auto'
                : ("0 0 " + ((_vm.maxWidth * row[0].count) /
                    (_vm.showNum
                      ? _vm.largestCount
                      : (_vm.data.find(function (r) { return r.isLargest; }) || {}).count || row[0].count)) + "px"),
          })}),_c('a-tooltip',{attrs:{"title":row[0].valueText}},[_c('Pill',{staticClass:"monospaced overflow-hidden overflow-ellipsis",style:({ maxWidth: _vm.isFunnel ? '100px' : '150px' }),attrs:{"block":"","selected":_vm.tab === 'this_page' && row[0].isCurrentPage}},[_vm._v(" "+_vm._s(row[0].valueText)+" ")])],1)],1),_c('div',{key:((row[0].value) + ":count"),staticClass:"flex items-center text-right justify-end"},[_vm._v(" "+_vm._s(_vm.showNum ? row[0].count : _vm.displayPercent(row[0].countPercent))+" ")]),(row[1])?[_c('div',{key:((row[1].value) + "|2"),staticClass:"flex items-center overflow-hidden -mt-3 mb-1"},[_c('div',{ref:("bar" + (row[1].isLargest ? ':largest' : '')),refInFor:true,staticClass:"bg-purple h-1 rounded-full",staticStyle:{"background-color":"var(--color-highlight)"},style:({
              flex:
                row[1].isLargest && row[1].count === _vm.largestCount
                  ? '1 1 auto'
                  : ("0 0 " + ((_vm.maxWidth * row[1].count) /
                      (_vm.showNum
                        ? _vm.largestCount
                        : (_vm.compareData.find(function (r) { return r.isLargest; }) || {}).count || row[1].count)) + "px"),
            })}),_c('a-tooltip',{attrs:{"title":row[1].valueText}},[_c('Pill',{staticClass:"monospaced overflow-hidden overflow-ellipsis",style:({ maxWidth: _vm.isFunnel ? '100px' : '150px' }),attrs:{"block":"","selected":_vm.tab === 'this_page' && row[1].isCurrentPage,"filledColor":"var(--color-highlight)"}},[_vm._v(" "+_vm._s(row[1].valueText)+" ")])],1)],1),_c('div',{key:((row[1].value) + ":count|2"),staticClass:"flex items-center text-right justify-end -mt-3 mb-1"},[_vm._v(" "+_vm._s(_vm.showNum ? row[1].count : _vm.displayPercent(row[1].countPercent))+" ")])]:_vm._e()]})],2),_c('a-tooltip',{attrs:{"title":"Pages with conditions aren't shown to all users, so can show up in funnel charts as much smaller numbers. This can make funnels more confusing to glance at, so we hide those pages from the chart by default."}},[(_vm.isFunnel && _vm.allUnconditionalPages.length < _vm.allPages.length)?_c('div',{staticClass:"mt-2 -mb-1 text-center text-xs text-gray-400 hover:opacity-80 cursor-pointer",on:{"click":function($event){_vm.showConditionalPages = !_vm.showConditionalPages}}},[_c('Icon',{staticClass:"mr-1",attrs:{"name":_vm.showConditionalPages ? 'eye-slash' : 'eye'}}),_vm._v(" "+_vm._s(_vm.showConditionalPages ? 'Hide' : 'Show')+" Conditional Pages ")],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }