import { repeatPages } from '@/components/form/helpers/repeatPages'
import componentTypes from '@/components/form/editor/helpers/componentTypes'
import getButtons from '../../helpers/getButtons'

const noUserInput = componentTypes()
  .filter(c => c.hasNoValue)
  .map(c => c.key)
const TYPES = {
  number: 'number',
  boolean: 'boolean',
  string: 'string',
  // object: 'object',
  array: '[]',
  any: 'any',
  undefined: 'undefined',
}

export function generateUserDataSchema(form, userData) {
  const schema = {}
  const pages = (form && Array.isArray(form.pages) && repeatPages(form.pages, userData)) || []
  const shouldSaveToSchema = c =>
    (c.container_type === 'Carousel' || !noUserInput.includes(c.type)) && !c.doNotSave
  pages.forEach(p => {
    if (Array.isArray(p.components))
      p.components.forEach(c => {
        if (shouldSaveToSchema(c)) {
          schema[c.key] = getReturnTypesForComponents(c)
          addExtraComponentKeys(c, schema)
        }
      })
  })
  if (form && form.computedFields) {
    form.computedFields.forEach(c => {
      if (!c.doNotSave) schema[c.key] = TYPES.any
    })
  }
  if (form && form.experiments) {
    form.experiments.forEach(e => {
      schema[e.key] = (e.variants || []).map(v => `'${v.key}'`)
    })
  }
  if (form && form.components) {
    form.components.forEach(c => {
      if (shouldSaveToSchema(c)) schema[c.key] = getReturnTypesForComponents(c)
    })
  }
  const simpleAnyKeys = ['url_keys', 'registered_keys', 'embed_code_keys']
  simpleAnyKeys.forEach(key => {
    if (form && form[key]) {
      const keys = form[key].split('\n').map(t => t.trim())
      keys.forEach(k => (schema[k] = [TYPES.any]))
    }
  })

  schema.createdAt = [TYPES.string]
  schema.entryId = [TYPES.string]
  schema.has_submitted = [TYPES.boolean, TYPES.undefined]
  schema.is_test = [TYPES.boolean, TYPES.undefined]

  return schema
}

function getReturnTypesForComponents(component, userData) {
  const { type } = component
  if (type === 'Container' && component.container_type === 'Carousel') return [TYPES.number]
  if (noUserInput.includes(type)) return
  const base = component.isRequired ? [] : [TYPES.undefined]
  switch (type) {
    case 'OptionSelector': {
      const buttons = getButtons(component, userData)
        .map(b => (typeof b === 'string' ? b : b && (b.key || b.id)))
        .filter(b => b)
      if (buttons.length > 0) {
        component.multiple
          ? base.push(`(${buttons.map(b => `'${b}'?`).join(' | ')})${TYPES.array}`)
          : base.push(...buttons.map(b => `'${b}'`))
      } else if (component.multiple) base.push(`${TYPES.string}${TYPES.array}`)
      else base.push(`${TYPES.string}`)
      break
    }
    case 'InputBox': {
      switch (component.input_type) {
        case 'number': {
          base.push(TYPES.number, TYPES.string)
          break
        }
        case 'checkbox':
        case 'confirm':
        case 'switch': {
          base.push(TYPES.boolean)
          break
        }

        default: {
          base.push(TYPES.string)
          break
        }
      }
      break
    }

    case 'CustomAuth': {
      base.push('signed_in', 'not_signed_in')
      break
    }
    case 'StripeCheckout': {
      base.push(TYPES.string)
      break
    }
    case 'BookMeeting': {
      base.push(TYPES.string)
      break
    }
    case 'FileUpload': {
      base.push(`{ name: ${TYPES.string}, url: ${TYPES.string} | ${TYPES.undefined} }`)
      break
    }

    default:
      break
  }
  return base
}

function addExtraComponentKeys(component, schema) {
  if (!component) return
  const base = component.isRequired ? [] : [TYPES.undefined]

  switch (component.type) {
    case 'StripeCheckout': {
      const componentIsCollectDetails =
        component.action === 'collect_details' || component.collect_details
      if (
        componentIsCollectDetails &&
        component.output_payment_method &&
        component.payment_method_key
      ) {
        base.push(TYPES.string)
        schema[component.payment_method_key] = base
      }
      break
    }

    default:
      return
  }
}
