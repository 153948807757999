<template>
  <div
    class="SignpostFormEditor overflow-auto shadow-lg bg-white border border-gray-200 rounded-lg m-2 flex flex-col"
    :class="{ minimized, expanded, isPreview: 'opacity-0' }"
  >
    <div class="flex justify-between p-4 pb-2">
      <div class="font-bold text-lg text-gray-500 capitalize pl-0.5">
        <Icon class="mr-1" :name="sidebarIcon" />
        {{ sidebarLabel }}
      </div>
      <div class="flex items-center cursor-pointer" @click="$store.commit('setRightBar', false)">
        <Icon class="mr-1" name="times" />
        Close
      </div>
    </div>
    <EditorPane v-if="form" :userData="userData" :form="form" :noAuthComments="noAuthComments" />

    <div v-else class="flex w-full h-full justify-center items-center">
      <Spinner />
    </div>
  </div>
</template>

<script>
import { isEqual, debounce } from 'lodash'
import { mapGetters, mapState } from 'vuex'

import Vue from 'vue'
// import Draggable from 'vuedraggable'
import VueFontAwesomePicker from 'vfa-picker'

import { unpack } from '@/helpers/computed'
import getOptions from './helpers/options'
import EditorInput from './helpers/EditorInput'
import EditorCheckbox from './helpers/EditorCheckbox'
import EditorSelect from './helpers/EditorSelect'
import EditorIcon from './helpers/EditorIcon'
import EditorCode from './helpers/EditorCode'
import EditorPane from './mainEditor/EditorPane.vue'

Vue.component('EditorInput', EditorInput)
Vue.component('EditorCheckbox', EditorCheckbox)
Vue.component('EditorSelect', EditorSelect)
Vue.component('EditorIcon', EditorIcon)
Vue.component('EditorCode', EditorCode)

Vue.use(VueFontAwesomePicker)

export default {
  name: 'SignpostFormEditor',
  components: {
    EditorPane,
  },
  inject: {
    _register: { default: () => () => {} },
    _updateReactive: { default: () => () => {} },
    _getFlowPages: { default: () => () => {} },
  },
  provide() {
    return {
      _getOptions: () => this.options,
      _getAllTags: () => this.allTags,
      _getData: () => this.form,
      _getBasePath: () => '',
      _updateData: this.updateData,
      _onPageUpdate: this.onPageUpdate,
    }
  },
  props: {
    id: String,
    form: Object,
    userData: Object,
    viewport: String,
    showingPages: Array,
    eligibleDestinations: Array,
    responsiveGroups: Array,
    isPreview: Boolean,
    minimized: Boolean,
    coldUpdates: Boolean,
    noAuthComments: Boolean,
    unsavedChanges: Array,
  },
  data() {
    return {
      expanded: false,
      openPages: [],
      openComputed: [],
      followCurrentPage: false,
      showFullUserData: false,
      editingResponsively: false,
      bulkAddPage: '',
      bulkGenerateContent: '',
      themeOptions: [
        { key: 'Default' },
        { key: 'Savvy' },
        { key: 'CommissaryClub', label: 'Commissary Club' },
        { key: 'CustomMySneaker', label: 'Custom My Sneakers' },
        { key: 'Moma' },
        { key: 'ClozeLoop' },
        { key: 'Lawyaw' },
      ],
      alignmentOptions: [
        { key: 'undefined', label: 'Default (Left)' },
        { key: 'left_buttons_centered', label: 'Left with Centered Buttons' },
        { key: 'centered', label: 'Centered' },
      ],
      defaultUserDataRaw: '',
    }
  },
  computed: {
    ...mapState(['versionData', 'highestVersion']),
    ...mapGetters(['activeGroupId', 'isAdmin']),
    ...unpack('form', [
      'title',
      'theme',
      'logoPlacement',
      'forget_user_data',
      'alignment',
      'computedFields',
      'destinations',
      'dataOutputs',
      'styles',
      'logoImgUrl',
    ]),
    sidebarLabel() {
      if (this.noAuthComments) return 'comments'
      return this.$store.state.showRightBar
    },
    sidebarIcon() {
      const barState = this.noAuthComments ? 'comments' : this.$store.state.showRightBar
      switch (barState) {
        case 'options':
          return 'tools'
        case 'design':
          return 'paint-brush'
        case 'triggers':
          return 'random'
        case 'comments':
          return 'comments'

        default:
          return 'tools'
      }
    },
    unsaved() {
      return this.$store.state.hasUnsavedFlowChanges
    },
    currentPageId() {
      return (this.userData && this.userData.currentPageId) || null
    },
    pages: {
      get() {
        return this.form.pages || []
      },
      set(v) {
        this._updateReactive(null, 'pages', v)
      },
    },
    options() {
      return getOptions(this.form, this.userData)
    },
    allTags() {
      const pageBlocks = this.pages.reduce(
        (blocks, page) => blocks.concat(page.layoutBlocks || []),
        []
      )
      return [
        ...getTags(this.pages, 'page'),
        ...getTags(pageBlocks, 'block'),
        ...getTags(this.options, 'component'),
      ]

      function getTags(entities, type) {
        return entities
          .reduce((acc, e) => acc.concat((e && e.tags) || []), [])
          .unique()
          .map(tag => ({ tag, type }))
      }
    },
    debounceUpdateReactive() {
      return debounce(this._updateReactive, 250, { trailing: true, leading: false })
    },
  },
  watch: {
    currentPageId(v, o) {
      if (isEqual(v, o)) return
      if (this.followCurrentPage) this.openPages = [v]
    },
  },
  methods: {
    updateData(path, key, val, toDebounce, isResponsive) {
      const method = toDebounce ? this.debounceUpdateReactive : this._updateReactive

      method(path, key, val, isResponsive)
    },
    onPageUpdate(index, [key, value]) {
      this._updateReactive(`pages.${index}`, key, value)
    },
  },
}
</script>

<style lang="scss">
@import './SignpostFormEditor.scss';
</style>
