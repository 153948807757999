<template>
  <div class="ContactAnswers text-xs items-center">
    <div class="flex flex-wrap items-center justify-center">
      <a-tooltip
        v-for="[key, val, preIcon, postIcon] in (answers || []).slice(0, 8)"
        :key="key"
        :title="key"
      >
        <Pill tight>
          <Icon v-if="preIcon" :name="preIcon" class="inline-icon" />
          {{ val }}
          <Icon v-if="postIcon" :name="postIcon" class="inline-icon" />
        </Pill>
      </a-tooltip>
    </div>
  </div>
</template>

<script>
import { universalIdToProper, trunc } from '@/helpers/textStringConversions'
import { mapGetters } from 'vuex'

export default {
  name: 'ContactAnswers',
  props: {
    contact: Object,
    mainFlow: Array,
  },
  computed: {
    ...mapGetters(['flows']),
    answers() {
      if (!this.mainFlow) return null

      const maxChars = 20
      const customHideKeys = (
        (this.flows.find(f => f.id === this.mainFlow[0]) || {}).hide_keys_in_analytics || ''
      ).split(/\n|,/)

      return (
        (this.mainFlow &&
          this.mainFlow[1] &&
          Object.entries(this.mainFlow[1])
            .filter(p => toInclude(p, customHideKeys))
            .sort((a, b) => (a[1] || '').length - (b[1] || '').length)
            .map(([key, val]) => [
              key,
              typeof val === 'string' && !val.includes('http') && !val.includes('@')
                ? universalIdToProper(val)
                : val,
            ])
            .map(([key, val]) => [
              key,
              typeof val === 'string'
                ? trunc(val, maxChars)
                : Array.isArray(val)
                ? val.map(v => universalIdToProper(trunc(v, maxChars))).join(', ')
                : val,
            ])
            .map(([key, val]) => [
              universalIdToProper(key),
              justKey(key, val)
                ? `${universalIdToProper(key)}`
                : needsKey(key, val)
                ? `${universalIdToProper(key)}: ${universalIdToProper(`${val}`)}`
                : val,
              typeof val === 'string' && val.includes('@') ? 'envelope' : null,
              // val === true ? 'check' : val === false ? 'times' : null,
            ])) ||
        []
      )
    },
  },
}

function toInclude([key, val], customHideKeys) {
  const ignoreKeys = [
    'current_page_index',
    'current_page_key',
    'current_total_pages',
    'highest_page_reached_index',
    'highest_page_reached_key',
    'updatedAt',
    'entryId',
    'maxPageIndex',
    'has_submitted',
    'completed_flow',
    'always_false',
    'submitted_timestamps',
    'triage_reasons',
    'generic_triage_reasons',
    'name',
    'email',
    'first_name',
    'last_name',
    'company',
    'website',
    'phone',
    'domain',
    '_prefilled',
    '_referrer',
    '_progress',
    '_has_analytics',
    'first_version',
    'last_version',
    ...customHideKeys,
  ]

  const ignoreVals = [undefined, null, '', {}, 'N/A', '__null', '_null']

  return (
    !ignoreKeys.includes(key) &&
    !key.endsWith('_id') &&
    !key.endsWith('_code') &&
    !ignoreVals.includes(val) &&
    !(val instanceof Object) &&
    val !== '[object Object]'
    // typeof val !== 'object'
  )
}
// function isRichText(val) {
//   return val && val.body
// }
// function isSource(val) {
//   return val && (val.referrer || val.source || val.medium)
// }
function justKey(key, val) {
  return false && typeof val === 'boolean'
}

function needsKey(key, val) {
  return (
    typeof val === 'boolean' ||
    ['false', 'False', 'true', 'True'].includes(val) ||
    typeof val === 'number' ||
    (typeof val === 'string' && val.length <= 3)
  )
}
</script>

<style lang="scss">
@import '@/styles/_variables.scss';

.ContactAnswers {
  flex-wrap: wrap;
}
</style>
