<template>
  <div class="IconPicker" @click.stop>
    <Dropdown isRadio text="Select an Icon" v-bind="{ buttons }" v-model="icon">
      <Icon v-if="icon" :name="icon" />
    </Dropdown>
  </div>
</template>

<script>
// import Icons from './material-icons'
import Icons from './fontawesome-icons'

export default {
  name: 'IconPicker',
  props: {
    value: String,
  },
  computed: {
    icon: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    buttons() {
      // const Icons = ['chevron-up', 'users', 'home']
      return Array.from(new Set(Icons)).map(i => ({ text: i, icon: i, id: i, value: `fad-${i}` }))
    },
  },
}
</script>

<style lang="scss">
@import './styles/IconPicker.scss';
</style>
