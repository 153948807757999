<template>
  <div class="Insights">
    <GridLayout
      v-if="gridLayoutArray.length"
      :layout.sync="gridLayoutArray"
      :rowHeight="15"
      :isDraggable="_editingMode()"
      :isResizable="_editingMode()"
      :verticalCompact="false"
      :preventCollision="false"
      :margin="[16, 16]"
      :useCssTransforms="false"
      v-bind="{ colNum }"
    >
      <GridItem
        v-for="gridItem in gridLayoutArray"
        :key="gridItem.id"
        v-bind="gridItem"
        :style="{ 'z-index': gridItem.y }"
        @moved="moved"
        @resized="resized"
      >
        <InsightCard
          v-bind="{ id: gridItem.id, filters, tags }"
          @remove-card="$emit('remove-card', $event)"
        />
      </GridItem>
    </GridLayout>
  </div>
</template>

<script>
import Firebase from 'firebase/app'
import 'firebase/firestore'

import { GridLayout, GridItem } from 'vue-grid-layout'
import InsightCard from '@/components/dashboard/InsightCard'
export default {
  name: 'InsightsCanvas',
  components: {
    GridLayout,
    GridItem,
    InsightCard,
  },
  inject: {
    _updateConnections: { default: () => () => {} },
    _editingMode: {
      default: null,
    },
  },
  props: {
    insights: { type: Object, default: () => ({}) },
    tags: { default: () => [], type: Array },
    useSimpleUpdate: Boolean,
    currentPath: String,
    externalFilters: Array,
  },
  data() {
    return {
      gridLayoutArray: [],
      loading: false,
    }
  },
  computed: {
    // id() {
    //   const split = this.currentPath.split('/')
    //   return split[split.length - 1]
    // },
    filters() {
      return this.externalFilters
    },
    hideFilters() {
      return ['journey', 'stage']
    },
    insightCards() {
      return Object.entries(this.insights || {}).map(e => ({ ...e[1], id: e[0] }))
    },
    colNum() {
      return 48
    },
  },
  mounted() {
    this._updateConnections()
  },
  watch: {
    insightCards: {
      handler(items) {
        if (items) {
          this.gridLayoutArray = items
            // .filter(item => item.grid)
            .map((item, i) => {
              const grid = item.grid || {}
              const { w = 4, h = 6, x = i * 4, y = 0 } = grid
              return { w, h, x, y, i, id: item.id }
            })
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    async moved(i, newX, newY) {
      const id = this.gridLayoutArray[i].id
      const update = { [`insights.${id}.grid.x`]: newX, [`insights.${id}.grid.y`]: newY }
      if (this.useSimpleUpdate) {
        this.$emit('update-grid', update)
      } else {
        let docRef = Firebase.firestore().doc(`content/${this.currentPath}`)

        const doc = await docRef.get()
        if (!doc.exists) await docRef.set({})

        docRef.update(update)
      }
    },
    async resized(i, newH, newW) {
      const id = this.gridLayoutArray[i].id
      const update = { [`insights.${id}.grid.h`]: newH, [`insights.${id}.grid.w`]: newW }
      if (this.useSimpleUpdate) {
        this.$emit('update-grid', update)
      } else {
        const docRef = Firebase.firestore().doc(`content/${this.currentPath}`)

        const doc = await docRef.get()
        if (!doc.exists) await docRef.set({})

        docRef.update(update)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/_variables.scss';

.Insights {
  margin: -6px -16px;
}
</style>
