<template>
  <!-- <Spinner v-if="loading" /> -->
  <!-- Standalone Flows, needs no auth nor initialization -->
  <FlowCommentable v-if="$route.name === 'flow-feedback'" :id="$route.params.id" />
  <Flow v-else-if="$route.name === 'flow'" :id="$route.params.id" />
  <Main v-else />
</template>

<script>
import { loadGooglePlaces } from '@/boot-up/scriptLoading'

export default {
  name: 'FlowSwitch',
  components: {
    Main: () => import('./Main.vue'),
    Flow: () => import('./Flow.vue'),
    FlowCommentable: () => import('./views/FlowCommentable.vue'),
  },
  async mounted() {
    try {
      loadGooglePlaces()
    } catch (error) {
      console.error(error)
    }
  },
  methods: {},
}
</script>
