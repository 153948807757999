import { parseURIComponent } from '@/helpers/textStringConversions'

export default function computeFilters(filters, context) {
  const rocksetValues = context.rocksetValues || { channel: [] }
  const aliasesMap = context.aliases
  return filters
    .reduce((acc, [key, value]) => {
      switch (key) {
        case 'country':
        case 'continent':
        case 'region':
        case 'city':
        case 'eventId':
        case 'journey':
        case 'contacttype':
        case 'contact_details':
        case 'flow':
        case 'stage': {
          acc.push([key, '==', value])
          break
        }
        case 'flow-values': {
          acc.push(['flow', '==', value])
          break
        }

        case 'medium': {
          acc.push(['medium', 'in', [value]])
          break
        }
        case 'page':
        case 'domain': {
          acc.push([key, '==', parseURIComponent(value)])
          break
        }
        case 'channel': {
          const channelObj = rocksetValues.channel.find(c => c.key === value)
          if (channelObj) {
            acc.push([
              'channel',
              'in',
              Array.from(new Set([channelObj.key, ...channelObj.aliases])),
            ])
          } else {
            acc.push(getAliasFilter('channel', value, aliasesMap))
          }
          break
        }
        case 'campaign': {
          const aliases = aliasesMap && aliasesMap['campaign']
          acc.push(
            aliases && Array.isArray(aliases[value])
              ? ['utm_campaign', 'in', [value, ...aliases[value]]]
              : ['utm_campaign', '==', value]
          )
          break
        }
        case 'daterange': {
          const { timestampKey, presetTimeValues } = context.dateTime || {}
          /* Should probably be reworked at some point */
          if (value.startsWith('date')) {
            const splitdate = value.slice(4).split('date')
            acc.push(
              [timestampKey, '>=', new Date(splitdate[0])],
              [timestampKey, '<=', new Date(splitdate[1])]
            )
          } else if (typeof value === 'string' && presetTimeValues[value]) {
            acc.push([timestampKey, '>=', presetTimeValues[value]])
            if (value === 'yesterday') acc.push([timestampKey, '<', presetTimeValues.today])
          }
          break
        }
        default:
          break
      }
      return acc
    }, [])
    .filterExists()
}

function getAliasFilter(key, value, aliasMap) {
  const aliases = aliasMap && aliasMap[key]
  return aliases && Array.isArray(aliases[value])
    ? [key, 'in', [value, ...aliases[value]]]
    : [key, '==', value]
}
