<template>
  <div class="LineChart" :class="presetField">
    <div class="chart-header">
      <a-tooltip v-if="typeof totalNumber !== 'number'">
        <template slot="title">Total Average</template>
        <div>{{ prefix }}{{ averageNumber }}</div>
      </a-tooltip>
      <a-tooltip v-else>
        <template slot="title"> Switch to {{ showAverage ? 'Total' : 'Average' }} </template>
        <div class="total-average" @click="showAverage = !showAverage">
          {{ prefix }}{{ showAverage ? averageNumber : totalNumber }}
          <span v-if="showAverage">AVG</span>
        </div>
      </a-tooltip>
      <div v-if="hovering">{{ prefix }}{{ hoverValue }}</div>
      <!-- <div>{{ hovering ? hoverPosition : values.length }}</div> -->
    </div>
    <div class="chart" ref="chart" :style="`margin: 0 -${m}px; padding: 0;`">
      <svg
        v-if="w && h"
        :viewBox="`0 0 ${w + m * 2} ${h + m * 2}`"
        :width="w + m * 2"
        :height="h + m * 2"
        @mouseover="hovering = true"
        @mouseleave="hovering = false"
        @mousemove="updateHoverPosition"
      >
        <path stroke="#bbb" :d="`M ${m} ${m + h} H ${m + w}`"></path>
        <path style="stroke-width: 1.5px;" :d="path"></path>
        <path
          v-if="max"
          stroke="#bbb"
          stroke-dasharray="1,1"
          :d="
            `M ${m + (hovering ? (w * hoverPosition) / (values.length - 1) : w)} ${m + h} V ${m +
              h -
              ((hovering ? values[hoverPosition] || 0 : lastValue) * h) / max}`
          "
        ></path>
        <circle
          v-if="max && lastValue !== undefined"
          :cx="m + (hovering ? (w * hoverPosition) / (values.length - 1) : w)"
          :cy="m + h - ((hovering ? values[hoverPosition] || 0 : lastValue) * h) / max"
          r="2"
        />
        <path
          v-if="showAverage"
          stroke="#bbb"
          stroke-dasharray="4"
          style="stroke-width: 1.5px;"
          :d="`M ${m} ${m + h - (averageNumber * h) / max} H ${m + w}`"
        ></path>
      </svg>
    </div>
    <div v-if="data" class="chart-footer">
      <div
        v-if="hovering"
        :style="{ marginLeft: `${m + (w * hoverPosition) / (values.length - 1)}px` }"
      >
        <div :style="{ flex: hoverPosition }"></div>
        <div style="margin: 0 -50%;">
          {{ hoverLabel }}
        </div>
        <div :style="{ flex: values.length - hoverPosition }"></div>
      </div>
      <template v-else>
        <div>{{ data.labels[0] }}</div>
        <div>{{ data.labels.slice(-1)[0] }}</div>
      </template>
    </div>
  </div>
</template>

<script>
import get from 'lodash/get'

export default {
  props: {
    preset: String,
    name: String,
    data: Object,
    colors: [Array, null],
    icon: String,
    config: Object,
    totalCount: Number,
    averageCount: Number,
    prefix: String,
    useVisitorCount: Boolean,
    noTotal: Boolean,
  },
  data() {
    return {
      w: 0,
      h: 0,
      showAverage: false,
      hovering: false,
      hoverPosition: null,
    }
  },
  computed: {
    values() {
      return get(this.data || {}, 'datasets[0].data', [])
    },
    hoverLabels() {
      return get(this.data || {}, 'datasets[0].hoverLabels', [])
    },
    m() {
      // Margin
      return 3
    },
    averageNumber() {
      return this.values
        ? parseInt((100 * this.values.reduce((a, i) => a + i, 0)) / this.values.length) / 100
        : null
    },
    totalNumber() {
      if (this.noTotal) return null
      if (this.useVisitorCount) return this.totalCount
      else if (this.values && this.values.length) {
        const sum = this.values.reduce((acc, item) => acc + item, 0)
        return this.prefix ? sum.toFixed(2) : sum
      }
      return null
    },
    lastValue() {
      return this.values.slice(-1)[0]
    },
    hoverValue() {
      if (this.hoverLabels && this.hoverLabels[this.hoverPosition])
        return this.hoverLabels[this.hoverPosition]
      return typeof this.values[this.hoverPosition] === 'number'
        ? this.prefix
          ? this.values[this.hoverPosition].toFixed(2)
          : this.values[this.hoverPosition]
        : this.values[this.hoverPosition]
    },
    hoverLabel() {
      return ((this.data && this.data.labels) || [])[this.hoverPosition]
    },
    max() {
      return Math.max(...this.values)
    },
    path() {
      const { values, w, h, m, max } = this

      if (!w || !h) return

      const length = values.length

      return values
        .map(
          (d, i) => `${i === 0 ? 'M' : 'L'} ${m + (w * i) / (length - 1)} ${m + h - (h * d) / max}`
        )
        .join(' ')
    },
    presetField() {
      return (this.config && this.config[`${this.config.service}-breakdown`]) || this.preset
    },
    chartData() {
      if (!this.data || !this.data.labels || this.data.datasets.length === 0) return

      const data = this.data.labels.map((label, i) => ({
        key: label,
        count: this.data.datasets[0].data[i],
      }))
      const max = Math.max(...data.map(row => row.count))

      return data.map(row => {
        const values = (row.values && row.values[0]) || {}
        return {
          ...row,
          key:
            row.key === '__null' || row.key === 'null-null'
              ? 'Unknown'
              : this.presetField === 'channels'
              ? `${values.channel}${values.medium !== 'null' ? ` (${values.medium})` : ''}`
              : row.key,
          percent: (100 * row.count) / max,
          icons: this.presetField === 'channels' ? this.getIcons(row.domains) : null,
        }
      })
    },
    nameKey() {
      switch (this.presetField) {
        case 'pages':
          return 'page'
        case 'channels':
          return 'channel'
        case 'continents':
          return 'continent'
        case 'countries':
          return 'country'
        case 'regions':
          return 'region'
        case 'cities':
          return 'city'
        default:
          return null
      }
    },
  },
  watch: {
    colors() {
      this.setColors()
    },
  },
  mounted() {
    this.setColors()

    const self = this
    this.interval = setInterval(function() {
      const pos = self.$refs.chart.getBoundingClientRect()

      self.w = pos.width
      self.h = pos.height
    }, 1000)
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  methods: {
    setColors() {
      if (!this.$el || !this.$el.style) return

      // if (this.colors) this.$el.style.setProperty('--bar-color', this.colors[0])
      // if (this.colors) this.$el.style.setProperty('--bar-background', this.colors[0] + '44')
      // if (this.colors) this.$el.style.setProperty('--bar-background-faded', this.colors[0] + '11')
      // if (this.colors) this.$el.style.setProperty('--bar-background-strong', this.colors[0] + '66')
    },
    getIcons(domains) {
      if (!domains || !domains.length) return []

      return domains
        .map(
          d =>
            d &&
            d
              .split('.')
              .slice(-2)
              .join('.')
              .split('.android')
              .join('')
        )
        .map(d => `https://logo.clearbit.com/${d}`)
        .reduce((acc, url) => acc.concat([url, `${url}.com`]), [])
    },
    updateHoverPosition(event) {
      if (this.hovering) {
        const { w, values, m } = this

        const offset = event.offsetX - m

        this.hoverPosition = (((values.length - 1) * offset) / (w - 2 * m)).toFixed(0)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/_variables.scss';

.LineChart {
  @include flex('column', 'a-stretch');
  stroke: $savvy;
  stroke-width: 1px;
  height: 100%;

  .chart-header,
  .chart-footer {
    @include flex('row', 'j-between');
  }
  .chart-header {
    font-size: 20px;
    color: $savvy;

    .total-average {
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }

      span {
        font-size: 0.5em;
        color: #777;
        font-weight: 600;
      }
    }
  }
  .chart {
    box-sizing: border-box;
    flex: 1;
    overflow: hidden;

    svg {
      height: 100%;
      width: 100%;
      fill: none;

      circle {
        fill: #6454f3;
      }
    }
  }
  .chart-footer {
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
  }
}
</style>
