<template>
  <div class="DataOutputFireEditor">
    <!-- Checkpoints -->
    <EditorSelect multiple :_key="key" :options="outputOptions" v-bind="{ label }" />
  </div>
</template>
<script>
import { startCase } from 'lodash'
export default {
  props: {
    form: Object,
    _key: String,
    jsxKey: String,
    label: String,
  },
  computed: {
    key() {
      return this.jsxKey || this._key || 'outputs_to_trigger'
    },
    outputOptions() {
      return (this.form.dataOutputs || []).map((o, i) => ({
        label: o.name || `${i + 1}: ${startCase(o.output)}`,
        key: o.id,
      }))
    },
  },
}
</script>
