<template>
  <div class="ContentLoop" :class="{ editingMode: _editingMode() }">
    <Entities renderless :collection="`content/${path}`" v-bind="{ filters }" v-model="entities" />
    <div class="item" v-for="(item, index) in items" :key="item.id">
      <div class="delete" @click="deleteItem(item.id)">
        <Icon name="times" />
      </div>
      <slot v-bind="{ item, index }" />
    </div>
    <slot name="items" v-bind="{ items, deleteItem }" />
    <Button v-if="isEditable && !noAdd" icon="plus" text="Add Item" :click="addItem" />
  </div>
</template>

<script>
import Firebase from '@firebase/app'
import '@firebase/firestore'

export default {
  props: {
    type: String,
    path: String,
    filters: Array,
    initialProps: Object,
    noAdd: Boolean,
    localFilter: Function,
  },
  inject: ['_isAdmin', '_editingMode'],
  data() {
    return {
      entities: [],
    }
  },
  computed: {
    items() {
      return this.entities
        .filter(i => this.isEditable || Object.values(i).filter(v => v).length > 2)
        .filter(this.localFilter || (i => i))
        .map(i => ({
          id: i.id,
          index: i.index,
          content: i,
          path: `${this.path}/${i.id}`,
        }))
        .sort((a, b) => a.index - b.index)
    },
    isEditable() {
      return Boolean(this._isAdmin() && this._editingMode())
    },
  },
  watch: {
    items: {
      handler(items) {
        this.$emit('input', items)
      },
      immediate: true,
    },
  },
  methods: {
    addItem() {
      Firebase.firestore()
        .collection(`content/${this.path}`)
        .add({
          index: this.entities.length,
          ...this.initialProps,
        })
    },
    deleteItem(id) {
      Firebase.firestore()
        .collection(`content/${this.path}`)
        .doc(id)
        .delete()
    },
  },
}
</script>

<style lang="scss">
@import './styles/ContentLoop.scss';
</style>
