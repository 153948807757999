<template>
  <div class="ContactProgressFlow text-center my-1 whitespace-nowrap" v-if="flowData">
    <div class="flow-name" v-if="flows.length > 1">
      {{ flowName }}
    </div>
    <div
      v-if="typeof flowData._progress === 'number' && flowData._progress < 100"
      class="progress-bar w-24 m-auto bg-gray-200 rounded overflow-hidden my-1"
    >
      <div
        class="bar h-1.5 bg-primary"
        :style="{ width: `${parseInt(flowData._progress)}%` }"
      ></div>
    </div>
    <div
      class="progress text-xs capitalize font-semibold text-gray-500 flex items-center justify-center my-1 whitespace-nowrap"
      :class="{ 'text-green-500': status === 'completed' }"
    >
      {{ checkpointNames.slice(-1)[0] }}
      <!-- <Icon v-if="status === 'completed'" type="far" name="check" class="mr-1" />
      <Icon v-else-if="status === 'opened'" type="far" name="eye" class="mr-1" />
      {{ status === 'in_progress' ? `${completionAmount}% Completed` : `${status} Flow` }} -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import getCheckpointOptions from '@/components/form/editor/helpers/getCheckpointOptions'

export default {
  name: 'ContactProgressFlow',
  props: {
    contact: Object,
    flowId: String,
    flowData: Object,
  },
  computed: {
    ...mapGetters(['flows']),
    flow() {
      return this.flows.find(flow => flow.id === this.flowId)
    },
    flowName() {
      return (this.flow && this.flow.title) || 'Unknown Flow'
    },
    // completionAmount() {
    //   return parseInt(
    //     ((this.flowData.current_page_index || this.flowData.maxPageIndex) /
    //       (this.flowData.current_total_pages || 5)) *
    //       100
    //   )

    //   // return parseInt((100 * this.checkpoints.length) / this.allCheckpoints.length)
    // },
    status() {
      return this.completionAmount >= 100
        ? 'completed'
        : !this.completionAmount
        ? 'opened'
        : 'in_progress'
    },
    checkpoints() {
      const checkpoints = (this.flowData && this.flowData._checkpoints) || []
      return ((Array.isArray(checkpoints) && checkpoints) || []).filter(notIgnore)
    },
    checkpointNames() {
      return this.checkpoints.map(c => c.split('_').join(' '))
    },
    allCheckpoints() {
      return getCheckpointOptions(this.flow).filter(notIgnore)
    },
  },
}

function notIgnore(c) {
  return !['loaded_flow', 'began_flow', 'completed_flow'].includes(c)
}
</script>

<style lang="scss" scoped>
@import '@/styles/_variables.scss';

.ContactProgressFlow {
}
</style>
