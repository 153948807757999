<template>
  <div class="Billing">
    <!-- <h3>Manage your Billing</h3> -->
    <button class="Button primary" @click="manageBilling">Click here to manage your billing</button>
    <!-- <h3>Add a card</h3>
    <div v-if="hasCard === true">
      Your billing is all set up!
    </div>
    <form action="/charge" method="post" v-else-if="hasCard === false">
      <div class="form-row">
        <div id="card-element">
          <Spinner />
        </div>

        <div id="card-errors" role="alert"></div>
      </div>
    </form>
    <button v-if="hasCard === false" class="Button save-card" id="card-button">
      Save card details
    </button> -->
  </div>
</template>

<script>
/* global Stripe */
import api from '@/helpers/api'

export default {
  name: 'Billing',
  data() {
    return {
      hasCard: null,
    }
  },
  props: {},
  async mounted() {
    // Create a Stripe client.
    const publicKey = process.env.VUE_APP_STRIPE_PUBLIC_KEY
    if (!publicKey) {
      console.warn('No Public Key detected! Stripe features will NOT WORK')
      return
    }

    var stripe = Stripe(publicKey)

    this.hasCard =
      (
        await api('/billing/check-billing-status', {
          email: this.$store.getters.user.auth.email,
        })
      ).data === 'card-on-file'

    if (this.hasCard) return

    // Create an instance of Elements.
    var elements = stripe.elements()

    // Custom styling can be passed to options when creating an Element.
    // (Note that this demo uses a wider set of styles than the guide below.)
    var style = {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    }

    // Create an instance of the card Element.
    var card = elements.create('card', { style: style })

    // Add an instance of the card Element into the `card-element` <div>.

    // var stripeBlock = document.getElementById('stripe-block')
    var errorMessage = document.getElementById('card-errors')

    const res = await api('/billing/create-setup-intent', {
      email: this.$store.getters.user.auth.email,
    })

    card.mount('#card-element')

    const clientSecret = res.data.client_secret

    // stripeBlock.style.height = '210px'
    // stripeBlock.style.opacity = '1'
    // stripeBlock.style.transform = 'scale(1)'
    // stripeBlock.style.marginBottom = '0px'

    var cardButton = document.getElementById('card-button')
    // var cardholderEmail = document.getElementById('cardholder-email');

    const self = this

    cardButton.addEventListener('click', function() {
      cardButton.textContent = 'Saving card...'

      stripe
        .confirmCardSetup(clientSecret, {
          payment_method: {
            card,
            billing_details: {
              //      	name: 'Hardcoded Name',
              //          email: cardholderEmail.value || 'default@email.com',
            },
          },
        })
        .then(function(result) {
          if (result.error) {
            // Display error.message in your UI.
            console.error('Stripe Error:', result.error)
            errorMessage.innerHTML = result.error.message
            cardButton.textContent = 'Try again'
          } else {
            self.hasCard = true
            // The setup has succeeded. Display a success message.
          }
        })
    })
  },
  methods: {
    async manageBilling() {
      const {
        data: {
          success,
          data: { portalSessionUrl },
        },
      } = await api('/billing/create-customer-portal-session')

      if (success) {
        window.open(portalSessionUrl)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/_variables.scss';
.Billing {
  padding: 40px 0;

  .Button {
    margin: 10px 0 20px;
  }
  .Button.save-card {
    margin: 20px auto 0;
  }

  #hero-email-form {
    height: 100px;
    overflow: hidden;
    opacity: 1;
    transition: height 0.3s, opacity 0.3s, transform 0.3s, margin 0.3s;
  }
  #stripe-block {
    max-width: 90vw;
    width: 500px;
    align-self: center;
    /* box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
      0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);*/
    border-radius: 7px;
    padding: 2vw;
    height: 0px;
    overflow: hidden;
    opacity: 0;
    transform: scale(0.01);
    margin-bottom: -100px;
    transition: height 0.3s, opacity 0.3s, transform 0.3s, margin 0.3s;
  }
  #stripe-block input {
    border-radius: 6px;
    margin-bottom: 6px;
    padding: 12px;
    border: 1px solid rgba(50, 50, 93, 0.1);
    height: 44px;
    font-size: 16px;
    width: 100%;
    background: white;
  }
  #stripe-block .result-message {
    line-height: 22px;
    font-size: 16px;
  }
  #stripe-block .result-message a {
    color: rgb(89, 111, 214);
    font-weight: 600;
    text-decoration: none;
  }
  #stripe-block .hidden {
    display: none;
  }
  #stripe-block #card-errors {
    color: #e91e63;
    text-align: center;
    font-size: 16px;
    line-height: 28px;
  }
  #stripe-block #card-element {
    border-radius: 4px 4px 0 0;
    padding: 12px;
    border: 1px solid rgba(50, 50, 93, 0.1);
    height: 44px;
    width: 100%;
    background: white;
  }
  #stripe-block #payment-request-button {
    margin-bottom: 32px;
  }
  /* Buttons and links */
  #stripe-block button {
    background: #5469d4;
    color: #ffffff;
    font-family: Arial, sans-serif;
    border-radius: 0 0 4px 4px;
    border: 0;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    width: 100%;
  }
  #stripe-block button:hover {
    filter: contrast(115%);
  }
  #stripe-block button:disabled {
    opacity: 0.5;
    cursor: default;
  }
  #stripe-block label {
    margin-bottom: 15px;
    line-height: 1.6em;
  }
  /* spinner/processing state, errors */
  .spinner,
  .spinner:before,
  #stripe-block .spinner:after {
    border-radius: 50%;
  }
  #stripe-block .spinner {
    color: #ffffff;
    font-size: 22px;
    text-indent: -99999px;
    margin: 0px auto;
    position: relative;
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 2px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
  .spinner:before,
  #stripe-block .spinner:after {
    position: absolute;
    content: '';
  }
  #stripe-block .spinner:before {
    width: 10.4px;
    height: 20.4px;
    background: #5469d4;
    border-radius: 20.4px 0 0 20.4px;
    top: -0.2px;
    left: -0.2px;
    -webkit-transform-origin: 10.4px 10.2px;
    transform-origin: 10.4px 10.2px;
    -webkit-animation: loading 2s infinite ease 1.5s;
    animation: loading 2s infinite ease 1.5s;
  }
  #stripe-block .spinner:after {
    width: 10.4px;
    height: 10.2px;
    background: #5469d4;
    border-radius: 0 10.2px 10.2px 0;
    top: -0.1px;
    left: 10.2px;
    -webkit-transform-origin: 0px 10.2px;
    transform-origin: 0px 10.2px;
    -webkit-animation: loading 2s infinite ease;
    animation: loading 2s infinite ease;
  }
  @-webkit-keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}
</style>
