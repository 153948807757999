var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"CommentsDisplay flex flex-col items-stretch flex-1 pt-2 overflow-hidden"},[_c('div',{staticClass:"flex flex-row justify-between px-4"},[_c('div',{staticClass:"cursor-pointer mr-1 px-1 pt-1 border-purple",class:{
        'border-b-2': _vm.commentsFilter === 'this_page',
      },on:{"click":function($event){_vm.commentsFilter = 'this_page'}}},[_vm._v(" This Page ("+_vm._s(_vm.threadsFiltered({ page: _vm.currentPageId, resolved: false }).length)+") ")]),_c('div',{staticClass:"cursor-pointer mx-1 px-1 py-1 border-purple",class:{
        'border-b-2': _vm.commentsFilter === 'all_pages',
      },on:{"click":function($event){_vm.commentsFilter = 'all_pages'}}},[_vm._v(" All Pages ("+_vm._s(_vm.threadsFiltered({ resolved: false }).length)+") ")]),_c('div',{staticClass:"cursor-pointer ml-1 px-1 py-1 border-purple",class:{
        'border-b-2': _vm.commentsFilter === 'resolved',
      },on:{"click":function($event){_vm.commentsFilter = 'resolved'}}},[_vm._v(" Resolved ")])]),(_vm.currentTabThreads.length === 0)?_c('div',{staticClass:"flex-1 flex flex-col px-4 justify-center text-center text-gray-400",staticStyle:{"flex-basis":"20rem"}},[(_vm.noAuthComments)?_c('a-empty',{attrs:{"description":"No comments here yet"}}):_c('a-empty',{attrs:{"description":"No unresolved comments - great job! 🚀"}})],1):_c('div',{staticClass:"scroller flex-1 px-4 pt-2"},_vm._l((_vm.currentTabThreads),function(thread){return _c('CommentThread',_vm._b({key:thread.id,attrs:{"replyingToThisThread":_vm.replyingToThreadId === thread.id,"inReplyMode":Boolean(_vm.replyingToThreadId)},on:{"open-page":function($event){return _vm.$emit('open-page', $event)},"menu-click":function($event){return _vm.$emit('menu-click', $event)},"assign":function($event){return _vm.$emit('assign', $event)},"archive":function($event){return _vm.$emit('archive', $event)},"resolve":function($event){return _vm.$emit('resolve', $event)},"toggle-edit":function($event){return _vm.$emit('toggle-edit', _vm.comment)},"set-type":function($event){return _vm.$emit('set-type', $event)}}},'CommentThread',{
        thread: thread,
        commentsFilter: _vm.commentsFilter,
        noAuthComments: _vm.noAuthComments,
        commenterName: _vm.commenterName,
        commenterEmail: _vm.commenterEmail,
        threadId: _vm.threadId,
        users: _vm.users,
        editingComment: _vm.editingComment,
        pages: _vm.pages,
      },false))}),1),_c('div',{staticClass:"flex flex-col mt-4 px-4 items-stretch"},[(_vm.commenterName && _vm.commenterEmail)?[(_vm.useLightweightUIElements)?_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.text),expression:"text"}],ref:"addCommentInput",staticClass:"my-2 add-comment-text",attrs:{"placeholder":"Type your comment here...","disabled":_vm.addingComment,"rows":"6"},domProps:{"value":(_vm.text)},on:{"pressEnter":function($event){$event.preventDefault();return _vm.addComment($event)},"input":function($event){if($event.target.composing){ return; }_vm.text=$event.target.value}}}):_c('a-textarea',{ref:"addCommentInput",staticClass:"my-2",attrs:{"placeholder":_vm.replyingToThread
            ? ("Reply to '" + (_vm.replyingToThread.comments.slice(-1)[0].text.slice(0, 40)) + "'...")
            : "Type your comment here...","disabled":_vm.addingComment,"auto-size":{ minRows: 2, maxRows: 6 }},on:{"pressEnter":function($event){$event.preventDefault();return _vm.addComment($event)}},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}}),_c(_vm.useLightweightUIElements ? 'button' : 'a-button',{tag:"component",staticClass:"my-2 add-comment-button",attrs:{"type":_vm.commentText && _vm.commentText.trim() ? 'primary' : null,"disabled":!_vm.commentText},on:{"click":_vm.addComment}},[_vm._v(" "+_vm._s(_vm.editingComment ? 'Update Comment' : ("Add Comment as " + _vm.commenterName))+" ")])]:[_c('div',{staticClass:"my-2"},[_vm._v("Add a name/email to leave feedback on this Flow:")]),_c(_vm.useLightweightUIElements ? 'input' : 'a-input',{tag:"component",staticClass:"enter-name",staticStyle:{"margin":"1em 0 0.5em"},attrs:{"placeholder":"Your Name"},model:{value:(_vm.newCommenterName),callback:function ($$v) {_vm.newCommenterName=$$v},expression:"newCommenterName"}}),_c(_vm.useLightweightUIElements ? 'input' : 'a-input',{tag:"component",staticClass:"enter-email",attrs:{"placeholder":"Your Email"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey){ return null; }return _vm.startCommenting($event)}},model:{value:(_vm.newCommenterEmail),callback:function ($$v) {_vm.newCommenterEmail=$$v},expression:"newCommenterEmail"}}),_c(_vm.useLightweightUIElements ? 'button' : 'a-button',{tag:"component",staticClass:"my-2 start-commenting-button",attrs:{"type":"primary"},on:{"click":_vm.startCommenting}},[_vm._v(" Start Commenting ")])]],2)])}
var staticRenderFns = []

export { render, staticRenderFns }