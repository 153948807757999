<template>
  <div class="MediaEmbedEditor">
    <a-space direction="vertical">
      <template v-if="location === 'basic_options'">
        <EditorCheckbox _key="silentAutoplayPreview" label="Silent Autoplay Preview" />
        <EditorCheckbox
          v-if="component.silentAutoplayPreview"
          _key="hide_controls"
          label="Hide Video Controls"
        />
        <EditorCheckbox
          v-if="!component.hide_controls || !component.silentAutoplayPreview"
          _key="centralPlayButton"
          label="Central Play Button"
        />
      </template>
      <template v-if="location === 'content'">
        <EditorHeading icon="paragraph" title="Content" />
        <EditorLabel class="mb-0" label="Image / GIF URL" />
        <div class="flex">
          <Uploader @update="onUpdate('src', $event)" />
          <EditorInput
            allowComputed
            class="flex-grow ml-2"
            _key="src"
            placeholder="Video / Embed URL"
            v-bind="{ component }"
          />
        </div>
        <template v-if="component.centralPlayButton">
          <EditorLabel class="mb-0" label="Placeholder Image / GIF URL" />
          <div class="flex">
            <Uploader @update="onUpdate('placeholder_image_url', $event)" />
            <EditorInput
              allowComputed
              class="flex-grow ml-2"
              _key="placeholder_image_url"
              placeholder="Placeholder Video / GIF URL"
              v-bind="{ component }"
            />
          </div>
        </template>
        <EditorInput allowComputed _key="embed_code" v-bind="{ component }" />
        <EditorCode _key="embed_code_raw" />
      </template>
      <!-- <template v-if="location === 'options'">
        <a-collapse>
          <a-collapse-panel key="Deprecated">
            <EditorCheckbox _key="roundedCorners" label="Rounded Corners" />
          </a-collapse-panel>
        </a-collapse>
      </template> -->
    </a-space>
  </div>
</template>

<script>
import Uploader from './helpers/Uploader'
import { unpack } from '@/helpers/computed'

export default {
  name: 'MediaEmbedEditor',
  components: {
    Uploader,
  },
  inject: ['_updateData', '_getBasePath'],
  props: {
    componentId: String,
    component: Object,
    location: String,
  },
  computed: {
    ...unpack('component', ['embed_code']),
  },
  methods: {
    onUpdate(key, val) {
      this._updateData(this._getBasePath(), key, val)
    },
  },
}
</script>
