import { startCase, unionBy } from 'lodash'

/**
 * Searches all Flows for a given pattern.
 * @param {*} forms Flows to search
 * @param {*} searchTerms An object containing search terms. Currently supported: componentType
 * @param {*} config An object containing config options. Allowed Keys: returnMatching
 * @returns An array of Flows that match the params
 */
export default function searchForms(forms, searchTerms, config) {
  if (!forms || forms.length === 0) return []

  return config.returnMatching
    ? forms
        .map(f => ({ matches: searchForm(f, searchTerms, config), flow: f }))
        .filter(f => f.matches.length)
    : forms.filter(f => searchForm(f, searchTerms, config))
}

/**
 * Searches a flow for a given pattern. Must return a boolean value.
 * @param {*} form A Savvy Flow containing parsed JSON string
 * @param {*} searchTerms Key-value pairs of search terms. Currently supported: componentType
 * @param {Object} config An object containing config options. Allowed Keys: returnMatching
 * @returns an array of matches if config.returnMatching = true, or a boolean if not
 */
function searchForm(form, searchTerms, config) {
  const { returnMatching } = config || {}

  const matches = Object.keys(searchTerms).reduce((acc, k) => {
    acc[k] = []
    return acc
  }, {})

  Object.entries(matches).forEach(([key, match]) => {
    switch (key) {
      case 'componentType': {
        findComponentType(form, searchTerms[key], match)
        break
      }

      case 'dataOutput': {
        findDataOutput(form, searchTerms[key], match)
        break
      }

      default:
        break
    }
  })

  const finalMatches = unionBy(Object.values(matches), match => match.location)

  return returnMatching ? finalMatches[0] : finalMatches.length > 0
}

function findDataOutput(form, terms, matches) {
  if (Array.isArray(form.dataOutputs)) {
    form.dataOutputs.forEach((e, i) => {
      let isMatch
      switch (terms) {
        case 'hubspot': {
          isMatch = e.output === 'hubspot'
          break
        }
        case 'hubspot-list': {
          isMatch = e.output === 'hubspot' && e.listId
          break
        }
        case 'hubspot-form': {
          isMatch = e.output === 'hubspot' && e.formId && e.portalId
          break
        }

        default:
          break
      }
      if (isMatch)
        matches.push({
          value: e,
          location: `form.dataOutputs.${i}`,
          locationReadable: `Data Output ${e.output} (i=${i})`,
        })
    })
  }
}

function findComponentType(form, componentType, matches) {
  if (Array.isArray(form.components)) {
    form.components.forEach((c, i) => {
      if (c.type === componentType)
        matches.push({
          value: c,
          location: `form.components.${i}`,
          locationReadable: `Global Component ${i}`,
        })
    })
  }

  if (Array.isArray(form.pages)) {
    form.pages.forEach((p, i) => {
      if (Array.isArray(p.components)) {
        p.components.forEach((c, j) => {
          if (c.type === componentType)
            matches.push({
              value: c,
              location: `form.pages.${i}.components.${j}`,
              locationReadable: `Page ${i} Component ${j}`,
            })
        })
      }
    })
  }
}

/* type IndexedFlowData = {key: string, type: string, id: string, label: string} */

export function createSearchableFlow(flow) {
  if (!flow) return []
  const createType = (type, object) => {
    const fields = {
      key: object.key,
      id: object.id,
      text: object.text,
      label: object.label,
      placeholder: object.placeholder,
    }
    const label = `${startCase(type)}: ${object.key || object.id}`
    return { label, type, key: object.key, id: object.id, fields }
  }

  const components = Array.isArray(flow.components)
    ? flow.components.map(c => createType('global_component', c))
    : []

  const computedFields = Array.isArray(flow.computedFields)
    ? flow.computedFields.map(c => createType('computed_field', c))
    : []

  const experiments = Array.isArray(flow.experiments)
    ? flow.experiments.map(c => createType('experiment', c))
    : []

  const pageComponents = []
  const pages = []

  if (Array.isArray(flow.pages)) {
    flow.pages.forEach(p => {
      const typeData = createType('page', p)

      if (Array.isArray(p.components)) {
        p.components.forEach(c => {
          const componentTypeData = createType('component', c)
          pageComponents.push({ ...componentTypeData, parentPageKey: p.key, parentPageId: p.id })
        })
      }
      return typeData
    })
  }

  return [...pages, ...components, ...computedFields, ...experiments, ...pageComponents]
}
