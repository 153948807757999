var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.name1 && _vm.name1.indexOf(':') > -1 && typeof _vm.nameCalc === 'string')?_c('IconifyIcon',{staticClass:"transform",class:[
    _vm.loading,
    _vm.rotate ? (_vm.rotate.startsWith('-') ? ("-rotate-" + (_vm.rotate.slice(1))) : ("rotate-" + _vm.rotate)) : '',
    {
      spin: _vm.spinCalc,
    } ],attrs:{"icon":_vm.name1}}):(!_vm.useFeather)?_c('FAIcon',{staticClass:"Icon",class:{
    spin: _vm.spinCalc,
    large: _vm.loading,
  },attrs:{"icon":_vm.nameCalc},on:{"click":_vm.click}}):(_vm.featherComponentName)?_c(_vm.featherComponentName,{tag:"component",staticClass:"Icon",style:({ color: _vm.color }),on:{"click":_vm.click}}):_c('div')}
var staticRenderFns = []

export { render, staticRenderFns }