<template>
  <div class="Alert" :class="[alertType, { miniMode, initializing, closing }]">
    <div class="alert" placement="top" width="400px">
      <strong>
        <div>
          <Icon v-if="iconName" :name="iconName" />
          {{ alert.message }}
        </div>
        <div v-if="canUndo" class="undo-button" :class="{ error }" @click="undo">
          <Icon name="undo" style="margin: 0px;" />
          {{ error || alert.undoText || 'Click to Undo' }}
        </div>
        <div v-if="Boolean(promptData)">
          <component :is="promptData.component" v-bind="promptData.props" @connected="dismiss" />
        </div>
      </strong>
      <a class="close" @click="dismiss">
        <Icon name="times" />
      </a>
    </div>
    <div v-if="canUndo" class="progress-bar">
      <div :style="{ width: widthPercent }">&nbsp;</div>
    </div>
  </div>
</template>

<script>
// const alert = {
//   id: String,
//   type: String,
//   message: String,
//   duration: Number,
//   undoFn: Function,
//   undoText: String,
//   promptData: {component: 'Source', props: props of the component}
// }
export default {
  components: {},
  props: { alert: Object },
  data() {
    return {
      initializing: true,
      closing: false,
      timer: null,
      tick: null,
      tickCount: 0,
      error: false,
    }
  },
  computed: {
    miniMode() {
      return this.$store.state.isInElectron
    },
    alertType() {
      return this.alert.type
    },
    promptData() {
      if (this.alertType !== 'prompt') return null
      // const sample = {component: 'Source', props: {source: 'gmail'}}
      return this.alert.promptData
    },
    canUndo() {
      return Boolean(this.alert.undoFn)
    },
    iconName() {
      return (
        {
          success: 'check',
          error: 'exclamation-triangle',
          prompt: 'exclamation-triangle',
        }[this.alertType] || null
      )
    },
    duration() {
      if (this.alert.type === 'error') return this.alert.duration || 6000

      return this.alert.duration || 3000
    },
    widthPercent() {
      const val = (this.duration - this.tickCount) / this.duration
      const percent = Math.round(val * 100)
      return `${percent}%`
    },
  },
  mounted() {
    setTimeout(() => {
      this.initializing = false
      this.$nextTick(() => {
        this.tickCount = 1000
      })
      if (this.alertType !== 'prompt') this.setUpTimers()
    }, 50)
  },
  beforeDestroy() {
    this.clearTimers()
  },
  methods: {
    setUpTimers() {
      const self = this
      this.tick = setInterval(() => {
        self.tickCount += 1000
      }, 1000)
      this.timer = setTimeout(() => {
        self.dismiss()
      }, self.duration)
    },
    clearTimers() {
      this.tick = clearInterval(this.tick) || null
      this.timer = clearTimeout(this.timer) || null
    },
    dismiss() {
      this.closing = true
      setTimeout(() => {
        this.clearTimers()
        this.$store.commit('closeAlert', this.alert.id)
      }, 200)
    },
    async undo() {
      try {
        if (!this.canUndo) return
        await this.alert.undoFn()
        this.dismiss()
        // this.$store.dispatch('alertSuccess', 'Undo Successful!')
      } catch (error) {
        this.clearTimers()
        this.error = error.message
      }
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/_variables.scss';

.Alert {
  overflow: hidden;
  margin: 10px;
  /* padding: 10px 30px 0px 30px; */
  margin-bottom: 20px;
  min-width: 150px;
  border: 1px solid transparent;
  border-radius: 4px;
  transition: transform 0.5s, opacity 0.5s;

  &.miniMode {
    margin: 4px;
    min-width: 100px;

    .alert {
      padding: 2px 5px;
      font-size: 0.9em;

      strong {
        margin-right: 10px;

        > div {
          @include flex('center');
        }
      }

      svg {
        margin: 5px 10px;
      }

      a.close {
        margin: 0;
        margin-right: 2px;
        margin-left: 5px;
        padding: 5px;
      }
    }
  }

  .progress-bar {
    width: 100%;
    height: 5px;

    > div {
      height: 100%;
      background: $savvy;
      transition: width 1s linear;
    }
  }

  &.error {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }
  &.success {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;
  }
  &.info {
    color: #3c6b79;
    background-color: #98e7ff;
    border-color: #5997c0;
  }
  &.prompt {
    color: #606939;
    background-color: #eef0d8;
    border-color: #e0e9c6;
  }
  &.initializing {
    transform: translateY(1.5rem);
    opacity: 0;
  }
  &.closing {
    transform: scale(0.1);
    opacity: 0;
    margin-top: -68px;
    transition: transform 0.3s, opacity 0.3s, margin 0.3s;
  }

  .alert {
    text-align: center;
    opacity: 0.9;
    display: flex;
    align-items: center;
    padding: 5px 30px 5px 30px;

    > strong,
    > a.close {
      display: flex;
      align-items: center;
    }
    strong {
      flex: 1;
      text-align: left;
      flex-direction: column;
      align-items: flex-start;
    }
    svg {
      margin-right: 15px;
    }

    a.close {
      margin: 0;
      margin-right: -20px;
      margin-left: 10px;
      padding: 10px;
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }

      svg {
        margin: 0;
      }
    }

    .undo-button {
      margin-top: 10px;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
      &.error {
        color: $fail;
      }
    }
  }
}
</style>
