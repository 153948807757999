import Firebase from 'firebase/app'
import 'firebase/firestore'

export default {
  inject: ['_isAdmin'],
  props: {
    accessType: {
      type: String,
      validator: value =>
        new Set(['access', 'group', 'owner-group', 'readonly', 'public']).has(value),
    },
    collection: String,
    groupId: String,
    value: [Array, Object],
    filters: Array,
    renderless: Boolean,
    sortBy: {
      type: [Array, String],
      validator: value =>
        typeof value === 'string' || (Array.isArray(value) && typeof value[0] === 'string'),
    },
    limit: Number,
    smooth: Boolean,
  },
  data() {
    return {
      rawData: [],
      loading: false,
    }
  },
  computed: {
    data() {
      return Array.isArray(this.rawData)
        ? this.rawData.map(item => ({ id: item.id, ...item }))
        : this.rawData
    },
    isAdmin() {
      return this._isAdmin()
    },
    adminModeActive() {
      return Boolean(this.user && this.user.adminModeActive)
    },
    userId() {
      return this.user && this.user.uid
    },
    user() {
      return this.$store ? this.$store.getters.user : null
    },
    groupIdInUse() {
      return this.groupId || (this.$store ? this.$store.getters.activeGroupId : null)
    },
  },
  watch: {
    data: {
      handler(data) {
        if (!this.smooth || this.loading === false) {
          this.$emit('input', data)
          this.$emit('loading', false)
          this.$emit('loaded')
          this.loading = false
        }
      },
      deep: true,
      immediate: true,
    },
    filters: {
      handler() {
        this.bind()
      },
      deep: true,
    },
    limit() {
      this.bind()
    },
    renderless() {
      this.bind()
    },
    accessType() {
      this.bind()
    },
    collection() {
      this.bind()
    },
    isAdmin() {
      this.bind()
    },
    adminModeActive() {
      this.bind()
    },
    userId() {
      this.bind()
    },
    groupIdInUse() {
      this.bind()
    },
    sortBy() {
      this.bind()
    },
  },
  mounted() {
    this.bind()
  },
  beforeDestroy() {
    this.rawData = []
    this.$emit('input', this.data)
  },
  methods: {
    bind() {
      if (!this.collection) {
        this.rawData = []
        return
      }

      this.bindData()
    },
    async bindData() {
      try {
        let ref = Firebase.firestore().collection(this.collection)

        switch (this.accessType) {
          case 'access': {
            if (!(this.isAdmin && this.adminModeActive)) {
              if (!this.groupIdInUse || !this.userId) return

              ref = ref.where('_read_access', 'array-contains-any', [
                this.userId,
                this.groupIdInUse,
              ])
            }
            break
          }
          case 'group': {
            if (!(this.isAdmin && this.adminModeActive)) {
              if (!this.groupIdInUse) return

              ref = ref.where('groupId', '==', this.groupIdInUse)
            }
            break
          }
          case 'owner-group': {
            if (!(this.isAdmin && this.adminModeActive)) {
              if (!this.groupIdInUse) return

              ref = ref.where('ownerGroupId', '==', this.groupIdInUse)
            }
            break
          }
        }

        if (this.filters) {
          this.filters.forEach(filter => {
            ref = ref.where(filter[0], filter[1], filter[2])
          })
        }

        if (this.sortBy) {
          if (Array.isArray(this.sortBy)) {
            const [key, direction] = this.sortBy
            ref = direction ? ref.orderBy(key, direction) : ref.orderBy(key)
          } else {
            ref = ref.orderBy(this.sortBy)
          }
        }

        if (this.limit) {
          ref = ref.limit(this.limit)
        }

        this.loading = true
        this.$emit('loading', true)
        await this.$bind('rawData', ref)

        if (this.smooth) {
          this.$emit('input', this.rawData)
          this.$emit('loading', false)
          this.$emit('loaded')
          this.loading = false
        }
      } catch (error) {
        console.error('Props to you', JSON.stringify(this.$props, null, 2))
        this.onErrorHandler(error)
      }
    },
    onErrorHandler(error) {
      console.error(
        `Entities Error for /${this.collection} with Admin: ${this.isAdmin}: `,
        error.message
      )
      console.error(`GroupId: ${this.groupIdInUse}, Admin: ${this.isAdmin}`, this.$props)

      // this.rawData = []
      // throw new Error('Catch me if you can')
    },
  },
  render() {
    if (this.renderless) return null
    if (this.data && this.data._error) {
      if (this.$scopedSlots.error) return this.$scopedSlots.error({ error: this.data })
      else {
        return (
          <div class={`entity-error ${this.collection}`}>
            You do not have permission to view these {this.collection}.
          </div>
        )
      }
    }

    const content = this.data.map(d => {
      return this.$scopedSlots.default
        ? this.$scopedSlots.default({ data: d })
        : `${d.title || d.name} (${d.id})`
    })

    return <div class="Entities">{content}</div>
  },
}

/* 
Render function should turn roughly into the equivalent of this:
<template>
  <div class="Entities">
    <template v-if="this.data && this.data._error"> </template>
    <template v-else-if="!renderless">
      <div class="item" v-for="item in data" :key="item.id">
        <slot v-bind:item="item" name="item">
          <!-- Fallback content -->
          {{ item.title || item.name }} ({{ item.id }})
        </slot>
      </div>
    </template>
  </div>
</template>
*/
