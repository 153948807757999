<template>
  <CommentsDisplay
    v-bind="{
      currentPageId,
      pages,
      noAuthComments,
      comments,
      addingComment,
      editingComment,
      version,
      insideEditor,
      commentText,
      commenterName,
      defaultAssignee: defaultAssignTo,
      commenterEmail,
      replyingToThreadId,
    }"
    @add="addComment"
    @assign="assignTo(...$event)"
    @archive="archiveComment($event)"
    @menu-click="onMenuClick(...$event)"
    @open-page="openPage"
    @toggle-edit="toggleEdit"
    @set-type="setType(...$event)"
    @set-commenter-details="startCommenting"
    @resolve="resolveComment(...$event)"
    @text="commentText = $event"
    @default-assignee="defaultAssignee = $event"
  />
</template>

<script>
import LogRocket from 'logrocket'
import { H } from 'highlight.run'

import confetti from 'canvas-confetti'
import Firebase from 'firebase/app'
import 'firebase/firestore'
import { mapState } from 'vuex'
import notifySlack from '../../../../helpers/notify'
import api from '@/helpers/api'
import CommentsDisplay from './CommentsDisplay.vue'
import getSlackUsers, { getSlackUser } from './helpers/slackUsers'
const SlackChannel = 'C02GS2AQM09'

export default {
  components: { CommentsDisplay },
  name: 'CommentEditor',
  inject: {
    _setUserData: { default: () => () => {} },
    _isStandalone: { default: () => () => false },
  },
  props: {
    id: String,
    form: Object,
    currentPageId: String,
    pages: Array,
    noAuthComments: Boolean,
    insideEditor: Boolean,
    userData: Object,
    comments: Array,
    flowGroupId: String,
  },
  data() {
    return {
      commentText: '',
      commenterName: '',
      commenterEmail: '',
      newCommenterName: '',
      newCommenterEmail: '',
      addingComment: false,
      editingComment: false,
      groupTitle: null,
      defaultAssignee: '',
      replyingToThreadId: null,
    }
  },
  computed: {
    ...mapState(['versionData']),
    formTitle() {
      const formTitle =
        (this.form && ((this.form._meta && this.form._meta.title) || this.form.title)) ||
        `Untitled Flow (${this.id})`
      return this.groupTitle ? `${formTitle} (${this.groupTitle})` : `${formTitle}`
    },
    users() {
      /* Slack Channel: C02GS2AQM09 */
      return getSlackUsers()
    },
    allowedToAssign() {
      const userByEmail = this.users.find(u => u.email === this.commenterEmail)
      const userById = this.users.find(a => a.uid === this.$store.getters.userId)
      return Boolean(userById || userByEmail)
    },
    defaultAssignTo() {
      return ''
      // if (!this.allowedToAssign) return ''
      // if (this.defaultAssignee) return this.defaultAssignee
      // if (this.$store.state.lastAssignedTo) return this.$store.state.lastAssignedTo
      // const comments = [...this.comments]
      // comments.sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime())
      // const mostRecentComment = comments[0] //.find(c => c.assignee && c.assignee.uid && c.assignee.uid !== this.$store.getters.userId)
      // if (mostRecentComment) {
      //   const uid = mostRecentComment.assignee && mostRecentComment.assignee.uid
      //   if (uid && uid !== this.$store.getters.userId) return uid
      // }
      // return ''
    },
    version() {
      return this.versionData && this.versionData.version
    },
  },
  async mounted() {
    const user = this.$store && this.$store.getters && this.$store.getters.user
    const userAuthObj = (user && user.auth) || {}
    const name = userAuthObj.displayName || localStorage.getItem('SavvyCommenterName')
    const email = userAuthObj.email || localStorage.getItem('SavvyCommenterEmail')
    this.startCommenting({ name, email })

    const { data } = await api(`/public/groups/${this.flowGroupId}`)
    if (data && data.title) this.groupTitle = data.title
  },
  watch: {
    commenterName: {
      handler(v) {
        if (v) localStorage.setItem('SavvyCommenterName', v)
      },
      immediate: true,
    },
    commenterEmail: {
      handler(v) {
        if (v) localStorage.setItem('SavvyCommenterEmail', v)
      },
      immediate: true,
    },
  },
  methods: {
    identify() {
      const email = this.commenterEmail
      const name = this.commenterName
      if (email && name && this._isStandalone()) {
        LogRocket.identify(email || name, { name: name, email: email })
        H.identify(email || name, { name, email, id: email })
      }
    },
    startCommenting({ name, email }) {
      this.commenterName = name
      this.commenterEmail = email
      this.identify()
    },
    async resolveComment(event, comment, unresolve) {
      this.replyingToThreadId = null

      await Firebase.firestore()
        .collection('forms')
        .doc(this.id)
        .collection('comments')
        .doc(comment.id)
        .update({ resolved: unresolve ? false : true })

      const isAssignedToSelf =
        comment.assigner && comment.assignee && comment.assigner.uid === comment.assignee.uid

      try {
        const formTitle = await this.getFlowTitle()
        const assigner = comment.assigner
          ? isAssignedToSelf
            ? `${comment.assigner.name}`
            : `<@${comment.assigner.slackId}>`
          : 'Anonymous'
        const message = `✅ ${this.commenterName} ${
          unresolve ? 'un-' : ''
        }resolved a comment assigned by ${assigner} in \`${formTitle}\` (Group: ${
          this.groupTitle
        } \n> ~"${comment.text}"~`
        await notifySlack(message, SlackChannel, true)
      } catch (error) {
        console.error(error)
      }

      function randomInRange(min, max) {
        return Math.random() * (max - min) + min
      }

      confetti({
        angle: randomInRange(80, 180),
        spread: randomInRange(50, 70),
        particleCount: randomInRange(100, 150),
        origin: { x: event.clientX / window.innerWidth, y: event.clientY / window.innerHeight },
        zIndex: 99999,
      })
    },
    async addComment() {
      if (!this.commentText || !this.commentText.trim()) return

      let commentData = this.editingComment
        ? { text: this.commentText, edited: true }
        : {
            location: {
              page: this.currentPageId || (this.pages[0] && this.pages[0].id) || null,
              groupId: this.flowGroupId,
              flowId: this.id,
            },
            author: { name: this.commenterName || null, email: this.commenterEmail || null },
            threadId: this.replyingToThreadId || 0,
            version: this.version,
            resolved: false,
            archived: false,
            text: this.commentText,
            timestamp: new Date().toISOString(),
          }

      const commentsCollection = Firebase.firestore()
        .collection('forms')
        .doc(this.id)
        .collection('comments')

      this.addingComment = true
      let commentId = this.editingComment
      if (this.editingComment) {
        await commentsCollection.doc(this.editingComment).update(commentData)
      } else {
        const newRef = await commentsCollection.add(commentData)
        commentId = newRef.id
      }
      if (commentData.author) {
        const user = this.users.find(
          u =>
            u.email === commentData.author.email ||
            (this.$store.getters.userId && u.uid === this.$store.getters.userId)
        )
        if (!user) {
          const flowTitle = await this.getFlowTitle()
          try {
            const message = `${this.commenterName} (${
              this.commenterEmail
            }) made a comment in \`${flowTitle}\` (Group: ${this.groupTitle}) - cc <@${
              getSlackUser('estefania').slackId
            }> \n> "${commentData.text}"`
            await notifySlack(message, SlackChannel, true)
          } catch (error) {
            console.error(error)
          }
        }
      }
      this.commentText = ''
      this.addingComment = false
      this.editingComment = false
      if (this.defaultAssignTo && this.defaultAssignTo !== '__none' && this.allowedToAssign) {
        const userToAssignTo = this.users.find(u => u.uid === this.defaultAssignTo)
        if (userToAssignTo) {
          this.assignTo({ ...commentData, id: commentId }, userToAssignTo)
        }
      }

      await this.$nextTick()

      document.querySelector(`#comment-${commentId}`).scrollIntoView({
        behavior: 'smooth',
      })
    },
    toggleEdit(comment) {
      this.replyingToThreadId = null

      if (this.editingComment) {
        this.editingComment = false
        this.commentText = ''
      } else {
        this.editingComment = comment.id
        this.commentText = comment.text
      }
    },
    async archiveComment(id) {
      await Firebase.firestore()
        .collection('forms')
        .doc(this.id)
        .collection('comments')
        .doc(id)
        .update({ archived: true })

      this.replyingToThreadId = null
    },
    async getFlowTitle() {
      try {
        const flowDoc = await Firebase.firestore()
          .collection('forms')
          .doc(this.id)
          .get()
        const formTitle =
          flowDoc.get('title') ||
          (this.form && ((this.form._meta && this.form._meta.title) || this.form.title))
        return this.groupTitle ? `${formTitle} (${this.groupTitle})` : `${formTitle}`
      } catch (error) {
        console.error(error)
        return this.formTitle
      }
    },
    openPage(pageId) {
      this._setUserData({ ...this.userData, currentPageId: pageId })
    },
    async assignTo(comment, assignee) {
      let assigner = this.users.find(a => a.uid === this.$store.getters.userId)
      if (!this.$store.getters.userId || !assigner)
        assigner = this.users.find(u => u.email === this.commenterEmail)

      if (!this.allowedToAssign) {
        this.$message.error(`You don't have permission to assign comments`)
        return
      }

      const assigneeHistory = [...(comment.assignee_history || [])].filter(a => a)
      if (comment.assignee) assigneeHistory.push(comment.assignee)
      const prevAssignee = assigneeHistory.slice(-1)[0] || null
      const isSameAssignee = assignee && prevAssignee && assignee.uid === prevAssignee.uid

      await Firebase.firestore()
        .collection('forms')
        .doc(this.id)
        .collection('comments')
        .doc(comment.id)
        .update({ assignee, assigner, assignee_history: assigneeHistory })

      const assignedToSelf = assignee && assigner && assignee.uid === assigner.uid
      const verb = assignee ? (assigneeHistory.length ? `re-assigned` : 'assigned') : 'unassigned'
      const preposition = assignee ? (isSameAssignee ? 'back to' : 'to') : 'from'
      const historyContext =
        assignee && prevAssignee && !isSameAssignee ? ` (from ${prevAssignee.name})` : ''
      if (this.$store && this.$store.commit && assignee)
        this.$store.commit('setLastAssignedTo', assignee.uid)
      if (assignee && assigner && assignee.uid === assigner.uid && assigneeHistory.length === 0)
        return
      if (assignedToSelf) return
      const taggedUser = assignee || prevAssignee
      const userString = assignedToSelf ? taggedUser.name : `<@${taggedUser.slackId}>`
      try {
        const formTitle = await this.getFlowTitle()
        const message = `${assigner.name} ${verb} a comment ${preposition} ${userString}${historyContext} in \`${formTitle}\` \n> "${comment.text}"`

        await notifySlack(message, SlackChannel, true)
      } catch (error) {
        console.error(`Error sending Slack notification`, error)
      }
    },
    async postToChannel(e, comment) {
      const formTitle = await this.getFlowTitle()
      const message = `${this.commenterName} brought ${(
        comment.author.name ||
        comment.author.email ||
        'Anonymous'
      ).trim()}'s comment into this channel to start a conversation: \n\n> "${
        comment.text
      }" \n\nFlow: \`${formTitle}\``
      const slackChannel = window.prompt(
        `What's the Slack channel ID? (Make sure you've added the Savvy Internal app to that channel)`
      )
      await notifySlack(message, slackChannel, true)
    },
    reply(comment) {
      this.replyingToThreadId = comment && (comment.threadId || comment.id)
    },
    setType(comment, type) {
      console.log('qwer comment, type', comment, type)
      Firebase.firestore()
        .collection('forms')
        .doc(this.id)
        .collection('comments')
        .doc(comment.id)
        .update({ type })
    },
    onMenuClick(key, comment) {
      switch (key) {
        case 'reply':
          return this.reply(comment)
        case 'edit':
          return this.toggleEdit(comment)
        case 'post_to_channel':
          return this.postToChannel(event.domEvent, comment)
        case 'resolve':
          return this.resolveComment(event.domEvent, comment)
        case 'unresolve':
          return this.resolveComment(event.domEvent, comment, true)
        case 'archive':
          return this.archiveComment(comment.id)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.CommentEditor {
  width: 300px;
}
</style>
