<template>
  <div class="InputBoxEditor">
    <a-space direction="vertical">
      <!-- <EditorInput monospaced _key="zip_code_key" v-bind="{ component }" /> -->
      <EditorIcon _key="icon" v-bind="{ component }" />
      <EditorInput _key="label" v-bind="{ component }" />
      <EditorInput _key="placeholder" v-bind="{ component }" />
      <EditorCheckbox _key="us_only" label="US Only" />
    </a-space>
  </div>
</template>

<script>
export default {
  name: 'InputBoxEditor',
  props: {
    componentId: String,
    component: Object,
  },
}
</script>
