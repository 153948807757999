<template>
  <div class="RegisterForMeetingEditor">
    <EditorSelect _key="source" :options="sourceOptions" />
    <template v-if="source === 'zoom'">
      <EditorInput _key="meeting_id" :component="component" />
      <a-divider />

      <a-collapse>
        <a-collapse-panel header="Registrant Details">
          <EditorInput allowComputed _key="email_key" :component="component" />
          <EditorInput allowComputed _key="name_key" :component="component" />
          <EditorInput allowComputed _key="address_key" :component="component" />
          <EditorInput allowComputed _key="city_key" :component="component" />
          <EditorInput allowComputed _key="country_key" :component="component" />
          <EditorInput allowComputed _key="zip_key" :component="component" />
          <EditorInput allowComputed _key="state_key" :component="component" />
          <EditorInput allowComputed _key="phone_key" :component="component" />
        </a-collapse-panel>
      </a-collapse>
    </template>
  </div>
</template>
<script>
import { unpackToCamel } from '@/helpers/computed'
export default {
  name: 'RegisterForMeetingEditor',
  props: { componentId: String, component: Object },
  computed: {
    ...unpackToCamel('component', ['source']),
    sourceOptions() {
      return ['zoom']
    },
  },
  watch: {},
  methods: {},
}
</script>
