import { cloneDeep, isEqual } from 'lodash'
import idGen from '@/helpers/idGen'

export function replaceDuplicateIds(form) {
  const clone = cloneDeep(form)
  const checkId = (c, seen) => {
    if (seen.has(c.id)) c.id = idGen()
    else seen.add(c.id)
  }
  if (Array.isArray(clone.components)) {
    const seenGlobalIds = new Set()
    clone.components.forEach(c => checkId(c, seenGlobalIds))
  }
  if (Array.isArray(clone.pages)) {
    const seenPageIds = new Set()
    const seenComponentIds = new Set()
    const seenButtonIds = new Set()
    clone.pages.forEach(p => {
      checkId(p, seenPageIds)
      if (Array.isArray(p.components)) {
        p.components.forEach(c => {
          checkId(c, seenComponentIds)
          if (Array.isArray(c.buttons)) {
            c.buttons.forEach(b => checkId(b, seenButtonIds))
          }
        })
      }
    })
  }
  if (Array.isArray(clone.dataOutputs)) {
    const seenDataOutputs = new Set()
    clone.dataOutputs.forEach(c => checkId(c, seenDataOutputs))
  }
  if (Array.isArray(clone.computedFields)) {
    const seenComputedFields = new Set()
    clone.computedFields.forEach(c => checkId(c, seenComputedFields))
  }
  if (Array.isArray(clone.experiments)) {
    const seenExperiments = new Set()
    clone.experiments.forEach(c => checkId(c, seenExperiments))
  }
  return clone
}

/* 
type Change = { key: string, id: string, index: number, status: 'deleted'|'new'|'changed' }
*/
export function diffForms(firstForm, secondForm) {
  const changes = {
    styles: [],
    pages: [],
    computedFields: [],
    experiments: [],
    dataOutputs: [],
    components: [],
    flow: [],
  }

  const addChanges = (key, typeLabel) => {
    diffArrayChanges(firstForm[key], secondForm[key], 'id', [], changes[key], { typeLabel })
  }

  addChanges('computedFields', 'Computed Field')
  addChanges('experiments', 'Experiment')
  addChanges('dataOutputs', 'Data Output')
  addChanges('components', 'Global Components')
  // addChanges('pages')
  if (Array.isArray(firstForm.pages) && Array.isArray(secondForm.pages)) {
    const secondMinifiedPages = secondForm.pages.map(p => minifyObject(p, ['components']))

    const oldPages = new Set(firstForm.pages.map(p => p.id))
    const newPages = secondMinifiedPages.filter(s => !oldPages.has(s.id))
    newPages.forEach((p, i) => {
      const diff = diffObjs(null, p, -1, i, { typeLabel: 'Page' })
      if (diff) changes.pages.push(diff)
    })
    firstForm.pages.forEach((p, i) => {
      const i2 = secondForm.pages.findIndex(cf2 => cf2.id === p.id)
      const item = secondForm.pages[i2]
      const diff = diffObjs(minifyObject(p), item ? minifyObject(item) : null, i, i2, {
        typeLabel: 'Page',
      })
      if (diff) {
        if (item) {
          const changes = []
          diffObjectChanges(p, item, [], changes)
          diff.changes = changes.filter(c => c.key !== 'components')

          const componentChanges = []
          diffArrayChanges(p.components, item.components, 'id', [], componentChanges, {
            typeLabel: 'Component',
          })
          if (componentChanges.length) diff.componentChanges = componentChanges
        }

        changes.pages.push(diff)
      }
    })
  }

  const firstFormStyles = (firstForm && firstForm.styles) || {}
  const secondFormStyles = (secondForm && secondForm.styles) || {}
  const existingStyles = new Set(Object.keys(firstFormStyles))
  const newStyles = Object.keys(secondFormStyles).filter(s => !existingStyles.has(s))
  const styleCb = s => {
    const diff = diffObjs(
      { ...(firstForm.styles && firstForm.styles[s]), key: s, id: s, __isGeneratedObj: true },
      { ...(secondForm.styles && secondForm.styles[s]), key: s, id: s, __isGeneratedObj: true }
    )
    if (diff) {
      const diffchange = []
      diffObjectChanges(
        firstForm.styles && firstForm.styles[s],
        secondForm.styles && secondForm.styles[s],
        [],
        diffchange,
        undefined,
        undefined,
        { typeLabel: 'Styles' }
      )
      if (diffchange.length) diff.changes = diffchange
      changes.styles.push(diff)
    }
  }
  newStyles.forEach(styleCb)
  Object.keys(firstFormStyles).forEach(styleCb)

  // diffObjectChanges(firstForm.styles, secondForm.styles, [], changes.styles)
  diffObjectChanges(firstForm, secondForm, Object.keys(changes), changes.flow)
  return changes
}

function diffObjs(obj1, obj2, i1, i2, extraChangeTags) {
  if (isEqual(obj1, obj2)) return null
  else {
    const exists = o => ![undefined, null].includes(o)
    if (exists(obj1) && exists(obj2)) {
      const objectisGen = obj1.__isGeneratedObj && obj2.__isGeneratedObj
      const { key, id } = obj2
      const diff = { key, id, status: 'changed', indexes: [i1, i2], moved: i1 !== i2 }
      if (extraChangeTags) Object.entries(extraChangeTags).forEach(([k, v]) => (diff[k] = v))
      if (objectisGen) diff.values = [obj1.value, obj2.value]
      return diff
    } else if (!exists(obj1)) {
      const objectisGen = obj2.__isGeneratedObj
      const diff = { key: obj2.key, id: obj2.id, status: 'new', indexes: [-1, i2] }
      if (extraChangeTags) Object.entries(extraChangeTags).forEach(([k, v]) => (diff[k] = v))
      if (objectisGen) diff.values = [undefined, obj2.value]
      return diff
    } else {
      const objectisGen = obj1.__isGeneratedObj
      const diff = { key: obj1.key, id: obj1.id, status: 'deleted', indexes: [i1, -1] }
      if (extraChangeTags) Object.entries(extraChangeTags).forEach(([k, v]) => (diff[k] = v))
      if (objectisGen) diff.values = [obj1.value, undefined]
      return diff
    }
  }
}

function diffArrayChanges(
  firstArray,
  secondArray,
  idKey,
  stripKeys,
  arrDestination,
  extraChangeTags,
  objectChangeTags
) {
  const arr1 = firstArray || []
  const arr2 = secondArray || []
  const oldArr = new Set(arr1.map(p => p[idKey]))
  const newArr = arr2.filter(s => !oldArr.has(s[idKey]))
  newArr.forEach(p => {
    const diff = diffObjs(
      null,
      p,
      -1,
      arr2.findIndex(pg => pg.id === p.id),
      extraChangeTags
    )
    if (diff) arrDestination.push(diff)
  })
  arr1.forEach((p, i) => {
    const i2 = arr2.findIndex(cf2 => cf2.id === p.id)
    const item = arr2[i2]
    const diff = diffObjs(
      minifyObject(p, stripKeys),
      item ? minifyObject(item, stripKeys) : null,
      i,
      i2,
      extraChangeTags
    )
    if (diff) {
      const changes = []
      diffObjectChanges(p, item, stripKeys, changes, i, i2, objectChangeTags)
      diff.changes = changes
      arrDestination.push(diff)
    }
  })
}

function diffObjectChanges(
  firstObj,
  secondObj,
  stripKeys,
  arrDestination,
  i1,
  i2,
  extraChangeTags
) {
  const oldObj = minifyObject(firstObj, stripKeys) || {}
  const newObj = minifyObject(secondObj, stripKeys) || {}
  const oldObjProperties = new Set(Object.keys(oldObj))
  const newObjProperties = Object.keys(newObj).filter(s => !oldObjProperties.has(s))
  const exists = o => ![undefined, null].includes(o)
  const objCb = k => {
    const diff = diffObjs(
      exists(oldObj[k]) ? { value: oldObj[k], key: k, id: k, __isGeneratedObj: true } : null,
      exists(newObj[k]) ? { value: newObj[k], key: k, id: k, __isGeneratedObj: true } : null,
      i1,
      i2,
      extraChangeTags
    )
    if (diff) arrDestination.push(diff)
  }
  newObjProperties.forEach(objCb)
  Object.keys(oldObj).forEach(objCb)
}

function minifyObject(obj, remove = []) {
  if (!obj) return {}
  const mini = Object.entries(obj)
    .filter(([k]) => !remove.includes(k))
    .reduce((acc, [k, v]) => {
      acc[k] = v
      return acc
    }, {})
  return mini
}

export function getDefaultNewFlow(id, title, groupId) {
  const DEFAULT_BUILDER_VERSION = 3
  const formData = {
    id,
    title,
    builder_version: DEFAULT_BUILDER_VERSION,
    pages: [],
    components: [
      {
        id: idGen(),
        key: 'global_header',
        type: 'Container',
        _location: 'before_components',
        tags: ['header'],
      },
      {
        id: idGen(),
        key: 'global_progress_bar',
        type: 'ProgressBar',
        parent_key: 'global_header',
        tags: ['progress_circle'],
      },
      {
        id: idGen(),
        key: 'global_footer_container',
        type: 'Container',
        _location: 'after_components',
        tags: ['footer'],
      },
      {
        id: idGen(),
        key: 'global_prev',
        type: 'CustomButton',
        parent_key: 'global_footer_container',
        action: 'prev-page',
        text: 'Prev',
        tags: ['global_prev'],
      },
      {
        id: idGen(),
        key: 'global_next',
        type: 'CustomButton',
        parent_key: 'global_footer_container',
        text: 'Next',
        action: 'next-page',
        validation_show_state: 'hide',
        tags: ['global_next'],
        needs_validation_passed: true,
      },
    ],
  }
  const flowData = {
    builder_version: DEFAULT_BUILDER_VERSION,
    version: 0,
    groupId,
    createdAt: new Date().toISOString(),
    form: JSON.stringify(formData),
  }
  return { flow: flowData, form: formData }
}

export function findLastChangedVersion(itemType, item, versionHistories, options) {
  const { property, originalForm } = options || {}
  /* 
  Make sure histories are sorted in descending timestamp
  No styles yet
  */
  return versionHistories.find(history => {
    const form = history.form
    const types = {
      computed_fields: form.computedFields || [],
      experiments: form.experiments || [],
      // data_output: form.dataOutputs || [], // uses different id system so needs some sort of figuring out
      pages: form.pages || [],
      global_components: form.components || [],
      styles: form.styles || {},
    }

    switch (itemType) {
      case 'styles': {
        const versionStyles = form.styles || {}
        const styles = originalForm.styles || {}
        return property
          ? !isEqual(versionStyles[property], styles[property])
          : !isEqual(versionStyles, styles)
      }
      case 'flow-property': {
        /* NYI */
        break
      }
      case 'components': {
        const originalPage = originalForm.pages.find(p => {
          const pageComponents = p.components || []
          return pageComponents.some(c => c.id === item.id)
        })
        const pageId = originalPage.id
        const versionPage = types.pages.find(p => p.id === pageId)
        if (!versionPage) return true
        const foundItem = versionPage.components.find(c => c.id === item.id)
        if (!foundItem) return true
        return property ? !isEqual(foundItem[property], item[property]) : !isEqual(foundItem, item)
      }

      default: {
        const array = types[itemType] || []
        const foundItem = array.find(d => d.id === item.id)
        if (!foundItem) return true
        return property ? !isEqual(foundItem[property], item[property]) : !isEqual(foundItem, item)
      }
    }
  })
}
