function parseURIComponent(p) {
  return decodeURIComponent(p.replace(/\+/g, ' '))
}

export function getChannelIdentifier(source, medium) {
  const src = transformSource(source)
  const med = transformMedium(medium)

  return `${src}~${med}`
}

function transformSource(source) {
  return source
}

function transformMedium(medium) {
  return medium
}

export function getChannelDisplay(identifier) {
  const [source, medium, campaign] = identifier.split('~')

  const sourceString = getSourceString(source)
  const mediumString = getMediumString(medium)
  const campaignString = getCampaignString(campaign)

  let str = sourceString

  if (mediumString && mediumString !== 'null') str = `${str} (${mediumString})`
  if (campaignString && campaignString !== 'null') str = `${str} - ${campaignString}`

  const iconImageUrl = getIcon(source, medium, campaign)

  const name = parseURIComponent(str)
    .split(' ')
    .map(t => t[0].toUpperCase() + t.slice(1))
    .join(' ')

  return { name, iconImageUrl }
}

export function getChannelIcon(channel) {
  switch (channel) {
    case 'twitter':
    case 'reddit':
      // case 'instagram':
      return channel
    case 'linkedin':
      return 'linkedin-in'
    case 't':
      return 'twitter'
    case 'facebook':
      return 'https://facebookbrand.com/wp-content/uploads/2019/04/f_logo_RGB-Hex-Blue_512.png?w=512&h=512'
    case 'google':
      return 'https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg'
    case 'adwords':
      return 'https://img.utdstc.com/icon/71b/f76/71bf76347c86d83d72214fd01d9ae00e30ab3b6af7167b58aef336853a04c3d2:200'
    case 'instagram':
      return 'https://www.freepnglogos.com/uploads/instagram-logo-png-transparent-0.png'
    case 'bing':
      return 'https://iconape.com/wp-content/png_logo_vector/iconfinder-10.png'
    case 'hubspot':
    case 'hs_email':
    case 'hs_automation':
      return 'https://www.chilipiper.com/wp-content/uploads/2020/06/Hubspot-1.png'
    case 'duckduckgo':
      return 'https://duckduckgo.com/assets/icons/meta/DDG-icon_256x256.png'
    case 'yahoo':
      return 'https://cdn1.iconfinder.com/data/icons/smallicons-logotypes/32/yahoo-512.png'
    case 'webflow':
      return 'https://assets-global.website-files.com/5b15d605b7c459fc409872b5/5f6e588d88779eea93a18c46_webflow-sq.png'
    case 'quora':
      return 'https://www.iconpacks.net/icons/2/free-quora-logo-icon-2439-thumb.png'
    case 'upwork':
      return 'https://media-exp3.licdn.com/dms/image/C4E0BAQFAvA55uI9RQQ/company-logo_200_200/0/1620158724382?e=2159024400&v=beta&t=ugYALvP3d7unZrHXmjYnbKTFk_VFCRoWmLRTUU_exlc'
    case 'ecosia':
      return 'https://cdn.iconscout.com/icon/free/png-512/ecosia-2-569348.png'
    case 'linktree':
    case 'linktr':
      return 'https://img.icons8.com/color/480/linktree.png'
    default:
      return null
  }
}

function getIcon() {
  return `https://cdn.heysavvy.com/images/icon.png`
}

export function convertFilterString(raw, category, context) {
  switch (category) {
    case 'channel':
      return getSourceString(raw)
    case 'medium':
      return getMediumString(raw)

    case 'journey':
      return kebabToProper(raw)

    case 'eventId':
      return eventIdToReadable(raw, context)
    case 'page':
    case 'location':
    case 'daterange':
    default:
      return raw
  }
}

function getSourceString(source) {
  switch (source ? source.toLowerCase() : source) {
    case 't':
      return 'Twitter'
    case 'adwords':
    case 'cpc':
      return 'Google'
    case 'fb':
      return 'Facebook'
    case 'linkedin':
    case 'li':
      return 'LinkedIn'
    case 'ig':
      return 'Instagram'
    case 'techcrunch':
      return 'Tech Crunch'
    case 'ycombinator':
      return 'Y-Combinator'

    default:
      return source
  }
}

function getMediumString(medium) {
  switch (medium) {
    case '__null':
      return 'Organic'
    default:
      return medium
  }
}

function getCampaignString(campaign) {
  switch (campaign) {
    default:
      return campaign
  }
}

function eventIdToReadable(eventId, context) {
  const { stages } = context
  if (eventId.startsWith('funnel.')) {
    const split = eventId.split('.')
    const stageId = split[split.length - 1]
    const stageLabel = stages.find(s => s.id === stageId) || { label: stageId }

    return `Manually moved to ${stageLabel.label}`
  }
  switch (eventId) {
    case 'visit-site':
    case '$pageview':
      return 'Page View'
    case 'enter-card':
      return 'Entered Credit Card'
    case 'submit-email':
      return 'Submitted Email'
    case 'began-email-submit':
    case 'begin-email-submit':
      return 'Started Typing Email'

    default:
      return universalIdToProper(eventId)
  }
}

function universalIdToProper(text) {
  return text
    .split('-')
    .map(t => t[0].toUpperCase() + t.slice(1))
    .join(' ')
    .split('_')
    .map(t => t[0].toUpperCase() + t.slice(1))
    .join(' ')
    .split('.')
    .map(t => t[0].toUpperCase() + t.slice(1))
    .join(': ')
}
function kebabToProper(text) {
  return text
    .split('-')
    .map(t => t[0].toUpperCase() + t.slice(1))
    .join(' ')
}
