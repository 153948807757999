<template>
  <div class="InsightCardInfo">
    <div>
      Cache Id:
      <pre class="p-1 m-1 rounded border-gray-400 bg-gray-200" v-text="cacheId" />
    </div>
    <div>
      Config:
      <pre class="p-1 m-1 rounded border-gray-400 bg-gray-200" v-text="configJSON" />
    </div>
    <div>
      Backend Request Object:
      <pre class="p-1 m-1 rounded border-gray-400 bg-gray-200" v-text="dashboardRequestJSON" />
    </div>
    <div v-if="cacheQuery">
      Backend Query:
      <pre class="p-1 m-1 rounded border-gray-400 bg-gray-200" v-text="cacheQuery" />
      <template v-if="cacheQueryParams">
        Params:
        <pre class="p-1 m-1 rounded border-gray-400 bg-gray-200" v-text="cacheQueryParams" />
      </template>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import { createDateRangeArray } from './computeDateGroupings'
export default {
  name: 'InsightCardInfo',
  props: {
    config: Object,
    cacheData: Object,
    cacheId: String,
    filters: Array,
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['isAdmin', 'activeGroupId']),
    ...mapState(['showTestData']),
    preset() {
      return this.config && (this.config.preset || this.config[`${this.service}-preset`])
    },
    dashboardRequest() {
      const funnelId = (this.filters.find(f => f[0] === 'journey') || [])[2]
      const stageId = (this.filters.find(f => f[0] === 'stage') || [])[2]
      const algoliaFilters = this.config[`${this.service}-checkpoint-filters`]
        ? this.config[`${this.service}-checkpoint-filters`]
        : undefined

      return {
        groupId: this.activeGroupId,
        preset: this.preset,
        filters: this.filters.filter(f => !['journey', 'stage'].includes(f[0])),
        funnelId: funnelId || this.$store.getters.mainJourneyId,
        stageId,
        cacheId: this.cacheId,
        config: '{ Config Object }',
        algoliaFilters,
        includeTest: this.showTestData,
        dateRanges: createDateRangeArray(this.config),
        timezone: this.$store.getters.groupTimezone, // Intl.DateTimeFormat().resolvedOptions().timeZone, // This is normally from the group but can be form the group document instead, defualts to PST
      }
    },
    dashboardRequestJSON() {
      return JSON.stringify(this.dashboardRequest, null, 2)
    },
    configJSON() {
      return JSON.stringify(this.config, null, 2)
    },
    cacheQuery() {
      return this.cacheData && this.cacheData._query
    },
    cacheQueryParams() {
      return this.cacheData && this.cacheData._params
    },
  },
  watch: {},
  methods: {},
}
</script>
