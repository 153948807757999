export default function googleFontsUrls(stylesObj) {
  const weights = new Set()
  const styles = new Set()
  const usedFamilies = Object.values(stylesObj || {})
    .reduce((acc, s) => {
      Object.entries(s).forEach(([k, v]) => {
        if (k === 'font-family') {
          const families = v.split(',').map(t => t.trim())
          acc.push(...families)
        }
        if (k === 'font-weight') {
          weights.add('400')
          switch (v) {
            case 'bold': {
              weights.add('700')
              break
            }
            case 'normal': {
              weights.add('400')
              break
            }
            case 'light': {
              weights.add('300')
              break
            }
            default: {
              weights.add(v)
              break
            }
          }
        }
        if (k === 'font-style' && v === 'italic') styles.add('ital')
      })
      return acc
    }, [])
    .unique()

  const disc = []
  const addItalics = styles.size > 0
  const addWeights = weights.size > 0
  if (addItalics) disc.push('ital')
  if (addWeights) disc.push('wght')

  const endDimensions = []
  const dimensions = Array.from(weights)
    .sort()
    .reduce((acc, w) => {
      if (addItalics) {
        acc.push([0, w])
        endDimensions.push([1, w])
      } else if (w) acc.push([w])

      return acc
    }, [])
  if (addItalics && !addWeights) dimensions.push([0], [1])
  endDimensions.forEach(d => dimensions.push(d))
  // let addString =
  let addString = disc.join(',')
  if (disc.length > 0) {
    addString = `:${addString}@${dimensions
      .filter(d => (typeof d === 'string' ? d.trim() : d))
      .filter(f => f)
      .map(d => d.join(','))
      .join(';')}`
  }

  const ignoreFamilies = new Set(['sans-serif', 'inherit'])
  const formStylesFontFamilies = usedFamilies
    .filter(f => !ignoreFamilies.has(f))
    .map(f => {
      const font = encodeURIComponent(trimFamilyName(f))
      return `family=${font}${addString}`
    })

  return formStylesFontFamilies.length > 0 ? formStylesFontFamilies : []
}

function trimFamilyName(t) {
  let text = t
  if (text.startsWith(`'`)) text = text.slice(1)
  if (text.startsWith(`"`)) text = text.slice(1)
  if (text.endsWith(`'`)) text = text.slice(0, -1)
  if (text.endsWith(`"`)) text = text.slice(0, -1)
  return text
}
