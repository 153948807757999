<template>
  <a-popover v-model="open" placement="bottomRight" trigger="click">
    <template slot="title">
      <div class="config-header">
        <span>Configure your Dashboard Card</span>
        <a-tooltip v-if="serviceConnectionStatus === 'not-connected'">
          <template slot="title">
            A connection to {{ serviceName }} is required for configuration
          </template>
          <span style="cursor: pointer; margin-left: 5px;">
            <a-icon type="exclamation-circle" style="color: orange;"></a-icon>
          </span>
        </a-tooltip>
        <a-tooltip v-else>
          <template slot="title">Refresh Config Menus </template>
          <span
            @click="debounceGetConfigOptions(config)"
            style="cursor: pointer; margin-left: 5px;"
          >
            <a-icon type="reload" :spin="anyOptionLoading"></a-icon>
          </span>
        </a-tooltip>
      </div>
    </template>
    <template slot="content">
      <a-space direction="vertical" size="large">
        <a-input v-if="config" size="large" placeholder="Card Title" v-model="config.title" />
        <a-space direction="vertical">
          <template
            v-for="{
              key,
              type,
              text,
              placeholder,
              showSearch,
              filterOption,
              size,
              buttonType,
              click,
              multiple,
              options,
              loading,
            } in propsToCollect"
          >
            <a-button
              v-if="type === 'button'"
              :key="key"
              :type="buttonType"
              :loading="loading"
              @click="click"
            >
              {{ text }}
            </a-button>

            <template v-else-if="type === 'date-picker'">
              <div :key="key">
                <a-range-picker @change="setPickerValue(key, $event)" />
                {{ formatCustomDateString(config[key]) }}
              </div>
            </template>

            <a-button-group :key="key" v-else-if="type === 'button-group'">
              <a-button
                v-for="option in options"
                :key="option.key"
                :type="
                  config[key] === option.key || (!config[key] && option.default)
                    ? 'primary'
                    : 'default'
                "
                @click="setConfigProp(key, option.key)"
              >
                {{ option.text }}
              </a-button>
            </a-button-group>

            <a-select
              v-else
              :mode="multiple ? 'multiple' : undefined"
              style="width: 300px"
              :key="key"
              :value="config[key]"
              :disabled="loading"
              v-bind="{ placeholder, size, loading, showSearch, filterOption }"
              @change="setConfigProp(key, $event)"
            >
              <a-select-option
                v-if="!(options || []).find(o => o.key === undefined)"
                :value="undefined"
              >
                None
              </a-select-option>
              <a-select-option v-for="{ key, text } in options" :key="key" :value="key">
                {{ text }}
              </a-select-option>
            </a-select>
          </template>
          <a-checkbox
            :checked="config && config.includeTest"
            @change="onToggleCheckbox('includeTest', $event)"
            >Include Test Data</a-checkbox
          >
          <a-checkbox
            :checked="config && config.goalCard"
            @change="onToggleCheckbox('goalCard', $event)"
            >Goal Card</a-checkbox
          >
        </a-space>

        <a-row>
          <a-col :span="8">
            <a-button type="danger" @click="deleteCard">Delete</a-button>
          </a-col>
          <a-col :span="16" style="text-align: right;">
            <a-space>
              <a-button @click="cancel">Cancel</a-button>
              <a-button type="primary" @click="save">Save</a-button>
            </a-space>
          </a-col>
        </a-row>
      </a-space>
    </template>
    <div class="InsightCardConfig" style="cursor: pointer;">
      <Icon name="pencil-alt" />
    </div>
  </a-popover>
</template>

<script>
/* global xkit */
// import get from 'lodash/get'

import { mapGetters } from 'vuex'
import { snakeCase } from 'lodash'
import clone from 'lodash/clone'
import debounce from 'lodash/debounce'
import Firebase from 'firebase/app'
import 'firebase/firestore'

import api from '@/helpers/api'
import { SERVICES } from './services'
import { toDateString } from '../../helpers/timeFilters'
import facebookPixelEvents from '../form/editor/helpers/facebookPixelEvents'
import getFilterString from '@/components/explorer/Filters/filtersToAlgoliaString'
import getCheckpointOptions from '@/components/form/editor/helpers/getCheckpointOptions'

export default {
  name: 'InsightCardConfig',
  components: {},
  inject: {
    _getConnections: { default: () => () => {} },
    _updateConnections: { default: () => () => {} },
  },
  props: {
    id: String,
    savedConfig: Object,
  },
  data() {
    return {
      options: {},
      optionsLoading: {},
      open: false,
      config: {},
      search: {},
    }
  },
  computed: {
    ...mapGetters(['flows']),
    rockset() {
      return this.$store.getters.rocksetFilterValues || {}
    },
    connections() {
      return this._getConnections()
    },
    projectId() {
      return this.$store.state.currentProjectId
    },
    services() {
      const services = clone(SERVICES)

      Object.entries(this.connections || {}).forEach(([key, c]) => {
        if (services[key]) services[key].isConnected = c || false
      })

      return services
    },
    serviceName() {
      return SERVICES[this.config.service] && SERVICES[this.config.service].name
    },
    serviceConnectionStatus() {
      const service = SERVICES[this.config.service]
      if (!service) return false
      if (service.alwaysConnected) return 'connected'
      if (!this.connections) return 'loading'
      const status = this.connections[this.config.service]
      // if (status === undefined) return 'loading'
      return status ? 'connected' : 'not-connected'
    },
    anyOptionLoading() {
      return Object.values(this.optionsLoading).some(l => l)
    },
    debounceGetConfigOptions() {
      return debounce(this.getConfigOptions, 250, { trailing: true, leading: false })
    },
    propsToCollect() {
      const self = this
      const { service, grouping } = this.config
      const actingService =
        this.config.service === 'combo' ? this.config['combo-source'] : this.config.service

      const serviceObject = SERVICES[actingService] || {}

      const serviceProp = {
        key: 'service',
        placeholder: 'Select a service',
        size: 'large',
        options: Object.entries(this.services).map(([key, s]) => ({ key, text: s.name })),
      }

      const isConnected =
        actingService && (serviceObject.alwaysConnected || serviceObject.isConnected)

      const connect = actingService &&
        !isConnected && {
          key: 'connect',
          type: 'button',
          text: `Connect to ${this.services[actingService].name}`,
          buttonType: 'primary',
          loading: !this.connections, //!this.connections.hasOwnProperty(actingService),
          click: () => self.connect(actingService),
        }

      const accountKey = `${actingService}-accounts`
      const accountOptions = serviceObject.accountRequired &&
        isConnected && {
          key: accountKey,
          placeholder: 'Select an account',
          size: 'large',
          options: this.options[accountKey] || [],
          loading: this.optionsLoading[accountKey] === true,
        }

      const presetAllowed = [
        'facebook-ads',
        'google-search-console',
        'stripe',
        'webflow',
        'hubspot',
        'savvy',
        'text',
        'markdown',
        'combo',
        'google-analytics',
        'salesforce',
      ]
      const presetKey = `${service}-preset`
      const presetOptions = new Set(presetAllowed).has(service) &&
        (this.config[accountKey] || !serviceObject.accountRequired) && {
          key: presetKey,
          placeholder: 'Select a preset',
          size: 'large',
          options: [
            { text: 'Funnel', key: 'funnel', services: ['combo'] },
            { text: 'Checkpoints', key: 'checkpoint', services: ['combo', 'savvy'] },
            { text: 'Pages', key: 'flow-pages', services: ['combo'] },
            { text: 'Text Body', key: 'text', services: ['text'] },
            { text: 'Markdown Body', key: 'markdown', services: ['markdown'] },
            { text: 'Visitors', key: 'visitors', services: ['savvy'] },
            { text: 'Top Line', key: 'top-line', services: ['savvy'] },
            { text: 'Users', key: 'users', services: ['google-analytics'] },
            { text: 'Spend', key: 'spend', services: ['facebook-ads'] },
            { text: 'Action Cost', key: 'action-cost', services: ['facebook-ads'] },
            {
              text: 'Impressions',
              key: 'impressions',
              services: ['facebook-ads', 'google-search-console'],
            },
            { text: 'Clicks', key: 'clicks', services: ['facebook-ads', 'google-search-console'] },
            { text: 'CPC', key: 'cpc', services: ['facebook-ads'] },
            {
              text: '# Customers',
              key: 'customers',
              services: ['stripe', 'webflow', 'hubspot', 'salesforce'],
            },
            { text: '$ Refunded', key: 'refund-amount', services: ['stripe', 'webflow'] },
            { text: '# Refunds', key: 'refund-quantity', services: ['stripe', 'webflow'] },
            { text: 'Revenue', key: 'revenue', services: ['stripe', 'webflow'] },
          ].filter(o => (o.services ? o.services.includes(service) : true)),
        }

      const checkpointOptions = service === 'savvy' &&
        this.config[presetKey] === 'checkpoint' && {
          key: `${service}-checkpoint`,
          placeholder: 'Select a Checkpoint',
          options: Array.from(
            this.flows.reduce((acc, f) => {
              getCheckpointOptions(f, true).forEach(o => {
                acc.add(o)
              })
              return acc
            }, new Set(getCheckpointOptions({}, false)))
          ).map(o => ({
            text: o,
            key: o,
          })),
        }

      const comparisonKeyOne = `cardOne`
      const comparisonKeyTwo = `cardTwo`
      const comparisonOptions = service === 'comparison' && {
        key: comparisonKeyOne,
        placeholder: 'Select an Insight Card',
        options: (this.options[`${service}-insights`] || []).filter(o => o.key !== this.id),
      }
      const comparisonOptionsTwo = service === 'comparison' && {
        ...comparisonOptions,
        key: comparisonKeyTwo,
      }

      const presetIsCheckpoint = ['checkpoint', 'flow-pages'].includes(this.config[presetKey])
      const sourceIsFlow = service === 'flow' || presetIsCheckpoint
      const flowOptions = sourceIsFlow && {
        key: 'flowId',
        placeholder: 'Select a flow',
        options: [...this.flows.map(f => ({ key: f.id, text: f.title }))],
      }

      const presetIsFunnel = this.config[presetKey] === 'funnel'
      const funnelOptions = presetIsFunnel && {
        key: 'funnelId',
        placeholder: 'Select a funnel',
        options: [...(this.options[`combo-funnel`] || [])],
      }

      const funnelSourceKey = `${service}-source`
      const funnelSourceOptions = (presetIsCheckpoint || presetIsFunnel) && {
        key: funnelSourceKey,
        placeholder: 'Optional - Select an external source',
        options: [
          { text: 'No Source', key: undefined },
          { text: 'Facebook Ads', key: 'facebook-ads' },
          { text: 'Google Ads', key: 'google-ads' },
          { text: 'Google Search Console', key: 'google-search-console' },
        ],
      }

      const funnelBreakdownKey = `${service}-funnel-breakdown`
      const funnelBreakdownOptions = presetIsFunnel && {
        key: funnelBreakdownKey,
        placeholder: 'Optional - Breakdown your funnel',
        showSearch: true,
        filterOption: this.filterOption,
        options: [
          { text: 'No breakdown', key: undefined },
          { text: 'Source', key: 'source', sources: [undefined] },
          { text: 'First Pageview', key: 'first-page', sources: [undefined] },
          { text: 'Pages Visited', key: 'pages-visited', sources: [undefined] },
          { text: 'Campaigns', key: 'campaigns', sources: ['facebook-ads'] },
          ...(this.options['savvy-flow-breakdown'] || []).map(o => ({
            ...o,
            sources: [undefined],
          })),
          // { text: 'Something', key: 'somekey', sources: ['google-ads'] },
        ].filter(o => (o.sources ? o.sources.includes(this.config[funnelSourceKey]) : true)),
      }

      const funnelFilterKey = `${service}-page-filter`
      const seenKeys = new Set()
      const flowFilterOptions = presetIsCheckpoint
        ? (this.rockset.flowValues || []).reduce((acc, f) => {
            if (Array.isArray(f.subValues)) {
              f.subValues.forEach(v => {
                const key = getFilterString(['flow-values', v.key])
                if (!seenKeys.has(key)) acc.push({ key, text: v.aliases[0] })
                seenKeys.add(key)
              })
            }
            return acc
          }, [])
        : []
      const funnelFilterOptions = presetIsFunnel && {
        key: funnelFilterKey,
        placeholder: 'Optional - Filter your results',
        showSearch: true,
        multiple: true,
        filterOption: this.filterOption,
        options: [{ text: 'No filter', key: undefined }, ...(this.options['savvy-pages'] || [])],
      }

      const checkpointFilterOptions = presetIsCheckpoint && {
        key: `${service}-checkpoint-filters`,
        placeholder: 'Optional - Filter your results',
        showSearch: true,
        multiple: false,
        filterOption: this.filterOption,
        options: [{ text: 'No filter', key: undefined }, ...flowFilterOptions],
      }

      const servicesWithCampaigns = ['facebook-ads']
      const isFunnnelWithService =
        service === 'combo' && servicesWithCampaigns.includes(actingService)
      const campaignKey = `${actingService}-campaigns`
      const campaignOptions = (new Set(servicesWithCampaigns).has(actingService) ||
        isFunnnelWithService) &&
        isConnected &&
        this.config[accountKey] && {
          key: campaignKey,
          placeholder: 'Select a campaign (optional)',
          size: 'large',
          options: [
            { key: undefined, text: 'All Campaigns' },
            ...(this.options[campaignKey] || []),
          ],
          loading: !this.options[campaignKey],
        }

      const adsetKey = `${actingService}-adset`
      const useAdset =
        (new Set(servicesWithCampaigns).has(actingService) || isFunnnelWithService) &&
        isConnected &&
        this.config[campaignKey]
      const selectedCampaign =
        this.options[campaignKey] &&
        this.options[campaignKey].find(c => c.key === this.config[campaignKey])
      const adsetOptionButtons = [
        { key: undefined, text: 'All Adsets' },
        ...((selectedCampaign && selectedCampaign.nodes) || []),
      ]
      const adsetOptions = useAdset && {
        key: adsetKey,
        placeholder: 'Select an adset (optional)',
        size: 'large',
        options: adsetOptionButtons,
      }

      const actionTypeKey = `${service}-action-type`
      const actionTypeOptions = this.config[presetKey] === 'action-cost' &&
        servicesWithCampaigns.includes(service) && {
          key: actionTypeKey,
          placeholder: 'Required - Select a Pixel Event',
          options: facebookPixelEvents().map(a => ({ text: a, key: snakeCase(a) })),
        }

      const flowBreakdownKey = `${service}-flow-breakdown`
      const presetIsFlows =
        (grouping === 'non-date' || grouping === 'both') &&
        this.config[`${service}-breakdown`] === 'flows'
      const flowBreakdownOptions = presetIsFlows && {
        key: flowBreakdownKey,
        placeholder: 'Select a flow',
        options: this.options[flowBreakdownKey],
      }

      /* Only show if preset is chosen */
      const breakdownOptions = new Set([
        'stripe',
        'webflow',
        'hubspot',
        'savvy',
        'google-search-console',
        'google-analytics',
        'salesforce',
      ]).has(service) &&
        this.config[presetKey] && {
          key: `grouping`,
          placeholder: 'Select a grouping',
          type: 'button-group',
          options: [
            { text: 'Total Number', key: 'none' },
            { text: 'Number over time', key: 'date' },
            { text: 'Breakdown', key: 'non-date' },
            { text: 'Breakdown over time', key: 'both' },
          ],
        }

      const noBreakdownPresets = new Set(['refund-amount', 'refund-quantity'])
      const groupingClause = grouping === 'both' || grouping === 'non-date'
      const breakdownSelect = groupingClause &&
        !serviceObject.noFilters &&
        !noBreakdownPresets.has(this.config[presetKey]) &&
        !presetIsCheckpoint &&
        !presetIsFunnel && {
          key: `${service}-breakdown`,
          placeholder: 'Breakdown by...',
          size: 'large',
          options: [
            { text: 'Plan', key: 'plan', services: ['stripe'] },
            { text: 'Product', key: 'product', services: ['webflow'] },
            { text: 'Channels', key: 'channels', services: ['savvy'] },
            { text: 'Pages', key: 'pages', services: ['savvy'] },
            { text: 'Flows', key: 'flows', services: ['savvy'] },
            { text: 'Referrers', key: 'referrers', services: ['savvy'] },
            { text: 'Continents', key: 'continents', services: ['savvy'] },
            { text: 'Countries', key: 'countries', services: ['savvy'] },
            { text: 'Browser', key: 'browser', services: ['google-analytics'] },
            { text: 'Regions', key: 'regions', services: ['savvy'] },
            { text: 'Cities', key: 'cities', services: ['savvy'] },
            { text: 'Keyword', key: 'keyword', services: ['google-search-console'] },
          ].filter(o => (o.services ? o.services.includes(service) : true)),
        }

      /* Only show if grouping is date / both */
      const dateGrouping = (grouping === 'both' || grouping === 'date') && {
        key: `date-interval`,
        placeholder: 'Select a date interval',
        type: 'button-group',
        options: [
          { text: 'Years', key: 'year' },
          { text: 'Months', key: 'month', default: true },
          { text: 'Weeks', key: 'week' },
          { text: 'Days', key: 'day' },
        ],
      }

      const showDateRange = !serviceObject.noFilters
      const dateRange = showDateRange && {
        key: 'date-range',
        placeholder: 'Optional - Select a date range',
        options: [
          { text: 'All', key: undefined },
          { text: 'Custom Range', key: 'custom' },
          { text: 'Today', key: 'today', categories: ['day'] },
          { text: 'Yesterday', key: 'yesterday', categories: ['day'] },
          { text: 'This week', key: 'this-week', categories: ['day'] },
          { text: 'This month', key: 'this-month', categories: ['week', 'day'] },
          { text: 'Last week', key: 'last-week', categories: ['day'] },
          { text: 'Last month', key: 'last-month', categories: ['week', 'day'] },
        ].filter(o =>
          ['date', 'both'].includes(grouping) && o.categories
            ? o.categories.includes(this.config['date-interval'])
            : true
        ),
      }

      // const eventIdsRockset = this.$store.state.rockset.eventId.map(o => ({
      //   text: o.key,
      //   key: o.key,
      // }))
      // const eventIdOptions = [{ text: 'No Filter', key: undefined }, ...eventIdsRockset].filter(
      //   o => o.key !== '$pageview'
      // )
      // const eventIdContactFilter = service === 'savvy' && {
      //   key: 'eventId',
      //   placeholder: 'Optional - Filter Contacts by event id',
      //   options: eventIdOptions,
      // }

      // const eventIdEventsFilter = service === 'savvy' && {
      //   key: 'event-eventId',
      //   placeholder: 'Optional - Filter Events by event id',
      //   options: eventIdOptions,
      // }

      const flowEvents = ['reached_flow_page', 'began_flow', 'completed_flow']
      const eventEventIdIsFlow = flowEvents.includes(this.config['event-eventId'])
      const flowIdFilter = service === 'savvy' &&
        eventEventIdIsFlow && {
          key: 'flowId',
          placeholder: 'Optional - Filter Events by flow id',
          options: this.flows.map(f => ({ key: f.id, text: f.title || `Untitled Flow (${f.id})` })),
        }

      /* TODO - Build a UI for selecting custom values properly - format is '<ISO STRING>_TO_<ISO STRING>' */
      const customDateRangeSelected = this.config['date-range'] === 'custom'
      const customPicker = customDateRangeSelected && {
        key: 'custom-date',
        type: 'date-picker',
      }

      return [
        serviceProp,
        connect,
        accountOptions,
        comparisonOptions,
        comparisonOptionsTwo,
        campaignOptions,
        adsetOptions,
        presetOptions,
        checkpointOptions,
        actionTypeOptions,
        breakdownOptions,
        breakdownSelect,
        dateGrouping,
        dateRange,
        // eventIdContactFilter,
        // eventIdEventsFilter,
        flowIdFilter,
        customPicker,
        funnelOptions,
        funnelSourceOptions,
        funnelBreakdownOptions,
        funnelFilterOptions,
        checkpointFilterOptions,
        flowBreakdownOptions,
        flowOptions,
      ].filter(p => p)
    },
  },
  watch: {
    savedConfig: {
      handler(savedConfig) {
        this.config = { ...savedConfig } || {}
      },
      immediate: true,
      deep: true,
    },
    config: {
      handler(c) {
        if (this.open && c) this.debounceGetConfigOptions(c)
      },
      immediate: true,
    },
    serviceConnectionStatus: {
      handler(v) {
        if (this.open && v === 'connected') this.debounceGetConfigOptions(this.config)
      },
      immediate: true,
    },
    open: {
      handler(open) {
        if (open && this.serviceConnectionStatus === 'connected')
          this.debounceGetConfigOptions(this.config)
      },
      immediate: true,
    },
  },
  methods: {
    getConfigOptions(c) {
      const service = c.service === 'combo' ? c['combo-source'] : c.service
      if (service && SERVICES[service] && SERVICES[service].accountRequired) {
        this.getFieldOptions(service, 'accounts')
      }
      if (c[`${service}-accounts`] && service === 'facebook-ads') {
        this.getFieldOptions(service, 'campaigns', { accountId: c[`${service}-accounts`] })
      }
      // if (service === 'savvy' && this.config[`${service}-preset`] === 'checkpoint') {
      //   this.getFieldOptions(c.service, 'checkpoints')
      // }
      if (c.service === 'combo' && c[`${c.service}-preset`] === 'funnel') {
        this.getFieldOptions(c.service, 'funnel')

        this.getFieldOptions('savvy', 'flow-breakdown')
        if (c[`${c.service}-funnel-breakdown`] === 'pages-visited') {
          this.getFieldOptions('savvy', 'pages')
        }
      }
      if (service === 'comparison')
        this.getFieldOptions(service, 'insights', { projectId: this.projectId })

      if (
        service === 'savvy' &&
        c[`${service}-preset`] === 'visitors' &&
        c[`${service}-breakdown`] === 'flows'
      ) {
        this.getFieldOptions(service, 'flow-breakdown')
      }
      if (
        c[`${service}-breakdown`] &&
        ['refund-amount', 'refund-quantity'].includes(c[`${service}-preset`])
      )
        delete this.config[`${service}-breakdown`]
      if (!c['date-interval']) this.setConfigProp('date-interval', 'month')
      const dateRangeObj = this.propsToCollect.find(p => p.key === 'date-range')
      if (c['date-range'] && dateRangeObj) {
        if (!dateRangeObj.options.find(o => o.key === c['date-range'])) {
          this.setConfigProp('date-range', undefined)
        }
      }
    },
    async getFieldOptions(service, propName, context) {
      // if (this.id !== 'PW43AWjFslnp1ck85Sg2') return
      const key = `${service}-${propName}`
      if (this.serviceConnectionStatus !== 'connected') return
      if (this.optionsLoading[key] || (this.options[key] && this.options[key].length)) return
      // this.optionsLoading[key] = true
      this.$set(this.optionsLoading, key, true)
      try {
        const data = { service, propName, groupId: this.$store.getters.activeGroupId, context }

        const res = await api('/metrics/insight-config', data)
        const results = res.data.options
        if (results.length > 0) this.$set(this.options, key, results)
        else {
          /* Do some sort of alert to say that we didn't find any results or offer a retry button */
        }
      } catch (error) {
        console.error(error)
      }
      // this.optionsLoading[key] = false
      this.$set(this.optionsLoading, key, false)
    },
    setConfigProp(key, val) {
      console.log(`thef key,val`, key, val)
      if (val === undefined) {
        delete this.config[key]
        this.config = { ...this.config }
      } else {
        // this.$set(this.config, key, val)
        this.config = { ...this.config, [key]: val }
      }
    },
    searchOptions(key, val) {
      if (val === undefined) {
        delete this.search[key]
        this.search = { ...this.search }
      } else {
        // this.$set(this.search, key, val)
        this.search = { ...this.search, [key]: val }
      }
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().includes(input.toLowerCase())
    },
    async connect(service) {
      await xkit.connect(service)

      this._updateConnections(service)
    },
    save() {
      const collection = this.projectId
        ? `projects/${this.projectId}/stats`
        : `groups/${this.$store.getters.activeGroupId}/insights`

      Firebase.firestore()
        .collection(collection)
        .doc(this.id)
        .set(this.config)

      this.open = false
    },
    cancel() {
      this.config = this.savedConfig
      this.open = false
    },
    deleteCard() {
      const collection = this.projectId
        ? `projects/${this.projectId}/stats`
        : `groups/${this.$store.getters.activeGroupId}/insights`
      Firebase.firestore()
        .collection(collection)
        .doc(this.id)
        .delete()

      this.$emit('remove-card', this.id)
    },
    setPickerValue(key, dateArr) {
      const dates = dateArr
      if (dates.length === 0) {
        this.setConfigProp(key, undefined)
      } else {
        const val = dates.map(d => d._d.toISOString()).join('_TO_')
        this.setConfigProp(key, val)
      }
    },
    onToggleCheckbox(key, e) {
      this.setConfigProp(key, e.target.checked)
    },
    formatCustomDateString(s) {
      return (
        s &&
        s
          .split('_TO_')
          .map(t => toDateString(t))
          .join(' to ')
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.InsightCardConfig {
  margin-top: -4px;

  .config-header {
    display: flex;
    flex-direction: row;
  }

  .Icon {
    color: #999;
    font-size: 12px;

    &:hover {
      // opacity: 0.8;
      color: var(--color-savvy);
    }
  }
}
</style>
