<template>
  <div class="FlowResultsPage">
    <Entity renderless collection="forms" v-bind="{ id }" v-model="form" />
    <FlowResults
      v-if="form && form.form"
      isActive
      :form="form && form.form && JSON.parse(form.form)"
      v-bind="{ id, showTestData }"
      @csvBlob="csvBlob = $event"
    />
  </div>
</template>

<script>
import FlowResults from '@/components/form/FlowResults'

export default {
  name: 'FlowResultsPage',
  components: {
    FlowResults,
  },
  props: {
    id: String,
  },
  data() {
    return {
      form: null,
      activeTabId: this.id,
      showTestData: false,
      csvBlob: null,
    }
  },
}
</script>

<style lang="scss">
@import '@/styles/_variables.scss';

.FlowResultsPage {
  margin: 0 -10px 0;
}
</style>
