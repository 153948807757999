<template>
  <div class="ConditionsEditor">
    <a-space direction="vertical">
      <ConditionEditor
        v-for="(condition, index) in _conditions"
        :key="condition.id"
        v-bind="{ condition, index, options, keys, optionOperators, optionValues, location }"
        @update="onUpdate(index, $event)"
        @remove="removeCondition(index)"
      />
      <a-button icon="plus" class="w-full" @click="addCondition"
        >Add a Show/Hide Condition</a-button
      >
    </a-space>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import Firebase from 'firebase/app'
import 'firebase/firestore'

import ConditionEditor from './ConditionEditor'
import { debounce } from 'lodash'
import componentTypes from '@/components/form/editor/helpers/componentTypes'

export default {
  name: 'ConditionsEditor',
  components: {
    ConditionEditor,
  },
  inject: [
    '_getOptions',
    '_getBasePath',
    '_updateData',
    '_setShowHiddenPages',
    '_setShowHiddenComponents',
    // '_setButtonState',
  ],
  props: {
    form: Object,
    conditions: Array,
    location: String,
  },
  computed: {
    _conditions: {
      get() {
        return this.conditions || []
      },
      set(val) {
        this.debounceUpdate(val)
      },
    },
    debounceUpdate() {
      return debounce(v => this._updateData(this._getBasePath(), 'conditions', v), 200, {
        trailing: true,
        leading: false,
      })
    },
    options() {
      const bannedTypes = new Set(
        componentTypes()
          .filter(c => c.hasNoValue)
          .map(c => c.key)
      )
      return this._getOptions().filter(o => {
        const isCarousel = o.containerType === 'Carousel'
        if (isCarousel) return true
        return o.type ? !bannedTypes.has(o.type) : true
      })
    },
    keys() {
      return Array.from(
        new Set(
          this.options
            // .filter(({ type }) => type === 'OptionButtons')
            .map(o => o.key)
        )
      )
    },
    optionOperators() {
      return this.options.reduce((acc, { key, type }) => {
        // multiple
        //   ? ['includes', 'does-not-include']
        //   :
        const allOptions = [
          '==',
          '!=',
          'exists',
          '!exists',
          'is-true',
          'is-false',
          'is-not-true',
          'is-not-false',
        ]
        acc[key] =
          type === 'computed'
            ? allOptions
            : ['InputBox', 'OptionButtons', 'OptionSelector'].includes(type)
            ? ['==', '!=', 'exists', '!exists']
            : allOptions
        if (type === 'InputBox') acc[key].push('is-empty', 'is-not-empty', 'is-true', 'is-false')
        return acc
      }, {})
    },
    optionValues() {
      return this.options.reduce(
        (values, { key, options }) => ({
          ...values,
          [key]: Array.from(new Set([...(values[key] || []), ...(options || [])])),
        }),
        {}
      )
    },
  },
  methods: {
    showHidden() {
      switch (this.location) {
        case 'page': {
          this._setShowHiddenPages(true)
          // this._setButtonState('show-hidden-page', true)
          break
        }
        case 'component': {
          this._setShowHiddenPages(true)
          this._setShowHiddenComponents(true)
          // this._setButtonState('show-hidden-page', true)
          // this._setButtonState('show-hidden-components', true)
          break
        }

        default:
          break
      }
    },
    addCondition() {
      this._conditions = [
        ...this._conditions,
        {
          id: Firebase.firestore()
            .collection('_')
            .doc().id,
          key: null,
          operator: '==',
          values: null,
        },
      ]
    },
    removeCondition(index) {
      this._conditions = this._conditions.spliceReturn(index, 1)
    },
    async onUpdate(index, event) {
      this.showHidden()
      await this.$nextTick()
      this.$set(this._conditions, index, event)

      this._conditions = cloneDeep(this._conditions)
    },
  },
}
</script>
