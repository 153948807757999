<template>
  <div class="TripleInsights">
    <Entity
      v-if="cacheIdOne"
      :collection="`/groups/${activeGroupId}/cached-data/`"
      :id="cacheIdOne"
      v-model="cacheOne"
    />
    <Entity
      v-if="cacheIdTwo"
      :collection="`/groups/${activeGroupId}/cached-data/`"
      :id="cacheIdTwo"
      v-model="cacheTwo"
    />
    <Entity
      v-if="cacheIdThree"
      :collection="`/groups/${activeGroupId}/cached-data/`"
      :id="cacheIdThree"
      v-model="cacheThree"
    />
    <div v-if="dataOne" class="single-number">
      Today
      <a-statistic
        v-if="typeof dataOne === 'number'"
        :prefix="prefix"
        :precision="0"
        :value="dataOne"
        :valueStyle="valueStyle"
      />
    </div>
    <div v-if="dataTwo" class="single-number">
      This Week
      <a-statistic
        v-if="typeof dataTwo === 'number'"
        :prefix="prefix"
        :precision="0"
        :value="dataTwo"
        :valueStyle="valueStyle"
      />
    </div>
    <div v-if="dataThree" class="single-number">
      This Month
      <a-statistic
        v-if="typeof dataThree === 'number'"
        :prefix="prefix"
        :precision="0"
        :value="dataThree"
        :valueStyle="valueStyle"
      />
    </div>
  </div>
</template>
<script>
import cachedDataToCardData, { generateCacheSlug, getBreakdownArray } from './cacheToChartData'
import { createDateRangeArray } from './computeDateGroupings'
import api from '@/helpers/api'

export default {
  props: { config: Object, requestReload: Boolean, prefix: String },
  data() {
    return {
      cacheOne: null,
      cacheTwo: null,
      cacheThree: null,
    }
  },
  computed: {
    valueStyle() {
      return 'font-size: 36px; color: #6454f3;'
    },
    activeGroupId() {
      return this.$store.getters.activeGroupId
    },
    preset() {
      return this.config[`${this.config.service}-preset`] || this.config.preset
    },
    configOne() {
      /* Today */
      return {
        ...this.config,
        'savvy-preset': 'visitors',
        grouping: 'none',
        'date-range': 'today',
        'date-interval': 'day',
      }
    },
    cacheIdOne() {
      return this.getCacheId(this.configOne)
    },
    dataOne() {
      return cachedDataToCardData(this.preset, this.cacheOne, this.configOne)
    },
    configTwo() {
      /* This week */
      return {
        ...this.config,
        'savvy-preset': 'visitors',
        grouping: 'none',
        'date-interval': 'week',
        'date-range': 'this-week',
      }
    },
    cacheIdTwo() {
      return this.getCacheId(this.configTwo)
    },
    dataTwo() {
      return cachedDataToCardData(this.preset, this.cacheTwo, this.configTwo)
    },
    configThree() {
      /* This month */
      return {
        ...this.config,
        'savvy-preset': 'visitors',
        grouping: 'none',
        'date-interval': 'month',
        'date-range': 'this-month',
      }
    },
    cacheIdThree() {
      return this.getCacheId(this.configThree)
    },
    dataThree() {
      return cachedDataToCardData(this.preset, this.cacheThree, this.configThree)
    },
  },
  watch: {
    config: {
      handler() {
        const self = this
        setTimeout(() => {
          self.load()
        }, 100)
      },
      immediate: true,
    },
    requestReload: {
      async handler(v) {
        if (v) {
          await this.load(true)
          this.$emit('reloaded')
        }
      },
    },
  },
  methods: {
    getCacheId(config) {
      if (!config) return null
      const breakdownArray = getBreakdownArray(config)
      return generateCacheSlug(config, undefined, undefined, breakdownArray)
    },
    async load(r) {
      await Promise.all([
        this.requestAPICacheUpdate(this.configOne, this.cacheOne, this.cacheIdOne, r),
        this.requestAPICacheUpdate(this.configTwo, this.cacheTwo, this.cacheIdTwo, r),
        this.requestAPICacheUpdate(this.configThree, this.cacheThree, this.cacheIdThree, r),
      ])
    },
    async requestAPICacheUpdate(config, cachedData, cacheId, r) {
      if (!r && cachedData && cachedData.updatedAt) {
        const nowSeconds = Date.now() / 1000
        const lastUpdatedSeconds = cachedData.updatedAt.seconds
        const interval = 60 * 15 //3600 * 1
        if (nowSeconds - lastUpdatedSeconds < interval) return
      }
      const filters = []

      const funnelId = (filters.find(f => f[0] === 'journey') || [])[2]
      const stageId = (filters.find(f => f[0] === 'stage') || [])[2]

      return await api('/metrics/dashboard', {
        groupId: this.activeGroupId,
        filters: filters.filter(f => !['journey', 'stage'].includes(f[0])),
        funnelId: funnelId || this.$store.getters.mainJourneyId,
        stageId,
        cacheId,
        config,
        dateRanges: createDateRangeArray(config),
        timezone: this.$store.getters.groupTimezone, // Intl.DateTimeFormat().resolvedOptions().timeZone, // This is normally from the group but can be form the group document instead, defualts to PST
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.TripleInsights {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}
</style>
