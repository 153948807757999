import api from '@/helpers/api'

export default async function createGroup(emails, groupName, user) {
  const { data } = await api('/groups/new', {
    emails,
    groupName,
    senderName: user.auth.displayName,
    senderEmail: user.auth.email,
    senderUserId: user.uid,
  })

  return data
}
