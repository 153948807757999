<template lang="html">
  <IconifyIcon
    v-if="name1 && name1.indexOf(':') > -1 && typeof nameCalc === 'string'"
    class="transform"
    :icon="name1"
    :class="[
      loading,
      rotate ? (rotate.startsWith('-') ? `-rotate-${rotate.slice(1)}` : `rotate-${rotate}`) : '',
      {
        spin: spinCalc,
      },
    ]"
  />
  <!-- <span class="iconify" v-if="name.indexOf(':') > -1" :data-icon="name"></span> -->

  <FAIcon
    v-else-if="!useFeather"
    class="Icon"
    :icon="nameCalc"
    :class="{
      spin: spinCalc,
      large: loading,
    }"
    @click="click"
  />
  <component
    class="Icon"
    v-else-if="featherComponentName"
    :is="featherComponentName"
    :style="{ color }"
    @click="click"
  />
  <div v-else></div>
</template>

<script>
// import * as VueFeatherIcons from 'vue-feather-icons'
import { Icon } from '@iconify/vue2'

export default {
  components: {
    // ...VueFeatherIcons,
    IconifyIcon: Icon,
  },
  props: {
    useFeather: Boolean,
    name: {},
    color: String,
    click: {
      type: Function,
      default: () => {},
    },
    type: String,
    loading: Boolean,
    spin: Boolean,
    large: Boolean,
  },
  computed: {
    name1() {
      return (this.name && this.name.split('@')[0]) || null
    },
    nameCalc() {
      if (this.loading) return ['far', 'spinner-third']
      if (this.type) return [this.type, this.name]
      if (
        typeof this.name === 'string' &&
        ['far', 'fas', 'fal', 'fad'].includes(this.name.split(':')[0])
      )
        return this.name.split(':')

      if (this.name && ['fab-', 'far-', 'fal-', 'fad-'].includes(this.name.slice(0, 4))) {
        return [this.name.slice(0, 3), this.name.slice(4)]
      } else if (typeof this.name === 'string' && this.name.indexOf(':') === -1) {
        return ['far', this.name]
      } else {
        return this.name
      }
    },
    spinCalc() {
      return this.spin || this.loading
    },
    rotate() {
      return (this.name && this.name.split('@')[1]) || null
    },
    featherName() {
      return this.name
    },
    featherComponentName() {
      return this.featherName && `${this.featherName}-icon`
    },
  },
  created() {
    // Feather.replace()
  },
}
</script>

<style lang="scss">
@import './styles/Icon.scss';

.Icon {
  &.loading {
    font-size: 2rem;
  }
  &.large {
    font-size: 2rem;

    &.loading {
      font-size: 4rem;
    }
  }
}
</style>
