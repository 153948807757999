<template>
  <div class="OptionSelectorButtonEditor">
    <span class="fas fa-bars handle"></span>
    <div style="margin-bottom: 5px;">
      <a-tag closable @close="$emit('remove')">
        Button {{ buttonIndex + 1 }}{{ button.hide ? ' (Hidden)' : '' }}</a-tag
      >
    </div>
    <a-space direction="vertical">
      <template v-if="showingMoreOptions || (button.conditions && button.conditions.length)">
        <ConditionsEditor location="component" :conditions="button.conditions" />
      </template>
      <EditorInput
        _key="key"
        isKey
        placeholder="Button Key"
        :noLabel="!showingMoreOptions"
        :component="button"
      />
      <div v-if="component.multiple" class="flex flex-row items-center">
        <EditorCheckbox _key="single_select" label="Button is single select" />
      </div>
      <a-alert
        v-if="isDuplicate"
        type="error"
        message="Key is used by another button in this set"
      />
      <EditorInput
        _key="text"
        placeholder="Button Text"
        :noLabel="!showingMoreOptions"
        :component="button"
      />
      <EditorInput
        _key="description"
        placeholder="Button Description"
        :noLabel="!showingMoreOptions"
        :component="button"
      />
      <template v-if="showingMoreOptions">
        <a-space>
          <EditorIcon _key="icon" :component="button" />
          <EditorInput _key="icon" placeholder="Icon Key" :component="button" />
          <EditorInput _key="emojiIcon" placeholder="Emoji Icon" :component="button" />
          <EditorInput _key="imageUrl" placeholder="Image Url" :component="button" />
        </a-space>
        <EditorCheckbox _key="hide" label="Hide Button" />
        <EditorSelect
          v-if="!component.multiple"
          label="On Click Event"
          _key="triggerEvent"
          defaultVal="next-page"
          :options="actions"
        />
        <template v-if="button.triggerEvent === 'open-url'">
          <EditorInput
            _key="url"
            placeholder="Url to open when clicked"
            allowComputed
            :component="button"
          />
          <EditorCheckbox _key="openUrlInNewTab" label="Open in New Tab" />
        </template>
        <template v-if="button.triggerEvent === 'next-page'">
          <EditorCheckbox _key="hide_if_no_nav_target" label="Hide button if no next page" />
        </template>
        <a-button size="small" @click="showingMoreOptions = false">- Fewer button options</a-button>
      </template>
      <a-button v-else size="small" @click="showingMoreOptions = true"
        >+ More button options</a-button
      >
    </a-space>
  </div>
</template>

<script>
import ConditionsEditor from './ConditionsEditor'

export default {
  name: 'OptionSelectorButtonEditor',
  components: {
    ConditionsEditor,
  },
  inject: ['_getBasePath', '_updateReactive'],
  provide() {
    return {
      _getBasePath: () => this.basePath,
    }
  },
  props: {
    button: Object,
    component: Object,
    buttonId: String,
    buttonIndex: Number,
    isDuplicate: Boolean,
  },
  data() {
    return {
      showingMoreOptions: false,
    }
  },
  computed: {
    basePath() {
      return `${this._getBasePath()}.buttons.${this.buttonIndex}`
    },
    actions() {
      return ['no-action', 'next-page', 'open-url']
    },
  },
}
</script>
