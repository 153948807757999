import Firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/auth'
import 'firebase/firestore'
import api from '@/helpers/api'
import notify from '@/helpers/notify'

// EXTERNAL

export function init({ setUser, cb, setWarrant }) {
  Firebase.analytics()
  Firebase.auth().useDeviceLanguage()

  if (process.env.VUE_APP_FIREBASE_EMULATE) {
    Firebase.auth().useEmulator('http://localhost:9099/')
    Firebase.firestore().useEmulator('localhost', 8888)
    Firebase.functions().useEmulator('localhost', 5001)
  }

  completeEmailSignInIfNecessary()

  const unsubscribe = Firebase.auth().onAuthStateChanged(user =>
    user ? fetchConstructAndSaveUser({ setUser, cb, setWarrant }) : setUser(null)
  )

  Firebase.auth().getRedirectResult()
  return unsubscribe
}

export function login({ provider, email, phoneNumber }) {
  const signIn = getSignInMethod({ provider, email, phoneNumber })

  if (!signIn) return

  return signIn()
    .then(function(result) {
      if (!result) return
    })
    .catch(function(error) {
      // Handle Errors here.
      console.error('Firebase Auth Error:', error)

      throw error
    })
}

export function logout() {
  return Firebase.auth().signOut()
}

export async function fetchConstructAndSaveUser({ setUser, cb, setWarrant }) {
  try {
    const user = await constructAndSetUserGradually(setUser, cb, setWarrant)

    if (user) {
      saveUserInfoToDb(user)
    }
    return user
  } catch (error) {
    console.error(error)

    if (window.location.hostname !== 'localhost') await notify(error.message)
    // await logout()
  }
}

export function setUpRecapture() {
  window.recaptchaVerifier = new Firebase.auth.RecaptchaVerifier('sign-in-button', {
    size: 'invisible',
    callback: function() {},
  })
}

export function completeLogin({ code }) {
  return window.confirmationResult
    .confirm(code)
    .then(function() {
      // User signed in successfully.
      // ...
    })
    .catch(function(error) {
      console.error('error', error)
      // User couldn't sign in (bad verification code?)
      // ...
    })
}

// INTERNAL

async function constructAndSetUserGradually(setUser, cb, setWarrant) {
  const firebaseUser = Firebase.auth().currentUser

  // if (Firebase.messaging.isSupported()) generateMessagingToken()

  let user = constructUser(firebaseUser)

  if (!firebaseUser) return null

  const idTokenResult = await Firebase.auth().currentUser.getIdTokenResult()

  user = constructUser(firebaseUser, idTokenResult)

  setUser(user)

  const { data } = await api('/user/serve')
  const hasGroup = data.hasGroup
  if (data.warrantToken && typeof setWarrant === 'function') setWarrant(data.warrantToken)
  if (cb) cb(hasGroup)

  // user = constructUser(firebaseUser, idTokenResult)

  if (data.tokens) user.tokens = data.tokens

  return user
}

function constructUser(firebaseUser, idTokenResult) {
  if (!firebaseUser) return null

  const claims = idTokenResult ? idTokenResult.claims : null

  return {
    uid: firebaseUser.uid,
    picture: firebaseUser.photoURL,
    auth: {
      displayName: firebaseUser.displayName,
      photoURL: firebaseUser.photoURL,
      email: firebaseUser.email,
      emailVerified: firebaseUser.emailVerified,
      phoneNumber: firebaseUser.phoneNumber,
      isAnonymous: firebaseUser.isAnonymous,
      tenantId: firebaseUser.tenantId,
    },
    claims,
  }
}

function saveUserInfoToDb(user) {
  Firebase.firestore()
    .collection('users')
    .doc(user.uid)
    .set(user, { merge: true })
    .catch(error => console.error('Error in saveUserInfoToDb', error))
}

function getSignInMethod({ provider, email, phoneNumber }) {
  switch (provider) {
    case 'facebook':
    case 'google': {
      const firebaseProvider = getFirebaseProvider(provider)

      return function() {
        // const functionKey = window.interop ? 'signInWithRedirect' : 'signInWithPopup'
        const functionKey = 'signInWithRedirect'

        return Firebase.auth()[functionKey](firebaseProvider)
      }
    }

    case 'email': {
      return function() {
        const actionCodeSettings = getActionCodeSettings()
        return Firebase.auth()
          .sendSignInLinkToEmail(email, actionCodeSettings)
          .then(function() {
            // The link was successfully sent. Inform the user.
            // Save the email locally so you don't need to ask the user for it again
            // if they open the link on the same device.
            window.localStorage.setItem('emailForSignIn', email)
          })
          .catch(function(error) {
            console.error('error', error)
            console.error('error.code', error.code)
            // Some error occurred, you can inspect the code: error.code
          })
      }
    }

    case 'phone': {
      return () => signInWithPhone(phoneNumber)
    }

    default:
      return null
  }
}

function getFirebaseProvider(provider) {
  switch (provider) {
    case 'facebook':
      return new Firebase.auth.FacebookAuthProvider()
    case 'google':
      return new Firebase.auth.GoogleAuthProvider()
  }
}

function getActionCodeSettings() {
  return {
    url: `${window.location.origin}/finishSignIn`,
    handleCodeInApp: true,
  }
}

export function signInWithPhone(phoneNumber) {
  // const phoneNumber = '+1 650-555-2218' // window.prompt('Enter your mobile number:')
  window.phoneNumber = phoneNumber

  var appVerifier = window.recaptchaVerifier

  return Firebase.auth()
    .signInWithPhoneNumber(phoneNumber, appVerifier)
    .then(function(confirmationResult) {
      // SMS sent. Prompt user to type the code from the message, then sign the
      // user in with confirmationResult.confirm(code).
      window.confirmationResult = confirmationResult
    })
    .catch(function(error) {
      console.error('error', error)
      // Error; SMS not sent
      // ...
    })
}

function completeEmailSignInIfNecessary() {
  // Confirm the link is a sign-in with email link.
  if (Firebase.auth().isSignInWithEmailLink(window.location.href)) {
    // Additional state parameters can also be passed via URL.
    // This can be used to continue the user's intended action before triggering
    // the sign-in operation.
    // Get the email if available. This should be available if the user completes
    // the flow on the same device where they started it.
    var email = window.localStorage.getItem('emailForSignIn')

    if (!email) {
      // User opened the link on a different device. To prevent session fixation
      // attacks, ask the user to provide the associated email again. For example:
      email = window.prompt('Please provide your email for confirmation')
    }
    // The client SDK will parse the code from the link for you.
    Firebase.auth()
      .signInWithEmailLink(email, window.location.href)
      .then(function() {
        // Clear email from storage.
        window.localStorage.removeItem('emailForSignIn')
        // You can access the new user via result.user
        // Additional user info profile not available via:
        // result.additionalUserInfo.profile == null
        // You can check if the user is new or existing:
        // result.additionalUserInfo.isNewUser
      })
      .catch(function(error) {
        console.error('error', error)

        // Some error occurred, you can inspect the code: error.code
        // Common errors could be invalid email and invalid or expired OTPs.
      })
  }
}
