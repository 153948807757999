<template>
  <div class="FormEditorView p-2 overflow-hidden">
    <div
      id="editor-left-sidebar"
      class="editor-wrapper relative m-2 mr-0 border border-gray-200 rounded-lg bg-white shadow-lg"
      :class="{ visible: showLeftBar, minimized }"
    >
      <NavSidebar
        v-if="form"
        v-bind="{ form, currentPageId, userData, version, noAuthComments, comments, savedForm }"
      />
      <!-- <SlushieMonitor
        v-if="coldUpdates"
        :changes="hasChanges"
        :resetTimer="forceResetDuration"
        @unfreeze="loadFrozenForm"
        @timer-reset="pendingChange = false"
      /> -->
    </div>
    <div id="editor-center" class="preview relative overflow-hidden mx-2" style="flex: 1;">
      <!-- <div v-if="coldUpdates" class="frozen-alert" @click="setFreezeStatus">
        <a-alert message="Frozen" type="info" />
      </div> -->
      <!-- <a-button
        v-if="isAdmin"
        icon="edit"
        shape="circle"
        size="large"
        class="editing-button"
        :type="editing ? 'primary' : 'default'"
        @click="toggleEditing"
      /> -->
      <!-- <a-button
        v-if="isAdmin"
        icon="edit"
        shape="circle"
        size="large"
        class="editing-button left"
        :type="selectionMode ? 'primary' : 'default'"
        @click="togglePickerMode"
      /> -->
      <!-- isPreview ? {} :  -->
      <!-- <QuickHelp v-bind="{ quickHelp }" /> -->
      <EditorToolbar
        v-if="form && form.pages && ((selectedComponentId && !componentIsDefault) || anchorToolbar)"
        :anchored="anchorToolbar"
        name="main"
        :class="{ invisible: anchorToolbar && !(selectedComponentId && !componentIsDefault) }"
        v-bind="{ form, userData }"
        @anchor="anchorToolbar = $event"
      />
      <EditorToolbar
        v-if="form && form.pages && ((selectedComponentId && !componentIsDefault) || anchorToolbar)"
        name="sub"
        v-bind="{ form, userData }"
        location="bottom"
      />
      <div
        class="viewport scroller max-h-full my-2 border border-gray-200 rounded-lg bg-gray-50 shadow-lg"
        :class="`viewport-${viewport.split(':').join('-')}`"
        @click="onSelectCanvasSpace"
      >
        <button v-if="tempForm && tempForm.popup" @click="popupOpen = true">Open Popup</button>
        <SignpostForm
          v-if="tempForm"
          isInWebApp
          :formProp="tempForm"
          :userDataProp="userData"
          :showHidden="showHiddenPages"
          :showHiddenComponents="showHiddenComponents"
          v-bind="{ id, popupOpen, version }"
          @update-form="onUpdateForm"
          @data-update="setUserData"
          @pagesUpdate="pages = $event"
          @destinationsUpdate="destinations = $event"
          @responsiveGroups="responsiveGroups = $event"
          @popup-update="popupOpen = $event"
        />
      </div>
    </div>
    <div
      class="editor-wrapper rhs relative flex flex-col"
      :class="{ visible: !isPreview && showRightBar, minimized }"
    >
      <!-- v-if="!noAuthComments && showRightBar !== 'comments'" -->
      <SignpostFormEditor
        :showingPages="pages"
        :eligibleDestinations="destinations"
        v-bind="{
          id,
          userData,
          viewport,
          form,
          responsiveGroups,
          minimized,
          isPreview,
          coldUpdates,
          unsavedChanges,
          noAuthComments,
        }"
        @minimize="minimized = $event"
        @update="onEditorFormUpdate"
        @updated-form="form = $event"
        @set-user-data="setUserData"
        @freeze="setFreezeStatus"
        @isDraggable="isDraggable = $event"
        @version-select="onVersionSelect"
      />
      <!-- <div
        v-else
        class="overflow-auto flex flex-col flex-1 shadow-lg bg-white border border-gray-200 rounded-lg m-2"
        :class="{ minimized, isPreview: 'opacity-0' }"
      >
        <div class="flex justify-between m-4 mb-0">
          <div class="font-bold text-lg text-gray-500 capitalize pl-0.5 capitalize">
            <Icon class="mr-1" :name="showRightBar === 'comments' ? 'comments' : 'chart-pie'" />
            {{ showRightBar }}
          </div>
          <div
            class="flex items-center cursor-pointer"
            @click="$store.commit('setRightBar', false)"
          >
            <Icon class="mr-1" name="times" />
            Close
          </div>
        </div>
        <CommentEditor
          v-if="showRightBar === 'comments'"
          v-bind="{ form, id, currentPageId, pages, noAuthComments, userData, comments, flowGroupId }"
        />
        <FlowAnalytics
          v-else
          v-bind="{ form, id, currentPageId, pages, noAuthComments, userData, comments, flowGroupId }"
        />
      </div> -->
    </div>
    <div
      v-if="!noAuthComments && !previewMode"
      class="rhs-floating-buttons flex flex-col items-center"
      style="z-index: 100;"
    >
      <button
        class="my-1 p-1 flex flex-col justify-center items-center text-xs border-none group"
        @click="toggleRightBar('options')"
      >
        <div
          class="w-10 h-10 m-1 flex justify-center items-center shadow rounded-full bg-white group-hover:shadow-md transition duration-150 ease-in-out"
          :class="{
            'bg-purple': showRightBar === 'options',
            'text-white': showRightBar === 'options',
          }"
        >
          <Icon type="far" name="tools" class="text-md transition duration-150 ease-in-out" />
        </div>
        <div class="my-1">
          Options
        </div>
      </button>
      <button
        :disabled="!paneViewButtonsEnabled.design"
        class="my-1 p-1 flex flex-col justify-center items-center text-xs border-none group"
        :class="{
          'opacity-30': !paneViewButtonsEnabled.design,
          'cursor-not-allowed': !paneViewButtonsEnabled.design,
        }"
        @click="toggleRightBar('design')"
      >
        <div
          class="w-10 h-10 m-1 flex justify-center items-center shadow rounded-full bg-white group-hover:shadow-md transition duration-150 ease-in-out"
          :class="{
            'bg-purple': showRightBar === 'design',
            'text-white': showRightBar === 'design',
          }"
        >
          <Icon type="far" name="paint-brush" class="text-md transition duration-150 ease-in-out" />
        </div>
        <div class="my-1">
          Design
        </div>
      </button>
      <button
        :disabled="!paneViewButtonsEnabled.triggers"
        class="my-1 p-1 flex flex-col justify-center items-center text-xs border-none group"
        :class="{
          'opacity-30': !paneViewButtonsEnabled.triggers,
          'cursor-not-allowed': !paneViewButtonsEnabled.triggers,
        }"
        @click="toggleRightBar('triggers')"
      >
        <div
          class="w-10 h-10 m-1 flex justify-center items-center shadow rounded-full bg-white group-hover:shadow-md transition duration-150 ease-in-out"
          :class="{
            'bg-purple': showRightBar === 'triggers',
            'text-white': showRightBar === 'triggers',
          }"
        >
          <Icon type="far" name="random" class="text-md transition duration-150 ease-in-out" />
        </div>
        <div class="my-1">
          Triggers
        </div>
      </button>
      <div class="mt-6 mb-5  border-b w-1/2 border-gray-300"></div>
      <button
        class="my-1 p-1 flex flex-col justify-center items-center text-xs border-none group"
        :class="{
          'opacity-30': !paneViewButtonsEnabled.comments,
          'cursor-not-allowed': !paneViewButtonsEnabled.comments,
        }"
        @click="toggleRightBar('comments')"
      >
        <div
          class="w-10 h-10 m-1 flex justify-center items-center shadow rounded-full bg-white group-hover:shadow-md transition duration-150 ease-in-out relative"
          :class="{
            'bg-purple': showRightBar === 'comments',
            'text-white': showRightBar === 'comments',
          }"
        >
          <Icon type="far" name="comments" class="text-md transition duration-150 ease-in-out" />
          <div
            v-if="hasComments"
            class="absolute element-bubble"
            style="top: 0; right: 0; background: orange"
          ></div>
        </div>
        <div class="my-1">
          Comments
        </div>
      </button>
      <button
        :disabled="!paneViewButtonsEnabled.analytics"
        class="my-1 p-1 flex flex-col justify-center items-center text-xs border-none group"
        :class="{
          'opacity-30': !paneViewButtonsEnabled.analytics,
          'cursor-not-allowed': !paneViewButtonsEnabled.analytics,
        }"
        @click="toggleRightBar('analytics')"
      >
        <div
          class="w-10 h-10 m-1 flex justify-center items-center shadow rounded-full bg-white group-hover:shadow-md transition duration-150 ease-in-out"
          :class="{
            'bg-purple': showRightBar === 'analytics',
            'text-white': showRightBar === 'analytics',
          }"
        >
          <Icon type="far" name="chart-pie" class="text-md transition duration-150 ease-in-out" />
        </div>
        <div class="my-1">
          Analytics
        </div>
      </button>
      <button
        v-if="flag_experiments"
        :disabled="!paneViewButtonsEnabled.experiments"
        class="my-1 p-1 flex flex-col justify-center items-center text-xs border-none group"
        :class="{
          'opacity-30': !paneViewButtonsEnabled.experiments,
          'cursor-not-allowed': !paneViewButtonsEnabled.experiments,
        }"
        @click="toggleRightBar('experiments')"
      >
        <div
          class="w-10 h-10 m-1 flex justify-center items-center shadow rounded-full bg-white group-hover:shadow-md transition duration-150 ease-in-out"
          :class="{
            'bg-purple': showRightBar === 'experiments',
            'text-white': showRightBar === 'experiments',
          }"
        >
          <Icon type="far" name="flask" class="text-md transition duration-150 ease-in-out" />
        </div>
        <div class="my-1">
          Experiments
        </div>
      </button>
      <button
        class="my-1 p-1 flex flex-col justify-center items-center text-xs border-none group"
        :class="{
          'opacity-30': !paneViewButtonsEnabled.experiments,
          'cursor-not-allowed': !paneViewButtonsEnabled.experiments,
        }"
        @click="togglePapercups"
      >
        <div
          class="w-10 h-10 m-1 flex justify-center items-center shadow rounded-full bg-white group-hover:shadow-md transition duration-150 ease-in-out"
          :class="{
            'bg-purple': showRightBar === 'experiments',
            'text-white': showRightBar === 'experiments',
          }"
        >
          <Icon
            type="fad"
            name="comments-alt"
            class="text-md transition duration-150 ease-in-out"
          />
        </div>
        <div class="my-1">
          Chat
        </div>
      </button>
      <TemplateEditor :form="form" @flow="onTemplateApply" />
    </div>
    <Popup
      isFlush
      :isOpen="typeof compareVersionId === 'string'"
      noButton
      @close="compareVersionId = null"
    >
      <JsonCompare :oldData="comparisonData[0]" :newData="comparisonData[1]" />
    </Popup>
    <Popup isFlush :isOpen="Boolean(dataPath.path !== undefined)" noButton @close="dataPath = {}">
      <JsonEditor :code="dataPath.code" v-model="jsonData" :data="dataPath" />
    </Popup>
  </div>
</template>

<script>
import Firebase from 'firebase/app'
import 'firebase/firestore'
import { mapGetters, mapState } from 'vuex'
import { get } from 'dot-prop'
import JsonCompare from 'vue-json-compare'
import Split from 'split.js'

import emptyUserData from '@/helpers/emptyUserData'
import { defaultHeadingComponents } from '@/components/form/helpers/defaultComponents'
// import SlushieMonitor from './editor/SlushieMonitor'
// import FormVersionViewer from './editor/FormVersionViewer'
import SignpostFormEditor from './editor/SignpostFormEditor'
import SignpostForm from './SignpostForm'
import FormEditorMixin from './FormEditorMixin'
// // import QuickHelp from './QuickHelp'
import repeatPage from '@/components/form/helpers/repeatPages'
import NavSidebar from './editor/NavSidebar'
import EditorToolbar from './editor/mainEditor/EditorToolbar.vue'
import JsonEditor from './editor/JsonEditor.vue'
import TemplateEditor from './editor/mainEditor/TemplateEditor.vue'
// import CommentEditor from './editor/mainEditor/CommentEditor.vue'
// import FlowAnalytics from './editor/mainEditor/FlowAnalytics.vue'

import { FLOWS_COLLECTION } from '@/constants'
import { passesConditions } from './helpers/conditions'
export default {
  name: 'FormEditorView',
  mixins: [FormEditorMixin],
  components: {
    EditorToolbar,
    SignpostFormEditor,
    SignpostForm,
    // CommentEditor,
    // FlowAnalytics,
    // SlushieMonitor,
    // FormVersionViewer,
    // QuickHelp,
    JsonCompare,
    NavSidebar,
    JsonEditor,
    TemplateEditor,
  },
  provide() {
    return {
      _isDraggable: () => this.isDraggable,
      _updateDraggable: val => (this.isDraggable = val),
      _updateGrid: grid => (this.currentGrid = grid),
      _getCurrentGrid: () => this.currentGrid,
      _isEditing: () => this.editing,
      _inspectData: () => this.inspectData,
      _inspectMode: () => this.inspectMode,
      _toggleInspect: this.toggleInspect,
      _disableDelays: () => this.disableDelay,
      _toggleDisableDelays: () => (this.disableDelay = !this.disableDelay),
      _setInspectData: this.setInspectData,
      _clearInspect: this.clearInspect,
      _getHoveredComponentId: () => this.hoveredComponentId,
      _getSelectedComponentId: () => this.selectedComponentId,
      _setHoveredComponentId: this.setHoveredComponentId,
      _setSelectedComponentId: this.setSelectedComponentId,
      _getSelectionMode: () => this.selectionMode,
      _setSelectionMode: this.togglePickerMode,
      _setUserData: this.setUserData,
      _resetUserData: this.reset,
      _setShowHiddenPages: v => (this.showHiddenPages = v),
      _setShowHiddenComponents: v => (this.showHiddenComponents = v),
      _setQuickHelp: qh => (this.quickHelp = qh),
      _isInEditor: () => (process.env.VUE_APP_TEST_BLOCKER ? false : true),
      _getFlowPages: visibility => (visibility ? this.visiblePages : this.allFlowPages),
      _getPreviewMode: () => this.previewMode,
      _openDataPath: e => (this.dataPath = e),
      _getCommentEditorProps: () => this.commentEditorProps,
    }
  },
  inject: {
    _register: { default: () => () => {} },
    _loadWarrantToken: { default: () => () => {} },
    // _registerButton: { default: () => () => {} },
    // _setButtonState: { default: () => () => {} },
  },
  props: {
    id: String,
    noAuthComments: Boolean,
    insideEditor: Boolean,
  },
  data() {
    return {
      flag_experiments: Boolean(localStorage.getItem('savvy_flag_experiments')),
      form: null,
      tempForm: null,
      formFreezer: null,
      p_userData: null,
      pages: [],
      destinations: [],
      responsiveGroups: [],
      isDraggable: false,
      // disableDelay: true,
      currentGrid: null,
      inspectMode: false,
      minimized: false,
      inspectData: null,
      inspectEl: null,
      popupOpen: false,
      hoveredComponentIds: [],
      selectedComponentId: null,
      // showHiddenPages: false,
      showHiddenComponents: false,
      quickHelp: null,
      previewMode: false,
      // mode: 'editing',
      editing: true,
      selectionMode: true,
      dataPath: {},
      anchorToolbar: false,
      init: true,
      comments: [],
      unsavedChanges: [],
      flowGroupId: null,
    }
  },
  computed: {
    ...mapState(['highestVersion', 'versionData', 'viewport', 'hasWarrantSessionToken']),
    version() {
      return this.versionData && this.versionData.version
    },
    ...mapGetters(['isAdmin']),
    isPreview() {
      return this.$route.path.endsWith('/preview') || !this.advancedMode
    },
    commentEditorProps() {
      return {
        form: this.form,
        id: this.id,
        currentPageId: this.currentPageId,
        pages: this.formPages,
        noAuthComments: this.noAuthComments,
        insideEditor: this.insideEditor,
        userData: this.userData,
        comments: this.comments,
        flowGroupId: this.flowGroupId,
      }
    },
    disableDelay: {
      get() {
        return this.$store.state.disableDelay || false
      },
      set(v) {
        this.$store.commit('setDisableDelay', v)
      },
    },
    showHiddenPages: {
      get() {
        return this.$store.state.showHiddenPages || false
      },
      set(v) {
        this.$store.commit('setShowHiddenPages', v)
      },
    },
    showLeftBar: {
      get() {
        return this.$store.state.showLeftBar
      },
      set(v) {
        this.$store.commit('setLeftBar', v)
      },
    },
    showRightBar: {
      get() {
        return this.$store.state.showRightBar
      },
      set(v) {
        this.$store.commit('setRightBar', v)
      },
    },
    advancedMode: {
      get() {
        return this.$store.state.advancedMode
      },
      set(v) {
        this.$store.commit('setAdvancedMode', v)
      },
    },
    mode: {
      get() {
        return this.$store.state.editorMode
      },
      set(v) {
        this.$store.commit('setEditorMode', v)
      },
    },
    userData: {
      get() {
        return this.p_userData || {}
      },
      set(v) {
        this.p_userData = v
      },
    },
    compareVersionId: {
      get() {
        return this.$store.state.compareVersionId || null
      },
      set(v) {
        this.$store.commit('setCompareVersionId', v)
      },
    },
    jsonData: {
      get() {
        return this.dataPath.path === '' ? this.form : get(this.form, this.dataPath.path, undefined)
      },
      set(v) {
        if (this.dataPath.path === '') {
          this.form = v
          this.debounceUpdateHistory()
        } else {
          this.updateReactive(null, this.dataPath.path, v)
        }
      },
    },
    comparisonData() {
      return this.$store.state.comparisonData
    },
    currentPageId() {
      const currentPageId = this.userData && this.userData.currentPageId
      return currentPageId !== undefined
        ? currentPageId
        : this.pages && this.pages[0] && this.pages[0].id
    },
    allFlowPages() {
      const pages = (this.form && this.form.pages) || []

      return this.previewMode && pages.some(p => p.repeater_key !== undefined)
        ? pages.reduce((acc, p) => {
            acc.push(...repeatPage(p, this.userData[p.repeater_key]))
            return acc
          }, [])
        : pages
    },
    visiblePages() {
      return this.allFlowPages.filter(e => !this.itemIsHidden(e))
    },
    pageInfo() {
      const currentPageId = this.currentPageId
      const pages = this.allFlowPages
      const currentPageIndex = pages.findIndex(p => p.id === currentPageId)
      const currentPage = pages[currentPageIndex]
      return { currentPage, currentPageIndex, currentPageId, pages }
    },
    hoveredComponentId() {
      return this.hoveredComponentIds[0]
    },
    componentIsDefault() {
      return Boolean(
        Object.values(defaultHeadingComponents()).find(c => c.id === this.selectedComponentId)
      )
    },
    paneViewButtonsEnabled() {
      return this.$store.state.paneViewButtons || {}
    },
    hasComments() {
      return this.comments.some(
        c =>
          c.version <= this.version &&
          c.location &&
          c.location.page === this.currentPageId &&
          !c.resolved &&
          !c.archived
      )
    },
    formPages() {
      return (this.form && this.form.pages) || []
    },
  },
  mounted() {
    this.onKeyDown = e => {
      if (e.key === 'Escape') {
        this.selectedComponentId = null
        return
      }
    }
    if (this.noAuthComments) {
      this.mode = 'use'
    }
    if (window.CommandBar) {
      window.CommandBar.addCallback('goToFlowPage', args => {
        this.setUserData({ ...this.userData, currentPageId: args.page.id })
      })
    }

    window.addEventListener('keydown', this.onKeyDown)
    this.deregisterAdvanced = this._register('toggleAdvancedMode', this.toggleAdvancedMode)
    this.deregisterPicker = this._register('setPickerMode', this.setPickerMode)
    this.initSplit()
    if (this.hasWarrantSessionToken === false) this._loadWarrantToken()
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.onKeyDown)
    if (window.CommandBar) {
      window.CommandBar.removeCallback('goToFlowPage')
      window.CommandBar.removeContext('currentFlowPages')
    }

    if (this.onRelease) document.removeEventListener('keyup', this.onRelease)
    if (this.deregisterAdvanced) this.deregisterAdvanced()
    if (this.deregisterPicker) this.deregisterPicker()
  },
  watch: {
    id: {
      handler(id) {
        if (id) this.bindVersionComments(id)
      },

      immediate: true,
    },
    formPages: {
      handler(v) {
        if (v.length > 0 && window.CommandBar) {
          window.CommandBar.addContext({
            currentFlowPages: this.form.pages.map((p, i) => ({
              label: p.key || p.id,
              id: p.id,
              key: p.key,
              index: 0,
              pos: i + 1,
            })),
          })
        }
      },
    },
    currentPageId: {
      handler(v, o) {
        if (v && o) {
          const globals = (this.form && this.form.components) || []
          const selectedComponentIsGlobal = Boolean(
            globals.find(gc => gc.id === this.selectedComponentId)
          )

          if (!selectedComponentIsGlobal) {
            this.selectedComponentId = null
          }
        }
      },
    },
    paneViewButtonsEnabled: {
      handler(v) {
        if (!v) return
        if (!v.triggers && this.showRightBar === 'triggers') this.showRightBar = 'options'
      },
    },
    mode: {
      handler(v, old) {
        this.setPickerMode(v, this.init)
        if (!this.init && old === 'preview' && v !== 'preview') this.showLeftBar = true
        // this.init = false
      },
      immediate: true,
    },
    showLeftBar: {
      handler(v) {
        if (v && !this.init) this.initSplit()
        else if (this.rmSplitInstance) this.rmSplitInstance()
      },
      immediate: true,
    },
  },
  methods: {
    itemIsHidden(item) {
      if (item.hide) return true
      return !passesConditions(item.conditions || [], this.userData, {}, true)
    },
    initSplit() {
      if (this.rmSplitInstance) this.rmSplitInstance()
      this.init = false
      const split = Split(['#editor-left-sidebar', '#editor-center'], {
        sizes: [16, 84],
        minSize: [143],
        expandToMin: true,
        snapOffset: 0,
        gutterAlign: 'start',
      })
      this.rmSplitInstance = () => {
        split.destroy()
        this.rmSplitInstance = null
      }
    },
    reloadDefaults() {
      const defaultUserData =
        (this.tempForm && this.tempForm.defaults && this.tempForm.defaults.userData) || {}
      const userData = this.p_userData
      Object.entries(defaultUserData).forEach(([k, v]) => {
        if (!userData[k]) this.$set(userData, k, v)
      })
    },
    reset() {
      const userData = emptyUserData(this.userData, this.form)

      this.setUserData(userData)
    },
    setUserData(userData) {
      if (userData) {
        this.userData = userData
      } else {
        const userData = {}
        if (this.userData.hasOwnProperty('is_test')) userData.is_test = this.userData.is_test
        this.userData = userData
      }
      this.reloadDefaults()
    },
    toggleInspect() {
      this.inspectMode = !this.inspectMode
    },
    clearInspect() {
      if (this.inspectEl) {
        this.inspectEl.e.style.boxShadow = this.inspectEl.shadow
        this.inspectEl.e.style.borderRadius = this.inspectEl.borderRadius
        this.inspectEl = null
      }
      this.inspectData = null
    },
    setInspectData(data, originals, prev) {
      if (!data) this.inspectData = null
      else {
        this.inspectEl = prev
        const styles = getComputedStyle(data)
        this.inspectData = Object.entries(styles).reduce((acc, [k, v]) => {
          if (!isNaN(+k)) return acc
          if (k.startsWith('webkit')) return acc
          if (originals[k]) acc[k] = originals[k]
          else acc[k] = `"${v}"`
          return acc
        }, {})
      }
    },
    toggleEditing(val) {
      return this.genericToggle('editing', val)
    },
    toggleAdvancedMode(val) {
      return this.genericToggle('advancedMode', val)
    },
    setHoveredComponentId(e, id, isOn) {
      if (isOn) this.hoveredComponentIds.push(id)
      else this.hoveredComponentIds = this.hoveredComponentIds.filter(_id => _id !== id)
    },
    onSelectCanvasSpace(e) {
      if (this.mode === 'editing' && this.popupOpen === false) this.setSelectedComponentId(e, null)
    },
    setSelectedComponentId(e, id) {
      if (e.stopPropagation) e.stopPropagation()
      if (e.preventDefault) e.preventDefault()
      this.selectedComponentId = id
      // this.selectionMode = false
      // this._setButtonState('component-options', false)
    },
    setPickerMode(mode) {
      // this.mode = mode
      switch (mode) {
        case 'use': {
          this.toggleEditing(false)
          this.togglePickerMode(false, true)
          this.toggleAdvancedMode(true)
          this.genericToggle('showHiddenPages', true)
          this.genericToggle('previewMode', false)
          // if (!init) this.initSplit()
          /* Handled in watcher for mode */
          // this.showLeftBar = true
          break
        }
        case 'preview': {
          this.toggleEditing(false)
          this.togglePickerMode(false)
          this.toggleAdvancedMode(false)
          this.genericToggle('previewMode', true)
          this.showHiddenPages = false
          this.showHiddenComponents = false
          this.showLeftBar = false
          // this.rmSplitInstance()
          break
        }

        case 'editing':
        default: {
          this.toggleEditing(true)
          this.togglePickerMode(true)
          this.toggleAdvancedMode(true)
          this.genericToggle('showHiddenPages', true)
          this.genericToggle('previewMode', false)
          // if (!init) this.initSplit()
          /* Handled in watcher for mode */
          // this.showLeftBar = true
          break
        }
      }
    },
    togglePickerMode(val, preserve) {
      this.genericToggle('selectionMode', val)
      if (!this.selectionMode && !preserve) {
        this.selectedComponentId = null
        this.hoveredComponentIds = []
      } else if (preserve && !this.selectionMode) {
        this.hoveredComponentIds = []
      }
    },
    genericToggle(key, val) {
      this[key] = val === undefined ? !this[key] : val
    },
    tempEnablePickerMode(event) {
      const mode = 'use'
      if (this.mode !== 'editing') return
      const keysArePressed =
        event.metaKey &&
        event.altKey &&
        event.shiftKey &&
        ['Alt', 'Meta', 'Shift'].includes(event.key)
      if (!keysArePressed) return
      this.tempMode = this.mode
      this.mode = mode
      this.tempState = { c: this.selectedComponentId, h: this.hoveredComponentIds }
      const onRelease = e => {
        const keysAreReleased =
          !e.metaKey && !e.altKey && !e.shiftKey && ['Alt', 'Meta', 'Shift'].includes(e.key)
        if (!keysAreReleased) return
        document.removeEventListener('keyup', onRelease)
        this.mode = this.tempMode
        this.tempMode = ''
        this.selectedComponentId = this.tempState.c
        this.hoveredComponentIds = this.tempState.h
      }
      if (this.onRelease) document.removeEventListener('keyup', onRelease)
      this.onRelease = onRelease
      document.addEventListener('keyup', onRelease)
    },
    toggleRightBar(key) {
      if (this.showRightBar === key) this.showRightBar = null
      else this.showRightBar = key
    },
    /* Moved outside the mixin to stop an error */
    bindVersionComments(id) {
      const ref = Firebase.firestore()
        .collection('forms')
        .doc(id)
        .collection('comments')
      this.$bind('comments', ref)
    },
    async loadForm(id, version) {
      const baseRef = Firebase.firestore()
        .collection(FLOWS_COLLECTION)
        .doc(id)
      const ref =
        typeof version === 'number'
          ? baseRef.collection('history').doc(`version-${version}`)
          : baseRef
      let doc = await ref.get()
      if (!doc.exists) {
        const docs = await baseRef
          .collection('history')
          .where('version', '==', version)
          .limit(1)
          .get()
        doc = docs.docs[0]
      }
      this.flowGroupId = doc.get('groupId')
      await this.$bind('unsavedChanges', baseRef.collection('history').where('saved', '==', false))
      const form =
        doc.get('json_storage_location') === 'kv'
          ? await fetch(
              `https://flow-storage.heysavvy.workers.dev/flows/${id}@${version}`
            ).then(res => res.json())
          : JSON.parse(doc.get('form') || '{}')
      this.savedForm = form
    },
    onTemplateApply(newForm) {
      this.form = newForm
      this.updateReactive(null, 'pages', newForm.pages || [])
    },
    togglePapercups() {
      window.dispatchEvent(new Event('papercups:open'))
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/_variables.scss';
@import '@/styles/flows.scss';

.FormEditorView {
  @include flex('row', 'stretch');
  height: 100%;

  .preview {
    position: relative;

    > .frozen-alert {
      position: absolute;
      top: 0;
    }

    > .editing-button {
      position: fixed;
      width: 50px;
      height: 50px;
      bottom: 20px;
      right: 80px;
      z-index: 200;

      &.left {
        right: 150px;
      }
    }
  }

  .gutter {
    cursor: col-resize;
  }

  // .ant-tabs-nav-scroll {
  //   display: flex;
  //   justify-content: center;
  // }

  .viewport {
    margin-right: auto;
    margin-left: auto;
    background-color: white;
    height: calc(100% - 1rem);
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAvSURBVHgB7dRBDQBACAPB3gX/PgkigIRggH47Ava5LxsIHyQFFFBgGI7cgwvsBApBdgjb2UUpBAAAAABJRU5ErkJggg==');
  }
  .viewport-regular {
    max-width: 1000px;
  }
  .viewport-tablet {
    max-width: 640px;
  }
  .viewport-mobile {
    max-width: 400px;
  }
  .viewport-mobile-iphone_12 {
    max-width: 390px;
  }
  .viewport-mobile-google_pixel_3 {
    max-width: 393px;
  }
  .viewport-mobile-plus-margin {
    max-width: 360px;
  }

  @media (max-width: 600px) {
    .SignpostFormEditor {
      display: none;
    }
  }

  .editor-wrapper {
    width: 0px;
    overflow: hidden;
    transition: width 0.15s ease-out;

    &.visible {
      overflow: visible;
      width: 180px;
      z-index: 200;
    }
    &.visible.rhs {
      width: 336px;
    }

    &.minimized {
      width: 50px;
      transition: width 0.15s ease-out;
      /* margin-left: -270px; */
    }
  }
  .frozen-alert {
    z-index: 200;
  }
}
</style>
