<template>
  <div class="flex items-center monospaced font-bold text-xs">
    <PlainEditableText
      :key="rerender"
      class="flex items-center"
      :isEditable="!disabled"
      :initialText="(page || component) && (page || component).key"
      :placeholder="`${page ? 'Page' : 'Component'} Key`"
      @update-text="onUpdate"
    />
  </div>
</template>

<script>
import PlainEditableText from '@/components/utilities/PlainEditableText'
import { snakeCase } from 'lodash'

export default {
  components: {
    PlainEditableText,
  },
  props: {
    page: Object,
    component: Object,
    disabled: Boolean,
  },
  data() {
    return { rerender: 0 }
  },
  methods: {
    onUpdate(val) {
      if (!val) {
        this.$message.warning('Keys cannot be blank')
        this.rerender += 1
        return
      }
      // @update-text="e => $emit('update', ['key', e])"
      const newVal = typeof val === 'string' ? snakeCase(val.trim()) : val
      this.$emit('update', ['key', newVal])
    },
  },
}
</script>
