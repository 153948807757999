<template>
  <div
    class="SmartContent"
    :class="[path, `type-${type}`, `content-${textKey}`, { hidden, isEditable, isEmpty }]"
    :key="id"
    @click.meta.alt.shift="openInFirebase"
  >
    <Entity v-if="useLocal" v-bind="{ collection, id }" v-model="data" />
    <template v-if="customInput">
      <a-dropdown v-if="customInput === 'Dropdown' && isEditable">
        <a-menu slot="overlay" @click="updateValue">
          <a-menu-item v-for="button in inputProps.buttons" :key="button.value">
            {{ button.text }}
          </a-menu-item>
        </a-menu>
        <a-button style="margin-left: 8px">{{ findButtonText(val) }}</a-button>
      </a-dropdown>
      <component :is="customInput" v-else-if="isEditable" v-model="val" v-bind="inputProps" />
      <component
        :is="customComponent"
        v-else-if="val !== undefined && customComponent"
        v-bind="componentProps(val)"
      />
      <div v-else>{{ (val && val.text ? val.text : val) || defaultVal }}</div>
    </template>
    <template v-else-if="defaultVal && !val && !isEditable">{{ defaultVal }}</template>
    <EditableText
      v-else
      content
      deleteOnEmpty
      :placeholder="placeholderCalc"
      :customComponent="Boolean(customComponent)"
      :customInput="Boolean(customInput)"
      v-bind="{ collection, id, initialText, textArea, isEditable, textKey, icon }"
    >
      <template v-slot:default="slotData">
        <component
          :is="customComponent"
          v-if="customComponent"
          v-bind="componentProps(slotData && slotData.value)"
        />
      </template>
    </EditableText>
  </div>
</template>

<script>
import get from 'lodash/get'
import Firebase from '@firebase/app'
import '@firebase/firestore'

import IconPicker from './IconPicker'

import { camelToProper } from '@/helpers/textStringConversions'
import { capitalize } from 'lodash'

// let Unsubscribe

let uuid = 0

export default {
  components: {
    IconPicker,
  },
  inject: {
    _isAdmin: { default: () => () => false },
    _editingMode: { default: () => () => false },
    _$bind: { default: () => false },
    _$unbind: { default: () => false },
    _$get: { default: () => false },
  },
  props: {
    type: String,
    path: String,
    initialText: String,
    textArea: Boolean,
    placeholder: String,
    icon: String,
    hidden: Boolean,
    private: Boolean,
    buttons: { type: Array, default: () => [] },
    useLocal: Boolean,
    defaultVal: {},
  },
  data() {
    return {
      data: null,
      dataCloned: null,
    }
  },
  computed: {
    activeGroupId() {
      return this.$store.getters.activeGroupId
    },
    dataStoreKey() {
      return this.collection + '/' + this.id
    },
    dataStore() {
      return this._$get
        ? this._$get(this.dataStoreKey)
        : this.$store.state.smartContent[this.dataStoreKey]
    },
    isEmpty() {
      return this.val === undefined
    },
    val: {
      get() {
        if (this.dataStore && !this.useLocal) return get(this.dataStore, this.textKey)
        return this.data ? get(this.data, this.textKey) : undefined
      },
      async set(val) {
        if (val === undefined) val = null

        const exists = (
          await Firebase.firestore()
            .collection(this.collection)
            .doc(this.id)
            .get()
        ).exists

        if (!exists) {
          await Firebase.firestore()
            .collection(this.collection)
            .doc(this.id)
            .set({})
        }

        Firebase.firestore()
          .collection(this.collection)
          .doc(this.id)
          .update({
            [this.textKey]: val,
          })
      },
    },
    collection() {
      let path = ['content']

      if (this.private) path = path.concat(['private', 'groups', this.activeGroupId])

      path = path.concat(this.path.split('/').slice(0, -2))

      return path.join('/')
    },
    id() {
      return this.path.split('/').slice(-2)[0]
    },
    textKey() {
      return this.path.split('/').slice(-1)[0]
    },
    isEditable() {
      return Boolean(this._isAdmin() && this._editingMode())
    },
    placeholderCalc() {
      return this.isEditable
        ? this.placeholder ||
            camelToProper(this.textKey)
              .split('.')
              .map(capitalize)
              .join(': ')
        : ''
    },
    customComponent() {
      switch (this.type) {
        case 'service-icon':
          return 'img'
        case 'dropdown':
        case 'color':
          return null
        case 'slider':
          return 'ToggleSwitch'

        default:
          return this.type
      }
    },
    customInput() {
      switch (this.type) {
        case 'icon':
          return 'IconPicker'
        case 'slider':
          return 'ToggleSwitch'
        case 'dropdown':
        case 'service-icon':
          return 'Dropdown'
        case 'color':
          return 'input'

        default:
          return false
      }
    },
    inputProps() {
      switch (this.type) {
        case 'service-icon':
          return {
            isRadio: true,
            buttons: [
              { text: 'Google', value: '/images/icons/google.svg' },
              { text: 'Webflow', value: '/images/icons/webflow.png' },
              { text: 'Facebook', value: '/images/icons/facebook.png' },
              { text: 'Code', value: '/images/icons/code.png' },
            ],
          }
        case 'dropdown':
          return { isRadio: true, buttons: this.buttons }
        case 'color':
          return { type: 'color' }
        default:
          return {}
      }
    },
  },
  beforeCreate() {
    this.uuid = uuid.toString()
    uuid += 1
  },
  beforeDestroy() {
    this.unbind()
    // this.$store.dispatch('smartContent/unbind', { key: this.dataStoreKey, cid: this.uuid })
  },
  watch: {
    dataStoreKey: {
      handler() {
        this.unbind()
        this.bind()
        // const { collection, id } = this
        // this.$store.dispatch('smartContent/unbind', { key: p, cid: this.uuid })
        // this.$store.dispatch('smartContent/bind', {
        //   key: p,
        //   cid: this.uuid,
        //   ref: Firebase.firestore()
        //     .collection(collection)
        //     .doc(id),
        // })
      },
      immediate: true,
    },
    val: {
      handler(val, oldVal) {
        if (val !== oldVal) this.$emit('input', val)
      },
      immediate: true,
    },
  },
  methods: {
    bind() {
      const ref = Firebase.firestore()
        .collection(this.collection)
        .doc(this.id)
      const payload = { key: this.dataStoreKey, cid: this.uuid, ref }
      if (this._$bind) this._$bind(payload)
      else this.$store.dispatch('smartContent/bind', payload)
    },
    unbind() {
      const payload = { key: this.dataStoreKey, cid: this.uuid }
      if (this._$unbind) this._$unbind(payload)
      else this.$store.dispatch('smartContent/unbind', payload)
    },
    updateValue(e) {
      this.val = e.key
    },
    findButtonText() {
      const button = this.inputProps.buttons.find(b => b.value === this.val)
      return button ? button.text : 'None'
    },
    componentProps(value) {
      switch (this.customComponent) {
        case 'icon':
          return {
            name: value,
          }
        case 'img':
          return {
            src: value,
            class: this.type === 'service-icon' ? 'small' : '',
          }

        case 'ToggleSwitch':
          return { static: true, initialOn: value }

        default:
          return {}
      }
    },
    openInFirebase() {
      if (!this.$store.getters.isAdmin) return

      window.open(
        `https://console.firebase.google.com/u/0/project/savvy-app-live/firestore/${encodeURIComponent(
          `data/${this.collection}/${this.id}`
        )}`,
        '_blank'
      )
    },
  },
}
</script>

<style lang="scss">
@import './styles/SmartContent.scss';
</style>
