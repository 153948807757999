var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-popover',{attrs:{"placement":"bottomRight","trigger":"click"},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('template',{slot:"title"},[_c('div',{staticClass:"config-header"},[_c('span',[_vm._v("Configure your Dashboard Card")]),(_vm.serviceConnectionStatus === 'not-connected')?_c('a-tooltip',[_c('template',{slot:"title"},[_vm._v(" A connection to "+_vm._s(_vm.serviceName)+" is required for configuration ")]),_c('span',{staticStyle:{"cursor":"pointer","margin-left":"5px"}},[_c('a-icon',{staticStyle:{"color":"orange"},attrs:{"type":"exclamation-circle"}})],1)],2):_c('a-tooltip',[_c('template',{slot:"title"},[_vm._v("Refresh Config Menus ")]),_c('span',{staticStyle:{"cursor":"pointer","margin-left":"5px"},on:{"click":function($event){return _vm.debounceGetConfigOptions(_vm.config)}}},[_c('a-icon',{attrs:{"type":"reload","spin":_vm.anyOptionLoading}})],1)],2)],1)]),_c('template',{slot:"content"},[_c('a-space',{attrs:{"direction":"vertical","size":"large"}},[(_vm.config)?_c('a-input',{attrs:{"size":"large","placeholder":"Card Title"},model:{value:(_vm.config.title),callback:function ($$v) {_vm.$set(_vm.config, "title", $$v)},expression:"config.title"}}):_vm._e(),_c('a-space',{attrs:{"direction":"vertical"}},[_vm._l((_vm.propsToCollect),function(ref){
          var key = ref.key;
          var type = ref.type;
          var text = ref.text;
          var placeholder = ref.placeholder;
          var showSearch = ref.showSearch;
          var filterOption = ref.filterOption;
          var size = ref.size;
          var buttonType = ref.buttonType;
          var click = ref.click;
          var multiple = ref.multiple;
          var options = ref.options;
          var loading = ref.loading;
return [(type === 'button')?_c('a-button',{key:key,attrs:{"type":buttonType,"loading":loading},on:{"click":click}},[_vm._v(" "+_vm._s(text)+" ")]):(type === 'date-picker')?[_c('div',{key:key},[_c('a-range-picker',{on:{"change":function($event){return _vm.setPickerValue(key, $event)}}}),_vm._v(" "+_vm._s(_vm.formatCustomDateString(_vm.config[key]))+" ")],1)]:(type === 'button-group')?_c('a-button-group',{key:key},_vm._l((options),function(option){return _c('a-button',{key:option.key,attrs:{"type":_vm.config[key] === option.key || (!_vm.config[key] && option.default)
                  ? 'primary'
                  : 'default'},on:{"click":function($event){return _vm.setConfigProp(key, option.key)}}},[_vm._v(" "+_vm._s(option.text)+" ")])}),1):_c('a-select',_vm._b({key:key,staticStyle:{"width":"300px"},attrs:{"mode":multiple ? 'multiple' : undefined,"value":_vm.config[key],"disabled":loading},on:{"change":function($event){return _vm.setConfigProp(key, $event)}}},'a-select',{ placeholder: placeholder, size: size, loading: loading, showSearch: showSearch, filterOption: filterOption },false),[(!(options || []).find(function (o) { return o.key === undefined; }))?_c('a-select-option',{attrs:{"value":undefined}},[_vm._v(" None ")]):_vm._e(),_vm._l((options),function(ref){
                  var key = ref.key;
                  var text = ref.text;
return _c('a-select-option',{key:key,attrs:{"value":key}},[_vm._v(" "+_vm._s(text)+" ")])})],2)]}),_c('a-checkbox',{attrs:{"checked":_vm.config && _vm.config.includeTest},on:{"change":function($event){return _vm.onToggleCheckbox('includeTest', $event)}}},[_vm._v("Include Test Data")]),_c('a-checkbox',{attrs:{"checked":_vm.config && _vm.config.goalCard},on:{"change":function($event){return _vm.onToggleCheckbox('goalCard', $event)}}},[_vm._v("Goal Card")])],2),_c('a-row',[_c('a-col',{attrs:{"span":8}},[_c('a-button',{attrs:{"type":"danger"},on:{"click":_vm.deleteCard}},[_vm._v("Delete")])],1),_c('a-col',{staticStyle:{"text-align":"right"},attrs:{"span":16}},[_c('a-space',[_c('a-button',{on:{"click":_vm.cancel}},[_vm._v("Cancel")]),_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.save}},[_vm._v("Save")])],1)],1)],1)],1)],1),_c('div',{staticClass:"InsightCardConfig",staticStyle:{"cursor":"pointer"}},[_c('Icon',{attrs:{"name":"pencil-alt"}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }