// import startCase from 'lodash/startCase'
import { merge } from 'lodash'
import cloneDeep from 'lodash/cloneDeep'
import idGen from '../../../../helpers/idGen'
import { pageLayouts } from './componentTypes'

export function getPageRoles() {
  return [undefined, 'start', 'question', 'info', 'results']
}

export function getFlowTemplates() {
  const allPageLayouts = pageLayouts()

  /* Note that page layouts don't contain keys or ids, and their components don't contain ids, so we have to add these in */
  const pageLayoutKey = (key, fields) => {
    const layout = allPageLayouts.find(l => l.key === key)
    if (!layout) {
      console.warn(`Specified Page Layout ${key} was not found inside Flow Templates`)
      return {}
    }
    if (Array.isArray(fields)) {
      const allowedFields = new Set(fields)
      return Object.entries(layout.template.page).reduce((acc, [k, v]) => {
        if (allowedFields.has(k)) acc[k] = v
        return acc
      }, {})
    }
    return layout.template.page
  }

  const templates = [
    {
      key: 'single_page_2_columns',
      label: 'Single Page 2 Columns',
      template: {
        pages: [
          {
            ...pageLayoutKey('2_columns'),
            key: '2_columns',
            components: [...pageLayoutKey('2_columns', ['components'])],
            page_role: 'first_2_columns',
          },
        ],
        styles: {},
      },
    },
  ]

  return templates
}

/**
 * Copies and returns a new Flow Object based off a template
 * @param {*} templateObject The template to use. should be a Flow structured JSON object.
 * @param {*} form The base form to merge into
 * @param {*} pageRoles An optional map of { [pageId]: pageRole } that overrides the form settings
 * @returns The merged Flow
 */
export function copyFlowTemplate(templateObject, form, pageRoles) {
  const newForm = cloneDeep(form)
  const template = cloneDeep(templateObject)

  /* Replace any primitive top level keys + any key not present */
  Object.entries(template).forEach(([key, value]) => {
    if (form.hasOwnProperty(key)) {
      switch (typeof value) {
        case 'boolean':
        case 'number':
        case 'string': {
          // newForm[key] = value
          return
        }
        default:
          return
      }
    } else {
      newForm[key] = value
    }
  })

  /* 
  Merge pages into the Flow, if page_role matches then merge the two pages otherwise leave it out. 
  Assign page.id if its missing or a duplicate, otherwise keep it 
  Components in the existing page are shoved under a default_container if it exists (created if not),
  while the template components are added to the page.components array
  */
  if (Array.isArray(template.pages)) {
    const templatePagesByRole = (template.pages || []).reduce((acc, page) => {
      if (page.page_role) acc[page.page_role] = page
      return acc
    }, {})
    newForm.pages = form.pages.map(basePage => {
      const basePageRole = (pageRoles && pageRoles[basePage.id]) || basePage.page_role
      const templatePage = templatePagesByRole[basePageRole]
      if (templatePage) {
        const templateRole = templatePage.page_role
        /* Merge pages of the same role */
        if (basePageRole === templateRole) {
          const newPage = cloneDeep(basePage)
          if (!newPage.id) newPage.id = idGen()
          Object.entries(templatePage).forEach(([k, v]) => {
            /* Handle Components separately */
            if (['components', 'default_container'].includes(k)) return
            if (!basePage.hasOwnProperty(k)) newForm[k] = v
            else {
              /* Merge objects and arrays, ignore others */
              if (Array.isArray(v)) {
                newForm[k] = Array.isArray(newForm[k]) ? [...form[k], ...v] : v
              } else if (!v) return
              else newForm[k] = merge(cloneDeep(v), cloneDeep(form[k]))
            }
          })
          /* 
          If basePage has a default_container, take the components inside that container
          Copy them to the template's default_container
          If the template doesn't have a default_container, then create and add one
          If the basePage has a default_container, keep anything inside default_container and discard the rest
          else take the whole components array
          */
          const componentsArray = basePage.components || []
          const templatePageComponents = templatePage.components || []
          let defaultContainer = templatePage.default_container
            ? templatePageComponents.find(c => c.key === templatePage.default_container)
            : null
          if (!defaultContainer) {
            defaultContainer = {
              type: 'Container',
              container_type: 'container',
              id: idGen(),
              key: `default_container_${basePage.key || 'page'}`,
            }
            templatePageComponents.push(defaultContainer)
          }
          const componentsToImport = basePage.default_container
            ? componentsArray.filter(c => c.parent_key === basePage.default_container)
            : componentsArray
          const importedComponents = componentsToImport.map(c => ({
            ...c,
            parent_key: defaultContainer.key,
          }))
          /* Add in the template components */
          if (Array.isArray(templatePageComponents)) {
            importedComponents.push(...templatePageComponents)
          }

          newPage.components = importedComponents
          newPage.default_container = defaultContainer.key
          return newPage
        }
      }
      return basePage
    })
  }

  /* Merge the following keys into Flow, adding them to their arrays and re-naming their Id if it already exists */
  const simpleMergeKeys = ['computedFields', 'dataOutputs', 'components', 'experiments']
  simpleMergeKeys.forEach(key => {
    if (Array.isArray(template[key])) {
      const ids = new Set(template[key].map(o => o.id))
      template[key].forEach(d => {
        const id = d.id || idGen()
        d.id = ids.has(id) ? idGen() : id
        if (Array.isArray(newForm[key])) {
          newForm[key].push(d)
        } else {
          newForm[key] = [d]
        }
      })
    }
  })

  /* Merge styles if it exists */
  if (template.styles) {
    const newStyles = cloneDeep(form.styles || {})
    Object.entries(template.styles || {}).forEach(([k, v]) => {
      if (newStyles[k]) {
        const styleObj = newStyles[k]
        Object.entries(v).forEach(([styleKey, styleValue]) => {
          if (!styleObj.hasOwnProperty(styleKey)) styleObj[styleKey] = styleValue
        })
      } else {
        newStyles[k] = v
      }
    })

    newForm.styles = newStyles
  }

  return newForm
}

/**
 * Copies a page template onto the current page
 * @param {*} template The template for the page, in the format { page: Page, style: Style }
 * @param {*} form The form
 * @param {*} pageId The page to merge with, can be an object or an id
 */
export function copyPageTemplate(template, form, pageId) {
  const page = typeof pageId === 'string' ? form.pages.find(p => p.id === pageId) : pageId
  if (!page) return
  const newPage = cloneDeep(page)
  Object.entries(template.page).forEach(([k, v]) => {
    if (k === 'components') return
    newPage[k] = v
  })

  // const newDefaultContainerKey = generateContainerKey(form)
  // newPage.default_container = newDefaultContainerKey
  const placeholderComponents = (template.page.components || [])
    .filter(
      c =>
        c.type === 'Container' &&
        !(template.page.components || []).some(_c => _c.parent_key === c.key)
    )
    .map(c => ({
      type: 'PlaceholderComponent',
      parent_key: `${page.key}_${c.key}`,
      key: null,
      id: idGen(),
    }))
  const newComponents = (template.page.components || []).map(c => ({
    ...cloneDeep(c),
    id: c.id || idGen(),
    key: c.type === 'PlaceholderComponent' ? null : `${page.key}_${c.key}`,
    parent_key: c.parent_key ? `${page.key}_${c.parent_key}` : undefined, // newDefaultContainerKey,
    buttons: c.buttons ? c.buttons.map(b => ({ id: idGen(), ...b })) : undefined,
  }))
  newPage.components.push(
    // { type: 'Container', key: newDefaultContainerKey, id: idGen() },
    ...newComponents,
    ...placeholderComponents
  )

  const newStyles = cloneDeep(form.styles || {})
  Object.entries(template.style || {}).forEach(([k, v]) => {
    if (newStyles[k]) {
      const styleObj = newStyles[k]
      Object.entries(v).forEach(([styleKey, styleValue]) => {
        styleObj[styleKey] = styleValue
      })
    } else {
      newStyles[k] = v
    }
  })

  return { page: newPage, styles: newStyles }
}
