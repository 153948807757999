<template>
  <div
    class="DotsMenu absolute top-2 right-2 p-1 opacity-0 text-gray-600 z-10 outline-none transition-all group-hover:opacity-70 cursor-pointer hover:text-purple hover:opacity-100"
    @click.stop
  >
    <Icon name="ellipsis-v" />
  </div>
</template>

<script>
export default {}
</script>
