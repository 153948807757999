<template>
  <div>
    <div v-for="key in dataToSendWithValues" :key="key">
      Possible Values for {{ key }}:
      <!-- Input to add a new mapKey - select if key is a selector component or input if its an input -->
      <div v-for="mapKey in Object.keys(valueMap[key] || {})" :key="mapKey">
        {{ mapKey }}

        <a-select
          v-if="findOptionFromKey(key)"
          :value="valueMap && valueMap[key] && valueMap[key][mapKey]"
          placeholder="Drop Key"
          showSearch
          :filterOption="filterOption"
          style="width:100%;"
          @change="updateValueMap(key, mapKey, $event)"
        >
          <a-select-option value="_omit_value">Omit Value</a-select-option>
          <a-select-option
            v-for="{ label, value } in findOptionFromKey(key).options || []"
            :key="value"
            :value="value"
          >
            {{ label }}
          </a-select-option>
        </a-select>
        <template v-else>
          <a-input
            :placeholder="mapKey"
            :disabled="valueMap && valueMap[key] && valueMap[key][mapKey] === '_omit_value'"
            :defaultValue="valueMap && valueMap[key] && valueMap[key][mapKey]"
            :value="valueMap && valueMap[key] && valueMap[key][mapKey]"
            @change="updateValueMap(key, mapKey, $event.target.value)"
          />
          <a-checkbox
            :checked="valueMap && valueMap[key] && valueMap[key][mapKey] === '_omit_value'"
            @change="onInputCheck(key, mapKey, $event)"
            >Omit Value</a-checkbox
          >
        </template>
      </div>
    </div>
    <a-divider></a-divider>
    Add Component:
    <a-select
      v-model="newKeySelect"
      placeholder="Select a key"
      showSearch
      :filterOption="filterOption"
      style="width:100%;"
    >
      <!-- @change="addNewKey" -->
      <a-select-option v-for="key in Object.keys(componentsByKey) || []" :key="key" :value="key">
        {{ key }}
      </a-select-option>
    </a-select>
    <template v-if="newKeySelect">
      Select Component Value:
      <a-select
        v-if="componentsByKey[newKeySelect] && componentsByKey[newKeySelect].buttons"
        placeholder="Select User Data Value"
        showSearch
        :filterOption="filterOption"
        style="width:100%;"
        :value="newValueSelect"
        @change="addNewKey(newKeySelect, $event)"
      >
        <a-select-option
          v-for="{ key } in componentsByKey[newKeySelect].buttons || []"
          :key="key"
          :value="key"
        >
          {{ key }}
        </a-select-option>
      </a-select>
      <div v-else style="display: flex; flex-direction: row;">
        <a-input placeholder="Enter User Data Value" v-model="newValueSelect" />
        <a-button icon="save" @click="addNewKey(newKeySelect, newValueSelect)" />
      </div>
    </template>
  </div>
</template>
<script>
export default {
  inject: ['_updateData', '_getOptions'],
  props: {
    dataOutput: Object,
    dataToSend: Array,
    componentsByKey: Object,
    basePath: String,
    options: Object,
  },
  data() {
    return { newKeySelect: null, newValueSelect: null }
  },
  computed: {
    valueMap: {
      get() {
        return this.dataOutput.valueMap || {}
      },
      set(v) {
        this._updateData(this.basePath, 'valueMap', v)
      },
    },
    mappings() {
      return this.dataOutput && this.dataOutput.mappings
    },
    output() {
      return this.dataOutput && this.dataOutput.output
    },
    dataToSendWithValues() {
      return this.dataToSend.filter(k => this.valueMap[k])
    },
  },
  watch: {},
  methods: {
    addNewKey(key, value) {
      this.newKeySelect = null
      this.newValueSelect = null
      const base = (this.valueMap || {})[key] || {}
      this.valueMap = { ...this.valueMap, [key]: { ...base, [value]: '' } }
    },
    findOptionFromKey(key) {
      const mappedKey = this.mappings[key] || key
      const option = this.options[this.output].find(k => k.key === mappedKey)
      if (option && option.options) return option
      else return null
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text &&
        option.componentOptions.children[0].text.toLowerCase().includes(input.toLowerCase())
      )
    },
    updateValueMap(k, mk, v) {
      this._updateData(`${this.basePath}.valueMap.${k}`, mk, v, true)
    },
    onInputCheck(k, mk, e) {
      const v = e.target.checked

      this.updateValueMap(k, mk, v ? '_omit_value' : '')
    },
  },
}
</script>
