<template>
  <div class="JsonEditor">
    <div v-if="title" class="title text-xl py-4 px-8">
      Editing <span class="px-1 rounded border-gray-400 bg-gray-200">{{ title }}</span>
    </div>
    <div
      class="grid grid-cols-2 px-2 auto-rows-auto gap-2 overflow-y-auto"
      style="max-height: 80vh;"
    >
      <div v-if="!simple">
        <div class="sticky top-0 bg-white">Original</div>
        <PrismEditor
          v-if="code"
          readonly
          class="my-editor"
          :highlight="highlighter"
          line-numbers
          :value="jsonString"
        />
        <pre
          v-else
          class="JsonViewer whitespace-pre-wrap p-1 rounded border-gray-400 bg-gray-200"
          v-text="jsonString"
        />
      </div>
      <div>
        <div v-if="!simple" class="sticky top-0 bg-white">Edited</div>
        <PrismEditor
          v-if="code"
          class="my-editor"
          :highlight="highlighter"
          line-numbers
          v-model="codeValue"
        />
        <pre
          v-else
          contenteditable
          ref="editor"
          class="JsonViewer whitespace-pre-wrap p-1 rounded border-gray-400 bg-gray-200"
          @input="debouncedValidate"
          v-text="jsonString"
        />
      </div>
    </div>
    <div class="my-2 py-2 flex flex-row-reverse">
      <button
        type="button"
        class="px-3 py-2 mx-8 cursor-pointer rounded border flex flex-row items-center"
        @click="save"
      >
        <Icon class="mr-2" name="save" /> Save
      </button>
    </div>
  </div>
</template>
<script>
import { debounce } from 'lodash'
import { PrismEditor } from 'vue-prism-editor'
import 'vue-prism-editor/dist/prismeditor.min.css' // import the styles somewhere
// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from 'prismjs/components/prism-core'
import 'prismjs/components/prism-clike'
import 'prismjs/components/prism-javascript'
import 'prismjs/themes/prism-tomorrow.css' // import syntax highlighting styles

export default {
  name: 'JsonEditor',
  components: { PrismEditor },
  props: { value: {}, data: Object, code: Boolean, simple: Boolean },
  data() {
    return { isValid: true, editingValue: '' }
  },
  computed: {
    path() {
      return this.data.path
    },
    title() {
      return this.data.title
    },
    codeValue: {
      get() {
        return this.editingValue || this.jsonString
      },
      set(v) {
        this.editingValue = v
      },
    },
    jsonString: {
      get() {
        if (this.code) return this.value
        return this.value ? JSON.stringify(this.value, null, 2) : null
      },
      set(v) {
        try {
          // this.debouncedEmit(v, this.code)
          this.$emit('input', this.code ? v : JSON.parse(v))
          this.$message.success('Updated successfully')
        } catch (error) {
          console.error(error)
          this.$message.error('Invalid JSON')
        }
      },
    },
    debouncedEmit() {
      return debounce((v, noParse) => this.$emit('input', noParse ? v : JSON.parse(v)), 500)
    },
    debouncedValidate() {
      return debounce(this.onInput, 500)
    },
  },
  methods: {
    onInput() {
      try {
        JSON.parse(this.$refs.editor.innerText)
        this.valid = true
      } catch (error) {
        this.valid = false
      }
    },
    save() {
      if (this.code) {
        this.jsonString = this.codeValue
      } else this.jsonString = this.$refs.editor.innerText
    },
    highlighter(code) {
      return highlight(code, languages.js)
    },
  },
}
</script>
