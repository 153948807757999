<template>
  <div class="DestinationEditor">
    <a-space direction="vertical">
      <a-tag closable @close="$emit('remove')">
        Data Output {{ index + 1 }} {{ hidden ? ' (Hidden)' : '' }}</a-tag
      >
      <EditorInput _key="name" label="Readable Name" />
      <template v-if="manualTriggers.length > 0">
        Manually triggered at: {{ manualTriggers }}
      </template>
      <EditorSelect label="Select Output" _key="output" :options="outputOptions" />
      <EditorCheckbox v-if="datastreamCompatible" label="Use Datastream" _key="datastream" />
      <EditorCheckbox label="Ignore Test Cases" _key="ignoreTest" />
      <EditorCheckbox label="Manual Trigger Only" _key="manual_trigger_only" />
      <template v-if="outputIsNotCustom">
        <EditorCheckbox label="No automatic submission on finish" _key="no_trigger_finish" />
        <EditorCheckbox
          v-if="output !== 'google-sheets'"
          label="Use Entry Data"
          _key="use_entry_data"
        />
        <EditorCheckbox label="Evaluate Triggers on Backend" _key="eval_triggers" />
        <EditorCheckbox label="Scrub Data on Success" _key="scrubOnSuccess" />
        <EditorCheckbox label="Use Mappings" _key="mappingsAllowed" />
        <EditorCheckbox
          v-if="mappingsAllowed"
          label="Exclude Unmapped Keys"
          _key="excludeUnmappedKeys"
        />
        <EditorInput _key="timeout" type="number" label="Delay on handling submission (s)" />
        <EditorInput
          v-if="!dataOutput.datastream"
          _key="submit_limit"
          type="number"
          label="Max Number of Submits"
        />

        <EditorInput
          v-if="allowUniqueIdentifier"
          allowComputed
          monospaced
          _key="uniqueId"
          label="Unique Identifier"
          tooltip="A Unique Identifier for each submission"
          :component="dataOutput"
        />
      </template>
      <!-- <ConditionsEditor :conditions="dataOutput.conditions" v-bind="{ form }" /> -->
      <EditorCheckbox label="Use Custom Trigger" _key="custom_trigger" />
      <!-- Trigger Editor -->
      <template v-if="dataOutput.custom_trigger">
        <div
          v-for="(trigger, i) in triggers"
          :key="`${trigger.condition}${trigger.operator}${trigger.value}`"
        >
          <EditorSelect :_key="`triggers.${i}.condition`" :options="triggerConditions" />
          <EditorSelect :_key="`triggers.${i}.operator`" :options="triggerOperators" />
          <EditorSelect
            v-if="trigger.condition === 'checkpoints'"
            :_key="`triggers.${i}.value`"
            :options="checkpointOptions"
          />
          <EditorInput v-else :_key="`triggers.${i}.value`" />
          <a-button icon="minus" @click="removeTrigger(i)">Remove Trigger</a-button>
        </div>
        <a-button icon="plus" @click="addTrigger">Add Trigger</a-button>
      </template>

      <EditorInput
        v-if="output === 'google-sheets'"
        monospaced
        _key="sheetUrl"
        label="Sheet URL"
        tooltip="Data will be sent to this sheet (Must have a connected Google Sheets account with edit permissions)"
        :component="dataOutput"
      />
      <template v-if="output === 'airtable'">
        <EditorInput
          monospaced
          _key="base"
          label="Base Id (e.g. 'appB0YeGyxaePaxp5')"
          tooltip="Data will be sent to this Base"
          :component="dataOutput"
        />
        <EditorInput
          monospaced
          _key="table"
          label="Table Name (e.g. 'My Table_1')"
          tooltip="This table must be part of the above Base"
          :component="dataOutput"
        />
      </template>
      <template v-if="output === 'close-io'">
        <EditorInput
          monospaced
          _key="leadId"
          label="Lead Id"
          tooltip="The lead id these contacts will be a part of"
          :component="dataOutput"
        />
        <EditorCheckbox label="Subscribe Contact To Sequence" _key="add_sequence" />
        <template v-if="dataOutput.add_sequence">
          <EditorSelect v-if="closeSequences.length" _key="sequence_id" :options="closeSequences"/>
          <EditorInput
            v-else
            _key="sequence_id"
            label="Sequence ID"
            tooltip="The Sequence to add contacts to"
            :component="dataOutput"/>
          <EditorSelect
            v-if="closeAccounts.length"
            _key="sender_account_id"
            :options="closeAccounts"/>
          <EditorInput
            v-else
            _key="sender_account_id"
            label="Sender Account Id"
            tooltip="The Id of the connected account you are using to send the emails"
            :component="dataOutput"/>
          <EditorSelect
            v-if="availableIdentities && availableIdentities.length"
            emit
            :options="availableIdentities"
            :value="currentIdentity && currentIdentity.key"
            @input="setIdentity"/>
          <template v-else>
            <EditorInput
              _key="sender_name"
              label="Sender Name"
              tooltip="The Sender Name"
              :component="dataOutput"
            />
            <EditorInput
              _key="sender_email"
              label="Sender email"
              tooltip="The Sender Email"
              :component="dataOutput"
            /> </template
        ></template>
      </template>
      <template v-if="output === 'twilio'">
        <EditorInput
          allowComputed
          _key="to"
          label="To"
          tooltip="The recipient's phone number"
          :component="dataOutput"
        />
        <EditorInput
          _key="from"
          label="From"
          tooltip="Your Sender phone number"
          :component="dataOutput"
        />
        <EditorInput
          allowComputed
          type="textarea"
          _key="body"
          label="Body"
          tooltip="The body of the email"
          :component="dataOutput"
        />
      </template>
      <template v-if="output === 'sendgrid-contacts'">
        <EditorInput
          monospaced
          allowComputed
          _key="listId"
          label="List to add contacts to"
          :component="dataOutput"
        />
      </template>
      <template v-if="output === 'sendgrid'">
        <EditorInput
          monospaced
          _key="templateId"
          label="Template Id - Optional"
          tooltip="If you have a pre-defined template you want to use"
          :component="dataOutput"
        />
        <EditorCheckbox _key="sendFromSavvy" label="Send from Savvy" />
        <EditorInput
          allowComputed
          _key="to"
          label="To"
          tooltip="The recipient of the email"
          :component="dataOutput"
        />
        <EditorInput
          allowComputed
          _key="toName"
          label="To (Name) - Optional"
          tooltip="The name of the recipient"
          :component="dataOutput"
        />
        <EditorInput
          allowComputed
          _key="cc"
          label="CC"
          tooltip="The CC of the email"
          :component="dataOutput"
        />
        <EditorInput
          allowComputed
          _key="bcc"
          label="bcc"
          tooltip="The BCC of the email"
          :component="dataOutput"
        />
        <EditorInput
          allowComputed
          _key="subject"
          label="Subject"
          tooltip="The subject of the email"
          :component="dataOutput"
        />
        <EditorInput
          v-if="!dataOutput.sendFromSavvy"
          _key="from"
          label="From"
          tooltip="Who this email is from"
          :component="dataOutput"
        />
        <EditorInput
          v-if="!dataOutput.sendFromSavvy"
          _key="fromName"
          label="From (Name) - Optional"
          tooltip="The name of the sender"
          :component="dataOutput"
        />
        <EditorInput
          v-if="!dataOutput.templateId"
          allowComputed
          type="rich"
          _key="body"
          label="Body"
          tooltip="The body of the email"
          :component="dataOutput"
        />
      </template>
      <template v-if="output === 'diahook'">
        <EditorInput
          monospaced
          _key="outboundId"
          label="Optional - Diahook App Id"
          tooltip="The customer's app_id to send to"
          :component="dataOutput"
        />
      </template>
      <template v-if="output === 'salesforce'">
        <EditorInput
          monospaced
          _key="accountId"
          label="Salesforce Account Id"
          tooltip="The accountId to associate contacts with"
          :component="dataOutput"
        />
      </template>
      <template v-if="output === 'facebook-conversions'">
        We recommend turning on the "Add User Location to User Data" option in Flow Options
        <EditorInput
          monospaced
          _key="pixelId"
          label="Pixel Id"
          tooltip="The Facebook pixel to associate contacts with"
          :component="dataOutput"
        />
        <EditorCheckbox _key="use_custom_event" label="Use Custom Event Name" />
        <EditorInput
          v-if="showCustomEvent"
          allowComputed
          monospaced
          _key="eventName"
          label="Event Name (Custom)"
          tooltip="The Facebook event name this output counts as"
          :component="dataOutput"
        />
        <EditorSelect
          v-else
          monospaced
          _key="eventName"
          label="Event Name (Preset)"
          :options="pixelEventOptions"
          tooltip="The Facebook event name this output counts as"
        />
        <EditorInput
          v-if="dataOutput.eventName === 'Purchase'"
          type="number"
          _key="purchase_value"
          label="Purchase Value"
          tooltip="The amount this purchase is valued at"
        />
      </template>
      <template v-if="output === 'hubspot'">
        <EditorCheckbox label="Submit as form" _key="submit_as_form" />
        <EditorInput
          monospaced
          allowComputed
          _key="listId"
          label="List Id - Optional"
          tooltip="The List Id you want contacts to be automatically added to"
          :component="dataOutput"
        />
        <EditorInput
          v-if="dataOutput.submit_as_form"
          monospaced
          allowComputed
          _key="portalId"
          label="Portal Id - Required"
          tooltip="The Hubspot Portal the form belongs to"
          :component="dataOutput"
        />
        <EditorInput
          v-if="dataOutput.submit_as_form"
          monospaced
          allowComputed
          _key="formId"
          label="Form Id - Required"
          tooltip="The ID of the form you're sending data to"
          :component="dataOutput"
        />
      </template>
      <template v-if="output === 'custom'">
        <label>
          Must contain a function called 'output'. Function receives userData as the first argument,
          and context as the second argument. Use context.setUserData(key, value) to set userData
          values
        </label>
        <EditorCode _key="code" v-bind="{ codeDraft }" />
        <a-button size="small" @click="generateSampleCode">Generate sample code</a-button>
      </template>

      <a-collapse v-if="mappingsAllowed">
        <a-collapse-panel key="key" header="Key Mappings">
          <div v-for="key in dataToSend" :key="key">
            {{ key }}:
            <a-select
              v-if="mappingsAreSelect"
              :value="mappings && mappings[key]"
              placeholder="Drop Key"
              showSearch
              :filterOption="filterOption"
              style="width:100%;"
              @change="updateMapping(key, $event)"
            >
              <a-select-option
                v-for="{ key, text } in options[output] || []"
                :key="key"
                :value="key"
              >
                {{ text }}
              </a-select-option>
            </a-select>
            <a-input
              v-else
              :placeholder="key"
              :defaultValue="mappings && mappings[key]"
              :value="mappings && mappings[key]"
              @change="updateMapping(key, $event.target.value)"
            />
          </div>
        </a-collapse-panel>
        <a-collapse-panel key="values" header="Value Mappings">
          <ValueMapEditor v-bind="{ dataOutput, dataToSend, componentsByKey, basePath, options }" />
        </a-collapse-panel>
        <a-collapse-panel
          v-if="output === 'hubspot' && dataOutput.formId"
          key="contactvalues"
          header="Contact-Only Properties"
        >
          <EditorSelect multiple _key="contactFields" :options="Object.values(mappings || {})" />
        </a-collapse-panel>
      </a-collapse>
      <a-button v-if="mappingsAllowed" @click="setMappings">Auto-map component keys</a-button>

      <a-button icon="download" size="small" @click="downloadCsvTemplate"
        >Download CSV Template</a-button
      >
      <a-button icon="download" :disabled="datastream" size="small" @click="sendSampleData"
        >Send a sample</a-button
      >
    </a-space>
  </div>
</template>

<script>
import Firebase from 'firebase/app'
import 'firebase/firestore'
import Papa from 'papaparse'
import api from '@/helpers/api'
import { Database } from 'firebase-firestore-lite'

import getCheckpointOptions from '@/components/form/editor/helpers/getCheckpointOptions'
import facebookPixelEvents from '@/components/form/editor/helpers/facebookPixelEvents'
import { unpackToCamel } from '@/helpers/computed'

import ValueMapEditor from './ValueMapEditor.vue'
// import ConditionsEditor from './ConditionsEditor'

import {
  createDestinationData,
  createRefData,
  getDataToSend,
  OUTPUTS_COLLECTION,
  runCustomOutput,
  findOutputsInUse,
  dataOutputValid,
} from '../helpers/dataOutputs'
import { startCase } from 'lodash'

const submissionDB = new Database({
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID_FLOW_SUBMISSION,
})

export default {
  name: 'DataOutputEditor',
  components: {
    // ConditionsEditor,
    ValueMapEditor,
  },
  provide() {
    return {
      _getBasePath: () => this.basePath,
    }
  },
  inject: ['_updateData', '_getOptions', '_getFlowPages'],
  props: {
    form: Object,
    dataOutput: Object,
    hidden: Boolean,
    index: Number,
    userData: Object,
  },
  data() {
    return {
      options: {},
      codeDraft: '',
      closeSequences: [],
      closeAccounts: [],
    }
  },
  computed: {
    basePath() {
      return `dataOutputs.${this.index}`
    },
    ...unpackToCamel('dataOutput', ['add_sequence']),
    ...[
      'output',
      'base',
      'table',
      'sheetUrl',
      'sheetId',
      'mappings',
      'datastream',
      'mappingsAllowed',
    ].reduce((acc, k) => {
      acc[k] = {
        get() {
          return this.dataOutput[k]
        },
        set(v) {
          this._updateData(this.basePath, k, v)
        },
      }
      return acc
    }, {}),
    showCustomEvent() {
      return (this.dataOutput && this.dataOutput.use_custom_event) || false
    },
    triggers: {
      get() {
        return this.dataOutput.triggers || []
      },
      set(v) {
        this._updateData(this.basePath, 'triggers', v)
      },
    },
    addExternalMappings() {
      const outputs = [
        'hubspot',
        'close-io',
        'sendgrid',
        'sendgrid-contacts',
        'facebook-conversions',
      ]
      return this.mappingsAllowed && outputs.includes(this.output)
        ? `${this.output}-true`
        : `${this.output}`
    },
    mappingsAreSelect() {
      const outputs = ['hubspot', 'close-io', 'sendgrid-contacts', 'facebook-conversions']
      return outputs.includes(this.output)
    },
    datastreamCompatible() {
      const allowed = ['hubspot', 'airtable', 'salesforce', 'sendgrid-contacts']
      return allowed.includes(this.output)
    },
    allowUniqueIdentifier() {
      const allowed = ['sendgrid']
      return allowed.includes(this.output)
    },
    manualTriggers() {
      return findOutputsInUse(this.form, this.dataOutput.id)
        .map(k => startCase(k))
        .join(', ')
    },
    outputOptions() {
      return [
        'airtable',
        'google-sheets',
        'hubspot',
        'close-io',
        'diahook',
        'salesforce',
        'sendgrid',
        'sendgrid-contacts',
        'custom',
        'facebook-conversions',
      ]
    },
    outputIsNotCustom() {
      return this.output !== 'custom'
    },
    noUserInput() {
      return ['PlainText', 'MediaImage', 'MediaEmbed', 'RichText']
    },
    triggerConditions() {
      return [undefined, 'checkpoints'] // 'current-page', 'highest-page'
    },
    triggerOperators() {
      return [
        undefined,
        { label: '== / includes', key: '==' },
        { label: '!= / not in', key: '!=' },
        // { label: '>=', key: '>=' },
        // { label: '>', key: '>' },
        { label: 'exists', key: 'exists' },
        { label: 'not exists', key: '!exists' },
      ]
    },
    checkpointOptions() {
      return getCheckpointOptions(this.form)
    },
    pixelEventOptions() {
      return facebookPixelEvents()
    },
    componentsByKey() {
      const componentsByKey = {}
      this.form &&
        this._getFlowPages().forEach(p => {
          if (Array.isArray(p.components))
            p.components.forEach(c => {
              if (!this.noUserInput.includes(c.type) && !c.doNotSave) componentsByKey[c.key] = c
            })
        })
      if (this.form && this.form.computedFields) {
        this.form.computedFields.forEach(c => {
          if (!c.doNotSave) componentsByKey[c.key] = c
        })
      }
      if (this.form && this.form.experiments) {
        this.form.experiments.forEach(c => {
          if (!c.doNotSave) componentsByKey[c.key] = c
        })
      }
      if (this.form && this.form.components) {
        this.form.components.forEach(c => {
          if (!this.noUserInput.includes(c.type) && !c.doNotSave) componentsByKey[c.key] = c
        })
      }

      return componentsByKey
    },
    availableIdentities() {
      const acc = this.closeAccounts.find(s => s.key === this.dataOutput.sender_account_id)
      if (acc) return acc.identities.map(d => ({ ...d, key: `${d.name}<${d.email}>` }))
      return null
    },
    currentIdentity() {
      if (this.availableIdentities.length) {
        const email = this.dataOutput.sender_email
        const name = this.dataOutput.sender_name
        const key = `${name}<${email}>`
        return this.availableIdentities.find(d => d.key === key)
      }
      return null
    },
    dataToSend() {
      return getDataToSend(this.form, this.userData)
    },
  },
  watch: {
    addSequence: {
      handler(v) {
        if (v && this.output === 'close-io') {
          this.loadSequences()
          this.loadAccounts()
        }
      },
      immediate: true,
    },
    datastreamCompatible: {
      handler(v) {
        if (!v) {
          this.datastream = false
        }
      },
    },
    addExternalMappings: {
      async handler(val) {
        const v = val.split('-')[1]
        const options = this.options[this.output] || []
        if (v && options.length === 0) {
          if (this.mappingsAllowedAPILoad) return
          this.mappingsAllowedAPILoad = true
          switch (this.output) {
            case 'hubspot':
            case 'facebook-conversions':
            case 'close-io': {
              await this.loadMappings()
              break
            }

            default:
              break
          }
          this.mappingsAllowedAPILoad = false
        }
      },
      immediate: true,
    },
  },
  methods: {
    setMappings() {
      this.mappings = this.dataToSend.reduce(
        (acc, k) => {
          acc[k] = acc[k] || k
          return acc
        },
        { ...(this.mappings || {}) }
      )
    },
    async loadMappings() {
      try {
        const { data } = await api('/integrations/data-output/properties', {
          groupId: this.$store.getters.activeGroupId,
          type: 'contacts',
          source: this.output,
        })
        if (data.success) {
          this.$set(this.options, this.output, [
            { key: undefined, text: 'Drop Key' },
            ...data.properties,
          ])
        } else this.$message.warning(`Fetch from ${this.output} failed: ${data.errorMessage}`)
      } catch (error) {
        console.error(error)
        this.$message.error(`Error in retrieving properties of Contacts in ${this.output}`)
      }
    },
    async loadAccounts() {
      try {
        const { data } = await api('/integrations/data-output/properties', {
          groupId: this.$store.getters.activeGroupId,
          type: 'accounts',
          source: this.output,
        })
        if (data.success) {
          this.closeAccounts = data.properties
        } else this.$message.warning(`Fetch from ${this.output} failed: ${data.errorMessage}`)
      } catch (error) {
        console.error(error)
        this.$message.error(`Error in retrieving properties of Contacts in ${this.output}`)
      }
    },
    async loadSequences() {
      try {
        const { data } = await api('/integrations/data-output/properties', {
          groupId: this.$store.getters.activeGroupId,
          type: 'sequences',
          source: this.output,
        })
        if (data.success) {
          this.closeSequences = data.properties
        } else this.$message.warning(`Fetch from ${this.output} failed: ${data.errorMessage}`)
      } catch (error) {
        console.error(error)
        this.$message.error(`Error in retrieving properties of Contacts in ${this.output}`)
      }
    },
    async setIdentity(event) {
      const [name, emailTag] = event.split('<')
      const email = emailTag.slice(0, -1)

      this._updateData(this.basePath, 'sender_name', name)
      await this.$nextTick()
      this._updateData(this.basePath, 'sender_email', email)
    },
    updateMapping(k, v) {
      this._updateData(`${this.basePath}.mappings`, k, v, true)
    },
    updateDataoutput(k, v) {
      this._updateData(`${this.basePath}`, k, v, true)
    },
    addTrigger() {
      this.triggers = [...this.triggers, {}]
    },
    removeTrigger(i) {
      this.triggers = this.triggers.filter((t, idx) => idx !== i)
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text &&
        option.componentOptions.children[0].text.toLowerCase().includes(input.toLowerCase())
      )
    },
    downloadCsvTemplate() {
      const data = this.dataToSend.reduce((acc, d) => {
        const mappings = this.dataOutput.mappings
        if (mappings) {
          const key = this.dataOutput.excludeUnmappedKeys
            ? this.dataOutput.mappings[d]
            : this.dataOutput.mappings[d] || d
          if (!key) return acc
          acc[key] = ''
        } else acc[d] = ''

        return acc
      }, {})
      if (!(this.dataOutput.mappings && this.dataOutput.mappings.entryId)) {
        delete data.entryId
      }

      data.is_test = ''
      data.has_submitted = ''

      const csvString = Papa.unparse([data])
      const csvContent = 'data:text/csv;charset=utf-8,' + csvString
      var encodedUri = encodeURI(csvContent.replace(/#/g, '%23'))
      window.open(encodedUri, '_blank')
    },
    async sendSampleData() {
      if (this.datastream) return
      const e = this.dataOutput
      const setUserData = (key, value) => {
        this.$message.info(`Output will set userData.${key}: ${value}`)
      }
      if (e.output === 'custom') {
        return runCustomOutput(e, this.userData, {
          setUserData,
          getComponentElement: key => document.querySelector(`.ComponentKey-${key}`),
        })
      }
      const finalUserData = this.dataToSend.map(key => [
        key,
        key === 'createdAt'
          ? new Date().toISOString()
          : (this._getOptions().find(o => o.key === key) || {}).multiple
          ? [getSampleValue(key, this.componentsByKey[key], this.userData)]
          : getSampleValue(key, this.componentsByKey[key], this.userData),
      ])

      finalUserData.push(['is_test', 'true'], ['has_submitted', 'false'])

      const data = {
        groupId: this.$store.getters.activeGroupId,
        data: finalUserData,
        formId: this.form.id,
      }

      const destinationData = createDestinationData(e, data, this.form, this.userData)
      const refData = createRefData(
        e,
        destinationData,
        this.form.id,
        destinationData.entryId,
        new Date().toISOString()
      )

      try {
        await submissionDB.ref(OUTPUTS_COLLECTION).add(refData)
        this.$message.success('Sent sample data successfully!')
      } catch (error) {
        console.error(error)
        this.$message.error('Could not create sample data - check console for details')
      }
    },
    generateSampleCode() {
      this.codeDraft = `function output(userData, context) {\n  return \n}`
    },
    async findMatchingOldData() {
      /* Probably will change as use cases need */
      const checkpoints = (this.dataOutput.triggers || [])
        .filter(t => t.condition === 'checkpoints' && !['!=', '!exists'].includes(t.operator))
        .map(t => t.value)
      if (checkpoints.length === 0) {
        throw new Error('Warning: No Checkpoints found!')
      }
      const limit = 250
      let query = Firebase.firestore()
        .collection('forms')
        .doc(this.form.id)
        .collection('entries')
        .where('_checkpoints', 'array-contains-any', checkpoints)
        .orderBy('createdAt', 'asc')
      if (this.startAfter) {
        query = query.startAfter(this.startAfter)
      }
      const snap = query.get()
      await Promise.all(snap.docs.map(d => this.createSubmissionForOldData(d)))
      if (snap.size === limit) {
        const last = snap.docs[snap.size - 1]
        this.startAfter = last
      } else this.startAfter = null
    },
    async createSubmissionForOldData(entryDoc) {
      /* Cancel if already submitted */
      if (entryDoc.get(`_submit_logs.${this.dataOutput.id}`)) return

      const entryData = entryDoc.data()
      const checkpoints = (this.dataOutput.triggers || [])
        .filter(t => t.condition === 'checkpoints' && !['!=', '!exists'].includes(t.operator))
        .map(t => t.value)
      const dataToSend = getDataToSend(this.form, entryData)
      const isValid = dataOutputValid(
        this.dataOutput,
        false,
        { location: 'checkpoints', locationValue: checkpoints, sendAll: false },
        entryData,
        dataToSend
      )
      if (!isValid) {
        console.warn(`Rejected entry ${entryDoc.id}`)
        return
      }

      const groupId = this.$store.getters.activeGroupId
      const data = { groupId, data: dataToSend, formId: this.form.id }
      const destinationData = createDestinationData(this.dataOutput, data, this.form, entryData)
      destinationData.data.entryId = entryDoc.id
      delete destinationData.data.data

      const date =
        entryDoc.get('updatedAt') && entryDoc.get('updatedAt').seconds
          ? new Date(entryDoc.get('updatedAt').seconds * 1000).toISOString()
          : entryDoc.get('updatedAt')
      const refData = createRefData(
        this.dataOutput,
        destinationData,
        this.form.id,
        destinationData.entryId,
        date
      )

      try {
        const ref = await submissionDB.ref(OUTPUTS_COLLECTION).add(refData)
        console.log(`Sent submission ${ref.id} successfully!`)
      } catch (error) {
        console.error(error)
        this.$message.error('Could not create sample data - check console for details')
      }
    },
  },
}

function getSampleValue(key, component, userData) {
  if (userData && userData[key]) return userData[key]
  if (component && component.validate_value) {
    switch (component.validation_formula) {
      case 'email_address':
        return `test_${key}@test.com`
      case 'full_name':
        return `Test ${key}`

      default:
        break
    }
  }

  return `test_${key}_value`
}
</script>
