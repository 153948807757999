<template>
  <div class="FlowEvent" :class="{ last }">
    <a-dropdown :trigger="['contextmenu']">
      <div>
        <div class="time">{{ time }}</div>
        <div class="box">
          <RichTextEditor
            v-if="isAdmin"
            :component="{ text: flowEvent.title }"
            @update="update('title', $event[1])"
          />
          <div v-else v-html="(flowEvent && flowEvent.title) || ''"></div>
          <!-- <PlainEditableText
            :isEditable="isAdmin"
            :initialText="flowEvent.title"
            class="title"
            placeholder="Title"
            @update-text="update('title', $event)"
          >
            {{ flowEvent && flowEvent.title }}
          </PlainEditableText> -->
          <!-- <PlainEditableText
            :isEditable="isAdmin"
            :initialText="flowEvent.subtitle"
            class="subtitle"
            placeholder="Subtitle"
            @update-text="update('subtitle', $event)"
          >
            {{ flowEvent && flowEvent.subtitle }}
          </PlainEditableText> -->
        </div>
      </div>
      <a-menu slot="overlay" @click="onRightClick">
        <a-menu-item key="delete">
          Delete Item
        </a-menu-item>
      </a-menu>
    </a-dropdown>
    <a-dropdown :trigger="['contextmenu']">
      <ColorSwitcher :color="flowEvent.color" class="circle" @update="update('color', $event)">
        <PlainEditableText
          v-if="hasEmoji"
          :isEditable="isAdmin"
          :initialText="flowEvent.emoji"
          @update-text="update('emoji', $event)"
        />
      </ColorSwitcher>
      <a-menu slot="overlay" @click="onSubMenuClick">
        <a-menu-item key="addEmoji">Add Emoji</a-menu-item>
        <a-menu-item key="removeEmoji">Remove Emoji</a-menu-item>
      </a-menu>
    </a-dropdown>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import PlainEditableText from '../utilities/PlainEditableText.vue'
import { toDateString } from '@/helpers/timeFilters'
import RichTextEditor from '@/components/form/editor/RichTextEditor.vue'
export default {
  components: { PlainEditableText, RichTextEditor },
  props: { flowEvent: Object, last: Boolean },
  computed: {
    ...mapGetters(['activeGroupId', 'isAdmin']),
    hasEmoji() {
      return this.flowEvent && this.flowEvent.hasEmoji
    },
    time() {
      if (!this.flowEvent || !this.flowEvent.timestamp) return null
      const date = this.flowEvent.timestamp.seconds * 1000
      const day = new Intl.DateTimeFormat('en-US', { weekday: 'long' }).format(date)
      return `${day}, ${toDateString(date, 'dm', true, true, false)}`
    },
  },
  methods: {
    update(key, value) {
      const event = { ...this.flowEvent, [key]: value }
      delete event.id

      this.$emit('update', event)
    },
    onRightClick({ key }) {
      if (key === 'delete') {
        this.$emit('delete')
      }
    },
    onSubMenuClick({ key }) {
      switch (key) {
        case 'addEmoji': {
          this.update('hasEmoji', true)
          break
        }
        case 'removeEmoji': {
          this.update('hasEmoji', false)
          break
        }

        default:
          break
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/styles/_variables.scss';
.FlowEvent {
  position: relative;

  &:after {
    position: absolute;
    content: '';
    width: 2px;
    height: 100%;
    background: #ddd;
    left: 0px;
    top: 0px;
  }
  &.last:after {
    position: absolute;
    content: '';
    width: 2px;
    height: 15px;
    background: #ddd;
    left: 0px;
    top: 0px;
  }
  padding-top: 15px;
  padding-left: 15px;

  .time {
    // padding-bottom: 10px;
    font-size: 12px;
    color: #999;
  }

  .box {
    // padding: 8px 10px 10px 10px;
    // border-radius: 5px;
    // background: var(--background-b);

    .title {
      // font-size: 18px;
      // color: #aaa9f1;
    }
    .subtitle {
      color: var(--text);
    }
  }
  .circle {
    position: absolute;
    left: -7px;
    top: 15px;
    width: 16px;
    height: 16px;
    z-index: 10;
  }
}
</style>
