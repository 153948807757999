export default function getCheckpointOptions(form, usePredefinedOptions, onlyUsedCheckpoints) {
  const autoCheckpoints = ['loaded_flow', 'viewed_flow', 'interacted_with_flow']
  const predefinedOptionalCheckpoints = [
    'began_flow',
    'completed_flow',
    'submitted_request',
    'subscribed_to_emails',
    'booked_meeting',
    'joined_waitlist',
    'signed_up',
    'collected_email',
    'collected_name',
    'collected_phone',
    'collected_org',
    'collected_address',
    'reached_triage_page',
    'reached_email_page',
    'reached_contact_details_page',
    'reached_book_meeting_page',
  ]
  const checkpointsToUse = usePredefinedOptions
    ? predefinedOptionalCheckpoints
    : !onlyUsedCheckpoints
    ? autoCheckpoints
    : []

  const pages = (form && form.pages) || []
  pages.forEach(p => {
    if (p.checkpoints) checkpointsToUse.push(...p.checkpoints)
    const components = p.components || []
    components.forEach(c => {
      if (c.checkpoints) checkpointsToUse.push(...c.checkpoints)
      if (c.type === 'BookMeeting') {
        if (Array.isArray(c.checkpoints_tier_1)) checkpointsToUse.push(...c.checkpoints_tier_1)
        if (Array.isArray(c.checkpoints_tier_2)) checkpointsToUse.push(...c.checkpoints_tier_2)
      }
    })
    if (p.on_complete_checkpoints) checkpointsToUse.push(...p.on_complete_checkpoints)
  })
  return Array.from(new Set(checkpointsToUse)).filter(c => c)
}
