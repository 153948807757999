<template>
  <div class="FileUploadEditor">
    <a-space direction="vertical">
      <template v-if="location === 'options' || !location">
        <EditorSelect _key="upload_type" :options="uploadTypeOptions" />
        <EditorIcon _key="icon" v-bind="{ component }" />
        <EditorInput _key="label" v-bind="{ component }" />
        <EditorInput v-if="!component.use_audio_recorder" _key="sublabel" v-bind="{ component }" />
        <!-- <EditorCheckbox
          v-if="component.upload_type"
          _key="use_audio_recorder"
          label="Use Audio Recorder"
        /> -->
        <EditorCheckbox
          v-else
          _key="multiple"
          label="Allow Multiple Uploads"
          v-bind="{ component }"
        />
        <EditorCheckbox _key="extractData" label="Extract Data (if CSV)" v-bind="{ component }" />
        <EditorCheckbox _key="uploadFile" label="Upload File to the Cloud" v-bind="{ component }" />
        <EditorCheckbox _key="show_validation_messages" label="Enable Validation Message Box" />
      </template>
    </a-space>
  </div>
</template>

<script>
import { unpack } from '@/helpers/computed'

export default {
  name: 'FileUploadEditor',
  props: {
    componentId: String,
    component: Object,
    location: String,
  },
  computed: {
    ...unpack('component', ['extractData', 'uploadFile']),
    uploadTypeOptions() {
      return [{ key: undefined, label: 'All Types' }, 'audio', 'video', 'doc']
    },
  },
}
</script>
