<template>
  <div class="FlowExperimentVariant my-3 p-3 rounded border border-gray-200 relative">
    <Icon
      class="absolute z-2 top-4 right-4 cursor-pointer hover:text-purple"
      name="trash"
      @click.native="$emit('delete')"
    />
    <div class="mr-1">Variant {{ index + 1 }}{{ index === 0 ? ': Control' : '' }}</div>
    <PlainEditableText
      class="flex items-center monospaced font-bold"
      :initialText="variant.key"
      placeholder="Variant Key"
      @update-text="update('key', $event)"
    />
    <PlainEditableText
      class="flex items-center italic text-gray-600"
      :initialText="variant.description"
      placeholder="Description"
      @update-text="update('description', $event)"
    />
    <div class="flex my-2">
      <a-slider
        class="flex-1"
        :tip-formatter="value => `${+parseFloat(value).toFixed(2)}%`"
        :min="0"
        :max="100"
        v-model="weighting"
      />
      <a-input-number
        style="marginLeft: 16px"
        :min="0"
        :max="100"
        :formatter="value => `${+parseFloat(value).toFixed(2)}%`"
        :parser="value => value.replace('%', '')"
        v-model="weighting"
      />
    </div>
    <a-button
      v-if="experiment.key && variant.key"
      class="my-2"
      :type="viewing ? 'primary' : null"
      @click="viewVariant"
    >
      <Icon class="mr-1" :name="viewing ? 'eye' : 'eye-slash'" />
      {{ viewing ? 'Viewing' : 'Click to View' }}
    </a-button>
    <!-- <a-button
      v-if="experiment.status === 'live' && experiment.key && variant.key"
      class="my-2"
      type="primary"
      @click="declareWinner"
    >
      <Icon class="mr-1" name="glass-cheers" />
      Declare Winner
    </a-button> -->
  </div>
</template>

<script>
import debounce from 'lodash/debounce'

import PlainEditableText from '@/components/utilities/PlainEditableText'

export default {
  name: 'FlowExperimentVariant',
  components: {
    PlainEditableText,
  },
  inject: ['_updateData', '_setUserData', '_setSelectedComponentId'],
  props: {
    variant: Object,
    index: Number,
    experiment: Object,
    experimentIndex: Number,
    form: Object,
    userData: Object,
    viewing: Boolean,
  },
  data() {
    return {
      open: false,
      weighting: this.variant.weighting || 0,
    }
  },
  computed: {
    debouncedUpdate() {
      return debounce(this.update, 250, { trailing: true, leading: false })
    },
  },
  watch: {
    variant: {
      handler(val) {
        console.log('val', val)
        if (this.weighting !== val.weighting) this.weighting = val.weighting || 0
      },
      deep: true,
    },
    weighting(val) {
      this.debouncedUpdate('weighting', val)
    },
  },
  methods: {
    update(key, val) {
      console.log('key, val', key, val)
      this._updateData(`experiments.${this.experimentIndex}.variants.${this.index}`, key, val)
    },
    viewVariant() {
      const variantKeyToSet =
        this.userData[this.experiment.key] === this.variant.key ? null : this.variant.key

      const newUserData = { ...this.userData, [this.experiment.key]: variantKeyToSet }
      console.log('newUserData', newUserData)
      this._setUserData(newUserData)
      this._setSelectedComponentId({})
    },
    declareWinner() {},
  },
}
</script>
