import Firebase from 'firebase/app'
import 'firebase/firestore'

export default function id(prefix) {
  return (
    (prefix ? `${prefix}_` : '') +
    Firebase.firestore()
      .collection('_')
      .doc().id
  )
}
