<template>
  <div class="SavvyFunnelCard" :class="presetField">
    <table style="height: 100%;">
      <tr>
        <td
          v-for="{ key, percent, percentFirst, count, stageSpend } in chartData"
          :key="key"
          :style="{ width: `${100 / chartData.length}%` }"
        >
          <div class="label">
            <div class="bar" :style="{ flexBasis: `calc(${percent}% - 14px)` }">
              <Annotation :id="`${id}-${key}`" />
              <div class="value" :class="{ canHide: percentFirst }">{{ count }}</div>
              <div v-if="percentFirst" class="value value-hover">
                {{ percentFirst.toFixed(2) }} %
              </div>
              <div v-if="stageSpend" class="value value-hover spend">Cost: ${{ stageSpend }}</div>
            </div>
          </div>
        </td>
      </tr>

      <tr style="height: 20px;">
        <td class="label-text" v-for="{ key, keyText } in chartData" :key="key">
          <template v-if="keyText !== key">
            <a-tooltip>
              <template slot="title">{{ key }}</template>
              {{ keyText }}
            </a-tooltip>
          </template>
          <template v-else>{{ key }} </template>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    id: String,
    funnelId: String,
    preset: String,
    name: String,
    data: Object,
    rawData: [Array, Object],
    colors: [Array, null],
    icon: String,
    config: Object,
  },
  computed: {
    presetField() {
      return (this.config && this.config[`${this.config.service}-breakdown`]) || this.preset
    },
    // journey() {
    //   return this.$store.getters.journey(this.funnelId)
    // },
    // journeyStages() {
    //   return (this.journey && this.journey.stages) || []
    // },
    totalSpend() {
      return this.rawData && this.rawData[0] && this.rawData[0].totalSpend
    },
    chartData() {
      if (!this.data || !this.data.labels || this.data.datasets.length === 0) return

      const data = this.data.labels.map((label, i) => ({
        key: label,
        count: this.data.datasets[0].data[i],
      }))
      // .sort((a, b) => b.count - a.count)
      const max = Math.max(...data.map(row => row.count || 0))

      return data.map((row, i) => {
        const count = row.count || 0
        const values = (row.values && row.values[0]) || {}
        const percentPrev = data[i - 1] ? (100 * count) / data[i - 1].count : undefined
        const percentFirst = data[0] ? (100 * count) / data[0].count : undefined
        const key =
          row.key === '__null' || row.key === 'null-null'
            ? 'Unknown'
            : this.presetField === 'channels'
            ? `${values.channel}${values.medium !== 'null' ? ` (${values.medium})` : ''}`
            : row.key
        // const stageId = this.journeyStages.find(s => s.label === key)
        const stageSpend = this.totalSpend ? (this.totalSpend / count).toFixed(2) : undefined
        return {
          ...row,
          count,
          key,
          keyText:
            key.split(' ').length > 2
              ? `${key
                  .split(' ')
                  .slice(0, 2)
                  .join(' ')}...`
              : key,
          stageSpend,
          // stageId,
          percent: (100 * count) / max,
          percentPrev,
          percentFirst,
          icons: this.presetField === 'channels' ? this.getIcons(row.domains) : null,
        }
      })
    },
    nameKey() {
      switch (this.presetField) {
        case 'pages':
          return 'page'
        case 'channels':
          return 'channel'
        case 'continents':
          return 'continent'
        case 'countries':
          return 'country'
        case 'regions':
          return 'region'
        case 'cities':
          return 'city'
        default:
          return null
      }
    },
  },
  watch: {
    colors() {
      this.setColors()
    },
  },
  mounted() {
    this.setColors()
  },
  methods: {
    sendToLiveView(id) {
      if (!id) return
      const path = `/projects/${this.$route.params.projectId}/pages/people`
      this.$router.push(`${path}?stage=${id}`)
    },
    setColors() {
      if (!this.$el || !this.$el.style) return

      if (this.colors) this.$el.style.setProperty('--bar-color', this.colors[0])
      if (this.colors) this.$el.style.setProperty('--bar-background', this.colors[0] + '44')
      if (this.colors) this.$el.style.setProperty('--bar-background-faded', this.colors[0] + '11')
      if (this.colors) this.$el.style.setProperty('--bar-background-strong', this.colors[0] + '66')
    },
    getIcons(domains) {
      if (!domains || !domains.length) return []

      return domains
        .map(
          d =>
            d &&
            d
              .split('.')
              .slice(-2)
              .join('.')
              .split('.android')
              .join('')
        )
        .map(d => `https://logo.clearbit.com/${d}`)
        .reduce((acc, url) => acc.concat([url, `${url}.com`]), [])
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/_variables.scss';

.SavvyFunnelCard {
  --bar-color: #555;
  --bar-background: #55555544;
  --bar-background-faded: #55555511;
  --bar-background-strong: #55555566;

  @include flex('row', 'stretch');
  height: 100%;

  .header {
    @include flex('a-center');
    font-size: 1.2em;
    text-transform: capitalize;
    margin: 0.25em 0 0.75em;

    .Icon {
      font-size: 0.9em;
      margin-right: 0.5em;
      margin-bottom: 0;
    }
  }

  &.channels {
    .label {
      text-transform: capitalize;
    }
  }

  .label {
    padding-left: 0.25em;
    padding-right: 0.25em;
    height: 100%;
    @include flex('column', 'a-stretch', 'j-end');

    &:hover {
      .Annotation {
        display: flex;
      }
      .bar {
        background: var(--bar-background-strong);

        .value.canHide {
          display: none;
        }
        .value-hover {
          display: initial;
        }
      }
    }

    .value {
      box-sizing: border-box;
      // min-width: 4em;
      text-align: center;
      position: absolute;
      left: 0;
      right: 0;
      top: -20px;

      &.spend {
        top: -40px;
      }
    }

    .bar {
      position: relative;
      flex: 0 0 0;
      border-radius: 2px;
      background: var(--bar-background);
      cursor: default;

      .value-hover {
        display: none;
      }
    }
  }
  .label-text {
    position: relative;
    z-index: 2;
    text-align: center;
    vertical-align: top;

    .icon-container {
      width: 25px;
      height: 20px;

      img {
        height: 20px;
        border-radius: 3px;
      }
    }
  }
}
</style>
