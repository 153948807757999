<template>
  <div class="FlowEventsTimeline">
    <Entities renderless v-bind="{ collection, filters, sortBy }" v-model="flowEvents" />
    <FlowEvent
      v-for="(event, i) in flowEvents"
      :key="event.id"
      :last="i === flowEvents.length - 1"
      :flowEvent="event"
      @update="updateFlowEvent(event.id, $event)"
      @delete="deleteFlowEvent(event.id)"
    />
    <a-button v-if="isAdmin" class="new-event" @click="createFlowEvent">Add New Event</a-button>
  </div>
</template>
<script>
import Firebase from 'firebase/app'
import 'firebase/firestore'
import { mapGetters } from 'vuex'

import FlowEvent from './FlowEvent.vue'
export default {
  components: { FlowEvent },
  props: { tags: { default: () => [], type: Array } },
  data() {
    return { flowEvents: [] }
  },
  computed: {
    ...mapGetters(['activeGroupId', 'isAdmin']),
    collection() {
      return `groups/${this.activeGroupId}/timeline-events`
    },
    filters() {
      if (this.tags.length > 0) return [['tags', 'array-contains-any', this.tags]]
      return undefined
    },
    sortBy() {
      return ['timestamp', 'asc']
    },
  },
  watch: {},
  methods: {
    async deleteFlowEvent(id) {
      const ref = Firebase.firestore()
        .collection(this.collection)
        .doc(id)
      await ref.delete()
    },
    async updateFlowEvent(id, data) {
      await Firebase.firestore()
        .collection(this.collection)
        .doc(id)
        .update(data)
    },
    async createFlowEvent() {
      const ref = Firebase.firestore()
        .collection(this.collection)
        .doc()
      const defaultData = {
        title: '',
        subtitle: '',
        color: '',
        emoji: '',
        tags: this.tags || [],
        timestamp: Firebase.firestore.FieldValue.serverTimestamp(),
      }
      ref.set(defaultData)
    },
  },
}
</script>
<style lang="scss" scoped>
.FlowEventsTimeline {
  padding: 10px;

  &:not(:hover) {
    .new-event {
      display: none;
    }
  }

  .new-event {
    margin-top: 10px;
  }
}
</style>
