<template>
  <div class="DataLogicPane px-4">
    <template v-if="location === 'options' || !location">
      <div class="flex gap-1 mt-4 mb-2 items-center">
        <template v-if="_getSiblingOptions().length">
          <div class="flex-auto overflow-hidden">
            <div class="text-xs uppercase font-bold text-gray-400 mt-1">
              {{ singularType }}
            </div>

            <div class="overflow-hidden flex gap-1">
              <div class="font-mono font-bold whitespace-nowrap overflow-ellipsis overflow-hidden">
                {{ currentLabel }}
              </div>
            </div>
          </div>
          <a-tooltip title="Edit as raw JSON">
            <a-button
              class="flex-shrink-0"
              icon="code"
              @click="_openDataPath({ path: basePath, title: currentLabel })"
            />
          </a-tooltip>
          <a-tooltip title="Add">
            <a-button class="flex-shrink-0" icon="plus" @click="onActionClick('add')" />
          </a-tooltip>
          <a-tooltip v-if="type === 'computed_fields'" title="Duplicate">
            <a-button class="flex-shrink-0" icon="copy" @click="onActionClick('duplicate')" />
          </a-tooltip>
          <!-- <a-tooltip title="Delete"> -->
          <a-popconfirm
            :title="`Are you sure you want to delete this ${singularType}?`"
            ok-text="Yes"
            cancel-text="No"
            @confirm="onActionClick('remove')"
          >
            <!-- @click="$emit('remove')" -->
            <a-button class="flex-shrink-0" icon="delete" />
          </a-popconfirm>
          <!-- <a-button class="flex-shrink-0" icon="delete" @click="onActionClick('remove')" /> -->
          <!-- </a-tooltip> -->
        </template>
        <div
          v-else
          class="flex flex-row items-center border rounded bg-white cursor-pointer py-1 px-3"
          @click="onActionClick('add')"
        >
          <span class="ml-2">+ Add your first {{ singularType }}</span>
        </div>
      </div>
    </template>
    <template v-if="location === 'options' || !location">
      <DataOutputEditor
        v-if="type === 'data_outputs' && currentObj"
        :key="currentObj && currentObj.id"
        :index="currentIndex"
        :dataOutput="currentObj"
        v-bind="{ form, userData }"
        @update="onDataOutputUpdate(currentIndex, $event)"
        @remove="removeDataOutput(currentIndex)"
      />
      <ComputedFieldEditor
        v-if="type === 'computed_fields' && currentObj"
        :key="currentObj && currentObj.id"
        :computedFieldIndex="currentIndex"
        :computedField="currentObj"
        v-bind="{ form, userData }"
        @update="onComputedFieldUpdate(currentIndex, $event)"
        @remove="removeComputedField(currentIndex)"
      />
    </template>
  </div>
</template>
<script>
import DataOutputEditor from '../DataOutputEditor'
import ComputedFieldEditor from '../ComputedFieldEditor'
import { startCase } from 'lodash'
export default {
  name: 'DataLogicPane',
  components: { DataOutputEditor, ComputedFieldEditor },
  props: {
    type: String,
    form: Object,
    userData: Object,
    currentId: String,
    location: String,
  },
  inject: [
    '_openDataPath',
    '_updateReactive',
    '_getSiblingOptions',
    '_onSelectDataOutput',
    // '_onSelectComputedField',
    '_openModal',
    '_doAction',
  ],
  data() {
    return {}
  },
  computed: {
    singularType() {
      return startCase(this.type).slice(0, -1)
    },
    basePath() {
      switch (this.type) {
        case 'data_outputs':
          return `dataOutputs.${this.currentIndex}`
        case 'computed_fields':
          return `computedFields.${this.currentIndex}`

        default:
          return null
      }
    },
    fieldName() {
      switch (this.type) {
        case 'data_outputs':
          return 'dataOutputs'
        case 'computed_fields':
          return 'computedFields'

        default:
          return null
      }
    },
    currentLabel() {
      const option = this._getSiblingOptions().find(o => o.id === this.currentId)
      return (option && option.label) || 'N/A'
    },
    typeArray() {
      return this.form[this.fieldName] || []
    },
    currentObj() {
      return this.typeArray.find(t => t.id === this.currentId)
    },
    currentIndex() {
      return this.typeArray.findIndex(t => t.id === this.currentId)
    },
  },
  watch: {},
  methods: {
    // onClickSibling(e) {
    //   switch (this.type) {
    //     case 'computed_fields': {
    //       this._onSelectComputedField(e)
    //       break
    //     }
    //     case 'data_outputs': {
    //       this._onSelectDataOutput(e)
    //       break
    //     }

    //     default:
    //       break
    //   }
    // },
    onActionClick(e) {
      switch (this.type) {
        case 'computed_fields': {
          if (e === 'remove') this.removeComputedField(this.currentIndex)
          if (e === 'add') this.newComputedField()
          if (e === 'duplicate') this._openModal('duplicate-computed-field')
          break
        }
        case 'data_outputs': {
          if (e === 'remove') this.removeDataOutput(this.currentIndex)
          if (e === 'add') this._doAction('new-output')
          break
        }
        default:
          break
      }
    },
    onDataOutputUpdate(output, [key, value]) {
      this._updateReactive(`dataOutputs[${output}]`, key, value)
    },
    removeDataOutput(index) {
      const outputs = this.form.dataOutputs.filter((d, i) => i !== index)
      this._updateReactive(null, 'dataOutputs', outputs)
    },
    onComputedFieldUpdate(index, [key, value]) {
      this._updateReactive(`computedFields[${index}]`, key, value)
    },
    newComputedField() {
      this._openModal('new-computed-field')
    },
    removeComputedField(index) {
      const outputs = this.form.computedFields.filter((d, i) => i !== index)
      this._updateReactive(null, 'computedFields', outputs)
    },
  },
}
</script>
