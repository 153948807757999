export default function getWidths() {
  return [
    {
      key: 'full',
      icon: 'column-width',
      popular: true,
      type: 'full',
    },
    {
      key: 'tablet',
      icon: 'tablet',
      popular: true,
      type: 'tablet',
    },
    {
      key: 'mobile',
      icon: 'mobile',
      popular: true,
      type: 'mobile',
    },
    {
      key: 'large',
      icon: 'fullscreen',
      type: 'full',
    },
    {
      key: 'mobile:iphone_12',
      icon: 'mobile',
      type: 'mobile',
    },
    {
      key: 'mobile:google_pixel_3',
      icon: 'mobile',
      type: 'mobile',
    },
    {
      key: 'mobile:plus-margin',
      icon: 'mobile',
      type: 'mobile',
    },
  ]
}
