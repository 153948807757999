<template>
  <div class="CustomAuthEditor">
    <a-space direction="vertical">
      <EditorSelect _key="service" :options="serviceOptions" />
      <EditorCheckbox _key="default_ui" label="Use Default UI" defaultTrue />
      <EditorInput
        v-if="component.default_ui === false"
        allowComputed
        _key="email_field"
        v-bind="{ component }"
      />
      <EditorInput
        v-if="component.default_ui === false"
        allowComputed
        _key="password_key"
        v-bind="{ component }"
      />
      <EditorInput allowComputed _key="button_text" v-bind="{ component }" />
    </a-space>
  </div>
</template>
<script>
export default {
  props: { componentId: String, component: Object, form: Object },
  data() {
    return {}
  },
  computed: {
    serviceOptions() {
      return ['firebase-auth']
    },
  },
  watch: {},
  methods: {},
}
</script>
