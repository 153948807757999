<template>
  <div class="ProgressBarEditor">
    <a-space direction="vertical">
      <template v-if="location === 'options' || !location">
        <EditorSelect
          _key="progress_type"
          :options="barOptions"
          :defaultVal="progressBarType || 'circle'"
        />
        <!-- <EditorCheckbox _key="use_custom_bar" label="Use Custom Design" /> -->
        <template v-if="progressBarType === 'custom'">
          <EditorCheckbox _key="allow_nav" label="Allow Navigation to Previous Pages" />
          <EditorCheckbox _key="fill_connectors" label="Animate Connector Fill" />
          <EditorLabel label="Default Node Icon" />
          <EditorIcon _key="node_icon" :component="component" />
          <EditorInput _key="node_icon" placeholder="Icon Key" :component="component" />
        </template>
        <template v-else>
          <FormProgressEditor :form="form" :component="component" />
        </template>
      </template>
    </a-space>
  </div>
</template>

<script>
import { debounce } from 'lodash'
import FormProgressEditor from './FormProgressEditor.vue'

export default {
  components: { FormProgressEditor },
  name: 'ProgressBarEditor',
  props: {
    componentId: String,
    component: Object,
    form: Object,
    location: String,
  },
  computed: {
    debounceUpdate() {
      return debounce(update => this.$emit('update', update), 200, {
        trailing: true,
        leading: false,
      })
    },
    progressBarType() {
      if (!this.component.progress_type && this.component.use_custom_bar) return 'custom'
      return this.component.progress_type || 'circle'
    },
    barOptions() {
      return ['circle', 'simple', 'custom']
    },
  },
}
</script>
