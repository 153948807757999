<template>
  <div class="ContactActivity">
    <div v-if="numPages > 1" class="num-pages whitespace-nowrap">
      <Icon type="far" name="copy" />
      {{ numPages }} Pages
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactActivity',
  props: {
    contact: Object,
    extras: Object,
  },
  computed: {
    numPages() {
      return ((this.extras && this.extras.cleanUrls) || []).length
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/_variables.scss';

.ContactActivity {
}
</style>
