<template>
  <div class="Toggle" :class="{ isOn }" @click="wasClicked">
    <slot :isOn="isOn" :toggle="toggle" :turnOn="turnOn" :turnOff="turnOff" />
  </div>
</template>

<script>
export default {
  props: {
    isToggle: Boolean,
    isTurnOn: Boolean,
    beginOn: Boolean,
  },
  data() {
    return {
      isOn: false,
    }
  },
  watch: {
    isOn(isOn) {
      this.$emit('on-update', isOn)
    },
  },
  mounted() {
    if (this.beginOn) {
      this.turnOn(true)
    }
  },
  methods: {
    toggle(withDelay) {
      this.setState(!this.isOn, withDelay)
    },
    turnOn(withDelay) {
      this.setState(true, withDelay)
    },
    turnOff(withDelay) {
      this.setState(false, withDelay)
    },
    setState(newState, withDelay) {
      if (withDelay) {
        setTimeout(() => {
          this.isOn = newState
        }, 50)
      } else {
        this.isOn = newState
      }
    },
    wasClicked() {
      if (this.isToggle) this.toggle(50)
      else if (this.isTurnOn) this.turnOn(50)
    },
  },
}
</script>
