<template>
  <a-tooltip :title="tooltip">
    <div
      class="ElementCard relative flex border-gray-300 p-1 rounded items-center text-center break-words cursor-pointer hover:border-purple group"
      :class="{
        'border-purple': isSelected,
        'border-2': isSelected,
        border: !isSelected,
        '-mx-0.5': isSelected,
        'flex-row': landscape,
        'flex-col': !landscape,
        'justify-start': landscape,
      }"
    >
      <Icon
        v-if="icon"
        class="text-3xl m-1 group-hover:text-purple"
        :class="{
          'text-purple': isSelected,
          'mr-4': landscape,
          'ml-2': landscape,
        }"
        :name="icon"
      />
      <img
        v-else-if="image"
        class="max-w-full max-h-20 m-1 mb-2"
        :class="{
          'mr-4': landscape,
          'ml-2': landscape,
        }"
        :src="image"
      />
      <div class="flex-1" style="max-width: 100%;" :class="{ 'text-left': landscape }">
        {{ label }}
      </div>
      <Icon v-if="endIcon" class="mx-3 text-gray-400" :name="endIcon" />
      <div class="flex flex-row items-center element-bubble-position">
        <div
          v-if="grayBubble"
          class="element-bubble gray text-white"
          :class="{ left: bubble }"
        ></div>
        <div
          v-if="bubble && typeof bubbleCount === 'number' && !useNumberBubble"
          class="flex"
          :class="{
            '-m-px': isSelected,
          }"
        >
          <div
            v-for="num in range"
            :key="num"
            class="element-bubble ml-0.5 text-white flex justify-center items-center"
          ></div>
        </div>
        <div
          v-else-if="bubble && typeof bubbleCount === 'number' && useNumberBubble"
          class="element-bubble large text-white flex justify-center items-center"
        >
          {{ bubbleCount }}
        </div>
        <div v-else-if="bubble" class="element-bubble text-white"></div>
      </div>
    </div>
  </a-tooltip>
</template>
<script>
export default {
  name: 'ElementCard',
  props: {
    bubble: Boolean,
    grayBubble: Boolean,
    bubbleCount: Number,
    useNumberBubble: { default: false, type: Boolean },
    icon: String,
    image: String,
    endIcon: String,
    label: String,
    isSelected: Boolean,
    landscape: Boolean,
    tooltip: String,
  },
  computed: {
    range() {
      return Array(this.bubbleCount)
        .fill(5)
        .map((e, i) => i)
    },
  },
}
</script>

<style lang="scss">
.element-bubble-position {
  top: -4px;
  right: -4px;
  position: absolute;
  &.left {
    right: 4px;
  }
}
.element-bubble {
  background: #6454f3;
  width: 8px;
  height: 8px;
  border-radius: 100px;

  &.gray {
    background: gray;
  }

  &.large {
    width: 16px;
    height: 16px;
    top: -8px;
    right: -8px;
  }
  .bubble-dot {
    background: white;
    width: 2px;
    height: 2px;
    border-radius: 100px;
  }
}
</style>
