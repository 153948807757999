<template>
  <div class="FlowAnalyticsCard">
    <FlowAnalyticsCardSeries
      :algoliaFilterString="algoliaFilterString"
      v-bind="{
        form,
        chart,
        tab,
        currentPageId,
      }"
      @update="onUpdate"
    />
    <FlowAnalyticsCardSeries
      v-if="algoliaCompareFilterString"
      :algoliaFilterString="algoliaCompareFilterString"
      v-bind="{
        form,
        chart,
        tab,
        currentPageId,
      }"
      @update="onUpdate($event, true)"
    />
    <div class="flex justify-between items-center">
      <Pill class="-mt-7 overflow-hidden">
        <Icon class="mr-1" :name="isFunnel ? 'filter' : 'signal-alt'" />
        <div class="text-xs uppercase font-bold text-gray-400">
          {{ isFunnel ? 'Your Funnel' : 'Breakdown:' }}
        </div>
        <div v-if="!isFunnel" class="monospaced ml-2 overflow-hidden overflow-ellipsis">
          {{ chart.key === 'first_version' ? 'version' : chart.key }}
        </div>
      </Pill>
    </div>
    <div
      v-if="loading"
      class="flex-1 flex flex-col justify-center items-center text-gray-400 min-h-20"
    >
      <Icon loading />
    </div>
    <div
      v-else-if="data.length === 0"
      class="flex-1 flex flex-col justify-center text-center text-gray-400"
    >
      No data for this property yet
    </div>
    <div
      v-else
      class="grid grid-cols-2 gap-2 overflow-hidden"
      style="grid-template-columns: 10fr 1fr;"
    >
      <div></div>
      <div
        class="cursor-pointer hover:opacity-70 font-bold text-gray-400 text-right -mt-1 -mb-2 whitespace-nowrap"
        @click="showNum = !showNum"
      >
        {{ showNum ? '#' : '%' }}
      </div>
      <template v-for="row in combinedData">
        <div :key="row[0].value" class="flex items-center overflow-hidden">
          <div
            class="bg-purple h-1 rounded-full"
            :style="{
              flex:
                row[0].isLargest && row[0].count === largestCount
                  ? '1 1 auto'
                  : `0 0 ${(maxWidth * row[0].count) /
                      (showNum
                        ? largestCount
                        : (data.find(r => r.isLargest) || {}).count || row[0].count)}px`,
            }"
            :ref="`bar${row[0].isLargest ? ':largest' : ''}`"
          ></div>
          <a-tooltip :title="row[0].valueText">
            <Pill
              class="monospaced overflow-hidden overflow-ellipsis"
              block
              :style="{ maxWidth: isFunnel ? '100px' : '150px' }"
              :selected="tab === 'this_page' && row[0].isCurrentPage"
            >
              {{ row[0].valueText }}
            </Pill>
          </a-tooltip>
        </div>
        <div :key="`${row[0].value}:count`" class="flex items-center text-right justify-end">
          {{ showNum ? row[0].count : displayPercent(row[0].countPercent) }}
        </div>
        <template v-if="row[1]">
          <div :key="`${row[1].value}|2`" class="flex items-center overflow-hidden -mt-3 mb-1">
            <div
              class="bg-purple h-1 rounded-full"
              style="background-color: var(--color-highlight);"
              :style="{
                flex:
                  row[1].isLargest && row[1].count === largestCount
                    ? '1 1 auto'
                    : `0 0 ${(maxWidth * row[1].count) /
                        (showNum
                          ? largestCount
                          : (compareData.find(r => r.isLargest) || {}).count || row[1].count)}px`,
              }"
              :ref="`bar${row[1].isLargest ? ':largest' : ''}`"
            ></div>
            <a-tooltip :title="row[1].valueText">
              <Pill
                class="monospaced overflow-hidden overflow-ellipsis"
                block
                :style="{ maxWidth: isFunnel ? '100px' : '150px' }"
                :selected="tab === 'this_page' && row[1].isCurrentPage"
                filledColor="var(--color-highlight)"
              >
                {{ row[1].valueText }}
              </Pill>
            </a-tooltip>
          </div>
          <div
            :key="`${row[1].value}:count|2`"
            class="flex items-center text-right justify-end -mt-3 mb-1"
          >
            {{ showNum ? row[1].count : displayPercent(row[1].countPercent) }}
          </div>
        </template>
      </template>
    </div>
    <a-tooltip
      title="Pages with conditions aren't shown to all users, so can show up in funnel charts as much smaller numbers. This can make funnels more confusing to glance at, so we hide those pages from the chart by default."
    >
      <div
        v-if="isFunnel && allUnconditionalPages.length < allPages.length"
        class="mt-2 -mb-1 text-center text-xs text-gray-400 hover:opacity-80 cursor-pointer"
        @click="showConditionalPages = !showConditionalPages"
      >
        <Icon :name="showConditionalPages ? 'eye-slash' : 'eye'" class="mr-1" />
        {{ showConditionalPages ? 'Hide' : 'Show' }} Conditional Pages
      </div>
    </a-tooltip>
  </div>
</template>

<script>
import FlowAnalyticsCardSeries from './FlowAnalyticsCardSeries'

export default {
  name: 'FlowAnalyticsCard',
  components: {
    FlowAnalyticsCardSeries,
  },
  props: {
    form: Object,
    chart: Object,
    algoliaFilterString: String,
    algoliaCompareFilterString: String,
    tab: String,
    currentPageId: String,
  },
  data() {
    return {
      searchResults: [],
      showNum: false,
      maxWidth: 0,
      searchToken: 0,
      loadingData: true,
      loadingCompareData: true,
      showConditionalPages: false,
      algoliaResults: null,
      data: [],
      compareData: [],
    }
  },
  computed: {
    loading() {
      return Boolean(
        this.loadingData || (this.algoliaCompareFilterString && this.loadingCompareData)
      )
    },
    isFunnel() {
      return this.chart.key === '_funnel'
    },
    updateWidthTrigger() {
      return [this.data, this.compareData, this.showNum]
    },
    currentPageKey() {
      return (this.form.pages.find(p => p.id === this.currentPageId) || {}).key
    },
    allPages() {
      return this.form.pages.filter(p => !p.hide)
    },
    allUnconditionalPages() {
      return this.allPages.filter(p => noSeriousConditions(p))
    },
    allShowingPages() {
      return this.showConditionalPages ? this.allPages : this.allUnconditionalPages
    },
    combinedData() {
      if (!this.algoliaCompareFilterString) return this.data.map(r => [r])

      return this.data.map(r => {
        const row = { ...r }

        const compareRow = this.compareData.find(r => r && row && r.value === row.value) || {
          ...row,
          count: 0,
          countPercent: 0,
        }

        return [row, compareRow]
      })
    },
    largestCount() {
      return Math.max(
        (this.data.find(r => r.isLargest) || {}).count || 0,
        (this.compareData.find(r => r.isLargest) || {}).count || 0
      )
    },
  },
  watch: {
    updateWidthTrigger: {
      handler() {
        this.$nextTick(() => {
          this.$nextTick(() => {
            this.$nextTick(() => {
              if (!(this.$refs['bar:largest'] || [])[0]) return

              this.maxWidth = this.$refs['bar:largest'][0].scrollWidth
            })
          })
        })
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    displayPercent(num) {
      num = num * 100
      num =
        num === 0 ? 0 : num < 0.095 ? num.toFixed(2) : num < 0.95 ? num.toFixed(1) : num.toFixed(0)
      return `${num}%`
    },
    onUpdate([data, loadingData], compare) {
      if (compare) {
        this.compareData = data
        this.loadingCompareData = loadingData
      } else {
        this.data = data
        this.loadingData = loadingData
      }
    },
  },
}

function noSeriousConditions(p) {
  return !p.conditions || !p.conditions.length // || !p.conditions.filter(allowsDefaults).length
}

// function allowsDefaults(c) {
//   return (
//     (c.operator === '==' && c.values.includes('_no_value')) ||
//     (c.operator === '!=' && !c.values.includes('_no_value'))
//   )
// }
</script>
