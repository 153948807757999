<template>
  <a-space>
    <vfa-picker :value="iconPickerString">
      <template v-slot:activator="{ on }">
        <a-tooltip :trigger="['hover']" placement="top" title="Cmd + Click to remove icon">
          <Icon :icon="icon || 'expand'" @click.exact.native="on" @click.meta.native="remove" />
        </a-tooltip>
      </template>
      <template v-slot:icon="{ icon, picked }">
        <div @click="onPicked(picked, icon)" :title="icon.label">
          <span :class="[parent(icon), `fa-${icon.class}`, 'vfa-icon-preview']" />
          <div class="vfa-icon-info">
            <span class="class">{{ icon.class }}</span>
          </div>
        </div>
      </template>
    </vfa-picker>
  </a-space>
</template>

<script>
import Icon from '../../Icon.vue'

export default {
  name: 'EditorIcon',
  components: {
    Icon,
  },
  inject: ['_getBasePath', '_updateData'],
  props: {
    component: Object,
    _key: String,
    placeholder: String,
  },
  computed: {
    libraryOptions() {
      return ['feather', 'fa']
    },
    icon() {
      return this.component && this.component[this._key]
    },
    p() {
      return (
        this.placeholder ||
        (this._key &&
          this._key
            .split(/-|_/)
            .map(w => w[0].toUpperCase() + w.slice(1))
            .join(' '))
      )
    },
    iconPickerString() {
      return Array.isArray(this.icon) ? this.icon.join(',') : this.icon
    },
  },
  methods: {
    parent(icon) {
      if (icon.styles.indexOf('regular') > -1) {
        return 'fa'
      } else if (icon.styles.indexOf('solid') > -1) {
        return 'fas'
      } else if (icon.styles.indexOf('brands') > -1) {
        return 'fab'
      }
      return ''
    },
    onPicked(picked, icon) {
      const val = [this.parent(icon), icon.class]

      this._updateData(this._getBasePath(), this._key, val)

      picked(icon)
    },
    remove() {
      this._updateData(this._getBasePath(), this._key, null)
    },
  },
}
</script>
