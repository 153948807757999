import get from 'lodash/get'
import { toSnake } from '@/helpers/textStringConversions'
import { debounce } from 'lodash'

export default {
  inject: ['_getData', '_getBasePath', '_updateData', '_setQuickHelp'],
  props: {
    _key: String,
    jsxKey: String,
    path: String,
    label: String,
    placeholder: String,
    suffix: String,
    tooltip: String,
    transform: Function,
    transformGet: Function,
    isKey: Boolean,
    global: Boolean,
    multiple: Boolean,
    emit: Boolean,
    value: {},
    noLabel: Boolean,
  },
  data() {
    return {
      debounce: false,
    }
  },
  computed: {
    key() {
      return this.jsxKey || this._key
    },
    pathCalc() {
      // Useful in case component wants to replace this prop to add to path (e.g. add .tags on the end)
      return this.path
    },
    flowData() {
      return this._getData()
    },
    basePath() {
      const base = this.global ? `defaults` : this._getBasePath()
      return base.startsWith('.') ? base.slice(1) : base
    },
    fullBasePath() {
      return this.pathCalc && this.pathCalc.split('.')[1]
        ? `${this.basePath}.${this.pathCalc
            .split('.')
            .slice(0, -1)
            .join('.')}`
        : this.basePath
    },
    finalKey() {
      return this.pathCalc ? this.pathCalc.split('.').slice(-1)[0] : this.key
    },
    val: {
      get() {
        if (this.emit) return this.value
        const val = get(this.flowData, [this.fullBasePath, this.finalKey].filterExists().join('.'))
        return typeof this.transformGet === 'function' ? this.transformGet(val) : val
      },
      set(val) {
        const finalVal = this.isKey
          ? this.multiple
            ? val.map(transformKey)
            : transformKey(val)
          : this.transform
          ? this.transform(val)
          : val

        if (this.emit === true) {
          this.$emit('input', finalVal)
        } else {
          const fn = this.debounce ? this.debounceUpdateData : this._updateData
          fn(this.fullBasePath, this.finalKey, finalVal)
        }
      },
    },
    debounceUpdateData() {
      return debounce(this._updateData, 250, { trailing: true, leading: false })
    },
    title() {
      return (
        this.label ||
        this.placeholder ||
        (this.finalKey &&
          this.finalKey
            .split(/-|_/)
            .map(w => w[0].toUpperCase() + w.slice(1))
            .join(' '))
      )
    },
  },
  methods: {
    onMouseEnter() {
      let quickHelpKey = ''

      const folders = [
        ['computedFields', 'computed_field'],
        ['pages', 'page'],
        ['components', 'component'],
        ['dataOutputs', 'data_output'],
        ['buttons', 'button'],
        ['conditions', 'condition'],
        ['triggers', 'trigger'],
        ['styles', 'style'],
      ]
      folders.forEach(f => {
        if (this.fullBasePath.includes(f[0])) quickHelpKey += `${f[1]}|`
      })
      quickHelpKey += this.finalKey

      this._setQuickHelp({
        key: quickHelpKey,
        label: this.label || this.title,
        position: this.$el.getBoundingClientRect(),
      })
    },
    onMouseLeave() {
      this._setQuickHelp(null)
    },
  },
}

function transformKey(t) {
  return t && toSnake(t)
}
