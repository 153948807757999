export function getStartOfDay(time) {
  const day = new Date(time)
  day.setHours(0)
  day.setMinutes(0)
  day.setSeconds(0)
  day.setMilliseconds(0)
  return day
}

export function getLast5Minutes(time) {
  const date = new Date(time)

  return new Date(date.getTime() - 1000 * 300)
}

export function getStartOfWeek(time) {
  const today = new Date(time)
  const day = 24 * 60 * 60 * 1000
  const weekday = today.getDay()
  const offset = weekday === 0 ? 6 : weekday - 1

  return new Date(getStartOfDay(time).getTime() - day * offset)
}

export function getStartOfMonth(time) {
  const date = new Date(time)
  date.setDate(1)
  date.setHours(0)
  date.setMinutes(0)
  date.setSeconds(0)

  return new Date(date)
}

export function toDateString(time, order, stringify, short, useSuffix) {
  const date = new Date(time)
  const year = date.getFullYear()
  const monthNum = date.getMonth() + 1
  const dayNum = date.getDate()
  const month = monthNum < 10 ? `0${monthNum}` : monthNum
  const day = dayNum < 10 ? `0${dayNum}` : dayNum

  if (stringify) {
    const monthName = short ? getMonthName(month).slice(0, 3) : getMonthName(month)
    const suffix = useSuffix ? getDaySuffix(+day) : ''

    let val = `${monthName} ${dayNum}`
    switch (order) {
      case 'mdy':
        return `${val} ${year}`
      case 'ym':
        return `${monthName} ${year}`
      case 'dm':
        return `${dayNum}${suffix} ${monthName}`
      case 'dmy':
        return `${dayNum}${suffix} ${monthName} ${year}`

      default:
        return val
    }
  }

  if (order === 'mdy') return `${month}-${day}-${year}`
  if (order === 'ymsd') {
    return date.getFullYear() === new Date().getFullYear()
      ? date.toDateString().slice(0, -5)
      : date.toDateString()
  }
  if (order === 'md') return `${month}-${day}`
  return `${year}-${month}-${day}`
}

export function getTimeText(customDateRange) {
  const { start, end } = customDateRange

  const format = start.getFullYear() === end.getFullYear() ? 'md' : 'mdy'

  const startString = toDateString(customDateRange.start, format, true) || 'Start'
  const endString = toDateString(customDateRange.end, format, true) || 'End'
  return end.getTime() - start.getTime() <= 24 * 60 * 60 * 1000
    ? startString
    : `${startString} to ${endString}`
}

function getDaySuffix(day) {
  if (day > 10 && day < 20) return 'th'
  const lastChar = `${day}`.slice(-1)
  switch (lastChar) {
    case '1':
      return 'st'
    case '2':
      return 'nd'
    case '3':
      return 'rd'

    default:
      return 'th'
  }
}

function getMonthName(i) {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  return monthNames[Number(i) - 1]
}
