<template>
  <div class="FormPageEditor font-normal p-4" style="font-size: 14px;">
    <a-space direction="vertical">
      <template v-if="location === 'options' || !location">
        <template v-if="siblingOptions.length">
          <div class="flex gap-1 mb-2 items-center">
            <div class="flex-auto overflow-hidden -mx-1">
              <div class="text-xs uppercase font-bold text-gray-400 mt-1 ml-1">
                Page
              </div>

              <div class="overflow-hidden flex gap-1 -mt-0.5 p-1">
                <KeyEditor class="text-sm" :page="page" @update="onKeyUpdate" />
              </div>
            </div>
            <a-tooltip title="Duplicate">
              <a-button class="flex-shrink-0" icon="copy" @click="$emit('duplicate')" />
            </a-tooltip>
            <a-popconfirm
              title="Are you sure you want to delete this page?"
              ok-text="Yes"
              cancel-text="No"
              @confirm="$emit('remove')"
            >
              <!-- @click="$emit('remove')" -->
              <a-button class="flex-shrink-0" icon="delete" />
            </a-popconfirm>
            <!-- <a-tooltip title="Delete">
              <a-button class="flex-shrink-0" icon="delete" @click="$emit('remove')" />
            </a-tooltip> -->
            <!-- <a-tooltip title="Go to Page">
              <a-button class="flex-shrink-0" icon="arrow-right" @click="goToPage" />
            </a-tooltip> -->
          </div>
        </template>
        <div
          v-else
          class="flex flex-row items-center border rounded bg-white cursor-pointer py-1 px-3"
          @click="_openModal('new-page')"
        >
          <span class="ml-2">+ Add your first page</span>
        </div>
        <a-alert v-if="isDuplicate" type="error" message="Page key is in use by another page" />
      </template>

      <template v-if="location === 'triggers' || !location">
        <ConversionTrackingEditor label="On Page Reached Conversions" :parent="page" />
        <ConversionTrackingEditor
          label="On Page Complete Conversions"
          _key="on_complete_conversions"
          :parent="page"
        />

        <EditorHeading icon="bullseye-arrow" title="Checkpoints" />
        <template v-if="pageIndex !== 0">
          <CheckpointEditor
            :parent="page"
            :form="form"
            :userData="userData"
            label="On Page Reached Checkpoints"
          />
        </template>
        <CheckpointEditor
          :parent="page"
          :form="form"
          :userData="userData"
          _key="on_complete_checkpoints"
        />

        <EditorHeading icon="share-alt" title="Data Outputs" />
        <DataOutputFireEditor
          _key="outputs_onload"
          :form="form"
          label="Outputs to trigger on page load"
        />
        <DataOutputFireEditor
          _key="outputs_oncomplete"
          :form="form"
          label="Outputs to trigger on page complete"
        />
      </template>

      <template v-if="location === 'options' || !location">
        <!-- <EditorInput _key="key" isKey noLabel placeholder="Page Key (Required)" :component="page" /> -->
        <template v-if="key">
          <EditorHeading icon="cog" title="General" />
          <TagsEditor type="page" />
          <EditorHeading icon="code-branch" title="Conditions" />
          <ConditionsEditor location="page" v-bind="{ conditions, form }" />
        </template>

        <EditorHeading icon="sliders-h" title="Configuration" />
        <EditorCheckbox _key="hide" label="Hide Page" />

        <EditorHeading icon="caret-down" title="More Options" />
        <a-collapse>
          <a-collapse-panel header="Other Options">
            <a-textarea placeholder="Images to Preload" autoSize v-model="preloadImages" />
            <template v-if="key">
              Automatically go to next page after delay
              <a-space direction="horizontal">
                <EditorInput type="number" _key="next_on_timeout" label="Auto Next Delay (ms)" />
                <a-tooltip title="Only while inside the Editor">
                  <a-button
                    v-if="next_on_timeout"
                    :type="_disableDelays() ? 'default' : 'primary'"
                    @click="_toggleDisableDelays"
                  >
                    {{ _disableDelays() ? 'OFF' : 'ON' }}
                  </a-button>
                </a-tooltip>
              </a-space>
              <EditorSelect
                label="When a Page is Incomplete"
                _key="show_next_button_on_incomplete"
                :defaultVal="form && form.show_next_button_on_incomplete"
                :options="incompletePageOptions"
              />
            </template>
          </a-collapse-panel>
        </a-collapse>
        <a-collapse>
          <a-collapse-panel header="Advanced">
            <EditorSelect
              label="Make this Page a Memberstack Form"
              _key="memberstack_form"
              :options="[undefined, 'signup', 'login']"
            />
            <EditorSelect label="Optional - Repeater Key" _key="repeater_key" :options="keys" />
            <EditorSelect
              label="Optional - Default Container"
              _key="default_container"
              :options="containerOptions"
            />
            <a-button
              class="w-full"
              @click="_openDataPath({ path: basePath, title: page && page.key })"
            >
              Edit as raw JSON
              <Icon class="ml-1" name="external-link-alt" />
            </a-button>
          </a-collapse-panel>
        </a-collapse>
        <a-collapse>
          <a-collapse-panel header="Deprecated">
            <EditorCheckbox _key="isFinish" label="Counts as Finish" v-bind="{ page }" />
            <template v-if="key">
              <template v-if="addingHeadingLabel || (page && page.headingLabel)">
                <EditorInput
                  allowComputed
                  _key="headingLabel"
                  label="Heading Label"
                  :component="page"
                />

                <div class="flex items-center flex-wrap gap-4">
                  <EditorCheckbox _key="headingLabelRich" label="Rich Text" />
                </div>
              </template>
              <EditorInput allowComputed _key="title" placeholder="Page Title" :component="page" />

              <div class="flex items-center flex-wrap gap-4">
                <EditorCheckbox _key="titleRich" label="Rich Text" />
              </div>
              <EditorInput
                allowComputed
                _key="subtitle"
                placeholder="Page Subtitle"
                :component="page"
              />
              <div class="flex items-center flex-wrap gap-4">
                <EditorCheckbox _key="subtitleRich" label="Rich Text" />
              </div>

              <div v-if="!(addingHeadingLabel || (page && page.headingLabel))" class="mt-2">
                <a-tooltip
                  :trigger="['hover']"
                  title="Heading labels go above titles, in a smaller font. Like a subtitle but above the title not below!"
                >
                  <a-button size="small" @click="addingHeadingLabel = true"
                    >+ Add a heading label</a-button
                  >
                </a-tooltip>
              </div>
              <EditorInput _key="page_label" placeholder="Page Label" />

              <!-- <a-collapse
              v-for="(component, index) in components"
              :key="component.id"
              :activeKey="openComponents"
              @change="setOpenComponents"
            >
              <a-collapse-panel
                :header="
                  `${component.key}${component.hide ? ' (Hidden)' : ''}` || 'Unkeyed Component'
                "
                :key="component.id"
              >
                <FormComponentEditor
                  :componentId="component.id"
                  :componentIndex="index"
                  :grid="currentGrid[index]"
                  v-bind="{ component, form, page }"
                  @update="onComponentUpdate(index, $event)"
                  @duplicate="duplicateComponent(index)"
                  @remove="removeComponent(index)"
                />
              </a-collapse-panel>
            </a-collapse> -->
            </template>
            <template v-if="location === 'options' || !location">
              <a-collapse style="margin-top: 20px">
                <a-collapse-panel header="Other Options">
                  <a-checkbox
                    :defaultChecked="Boolean(headingLabelBlock || headingLabelColumn)"
                    @change="
                      debounceUpdate(['headingLabelBlock', $event.target.checked ? 1 : null])
                    "
                    >Put Heading Label
                    {{
                      headingLabelBlock || headingLabelColumn
                        ? `In Block ${headingLabelBlock || headingLabelColumn}`
                        : 'Among Components'
                    }}
                  </a-checkbox>
                  <div class="flex items-center">
                    <template v-if="Boolean(headingLabelBlock || headingLabelColumn)">
                      <a-slider
                        style="flex: 1; margin-left: 10px;"
                        :default-value="headingLabelBlock || headingLabelColumn || 1"
                        :min="1"
                        :max="8"
                        @change="debounceUpdate(['headingLabelBlock', $event])"
                      />
                    </template>
                  </div>
                  <a-checkbox
                    :defaultChecked="Boolean(titleBlock || titleColumn)"
                    @change="$emit('update', ['titleBlock', $event.target.checked ? 1 : null])"
                    >Put Title
                    {{
                      titleBlock || titleColumn
                        ? `In Block ${titleBlock || titleColumn}`
                        : 'Among Components'
                    }}
                  </a-checkbox>
                  <div class="flex items-center">
                    <template v-if="Boolean(titleBlock || titleColumn)">
                      <a-slider
                        style="flex: 1; margin-left: 10px;"
                        :default-value="titleBlock || titleColumn || 1"
                        :min="1"
                        :max="8"
                        @change="debounceUpdate(['titleBlock', $event])"
                      />
                    </template>
                  </div>
                  <a-checkbox
                    :defaultChecked="Boolean(subtitleBlock || subtitleColumn)"
                    @change="debounceUpdate(['subtitleBlock', $event.target.checked ? 1 : null])"
                    >Put Subtitle
                    {{
                      subtitleBlock || subtitleColumn
                        ? `In Block ${subtitleBlock || subtitleColumn}`
                        : 'Among Components'
                    }}
                  </a-checkbox>
                  <div class="flex items-center">
                    <template v-if="Boolean(subtitleBlock || subtitleColumn)">
                      <a-slider
                        style="flex: 1; margin-left: 10px;"
                        :default-value="subtitleBlock || subtitleColumn || 1"
                        :min="1"
                        :max="8"
                        @change="debounceUpdate(['subtitleBlock', $event])"
                      />
                    </template>
                  </div>
                  <a-space>
                    <EditorCheckbox
                      :defaultTrue="pageIndex !== 0"
                      _key="showPrev"
                      label="Show Prev"
                      v-bind="{ page }"
                    />
                    <EditorCheckbox
                      defaultTrue
                      _key="showNext"
                      label="Show Next"
                      v-bind="{ page }"
                    />
                  </a-space>
                  <a-space>
                    Use Page Nav Text <a-switch v-model="usePageNavText" size="small" />
                  </a-space>
                  <a-space v-if="showNav">
                    <EditorInput
                      :global="!usePageNavText"
                      _key="prevText"
                      placeholder="Prev Text"
                      :component="page"
                    />
                    <EditorInput
                      :global="!usePageNavText"
                      _key="nextText"
                      placeholder="Next Text"
                      :component="page"
                    />
                  </a-space>
                  <EditorInput
                    v-if="page && pageIndex === 0 && page.showPrev"
                    _key="backUrl"
                    label="Url to go back to"
                  />
                  <EditorSelect
                    v-if="page && page.showPrev !== false"
                    _key="prevIcon"
                    :options="arrowOptions.prev"
                  />
                  <EditorSelect
                    v-if="page && page.showNext !== false"
                    _key="nextIcon"
                    :options="arrowOptions.next"
                  />
                  <EditorCheckbox _key="use_custom_next" label="Use Custom Next Button" />
                </a-collapse-panel>
                <a-collapse-panel header="Custom Next Button" v-if="page && page.use_custom_next">
                  <CustomButtonEditor
                    :component="(page && page.custom_next_button) || {}"
                    :componentId="`${page && page.id}_custom_next_button`"
                    :form="form"
                    path="custom_next_button"
                  />
                </a-collapse-panel>
              </a-collapse>
            </template>
          </a-collapse-panel>
        </a-collapse>
      </template>

      <template v-if="location === 'design-footer' || !location">
        <EditorHeading icon="caret-down" title="Other Design Options" />
        <template v-if="key">
          <div class="my-1"></div>
          <a-collapse>
            <a-collapse-panel header="Transitions">
              <TransitionEditor
                :form="form"
                label="Title Transition"
                name="title"
                style="width: 100%;"
              />
              <TransitionEditor
                :form="form"
                label="Content Transition"
                name="content"
                style="width: 100%;"
              />
              <TransitionEditor
                :form="form"
                label="Footer Transition"
                name="footer"
                style="width: 100%;"
              />
            </a-collapse-panel>
          </a-collapse>
          <div class="my-6"></div>
          <a-space v-if="useGrid" align="center">
            <a-switch size="small" @change="_updateDraggable($event)" /> Arrange page
          </a-space>
        </template>
      </template>
      <!-- 
      <template v-if="location === 'design-footer' || !location">
        <a-collapse>
          <a-collapse-panel header="Advanced">
            <EditorCheckbox _key="isFlush" label="Flush to Edges" v-bind="{ page }" />
            <a-select
              placeholder="Select Page Layout"
              :default-value="layout || undefined"
              style="width: 100%;"
              option-label-prop="value"
              @change="debounceUpdate(['layout', $event])"
            >
              <a-select-option v-for="l in layouts" :key="l" :value="l">
                <LayoutPreview v-if="l" :layout="l" />
                <template v-else>None / Columns (Default)</template>
              </a-select-option>
            </a-select>
            <template v-if="layout">
              <LayoutPreview v-bind="{ layout }" />
              <div v-for="(block, index) in layout.split(',')" :key="block">
                <a-space align="center">
                  <span style="white-space: nowrap;">{{ index + 1 }}:</span>
                  <div class="flex items-center">
                    <a-checkable-tag
                      v-model="((layoutBlocks || [])[index] || {}).horizontal"
                      @change="updateLayoutBlocks(index, 'horizontal', $event)"
                    >
                      H
                    </a-checkable-tag>
                    <a-checkable-tag
                      v-model="((layoutBlocks || [])[index] || {}).stretch"
                      @change="updateLayoutBlocks(index, 'stretch', $event)"
                    >
                      Str
                    </a-checkable-tag>
                    <a-select
                      placeholder="Align"
                      :default-value="((layoutBlocks || [])[index] || {}).align"
                      style="width: 80px;"
                      @change="updateLayoutBlocks(index, 'align', $event)"
                    >
                      <a-select-option value="center">
                        Center (Default)
                      </a-select-option>
                      <a-select-option value="flex-start">
                        Start
                      </a-select-option>
                      <a-select-option value="flex-end">
                        End
                      </a-select-option>
                    </a-select>
                  </div>
                  <EditorInput
                    _key="spacing"
                    style="width: 60px;"
                    :component="(layoutBlocks || [])[index] || {}"
                    @update="updateLayoutBlocks(index, ...$event)"
                  />
                </a-space>
                <a-space>
                  <TagsEditor type="block" :path="`layoutBlocks.${index}`" />
                  <EditorInput
                    isKey
                    style="width: 120px;"
                    size="small"
                    :path="`layoutBlocks.${index}.tab_group`"
                  />
                </a-space>
              </div>
            </template>
          </a-collapse-panel>
        </a-collapse>
      </template> -->

      <template v-if="location === 'options'">
        <EditorHeading class="mb-0" icon="cube" title="Components" />
        <Draggable
          v-model="components"
          handle=".handle"
          class="border border-gray-200 rounded-md overflow-hidden"
        >
          <a-dropdown
            :trigger="['contextmenu']"
            v-for="(component, index) in components"
            :key="component.id"
          >
            <a-menu slot="overlay">
              <a-sub-menu
                key="move-component"
                title="Move Component "
                @click="_doAction('move-component-to-page', component, $event.key)"
              >
                <a-menu-item v-for="type in siblingOptions" :key="type.id" :value="type.id">
                  {{ type.label }} {{ type.hidden ? '(Hidden)' : '' }}
                </a-menu-item>
              </a-sub-menu>
            </a-menu>
            <ComponentLink
              :class="{
                'in-container': component.parent_key && containers.has(component.parent_key),
              }"
              :id="component.id"
              :hidden="hiddenComponents.has(component.id)"
            >
              <div class="flex-grow">
                <span class="font-mono text-xs">{{
                  component.key || `Untitled Component ${index + 1}`
                }}</span>
                {{ hiddenComponents.has(component.id) ? ' (Hidden)' : '' }}
                {{
                  component.parent_key && containers.has(component.parent_key)
                    ? ' (In Container)'
                    : ''
                }}
              </div>
              <div class="handle cursor-move hover:text-savvy">
                <Icon name="bars" />
              </div>
            </ComponentLink>
          </a-dropdown>
        </Draggable>
        <a-button type="primary" icon="plus" class="w-full" @click="_openModal('new-component')"
          >New Component</a-button
        >
        <EditorSelect _key="page_role" :options="pageRoles" />
      </template>
    </a-space>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import debounce from 'lodash/debounce'

import { unpack } from '@/helpers/computed'
import pageLayouts from './helpers/pageLayouts'
import facebookPixelEvents from './helpers/facebookPixelEvents'
import { getIncompletePageOptions } from './helpers/constants'
import { passesConditions } from '@/components/form/helpers/conditions'

import Draggable from 'vuedraggable'

// import FormComponentEditor from './FormComponentEditor'
import ConditionsEditor from './ConditionsEditor'
import TransitionEditor from './TransitionEditor'
// import LayoutPreview from './LayoutPreview'
import TagsEditor from './TagsEditor'
import ConversionTrackingEditor from './ConversionTrackingEditor.vue'
import CheckpointEditor from './CheckpointEditor.vue'
import DataOutputFireEditor from './DataOutputFireEditor.vue'
import CustomButtonEditor from './CustomButtonEditor.vue'
import KeyEditor from './mainEditor/toolbar/KeyEditor'
import { snakeCase } from 'lodash'
import { getPageRoles } from './helpers/flowTemplates'

export default {
  name: 'FormPageEditor',
  components: {
    Draggable,
    // FormComponentEditor,
    ConditionsEditor,
    TransitionEditor,
    // LayoutPreview,
    TagsEditor,
    ConversionTrackingEditor,
    CheckpointEditor,
    CustomButtonEditor,
    DataOutputFireEditor,
    KeyEditor,
  },
  inject: [
    '_updateData',
    '_updateDraggable',
    '_getCurrentGrid',
    '_toggleDisableDelays',
    '_disableDelays',
    '_getOptions',
    '_openDataPath',
    '_onSelectPage',
    '_onSelectComponent',
    '_getSiblingOptions',
    '_openModal',
    '_doAction',
    '_getFlowPages',
    '_updateReactive',
  ],
  provide() {
    return {
      _getBasePath: () => this.basePath,
    }
  },
  props: {
    form: Object,
    page: Object,
    pageId: String,
    pageIndex: Number,
    isDuplicate: Boolean,
    userData: Object,
    location: String,
  },
  data() {
    return {
      openComponents: [],
      editingKey: false,
      addingHeadingLabel: false,
    }
  },
  computed: {
    ...unpack('page', [
      'key',
      'conditions',
      'headingLabelBlock',
      'titleBlock',
      'subtitleBlock',
      'headingLabelColumn',
      'titleColumn',
      'subtitleColumn',
      'isFinish',
      'showNav',
      'showPrev',
      'showNext',
      'isFlush',
      'layout',
      'layoutBlocks',
      'useGrid',
      'next_on_timeout',
    ]),
    usePageNavText: {
      get() {
        if (!this.page) return true
        return this.page.usePageNavText === undefined ? true : this.page.usePageNavText
      },
      set(v) {
        this._updateData(this.basePath, 'usePageNavText', v)
      },
    },
    incompletePageOptions() {
      return getIncompletePageOptions()
    },
    siblingOptions() {
      return this._getSiblingOptions()
    },
    keys() {
      const keys = Array.from(new Set(this._getOptions().map(o => o.key)))
      return [undefined, ...keys]
    },
    basePath() {
      return `pages.${this.pageIndex}`
    },
    hasPrev() {
      return this.currentPageIndex > 0
    },
    hasNext() {
      return this.currentPageIndex < this._getFlowPages().length - 1
    },
    currentGrid() {
      return this._getCurrentGrid() || {}
    },
    components: {
      get() {
        return this.page && this.page.components
      },
      set(components) {
        this._updateData(this.basePath, 'components', components)
      },
    },
    hiddenComponents() {
      return new Set(
        this.components
          .filter(item => {
            if (item.hide) return true
            return !passesConditions(item.conditions || [], this.userData, this.components, true)
          })
          .map(c => c.id)
      )
    },
    containers() {
      const components = this.components || []
      return new Set(components.filter(c => c.type === 'Container').map(c => c.key))
    },
    containerOptions() {
      const components = this.components || []
      return [undefined, ...components.filter(c => c.type === 'Container').map(c => c.key)]
    },
    preloadImages: {
      get() {
        return (this.page && this.page.preloadImages && this.page.preloadImages.join('\n')) || ''
      },
      set(v) {
        this._updateData(
          this.basePath,
          'preloadImages',
          v.split('\n').map(t => (t && t.trim()) || '')
        )
      },
    },
    arrowOptions() {
      const prev = [
        undefined,
        { key: 'fa-solid:chevron-left', label: 'Font Awesome: Chevron' },
        { key: 'fa-solid:arrow-left', label: 'Font Awesome: Arrow' },
        { key: 'feather:chevron-left', label: 'Feather: Chevron' },
        { key: 'feather:arrow-left', label: 'Feather: Arrow' },
      ]
      const next = [
        undefined,
        { key: 'fa-solid:chevron-right', label: 'Font Awesome: Chevron' },
        { key: 'fa-solid:arrow-right', label: 'Font Awesome: Arrow' },
        { key: 'feather:chevron-right', label: 'Feather: Chevron' },
        { key: 'feather:arrow-right', label: 'Feather: Arrow' },
      ]
      return { next, prev }
    },
    debounceUpdate() {
      return debounce(update => this._updateData(this.basePath, ...update), 200, {
        trailing: true,
        leading: false,
      })
    },
    facebookPixelEvents() {
      return [undefined].concat(facebookPixelEvents())
    },
    layouts() {
      return pageLayouts()
    },
    pageRoles() {
      return getPageRoles()
    },
  },
  methods: {
    onKeyUpdate([key, val]) {
      const newVal = typeof val === 'string' ? snakeCase(val.trim()) : val
      this._updateReactive(this.basePath, key, newVal)
    },
    onComponentUpdate(index, [key, value]) {
      // const components = cloneDeep(this.page.components)

      // components[index][key] = value
      this._updateData(`${this.basePath}.components.${index}`, key, value)
      // this.$emit('update', ['components', components])
    },
    goToPage() {
      this.$emit('jump', { ...this.userData, currentPageId: this.pageId })
    },
    updateLayoutBlocks(index, key, value) {
      const layoutBlocks = cloneDeep(this.layoutBlocks || [])

      if (!layoutBlocks[index]) layoutBlocks[index] = {}

      layoutBlocks[index][key] = value
      this._updateData(`${this.basePath}`, 'layoutBlocks', layoutBlocks)
    },
  },
}
</script>
