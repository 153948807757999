<template>
  <Icon v-if="isFallingBack && fallbackIcon" :name="fallbackIcon" />
  <img
    v-else
    ref="image"
    :src="isFallingBack ? fallback : image"
    @load="onLoad"
    @error="useFallback"
  />
</template>
<script>
export default {
  props: {
    src: String,
    srcs: Array,
    fallback: String,
    fallbackIcon: String,
  },
  data() {
    return {
      // image: '',
      retryCount: 0,
      isFallingBack: false,
    }
  },
  computed: {
    image() {
      return this.src || (this.srcs && this.srcs[this.retryCount]) || this.fallback
    },
  },
  // mounted() {
  //   this.image = this.src || (this.srcs && this.srcs[this.retryCount]) || this.fallback
  // },
  methods: {
    onLoad() {
      // if (this.retryCount < (this.srcs ? this.srcs.length + 1 : 2)) {
      //   this.image = this.src || (this.srcs && this.srcs[this.retryCount]) || this.fallback
      // }
    },
    useFallback() {
      const self = this

      this.$nextTick(() => {
        this.retryCount += 1

        setTimeout(() => {
          if (this.retryCount >= (this.srcs ? this.srcs.length - 1 : 1)) {
            self.isFallingBack = true
          } else {
            // this.image = this.src || (this.srcs && this.srcs[this.retryCount]) || this.fallback
          }
        }, 500)
      })
    },
  },
}
</script>
<style lang="scss" scoped></style>
