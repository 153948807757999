const prodEnv = getProdEnv()

export function getEnv(key) {
  const prodSuffix = getProdSuffix(prodEnv)
  const baseKey = `VUE_APP_${key}`

  const envVar = process.env[`${baseKey}${prodSuffix}`]

  if (prodSuffix && !envVar) console.warn(`${baseKey}${prodSuffix} not found`)

  const fallback = process.env[baseKey]

  return envVar || fallback
}

function getProdEnv() {
  switch (window.location.host) {
    case 'app.trysavvy.com':
      return 'prod'
    case 'app.heysavvy.com':
      return 'prod-old'
    case 'flows.heysavvy.com':
      return 'flows'
    default:
      return null
  }
}

function getProdSuffix(prodEnv) {
  switch (prodEnv) {
    case 'prod':
      return '__PROD'
    case 'prod-old':
      return '__PROD_OLD'
    case 'flows':
      return '__FLOWS'
    default:
      return ''
  }
}
