<template>
  <div
    class="Pill py-0.5 text-xs rounded-full bg-white border-gray-200 items-center group"
    :class="[
      clickableness,
      {
        selected,
        border: !borderless,
        'px-2': !borderless,
        block,
        flex: !block,
        'm-1': !flush,
        'm-0.5': tight,
        'bg-purple': selected,
        'text-white': selected,
        'border-purple': selected,
        'whitespace-nowrap': !allowMultipleLines,
        filled: color && filled,
        hoverFilled: color && hoverFilled,
      },
    ]"
    :style="{
      '--color': color,
      '--border-color': color,
      '--filled-color': filledColor,
      'border-style': dashed && 'dashed',
      'border-width': dashed && '1.5px',
    }"
    @click.stop="$emit('click', $event)"
  >
    <Icon v-if="icon" :name="icon" class="mr-1 mb-px" />
    <slot></slot>
    <Icon
      v-if="closeable || closeableHover"
      class="ml-1 cursor-pointer group-hover:block"
      :class="{
        hidden: closeableHover,
        'hover:text-purple': !clickable,
        'hover:opacity-70': clickable,
      }"
      name="times"
      @click.stop.native="$emit('close')"
    />
  </div>
</template>

<script>
export default {
  props: {
    clickable: Boolean,
    dashed: Boolean,
    tight: Boolean,
    flush: Boolean,
    closeable: Boolean,
    closeableHover: Boolean,
    selected: Boolean,
    allowMultipleLines: Boolean,
    block: Boolean,
    icon: String,
    color: String,
    filledColor: String,
    filled: Boolean,
    hoverFilled: Boolean,
    borderless: Boolean,
  },
  computed: {
    clickableness() {
      return this.clickable
        ? `cursor-pointer ${this.selected ? 'hover:opacity-80' : 'hover:bg-gray-50'}`
        : null
    },
  },
}
</script>

<style lang="scss" scoped>
.Pill {
  // --filled-color: #4b5563; // .text-gray-600
  --border-color: #e5e7eb; // .text-gray-200
  border-color: var(--border-color);
  color: var(--color);

  &.selected {
    --color: white;
    // --border-color: var(--color-savvy);
    border-color: var(--filled-color, var(--color-savvy));
    background-color: var(--filled-color, var(--color-savvy));
  }

  &:hover .Icon {
    display: block;
  }
  &.filled,
  &.hoverFilled:hover {
    border-color: var(--filled-color, var(--color));
    background-color: var(--filled-color, var(--color));
    color: white;
  }
}
</style>
