var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"root",staticClass:"NavPagePane m-2 mx-4 relative rounded border-gray-200 overflow-hidden cursor-pointer transform hover:border-gray-400 hover:scale-105 transition-all group",class:{
    'border-purple': _vm.userData.currentPageId === _vm.value.slice(2),
    'hover:border-purple': _vm.userData.currentPageId === _vm.value.slice(2),
    'border-2': _vm.selectedKeys.includes(_vm.value),
    border: !_vm.selectedKeys.includes(_vm.value),
  },staticStyle:{"padding-bottom":"50%"},attrs:{"id":_vm.value},on:{"click":_vm.onClick}},[(_vm.url)?[(_vm.backupUrl && _vm.backupUrl !== _vm.url && _vm.showImage && _vm.showBackupUrl)?_c('img',{staticClass:"absolute inset w-full hover:opacity-100",class:{ 'opacity-50': _vm.pageIsHidden },attrs:{"src":_vm.backupUrl,"alt":("Page " + (_vm.index + 1))}}):_vm._e(),(_vm.showImage)?_c('img',{staticClass:"absolute inset w-full hover:opacity-100",class:{ 'opacity-50': _vm.pageIsHidden },attrs:{"src":_vm.url,"alt":("Page " + (_vm.index + 1))},on:{"load":function($event){_vm.showBackupUrl = false}}}):_vm._e()]:_c('Icon',{staticClass:"absolute inset w-full hover:opacity-100 text-xl opacity-50",staticStyle:{"left":"45%","top":"40%"},attrs:{"name":"edit"}}),_c('div',{staticClass:"handle absolute top-0 left-0 bottom-0 bg-white border-r border-gray-300 flex flex-col justify-center p-1 opacity-0 cursor-move group-hover:opacity-100 hover:bg-gray-50 transition-all"},[_c('Icon',{staticClass:"text-sm",attrs:{"name":"grip-horizontal","type":"fas"}})],1),(_vm.pageIsHidden)?_c('Icon',{staticClass:"absolute top-2 right-2 text-gray-600 transform group-hover:scale-110",class:{
      'text-purple': _vm.userData.currentPageId === _vm.value.slice(2),
    },attrs:{"name":"eye-slash"}}):_vm._e(),(_vm.numComments)?_c('div',{staticClass:"absolute bottom-2 right-2 bg-yellow-500 px-2 py-0.5 rounded-full text-xs font-bold text-white"},[_vm._v(" "+_vm._s(_vm.numComments)+" "),_c('Icon',{staticClass:"ml-0.5",staticStyle:{"font-size":"0.65rem"},attrs:{"name":"comment"}})],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }