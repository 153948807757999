<template>
  <div class="CustomButtonEditor">
    <a-space direction="vertical">
      <template v-if="location === 'basic_options'">
        <EditorCheckbox _key="toggle" label="Is Toggle Button" />
      </template>

      <template v-if="location === 'content'">
        <EditorHeading icon="paragraph" title="Content" />
        <div>
          <EditorIcon _key="icon" :component="component" />
          Select an Icon
        </div>
        <EditorInput
          allowComputed
          placeholder="Button Emoji Icon"
          _key="emojiIcon"
          v-bind="{ component }"
        />
        <EditorInput
          allowComputed
          _key="imageUrl"
          placeholder="Custom Image Url"
          v-bind="{ component }"
        />
        <EditorInput allowComputed _key="text" placeholder="Text" v-bind="{ component }" />
        <EditorCheckbox _key="richText" label="Title: Rich Text" />
        <EditorInput
          allowComputed
          _key="description"
          placeholder="Description"
          v-bind="{ component }"
        />
        <EditorCheckbox _key="richDescription" label="Description: Rich Text" />
      </template>
      <template v-if="location === 'options' || !location">
        <EditorCheckbox v-if="form.form_wrapper" _key="submit_form" label="Submit as Form" />
        <EditorCheckbox
          _key="needs_validation_passed"
          label="Disable Button if page requirements not met"
        />
        <EditorSelect
          _key="validation_show_state"
          label="Disabled State Behavior"
          :options="validCustomButtonShowOptions"
        />
        <!-- <EditorCheckbox _key="isFinish" label="Counts as Finish" /> -->

        <EditorHeading icon="location" title="Button Actions" />
        <div class="flex items-center">
          <EditorLabel label="Select an Action" style="margin:0;" />
          <a-popover placement="topRight">
            <Icon class="ml-2" name="info-circle" />
            <div slot="content">
              <PageRequirementsViewer v-bind="{ page, form, userData }">
                This action cannot be performed if there are any invalid components.
              </PageRequirementsViewer>
            </div>
          </a-popover>
        </div>
        <EditorSelect noLabel _key="action" :options="actionOptions" />
        <template v-if="action === 'open-url'">
          <EditorInput
            allowComputed
            _key="actionUrl"
            placeholder="URL to Open"
            v-bind="{ component }"
          />

          <EditorCheckbox _key="openUrlInNewTab" label="Open URL in New Tab" />
          <EditorCheckbox _key="shouldDownloadUrl" label="Download URL as a File" />
          <template v-if="shouldDownloadUrl">
            <EditorInput
              allowComputed
              _key="downloadFilename"
              label="Filename of Download"
              placeholder="My File.pdf"
              v-bind="{ component }"
            />

            <a-alert
              banner
              type="info"
              message="For this to work, the download file must be hosted on the same domain as the website containing the Flow."
            />
          </template>
        </template>
        <template v-else-if="['next-page', 'prev-page'].includes(action)">
          <EditorCheckbox _key="hide_if_no_nav_target" label="Hide button if no nav target" />
        </template>
        <template v-else-if="pageActionKeys.includes(action)">
          <EditorSelect
            _key="disable_if_unreachable"
            label="Disable if"
            :options="pageUnreachableOptions"
          />
        </template>
        <template v-else-if="action === 'open-info-box'">
          <EditorInput
            allowComputed
            _key="info_box_key"
            label="Key of Info Box to open"
            v-bind="{ component }"
          />
        </template>
      </template>
      <template v-if="location === 'options-advanced'">
        <EditorCheckbox _key="use_custom_code" label="Run Custom Code on click" />
        <template v-if="component.use_custom_code">
          <label>
            Must contain a function called 'customHandler'. Function receives userData as the first
            argument.
          </label>
          <EditorCode _key="custom_code" v-bind="{ codeDraft }" />
          <a-button size="small" @click="generateSampleCode">Generate sample code</a-button>
          <EditorInput _key="output_key" label="Key to output data to" v-bind="{ component }" />
          <EditorInput
            allowComputed
            _key="custom_code_loading_text"
            placeholder="Loading..."
            v-bind="{ component }"
          />
          <EditorInput
            allowComputed
            _key="custom_code_success_text"
            placeholder="Success!"
            v-bind="{ component }"
          />
          <EditorInput
            allowComputed
            _key="custom_code_fail_text"
            placeholder="Failed / Submit Again"
            v-bind="{ component }"
          />
        </template>
      </template>
      <template v-if="location === 'triggers'">
        <ConversionTrackingEditor :parent="component" />
        <CheckpointEditor :parent="component" :form="form" />
        <DataOutputFireEditor
          _key="outputs_onclick"
          :form="form"
          label="Outputs to trigger on button click"
        />
      </template>
    </a-space>
  </div>
</template>
<script>
import { unpack } from '@/helpers/computed'
import facebookPixelEvents from './helpers/facebookPixelEvents'
import ConversionTrackingEditor from './ConversionTrackingEditor.vue'
import CheckpointEditor from './CheckpointEditor.vue'
import DataOutputFireEditor from './DataOutputFireEditor.vue'
import { getValidCustomButtonShowOptions } from './helpers/constants'
import PageRequirementsViewer from './PageRequirementViewer.vue'

export default {
  components: {
    ConversionTrackingEditor,
    CheckpointEditor,
    DataOutputFireEditor,
    PageRequirementsViewer,
  },
  provide() {
    return {
      _getBasePath: () => (this.path ? `${this._getBasePath()}.${this.path}` : this._getBasePath()),
    }
  },
  inject: ['_getBasePath', '_getFlowPages', '_setSelectedComponentId'],
  props: {
    componentId: String,
    component: Object,
    form: Object,
    userData: Object,
    path: String,
    location: String,
    page: Object,
  },
  data() {
    return {
      codeDraft: '',
    }
  },
  computed: {
    ...unpack('component', ['action', 'shouldDownloadUrl']),
    validCustomButtonShowOptions() {
      return getValidCustomButtonShowOptions()
    },
    pageActions() {
      return this._getFlowPages().map(({ key, id }) => ({
        key: `page-${id}`,
        label: `Page: ${key}`,
      }))
    },
    pageActionKeys() {
      return this.pageActions.map(p => p.key)
    },
    actionOptions() {
      return [
        'no-action',
        'open-url',
        'next-page',
        'open-info-box',
        'prev-page',
        'close-popup',
        'reset',
        ...this.pageActions,
      ]
    },
    pageUnreachableOptions() {
      return [undefined, 'page-hidden', 'page-in-front', 'page-not-reached']
    },
    facebookPixelEvents() {
      return [undefined].concat(facebookPixelEvents())
    },
  },
  watch: {},
  methods: {
    generateSampleCode() {
      this.codeDraft = `function customHandler(userData) {\n  return new Promise((resolve, reject) => {\n    resolve({ success: true, value: true })\n  }) \n}`
    },
  },
}
</script>
