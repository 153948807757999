<template>
  <div class="FeatureFlag">
    <input type="checkbox" v-model="flag" />
  </div>
</template>

<script>
import get from 'lodash/get'
import Firebase from 'firebase/app'
import 'firebase/firestore'

export default {
  name: 'FeatureFlag',
  props: {
    user: Object,
    feature: String,
  },
  computed: {
    flag: {
      get() {
        return get(this.user, `flags.${this.feature}`)
      },
      set(val) {
        Firebase.firestore()
          .collection('users')
          .doc(this.user.id)
          .update({
            [`flags.${this.feature}`]: val,
          })
      },
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/_variables.scss';
.FeatureFlag {
}
</style>
