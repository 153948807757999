<template>
  <div class="FlowExperiments flex flex-col items-stretch flex-1 overflow-hidden p-4">
    <FlowExperiment
      v-for="(experiment, index) in experiments"
      :key="experiment.id"
      v-bind="{ index, experiment, form, userData }"
      @delete="deleteExperiment(index)"
    />
    <a-button class="my-2" type="primary" @click="createExperiment"
      ><Icon class="mr-1" name="plus" /> New Experiment</a-button
    >
  </div>
</template>

<script>
import Firebase from 'firebase/app'
import 'firebase/firestore'

import FlowExperiment from './FlowExperiment'

export default {
  name: 'FlowExperiments',
  components: {
    FlowExperiment,
  },
  inject: ['_updateData'],
  props: {
    id: String,
    form: Object,
    userData: Object,
  },
  computed: {
    experiments() {
      return (this.form && this.form.experiments) || []
    },
  },
  methods: {
    createExperiment() {
      console.log('createExperiment')
      this._updateData('', 'experiments', [
        ...this.experiments,
        {
          id: id(),
          status: 'draft',
          variants: [
            {
              id: id(),
              weighting: 100,
            },
          ],
        },
      ])
    },
    deleteExperiment(index) {
      console.log('index')
      this._updateData(
        '',
        'experiments',
        this.experiments.filter((e, i) => i !== index)
      )
    },
  },
}

function id() {
  return Firebase.firestore()
    .collection('_')
    .doc().id
}
</script>
