<template>
  <Card hidden :small="!goalCard" class="InsightCard flex flex-col" :class="{ goal: goalCard }">
    <Entity v-if="id && !isFixedConfig" :collection="collection" v-bind="{ id }" v-model="config" />
    <Entity
      v-if="useVisitorCount"
      :collection="`${collection}/${id}/cache`"
      id="visitorCount"
      v-model="cardCache"
      @loading="onLoaded($event, 'loadingCardCache')"
    />
    <Entity
      v-if="useCache && service"
      :collection="`/groups/${activeGroupId}/cached-data/`"
      :id="cachedDataId"
      v-model="cachedData"
      @loading="onLoaded($event, 'loadingCache')"
    />
    <Annotation :id="id || preset" />
    <div class="flex items-center justify-between relative mb-2">
      <div
        class="flex items-center"
        :style="{ opacity: isFlush ? 0 : 1, 'pointer-events': isFlush ? 'none' : 'all' }"
      >
        <div style="font-size: 16px;" :class="{ 'text-2xl': goalCard }">
          <a-icon :type="icon" />{{ name }}
        </div>
        <a-tooltip v-if="includeTest">
          <template slot="title">
            This card is showing test contacts as well as real ones
          </template>
          <Icon name="vial" class="test-data mx-2" :class="{ active: includeTest }" />
        </a-tooltip>
        <a-tooltip>
          <template slot="title">
            {{ loadingData === 'update' ? 'Updating your data...' : `Refreshed ${lastUpdatedAt}` }}
          </template>
          <div class="reload-card" :class="{ clickable: !loadingData }" @click="reloadCard">
            <a-icon type="sync" :spin="loadingData === 'update'"></a-icon>
          </div>
        </a-tooltip>
        <a-tooltip v-if="currentSourceIsConnected === 'not-connected'">
          <template slot="title">
            A connection to {{ serviceName }} is required to update your insights
          </template>
          <div @click="connectToService" style="cursor: pointer; margin-left: 5px;">
            <a-icon type="exclamation-circle" style="color: orange;"></a-icon>
          </div>
        </a-tooltip>
        <a-tooltip v-else-if="alertDetails">
          <template slot="title">
            {{
              alertDetails.message
                ? alertDetails.message + ' (Right Click to copy to Clipboard)'
                : 'Something went wrong! Right Click to copy an error summary for us!'
            }}
          </template>
          <div
            @click.exact="closeAlert(true)"
            @click.right="closeAlert"
            style="cursor: pointer; margin-left: 5px;"
          >
            <a-icon type="warning" style="color: red;"></a-icon>
          </div>
        </a-tooltip>
      </div>
      <div class="edit-markdown" v-if="isAdmin && service === 'markdown'">
        <a-switch size="small" v-model="editingMarkdown" />
        {{ editingMarkdown ? 'Editing' : 'Edit' }}
      </div>
      <div class="flex flex-row items-center">
        <a-popover v-if="isAdmin" v-model="debugDisplay" placement="bottomRight" trigger="click">
          <template slot="title">
            Card Details
          </template>
          <template slot="content">
            <InsightCardInfo
              :config="config"
              :filters="filters"
              :cacheId="cachedDataId"
              :cacheData="cachedData"
            />
          </template>
          <div class="info-hover-button cursor-pointer mr-5">
            <Icon name="search" />
          </div>
        </a-popover>
        <InsightCardConfig
          v-if="id && isAdmin"
          v-bind="{ id }"
          :savedConfig="config"
          @remove-card="$emit('remove-card', $event)"
        />
      </div>
    </div>
    <!-- <a-alert v-if="alertDetails" v-bind="alertDetails" @close="closeAlert" /> -->
    <Spinner v-if="loadingCache || loadingData === 'new'" />
    <template v-else-if="cardData && !loadingCache">
      <!-- style="height: 357px;" -->
      <ClickToScroll
        class="CardBody flex-1"
        :style="{
          'overflow-y': isFlush ? 'visible' : undefined,
          'margin-bottom': chartType === 'savvy-bar' ? '-16px' : '0',
        }"
        :off="chartType !== 'savvy-bar'"
        ref="cardContent"
      >
        <template v-if="goalCard">
          <RichTextEditor
            v-if="isAdmin"
            :component="{ text: config && config.goalDescription }"
            @update="setConfigProp('goalDescription', $event[1])"
          />
          <div v-else v-html="(config && config.goalDescription) || ''" />
        </template>
        <div v-if="service === 'text'">
          <EditableText v-bind="{ id, collection }" textKey="body" />
        </div>
        <div v-else-if="service === 'markdown'">
          <textarea v-if="editingMarkdown" style="width: 100%; height: 100%;" v-model="markdownRaw">
          </textarea>
          <div v-else v-html="compiledMarkdown"></div>
        </div>
        <FlowEventsTimeline v-else-if="service === 'timeline'" :tags="tags" />
        <FlowThumbnail v-else-if="service === 'flow'" :config="config" />
        <ComparisonChart
          v-else-if="service === 'comparison'"
          :config="config"
          :collection="collection"
          @loading="loadingData"
        />
        <SavvyFunnelCard
          v-else-if="chartType === 'funnel'"
          :data="cardData"
          :rawData="cachedData && cachedData.rawData"
          v-bind="{
            id,
            preset,
            colors,
            icon,
            config,
            funnelId: config && config.funnelId,
          }"
        />
        <TripleInsights
          v-else-if="service === 'savvy' && preset === 'top-line'"
          :config="config"
          :requestReload="requestReload"
          @reloaded="onReloaded"
        />
        <template v-else-if="cardData && cardData.labels">
          <SavvyLineChart
            v-if="chartType === 'line'"
            :data="cardData"
            :totalCount="visitorCount"
            v-bind="{ prefix, useVisitorCount }"
          />
          <!-- <BarChart  v-else-if="chartType === 'bar'" :chartData="cardData" :options="chartOptions" /> -->
          <SavvyBarChart
            v-else-if="chartType === 'savvy-bar'"
            :data="cardData"
            v-bind="{
              id,
              preset,
              colors,
              icon,
              config,
              prefix,
              suffix,
              rawData: cachedData && cachedData.rawData,
            }"
          />
        </template>
        <div v-else-if="chartType === 'number'" class="single-number">
          <div class="text-4xl" style="color: #6454f3;">
            <span v-if="prefix">{{ prefix }} </span>
            <span>{{ numberFormat((cardData && cardData) || 0) }}</span>
          </div>
          <!-- <a-statistic
            :prefix="prefix"
            :precision="0"
            :value="cardData"
            valueStyle="font-size: 36px; color: #6454f3;"
          /> -->
        </div>
        <template v-if="goalCard">
          <RichTextEditor
            v-if="isAdmin"
            :component="{ text: config && config.goalFooter }"
            @update="setConfigProp('goalFooter', $event[1])"
          />
          <div v-else v-html="(config && config.goalFooter) || ''" />
        </template>
      </ClickToScroll>
      <div class="rhs">
        <FlowEventsTimeline v-if="goalCard" :tags="tags" />
      </div>
    </template>
  </Card>
</template>

<script>
import debounce from 'lodash/debounce'
import marked from 'marked'
import Firebase from '@firebase/app'
import '@firebase/functions'
import { mapGetters, mapState } from 'vuex'

import api from '@/helpers/api'
import { unpack } from '@/helpers/computed'
// import { toDateString } from '@/helpers/timeFilters'
import { createDateRangeArray } from './computeDateGroupings'
import cachedDataToCardData, { generateCacheSlug, getBreakdownArray } from './cacheToChartData'

import SavvyBarChart from './SavvyBarChart'
import SavvyLineChart from './SavvyLineChart'
import SavvyFunnelCard from './SavvyFunnelCard'
import ComparisonChart from './SavvyComparisonChart'
import TripleInsights from './TripleInsights'
// // import BarChart from './BarChart'
import InsightCardConfig from './InsightCardConfig'
import { SERVICES } from './services'
import { copyToClipboard } from '@/helpers/clipboard'
import getPresetConfig from './insightCardPresets'

import RichTextEditor from '@/components/form/editor/RichTextEditor.vue'
import FlowEventsTimeline from '../explorer/FlowEventsTimeline.vue'
import FlowThumbnail from '@/components/form/FlowThumbnail.vue'
import InsightCardInfo from './InsightCardInfo.vue'
import moment from 'moment'

export default {
  name: 'InsightCard',
  components: {
    TripleInsights,
    SavvyBarChart,
    SavvyLineChart,
    ComparisonChart,
    SavvyFunnelCard,
    InsightCardConfig,
    FlowEventsTimeline,
    FlowThumbnail,
    RichTextEditor,
    InsightCardInfo,
  },
  inject: {
    _getConnections: { default: () => () => {} },
    _updateConnections: { default: () => () => {} },
  },
  props: {
    id: String,
    data: Object,
    isSmall: Boolean,
    tags: { default: () => [], type: Array },
    filters: { type: Array, default: () => [] },
  },
  data() {
    return {
      now: Date.now(),
      config: {},
      queryResult: null,
      showAll: false,
      initialWait: true,
      cachedData: null,
      cardCache: null,
      loadingCardCache: true,
      loadingCache: true,
      loadingData: false,
      requestReload: false,
      editingMarkdown: false,
      alertDetails: null,
      debugDisplay: false,
    }
  },
  computed: {
    ...mapGetters(['isAdmin']),
    ...mapState(['showTestData']),
    ...unpack('config', ['goalCard']),
    isFixedConfig() {
      return this.id.startsWith('preset')
    },
    isFlush() {
      return ['flow'].includes(this.service)
    },
    connections() {
      return this._getConnections()
    },
    currentSourceIsConnected() {
      const service = SERVICES[this.service]
      if (!service) return false
      if (service.alwaysConnected) return 'connected'
      if (!this.connections) return 'loading'
      const status = this.connections[this.service]
      // if (status === undefined) return 'loading'
      return status ? 'connected' : 'not-connected'
    },
    projectId() {
      return this.$store.state.currentProjectId
    },
    collection() {
      return this.projectId
        ? `projects/${this.projectId}/stats`
        : `groups/${this.activeGroupId}/insights`
    },
    displayLimit() {
      return this.isSmall ? 5 : 10
    },
    service() {
      return (this.config && this.config.service) || (this.data && this.data.service)
    },
    serviceName() {
      return SERVICES[this.service] && SERVICES[this.service].name
    },
    chartType() {
      if (this.config) {
        const presetIsFunnel =
          this.service === 'combo' && ['flow-pages', 'checkpoint', 'funnel'].includes(this.preset)
        if (presetIsFunnel) {
          if (this.config[`${this.service}-funnel-breakdown`]) return 'savvy-bar'
          return 'funnel'
        }
        switch (this.config.grouping) {
          case 'none':
            return 'number'
          case 'date':
          case 'both':
            return 'line'
          case 'non-date':
          default:
            return 'savvy-bar'
        }
      }
      return null // 'savvy-bar'
    },
    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display:
            (this.cardData && this.cardData.datasets && this.cardData.datasets.length > 1) || false,
        },
        scales: {
          xAxes: [{ gridLines: { display: false } }],
          yAxes: [
            {
              gridLines: { display: false },
              ticks: { beginAtZero: true },
            },
          ],
        },
      }
    },
    preset() {
      return (
        (this.config && (this.config.preset || this.config[`${this.service}-preset`])) ||
        (this.data && this.data.preset)
      )
    },
    prefix() {
      if (this.preset === 'revenue') return '$'
      else return ''
    },
    suffix() {
      if (
        ['checkpoint', 'flow-pages', 'funnel'].includes(this.preset) &&
        this.config['combo-funnel-breakdown']
      )
        return '%'
      else return ''
    },
    name() {
      if (this.config && this.config.title) return this.config.title

      switch (this.preset) {
        case 'impressions':
          return 'Search term impressions'
        case 'clicks':
          return 'Search term clicks'
        case undefined:
          return 'New Insight Card'
        default:
          return (
            'Top ' +
            this.preset
              .split('-')
              .map(w => w[0].toUpperCase() + w.slice(1))
              .join(' ')
          )
      }
    },
    colors() {
      switch (this.preset) {
        case 'pages':
          return ['#6454f3']
        case 'referrers':
          return ['#ec1b8d']
        case 'countries':
          return ['#25c188']
        case 'cities':
          return ['#ff8d00']

        default:
          return ['#6454f3']
      }
    },
    icon() {
      switch (this.preset) {
        case 'pages':
          return 'book'
        case 'referrers':
          return 'share-alt'
        case 'countries':
          return 'environment'
        case 'cities':
          return 'environment'
        default:
          return 'chart-area'
      }
    },
    activeGroupId() {
      return this.$store.getters.activeGroupId
    },
    cardData() {
      if (this.useCache) {
        return cachedDataToCardData(this.preset, this.cachedData, this.config)
      }

      const data = this.convertInternalAPIToCacheData(this.queryResult)

      return data ? cachedDataToCardData(this.presetField, { data }, this.config) : {}
    },
    useCache() {
      if (this.service === 'text') return false
      if (this.service === 'comparison') return false
      return true
    },
    useInternalAPI() {
      if (SERVICES[this.service] && SERVICES[this.service].useInternalAPI) return true

      return false
    },
    cachedDataId() {
      if (this.service && this.preset && this.preset !== 'top-line') {
        return generateCacheSlug(
          this.config,
          this.service,
          this.preset,
          this.breakdownArray,
          this.showTestData
        )
      }
      return null
    },
    breakdownArray() {
      return getBreakdownArray(this.config, this.service)
    },
    deBounceGetData() {
      return debounce(this.getData, 250, { trailing: true, leading: false })
    },
    useVisitorCount() {
      return (
        this.service === 'savvy' &&
        this.preset === 'visitors' &&
        (this.config.grouping === 'date' || this.config.grouping === 'none')
      )
    },
    visitorCount() {
      return (this.cardCache && this.cardCache.value) || null
    },
    lastUpdatedAt() {
      /* Not reactive to now */
      const updatedAt =
        this.cachedData && this.cachedData.updatedAt && this.cachedData.updatedAt.seconds * 1000
      return moment(new Date(updatedAt).getTime()).from(moment(this.now))
    },
    includeTest() {
      return this.showTestData || (this.config && this.config.includeTest)
    },
    markdownRaw: {
      get() {
        return this.config && this.config.markdownRaw
      },
      set: debounce(function(markdownRaw) {
        Firebase.firestore()
          .collection(this.collection)
          .doc(this.id)
          .update({ markdownRaw })
      }, 300),
    },
    compiledMarkdown: function() {
      return this.service === 'markdown' && this.config && marked(this.config.markdownRaw)
    },
  },
  mounted() {
    this.nowTimer = setInterval(() => (this.now = Date.now()), 60000)
  },
  beforeDestroy() {
    clearInterval(this.nowTimer)
  },
  watch: {
    id: {
      handler(id) {
        if (id.startsWith('preset')) {
          this.config = getPresetConfig(id)
        }
      },
      immediate: true,
    },
    chartType: {
      handler() {
        this.loadingCache = true
        // this.cachedData = null
        const self = this
        this.selfClearingTimer = setTimeout(() => {
          self.loadingCache = false
        }, 5000)
      },
    },
    config: {
      handler() {
        if (!this.initialWait) this.deBounceGetData()
      },
      deep: true,
    },
    loadingCache: {
      handler(v) {
        if (!v) this.deBounceGetData()
      },
    },
    filters() {
      if (!this.initialWait) this.deBounceGetData()
    },
    initialWait: {
      handler(v) {
        if (!v) this.deBounceGetData(false, false)
        else {
          const self = this
          setTimeout(() => (self.initialWait = false), 1500)
        }
      },
      immediate: true,
    },
  },
  methods: {
    setLoadingData(e) {
      if (e.type === 'data') {
        this.loadingData = e.value
      } else this.loadingCache = e.value
    },
    alert(message, description) {
      this.alertDetails = {
        message,
        description,
        banner: true,
        closable: true,
        type: 'error',
        showIcon: true,
      }
    },
    closeAlert(clear) {
      if (clear) this.alertDetails = null
      else copyToClipboard(`${this.name} (${this.id}) error: ${this.alertDetails.description}`)
    },
    async connectToService(service) {
      try {
        await window.xkit.connect(service)

        this._updateConnections(service)
      } catch (error) {
        console.error(error)
      }
    },
    reloadCard() {
      if (this.loadingData) return
      /* If using TripleInsights do this instead */
      if (this.service === 'savvy' && this.preset === 'top-line') {
        this.loadingData = 'update'
        this.requestReload = true
      } else this.deBounceGetData(true, true)
    },
    onReloaded() {
      this.loadingData = false
      this.requestReload = false
    },
    convertInternalAPIToCacheData(queryResult) {
      if (!queryResult) return
      return Object.keys(queryResult).every(k => k === this.preset)
        ? this.queryResult[this.preset].breakdown
        : Object.values(this.queryResult).reduce((acc, d) => {
            acc[d.key] = d.breakdown || d.count
            return acc
          }, {})
    },
    async getData(skipTimeCheck, force) {
      if (!this.config || !this.cachedDataId) return
      if (!force && this.loadingCache) return
      if (this.activeGroupId === 'UwH8vwytXcivU2GfumRd') return
      try {
        if (!skipTimeCheck && this.cachedData && this.cachedData.updatedAt) {
          const nowSeconds = Date.now() / 1000
          const lastUpdatedSeconds = this.cachedData.updatedAt.seconds
          const interval = 60 * 15 //3600 * 1
          if (nowSeconds - lastUpdatedSeconds < interval) {
            this.closeAlert(true)
            return
          }
        }

        if (this.useVisitorCount) {
          this.getVisitorCount(skipTimeCheck, force)
        }

        this.loadingData = !this.cachedData ? 'new' : 'update'

        if (this.useInternalAPI) {
          const res = await this.requestAPICacheUpdate(this.preset)
          if (res && !res.success && res.error) {
            this.alert(res.error.message, res.error.errorMessage)
            /* TODO - Add some sort of alert for when success is false */
          } else if (res) this.closeAlert(true)
        } else await this.requestFirebaseCacheUpdate()
      } catch (error) {
        console.error(error)
        this.alert(error.message, error.message)
      }
      this.loadingData = false
    },
    async requestAPICacheUpdate(preset) {
      const funnelId = (this.filters.find(f => f[0] === 'journey') || [])[2]
      const stageId = (this.filters.find(f => f[0] === 'stage') || [])[2]
      const algoliaFilters = this.config[`${this.service}-checkpoint-filters`]
        ? this.config[`${this.service}-checkpoint-filters`]
        : undefined

      return await api('/metrics/dashboard', {
        groupId: this.activeGroupId,
        preset,
        filters: this.filters.filter(f => !['journey', 'stage'].includes(f[0])),
        funnelId: funnelId || this.$store.getters.mainJourneyId,
        stageId,
        cacheId: this.cachedDataId,
        config: this.config,
        algoliaFilters,
        includeTest: this.includeTest,
        dateRanges: createDateRangeArray(this.config),
        timezone: this.$store.getters.groupTimezone, // Intl.DateTimeFormat().resolvedOptions().timeZone, // This is normally from the group but can be form the group document instead, defualts to PST
      })
    },
    onLoaded(e, key) {
      this[key] = e
      if (!e) clearTimeout(this.selfClearingTimer)
    },
    async requestFirebaseCacheUpdate() {
      // let callableString = ''
      if (!this.preset) return
      const data = {
        groupId: this.activeGroupId,
        preset: this.preset,
        cacheId: this.cachedDataId,
        config: this.config,
        dateRanges: createDateRangeArray(this.config),
        /* Deprecated */
        aggType: this.preset,
        breakdown: this.breakdownArray,
      }

      if (this.service !== 'text') Firebase.functions().httpsCallable('insights')(data)
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    async getVisitorCount(skipTimeCheck, force) {
      if (!force && this.loadingCardCache) return

      if (!skipTimeCheck && this.cardCache && this.cardCache.updatedAt) {
        const nowSeconds = Date.now() / 1000
        const lastUpdatedSeconds = this.cardCache.updatedAt.seconds
        const interval = 60 * 15 //3600 * 1
        if (nowSeconds - lastUpdatedSeconds < interval) return
      }

      const dateRange = createDateRangeArray(this.config)
      // const { data } =
      await api('/metrics/visitor-total', {
        groupId: this.activeGroupId,
        range: dateRange,
        config: this.config,
        timezone: this.$store.getters.groupTimezone,
        cachePath: `${this.collection}/${this.id}/cache/visitorCount`,
      })
    },
    setConfigProp(key, val) {
      const newConfig = { ...this.config }
      if (val === undefined) {
        delete newConfig[key]
      } else {
        newConfig[key] = val
      }
      const collection = this.projectId
        ? `projects/${this.projectId}/stats`
        : `groups/${this.$store.getters.activeGroupId}/insights`

      Firebase.firestore()
        .collection(collection)
        .doc(this.id)
        .set(newConfig)
    },
    numberFormat(n) {
      if (n === Math.floor(n)) return Math.floor(n)
      return `${n.toFixed(2)}`.split('.')[0]
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/_variables.scss';

.InsightCard {
  height: 100%;

  &:not(:hover) {
    .InsightCardConfig,
    .info-hover-button,
    .edit-markdown,
    .reload-card {
      opacity: 0;
    }
  }

  .Icon.test-data {
    &.active {
      color: $savvy;
    }
  }

  &.goal {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: auto 1fr;

    .name {
      font-size: 24px;
    }
    .LineChart {
      height: calc(100% - 70px);
    }
    .rhs {
      grid-area: 1 / 2 / 3 / 3;
      overflow-y: scroll;
    }
  }

  .reload-card {
    margin: 0.15em 0 0 0.75em;
    font-size: 0.9em;
    color: #bbb;
    pointer-events: none;
    cursor: not-allowed;

    &.clickable {
      pointer-events: all;
      cursor: pointer;

      &:hover {
        color: $savvy;
      }
    }
  }

  .single-number {
    .ant-statistic-content {
      font-size: 20px;
      color: $savvy;
    }
  }

  .FlowThumbnail {
    position: absolute;
    top: 0; // -50px;
    left: 0; // -16px;
    right: 0; // -16px;
    bottom: 0; // -8px;
  }
}
// .ant-card {
//   width: 50%;
// }
// .InsightCard {
//   // margin: 0.5em;
//   // padding: 1em 1.5em;
//   // background: white;
//   // border-radius: 4px;
// }
</style>
