<template lang="html">
  <div class="Spinner" :class="{ purple, white }">
    <div class="grower">
      <img :src="src" class="fa-spin" />
    </div>
  </div>
</template>

<script>
export default {
  props: { purple: Boolean, white: Boolean, isWC: Boolean },
  computed: {
    src() {
      return this.isWC ? 'https://cdn.heysavvy.com/images/icon.png' : '/images/icon.png'
    },
  },
}
</script>

<style lang="scss">
@import './styles/Spinner.scss';
</style>
