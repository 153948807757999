<template>
  <div class="ContactProfileSource">
    <div class="main-label text-sm flex items-center">
      <span :class="{ 'font-semibold ': !isAnon }">{{ mainLabel }}</span>
      <template v-if="!contact.is_test">
        <ContactSource
          v-for="source in sources.slice(0, maxSources)"
          :key="`${source.channel}-${source.medium}-${source.campaign}`"
          v-bind="{ source }"
        />
        <span
          v-if="sources.length > maxSources"
          class="text-xs ml-2 text-gray-400 whitespace-nowrap"
          >+{{ sources.length - maxSources }}</span
        >
      </template>
      <div
        v-if="contact.is_test"
        class="px-2 py-0.5 m-2 text-gray-700 text-xs whitespace-nowrap rounded bg-gray-100"
      >
        <Icon name="vial" />
        Test User
      </div>
    </div>
    <div class="other-labels text-xs" v-if="otherLabels.length">
      <div v-for="[key, val] in otherLabels" :key="key" class="label">
        {{ val }}
      </div>
    </div>
  </div>
</template>

<script>
import { notNull } from './helpers/algolia'
import ContactSource from './ContactSource'

export default {
  name: 'ContactProfileSource',
  components: {
    ContactSource,
  },
  props: {
    contact: Object,
    extras: Object,
  },
  data() {
    return {
      maxSources: 3,
    }
  },
  computed: {
    isAnon() {
      return !notNull(this.allLabels[0] && this.allLabels[0][1])
    },
    mainLabel() {
      return notNull(this.allLabels[0] && this.allLabels[0][1]) || 'Anonymous'
    },
    otherLabels() {
      return this.allLabels.slice(1)
    },
    allLabels() {
      const c = this.contact
      const keys = ['name', 'email', 'phone', 'company', 'website', 'domain']

      return keys
        .map(key => [key, c[key] || (Object.values(c.flows || {}).find(f => f[key]) || {})[key]])
        .map(e =>
          e[0] === 'name' && !e[1] && (c.first_name || c.last_name)
            ? ['name', [c.first_name, c.last_name].join(' ')]
            : e
        )
        .filter(([, val]) => notNull(val))
    },
    sources() {
      return (this.extras && this.extras.sources) || []
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/_variables.scss';

.ContactProfileSource {
}
</style>
