<template>
  <div class="AnalyticsSnippet">
    <Entities
      renderless
      collection="products"
      :limit="1"
      :filters="productFilters"
      v-model="products"
    />
    <div v-if="productId">
      <div class="snippet">
        <div v-for="s in snippet" :key="s">{{ s }}</div>
        <div class="copy">
          <Button text="Copy" @click.native="copySnippet" />
        </div>
      </div>
      <div class="or">or</div>
      <Button
        class="new-savvy secondary"
        text="Download the WordPress Plugin"
        @click.native="downloadWPPlugin"
      />
    </div>
    <Spinner v-else />
  </div>
</template>

<script>
import { copyToClipboard } from '@/helpers/clipboard'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import { getWordPressSnippet } from '@/data/codeSnippets'
export default {
  name: 'AnalyticsSnippet',
  data() {
    return {
      products: [],
    }
  },
  computed: {
    activeGroupId() {
      return this.$store.getters.activeGroupId
    },
    productFilters() {
      return [['ownerGroupId', '==', this.activeGroupId]]
    },
    productId() {
      return this.products[0] ? this.products[0].id : null
    },
    snippet() {
      return [
        `<savvy-smart-form id="${this.productId}"></savvy-smart-form>`,
        `<script src="https://cdn.heysavvy.com/wc/savvy.min.js"><` + `/script>`,
      ]
    },
  },
  methods: {
    copySnippet() {
      copyToClipboard(this.snippet.join('\n'))
      this.$message.info('Snippet copied to clipboard!')
    },
    async downloadWPPlugin() {
      if (!this.productId) return
      const zip = new JSZip()
      const folder = zip.folder('SavvyWordpressPlugin')
      folder.file('SavvyWordpressPlugin.php', getWordPressSnippet(this.productId))
      const content = await zip.generateAsync({ type: 'blob' })

      await saveAs(content, 'SavvyWordpressPlugin.zip')
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/_variables.scss';

.AnalyticsSnippet {
  height: 100%;
  box-sizing: border-box;
  text-align: left;
  // @include flex('column', 'a-start');

  .snippet {
    position: relative;
    align-self: flex-start;
    min-width: 600px;
    margin: 40px 20px;
    padding: 40px 50px;
    font-size: 20px;
    font-family: monospace;
    border-radius: 8px;
    background: white;
    border-top: 8px solid var(--color-savvy);
    color: var(--color-savvy);

    > div.copy {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  button.Button {
    align-self: flex-start;
    margin: 10px 20px;
  }
  .or {
    font-size: 20px;
    margin: 0 20px 10px;
  }
}
</style>
