import Firebase from 'firebase/app'
import 'firebase/firestore'

export default {
  props: {
    collection: String,
    id: String,
    value: Object,
    propKey: { type: String, default: 'data' },
  },
  data() {
    return {
      data: null,
    }
  },
  watch: {
    data: {
      handler(data) {
        this.$emit('input', data)
      },
      immediate: true,
    },
    collection() {
      this.bind()
    },
    id() {
      this.bind()
    },
  },
  mounted() {
    this.bind()
  },
  beforeDestroy() {
    this.$emit('input', null)
  },
  methods: {
    async bind() {
      if (!this.collection || !this.id) {
        this.data = null
        return
      }
      this.$emit('loading', true)
      try {
        const ref = Firebase.firestore()
          .collection(this.collection)
          .doc(this.id)

        const data = await this.$bind('data', ref)

        this.$emit('loaded', data)
      } catch (error) {
        this.onErrorHandler(error)
      }
      this.$emit('loading', false)
    },
    onErrorHandler(error) {
      console.error(`Entity Error for /${this.collection}/${this.id}: `, error)
      this.data = { _error: true }
    },
  },
  render() {
    if (this.data && this.data._error) {
      if (this.$scopedSlots.error) return this.$scopedSlots.error({ error: this.data })
      else {
        return (
          <div class={`entity-error ${this.collection}-${this.id}`}>
            You do not have permission to view this {getEntityName(this.collection)}.
          </div>
        )
      }
    }
    return this.$scopedSlots.default
      ? this.$scopedSlots.default({ [this.propKey]: this.data })
      : null
  },
}

function getEntityName(collection) {
  switch (collection) {
    case 'queries':
      return 'query'
    case 'cards':
    case 'users':
    case 'groups':
    default:
      return collection.slice(0, -1)
  }
}
