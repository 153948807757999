<template>
  <a-dropdown v-if="full" :trigger="isAdmin ? ['contextmenu'] : []">
    <div
      class="Card AnnotationCard"
      :class="{ isHighlighted }"
      @mouseenter="_highlightAnnotation(id)"
      @mouseleave="_highlightAnnotation(null)"
    >
      <Entity
        v-if="linkedPageId"
        :collection="linkedPageCollection"
        :id="linkedPageId"
        v-model="linkedPage"
      />
      <div class="CardHeader" v-if="annotation.title || editingMode">
        <div>
          <div class="important" v-if="annotation.important">
            <a-space align="center" :size="6"><a-icon type="info-circle" />Important </a-space>
          </div>
          <div style="display: flex; align-items: center;">
            <AnnotationIcon click v-bind="{ annotation, rootPath }" />
            <SmartContent :path="`${rootPath}/title`" />
          </div>
        </div>
        <a-tooltip
          :title="(annotation.archived ? 'Un-' : '') + `Archive this Insight`"
          placement="topLeft"
        >
          <Icon class="archive" name="archive" :click="toggleArchive" />
        </a-tooltip>
      </div>
      <div class="CardBody">
        <SmartContent :path="`${rootPath}/body`" />
      </div>
      <a-button
        class="secondary"
        v-if="annotation.linkedPageId && linkedPageIsHidden"
        icon="deployment-unit"
        @click="goToLinkedPage"
        >Monitor this Insight</a-button
      >
    </div>

    <a-menu slot="overlay">
      <a-menu-item key="0">
        <div @click="toggleImportant">
          Mark as {{ this.annotation && this.annotation.imporant ? 'unimportant' : 'important' }}
        </div>
      </a-menu-item>
      <a-menu-item key="1">
        <div @click="viewInFirebase">
          View in Firebase
        </div>
      </a-menu-item>
      <a-sub-menu v-if="isAdmin" key="sub2">
        <span slot="title"><a-icon type="search" /><span>Add a hidden page</span></span>
        <a-menu-item :key="page.id" v-for="page in pagesToShow" @click.native="addPage(page.id)">
          {{ page.title }} {{ page.hidden ? '(Hidden)' : '' }}
        </a-menu-item>
      </a-sub-menu>
    </a-menu>
  </a-dropdown>

  <a-popover
    v-else-if="annotation || editingMode"
    :class="{ exists: annotation }"
    :trigger="['click']"
  >
    <!-- <div
      class="Annotation"
      :class="{ isHighlighted }"
      @mouseenter="_highlightAnnotation(id)"
      @mouseleave="_highlightAnnotation(null)"
    /> -->
    <AnnotationIcon class="Annotation" v-bind="{ annotation, rootPath }" />
    <template slot="title">
      <div style="display: flex; align-items: center;">
        <AnnotationIcon click v-bind="{ annotation, rootPath }" />
        <SmartContent :path="`${rootPath}/title`" />
      </div>
    </template>
    <template slot="content">
      <div style="width: 200px; min-height: 100px">
        <SmartContent :path="`${rootPath}/body`" />
      </div>
    </template>
  </a-popover>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import Firebase from 'firebase/app'
import 'firebase/firestore'

import { mapParams } from '@/helpers/router'

import AnnotationIcon from './AnnotationIcon'

export default {
  name: 'Annotation',
  inject: {
    _getAnnotation: { default: () => () => {} },
    _highlightAnnotation: { default: () => () => {} },
    _annotationIsHighlighted: { default: () => () => {} },
  },
  props: {
    id: String,
    pagesPath: String,
    full: Boolean,
  },
  components: {
    AnnotationIcon,
  },
  data() {
    return {
      linkedPage: null,
    }
  },
  computed: {
    ...mapState(['editingMode', 'pages']),
    ...mapGetters(['isAdmin']),
    ...mapParams(['projectId']),
    pagesToShow() {
      return this.pages.filter(p => p.title && p.hidden && p.sidebar)
    },
    annotation() {
      return this._getAnnotation(this.id)
    },
    rootPath() {
      const rootPath = `private/groups/${
        this.$store.getters.activeGroupId
      }/annotations/${encodeURIComponent(this.$route.path)}/annotations/${this.id}`

      return rootPath
    },
    isHighlighted() {
      return this._annotationIsHighlighted(this.id)
    },
    linkedPageCollection() {
      return `content/${this.pagesPath}/pages`
    },
    linkedPageId() {
      return this.annotation && this.annotation.linkedPageId
    },
    linkedPageIsHidden() {
      return this.linkedPage && this.linkedPage.hidden
    },
    icon() {
      switch (this.annotation && this.annotation.type) {
        case 'hot':
          return 'fad-fire-alt'
        case 'heating':
          return 'fad-temperature-up'
        case 'cold':
          return 'fad-snowflake'
        case 'cooling':
          return 'fad-temperature-down'
        default:
          return null
      }
    },
    colors() {
      switch (this.annotation && this.annotation.type) {
        case 'hot':
        case 'heating':
          return ['red', 'grey']
        case 'cold':
        case 'cooling':
          return ['blue', 'grey']
        default:
          return null
      }
    },
  },
  // watch: {
  //   isHighlighted(isHighlighted) {
  //     if (isHighlighted) this.$el.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
  //   },
  // },
  methods: {
    goToLinkedPage() {
      Firebase.firestore()
        .collection(this.linkedPagePath)
        .doc(this.annotation.linkedPageId)
        .update({ hidden: false })

      this.$router.push(`/projects/${this.projectId}/pages/${this.annotation.linkedPageId}`)
    },
    addPage(id) {
      Firebase.firestore()
        .collection('content')
        .doc(this.rootPath)
        .update({ linkedPageId: id })
    },
    toggleArchive() {
      Firebase.firestore()
        .collection('content')
        .doc(this.rootPath)
        .update({ archived: !this.annotation.archived })
    },
    toggleImportant() {
      Firebase.firestore()
        .collection('content')
        .doc(this.rootPath)
        .update({ important: !this.annotation.important })
    },
    changeType() {
      const types = ['hot', 'cold', 'heating', 'cooling']
      const index = types.indexOf(this.annotation.type)
      const type = types[index < types.length - 1 ? index + 1 : 0]

      Firebase.firestore()
        .collection('content')
        .doc(this.rootPath)
        .update({
          type,
        })
    },
    viewInFirebase(event) {
      const url = `https://console.firebase.google.com/u/0/project/savvy-app-live/firestore/data/content~2F${encodeURIComponent(
        this.rootPath
      )}`

      if (event.metaKey) {
        // copyToClipboard(url)
      } else {
        window.open(url)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/_variables.scss';

.Annotation {
  position: absolute;
  top: -6px;
  right: -6px;
  // background: $highlight;
  border-radius: 100px;
  // width: 12px;
  // height: 12px;
  z-index: 10;
  transition: transform 0.3s;
  cursor: pointer;

  &.isHighlighted,
  &:hover {
    // box-shadow: 0 4px 10px rgba($highlight, 0.3);
    transform: scale(1.5);
  }
}
.AnnotationCard {
  transition: transform 0.3s, background-color 0.3s;

  &:not(:hover) {
    .Icon.archive {
      opacity: 0;
    }
  }
  &.isHighlighted {
    // box-shadow: 0 4px 10px rgba($highlight, 0.3);
    background-color: rgba($highlight, 0.05);
    transform: translateY(-4px);
  }

  .Icon.archive {
    color: #ccc;
    cursor: pointer;

    &:hover {
      color: $savvy;
    }
  }
  .important {
    margin: 0 0 10px 0;
    color: $highlight;
    font-weight: 500;
  }
  > .ant-btn {
    margin: 8px;
  }
}
</style>
