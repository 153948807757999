export const ALGOLIA_APP_ID = process.env.VUE_APP_ALGOLIA_APP_ID
export const ALGOLIA_MESSAGES_INDEX_NAME = process.env.VUE_APP_ALGOLIA_MESSAGES_INDEX_NAME
export const CONTACTS_INDEX_NAME = process.env.VUE_APP_ALGOLIA_CONTACTS_INDEX_NAME
export const INDEX_NAMES = { messages: ALGOLIA_MESSAGES_INDEX_NAME, contacts: CONTACTS_INDEX_NAME }
export const FLOWS_COLLECTION = 'forms'

export const PROJECT_STATUSES = [
  { id: 'live', name: 'Live', icon: 'play-circle', color: '#25c188' },
  { id: 'paused', name: 'Paused', icon: 'pause-circle', color: '#aaa' },
  { id: 'draft', name: 'Draft', icon: 'edit', color: '#3377ff' },
  { id: 'stopped', name: 'Stopped', icon: 'stop-circle', color: '#aa0000' },
]
export function getProjectStatus(id) {
  return PROJECT_STATUSES.find(p => p.id === id)
}
