<template>
  <div
    class="FlowExperiment my-2 p-3 rounded border border-gray-200 relative"
    :class="{
      'cursor-pointer': !open,
      'hover:bg-gray-50': !open,
    }"
    @click.self="open = true"
  >
    <div
      :class="{
        'pointer-events-none': !open,
      }"
    >
      <Icon
        v-if="open"
        class="absolute top-4 right-4 cursor-pointer hover:text-purple"
        name="times"
        @click.native="open = false"
      />
      <div class="flex mb-2">
        <span class="mr-1"> {{ index + 1 }}. </span>
        <PlainEditableText
          class="flex items-center"
          :isEditable="open"
          :initialText="experiment.title"
          placeholder="Experiment Title"
          @update-text="update('title', $event)"
        />
      </div>
      <div class="flex">
        <PlainEditableText
          class="flex items-center monospaced font-bold"
          :isEditable="open"
          :initialText="experiment.key"
          placeholder="Experiment Key"
          @update-text="update('key', $event)"
        />
      </div>
      <div v-if="!open" class="font-bold capitalize">{{ experiment.status }}</div>
      <template v-if="open">
        <div class="flex mt-2">
          <PlainEditableText
            class="flex items-center italic text-gray-600"
            :isEditable="open"
            :initialText="experiment.description"
            placeholder="Experiment Description"
            @update-text="update('description', $event)"
          />
        </div>
        <a-select
          style="width: 100%; margin-top: 16px;"
          :value="experiment.status"
          @change="update('status', $event)"
        >
          <a-select-option value="draft">Draft</a-select-option>
          <a-select-option value="live">Live</a-select-option>
          <a-select-option value="paused">Paused</a-select-option>
          <a-select-option value="completed">Completed</a-select-option>
          <a-select-option value="cancelled">Cancelled</a-select-option>
        </a-select>

        <div class="flex flex-col my-2">
          <a-button v-if="toFork" class="capitalize" @click="fork">
            Fork Current {{ toFork[0] }}
          </a-button>
        </div>

        <div
          v-if="variants.map(v => v.weighting).reduce((a, b) => a + b, 0) !== 100"
          class="rounded border-2 border-red-300 p-2 text-center"
        >
          <div>
            <Icon class="mr-1" name="exclamation-triangle" />
            Weightings don't add up to 100!
          </div>
          <a-button type="primary" class="my-2" @click="autoAssignWeightings">
            <Icon class="mr-1" name="wrench" />
            Click to fix
          </a-button>
        </div>
        <FlowExperimentVariant
          v-for="(variant, _index) in variants"
          :key="variant.id"
          :viewing="variantViewing === variant.key"
          :experimentIndex="index"
          :index="_index"
          v-bind="{ variant, experiment, userData }"
          @delete="deleteVariant(_index)"
        />
        <div class="flex flex-col">
          <a-button
            v-if="variants.some(v => v.weighting !== 100 / numVariants)"
            class="my-2"
            @click="autoAssignWeightings"
          >
            <Icon class="mr-1" name="plus" />
            Auto-assign Weightings
          </a-button>
          <a-button class="my-2" type="primary" @click="createVariant">
            <Icon class="mr-1" name="plus" />
            Add Variant
          </a-button>
          <a-button class="my-2" type="danger" @click="$emit('delete')">
            <Icon class="mr-1" name="trash" />
            Delete Experiment
          </a-button>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Firebase from 'firebase/app'
import 'firebase/firestore'
import cloneDeep from 'lodash/cloneDeep'

import PlainEditableText from '@/components/utilities/PlainEditableText'
import FlowExperimentVariant from './FlowExperimentVariant'

export default {
  name: 'FlowExperiment',
  components: {
    PlainEditableText,
    FlowExperimentVariant,
  },
  inject: ['_updateData', '_getSelectedComponentId'],
  props: {
    index: Number,
    experiment: Object,
    form: Object,
    userData: Object,
  },
  data() {
    return {
      open: false,
    }
  },
  computed: {
    variants() {
      return this.experiment.variants || []
    },
    numVariants() {
      return this.variants.length
    },
    variantViewing() {
      return this.userData[this.experiment.key]
    },
    toFork() {
      return this._getSelectedComponentId()
        ? ['component', this._getSelectedComponentId()]
        : ['page', this.userData.currentPageId]
    },
  },
  watch: {
    numVariants() {
      this.autoAssignWeightings()
    },
  },
  methods: {
    update(key, val) {
      console.log('key, val', key, val)
      this._updateData(`experiments.${this.index}`, key, val)
    },
    createVariant() {
      console.log('createVariant')
      this._updateData(`experiments.${this.index}`, 'variants', [
        ...this.variants,
        {
          id: id(),
        },
      ])
    },
    deleteVariant(index) {
      console.log('index', index)
      this._updateData(`experiments.${this.index}`, 'variants', [
        ...this.variants.filter((e, i) => i !== index),
      ])
    },
    autoAssignWeightings() {
      const newWeighting = 100 / this.numVariants
      this._updateData(`experiments.${this.index}`, 'variants', [
        ...this.variants.map(v => ({ ...v, weighting: newWeighting })),
      ])
    },
    fork() {
      console.log('toFork', this.toFork)
      switch (this.toFork[0]) {
        case 'component':
          {
            const componentIndex = this.form.pages[
              this.userData.current_page_index
            ].components.findIndex(c => c.id === this.toFork[1])
            console.log('componentIndex', componentIndex)
            const allComponents = this.form.pages[this.userData.current_page_index].components
            const originalComponent = allComponents[componentIndex]
            const newComponents = this.variants.map((variant, index) => {
              const newComponent = cloneDeep(originalComponent)

              if (!newComponent.conditions) newComponent.conditions = []

              console.log('variant.key', variant.key)
              newComponent.conditions.push({
                key: this.experiment.key,
                operator: '==',
                values: [variant.key].concat(index === 0 ? ['_no_value'] : []),
              })
              if (index > 0) {
                newComponent.id = id()
                newComponent.cloned_from_key = newComponent.key
                if (newComponent.type === 'Container')
                  newComponent.key = `${originalComponent.key}_${variant.key}`
              }
              newComponent.experiment_origin = {
                experiment_key: this.experiment.key,
                variant_key: variant.key,
              }

              console.log('newComponent', newComponent)

              return newComponent
            })

            const newChildComponents = []

            newComponents
              .slice(1)
              .forEach(c =>
                newChildComponents.push(
                  ...generateChildDupes(c, allComponents, c.experiment_origin.variant_key)
                )
              )

            // const newChildComponents = newComponents.slice(1).reduce((acc, component, index) => {
            //   const childComponents = allComponents.filter(c => c.parent_key === originalComponent.key)

            //   acc.push(
            //     ...childComponents.map(c => ({
            //       ...c,
            //       id: id(),
            //       key: `${c.key}_${this.variants[index].key}`,
            //       parent_key: component.key,
            //     }))
            //   )

            //   return acc
            // }, [])
            allComponents.splice(componentIndex, 1, ...newComponents.concat(newChildComponents))
            this._updateData(
              `pages.${this.userData.current_page_index}`,
              'components',
              allComponents
            )
          }

          break

        case 'page':
          {
            const pageIndex = this.userData.current_page_index
            console.log('pageIndex', pageIndex)
            const pages = this.form.pages
            const newPages = this.variants.map((variant, index) => {
              const newPage = cloneDeep(pages[pageIndex])

              if (!newPage.conditions) newPage.conditions = []

              console.log('variant.key', variant.key)
              newPage.conditions.push({
                key: this.experiment.key,
                operator: '==',
                values: [variant.key],
              })
              if (index > 0) newPage.id = id()

              console.log('newPage', newPage)

              return newPage
            })
            pages.splice(pageIndex, 1, ...newPages)
            this._updateData(``, 'pages', pages)
          }

          break

        default:
          break
      }
    },
  },
}

function id() {
  return Firebase.firestore()
    .collection('_')
    .doc().id
}

function generateChildDupes(parentComponent, allComponents, variantKey) {
  console.log('parentComponent, variantKey', parentComponent, variantKey)
  const newComponents = cloneDeep(
    allComponents.filter(c => c.parent_key === parentComponent.cloned_from_key)
  ).map(c => ({
    ...c,
    id: id(),
    cloned_from_key: c.key,
    key: c.type === 'Container' ? `${c.key}_${variantKey}` : c.key,
    parent_key: parentComponent.key,
  }))

  const newChildComponents = []

  newComponents.forEach(c => {
    if (c.type === 'Container')
      newChildComponents.push(...generateChildDupes(c, allComponents, variantKey))
  })

  return newComponents.concat(newChildComponents)
}
</script>
