<template>
  <div class="ConnectionsDashboard">
    <Entities renderless collection="groups" :filters="groupsFilters" v-model="groups" />
    <a-select
      labelInValue
      showSearch
      :filterOption="searchOption"
      placeholder="Select Group to Search"
      style="width: 100%"
      @select="getConnectionStatus"
      @search="$event => (searchText = $event)"
    >
      <a-select-option v-for="g in groupButtons" :key="g.id" :name="g.name">
        {{ g.name }}
      </a-select-option>
    </a-select>

    <!-- <a-dropdown>
      <a-menu slot="overlay" placement=bottomLeft @click="getConnectionStatus">
        <a-menu-item v-for="g in groups" :key="g.id">
          {{ g.name || g.title }}
        </a-menu-item>
      </a-menu>
      <a-input placeholder="Search Group" v-model="searchText" />
    </a-dropdown> -->

    <a-table rowKey="id" :columns="columns" :data-source="groupData">
      <span slot="name" slot-scope="text" style="font-family: monospace;">
        {{ text }}
      </span>

      <template v-for="service in services" :slot="service" slot-scope="text">
        <div :key="service" style="font-family: monospace;">
          <a-icon v-if="text[service]" :type="text[service] === true ? 'check' : 'close'" />
        </div>
      </template>
    </a-table>
  </div>
</template>
<script>
import api from '@/helpers/api'
import { SERVICES } from '@/components/dashboard/services'
import debounce from 'lodash/debounce'

export default {
  props: {},
  data() {
    return {
      connectionStatus: {},
      groups: [],
      searchText: null,
      loading: false,
    }
  },
  computed: {
    groupButtons() {
      return this.groups.map(g => ({ id: g.id, name: g.name || g.title || 'Unnamed Group' }))
      // .filter(g => (this.searchText ? g.name.includes(this.searchText) : true))
    },
    groupData() {
      return Object.values(this.connectionStatus)
    },
    user() {
      return this.$store.getters.user
    },
    activeGroupId() {
      return this.$store.getters.activeGroupId
    },
    uid() {
      return this.user ? this.user.uid : null
    },
    isAdmin() {
      return this.$store.getters.isAdmin
    },
    services() {
      return Object.keys(SERVICES).filter(s => SERVICES[s].alwaysConnected !== true)
    },
    columns() {
      const services = this.services.map(s => ({
        service: s,
        key: s,
        title: SERVICES[s].name,
        scopedSlots: { customRender: s },
      }))

      return [
        {
          dataIndex: 'name',
          key: 'name',
          title: 'Name',
          scopedSlots: { customRender: 'name' },
        },

        ...services,
      ]
    },
    groupsFilters() {
      return !this.isAdmin && this.uid ? [['users', 'array-contains', this.uid]] : []
    },
    userIds() {
      return this.groups.reduce((acc, g) => {
        const users = g.users || []
        acc.push(...(users || []))
        return acc
      }, [])
    },
    debounceGetConnectionStatus() {
      return debounce(this.getConnectionStatus, 250, { trailing: true, leading: false })
    },
    debounceSetUserId() {
      const self = this
      return debounce(e => (self.idToSearch = e.target.value), 500, {
        trailing: true,
        leading: false,
      })
    },
  },
  watch: {
    activeGroupId: {
      handler(id) {
        if (id) this.getConnectionStatus({ key: id })
      },
      immediate: true,
    },
  },
  methods: {
    searchOption(v, o) {
      return o.data.attrs.name.includes(v)
    },
    async getConnectionStatus(e) {
      this.loading = true
      try {
        const { data } = await api('/integrations/status', {
          groupId: e.key,
          useIndividualStatus: true,
        })
        this.connectionStatus = { ...this.connectionStatus, ...data }
      } catch (error) {
        console.error(error)
      }
      this.loading = false
    },
  },
}
</script>
<style lang="scss" scoped></style>
