<template>
  <div class="SetGroupName">
    <div class="background-colour-section">
      <div class="login-div">
        <img
          src="https://uploads-ssl.webflow.com/5ecc71a3e61d9257c9d13bbd/5ecc74713695b4ac074d6cb1_updated%20logo.png"
          width="127"
          srcset="
            https://uploads-ssl.webflow.com/5ecc71a3e61d9257c9d13bbd/5ecc74713695b4ac074d6cb1_updated%20logo-p-500.png   500w,
            https://uploads-ssl.webflow.com/5ecc71a3e61d9257c9d13bbd/5ecc74713695b4ac074d6cb1_updated%20logo-p-800.png   800w,
            https://uploads-ssl.webflow.com/5ecc71a3e61d9257c9d13bbd/5ecc74713695b4ac074d6cb1_updated%20logo-p-1080.png 1080w,
            https://uploads-ssl.webflow.com/5ecc71a3e61d9257c9d13bbd/5ecc74713695b4ac074d6cb1_updated%20logo.png        1365w
          "
          sizes="126.9937515258789px"
          alt=""
          class="static-page-image-icon"
        />
        <h2 class="heading">Your organization is almost ready!</h2>
        <div class="text-block">Choose an organisation name:</div>
        <Spinner v-if="loading" />
        <div v-else class="main-cta">
          <input
            class="org-name"
            type="text"
            v-model="newOrgName"
            placeholder="Pied Piper"
            @keyup.enter="setOrgName"
          />
          <Button
            id="SavvyNavigate-logging-in"
            class="new-savvy w-button"
            :disabled="!newOrgName"
            @click.native="setOrgName"
          >
            Let's go
          </Button>
        </div>
      </div>
      <div class="logout">
        <a @click="logout">
          <Icon name="sign-out-alt" />
          Sign out</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/helpers/api'
import { logout } from '@/firebase.js'

export default {
  components: {},
  props: {
    auto: Boolean,
  },
  data() {
    return {
      newOrgName: '',
      loading: false,
    }
  },
  computed: {
    activeGroupId() {
      return this.$store.getters.activeGroupId
    },
  },
  methods: {
    logout,
    async setOrgName() {
      if (this.newOrgName) {
        this.loading = true

        // if (this.loading) throw new Error('test')

        const res = await api('/user/complete-group-setup', {
          groupId: this.activeGroupId,
          groupName: this.newOrgName,
        })

        if (!res.data.success) {
          this.loading = false
          this.$message.error(res.data.error)
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/_variables.scss';
@import '@/components/utilities/styles/Input.scss';

.SetGroupName.main-view {
  // text-align: center;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
  color: #2d3958;
  font-size: 15px;
  line-height: 1;
  padding: 0;

  .background-colour-section {
    min-height: 100vh;
    padding-top: 100px;
    box-sizing: border-box;
    // background-color: #ebfbff;
    // background-image: url(https://uploads-ssl.webflow.com/5ecc71a…/5ecc71a…_Wave%20Blue.svg);
    background-position: 0px 0px;
    background-size: cover;
    @include flex('column');
  }

  .login-div {
    max-width: 85%;
    min-height: 400px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 0px;
  }
  .static-page-image-icon {
    display: block;
    margin-right: auto;
    margin-left: auto;
  }

  img {
    max-width: 100%;
    vertical-align: middle;
  }
  h2 {
    margin-bottom: 24px;
    line-height: 1.15;
    font-weight: 500;
    letter-spacing: -0.03em;
  }
  .heading {
    margin-top: 25px;
    font-size: 32px;
    text-align: center;
  }

  .text-block {
    font-size: 20px;
    text-align: center;
  }

  .main-cta {
    @include flex('a-stretch', 'j-center');
    margin: 20px;

    input.org-name {
      margin: 10px;
      font-size: 20px;
      padding-left: 20px;
      padding-right: 20px;
    }

    .w-button {
      @include flex('a-center', 'j-center');
      margin: 10px;
      width: 100px;
      padding: 9px 15px;
      color: white;
      border: 0;
      line-height: inherit;
      text-decoration: none;
      transition: transform 0.3s;

      &:enabled {
        cursor: pointer;

        &:hover {
          transform: translateY(-10%);
        }
      }
    }
  }
  .logout {
    flex: 1;
    text-align: center;
    @include flex('column', 'j-end');

    a {
      margin: 50px;
      cursor: pointer;

      &:hover {
        color: var(--color-savvy);
      }
    }
  }
}
</style>
