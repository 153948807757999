<template>
  <div class="AllFlows">
    <div class="title">All Flows</div>
    <div v-for="[groupId, flows] in groupsWithFlows" :key="groupId" class="group">
      <div class="header">
        {{ flows[0].groupName }}
      </div>
      <div class="group-flows">
        <div
          v-for="flow in flows"
          :key="flow.flowId"
          class="flow"
          :style="{ backgroundImage: `url(${getThumbnailUrl(flow.flowId, 100000)})` }"
        >
          <div class="title">{{ flow.flowName }}</div>
          <a :href="`/flow/${flow.flowId}`">View Standalone</a>
          <div class="in-app-buttons">
            <router-link class="link" :to="`/project/flows/${flow.flowId}?groupId=${groupId}`">
              Flow Page
            </router-link>

            <router-link class="link" :to="`/project/flows/${flow.flowId}/edit?groupId=${groupId}`">
              Edit Flow
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from '@/helpers/api'

import getThumbnailUrl from '@/components/form/editor/helpers/thumbnailUrl'

export default {
  props: {},
  data() {
    return { flowsByGroupId: {} }
  },
  computed: {
    groupsWithFlows() {
      return Object.entries(this.flowsByGroupId).sort((a, b) =>
        a[1][0].groupName.localeCompare(b[1][0].groupName)
      )
    },
  },
  async mounted() {
    const { data } = await api('/public/flows', undefined, { noAuth: true })
    this.flowsByGroupId = data
  },
  watch: {},
  methods: {
    getThumbnailUrl,
    updateActiveGroup(url) {
      this.$router.push(url)
    },
  },
}
</script>
<style lang="scss" scoped>
.AllFlows {
  display: flex;
  flex-direction: column;
  .title {
    font-size: 3em;
    padding-left: 15px;
  }
  .group {
    font-size: 1.5em;
    .header {
      padding-left: 15px;
    }
    .group-flows {
      display: flex;
      flex-direction: row;
      width: 100vw;
      flex-wrap: wrap;

      .flow {
        /* border: 1px solid black; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 720px;
        height: 500px;
        /* cursor: pointer; */
        padding: 10px;
        margin: 10px;
        border: 1px solid grey;
        border-radius: 5px;
        background-color: white;
        background-size: contain;
        background-position: top center;
        background-repeat: no-repeat;

        .title {
          background-color: white;
          color: blue;
          font-size: 18px;
        }

        > a {
          margin: 10px;
          padding: 10px;
          border: 1px solid grey;
          border-radius: 5px;
          padding: 5px 10px;
          cursor: pointer;
          font-size: 12px;
          &:hover {
            background: #f9fafc;
          }
        }
        div.in-app-buttons {
          display: flex;
          flex-direction: row;
          width: 100%;
          justify-content: space-around;
          font-size: 12px;
          > .link {
            border: 1px solid grey;
            border-radius: 5px;
            padding: 5px 10px;
            cursor: pointer;
            &:hover {
              background: #f9fafc;
            }
          }
        }

        > *,
        div.in-app-buttons {
          display: none;
        }
      }
    }
  }
}
</style>
