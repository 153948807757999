<template>
  <div class="TagsEditor">
    <EditorLabel :label="`${type} Tags`" />
    <template v-for="tag in tags">
      <a-tooltip v-if="tag.length > 20" :key="tag" :title="tag">
        <a-tag :key="tag" closable @close="() => handleClose(tag)">
          {{ `${tag.slice(0, 20)}...` }}
        </a-tag>
      </a-tooltip>
      <a-tag v-else :key="tag" closable @close="() => handleClose(tag)">
        {{ tag }}
      </a-tag>
    </template>
    <a-auto-complete v-if="inputVisible" placeholder="New Tag" @select="handleInputConfirm">
      <template slot="default">
        <!-- :defaultValue="key" -->
        <a-input
          size="small"
          ref="input"
          style="width: 100px; line-height: 1; padding: 0 4px; margin: 2px; height: 22px; font-size: 12px;"
          v-model="inputValue"
          @blur="handleInputConfirm(inputValue)"
          @keyup.enter="handleInputConfirm(inputValue)"
        />
      </template>
      <template slot="dataSource">
        <a-select-option v-for="{ tag } in tagOptions" :key="tag" :value="tag">
          {{ tag }}
        </a-select-option>
      </template>
    </a-auto-complete>
    <a-tag v-else class="add-new" @click="showInput">
      <a-icon type="plus" /> {{ small ? '' : 'New Tag' }}
    </a-tag>
  </div>
</template>

<script>
import { toSnake } from '@/helpers/textStringConversions'

import EditorMixin from './helpers/EditorMixin'

export default {
  name: 'TagsEditor',
  mixins: [EditorMixin],
  inject: ['_getBasePath', '_updateData', '_getAllTags'],
  props: {
    small: Boolean,
    type: String,
  },
  data() {
    return {
      inputVisible: false,
      inputValue: '',
    }
  },
  computed: {
    pathCalc() {
      // Useful in case component wants to replace this prop to add to path (e.g. add .tags on the end)
      return this.path ? `${this.path}.tags` : 'tags'
    },
    tags: {
      get() {
        return this.val || []
      },
      set(tags) {
        this.val = tags
      },
    },
    allTags() {
      return this._getAllTags()
    },
    tagOptions() {
      const tagsInUse = new Set(this.tags)
      const tags = this.allTags.filter(t => {
        const typeMatch = this.type ? this.type === t.type : true
        const textMatch = this.inputValue ? t.tag.includes(this.inputValue) : true
        return typeMatch && !tagsInUse.has(t.tag) && this.inputValue !== t.tag && textMatch
      })

      return [{ tag: this.inputValue }, ...tags].filter(t => t && t.tag)
    },
  },
  methods: {
    handleClose(removedTag) {
      const tags = this.tags.filter(tag => tag !== removedTag)
      this.tags = tags
    },
    async showInput() {
      this.inputVisible = true
      await this.$nextTick()
      await this.$nextTick()
      this.$refs.ele.$refs.input.focus()
    },
    handleInputChange(e) {
      this.inputValue = e.target.value
    },
    handleInputConfirm(tag) {
      const inputValue = toSnake(tag)
      let tags = this.tags
      if (inputValue && tags.indexOf(inputValue) === -1) {
        tags = [...tags, inputValue]
      }
      Object.assign(this, {
        tags,
        inputVisible: false,
        inputValue: '',
      })
    },
  },
}
</script>

<style lang="scss">
.TagsEditor .ant-tag {
  font-family: monospace;

  &:not(.add-new) {
    margin-bottom: 8px;
  }
  &.add-new {
    background: #fff;
    border-style: dashed;
    cursor: pointer;
    font-family: inherit;

    &:hover {
      background: #f9f9f9;
    }
  }
}
</style>
