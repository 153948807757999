<template>
  <div
    class="EditorHeading font-bold text-base text-gray-500 mt-6 mb-1 flex items-center"
    :class="{ 'whitespace-nowrap': noWrap }"
  >
    <div class="px-2 border-b-2 border-gray-400 mr-2"></div>
    <Icon v-if="icon" class="mr-2" :name="icon" />
    <template v-if="title">{{ title }}</template>
    <slot v-else />
    <div class="flex-1 border-b-2 border-gray-400 ml-2"></div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    noWrap: Boolean,
    icon: String,
  },
}
</script>
