import Moment from 'moment'

export default getFilters()

function getFilters() {
  const allFilters = getAllFilters()
  const filterNames = Object.keys(allFilters)

  return filterNames.map(name => ({
    name,
    function: allFilters[name],
  }))
}

// FILTERS

function getAllFilters() {
  return {
    naIfMissing(value) {
      return value ? value : 'N/A'
    },
    caps(text) {
      return text
        .split(' ')
        .map(t => t[0].toUpperCase() + t.slice(1).toLowerCase())
        .join(' ')
    },
    formatDate(date, format) {
      // const format = 'relative' || 'date-relative' || 'hybrid'

      const momentFormat = getMomentString(format)

      if (momentFormat === 'calendar') {
        return Moment(date).calendar()
      }
      if (momentFormat.startsWith('rel-')) {
        return `${Moment(date).format(momentFormat.slice(4))}, ${Moment(date).fromNow()}`
      }
      if (momentFormat === 'relative') {
        return Moment(date).fromNow()
      }
      return Moment(date).format(momentFormat)
    },
    commas(value) {
      const string = value.toString()

      if (value > 999999) {
        return string.slice(0, -6) + ',' + string.slice(-6, -3) + ',' + string.slice(-3)
      } else if (value > 999) {
        return string.slice(-6, -3) + ',' + string.slice(-3)
      } else {
        return string
      }
    },
    fromNow(timestamp, neverText, transform) {
      if (timestamp && typeof timestamp !== 'number' && timestamp.seconds)
        timestamp = timestamp.seconds * 1000

      if (!transform) transform = t => t

      return (
        (timestamp && transform(Moment(timestamp).fromNow())) ||
        neverText ||
        'Never'
      ).replace('minutes', 'mins')
    },
    timeSimpleDate(timestamp, neverText, transform) {
      if (timestamp && typeof timestamp !== 'number' && timestamp.seconds)
        timestamp = timestamp.seconds * 1000

      if (!transform) transform = t => t

      const relativeDay =
        Moment(timestamp) > Moment().startOf('day')
          ? 'Today'
          : Moment(timestamp) >
            Moment()
              .subtract(1, 'day')
              .startOf('day')
          ? 'Yesterday'
          : null

      const formatted =
        timestamp &&
        (relativeDay
          ? `${relativeDay} at ${Moment(timestamp).format('h:mma')}`
          : Moment(timestamp).format('ddd MMM D [at] h:mma'))

      return (formatted && transform(formatted)) || neverText || 'Never'
    },
    scoreReadable(score, type) {
      if (!score) return 'No Match'
      if (!type) type = 'full'

      const percentScore = Math.round(score * 5 + 50)

      switch (type) {
        case 'full':
          return `${percentScore}% Match`
        case 'percent':
          return `${percentScore}%`
        default:
          return percentScore
      }
    },
    countText(text, arrOrNumber) {
      if (!arrOrNumber) return ''
      const number = typeof arrOrNumber === 'number' ? arrOrNumber : arrOrNumber.length
      if (number > 1) return '' + number + ' ' + makePlural(text)
      else return '' + number + ' ' + text
    },
    maxLength(text, length) {
      if (!length) length = 50

      return text.length > length ? text.slice(0, length).trim() + '...' : text
    },
  }
}

// HELPERS

function getMomentString(format) {
  switch (format) {
    case 'absolute':
      return 'MMMM Do, h:mm:ss a'
    case 'hybrid':
      return 'rel-MMM Do'
    case 'calendar':
    default:
      return format
  }
}

function makePlural(text) {
  if (text.slice(-1)[0] === 'y') {
    return text.slice(0, -1) + 'ies'
  }

  switch (text) {
    case 'sheep':
      return text
    default:
      return text + 's'
  }
}
