export function getThreadsFromComments(comments, pages) {
  const commentsFiltered = comments
    .map(c => ({
      ...c,
      id: c.id,
      pageIndex: pages ? pages.findIndex(p => p.id === c.location.page) : undefined,
    }))
    .filter(c => !c.archived)

  const commentsSorted = commentsFiltered.sort(
    (a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
  )

  return commentsSorted
    .filter(comment => !comment.threadId)
    .filter(comment => !comment.archived)
    .map(comment => ({
      id: comment.id,
      version: comment.version,
      location: comment.location,
      page: comment.page,
      pageIndex: comment.pageIndex,
      comments: [comment, ...commentsSorted.filter(c => c.threadId === comment.id).reverse()],
    }))
}

export function getThreadsFilteredFn(threads, currentVersion) {
  return function({ version = currentVersion, page, resolved, byPage }) {
    let t = threads

    if (version) t = t.filter(t => currentVersion >= t.version)
    if (page) t = t.filter(t => t.location.page === page)
    if (typeof resolved === 'boolean')
      t = t.filter(thread => (resolved ? threadIsResolved(thread) : !threadIsResolved(thread)))

    if (byPage) t = t.slice().sort((a, b) => a.pageIndex - b.pageIndex)

    return t
  }
}

function threadIsResolved(thread) {
  const assignedComments = thread.comments.filter(c => c.assignee)

  return assignedComments.length
    ? assignedComments.every(c => c.resolved)
    : thread.comments[0].resolved
}
