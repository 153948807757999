<template>
  <div class="Integrations">
    <a-page-header>
      <template slot="title">
        <a-space size="middle" align="center">
          <Icon type="fad" name="cogs" />
          <div>Integrations</div>
        </a-space>
      </template>
    </a-page-header>
    <div class="integrations-container mx-4" ref="integrations">
      <activepieces-marketplace> </activepieces-marketplace>
    </div>
  </div>
</template>
<script>
import Firebase from 'firebase/app'
import 'firebase/auth'
// import { loginToXkit } from '@/boot-up/initServices'

export default {
  name: 'Integrations',
  props: {
    reNavigating: Date,
  },
  watch: {
    reNavigating() {
      this.forceRouteUpdate(this.$route)
    },
  },
  async mounted() {
    loadActivePieces()
    try {
      // await loginToXkit()
    } catch (error) {
      console.error('Failed to login to Xkit')
    }

    // keep the catalog on the component so we can push
    // route changes to it.
    // const filteredConnections = new Set([
    //   'facebook-ads',
    //   'google-ads',
    //   'google-analytics',
    //   'google-search-console',
    //   // 'google-sheets',
    //   'postmark',
    //   'salesforce',
    //   'stripe',
    //   'webflow',
    //   'zoom-admin',
    // ])
    // this.catalog = window.xkit.renderCatalog(this.$refs.integrations, {
    //   hideTitle: true,
    //   rootPath: this.$route.matched.slice(-1)[0].path.split('*')[0],
    //   routerType: this.$store.state.isInElectron ? 'memory' : 'browser',
    //   filter: c => !filteredConnections.has(c.slug),
    // })
  },
  beforeDestroy() {
    window.xkit.unmountCatalog(this.$refs.integrations)
    this.catalog = undefined
  },
  beforeRouteUpdate(route) {
    this.forceRouteUpdate(route)
  },
  methods: {
    forceRouteUpdate(route) {
      if (!this.catalog) {
        return
      }
      // Remove any parts of the path not relevant to
      // the catalog
      const rootPath = this.$route.matched.slice(-1)[0].path.split('*')[0]
      if (!route.path.startsWith(rootPath)) {
        return
      }
      const path = route.path.slice(rootPath.length)
      const pathWithLeadingSlash = path[0] === '/' ? path : `/${path}`
      this.catalog.pushHistory(pathWithLeadingSlash)
    },
  },
}

async function loadActivePieces() {
  loadActualActivepiecesSdk()
  var loadSdkPromise
  function loadActualActivepiecesSdk() {
    loadSdkPromise = new Promise((resolve, reject) => {
      const scriptTag = document.createElement('script')
      scriptTag.src = 'https://cdn.activepieces.com/embedded/v1/activepieces-sdk.js'
      scriptTag.onload = () => {
        setActivepiecesInWindow()
        resolve()
      }
      scriptTag.onerror = err => {
        reject(err)
      }
      scriptTag.async = true
      document.head.append(scriptTag)
    })
  }
  function setActivepiecesInWindow() {
    const apSdk = document.createElement('activepieces-sdk')
    document.body.append(apSdk)
    activepieces = apSdk
  }
  var activepieces = {}
  const methodNames = [
    'init',
    'configureIntegration',
    'listIntegrations',
    'disableIntegration',
    'getProject',
    'isIntegrationEnabled',
    'insertCollectionWidget',
    'insertMarketplaceWidget',
    'sendEvent',
    'runFlow',
    'on',
  ]
  for (const method of methodNames) {
    activepieces[method] = (...args) => {
      return new Promise((resolve, reject) => {
        loadSdkPromise.then(
          () => {
            activepieces[method](...args).then(
              val => resolve(val),
              err => reject(err)
            )
          },
          err => reject(err)
        )
      })
    }
  }

  const token = await Firebase.auth().currentUser.getIdToken()
  console.log('token', token)

  activepieces.init({
    projectId: '48e93fd4-bce2-4c31-ba89-5961060ed090',
    environment: 'production',
    token,
    styles: { primaryColor: '#6454f3' },
  })
}
</script>

<style lang="scss" scoped>
@import '@/styles/_variables.scss';

.Integrations {
  &:not(.main-view) {
    .ant-page-header {
      display: none;
    }
  }
  &.main-view {
    .integrations-container {
      margin: -10px 10px 10px;
      padding: 0 20px;
    }
  }
}
</style>
