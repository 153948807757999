var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"root",staticClass:"EditorToolbar flex justify-center",class:[_vm.name, _vm.anchored ? 'w-full' : 'fixed'],style:(_vm.styleText)},[_c('div',{staticClass:"h-8 flex items-stretch shadow-lg border border-gray-100 bg-white rounded-full"},_vm._l((_vm.toolbarOptions),function(option,index){return _c('a-tooltip',{key:option.key,attrs:{"title":option.label}},[_c(option.componentType,_vm._b({tag:"component",staticClass:"px-3 hover:text-purple hover:bg-gray-100",class:[
          option.class,
          {
            'pl-4': index === 0,
            'pr-4': index === _vm.toolbarOptions.length - 1,
            'rounded-l-full': index === 0,
            'rounded-r-full': index === _vm.toolbarOptions.length - 1,
            'bg-gray-200': option.active,
            'text-purple': option.active,
          } ],style:(option.type === 'editor_select' ? 'padding: 0;' : ''),attrs:{"component":_vm.currentComponent},on:{"update":function (ref) {
                var key = ref[0];
                var val = ref[1];

                return _vm.onUpdate(key, val);
}}},'component',option,false))],1)}),1)])}
var staticRenderFns = []

export { render, staticRenderFns }