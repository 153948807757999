<template>
  <div class="ContainerEditor">
    <template v-if="location === 'top'">
      <EditorSelect _key="container_type" defaultVal="container" :options="containerTypes" />
    </template>
    <template v-if="location === 'options' || !location">
      <a-space direction="vertical">
        <EditorLabel label="Child Components" />
        <ComponentLink
          v-for="(component, index) in children"
          :key="component.key || component.id"
          :id="component.id"
          :hidden="hiddenComponents.has(component.id)"
        >
          <div class="flex-grow">
            <span class="font-mono text-xs">{{
              component.key || `Untitled Component ${index + 1}`
            }}</span>
            {{ hiddenComponents.has(component.id) ? ' (Hidden)' : '' }}
          </div>
        </ComponentLink>
      </a-space>
      <template v-if="component.container_type === 'carousel'">
        <EditorCheckbox _key="autoplay" />
        <EditorCheckbox _key="arrows" defaultTrue label="Show Navigation Arrows" />
        <EditorCheckbox _key="dots" defaultTrue label="Show Navigation Dots" />
        <EditorCheckbox _key="infinite" defaultTrue label="Infinite Scroll" />
        <EditorInput _key="visible_slides" type="number" label="Slides to show at once" />
        <EditorCheckbox _key="center_mode" label="Use Center Mode" />
        <EditorInput
          v-if="component.center_mode"
          _key="center_padding"
          label="Padding around Center Mode"
          placeholder="50px"
        />
      </template>
    </template>
  </div>
</template>

<script>
export default {
  name: 'ContainerEditor',
  inject: {
    _onSelectComponent: { default: () => () => {} },
    _getSiblingOptions: { default: () => () => {} },
  },
  props: {
    componentId: String,
    component: Object,
    location: String,
    form: Object,
    page: Object,
  },
  computed: {
    containerTypes() {
      return ['container', 'columns', 'rows', 'carousel']
    },
    hiddenComponents() {
      return new Set(
        this._getSiblingOptions()
          .filter(c => c.hidden)
          .map(c => c.id)
      )
    },
    componentIsGlobal() {
      return Boolean((this.form.components || []).find(c => c.id === this.component.id))
    },
    children() {
      if (!this.component.key) return []
      const components = this.componentIsGlobal
        ? this.form.components || []
        : this.page.components || []
      return components.filter(c => c.parent_key === this.component.key)
    },
  },
}
</script>
