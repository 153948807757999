<template>
  <div class="PageHeadingLabelEditor">
    <a-space direction="vertical">
      <template v-if="location === 'options' || !location">
        <EditorInput
          allowComputed
          _key="headingLabel"
          placeholder="Page Title"
          v-bind="{ component }"
        />
        <div class="flex items-center flex-wrap gap-4">
          <EditorCheckbox _key="headingLabelRich" label="Rich Text" />
        </div>
      </template>
    </a-space>
  </div>
</template>

<script>
export default {
  name: 'PageHeadingLabelEditor',
  provide() {
    return { _getBasePath: () => `pages.${this.pageIndex}` }
  },
  props: {
    page: Object,
    form: Object,
    component: Object,
    location: String,
  },
  computed: {
    pageIndex() {
      return this.form.pages.findIndex(p => p.id === this.page.id)
    },
  },
}
</script>
