<template>
  <div class="TransitionEditor">
    <EditorSelect
      :_key="name"
      defaultVal="no-transition"
      tooltip="Transition Name"
      :options="transitionOptions"
      class="flex-grow"
    >
      <a-tooltip title="Use Global Transition">
        <a-button
          :type="useGlobal ? 'primary' : 'default'"
          icon="global"
          @click="useGlobal = !transition[`individual-${name}`]"
        />
      </a-tooltip>
      <a-button v-if="!useGlobal" icon="stop" @click="removeTransition('remove')" />
    </EditorSelect>
  </div>
</template>

<script>
import get from 'lodash/get'
/* Note - 
still have to apply transition group or transition around the corresponding component
and give it a key so that it re-renders properly */
export default {
  name: 'TransitionEditor',
  provide() {
    return { _getBasePath: () => (this.useGlobal ? `defaults.transitions` : this.basePath) }
  },
  inject: ['_getBasePath', '_updateData'],
  props: {
    form: Object,
    name: String,
  },
  computed: {
    transition() {
      return get(this.form, this.basePath, {})
    },
    useGlobal: {
      get() {
        const key = `individual-${this.name}`
        return !this.transition[key]
      },
      set(v) {
        this._updateData(`${this._getBasePath()}.transition`, `individual-${this.name}`, v)
      },
    },
    transitionOptions() {
      return ['no-transition', 'rise-and-fade', 'rise-and-fade-in', 'rise-and-fade-delay']
    },
    basePath() {
      return `${this._getBasePath()}.transition`
    },
  },
  methods: {
    removeTransition() {
      this._updateData(this.basePath, `individual-${this.name}`, null)
    },
  },
}
</script>
