<template>
  <div class="CalculatedBlockEditor">
    <a-space direction="vertical">
      <EditorSelect
        label="Select Calculation Formula"
        _key="calculation"
        :options="['homestead_savings']"
      />
      <EditorSelect multiple label="Input Values (In Order)" _key="inputs" :options="keys" />
    </a-space>
  </div>
</template>

<script>
import { unpack } from '@/helpers/computed'

export default {
  name: 'CalculatedBlockEditor',
  props: {
    componentId: String,
    component: Object,
    form: Object,
  },
  computed: {
    ...unpack('component', ['calculation', 'inputs']),
    options() {
      return this.form.pages.reduce(
        (options, page) =>
          options.concat(
            page.components
              .filter(c => c.type === 'OptionButtons' || c.type === 'OptionSelector')
              .map(({ id, key, buttons, multiple }) => ({
                key: key || id,
                options: buttons.map(b => b.text),
                multiple,
              }))
          ),
        []
      )
    },
    keys() {
      return this.options.map(o => o.key)
    },
  },
}
</script>
