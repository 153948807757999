<template>
  <div
    class="EditorLabel text-xs uppercase font-bold text-gray-400 mt-2 mb-1"
    :class="{ 'whitespace-nowrap': noWrap }"
  >
    <Icon v-if="icon" class="mr-2" :name="icon" />
    <template v-if="label">{{ label }}</template>
    <slot v-else />
    <template v-if="!noColon">:</template>
    <a-tooltip v-if="hasTooltip">
      <Icon class="ml-2" name="info-circle" />
      <template slot="title">
        <template v-if="tooltip">{{ tooltip }}</template>
        <slot v-else name="tooltip" />
      </template>
    </a-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    noColon: Boolean,
    noWrap: Boolean,
    icon: String,
    tooltip: String,
  },
  computed: {
    hasTooltip() {
      return Boolean(this.tooltip || this.$scopedSlots.tooltip)
    },
  },
}
</script>
