<template>
  <div class="ConditionEditor">
    <a-space direction="vertical">
      <div class="flex justify-between -mr-2">
        <div>Show this {{ location }} if:</div>
        <a-tag closable @close="$emit('remove')"> Condition {{ index + 1 }} </a-tag>
      </div>
      <a-select
        showSearch
        placeholder="Select a Property"
        :default-value="key || undefined"
        style="width: 100%;"
        @change="$emit('update', { ...condition, key: $event })"
      >
        <a-select-option v-for="_key in keys" :key="_key" :value="_key">
          {{ _key }}
        </a-select-option>
      </a-select>
      <template v-if="key">
        <a-select
          showSearch
          :key="`${key}-operator`"
          placeholder="Select an Operator"
          :default-value="operator"
          style="width: 100%;"
          @change="$emit('update', { ...condition, operator: $event })"
        >
          <a-select-option v-for="op in possibleOperators" :key="op" :value="op">
            {{ getOperatorText(op) }}
          </a-select-option>
        </a-select>
        <template v-if="showValues">
          <!-- <a-input
            v-if="optionIsInput"
            :value="(condition.values && condition.values[0]) || ''"
            @change="$emit('update', { ...condition, values: [$event.target.value] })"
          >
          </a-input>
            v-else -->
          <a-select
            showSearch
            :key="`${key}-value`"
            placeholder="Select a Value"
            :default-value="values && values.length ? values : []"
            mode="tags"
            allowClear
            style="width: 100%;"
            @change="onChange"
          >
            <a-select-option v-for="value in possibleValues" :key="value" :value="value">
              {{ getValueText(value) }}
            </a-select-option>
          </a-select>
        </template>
        <a-alert v-for="warning in warnings" :key="warning" :message="warning" banner />
      </template>
    </a-space>
  </div>
</template>

<script>
import { unpack } from '@/helpers/computed'

export default {
  name: 'ConditionEditor',
  props: {
    form: Object,
    condition: Object,
    index: Number,
    keys: Array,
    options: Array,
    optionOperators: Object,
    optionValues: Object,
    location: String,
  },
  computed: {
    ...unpack('condition', ['key', 'operator', 'values']),
    selectedOption() {
      const option = this.options.find(o => o.key === this.key)
      return option ? option : null
    },
    possibleOperators() {
      return this.optionOperators[this.key] || []
    },
    optionIsInput() {
      return (
        (this.selectedOption && ['InputBox', 'computed'].includes(this.selectedOption.type)) ||
        false
      )
    },
    possibleValues() {
      const values = this.optionValues[this.key] || []
      // '_not_true', '_not_false'
      return [...values, '_no_value', '_true', '_false']
    },
    showValues() {
      return !['exists', '!exists', 'is-true', 'is-false', 'is-not-true', 'is-not-false'].includes(
        this.operator
      )
    },
    warnings() {
      if (this.optionIsInput) return []
      const valueWarnings =
        (Array.isArray(this.values) &&
          this.values
            .filter(value => !this.possibleValues.includes(value))
            .map(
              value =>
                `"${value}" is not listed as an option in the "${this.key}" field - is this Condition out of date?`
            )) ||
        []
      const keyWarnings =
        this.key && Array.isArray(this.keys) && !new Set(this.keys).has(this.key)
          ? `"${this.key}" is not a key in the current form - is this Condition out of date?`
          : ''
      return [...valueWarnings, keyWarnings].filterExists()
    },
  },
  watch: {
    key(key) {
      if (key && !this.possibleOperators.includes(this.operator))
        this.$emit('update', { ...this.condition, operator: this.possibleOperators[0] })
    },
  },
  methods: {
    onChange(event) {
      const convertValues = v => {
        if (typeof v === 'string') {
          if (v.startsWith('+')) return Number(v)
          if (v.startsWith('\\+')) return v.slice(1)
        }
        return v
      }
      const values = Array.isArray(event) ? event.map(convertValues) : event
      this.$emit('update', { ...this.condition, values })
    },
    getValueText(val) {
      switch (val) {
        case '_no_value':
          return 'No Value'
        case '_true':
          return 'True'
        case '_false':
          return 'False'
        case '_not_true':
          return 'Not True'
        case '_not_false':
          return 'Not False'
        case '_empty':
          return 'Empty'
        case '_!empty':
          return 'Not Empty'

        default:
          return val
      }
    },
    getOperatorText(op) {
      switch (op) {
        case '==':
          return 'Is / Includes'
        case '!=':
          return 'Is Not / Does Not Include'
        case '!exists':
          return 'Does Not Exist'

        default:
          return op
            ? op
                .split('-')
                .map(w => w[0].toUpperCase() + w.slice(1))
                .join(' ')
            : op
      }
    },
  },
}
</script>
