export default function getPresetConfig(id) {
  return getPresetConfigs()[id] || {}
}

export function getPresetConfigs() {
  return {
    preset_visitors_over_time_day: {
      grouping: 'date',
      'date-interval': 'day',
      'savvy-preset': 'visitors',
      service: 'savvy',
      title: 'Visitors by day',
    },
    preset_visitors_over_time_week: {
      grouping: 'date',
      'date-interval': 'week',
      'savvy-preset': 'visitors',
      service: 'savvy',
      title: 'Visitors by day',
    },
    preset_visitors_by_city: {
      grouping: 'non-date',
      'savvy-breakdown': 'cities',
      'savvy-preset': 'visitors',
      service: 'savvy',
      title: 'Visitors by city',
    },
    preset_visitors_by_state: {
      grouping: 'non-date',
      'savvy-breakdown': 'regions',
      'savvy-preset': 'visitors',
      service: 'savvy',
      title: 'Visitors by state',
    },
    preset_funnel: {
      'combo-preset': 'funnel',
      'date-interval': 'month',
      funnelId: 'default_funnel',
      service: 'combo',
      title: 'Your funnel',
    },
    preset_popular_pages: {
      grouping: 'non-date',
      'savvy-breakdown': 'pages',
      'savvy-preset': 'visitors',
      service: 'savvy',
      title: 'Popular pages',
    },
    preset_visitors_by_source: {
      'google-analytics-breakdown': 'browser',
      grouping: 'non-date',
      'savvy-breakdown': 'referrers',
      'savvy-preset': 'visitors',
      service: 'savvy',
      title: 'Visitors by source',
    },
    preset_flow_breakdown: {
      'date-interval': 'month',
      grouping: 'non-date',
      'savvy-breakdown': 'flows',
      'savvy-flow-breakdown': '3GE6xyoY5WUU9D1IriYR - service',
      'savvy-preset': 'visitors',
      service: 'savvy',
      title: 'People completed flows',
    },
  }
}
