<template>
  <div class="EditorCode">
    <a-space direction="vertical">
      <a-button
        v-if="!hideDataPath && _openDataPath"
        type="primary"
        icon="edit"
        class="w-full"
        @click="_openDataPath({ path: `${basePath}.${jsxKey || _key}`, code: true, title: _key })"
      >
        Edit JS Code
      </a-button>
      <PrismEditor class="my-editor" :highlight="highlighter" line-numbers v-model="codeLocal" />
      <a-space>
        <a-button
          size="small"
          :type="isSaved ? '' : 'primary'"
          :disabled="isSaved"
          icon="save"
          @click="save"
          >{{ isSaved ? 'Updated' : 'Update' }}</a-button
        >
        <a-button v-if="!isSaved" size="small" icon="stop" @click="codeLocal = val"
          >Cancel</a-button
        >
      </a-space>
    </a-space>
  </div>
</template>

<script>
import { PrismEditor } from 'vue-prism-editor'
import 'vue-prism-editor/dist/prismeditor.min.css' // import the styles somewhere
// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from 'prismjs/components/prism-core'
import 'prismjs/components/prism-clike'
import 'prismjs/components/prism-javascript'
import 'prismjs/themes/prism-tomorrow.css' // import syntax highlighting styles

import EditorMixin from './EditorMixin'

export default {
  name: 'EditorCode',
  mixins: [EditorMixin],
  inject: { _openDataPath: { default: () => false } },
  components: {
    PrismEditor,
  },
  props: {
    codeDraft: String,
    hideDataPath: Boolean,
  },
  data() {
    return {
      codeLocal: null,
    }
  },
  computed: {
    isSaved() {
      return this.codeLocal === this.val
    },
  },
  watch: {
    val: {
      handler(val) {
        this.codeLocal = val
      },
      immediate: true,
    },
    codeDraft: {
      handler(codeDraft) {
        if (codeDraft) this.codeLocal = codeDraft
      },
    },
  },
  methods: {
    update(update) {
      this._updateData(this.basePath, ...update)
    },
    highlighter(code) {
      // return code
      return highlight(code, languages.js) // languages.<insert language> to return html with markup
    },
    save() {
      this._updateData(this.basePath, this._key, this.codeLocal)
    },
  },
}
</script>

<style lang="scss">
/* required class */
.my-editor,
.prism-editor__line-numbers {
  font-size: 11px !important;
  line-height: 1.6em !important;
}

.my-editor {
  border-radius: 4px;
  /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
  background: #2d2d2d;
  color: #ccc;

  /* you must provide font-family font-size line-height. Example: */
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
}

/* optional class for removing the outline */
.prism-editor__textarea:focus {
  outline: none;
}
</style>
