<template>
  <div class="FeatureFlags">
    <Entities renderless collection="users" v-model="users" />
    <Entities renderless collection="groups" v-model="groups" />
    <table>
      <tr>
        <th>
          <input type="text" placeholder="Filter users..." v-model="textFilter" />
        </th>
        <th v-for="feature in features" :key="feature">
          <span>
            {{ feature.split('_').join(' ') }}
          </span>
        </th>
      </tr>
      <tr v-for="user in usersFiltered" :key="user.id">
        <td>{{ user.name }} {{ user.groupNames ? `(${user.groupNames})` : '' }}</td>
        <td v-for="feature in features" :key="feature">
          <FeatureFlag v-bind="{ user, feature }" />
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import FeatureFlag from '@/components/FeatureFlag.vue'

export default {
  name: 'FeatureFlags',
  components: {
    FeatureFlag,
  },
  data() {
    return {
      users: [],
      groups: [],
      textFilter: '',
    }
  },
  computed: {
    usersFiltered() {
      return this.users
        .map(u => ({
          ...u,
          name: u.displayName || u.email || (u.auth && u.auth.email),
          groupNames: this.groups
            .filter(g => (g.users || []).includes(u.id))
            .map(g => g.name)
            .join(', '),
        }))
        .filter(u => u.name)
        .filter(
          u =>
            !this.textFilter ||
            (u.name || '').toLowerCase().includes(this.textFilter.toLowerCase()) ||
            (u.groupNames || '').toLowerCase().includes(this.textFilter.toLowerCase())
        )
        .slice()
        .sort((a, b) => b.name - a.name)
        .sort((a, b) => (b.uid === this.$store.getters.userId ? 1 : -1))
    },
    features() {
      return [
        'command_bar',
        'homepage_dashboard',
        'tracking_links',
        'pipelines',
        'event_tracking',
        'daily_notifications',
        'projects',
        'top_nav',
        'import_contacts',
        'new_integrations',
      ]
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/_variables.scss';

.FeatureFlags {
  > table {
    text-align: left;
    min-width: 100%;
    overflow-x: scroll;

    tr {
      th:not(:first-child) {
        text-transform: capitalize;
        height: 100px;

        > span {
          width: 50px;
          display: block;
          transform-origin: 0 left;
          transform: translateX(1em) translatey(1em) rotate(-60deg);
          white-space: nowrap;
        }
      }
      td {
        padding: 5px 10px;
        border-bottom: 1px solid #ddd;

        &:not(:last-child) {
          border-right: 1px solid #ddd;
        }
      }
    }
  }
}
</style>
