<template>
  <div class="OptionSelectorEditor">
    <a-space direction="vertical">
      <!-- <a-space>
        <EditorIcon _key="icon" v-bind="{ component }" />
        <EditorInput _key="label" v-bind="{ component }" />
      </a-space> -->
      <template v-if="location === 'basic_options' || !location">
        <EditorCheckbox _key="dropdown" label="Dropdown" v-bind="{ component }" />
        <EditorCheckbox _key="multiple" label="Allow Multiple" v-bind="{ component }" />
        <EditorCheckbox
          v-if="!dropdown"
          _key="checkbox"
          label="Include Checkbox in Button Design"
          v-bind="{ component }"
        />
        <EditorSelect
          v-if="component.checkbox"
          _key="checkbox_location"
          :options="checkboxOptions"
          v-bind="{ component }"
        />
        <EditorCheckbox
          v-if="!component.top_keys"
          _key="randomize_buttons"
          label="Shuffle Options"
          v-bind="{ component }"
        />
      </template>

      <template v-if="location === 'content' || !location">
        <EditorHeading icon="paragraph" title="Content" />
        <EditorInput allowComputed _key="label" v-bind="{ component }" />
        <EditorCheckbox _key="label_rich" label="Label: Rich Text" />
        <EditorCheckbox _key="richText" label="Button Texts: Rich Text" />
        <EditorCheckbox _key="richDescription" label="Button Descriptions: Rich Text" />
        <template v-if="dropdown">
          <EditorInput _key="placeholder" v-bind="{ component }" />
          <EditorCheckbox defaultTrue _key="allow_typing" v-bind="{ component }" />
          <EditorCheckbox defaultTrue _key="clearable" v-bind="{ component }" />
          <template v-if="allow_typing !== false">
            <EditorInput _key="no_results_text" v-bind="{ component }" />
            <EditorInput _key="search_prompt_text" v-bind="{ component }" />
          </template>
        </template>

        <!-- <EditorSelect
        _key="store_data_type"
        :defaultVal="fetch_options ? 'full_object' : 'key'"
        :options="['key', /*'text',*/ 'full_object']"
      /> -->
      </template>
      <template v-if="location === 'options' || !location">
        <EditorHeading icon="fad:th-large" title="Buttons" />
        <a-tooltip :title="fetch_options ? `You're fetching options instead from an API` : ''">
          <EditorSelect
            placeholder="Select Preset Buttons"
            _key="preset_buttons"
            :disabled="fetch_options"
            :options="presetButtonOptions"
          />
        </a-tooltip>
        <Draggable
          v-if="showAll || buttons.length <= 5"
          v-model="buttons"
          group="people"
          handle=".handle"
        >
          <!-- <div v-for="element in myArray" :key="element.id">{{element.name}}</div> -->
          <OptionSelectorButtonEditor
            v-for="(button, index) in buttons"
            :key="button.id"
            :buttonIndex="index"
            :isDuplicate="dupeOptions.has(button.key)"
            v-bind="{ button, component }"
            @remove="removeButton(index)"
          />
        </Draggable>
        <a-button v-if="buttons.length > 5 && !showAll" @click="showAll = true"
          >Show all {{ buttons.length }} buttons</a-button
        >

        <a-button type="primary" class="w-full" icon="plus" @click="newButton">
          New Button
        </a-button>
        <a-button size="small" icon="plus" @click="addOtherOption">Add 'Other' option</a-button>

        <div class="mt-4">
          Add Buttons in Bulk:
        </div>
        <textarea
          style="width: 100%;"
          rows="3"
          placeholder="Just button text (no descriptions, images etc), each on a separate line"
          v-model="bulkNewButtons"
        />
        <a-button
          size="small"
          icon="plus"
          :disabled="!bulkNewButtons || !bulkNewButtons.trim()"
          @click="addBulkButtons"
          >Create Buttons in Bulk >
        </a-button>
      </template>

      <template v-if="location === 'design-footer' || !location">
        <EditorHeading icon="caret-down" title="Other Design Options" />
        <EditorSelect
          v-if="!dropdown"
          class="mb-8"
          placeholder="Select Layout"
          _key="layout"
          :options="layoutOptions"
        />
      </template>

      <template v-if="location === 'options' || !location">
        <EditorInput
          v-if="multiple"
          _key="maxSelections"
          type="number"
          placeholder="Max Selections"
          v-bind="{ component }"
        />
      </template>
      <template v-if="location === 'options-advanced' || !location">
        <EditorCheckbox _key="fetch_options" label="Fetch Options from an API" />
        <EditorInput isKey _key="store_full_option_data" v-bind="{ component }" />
        <template v-if="fetch_options">
          <EditorCheckbox _key="search_options_frontend" label="Search Options from Flow" />
          <EditorInput
            v-if="!component.search_options_frontend"
            type="number"
            _key="search_text_min_length"
            label="Min Length of text to search"
            placeholder="0"
            v-bind="{ component }"
          />

          <template v-else>
            <label>
              OPTIONAL: Must contain a function called 'optionTransformer'. Function receives the
              item as the only argument, and should return either an object with { key: string,
              text: string }, or a string
            </label>
            <EditorCode _key="frontend_transform_fn" v-bind="{ codeDraft }" />
            <a-button size="small" @click="generateSampleCode">Generate sample code</a-button>
          </template>
          <EditorSelect
            _key="fetch_preset"
            placeholder="Select Preset API"
            :options="fetchPresetOptions"
          />
          <EditorSelect
            v-if="fetch_preset === 'location'"
            placeholder="Select Location Fetch Type"
            _key="fetch_preset_location_type"
            :options="fetchPresetLocationOptions"
          />
          <template v-if="!fetch_preset">
            <EditorInput allowComputed monospaced _key="fetch_uri" v-bind="{ component }" />
            <EditorLabel label="Fetch Headers" />
            <EditorCode _key="fetch_headers" />
            <EditorLabel label="Fetch Transformer" />
            <EditorCode _key="fetch_transformer" />
          </template>
          <EditorInput type="number" _key="fetch_debounce" v-bind="{ component }" />
        </template>

        <template v-if="!fetch_options">
          <EditorSelect
            label="Optional - Button Repeater Key"
            _key="button_repeater_key"
            :options="keys"
          />
        </template>
        <EditorInput
          _key="top_keys"
          label="Keys at front of list"
          type="textarea"
          :transform="topKeysTransformer"
          :transformGet="topKeysGetTransformer"
        />
      </template>

      <ConversionTrackingEditor v-if="location === 'triggers'" :parent="component" />
    </a-space>
  </div>
</template>

<script>
import Firebase from 'firebase/app'
import 'firebase/firestore'
import Draggable from 'vuedraggable'

import { unpack } from '@/helpers/computed'
import { toSnake } from '@/helpers/textStringConversions'

import OptionSelectorButtonEditor from './OptionSelectorButtonEditor'
import facebookPixelEvents from './helpers/facebookPixelEvents'
import ConversionTrackingEditor from './ConversionTrackingEditor.vue'

export default {
  name: 'OptionSelectorEditor',
  components: {
    Draggable,
    OptionSelectorButtonEditor,
    ConversionTrackingEditor,
  },
  inject: ['_getBasePath', '_updateData', '_getOptions'],
  props: {
    componentId: String,
    component: Object,
    componentIndex: Object,
    page: Object,
    location: String,
  },
  data() {
    return {
      bulkNewButtons: '',
      showAll: false,
      codeDraft: '',
    }
  },
  computed: {
    ...unpack('component', [
      'key',
      'multiple',
      'dropdown',
      'placeholder',
      'nextOnSelect',
      'fetch_preset',
      'fetch_options',
      'preset_buttons',
      'allow_typing',
    ]),
    facebookPixelEvents() {
      return [undefined].concat(facebookPixelEvents())
    },
    layoutOptions() {
      return [
        { key: undefined, label: 'Default (Tiles)' },
        { key: 'horizontal_bars', label: 'Horizontal Bars' },
        { key: 'buttons_wrapped', label: 'Regular Buttons, Wrapped' },
      ]
    },
    fetchPresetOptions() {
      return [undefined, 'location']
    },
    fetchPresetLocationOptions() {
      return [undefined, 'places_autocomplete']
    },
    checkboxOptions() {
      return [undefined, 'start', 'end']
    },
    presetButtonOptions() {
      return [
        undefined,
        'countries',
        'states',
        'states_with_dc',
        'states_with_dc_and_territories',
        'states_with_outside_us',
        'canadian_provinces',
        'canadian_provinces_and_territories',
        'day_nums',
        'months',
        'years',
      ]
    },
    buttons: {
      get() {
        return this.component.buttons || []
      },
      set(val) {
        this._updateData(this._getBasePath(), 'buttons', val)
      },
    },
    keys() {
      const keys = Array.from(new Set(this._getOptions().map(o => o.key)))
      return [undefined, ...keys]
    },
    dupeOptions() {
      const seen = new Set()
      return (this.buttons || []).reduce((acc, o) => {
        if (o.key) {
          if (seen.has(o.key)) acc.add(o.key)
          seen.add(o.key)
        }
        return acc
      }, new Set())
    },
  },
  methods: {
    topKeysTransformer(val) {
      return val && val.split(',').map(t => t.trim())
    },
    topKeysGetTransformer(val) {
      return val && val.join(',')
    },
    newButton() {
      // @TODO: just splice into the buttons computed property like removeButton does?
      const buttons = [
        ...(this.component.buttons || []),
        {
          id: Firebase.firestore()
            .collection('_')
            .doc().id,
        },
      ]

      this._updateData(this._getBasePath(), 'buttons', buttons)
    },
    addOtherOption() {
      const buttons = [
        ...(this.component.buttons || []),
        {
          id: Firebase.firestore()
            .collection('_')
            .doc().id,
          key: 'other',
          text: 'Other',
          // icon: ['fas', 'ellipsis-h'],
        },
      ]

      this._updateData(this._getBasePath(), 'buttons', buttons)

      const newInput = {
        id: Firebase.firestore()
          .collection('_')
          .doc().id,
        key: `other_${this.key}`,
        type: 'InputBox',
        label: 'Other:',
        conditions: [
          {
            id: Firebase.firestore()
              .collection('_')
              .doc().id,
            key: this.key,
            operator: '==',
            values: ['other'],
          },
        ],
        placeholder: 'Type your answer here',
        isRequired: true,
      }

      const pagePath = this._getBasePath()
        .split('.')
        .slice(0, -1)
        .join('.')

      this._updateData(pagePath, this.page.components.length, newInput)
    },
    removeButton(index) {
      const buttons = this.component.buttons.spliceReturn(index, 1)

      this._updateData(this._getBasePath(), 'buttons', buttons)
    },
    addBulkButtons() {
      const buttons = this.bulkNewButtons
        .split(/\n/)
        .map(text => text.trim())
        .filter(text => text)
        .map(text => ({
          id: Firebase.firestore()
            .collection('_')
            .doc().id,
          text,
          key: toSnake(text),
        }))

      this._updateData(this._getBasePath(), 'buttons', [...this.buttons, ...buttons])

      this.bulkNewButtons = ''
    },
    generateSampleCode() {
      this.codeDraft = `function optionTransformer(options) {\n return options.map(option => { return option }) \n}`
    },
  },
}
</script>
