<template>
  <div class="FlowEditor flex flex-col gap-4 p-4">
    <template v-if="location === 'options' || !location">
      <div class="flex flex-row items-end">
        <EditorInput
          emit
          :value="(savedLiveFlow && savedLiveFlow._meta.title) || form.title"
          _key="title"
          class="flex-grow"
          placeholder="Flow Title"
          :component="form"
          @input="debounceRenameFlow"
        />
      </div>
      <FormProgressEditor :form="form" @update="_updateReactive(null, $event[0], $event[1])" />

      <div
        v-if="!(showLogoOptions || form.logoImgUrl || form.logo_link_url || form.logoPlacement)"
        class="mt-2"
      >
        <a-tooltip
          :trigger="['hover']"
          title="You can upload and add your logo to the header or footer of the Flow"
        >
          <a-button size="small" @click="showLogoOptions = true">+ Show logo options</a-button>
        </a-tooltip>
      </div>

      <div
        v-if="showLogoOptions || form.logoImgUrl || form.logo_link_url || form.logoPlacement"
        class="flex flex-col gap-2"
      >
        <div class="flex">
          <EditorInput
            class="flex-grow"
            _key="logoImgUrl"
            placeholder="Logo Image URL"
            :component="form"
            @update="_updateReactive(null, 'logoImgUrl', $event[1])"
          >
            <Uploader slot="prefix" @update="_updateReactive(null, 'logoImgUrl', $event)" />
          </EditorInput>
        </div>
        <EditorInput
          monospaced
          _key="logo_link_url"
          placeholder="Logo Hyperlink URL"
          :component="form"
          @update="_updateReactive(null, 'logo_link_url', $event[1])"
        />

        <EditorSelect
          label="Logo Placement"
          _key="logoPlacement"
          :options="['top-left', 'bottom-right']"
        />
      </div>
      <EditorCheckbox _key="show_custom_selectors" label="Enable Custom Selectors" />

      <a-collapse>
        <a-collapse-panel header="Global Components">
          <!-- <EditorLabel class="mt-4 -mb-1" label="Global Components" /> -->
          <Draggable
            v-model="globalComponents"
            handle=".handle-page"
            class="border border-gray-200  rounded-md overflow-hidden"
          >
            <ComponentLink
              v-for="comp in globalComponents"
              :key="comp.id"
              :id="comp.id"
              :hidden="hiddenGlobalComponents.has(comp.id)"
            >
              <div class="flex-grow">
                <span class="font-mono text-xs">
                  {{ comp.key }}{{ hiddenGlobalComponents.has(comp.id) ? ' (Hidden)' : '' }}
                </span>
              </div>
              <div class="handle-page cursor-move">
                <Icon name="bars" />
              </div>
            </ComponentLink>
          </Draggable>
          <a-button icon="plus" @click="$emit('new-component', true)"
            >New Global Component</a-button
          >
        </a-collapse-panel>
        <!-- <a-collapse-panel header="Other Options">
          <div class="flex flex-col gap-2">
            <EditorSelect label="Select Alignment" _key="alignment" :options="alignmentOptions" />
            <textarea
              style="width: 100%;"
              rows="3"
              placeholder="Bulk Add Pages - enter page keys here, separated by a newline"
              v-model="bulkAddPage"
            />
            <a-button icon="plus" @click="addBulkPages">Add Pages in Bulk</a-button>
          </div>
        </a-collapse-panel> -->
      </a-collapse>
      <EditorInput _key="popup_selector" label="Popup Trigger Selector" :component="form" />
      <EditorSelect
        label="When a Page is Incomplete"
        _key="show_next_button_on_incomplete"
        :defaultVal="'hide'"
        :options="[
          { key: 'hide', label: 'If Incomplete Hide Next Button' },
          { key: 'show_disabled', label: 'If Incomplete Show (Disabled) Next Button' },
          {
            key: 'show_disabled_except_single_selects',
            label: 'Show (Disabled) Button Except Single Selects',
          },
        ]"
      />
      <a-collapse>
        <a-collapse-panel key="options" header="Advanced">
          <a-space direction="vertical">
            <EditorCheckbox
              _key="standalone_full_height"
              label="Set Heights to 100% in Standalone Flows"
            />
            <EditorCheckbox _key="id_logrocket_user" label="Identify User in Logrocket" />
            <EditorCheckbox _key="sync_pages_with_url" label="Sync Flow Pages with the URL" />
            <EditorCheckbox
              :disabled="form.scroll_to_document_top"
              _key="scroll_to_top_page"
              label="Scroll to the top on page change"
            />
            <EditorCheckbox
              :disabled="form.scroll_to_top_page"
              _key="scroll_to_document_top"
              label="Scroll to the top of document on page change"
            />
            <EditorCheckbox _key="use_location_data" label="Add User Location to User Data" />
            <EditorCheckbox _key="add_referrer" label="Add Referrer to User Data" />
            <EditorCheckbox _key="forget_user_data" label="Forget User Data on Page Load" />
            <EditorCheckbox
              v-if="!form.forget_user_data"
              _key="reset_page"
              label="Reset User's page to 1 on Load"
            />
            <EditorCheckbox
              v-if="!form.forget_user_data"
              _key="reset_page_on_popup"
              label="Reset User's page to 1 on popup open"
            />
            <EditorCheckbox _key="required_labels" label="Add '*' to required component labels" />
            <EditorCheckbox
              _key="emit_all_user_data_updates"
              label="Emit User Data Updates to Window"
            />
            <EditorCheckbox _key="emit_nav_events" label="Emit Nav Events instead of navigating" />
            <EditorCheckbox
              _key="fetch_contact_history"
              label="Get site event data from Savvy Analytics"
            />
            <EditorCheckbox _key="hide_prefilled_pages" label="Auto Hide Prefilled Pages" />
            <EditorCheckbox _key="form_wrapper" label="Use Form Wrapper Tag" />
            <EditorCheckbox
              v-if="form.form_wrapper"
              _key="disable_form_validation"
              label="Use Custom Validation Messages"
            />
            <EditorInput
              _key="delay_computed_field_loading"
              label="Delay computed field loading (ms)"
            />
            <EditorSelect
              v-if="form.delay_computed_field_loading"
              _key="delay_computed_field_loading_ignore_list"
              label="Except these Computed Fields:"
              multiple
              :options="(form.computedFields || []).map(cf => ({ key: cf.key, text: cf.key }))"
            />
            <EditorInput _key="url_keys" monospaced type="textarea" />
            <EditorInput _key="registered_keys" monospaced type="textarea" />
            <EditorInput _key="embed_code_keys" monospaced type="textarea" />
            <EditorInput _key="hide_keys_in_analytics" monospaced type="textarea" />
            <EditorSelect
              _key="include_user_data_fields"
              label="Include in User Data"
              multiple
              :options="userDataIncludes"
            />
            <EditorCheckbox _key="popup" label="Force Flow to be a Popup (not recommended)" />
            <EditorCheckbox
              _key="popup_prevent_default"
              label="Prevent Default Events from firing when opening Popup"
            />
            <a-button class="w-full" @click="_openDataPath({ path: '', title: form.title })">
              Edit as raw JSON
              <Icon class="ml-1" name="external-link-alt" />
            </a-button>
          </a-space>
        </a-collapse-panel>
        <a-collapse-panel key="userData" header="User Data">
          <div class="section-heading flex">
            <a-space align="center">
              <a-switch size="small" v-model="showFullUserData" /> Expand
              <a-button size="small" icon="undo" @click="_resetUserData">
                Reset
              </a-button>
            </a-space>
          </div>
          <pre ref="userdatajson" :contenteditable="false" v-text="userDataJson" />
        </a-collapse-panel>
        <a-collapse-panel key="defaultuserData" header="Default User Data">
          <div class="section-heading flex">
            <h3 class="flex-grow align-center">
              Default User Data
            </h3>
            <a-space align="center">
              <template v-if="editingDefaultUserDataJson">
                <a-button size="small" icon="stop" @click="cancelDefaultUserDataJson" />
                <a-button
                  size="small"
                  type="primary"
                  icon="check"
                  @click="applyDefaultUserDataJson"
                />
              </template>
              <template v-else>
                <a-button size="small" icon="edit" @click="editingDefaultUserDataJson = true" />
              </template>
            </a-space>
          </div>
          <pre
            ref="defaultUserdatajson"
            :contenteditable="editingDefaultUserDataJson"
            v-text="defaultUserDataJson"
          />
        </a-collapse-panel>
      </a-collapse>
    </template>

    <template v-if="location === 'triggers' || !location">
      <DataOutputFireEditor
        _key="outputs_onopenpopup"
        :form="form"
        label="Outputs to trigger on popup open"
      />
      <DataOutputFireEditor
        _key="outputs_onclosepopup"
        :form="form"
        label="Outputs to trigger on popup close"
      />
      <DataOutputFireEditor
        _key="outputs_onloadflow"
        :form="form"
        label="Outputs to trigger on flow load"
      />
      <DataOutputFireEditor
        _key="outputs_onviewflow"
        :form="form"
        label="Outputs to trigger on flow viewed"
      />
    </template>
    <template
      v-if="(location !== 'core' && location !== 'design' && location !== 'triggers') || !location"
    >
      <EditorLabel class="mt-4 -mb-1" label="Pages" />
      <Draggable
        v-model="pages"
        handle=".handle-page"
        class="border border-gray-200 rounded-md overflow-hidden"
      >
        <ComponentLink
          v-for="(page, index) in pagesWithRepeat"
          :key="page.id"
          :hidden="hiddenPages.has(page.id)"
          noHandler
          @click.native="onSelectPage(page.id)"
        >
          <div class="flex-grow">
            {{ (page.isFinish ? 'Finish: ' : '') + `Page ${index + 1}:` }}
            <span class="font-mono text-xs">{{ page.key }}</span>
            {{
              (hiddenPages.has(page.id) ? ' (Hidden)' : '') +
                (page.id === currentPageId ? ' (Current)' : '')
            }}
            <template v-if="page.__is_repeated"> (Repeated)</template>
          </div>
          <div class="handle-page cursor-move">
            <Icon name="bars" />
          </div>
        </ComponentLink>
      </Draggable>
      <a-button type="primary" icon="plus" class="w-full" @click="_openModal('new-page')"
        >New Page</a-button
      >
      <EditorLabel label="Builder Version" />
      <a-select
        :default-value="form.builder_version"
        style="width: 100%;"
        @change="updateBuilderVersion"
      >
        <a-select-option v-for="version in builderVersions" :key="version" :value="version">
          {{ version }}
        </a-select-option>
      </a-select>

      <a-popconfirm
        title="Are you sure you want to archive this flow?"
        okType="danger"
        ok-text="Archive Flow"
        cancel-text="No"
        @confirm="archiveFlow"
      >
        <a-icon slot="icon" type="question-circle-o" style="color: red" />
        <div class="cursor-pointer border rounded p-2 mb-2">
          <Icon name="trash" />
          Archive Flow
        </div>
      </a-popconfirm>
      <a-button :type="isTemplate ? 'primary' : undefined" @click="setTemplateStatus">
        Set Flow as Template
      </a-button>
    </template>
  </div>
</template>
<script>
import { debounce, snakeCase } from 'lodash'
import id from './helpers/id'
// import { unpack } from '@/helpers/computed'
import { passesConditions } from '@/components/form/helpers/conditions'
import Firebase from '@firebase/app'
import '@firebase/firestore'

import Draggable from 'vuedraggable'
import FormProgressEditor from './FormProgressEditor.vue'
import Uploader from './helpers/Uploader'
import DataOutputFireEditor from './DataOutputFireEditor.vue'
import { repeatPages } from '../helpers/repeatPages'
import { mapGetters } from 'vuex'

export default {
  name: 'FlowEditor',
  components: { Draggable, FormProgressEditor, Uploader, DataOutputFireEditor },
  props: {
    location: String,
    form: Object,
    userData: Object,
    currentPageId: String,
  },
  inject: [
    '_updateReactive',
    '_onSelectPage',
    '_openDataPath',
    '_resetUserData',
    '_onSelectComponent',
    '_openModal',
    '_setShowHiddenPages',
    // '_setButtonState',
    '_getPreviewMode',
  ],
  data() {
    return {
      bulkAddPage: '',
      showLogoOptions: false,
      showFullUserData: false,
      editingDefaultUserDataJson: false,
    }
  },
  computed: {
    ...mapGetters(['flows']),
    savedLiveFlow() {
      return this.flows.find(f => f.id === this.form.id)
    },
    isTemplate() {
      return Boolean(
        this.savedLiveFlow && this.savedLiveFlow._meta && this.savedLiveFlow._meta.template
      )
    },
    pages: {
      get() {
        return this.form.pages || []
      },
      set(v) {
        this._updateReactive(null, 'pages', v)
      },
    },
    pagesWithRepeat() {
      return this._getPreviewMode() ? repeatPages(this.pages, this.userData) : this.pages
    },
    hiddenPages() {
      return new Set(
        this.pagesWithRepeat
          .filter(item => {
            if (item.hide) return true
            return !passesConditions(
              item.conditions || [],
              this.userData,
              this.globalComponents,
              true
            )
          })
          .map(c => c.id)
      )
    },
    globalComponents: {
      get() {
        return this.form.components || []
      },
      set(v) {
        this._updateReactive(null, 'components', v)
      },
    },
    hiddenGlobalComponents() {
      return new Set(
        this.globalComponents
          .filter(item => {
            if (item.hide) return true
            return !passesConditions(
              item.conditions || [],
              this.userData,
              this.globalComponents,
              true
            )
          })
          .map(c => c.id)
      )
    },
    alignmentOptions() {
      return [
        { key: 'undefined', label: 'Default (Left)' },
        { key: 'left_buttons_centered', label: 'Left with Centered Buttons' },
        { key: 'centered', label: 'Centered' },
      ]
    },
    userDataIncludes() {
      return [
        'current_page_title',
        'highest_page_title',
        'current_page_subtitle',
        'highest_page_subtitle',
        'current_page_heading_label',
        'highest_page_heading_label',
      ]
    },
    userDataJson() {
      return JSON.stringify(this.userData, this.replacer, 2)
    },
    defaultUserDataJson() {
      const userData = (this.form && this.form.defaults && this.form.defaults.userData) || {}
      return JSON.stringify(userData, this.replacer, 2)
    },
    builderVersions() {
      return [1, 2, 3]
    },
    debounceRenameFlow() {
      return debounce(this.renameFlow, 1000)
    },
  },
  watch: {},
  methods: {
    editTitle() {
      if (window.CommandBar) window.CommandBar.execute(10891)
    },
    async onSelectPage(pageId) {
      const page = this.pages.find(p => p.id === pageId)
      if (page) {
        const pageIsHidden = Boolean(this.hiddenPages.has(pageId) || page.hide)
        this._setShowHiddenPages(pageIsHidden)
        // this._setButtonState('show-hidden-page', pageIsHidden)
        await this.$nextTick()
      }
      this._onSelectPage({ key: pageId })
    },
    addBulkPages() {
      const newPages = this.bulkAddPage
        .split(/\n/)
        .map(text => text.trim())
        .filter(text => text)
        .map(text => ({
          id: id(),
          key: snakeCase(text),
          components: [],
          showNav: true,
        }))

      if (this.form.pages) this._updateReactive(null, 'pages', [this.form.pages, ...newPages])
      else this._updateReactive(null, 'pages', newPages)

      this.bulkAddPage = ''
    },
    replacer(key, value) {
      if (this.showFullUserData) return value

      if (value && Array.isArray(value) && value.length > 20) return `[Array: ${value.length}]`

      return value
    },
    cancelDefaultUserDataJson() {
      const userData = (this.form && this.form.defaults && this.form.defaults.userData) || {}
      this.$refs.defaultUserdatajson.innerText = JSON.stringify(userData, this.replacer, 2)
      this.editingDefaultUserDataJson = false
    },
    applyDefaultUserDataJson() {
      try {
        const userData = JSON.parse(this.$refs.defaultUserdatajson.innerText)
        this._updateReactive('defaults', 'userData', userData)
        this.editingDefaultUserDataJson = false
      } catch (error) {
        this.$message.error('Error applying JSON')
        console.error(error)
      }
    },
    archiveFlow() {
      Firebase.firestore()
        .collection('forms')
        .doc(this.form.id)
        .update({ _archived: true })
      this.$router.push(`/project/flows`)
    },
    renameFlow(title) {
      Firebase.firestore()
        .collection('forms')
        .doc(this.form.id)
        .update({ title })
    },
    setTemplateStatus() {
      Firebase.firestore()
        .collection('forms')
        .doc(this.form.id)
        .update({ template: !this.isTemplate })
    },
    updateBuilderVersion(event) {
      this._updateReactive(null, 'builder_version', event)
    },
  },
}
</script>
