<template>
  <div class="FlowCards">
    <div class="cards-heading">
      Your Flows
      <span v-if="limit" style="padding-left: 15px; font-size: 0.8em;">
        <router-link :to="baseTo">View All ></router-link>
      </span>
      <a-tooltip v-else title="Add New Flow">
        <a-button icon="plus" type="link" @click="addNewFlow"></a-button>
      </a-tooltip>
    </div>
    <div class="cards-container">
      <Card
        v-for="card in limit ? flowsInOrder.slice(0, limit) : flowsInOrder"
        :key="card.id"
        flush
      >
        <FlowThumbnail :flowId="card.id" :flow="card" />
        <!-- :label="card.title || 'Untitled Flow'" -->
        <!-- :to="`${baseTo}/${card.id}`" -->
      </Card>
    </div>
  </div>
</template>
<script>
import Firebase from 'firebase/app'
import 'firebase/firestore'

import { mapGetters } from 'vuex'

import notify from '@/helpers/notify'
import FlowThumbnail from '@/components/form/FlowThumbnail.vue'
import { getDefaultNewFlow } from '@/components/form/editor/helpers/formHelpers'
import flowsInOrder from './helpers/flowsInOrder'
export default {
  name: 'FlowCards',
  components: { FlowThumbnail },
  props: {
    limit: Number,
  },
  computed: {
    ...mapGetters(['isAdmin', 'activeGroupId', 'activeGroup', 'user', 'flows']),
    baseTo() {
      return `/project/flows`
    },
    flowsInOrder() {
      return flowsInOrder(this.flows, this.user)
    },
  },
  methods: {
    async addNewFlow() {
      const batch = Firebase.firestore().batch()

      const formRef = Firebase.firestore()
        .collection('forms')
        .doc()
      const id = formRef.id
      const newFlowName = `Untitled Flow (${this.activeGroup?.name})`
      const { form: initialFormData, flow } = getDefaultNewFlow(id, newFlowName, this.activeGroupId)
      // const initialFormData = JSON.stringify({ id, form_wrapper: true, builder_version: 3 })

      const userName =
        this.$store.getters.user.nickname ||
        (this.$store.getters.user.auth &&
          (this.$store.getters.user.auth.displayName || this.$store.getters.user.auth.email))
      const author = { userId: this.$store.getters.userId, userName: userName || 'Unnamed User' }

      batch.set(formRef.collection('history').doc('version-0'), {
        groupId: this.activeGroupId,
        form: JSON.stringify(initialFormData),
        author,
        saved: true,
        status: 'saved',
        version: 0,
        from_version: (this.versionData && this.versionData.version) || null,
        updatedAt: Firebase.firestore.FieldValue.serverTimestamp(),
      })
      batch.set(formRef, flow)
      await batch.commit()
      this.$router.push(`${this.baseTo}/${id}/edit`)

      notify(
        `
*New Flow Created!* \n
Org ID: \`${this.activeGroupId}\` | Org Name: *${this.activeGroup?.name}* \n
Flow ID: \`${id}\` | Flow Name: *${newFlowName}* \n
Creator: *${userName}* \n
<https://beta.trysavvy.com/project/flows/${id}/edit|Open in Builder>
`,
        'C040HMS7NDC',
        true
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.FlowCards {
  margin: 0 0 20px;
  width: 100%;

  .cards-heading {
    font-size: 16px;
  }

  .cards-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    // width: 100%;
    margin: 0 -10px;

    > div {
      width: calc(33.333% - 16px);
      min-width: 320px;
      height: 20vw;
      margin: 24px 8px 8px;
    }
  }
}
</style>
