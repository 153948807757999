// export function copyToClipboard(text) {
//   var input = document.createElement('textarea')
//   // input.setAttribute('value', text)
//   input.innerHTML = text
//   document.body.appendChild(input)
//   input.select()
//   var result = document.execCommand('copy')
//   document.body.removeChild(input)
//   return result
// }

function fallbackCopyTextToClipboard(text) {
  var textArea = document.createElement('textarea')
  textArea.value = text

  // Avoid scrolling to bottom
  textArea.style.top = '0'
  textArea.style.left = '0'
  textArea.style.position = 'fixed'

  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    // var successful =
    document.execCommand('copy')
    // var msg = successful ? 'successful' : 'unsuccessful'
    // console.log('Fallback: Copying text command was ' + msg)
  } catch (err) {
    // console.error('Fallback: Oops, unable to copy', err)
  }

  document.body.removeChild(textArea)
}
export function copyToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text)
    return
  }
  navigator.clipboard.writeText(text).then(
    function() {
      // console.log('Async: Copying to clipboard was successful!')
    },
    function(err) {
      console.error('Async: Could not copy text: ', err)
    }
  )
}
