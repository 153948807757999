<template>
  <a-tooltip :title="title">
    <div
      class="ContactSource rounded-full w-6 flex-none h-6 ml-2 uppercase bg-gray-200 flex items-center justify-center overflow-hidden font-bold text-gray-500"
      :style="{ color: colors[0], backgroundColor: colors[1] }"
    >
      <img
        v-if="icon && icon.startsWith('http')"
        :src="icon"
        class="max-w-4 max-h-4"
        :style="{ transform: size ? `scale(${size})` : 'none' }"
      />
      <Icon v-else-if="icon" type="fab" :name="icon" />
      <template v-else>{{ (channel || [])[0] || '?' }}</template>
    </div>
  </a-tooltip>
</template>

<script>
import ColorHash from 'color-hash'

import { unpack } from '@/helpers/computed'
import { getChannelIcon } from '@/helpers/channels'
import { universalIdToProper } from '@/helpers/textStringConversions'

export default {
  name: 'ContactSource',
  props: {
    source: Object,
  },
  computed: {
    ...unpack('source', ['channel', 'medium', 'campaign']),
    title() {
      let channelTitle = getChannelTitle(this.channel)

      return (
        universalIdToProper(channelTitle) +
        (this.medium ? ` (${universalIdToProper(this.medium)})` : '') +
        (this.campaign ? `: ${universalIdToProper(this.campaign)}` : '')
      )

      function getChannelTitle(channel) {
        switch (channel) {
          case 't':
            return 'twitter'
          case 'ycombinator':
            return 'Y Combinator'
          case 'workatastartup':
            return 'Work At A Startup'
          case 'linkedin':
            return 'LinkedIn'
          case 'adwords':
            return 'AdWords'
          case 'hs_email':
          case 'hs_automation':
            return 'HubSpot'
          case 'duckduckgo':
            return 'DuckDuckGo'
          default:
            return channel
        }
      }
    },
    icon() {
      return getChannelIcon(this.channel)
    },
    size() {
      switch (this.channel) {
        case 'instagram':
        case 'duckduckgo':
        case 'yahoo':
        case 'webflow':
        case 'linktree':
        case 'linktr':
          return 1.2
        case 'upwork':
        case 'quora':
        case 'ecosia':
          return 1.3
        case 'facebook':
          return 1.5
        default:
          return null
      }
    },
    colors() {
      switch (this.channel) {
        case 'facebook':
          return ['#1757dc', '#1757dc22']
        case 't':
        case 'twitter':
          return ['#00acee', '#00acee22']
        case 'reddit':
          return ['#FF5700', '#FF570022']
        case 'linkedin':
          return ['#0e76a8', '#0e76a822']
        case 'ycombinator':
        case 'workatastartup':
          return ['white', '#f26523']
        case 'instagram':
          return ['#cd486b', '#8a3ab922']
        case 'quora':
          return ['#fff', '#b92b28']
        case 'upwork':
          return ['#fff', '#15a800']
        case 'google':
        case 'adwords':
        case 'bing':
        case 'hubspot':
        case 'duckduckgo':
        case 'yahoo':
        case 'webflow':
        case 'ecosia':
        case 'linktree':
        case 'linktr':
          return []
      }

      var colorHashBold = new ColorHash({ saturation: 0.9, lightness: 0.4 })
      var colorHashPale = new ColorHash({ saturation: 0.9, lightness: 0.95 })

      return [colorHashBold.hex(this.channel), colorHashPale.hex(this.channel)]
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/_variables.scss';

.ContactSource {
  .Icon {
    font-size: 14px;
  }
}
</style>
