import { parseURIComponent } from '@/helpers/textStringConversions'

export default function getThumbnailUrl(id, version, options = {}) {
  const url = `https://flows.trysavvy.com/flow/${id}`

  if (!url) return null
  const { pageId, width, crop, viewportWidth, maxAge } = options || {}

  const AUTHKEY = process.env.VUE_APP_THUMIO_AUTH_KEY

  const params = [
    'savvy_test=true',
    version ? `version=${version}` : null,
    pageId ? `current_page_id=${pageId}` : null,
  ].filter(p => p)

  return `https://image.thum.io/get/auth/${AUTHKEY}/noanimate/width/${width || 1200}/maxAge/${
    typeof maxAge === 'number' ? maxAge : 24
  }/wait/5/crop/${crop || 800}/viewportWidth/${viewportWidth || 1200}/${parseURIComponent(
    url
  )}?${params.join('&')}`
}
