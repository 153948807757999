<template>
  <div class="FlowAnalytics flex flex-col items-stretch flex-1 overflow-hidden">
    <!-- <div class="break-all">
      {{ algoliaFilterString }}
    </div> -->
    <div class="flex flex-row justify-center mb-4 m-2">
      <div
        class="cursor-pointer mr-1 px-1 py-1 border-purple"
        :class="{
          'border-b-2': tab === 'this_page',
        }"
        @click="tab = 'this_page'"
      >
        This Page
      </div>
      <div
        class="cursor-pointer mx-1 px-1 py-1 border-purple"
        :class="{
          'border-b-2': tab === 'all_pages',
        }"
        @click="tab = 'all_pages'"
      >
        All Pages
      </div>
    </div>
    <div class="flex flex-row justify-start m-1 flex-wrap">
      <template v-for="{ key, icon, text, selected, options, toggle } in displayFilters">
        <Pill
          v-if="toggle"
          :key="key"
          class="capitalize"
          clickable
          v-bind="{ selected }"
          @click="setFilter(key, !selected)"
        >
          <Icon class="mr-1.5" :name="icon" />
          {{ text }}
        </Pill>
        <a-dropdown v-else :key="key" :trigger="['click']">
          <Pill
            class="capitalize"
            clickable
            :closeable="selected"
            v-bind="{ selected }"
            @close="removeFilter(key)"
          >
            <Icon class="mr-1.5" :name="icon" />
            {{ text }}
            <Icon v-if="!selected" class="ml-1" name="fas:caret-down" />
          </Pill>
          <a-menu slot="overlay" @click="setFilter(key, $event)">
            <a-menu-item v-for="{ key, text } in options" :key="key">
              <a class="capitalize" href="javascript:;">{{ text }}</a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </template>
    </div>
    <div v-if="comparing" class="flex flex-row justify-start m-1 flex-wrap">
      <template v-for="{ key, icon, text, selected, options, toggle } in displayCompareFilters">
        <Pill
          v-if="toggle"
          :key="key"
          filledColor="#ec1b8d"
          class="capitalize"
          clickable
          v-bind="{ selected }"
          @click="setFilter(key, !selected, true)"
        >
          <Icon class="mr-1.5" :name="icon" />
          {{ text }}
        </Pill>
        <a-dropdown v-else :key="key" :trigger="['click']">
          <Pill
            clickable
            class="capitalize"
            filledColor="#ec1b8d"
            :closeable="selected"
            v-bind="{ selected }"
            @close="removeFilter(key, true)"
          >
            <Icon class="mr-1.5" :name="icon" />
            {{ text }}
            <Icon v-if="!selected" class="ml-1" name="fas:caret-down" />
          </Pill>
          <a-menu slot="overlay" @click="setFilter(key, $event, true)">
            <a-menu-item v-for="{ key, text } in options" :key="key">
              <a class="capitalize" href="javascript:;">{{ text }}</a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </template>
    </div>
    <div class="mb-2 m-1 flex flex-row justify-start">
      <Pill
        dashed
        :icon="comparing ? 'times' : 'balance-scale-right'"
        clickable
        @click="comparing = !comparing"
      >
        {{ comparing ? 'Stop Comparing' : 'Compare...' }}
      </Pill>
    </div>
    <div
      v-if="charts.length === 0"
      class="flex-1 flex flex-col justify-center text-center text-gray-400"
    >
      <a-empty description="No charts yet - publish your Flow to start collecting data! 📊" />
    </div>
    <div v-else-if="id" class="scroller flex-1 mt-2 px-2">
      <Card v-for="chart in charts" :key="chart.key" small class="mt-4 mb-8 border border-gray-200">
        <FlowAnalyticsCard
          v-bind="{
            form,
            chart,
            algoliaFilterString,
            algoliaCompareFilterString,
            tab,
            currentPageId,
          }"
        />
      </Card>
    </div>
  </div>
</template>

<script>
const availableFilters = [
  {
    key: 'date',
    icon: 'clock',
    offText: 'All Time',
  },
  {
    key: 'page',
    icon: 'file',
    offText: 'All Pages',
  },
  {
    key: 'test',
    icon: 'vial',
    text: 'Test Data',
    toggle: true,
  },
  {
    key: 'checkpoint',
    icon: 'bullseye-pointer',
    offText: 'All Checkpoints',
  },
  {
    key: 'version',
    icon: 'code-branch',
    offText: 'All Versions',
  },
  {
    key: 'values',
    icon: 'pencil',
    offText: 'All Answers',
  },
]

import Firebase from 'firebase/app'
import 'firebase/firestore'
import Moment from 'moment'

import { FLOWS_COLLECTION } from '@/constants'
import { snakeToProper } from '@/helpers/textStringConversions'
import getOptions from '../helpers/options'
import getCheckpointOptions from '@/components/form/editor/helpers/getCheckpointOptions'

import FlowAnalyticsCard from './FlowAnalyticsCard'

export default {
  name: 'FlowAnalytics',
  components: {
    FlowAnalyticsCard,
  },
  props: {
    id: String,
    form: Object,
    currentPageId: String,
    pages: Array,
    noAuthComments: Boolean,
    userData: Object,
    comments: Array,
    flowGroupId: String,
  },
  data() {
    return {
      filters: [],
      compareFilters: [],
      tab: 'this_page',
      allFormVersions: [],
      comparing: false,
    }
  },
  computed: {
    charts() {
      if (!this.form) return

      const funnelCharts = [{ key: '_funnel' }]

      const answerBreakdownCharts = Array.from(
        new Set(
          getOptions(this.form, this.userData)
            .filter(o => o.type === 'OptionSelector')
            .map(o => o.key)
        )
      )
        .filter(
          key =>
            this.tab === 'all_pages' ||
            (
              (this.form.pages.find(page => page.id === this.currentPageId) || {}).components || []
            ).some(c => c.key === key)
        )
        .map(key => ({ key }))

      const userBreakdownCharts = [{ key: 'first_version' }]

      return funnelCharts.concat(answerBreakdownCharts).concat(userBreakdownCharts)
    },
    // defaultFilters() {
    //   return (this.form && this.form.default_filters) || []
    // },
    displayFilters() {
      return availableFilters.map(f => {
        const filter = { ...f }

        filter.applied = this.filters
          .filter(f => f.key === filter.key)
          .map(({ operator, value }) => ({ operator, value }))
        filter.selected = filter.applied.length > 0
        filter.text = filter.toggle
          ? filter.text
          : filter.selected
          ? getText(filter)
          : filter.offText
        filter.options = getFilterOptions(
          filter.key,
          this.form,
          this.userData,
          this.allFormVersions
        ).map(option =>
          typeof option === 'string'
            ? {
                key: option,
                text: snakeToProper(option),
              }
            : option
        )

        return filter
      })
    },
    displayCompareFilters() {
      return availableFilters.map(f => {
        const filter = { ...f }

        filter.applied = this.compareFilters
          .filter(f => f.key === filter.key)
          .map(({ operator, value }) => ({ operator, value }))
        filter.selected = filter.applied.length > 0
        filter.text = filter.toggle
          ? filter.text
          : filter.selected
          ? getText(filter)
          : filter.offText
        filter.options = getFilterOptions(
          filter.key,
          this.form,
          this.userData,
          this.allFormVersions
        ).map(option =>
          typeof option === 'string'
            ? {
                key: option,
                text: snakeToProper(option),
              }
            : option
        )

        return filter
      })
    },
    algoliaFilterString() {
      if (!this.form) return null

      return [
        {
          key: 'checkpoint',
          value: `loaded_flow`,
        },
      ]
        .concat(
          this.filters.find(f => f.key === 'test' && f.value === true)
            ? []
            : [
                {
                  key: 'is_test',
                  value: false,
                },
              ]
        )
        .concat(this.filters.filter(f => f.key !== 'test'))
        .map(f => '(' + getAlogliaFilter(f.key, f.value, this.form) + ')')
        .join(' AND ')
    },
    algoliaCompareFilterString() {
      if (!this.form || !this.comparing) return null

      return [
        {
          key: 'checkpoint',
          value: `loaded_flow`,
        },
      ]
        .concat(
          this.compareFilters.find(f => f.key === 'test' && f.value === true)
            ? []
            : [
                {
                  key: 'is_test',
                  value: false,
                },
              ]
        )
        .concat(this.compareFilters.filter(f => f.key !== 'test'))
        .map(f => '(' + getAlogliaFilter(f.key, f.value, this.form) + ')')
        .join(' AND ')
    },
  },
  watch: {
    comparing() {
      this.compareFilters = []
    },
  },
  created() {
    this.getVersions()
  },
  methods: {
    getVersions() {
      const baseRef = Firebase.firestore()
        .collection(FLOWS_COLLECTION)
        .doc(this.id)

      let ref = baseRef
        .collection('history')
        .orderBy('version', 'desc')
        .limit(20)
      this.$bind('allFormVersions', ref)
    },
    setFilter(key, event, isCompare) {
      const value = (event && event.key) || event || undefined
      const filtersList = isCompare ? 'compareFilters' : 'filters'

      if (!value) return this.removeFilter(key, isCompare)

      this[filtersList] = this[filtersList]
        .filter(f => f.key !== key)
        .concat([{ key, operator: '==', value }])
    },
    removeFilter(key, isCompare) {
      const filtersList = isCompare ? 'compareFilters' : 'filters'

      this[filtersList] = this[filtersList].filter(f => f.key !== key)
    },
  },
}

function getText(filter) {
  const includeKey = !['date', 'values'].includes(filter.key)

  return (
    (includeKey ? filter.key + ': ' : '') +
    snakeToProper((filter.applied[0].value || 'Active').split(':').join(' = '))
  )
}

function getFilterOptions(key, form, userData, allFormVersions) {
  switch (key) {
    case 'date':
      return [
        'today',
        'yesterday',
        'this_week',
        'last_week',
        'this_month',
        'last_month',
        'this_year',
        'last_year',
      ]
    case 'checkpoint':
      return getCheckpointOptions(form, false, true)
    case 'page':
      return form.pages.map(p => p.key)
    case 'version':
      return Array.from(new Set(allFormVersions.map(v => `${v.version}`)))
    case 'values':
      return Array.from(
        new Set(
          getOptions(form, userData).reduce((acc, o) => {
            return acc.concat((o.options || []).filter(_o => _o).map(_o => `${o.key}:${_o}`))
          }, [])
        ).map(o => ({
          key: o,
          text: snakeToProper(o.split(':').join(' = ')),
        }))
      )

    default:
      return ['one', 'two', 'three']
  }
}

function getAlogliaFilter(key, value, form) {
  switch (key) {
    case 'date':
      return `updatedAt: ${getStartDate(value)} TO ${getEndDate(value)}`
    case 'page':
      return `flow_pages_viewed:${form.id}.${value}`
    case 'values':
      return `flow_answers:"${form.id}.${value}"`
    case 'checkpoint':
      return `flow_checkpoints:${form.id}.${value}`

    case 'version':
      // return 'flow_answers:"9sBWfYkaXiuky6nLgwNx.role:campaign_staff"'
      return `flow_answers:"${form.id}.first_version:${value}"`

    default:
      return `${key}:${value}`
  }
}

function getStartDate(val) {
  switch (val) {
    case 'today':
      return start('day')
    case 'this_week':
      return start('week')
    case 'this_month':
      return start('month')
    case 'this_year':
      return start('year')

    case 'yesterday':
      return startMinusOne('day')
    case 'last_week':
      return startMinusOne('week')
    case 'last_month':
      return startMinusOne('month')
    case 'last_year':
      return startMinusOne('year')

    default:
      return 0
  }
}

function getEndDate(val) {
  switch (val) {
    case 'yesterday':
      return start('day')
    case 'last_week':
      return start('week')
    case 'last_month':
      return start('month')
    case 'last_year':
      return start('year')

    default:
      return end('day')
  }
}

function start(key) {
  return Moment()
    .startOf(key)
    .valueOf()
}

function end(key) {
  return Moment()
    .endOf(key)
    .valueOf()
}

function startMinusOne(key) {
  return Moment()
    .subtract(1, `${key}s`)
    .startOf(key)
    .valueOf()
}
</script>

<style lang="scss" scoped>
.FlowAnalytics {
}
</style>
