import { UNIQUE_PRESET_COUNT, SERVICES } from './services'
import { toDateString } from '../../helpers/timeFilters'
import {
  combineDataToNumber,
  groupDataByTime,
  sortEntriesByDate,
  limitToDateRange,
  chartDate,
  createDateRangeArray,
  valuesAreDates,
} from './computeDateGroupings'

export default function cachedDataToCardData(preset, cachedData, config) {
  if (!cachedData) return { datasets: [], labels: [] }

  const dateRange = createDateRangeArray(config)
  const isOneDimension = Object.values(cachedData && cachedData.data).every(d => Array.isArray(d))
  const isSingle = Object.values(cachedData && cachedData.data).every(d => typeof d === 'number')
  const singleNumber = config.service !== 'combo' && config.grouping === 'none'
  const dateGrouping =
    config.grouping === 'date' || config.grouping === 'both'
      ? config['date-interval'] || 'month'
      : 'month'

  if (UNIQUE_PRESET_COUNT.has(preset) && singleNumber && cachedData.total) {
    return cachedData.total
  }

  const allowed = config['combo-page-filter'] ? new Set(config['combo-page-filter'] || []) : null
  const prefilterData =
    config.service === 'combo' && config['combo-page-filter']
      ? Object.entries(cachedData.data).reduce((acc, [k, v]) => {
          if (allowed && allowed.has(k)) acc[k] = v
          return acc
        }, {})
      : cachedData.data
  const data = limitToDateRange(prefilterData, dateRange, config)
  let datasets = []
  let labels = []
  if (isSingle) {
    /*
    Data format is { [breakdownValue]: number }
    Grouping === 'non-date' or grouping === 'none && preset === visitors
     */
    if (singleNumber) return combineDataToNumber(data)
    const keys = cachedData.keyOrder
      ? cachedData.keyOrder
      : sortEntriesByDate(Object.entries(data)).map(e => e[0])
    // : Object.keys(data).sort((a, b) => new Date(a) - new Date(b))
    const keysAreDates = valuesAreDates(keys)
    labels = keys.map(l =>
      cachedData.mappings && cachedData.mappings[l]
        ? cachedData.mappings[l]
        : keysAreDates
        ? chartDate(l, config)
        : l
    )
    datasets = [
      {
        data: keys.map(d => data[d]),
        label: preset,
        backgroundColor: '#6454f355',
      },
    ]
  } else if (isOneDimension) {
    /* Grouping === 'none', data format is { [YYYY-MM]: number[] } */
    if (singleNumber) return combineDataToNumber(data)
    /* Grouping === 'date', data format is { [YYYY-MM]: number[] } */
    const dataByTime = groupDataByTime(data, dateGrouping)
    const entries = sortEntriesByDate(Object.entries(dataByTime))
    labels = entries.map(e => e[0])
    datasets = [
      {
        label: preset,
        backgroundColor: '#6454f355',
        data: entries.map(e => e[1].reduce((sum, day) => sum + day, 0)),
      },
    ]
  } else {
    /* Grouping === 'both', data format is { [breakdownValue]: { [YYYY-MM]: number[] } */
    if (config.grouping === 'both') {
      const groupedData = Object.entries(data).reduce((acc, e) => {
        const [k, v] = e
        acc[k] = groupDataByTime(v, dateGrouping)
        return acc
      }, {})
      const labelArray = Array.from(
        new Set(
          Object.values(groupedData).reduce((acc, v) => {
            acc.push(...Object.keys(groupDataByTime(v, dateGrouping)))
            return acc
          }, [])
        )
      )
        .sort()
        .sort((a, b) => new Date(a) - new Date(b))
      labels = labelArray
      datasets = Object.entries(data).map(e => {
        const [key, val] = e
        const valByTime = groupDataByTime(val, dateGrouping)
        return {
          label: (cachedData.mappings && cachedData.mappings[key]) || key,
          backgroundColor: '#6454f355', // Change colors
          data: labelArray.map(m =>
            valByTime[m] ? valByTime[m].reduce((sum, day) => sum + day, 0) : 0
          ),
        }
      })
    } else {
      const labelArray = Object.keys(data)
      labels = labelArray
      datasets = [
        {
          label: preset,
          backgroundColor: '#6454f355',
          data: labelArray.map(l =>
            Object.values(data[l]).reduce(
              (sum, day) => sum + (Array.isArray(day) ? day.reduce((sum, d) => sum + d, 0) : +day),
              0
            )
          ),
        },
      ]
    }
  }

  return {
    labels,
    datasets,
  }
}

export function generateCacheSlug(config, cService, cPreset, breakdownArray, forceTest) {
  // const { grouping, service } = config
  const grouping = config.grouping
  const service = cService || config.service
  const preset = cPreset || config.preset || config[`${service}-preset`]
  const basePrefix = config.includeTest || forceTest ? 'test-' : ''
  let base = `${basePrefix}${service}-${preset}`
  const useUniqueSlug = UNIQUE_PRESET_COUNT.has(preset)
  const additions = useUniqueSlug ? [] : [...(breakdownArray || [])]
  const groupingIsBreakdown = grouping === 'non-date' || grouping === 'both'
  if (SERVICES[service] && SERVICES[service].accountRequired) {
    const account = config[`${service}-accounts`]
    if (account) base += `-accId-${encodeURIComponent(account)}`
  }

  if (service === 'combo') {
    if (config.funnelId && config['combo-preset'] === 'funnel') {
      base += `-${config.funnelId}`
    }
    if (config.flowId && ['checkpoint', 'flow-pages'].includes(config['combo-preset'])) {
      base += `-${config.flowId}`
    }
    if (config[`combo-checkpoint-filters`]) base += `-filters-${config[`combo-checkpoint-filters`]}`
    if (config['combo-funnel-breakdown']) base += `-by-${config['combo-funnel-breakdown']}`
    if (config['combo-source']) base += `-src-${config['combo-source']}`
    if (config['date-range']) {
      if (config['date-range'] === 'custom' && config['custom-date']) {
        const customRange = config['custom-date'].split('_TO_').map(d => toDateString(d))
        base += `range-${customRange.join('-')}`
        // 2020-10-12T01:40:02.055Z_TO_2020-10-21T01:40:02.055Z
      } else base += `-range-${config['date-range']}`
    }
  }

  if (service === 'savvy' && preset === 'checkpoint' && config['savvy-checkpoint']) {
    base += `-${config['savvy-checkpoint']}`
  }

  if (
    service === 'savvy' &&
    groupingIsBreakdown &&
    config['savvy-breakdown'] === 'flows' &&
    config['savvy-flow-breakdown']
  ) {
    base += `-${config['savvy-flow-breakdown'].split(' - ')[1]}`
  }
  addFacebookAdRelated(config, additions)

  if (useUniqueSlug) {
    if (grouping) {
      if (['non-date', 'both'].includes(grouping)) {
        if (config[`${service}-breakdown`]) {
          additions.push(config[`${service}-breakdown`])
        }
        // if (grouping === 'non-date') additions.push('date')
      } else additions.push('date')
    }
    const dateRange = config['date-range']

    if (['date', 'both'].includes(config.grouping)) {
      additions.push(`${config['date-interval'] || 'day'}`)
    }

    if (dateRange) {
      if (dateRange === 'custom') {
        if (config['custom-date']) {
          const customRange = config['custom-date'].split('_TO_').map(d => toDateString(d))
          additions.push(`range-${customRange.join('-')}`)
          // 2020-10-12T01:40:02.055Z_TO_2020-10-21T01:40:02.055Z
        }
      } else {
        additions.push(dateRange)
      }
    }
  }

  additions.filterExists().forEach(b => {
    base += `-by-${b}`
  })

  const keysToAdd = ['eventId', 'event-eventId', 'flowId']
  const bonusFilters = keysToAdd.map(k => (config[k] ? `${k}=${config[k]}` : '')).filterExists()
  bonusFilters.filterExists().forEach(b => (base += `&${b}`))

  return base
}

export function getBreakdownArray(config, cService) {
  if (!config) return ['date']
  const service = cService || config.service
  const breakdownType = config[`${service}-breakdown`]
  const grouping = config.grouping
  const array = [
    grouping === 'non-date' || grouping === 'both' ? breakdownType : null,
    ['date', 'both', 'none'].includes(config.grouping) ? 'date' : null,
  ]

  if (service === 'combo') {
    if (config[`${service}-source`]) {
      array.push(`${config[`${service}-source`]}`)
    }
    if (config[`${service}-funnel-breakdown`]) {
      array.push(`${config[`${service}-funnel-breakdown`]}`)
    }
  }

  const filteredArray = array.filter(e => e)
  return filteredArray.length > 0 ? filteredArray : ['date']
}

function addFacebookAdRelated(config, additions) {
  if (
    config.service === 'facebook-ads' ||
    (config.service === 'combo' && config['combo-source'] === 'facebook-ads')
  ) {
    const service = 'facebook-ads'

    if (config[`${service}-campaigns`])
      additions.push(`campaignId-${config[`${service}-campaigns`]}`)

    if (config[`${service}-adset`]) additions.push(`campaignId-${config[`${service}-adset`]}`)
  }
}
