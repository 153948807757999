<template>
  <div class="Popup" v-if="isOpen" :class="{ isFlush }">
    <WindowEvent event-name="keyup" @on-trigger="onKeyUp($event)" />
    <div class="overlay" @click="$emit('close', $event)"></div>
    <div class="container">
      <div class="box">
        <Button class="close borderless" icon="times" :click="$event => $emit('close', $event)" />
        <slot />
        <div class="done" v-if="!noButton">
          <Button
            class="success"
            :text="closeText || 'Done'"
            icon="check"
            :click="event => $emit('close', event)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WindowEvent from '@/components/logic/WindowEvent.vue'

export default {
  components: {
    WindowEvent,
  },
  props: {
    isOpen: Boolean,
    closeText: String,
    noButton: Boolean,
    isFlush: Boolean,
  },
  methods: {
    onKeyUp(event) {
      if (event.code === 'Escape') {
        this.$emit('close')
      }
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/_variables.scss';

.Popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 500;
  cursor: auto;

  &,
  > .overlay {
    top: 0;
    left: 0;
  }
  &.medium {
    > .container .box {
      width: 600px;
      max-width: unset;
    }
  }
  &.large {
    > .container .box {
      width: 920px;
      max-width: unset;
    }
  }
  &.isFlush {
    > .container > .box {
      padding: 0;
    }
  }

  > .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 0;
  }
  > .container {
    overflow-y: scroll;
    width: 100%;
    max-width: unset;
    height: 100%;

    > .box {
      position: relative;
      width: 90%;
      max-width: 1200px;
      min-height: 60px;
      margin: 10px auto;
      padding: 20px 80px;
      background-color: white;
      z-index: 400;
      border-radius: 10px;
      box-sizing: border-box;

      .Button.close {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 2;
        padding: 15px 18px;

        .Icon {
          margin: 0;
        }
      }
      .done {
        display: flex;
        justify-content: flex-end;
        margin: 20px;

        .button.success {
          background: $success;
          border-color: $success;
          color: white;
        }
      }

      .SingleSentence,
      .interactive-filters-container {
        font-size: 26px;
        margin: 30px 0;

        .Options {
          font-size: 16px;
        }
        .value-box {
          border-bottom: 4px solid rgba(0, 0, 0, 0);

          &:hover {
            border-color: $savvy;
          }
        }
        .InteractiveFilters {
          .rule {
            margin: -5px -3px;

            > span {
              font-size: 22px;
            }
          }
          > .Selector {
            display: block;
            margin-top: 10px;
          }
          .add-rule {
            font-size: 18px;
            margin-top: 10px;
          }
        }
      }
      .selector-label {
        font-size: 22px;
      }
      .SingleSentence {
        .phrase:first-child .line > .text {
          font-size: 22px;
          margin-right: 10px;
          // display: block;
        }
        .Options {
          font-size: 16px;
          max-width: 300px;
          max-height: 400px;

          .option {
            padding: 10px 15px;

            &:not(:last-child) {
              border-bottom: 1px solid #eee;
            }
            &.disabled {
              background: #fcfcfc;
              opacity: 0.5;
            }
          }
        }
      }
    }
  }
}
</style>
