<template>
  <div class="ContactProgress flex items-center justify-center">
    <ContactProgressFlow
      v-if="mainFlow"
      :flowId="mainFlow[0]"
      :flowData="mainFlow[1]"
      v-bind="{ contact }"
    />
    <div v-if="numMoreFlows" class="plus-more text-xs ml-4 text-gray-400 whitespace-nowrap">
      + {{ numMoreFlows }} Flow{{ numMoreFlows > 1 ? 's' : '' }}
    </div>
  </div>
</template>

<script>
import ContactProgressFlow from './ContactProgressFlow'

export default {
  name: 'ContactProgress',
  components: {
    ContactProgressFlow,
  },
  props: {
    contact: Object,
    mainFlow: Array,
  },
  computed: {
    numMoreFlows() {
      return this.contact.flows ? Object.values(this.contact.flows).length - 1 : null
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/_variables.scss';

// .ContactProgress {
// }
</style>
